var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('b-form-group',{attrs:{"label":"Период:","disabled":_vm.loading,"invalid-feedback":((_vm.errors['filter.dateStart']) + " " + (_vm.errors['filter.dateEnd'])),"state":_vm.states['filter.dateStart'] && _vm.states['filter.dateEnd']}},[_c('date-popover',{staticClass:"mb-2",attrs:{"size":"sm","value":_vm.filter.dateStart,"state":_vm.states['filter.dateStart'],"disabled":_vm.loading},on:{"change":function($event){_vm.setFilter({ field: 'dateStart', value: $event }), _vm.setFilter({ field: 'releaseIds', value: [] })}}}),_c('date-popover',{attrs:{"size":"sm","value":_vm.filter.dateEnd,"state":_vm.states['filter.dateEnd'],"disabled":_vm.loading},on:{"change":function($event){_vm.setFilter({ field: 'dateEnd', value: $event }), _vm.setFilter({ field: 'releaseIds', value: [] })}}})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Кинотеатры:","disabled":_vm.loading,"invalid-feedback":_vm.errors['filter.cinemaIds']}},[_c('dictionary-provider',{attrs:{"name":"cinemas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cinemas = ref.items;
var cinemasLoading = ref.loading;
return [_c('toggle-list',{attrs:{"options":_vm.convertCinemas(cinemas),"value":_vm.convertToDictionaryFromIds(_vm.filter.cinemaIds, cinemas),"disabled":_vm.loading || cinemasLoading},on:{"change":function($event){_vm.setFilter({ field: 'cinemaIds', value: _vm.convertFromDictionaryToIds($event) }), _vm.setFilter({ field: 'releaseIds', value: [] })}}})]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Дистрибьютор:","disabled":_vm.loading,"state":_vm.states['filter.distributorId'],"invalid-feedback":_vm.errors['filter.distributorId']}},[_c('dictionary-provider',{attrs:{"name":"distributors2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":_vm.filter.distributorId,"state":_vm.states.distributorId,"disabled":_vm.loading,"options":_vm.$array.convertToOptions(items)},on:{"change":function($event){return _vm.setFilter({ field: 'distributorId', value: $event})}}})]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Релиз:","disabled":_vm.loading,"invalid-feedback":_vm.errors['filter.releaseIds']}},[_c('dictionary-provider',{attrs:{"name":"showsReleases","filter":{ dateStart: _vm.filter.dateStart, dateEnd: _vm.filter.dateEnd, cinemaIds: _vm.filter.cinemaIds, distributorId: _vm.filter.distributorId },"disable-fetching":!_vm.filter.dateStart || !_vm.filter.dateEnd},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var releases = ref.items;
var releasesLoading = ref.loading;
return [_c('toggle-list',{attrs:{"options":_vm.convertReleasesToOptions(releases),"value":_vm.convertToDictionaryFromIds(_vm.filter.releaseIds, releases),"searchable":true,"disabled":releasesLoading || _vm.loading || (!_vm.filter.dateStart || !_vm.filter.dateEnd),"state":_vm.states['filter.releaseIds']},on:{"change":function($event){_vm.setFilter({ field: 'releaseIds', value: _vm.convertFromDictionaryToIds($event) })}}})]}}])})],1),_c('div',{staticClass:"mt-4"},[_c('b-button',{attrs:{"variant":"info","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.fetchReport({ format: 'html' })}}},[_vm._v("Просмотр")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"outline-info","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.printReport()}}},[_c('icon',{attrs:{"icon":"fa-print"}}),_vm._v(" Печать")],1),_c('b-dropdown',{staticClass:"ml-2",attrs:{"variant":"outline-info","size":"sm","disabled":_vm.loading},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('icon',{attrs:{"icon":"fa-save"}})]},proxy:true}])},_vm._l((_vm.formats),function(format){return _c('b-dropdown-item',{key:format.value,on:{"click":function($event){return _vm.fetchReport({ format: format.value })}}},[_vm._v(_vm._s(format.text))])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }