import Vue from 'vue';
import Compressor from 'compressorjs';

function createPlugin({
  registerFile, uploadFile, getFilepath, downloadFile,
}) {
  return new Vue({
    data: {
      files: [],
      optionsDefaultCompressor: {
        strict: true,
        quality: 0.6,
        mimeType: '',
        convertSize: 5000000,
      },
    },
    methods: {
      getBase64(file, { maxWidth, maxHeight } = {}) {
        return new Promise(async (resolve) => {
          try {
            const compressFile = await this.compressImage(file, {
              maxWidth,
              maxHeight,
            });

            const reader = new FileReader();
            reader.readAsDataURL(compressFile);
            reader.onload = () => {
              resolve(reader.result);
            };
          } catch (error) {
            console.log(error);
          }
        });
      },

      async registerAndUpload(file, params) {
        const [, id] = await registerFile();

        if (!id) {
          return false;
        }

        this.pushFile(id, file, params);

        if (this.isImage(file.type)) {
          this.getBase64(file, {
            maxWidth: 100,
          }).then((thumb) => this.updateFileValue(id, 'thumb', thumb));
        }

        this.upload(id, file, params);

        return id;
      },

      async upload(id, file, params) {
        const formData = this.prepareData({ file, ...params });

        this.resetFile(id, file);

        uploadFile(id, formData, (progress) => {
          this.updateFileValue(id, 'progress', progress);
        }).then(([err, data]) => {
          if (data && data.status === 200) {
            this.deleteFile(id);
          }

          if (err) {
            this.updateFileValue(id, 'error', true);
            this.$emit('error', err);
          }
        });
      },

      resetFile(id, file) {
        this.updateFileValue(id, 'error', false);
        this.updateFileValue(id, 'type', file.type);
        this.updateFileValue(id, 'size', file.size);
        this.updateFileValue(id, 'name', file.name);
        this.updateFileValue(id, 'progress', 0);
      },

      emitFilesChangedEvent() {
        this.$emit('change', this.files);
      },

      getPath(id) {
        return getFilepath(id);
      },

      isImage(type) {
        return type.substr(0, 6) === 'image/' || false;
      },

      getIndxFileById(id) {
        return this.files.findIndex((file) => file.id === id);
      },

      async pushFile(id, file, params = {}) {
        const fileInfo = {
          id,
          file,
          params,
          name: file.name,
          type: file.type,
          size: file.size,
          thumb: null,
          progress: 0,
          error: false,
        };

        this.files.push(fileInfo);

        this.emitFilesChangedEvent();
      },

      updateFileValue(id, field, value) {
        const indx = this.getIndxFileById(id);

        if (indx < 0) return;

        this.$set(this.files, indx, {
          ...this.files[indx],
          [field]: value,
        });

        this.emitFilesChangedEvent();
      },

      deleteFile(id) {
        const indx = this.getIndxFileById(id);

        this.files.splice(indx, 1);

        this.emitFilesChangedEvent();
      },

      compressImage(file, size) {
        return new Promise((resolve, reject) => {
          // eslint-disable-next-line no-new
          new Compressor(file, {
            ...this.optionsDefaultCompressor,
            ...size,
            success: (result) => {
              resolve(result);
            },
            error(err) {
              reject(new Error(err.message));
            },
          });
        });
      },

      prepareData(params) {
        if (typeof params !== 'object') return false;

        const fd = new FormData();

        // eslint-disable-next-line no-restricted-syntax
        for (const field in params) {
          // eslint-disable-next-line no-prototype-builtins
          if (params.hasOwnProperty(field)) {
            fd.append(field, params[field]);
          }
        }

        return fd;
      },
    },
  });
}

export default {
  install(Vue, options) {
    if (
      !options.uploadFile
      || !options.registerFile
      || !options.getFilepath
      || !options.downloadFile
    ) { return; }

    const {
      registerFile, uploadFile, getFilepath, downloadFile,
    } = options;

    Vue.prototype.$fileManager = createPlugin({
      registerFile,
      uploadFile,
      getFilepath,
      downloadFile,
    });
  },
};
