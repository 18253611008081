<template>
  <div class="table-container customers">
    <collection-provider
      collection="customers"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          :permissions="$user.permissions"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(phone)>
            <masked-input
              type="text"
              class="form-control form-control-sm"
              :placeholder="placeholder.SIMPLE_PHONE"
              :disabled="data.loading"
              :value="data.filter.phone"
              :mask="mask.getSimplePhoneMask()"
              :guide="true"
              @change="actions.changeFilter('phone', $event.target.value)"
            />
          </template>

          <template #cell(phone)="data">
            {{ formatter.phoneFormat(data.item.phone) }}
          </template>

          <template #cell(balance)="data">
            <a
              class="text-primary cursor-pointer"
              @click="openCustomerOperations(data.item)"
            >{{ data.item.balance }}</a>
          </template>

          <template #cell(approvedAt)="data">
            {{ data.item.approvedAt ?  formatDateTime(data.item.approvedAt) : '' }}
          </template>

          <template #cell(blockedAt)="data">
            {{ data.item.blockedAt ?  formatDateTime(data.item.blockedAt) : '' }}
          </template>
        </datatable>
      </template>
    </collection-provider>

    <b-modal
      v-if="modals.customerOperations.show"
      centered
      cancel-title="Закрыть"
      size="xl"
      :visible="true"
      :title="modals.customerOperations.title"
      :ok-only="true"
      ok-title="Закрыть"
      ok-variant="secondary"
      @hidden="closeCustomerOperations()"
    >
      <customer-operations
        :id="modals.customerOperations.id"
      ></customer-operations>
    </b-modal>

    <modal-model-form
      v-if="modals.customer.show"
      create-title="Создание физического лица"
      edit-title="Редактирование физического лица"
      collection="customers"
      :id="modals.customer.id"
      @close="modals.customer.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <customer-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></customer-form>
      </template>
    </modal-model-form>

    <modal-model-form
      v-if="modals.customerBalance.show"
      collection="customerOperations"
      id=""
      :create-title="modals.customerBalance.title"
      :init="{ customerId: modals.customerBalance.id }"
      @close="modals.customerBalance.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <customer-balance-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></customer-balance-form>
      </template>
    </modal-model-form>

    <customer-devices
      v-if="modals.customerDevices.show"
      :id="modals.customerDevices.id"
      @hide="closeCustomerDevices()"
    ></customer-devices>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import CustomerForm from './CustomerForm.vue';
import CustomerBalanceForm from './CustomerBalanceForm.vue';
import CustomerDevices from './CustomerDevices.vue';
import CustomerOperations from './CustomerOperations.vue';
import { Formatter } from '../../utils/formatter';
import datetime from '../../utils/datetime';

import { placeholder } from '../../utils/placeholder';
import { MaskHelper } from '../../utils/mask';

export default {
  components: {
    CustomerForm,
    CustomerBalanceForm,
    CustomerDevices,
    CollectionProvider,
    ModalModelForm,
    CustomerOperations,
    Datatable,
    MaskedInput,
  },
  data() {
    return {
      formatter: Formatter,
      placeholder,
      mask: MaskHelper,
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        customer: {
          show: false,
          id: 0,
        },
        customerOperations: {
          show: false,
          id: null,
          title: '',
        },
        customerBalance: {
          show: false,
          id: null,
          title: '',
        },
        customerDevices: {
          show: false,
          id: null,
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateCustomerForm() {
      this.modals.customer.id = '';
      this.modals.customer.show = true;
    },

    async removeCheckedCustomers() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    openEditCustomerForm(id) {
      this.modals.customer.id = id;
      this.modals.customer.show = true;
    },

    async removeCustomer(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    createTitle({
      name, surname, phone, balance,
    }) {
      const fullname = [name, surname].join(' ').trim();

      return `${fullname || 'Без имени'}, ${this.formatter.phoneFormat(phone)}, Баланс: ${balance}`;
    },

    openCustomerOperations(item) {
      this.modals.customerOperations.show = true;
      this.modals.customerOperations.id = item.id;
      this.modals.customerOperations.title = this.createTitle(item);
    },

    closeCustomerOperations() {
      this.modals.customerOperations.show = false;
      this.modals.customerOperations.id = null;
      this.modals.customerOperations.title = '';
    },

    openCustomerBalanceForm(id, item) {
      this.modals.customerBalance.show = true;
      this.modals.customerBalance.id = id;
      this.modals.customerBalance.title = this.createTitle(item);
    },

    closeCustomerBalanceForm() {
      this.modals.customerBalance.show = false;
      this.modals.customerBalance.id = null;
      this.modals.customerBalance.title = '';
    },

    openCustomerDevices(id, item) {
      this.modals.customerDevices.show = true;
      this.modals.customerDevices.id = id;
    },

    closeCustomerDevices() {
      this.modals.customerDevices.show = false;
      this.modals.customerDevices.id = null;
    },

    formatDateTime(date) {
      return datetime.formatDateTime(date);
    },
  },
};
</script>
