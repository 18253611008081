import http from './http';
import { convertError } from './utilities';
import {
  getAuthToken, setAuthToken, resetAuthToken, isTokenExpired,
} from './token';

let _refreshPromise = null;

async function refreshToken(token) {
  try {
    // несколько параллельных запросов могут породить несколько запросов на обновление
    // поэтому на каждый запрос ожидаем один и тот же промис, который инициировал первый запрос

    if (!_refreshPromise) {
      _refreshPromise = http.post('auth/refresh', {}, {
        headers: { Authorization: `Bearer ${token}` },
        skipAuth: true,
      });
    }

    const { data } = await _refreshPromise;

    _refreshPromise = null;

    return [null, data.access_token];
  } catch (error) {
    return convertError(error);
  }
}

http.interceptors.request.use(
  async (config) => {
    if (!config.headers.Authorization && !config.skipAuth) {
      let accessToken = getAuthToken();

      if (accessToken && isTokenExpired(accessToken)) {
        const [, token] = await refreshToken(accessToken);

        if (token) {
          accessToken = token;

          setAuthToken(accessToken);
        }
      }

      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (err) => Promise.reject(err),
);

http.interceptors.response.use((response) => {
  const accessToken = response?.headers?.Authorization;

  if (accessToken) {
    setAuthToken(accessToken);
  }

  return response;
}, (err) => {
  if (err?.response?.status === 401) {
    resetAuthToken();
  }

  return Promise.reject(err);
});

export async function loginUser({ email, password }) {
  try {
    const { data } = await http.post('auth/login', { email, password }, { skipAuth: true });

    setAuthToken(data.access_token);

    return [null, true];
  } catch (error) {
    return convertError(error);
  }
}

export async function logoutUser() {
  try {
    const accessToken = getAuthToken();

    if (accessToken) {
      await http.post('auth/logout');
    }

    resetAuthToken();

    return [null, true];
  } catch (error) {
    return convertError(error);
  }
}

export async function fetchUser() {
  try {
    const { data: { info, permissions } } = await http.get('auth/user');
    return [null, { ...info, permissions }];
  } catch (error) {
    return convertError(error);
  }
}
