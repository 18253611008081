import { convertError, convertResponse, createQuery } from './utilities';

import { API_VERSION } from '../config';
import http from './http';
/* eslint-disable no-return-await */
/* eslint-disable no-restricted-syntax */

export async function fetchItems(collection, {
  page, limit, sort, filter, params,
} = {}) {
  const query = createQuery({
    page, limit, sort, filter, params,
  });

  try {
    const response = await http.get(`/${API_VERSION}/${collection}?${query}`);
    return [null, convertResponse(response)];
  } catch (error) {
    return convertError(error);
  }
}

export async function fetchAll(name) {
  try {
    const response = await http.get(`/${API_VERSION}/dictionaries/${name}`);
    return [null, convertResponse(response)];
  } catch (error) {
    return convertError(error);
  }
}

export async function getItem(collection, id) {
  try {
    const response = await http.get(`/${API_VERSION}/${collection}/${id}`);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function createItem(collection, values) {
  try {
    const response = await http.post(`/${API_VERSION}/${collection}`, values);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function patchItem(collection, id, values) {
  try {
    const response = await http.put(`/${API_VERSION}/${collection}/${id}`, values);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function removeItem(collection, id) {
  try {
    await http.delete(`/${API_VERSION}/${collection}/${id}`);
    return [null, true];
  } catch (error) {
    return convertError(error);
  }
}

export async function patchItems(collection, ids, values) {
  const promises = [];

  for (const id of ids) {
    promises.push(patchItem(collection, id, values));
  }

  return await Promise.all(promises);
}

export async function removeItems(collection, ids) {
  const promises = [];

  for (const id of ids) {
    promises.push(removeItem(collection, id));
  }

  return await Promise.all(promises);
}

export async function exportItem(collection, id, actionName = 'export') {
  try {
    const response = await http.post(`/${API_VERSION}/${collection}/${id}/${actionName}`, {}, {
      responseType: 'blob',
    });

    return [null, response.data || {}];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}

export async function execItemAction(collection, id, actionName, params) {
  try {
    const response = await http.post(`/${API_VERSION}/${collection}/${id}/${actionName}`, params);

    return [null, response.data || {}];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}
