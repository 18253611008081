<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Режим:"
        :invalid-feedback="errors.type"
        :state="states.type"
      >
        <b-form-select
          size="sm"
          :value="value"
          :options="options"
          :disabled="loading"
          @change="$emit('change', $event)"
        >
          <template #first>
            <b-form-select-option :value="null">-- Режим не выбран --</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { TYPES_SETTINGS as options } from './_index.settings';

export default {
  props: {
    value: null,
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
  },
  data: () => ({
    options,
  }),
};
</script>
