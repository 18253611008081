<template>
  <modal-form-dialog
    title="Список устройств"
    size="xl"
    :close="() => true"
    :hideFooter="true"
    @hidden="$emit('hide')"
  >
    <collection-provider
      collection="customerDevices"
      ref="collection"
      :filter="{ customer_id: id }"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          :permissions="$user.permissions"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #cell(appId)="data">
            {{ getAppName(data.item.appId) }}
          </template>
          <template #cell(token)="data">
            <div class="token">
              {{ data.item.token }}
            </div>
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt }}
          </template>
        </datatable>
      </template>
    </collection-provider>
  </modal-form-dialog>
</template>

<script>
import { mapState } from 'vuex';

import { confirmDialog } from '../../utils/dialogs';

import CollectionProvider from '../../providers/CollectionProvider';

import ModalFormDialog from '../../components/modal/ModalFormDialog.vue';
import Datatable from '../../components/datatable/Datatable.vue';

import fields from './_customerDevices.fields';
import panelActions from './_customerDevices.panelActions';
import itemActions from './_customerDevices.itemActions';

export default {
  components: {
    CollectionProvider,
    ModalFormDialog,
    Datatable,
  },
  props: {
    id: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
    };
  },
  computed: {
    ...mapState('dictionaries/apps', {
      apps: (state) => state.items,
    }),
  },
  async created() {
    await this.$store.dispatch('dictionaries/apps/fetchItems');
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    async removeCheckedCustomerDevices() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    async removeCustomerDevice(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    getAppName(appId) {
      return this.apps.find(({ id }) => id === appId)?.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.token {
  word-break: break-all;
}
</style>
