<template>
  <div class="table-container reserve-types">
    <collection-provider
      collection="reserveTypes"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.reserveType.show"
      create-title="Создание типа брони"
      edit-title="Редактирование типа брони"
      collection="reserveTypes"
      :id="modals.reserveType.id"
      @close="modals.reserveType.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <reserve-type-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></reserve-type-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import ReserveTypeForm from './ReserveTypeForm.vue';

export default {
  components: {
    CollectionProvider,
    ModalModelForm,
    Datatable,
    ReserveTypeForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        reserveType: {
          show: false,
          id: 0,
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateReserveTypeForm() {
      this.modals.reserveType.id = '';
      this.modals.reserveType.show = true;
    },

    async removeCheckedReserveTypes() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    openEditReserveTypeForm(id) {
      this.modals.reserveType.id = id;
      this.modals.reserveType.show = true;
    },

    async removeReserveType(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>
