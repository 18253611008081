<template>
  <div>
    <b-table
      hover
      no-local-sorting
      :table-class="['table-library', { 'table-library-tree' : isTreeMode }]"
      :thead-class="['table-library__thead', { 'table-library__thead-hide' : isTreeMode }]"
      :tbody-tr-class="rowClass"
      :items="prepareItems"
      :fields="datatable.fields"
      :filter-included-fields="['name']"
      :filter="filter"
      :sort-by="sort.by"
      :sort-desc="sort.desc"
      @sort-changed="changeSort({ by: $event.sortBy, desc: !!$event.sortDesc })"
    >
      <template
        v-if="showBackButton"
        #top-row
      >
        <files-row-top
          :row-length="datatable.fields.length"
          @click-directory="fetchItemsByDirectortId(backBtnDirectory)"
        ></files-row-top>
      </template>

      <template #cell(name)="{ item }">
        <files-item-directory
          v-if="helperFile.isDirectory(item.type)"
          :item="item"
          :is-list-mode="isListMode"
          :is-tree-mode="isTreeMode"
          @open-directory="openDirectory"
          @close-directory="closeDirectory"
        ></files-item-directory>

        <files-item-file
          v-if="helperFile.isFile(item.type)"
          :item="item"
          :clickable="item.status !== helperFileStatus.statusLoading"
          @click-file="$emit('click-file', $event)"
        ></files-item-file>
      </template>

      <template #cell(mediaInfo)="{ item }">
        {{ getItemMediaInfo(item) }}
      </template>

      <template #cell(action)="{ item }">
        <actions
          :actions="getItemActions(item)"
          @click="onActionItem(item.id, ...arguments)"
        >
        </actions>
      </template>
    </b-table>

    <modal-file
      v-if="modal.file.show"
      :id="modal.file.id"
      :name="modal.file.name"
      @hide="
        modal.file.show = false,
        modal.file.name = null,
        modal.file.id = null
      "
    >
    </modal-file>

    <modal-directory
      v-if="modal.directory.show"
      :id="modal.directory.id"
      :name="modal.directory.name"
      @hide="
        modal.directory.show = false,
        modal.directory.name = null,
        modal.directory.id = null
      "
    >
    </modal-directory>

    <modal-content
      v-if="modal.warning.show"
      title="Ошибка"
      size="md"
      @hide="modal.warning.show = false"
    >
      <div class="text">Вы не можете удалить данный файл или папку, так как он используется в одном из медиапотоков.</div>
    </modal-content>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';

import {
  fields, isFile, isDirectory, isVideo, isImage,
} from './_index.fields';
import {
  MEDIA_PANEL_LIBRARY_STATUS_UPLOADING as statusLoading,
} from '../../constants';
import actionItem from './_index.itemActions';

import Actions from '../../components/datatable/Actions.vue';

import ModalFile from './ModalFile.vue';
import ModalContent from '../media-panels/ModalContent.vue';
import ModalDirectory from './ModalDirectory.vue';
import FilesRowTop from './FilesRowTop.vue';
import FilesItemFile from './FilesItemFile.vue';
import FilesItemDirectory from './FilesItemDirectory.vue';

export default {
  name: 'Files',
  props: {
    parentDirectoryId: null,
    colorRowSelected: null,
    filter: null,
    mode: {
      type: String,
      default: 'list',
    },
    hideFields: {
      type: Array,
      default: () => [],
    },
    filesSelected: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    helperFile: {
      isFile,
      isDirectory,
      isVideo,
      isImage,
    },
    helperFileStatus: {
      statusLoading,
    },
    modal: {
      directory: {
        show: false,
        name: null,
        id: null,
      },
      file: {
        show: false,
        name: null,
        id: null,
      },
      warning: {
        show: false,
      },
    },
    actionItem,
    arrShowDirectories: [],
  }),
  components: {
    Actions,
    FilesRowTop,
    FilesItemDirectory,
    FilesItemFile,
    ModalFile,
    ModalDirectory,
    ModalContent,
  },
  async created() {
    await this.fetchItemsByDirectortId(this.parentDirectoryId);

    if (this.isTreeMode) {
      this.arrShowDirectories.push({ id: this.parentDirectoryId });
    }
  },
  computed: {
    isListMode() {
      return this.mode === 'list';
    },

    isTreeMode() {
      return this.mode === 'tree';
    },

    ...mapGetters({
      directoriesNavigationInListMode: 'media/library/directoriesNavigationInListMode',
      itemsDirectories: 'media/library/itemsDirectories',
    }),

    ...mapState('media/library', {
      parentId: (state) => state.parentId,
      loading: (state) => state.loading,
      items: (state) => state.items,
      sort: (state) => state.sort,
    }),

    prepareItems() {
      let items = [...this.items];

      if (this.isListMode) {
        items = items
          .filter((item) => item.parentId === this.parentId)
          .sort((a, b) => a.type - b.type);
      }

      if (this.isTreeMode) {
        items = items
          .filter((item) => this.arrShowDirectories.map((_item) => _item.id).includes(item.parentId))
          .map((item) => ({
            ...item,
            ...(this.helperFile.isDirectory(item.type) && { isOpen: this.arrShowDirectories.map((_item) => _item.id).includes(item.id) }),
          }));
      }

      return items.map((item) => ({
        ...item,
        ...((this.helperFile.isFile(item.type) && this.helperFile.isVideo(item?.mediaInfo?.mimeType))
          && { duration: this.convertDuration(item?.mediaInfo?.duration) }),
        ...(this.helperFile.isFile(item.type) && { contentType: item?.mediaInfo?.mimeType }),
        ...((this.helperFile.isFile(item.type) && this.filesSelected.map((_item) => _item.id).includes(item.id))
          && { _rowVariant: this.colorRowSelected }),
      }));
    },

    datatable() {
      const f = [...fields];

      this.hideFields.forEach((field) => {
        const index = f.findIndex((item) => item.key === field);

        if (index > -1) {
          f.splice(index, 1);
        }
      });

      return { fields: f };
    },

    showBackButton() {
      return this.parentId !== this.parentDirectoryId && this.isListMode;
    },

    backBtnDirectory() {
      return this.directoriesNavigationInListMode.length > 1 && this.directoriesNavigationInListMode[this.directoriesNavigationInListMode.length - 2].id;
    },
  },
  methods: {
    ...mapActions({
      fetchItemsByDirectortId: 'media/library/fetchItemsByDirectortId',
      deleteFileOrDirectory: 'media/library/deleteFileOrDirectory',
      changeSort: 'media/library/changeSort',
    }),

    rowClass(item) {
      if (this.isTreeMode) {
        if (item) {
          return `tr-${item.depth}`;
        }
      }

      return '';
    },

    async openDirectory(id) {
      await this.fetchItemsByDirectortId(id);

      if (this.isTreeMode) {
        const item = this.itemsDirectories.find((_item) => _item.id === id);

        if (!item || this.arrShowDirectories.find((_item) => _item.id === item.id)) {
          return;
        }

        this.arrShowDirectories.push({ id: item.id, parentId: item.parentId });
      }
    },

    closeDirectory(id) {
      if (this.isTreeMode) {
        let parentId = id;

        const indexById = this.arrShowDirectories.findIndex((item) => item.id === parentId);

        if (indexById > -1) {
          this.arrShowDirectories.splice(indexById, 1);
        }

        this.arrShowDirectories = this.arrShowDirectories.reduce((acc) => {
          const indexByParentId = acc.findIndex((item) => item.parentId === parentId);

          if (indexByParentId > -1) {
            parentId = acc[indexByParentId].id;
            acc.splice(indexByParentId, 1);
          }

          return acc;
        }, [...this.arrShowDirectories]);
      }
    },

    convertDuration(seconds) {
      return moment.utc(moment.duration(seconds, 'seconds').asMilliseconds()).format('HH:mm:ss');
    },

    getItemById(id) {
      return this.items.find((item) => item.id === id);
    },

    editFileOrDirectory({ id }) {
      const { type, name } = this.getItemById(id);

      if (this.helperFile.isDirectory(type)) {
        this.modal.directory.show = true;
        this.modal.directory.name = name;
        this.modal.directory.id = id;
      }

      if (this.helperFile.isFile(type)) {
        this.modal.file.show = true;
        this.modal.file.name = name;
        this.modal.file.id = id;
      }
    },

    onActionItem(id, action) {
      if (action === 'deleteFileOrDirectory') {
        const { used } = this.getItemById(id);

        if (used) {
          this.modal.warning.show = true;
          return;
        }
      }

      this[action]({ id });
    },

    getItemActions(item) {
      const actions = this.actionItem(item);
      return actions.filter((action) => !action?.hidden);
    },

    getItemMediaInfo(item) {
      if (item?.mediaInfo?.resolution) {
        return item.mediaInfo.resolution;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  max-height: 100%;
}

.table-library {
  /deep/ .table-library__thead {
    th {
      border: 0;
      font-weight: 500;
      font-size: 15px;
    }

    &-hide {
      display: none;
    }
  }

  &.table-library-tree {
    max-width: 100%;
    margin-top: 0;

    /deep/ tbody tr {
      &:first-child {
        td {
          border-top: none;
        }
      }
    }

    /deep/ .table-library__name {
      max-width: 200px;
    }
  }

  /deep/ td {
    vertical-align: middle;
    padding: .5rem;
  }

  /deep/ tbody {
    @for $i from 1 through 15 {
      .tr-#{$i} {
        td:first-child {
          padding-left: calc(35px * #{$i});
        }
      }
    }
  }

  /deep/ .table-library__name {
    max-width: 400px;
  }

  /deep/ .table-library__image-wrap {
    width: 75px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
