import screens from './screens';
import screenshot from './screenshot';
import logs from './logs';
import library from './library';

export default {
  namespaced: true,

  modules: {
    screens,
    screenshot,
    logs,
    library,
  },
};
