<template>
  <b-modal
    ref="modal"
    centered
    ok-variant="success"
    ok-title="Установить"
    cancel-title="Закрыть"
    :visible="true"
    :busy="loading"
    @ok="ok"
    @hidden="$emit('hide')"
  >
    <template #modal-title><h4>Установка минимальных цен</h4></template>
    <b-container fluid class="p-0">
      <b-form-group
        label="Релиз:"
        :invalid-feedback="errors.releaseId"
        :state="states.releaseId"
      >
        <dictionary-provider
          name="showsReleases"
          :filter="{
            cinemaId,
            dateStart: item.dateFrom,
            dateEnd: item.dateTo
          }"
          :disable-fetching="!cinemaId || !item.dateFrom || !item.dateTo"
        >
          <template #default="{ items, loading: releasesLoading }">
            <b-form-select
              size="sm"
              v-model="item.releaseId"
              :disabled="loading || releasesLoading"
            >
              <template v-slot:first>
                <b-form-select-option :value="null">-- Выберите фильм --</b-form-select-option>
              </template>
              <option
                v-for="item in items"
                :value="item.id"
                :key="item.id"
              >{{item.movie.name}} {{item.format.name}}</option>
            </b-form-select>
          </template>
        </dictionary-provider>
      </b-form-group>

      <b-form-group
        label="Даты начала и окончания периода:"
        :invalid-feedback="errors.dateFrom || errors.dateTo"
        :state="states.dateFrom || states.dateTo"
      >
        <date-interval
          :value="{from: item.dateFrom, to: item.dateTo}"
          :disabled="loading"
          @selected="updateValue('dateFrom', $event.from), updateValue('dateTo', $event.to)"
        ></date-interval>
      </b-form-group>

      <b-form-group
        label="Время начала и окончания периода:"
        :invalid-feedback="errors.timeFrom || errors.timeTo"
        :state="states.timeFrom || states.timeTo"
      >
        <time-interval
          :value="{from: item.timeFrom, to: item.timeTo}"
          :disabled="loading"
          @change="updateValue('timeFrom', $event.from), updateValue('timeTo', $event.to)"
        ></time-interval>
      </b-form-group>

      <b-form-group
        label="Минимальная стоимость:"
        :invalid-feedback="errors.minPrice"
        :state="states.minPrice"
      >
        <b-form-input
          type="number"
          size="sm"
          min="0"
          v-model="item.minPrice"
          :disabled="loading"
        ></b-form-input>
      </b-form-group>
    </b-container>
  </b-modal>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { mapState } from 'vuex';

import DateInterval from '../../components/fields/DateInterval.vue';
import TimeInterval from '../../components/fields/TimeInterval.vue';

import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    DateInterval,
    TimeInterval,
    DictionaryProvider,
  },
  data() {
    return {
      errors: {},
      states: {},
      item: {
        releaseId: null,
        dateFrom: null,
        dateTo: null,
        timeFrom: null,
        timeTo: null,
        minPrice: null,
      },
    };
  },
  computed: {
    ...mapState('data/sales', {
      loading: (state) => state.loading,
      cinemaId: (state) => state.cinemaId,
      date: (state) => state.date,
    }),
  },
  created() {
    const date = this.$datetime.convertDateToDbFormat(this.date);
    this.item.dateFrom = date;
    this.item.dateTo = date;
  },
  methods: {
    setError(field, value) {
      this.$set(this.errors, field, value);
    },

    resetErrors() {
      this.errors = {};
    },

    setState(field, value) {
      this.$set(this.states, field, value);
    },

    resetStates() {
      this.states = {};
    },

    async ok(event) {
      event.preventDefault();

      this.resetErrors();
      this.resetStates();

      const [err] = await this.$store.dispatch('data/sales/setMinPrice', this.item);

      if (err && err.status === 422 && err?.data?.errors) {
        const errors = err?.data?.errors;

        for (const field in errors) {
          this.setError(field, errors[field].join(', '));
          this.setState(field, false);
        }
      }

      if (!err) {
        this.$refs.modal.hide();
      }
    },

    updateValue(field, value) {
      this.item[field] = value;
    },
  },
};
</script>
