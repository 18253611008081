<template>
  <div class="p-2">
    <b-form-group
      label="Период для поиска релизов:"
    >
      <date-interval
        :value="{from: releaseDateStart, to: releaseDateEnd}"
        :disabled="loading"
        @selected="releaseDateStart = $event.from, releaseDateEnd = $event.to, updateValue('movieId', null)">
      </date-interval>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Фильм:"
      :disabled="!item.cinemaId"
      :invalid-feedback="errors.movieId"
      :state="states.movieId"
    >
      <dictionary-provider
        name="showsReleases"
        :filter="{ cinemaId: item.cinemaId, dateStart: releaseDateStart, dateEnd: releaseDateEnd }"
        :disable-fetching="!item.cinemaId || !releaseDateStart || !releaseDateEnd"
      >
        <template #default="{ items: releases, loading: releasesLoading }">
          <b-form-select
            size="sm"
            :options="$array.convertToOptions(convertReleasesToMovies(releases), null)"
            :value="item.movieId"
            :disabled="releasesLoading || (!item.cinemaId || !releaseDateStart || !releaseDateEnd)"
            :state="states.movieId"
            @change="updateValue('movieId', $event)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">-- Выберите фильм --</b-form-select-option>
            </template>
          </b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Период для отчёта:"
      :invalid-feedback="errors.dateEnd || errors.dateStart"
      :state="states.dateEnd || states.dateStart"
    >
      <date-interval
        :value="{from: item.dateStart, to: item.dateEnd}"
        :disabled="loading"
        @selected="updateValue('dateStart', $event.from), updateValue('dateEnd', $event.to)">
      </date-interval>
    </b-form-group>

    <div class="mt-4 font-weight-bold">Используя инструмент ручной отправки отчетов в Rentrak, вы признаете, что делаете это на свой риск и несете полную ответственность за любые проблемы, связанные с его использованием.</div>
  </div>
</template>

<script>
import DictionaryProvider from '../../../providers/DictionaryProvider';
import DateInterval from '../../../components/fields/DateInterval.vue';

export default {
  components: {
    DictionaryProvider,
    DateInterval,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      releaseDateStart: null,
      releaseDateEnd: null,
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    convertReleasesToMovies(releases) {
      return releases
        .reduce((acc, release) => {
          let movie = acc.find((_movie) => _movie.id === release.movieId);

          if (!movie) {
            movie = {
              id: release.movieId,
              name: release.movie.name,
              releases: [],
            };

            acc.push(movie);
          }

          movie.releases.push(release);

          return acc;
        }, [])
        .map((movie) => ({
          id: movie.id,
          name: `${movie.name} (${movie.releases.map((release) => release.format.name).join(', ')})`,
        }));
    },
  },
};
</script>
