<template>
  <b-popover
    :target="target"
    :show="true"
    boundary="window"
  >
    <template #title>
      <div class="d-flex">
        <span class="mr-3">Число сеансов</span>
        <span
          class="cursor-pointer ml-auto"
          @click="$emit('hide')"
        >
          <icon icon="fa-times"></icon>
        </span>
      </div>
    </template>
    <div class="d-flex mt-2 mb-2">
      <div class="d-flex align-items-center ml-auto mr-auto">
        <span
          class="cursor-pointer text-primary"
          @click="decreaseCount()"
        >
          <icon icon="fa-minus-square"></icon>
        </span>
        <b-input
          class="text-center releases-counter"
          type="number"
          size="sm"
          v-model="localValue"
          :min="1"
          :step="1"
        ></b-input>
        <span
          class="cursor-pointer text-primary"
          @click="increaseCount()"
        >
          <icon icon="fa-plus-square"></icon>
        </span>
      </div>
    </div>
    <div class="d-flex">
      <b-button
        class="ml-auto mr-auto"
        size="sm"
        variant="info"
        @click="save()"
      >Сохранить</b-button>
    </div>
  </b-popover>
</template>

<script>
export default {
  props: {
    target: {
      default: '',
      required: true,
    },
    value: {
      default: 1,
    },
  },
  data() {
    return {
      localValue: 1,
    };
  },
  watch: {
    value: {
      handler() {
        this.localValue = +this.value;
      },
      immediate: true,
    },
    localValue: {
      handler() {
        if (this.localValue <= 0) {
          this.localValue = 1;
        }
      },
      immediate: true,
    },
  },
  methods: {
    increaseCount() {
      this.localValue = +this.localValue + 1;
    },

    decreaseCount() {
      if (this.localValue <= 1) {
        return;
      }

      this.localValue = +this.localValue - 1;
    },

    save() {
      this.$emit('change', this.localValue);
      this.$emit('hide');
    },
  },
};
</script>

<style scoped>
.releases-counter {
  max-width: 50px;
}
</style>
