<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Тема вопроса:"
          :invalid-feedback="errors.question"
          :state="states.question"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.question"
            :state="states.question"
            :disabled="loading"
            @change="updateValue('question', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Категория вопроса:"
          :invalid-feedback="errors.qaCategoryId"
          :state="states.qaCategoryId"
        >
          <dictionary-provider name="qaCategories">
            <template #default="{ items, loading: qaCategoriesLoading }">
              <b-form-select
                size="sm"
                :value="item.qaCategoryId"
                :options="$array.convertToOptions(items)"
                :disabled="loading || qaCategoriesLoading"
                @change="updateValue('qaCategoryId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
          label="Источники:"
          :invalid-feedback="errors.apps"
          :state="states.apps"
        >
          <dictionary-provider name="apps">
            <template #default="{ items, loading: appsLoading }">
              <toggle-list
                placeholder="Все"
                :value="item.apps"
                :options="items"
                :disabled="loading || appsLoading"
                @change="updateValue('apps', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
          label="Ответ:"
          :invalid-feedback="errors.answer"
          :state="states.answer"
        >
          <v-md-editor
            :value="item.answer"
            :state="states.answer"
            :disabled="loading"
            :disabled-menus="['h/h1', 'image/upload-image']"
            left-toolbar="undo redo clear | h bold italic quote | ul ol hr | link image"
            right-toolbar="preview sync-scroll fullscreen"
            height="400px"
            @change="updateValue('answer', $event)"
          ></v-md-editor>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    convertApps(apps) {
      if (!apps) {
        return [];
      }

      return apps.map((app) => ({
        id: app.id,
        name: app.name,
      }));
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/redactor.scss';
</style>
