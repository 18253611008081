export default () => [
  {
    type: 'button',
    text: 'Добавить физическое лицо',
    variant: 'info',
    icon: 'fa-plus',
    class: 'mr-1',
    action: 'openCreateCustomerForm',
    permission: 'customers.edit',
  },
];
