export default {
  frequency_high_recency_low: 'В зоне риска',
  frequency_high_recency_normal: 'Почти фанаты',
  frequency_high_recency_high: 'Фанаты',
  frequency_normal_recency_low: 'Спящие',
  frequency_normal_recency_normal: 'Черепахи',
  frequency_normal_recency_high: 'Перспективные',
  frequency_low_recency_low: 'Потерянные',
  frequency_low_recency_normal: 'Требуют внимания',
  frequency_low_recency_high: 'Новенькие',
};

export const recencyTitles = {
  high: 'Были недавно',
  normal: 'Средняя',
  low: 'Были давно',
};

export const frequencyTitles = {
  high: 'Часто',
  normal: 'Средне',
  low: 'Редко',
};

export const monetaryTitles = {
  high: 'Высокая',
  normal: 'Средняя',
  low: 'Низкая',
};
