<template>
  <multiselect
    select-label=""
    track-by="id"
    label="name"
    tag-position="bottom"
    :value="localValue"
    :options="options"
    :placeholder="placeholder"
    :multiple="multiple"
    :searchable="searchable"
    :disabled="disabled"
    :loading="loading"
    :limit="limit"
    :show-labels="showLabels"
    :options-limit="optionsLimit"
    :limit-text="limitText"
    :internal-search="internalSearch"
    :clear-on-select="clearOnSelect"
    :close-on-select="closeOnSelect"
    :preselect-first="preselectFirst"
    :preserve-search="preserveSearch"
    :hide-selected="hideSelected"
    :show-no-results="showNoResults"
    @remove="remove"
    @select="select"
    @search-change="search"
  >
    <template v-if="$slots.tag" #tag="{ option, remove }">
      <slot name="tag" :option="option" :remove="remove" />
    </template>
    <template v-if="$slots.clear" #clear>
      <slot name="clear" />
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      default: () => [],
    },
    options: {
      default: () => [],
    },
    variant: {
      default: 'btn-outline',
    },
    disabled: {
      default: false,
    },
    searchable: {
      default: false,
    },
    loading: {
      default: false,
    },
    placeholder: {
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: true,
    },
    preselectFirst: {
      type: Boolean,
      default: false,
    },
    preserveSearch: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 99999,
    },
    optionsLimit: {
      default: 1000,
    },
    showLabels: {
      default: true,
    },
    internalSearch: {
      default: true,
    },
    showNoResults: {
      default: true,
    },
    limitText: {
      type: Function,
      default: (count) => `${count} и больше`,
    },
  },
  computed: {
    localValue() {
      return this.value.map((item) => this.options.find((option) => item.id === option.id));
    },
  },
  methods: {
    remove(value) {
      this.$emit(
        'change',
        this.value.filter((valueItem) => valueItem.id !== value.id),
      );
    },

    select(value) {
      this.$emit('change', [...this.value, value]);
    },

    search(value) {
      this.$emit('search', value);
    },
  },
};
</script>

<style>
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import '../../assets/multiselect.css';
</style>
