var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cinema-page',{scopedSlots:_vm._u([{key:"scroll",fn:function(ref){
var cinemaId = ref.cinemaId;
return [_c('div',[_c('collection-provider',{ref:"collection",attrs:{"collection":"targets","filter":{ cinemaId: cinemaId },"disable-fetching":!cinemaId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"item-actions":_vm.datatable.itemActions,"panel-actions":_vm.datatable.panelActions},on:{"item-action":function($event){return _vm.performItemAction($event)},"panel-action":function($event){return _vm.performPanelAction($event)}},scopedSlots:_vm._u([{key:"cell(date)",fn:function(data){return [_c('span',{staticClass:"mr-1"},[_vm._v("c "+_vm._s(_vm.$datetime.formatDate(data.item.dateStart)))]),_c('span',[_vm._v("по "+_vm._s(_vm.$datetime.formatDate(data.item.dateEnd)))])]}},{key:"cell(ticketsPricePercent)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.ticketsPricePercent)+"% ")]}},{key:"cell(ticketsCountPercent)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.ticketsCountPercent)+"% ")]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}],null,true)}),(_vm.modals.target.show)?_c('modal-model-form',{attrs:{"create-title":"Создание плановых показателей","edit-title":"Редактирование плановых показателей","collection":"targets","id":_vm.modals.target.id,"init":{ cinemaId: cinemaId }},on:{"close":function($event){_vm.modals.target.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('target-form',{attrs:{"cinema-id":cinemaId,"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,true)}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }