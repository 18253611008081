var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cinema-page',{attrs:{"route-name":"pushkinsCardEvents2","route-param-name":"cinemaId"},scopedSlots:_vm._u([{key:"scroll",fn:function(ref){
var cinemaId = ref.cinemaId;
return [_c('div',[_c('collection-provider',{ref:"collection",attrs:{"collection":"pushkinsCardEvents2","filter":{ cinemaId: cinemaId },"disable-fetching":!cinemaId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"item-actions":_vm.datatable.itemActions,"panel-actions":_vm.datatable.panelActions},on:{"item-action":function($event){return _vm.performItemAction($event)},"panel-action":function($event){return _vm.performPanelAction($event)}},scopedSlots:_vm._u([{key:"cell(movie)",fn:function(data){return [(data.item.movie)?_c('router-link',{attrs:{"id":("movie_" + (data.item.movie.id)),"to":{
                  name: 'movie2',
                  params: {
                    movieId: data.item.movie.id,
                    tab: 'info',
                  },
                },"target":"_blank"}},[_vm._v(" "+_vm._s(data.item.movie.name)+" ")]):_vm._e()]}},{key:"cell(name)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.openPushkinsCardEvent(data.item.id)}}},[_vm._v(_vm._s(data.item.name))])]}},{key:"cell(accepted)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.accepted ? 'Да' : 'Нет')+" ")]}},{key:"cell(periodStart)",fn:function(ref){
                var periodStart = ref.item.periodStart;
return [_vm._v(" "+_vm._s(periodStart && _vm.$datetime.formatDateTime(periodStart))+" ")]}},{key:"cell(periodEnd)",fn:function(ref){
                var periodEnd = ref.item.periodEnd;
return [_vm._v(" "+_vm._s(periodEnd && _vm.$datetime.formatDateTime(periodEnd))+" ")]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}],null,true)})],1),(_vm.modals.pushkinsCardEvent.show)?_c('model-provider',{attrs:{"collection":"pushkinsCardEvents2","id":_vm.modals.pushkinsCardEvent.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var data = ref.data;
return [_c('b-modal',{attrs:{"centered":"","title":"Периоды","visible":true,"ok-only":true},on:{"hidden":function($event){_vm.modals.pushkinsCardEvent.show = false}}},[_c('b-table',{staticClass:"datatable",attrs:{"empty-text":"Нет данных","items":data.item.periodItems,"fields":_vm.periodItemsColumns,"striped":true,"hover":true,"no-local-sorting":false,"show-empty":true},scopedSlots:_vm._u([{key:"cell(start)",fn:function(ref){
                var start = ref.item.start;
return [_vm._v(" "+_vm._s(start && _vm.$datetime.formatDateTime(start))+" ")]}},{key:"cell(end)",fn:function(ref){
                var end = ref.item.end;
return [_vm._v(" "+_vm._s(end && _vm.$datetime.formatDateTime(end))+" ")]}}],null,true)})],1)]}}],null,true)}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }