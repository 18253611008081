<template>
  <div class="p-3 border rounded">
    <div class="d-flex flex-column justify-content-between h-100">
      <div class="d-flex">
        <div class="w-50 p-1 d-flex flex-column">
          <div class="font-weight-bold mb-2">{{ title1 }}</div>
          <div>{{ getValue(data1) }}</div>
          <div
            :class="['percent', 'mt-1', getPercent(data1) > 0 ? 'text-success' : 'text-danger']"
          >{{ getSign(data1) }}{{ getPercent(data1) }}%</div>
        </div>
        <div class="w-50 p-1 text-center">
          <chart-fill
            :data="data1"
            :line-color="lineColor"
            :fill-color="fillColor"
          ></chart-fill>
        </div>
      </div>
      <div class="border-top my-1"></div>
      <div class="d-flex">
        <div class="w-50 p-1 d-flex flex-column">
          <div class="font-weight-bold mb-2">{{ title2 }}</div>
          <div>{{ getValue(data2) }}</div>
          <div
            :class="['percent', 'mt-1', getPercent(data2) > 0 ? 'text-success' : 'text-danger']"
          >{{ getSign(data2) }}{{ getPercent(data2) }}%</div>
        </div>
        <div class="w-50 p-1 text-center">
          <chart-fill
            :data="data2"
            :line-color="lineColor"
            :fill-color="fillColor"
          ></chart-fill>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartFill from '../../../components/charts/ChartFill.vue';

export default {
  components: {
    ChartFill,
  },
  props: {
    title1: {
      default: '',
    },
    title2: {
      default: '',
    },
    data1: {
      default: () => [],
    },
    data2: {
      default: () => [],
    },
    lineColor: {
      default: '#1A8CFF',
    },
    fillColor: {
      default: 'rgba(26, 141, 255, 0.2)',
    },
  },
  methods: {
    getValue(data) {
      if (!data.length) {
        return 0;
      }

      return data[data.length - 1].toLocaleString();
    },

    getPercent(data) {
      if (data.length < 2) {
        return 0;
      }

      const lastValue = data[data.length - 1];
      const prevValue = data[data.length - 2];

      const ratio = prevValue ? (lastValue - prevValue) / prevValue : 0;

      return (ratio * 100).toFixed(2);
    },

    getSign(data) {
      return this.getPercent(data) > 0 ? '+' : '';
    },
  },
};
</script>

<style scoped>
.percent {
  font-size: 0.9em;
}
.font-weight-bold {
  font-weight: 500 !important;
}
</style>
