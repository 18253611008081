import _factory from './_factory';
import collectionServiceFactory from '../../services/collectionServiceFactory';

const positionTagsService = collectionServiceFactory('positions/tags');

export default {
  ..._factory('tags', {
    actions: {
      async changePositions({ commit, dispatch }, { values }) {
        commit('setLoading', true);

        const [err, item] = await positionTagsService.createItem(values);

        if (!err) {
          await dispatch('fetchItems');
        }

        commit('setLoading', false);

        return [err, item];
      },
    },
  }),
};
