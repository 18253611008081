export default [
  // {
  //   key: 'id',
  //   type: 'text',
  //   label: '#',
  //   filterable: false,
  //   sortable: false,
  // },
  {
    key: 'eventId',
    type: 'text',
    label: 'Идентификатор события',
    sortable: false,
    filterable: false,
  },
  {
    key: 'movie',
    type: 'text',
    label: 'Фильм',
    sortable: false,
    filterable: false,
  },
  {
    key: 'periodStart',
    type: 'text',
    label: 'Начало периода',
    sortable: false,
    filterable: false,
  },
  {
    key: 'periodEnd',
    type: 'text',
    label: 'Окончание периода',
    sortable: false,
    filterable: false,
  },
];
