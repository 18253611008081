<template>
  <div class="d-flex">
    <b-button
      size="sm"
      variant="outline-secondary"
      :disabled="disabled"
      @click="$emit('add-screen')">Добавить экран</b-button>
    <b-button
      size="sm"
      class="ml-2"
      variant="outline-secondary"
      :disabled="disabled"
      @click="$emit('add-group')">Добавить группу</b-button>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      default: false,
    },
  },
};
</script>
