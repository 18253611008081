<template>
  <div
    class="value-range"
     :style="{'opacity': disabled ? 0.5 : 1}"
  >
    <div class="d-flex w-100">
      <div class="border-right w-1-3 py-1">
        <div
          v-if="!showLowValueInput"
          class="cursor-pointer text-primary"
          @click="!disabled && openLowValueInput()"
        >{{ inverted ? 'от' : 'до' }} {{ lowValue }}</div>
        <input
          v-else
          ref="lowValueInput"
          type="number"
          class="value-range-input"
          :value="lowValue"
          :disabled="disabled"
          @change="$emit('change-low-value', +$event.target.value)"
          @blur="showLowValueInput = false"
        >
      </div>
      <div class="border-right w-1-3 py-1">от {{ inverted ? highValue + 1 : lowValue + 1 }} до {{ inverted ? lowValue - 1 : highValue - 1 }}</div>
      <div class="w-1-3 py-1">
        <div
          v-if="!showHighValueInput"
          class="cursor-pointer text-primary"
          @click="!disabled && openHighValueInput()"
        >{{ inverted ? 'до' : 'от' }} {{ highValue }}</div>
        <input
          v-else
          ref="highValueInput"
          type="number"
          class="value-range-input"
          :value="highValue"
          :disabled="disabled"
          @change="$emit('change-high-value', +$event.target.value)"
          @blur="showHighValueInput = false"
        >
      </div>
    </div>
    <div class="d-flex w-100">
      <div class="bg-danger text-white w-1-3">{{ lowText }}</div>
      <div class="bg-warning text-white w-1-3">{{ normalText }}</div>
      <div class="bg-success text-white w-1-3">{{ highText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lowText: {
      default: 'Давно',
    },
    normalText: {
      default: 'Норма',
    },
    highText: {
      default: 'Недавно',
    },
    disabled: {
      default: false,
    },
    inverted: {
      default: false,
    },
    lowValue: {
      default: 0,
    },
    highValue: {
      default: 0,
    },
  },
  data() {
    return {
      showLowValueInput: false,
      showHighValueInput: false,
    };
  },
  methods: {
    async openLowValueInput() {
      this.showLowValueInput = true;
      await this.$nextTick();
      this.$refs.lowValueInput.focus();
    },

    async openHighValueInput() {
      this.showHighValueInput = true;
      await this.$nextTick();
      this.$refs.highValueInput.focus();
    },
  },
};
</script>

<style scoped>
.value-range {
  font-size: 12px;
  text-align: center;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
}
.w-1-3 {
  width: 33.333%;
}
.value-range-input {
  border: none;
  outline: none;
  width: 100%;
  height: 12px;
  line-height: 12px;
  text-align: center;
}
.value-range :focus {
  outline: none;
}
</style>
