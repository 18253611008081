<template>
  <div class="p-2">
    <b-form-group
      label="Период:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.dateStart']} ${errors['filter.dateEnd']}`"
      :state="states['filter.dateStart'] && states['filter.dateEnd']"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="filter.dateStart"
        :state="states['filter.dateStart']"
        :disabled="loading"
        @change="setFilter({ field: 'dateStart', value: $event }), setFilter({ field: 'releaseId', value: null })"
      ></date-popover>
      <date-popover
        size="sm"
        :value="filter.dateEnd"
        :state="states['filter.dateEnd']"
        :disabled="loading"
        @change="setFilter({ field: 'dateEnd', value: $event }), setFilter({ field: 'releaseId', value: null })"
      ></date-popover>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Выборка:"
      :disabled="loading"
      :invalid-feedback="errors['params.compareDatesBy']"
    >
      <b-form-radio-group
        name="compare-dates-by"
        :state="states['params.compareDatesBy']"
        :checked="params.compareDatesBy"
        :disabled="loading"
        @change="setParam({ field: 'compareDatesBy', value: $event })"
      >
        <b-form-radio value="created_at">По дате продажи</b-form-radio>
        <b-form-radio value="show_date">По дате сеанса</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Учет типа суток:"
      :disabled="loading"
      :invalid-feedback="errors['params.dayType']"
    >
      <b-form-radio-group
        name="day-type"
        :state="states['params.dayType']"
        :checked="params.dayType"
        :disabled="loading"
        @change="setParam({ field: 'dayType', value: $event })"
      >
        <b-form-radio value="business">Бизнес-сутки</b-form-radio>
        <b-form-radio value="calendar">Календарные</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Группы приложений:"
      :disabled="loading"
      :invalid-feedback="errors['filter.appGroupId']"
    >
      <dictionary-provider
        name="appGroups"
        :filter="{ online: 1 }"
      >
        <template #default="{ items: appGroupId, loading: appGroupsLoading }">
          <b-form-select
            size="sm"
            :options="$array.convertToOptions(appGroupId, null)"
            :value="localAppGroupId"
            :disabled="appGroupsLoading || loading"
            :state="states['filter.appGroupId']"
            @change="localAppGroupId = $event, setFilter({ field: 'appGroupId', value: $event })"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">-- Выберите группу --</b-form-select-option>
            </template>
          </b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Канал продаж:"
      :disabled="loading"
      :invalid-feedback="errors['filter.appId']"
      :state="states['filter.appId']"
    >
      <dictionary-provider
        name="apps"
        :filter="{ appGroupId: localAppGroupId }"
        :disable-fetching="!localAppGroupId"
      >
        <template #default="{ items: apps, loading: appsLoading }">
          <b-form-select
            size="sm"
            :options="$array.convertToOptions(apps, null)"
            :value="filter.appId"
            :disabled="appsLoading || loading || !localAppGroupId"
            :state="states['filter.appId']"
            @change="setFilter({ field: 'appId', value: $event })"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">-- Выберите канал --</b-form-select-option>
            </template>
          </b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Релиз:"
      :disabled="loading"
      :invalid-feedback="errors['filter.releaseId']"
    >
      <dictionary-provider
        :name="params.compareDatesBy == 'show_date' ? 'showsReleases' : 'salesReleases'"
        :filter="{ dateStart: filter.dateStart, dateEnd: filter.dateEnd }"
        :disable-fetching="!filter.dateStart || !filter.dateEnd"
      >
        <template #default="{ items: releases, loading: releasesLoading }">
          <b-form-select
            size="sm"
            :options="convertReleasesToOptions(releases)"
            :value="filter.releaseId"
            :disabled="releasesLoading || loading || (!filter.dateStart || !filter.dateEnd)"
            :state="states['filter.releaseId']"
            @change="setFilter({ field: 'releaseId', value: $event })"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">-- Выберите релиз --</b-form-select-option>
            </template>
          </b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="mt-2">
      <b-button
        variant="info"
        size="sm"
        :disabled="loading"
        @click="fetchReport({ format: 'html' })"
      >Посмотреть</b-button>
      <b-dropdown
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
      >
        <template #button-content>
          <icon icon="fa-save"></icon>
        </template>
        <b-dropdown-item
          v-for="format in formats"
          :key="format.value"
          @click="fetchReport({ format: format.value })"
        >{{format.text}}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DictionaryProvider from '../../../providers/DictionaryProvider';
import DatePopover from '../../../components/fields/DatePopover.vue';
import datetime from '../../../utils/datetime';

export default {
  components: {
    DictionaryProvider,
    DatePopover,
  },
  data() {
    return {
      localAppGroupId: null,
      format: 'xlsx',
      formats: [
        { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'XLSX' },
      ],
    };
  },
  computed: {
    ...mapState('reports/proceedsByCinemasByOnlineWithDetails', {
      params: (state) => state.params,
      filter: (state) => state.filter,
      loading: (state) => state.loading,
      errors: (state) => state.errors,
      states: (state) => state.states,
    }),
  },
  created() {
    const date = datetime.convertDateToDbFormat(new Date());

    this.setFilter({ field: 'releaseId', value: null });
    this.setFilter({ field: 'dateStart', value: date });
    this.setFilter({ field: 'dateEnd', value: date });
    this.setFilter({ field: 'appGroupId', value: null });
    this.setFilter({ field: 'appId', value: null });
    this.setParam({ field: 'compareDatesBy', value: 'show_date' });
    this.setParam({ field: 'dayType', value: 'business' });
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      setParam: 'reports/proceedsByCinemasByOnlineWithDetails/setParam',
      setFilter: 'reports/proceedsByCinemasByOnlineWithDetails/setFilter',
      fetchReport: 'reports/proceedsByCinemasByOnlineWithDetails/fetchReport',
      reset: 'reports/proceedsByCinemasByOnlineWithDetails/reset',
    }),

    convertReleasesToOptions(releases) {
      return releases.map((release) => ({
        value: release.id,
        text: `${release.movie.name} ${release.format.name}`,
      }));
    },
  },
};
</script>
