<template>
  <div class="border rounded">
    <div class="border-bottom text-center font-weight-semibold py-1">{{ title }}</div>

    <div
      v-for="(monetary, index) in ['high', 'normal', 'low']"
      class="d-flex align-items-center mx-3 py-2"
      :key="monetary"
      :class="{'border-top': index > 0}"
    >
      <b-checkbox
        :checked="isChecked(recency, frequency, monetary)"
        :disabled="disabled"
        @change="toggleChecked(recency, frequency, monetary)"
      ></b-checkbox>

      <img :src="require(`@/assets/analytics/rfm_recency_${recency}.svg`)">
      <img :src="require(`@/assets/analytics/rfm_frequency_${frequency}.svg`)">
      <img :src="require(`@/assets/analytics/rfm_monetary_${monetary}.svg`)">

      <div class="ml-3 mw-50">{{ getMainSegmentCount(monetary).toLocaleString() }}</div>

      <div
        class="ml-1 text-small"
        :class="{
          'text-success': getMainSegmentCount(monetary) > getOffsetSegmentCount(monetary),
          'text-danger': getMainSegmentCount(monetary) < getOffsetSegmentCount(monetary),
        }"
      >{{ formatDiff(getMainSegmentCount(monetary), getOffsetSegmentCount(monetary)) }}</div>

      <div class="ml-auto text-right">{{ getMainSegmentCountPercent(monetary) }}%</div>

      <div
        class="ml-1 mw-30 text-right text-small"
        :class="{
          'text-success': getMainSegmentCountPercent(monetary) > getOffsetSegmentCountPercent(monetary),
          'text-danger': getMainSegmentCountPercent(monetary) < getOffsetSegmentCountPercent(monetary),
        }"
      >{{ formatDiff(getMainSegmentCountPercent(monetary), getOffsetSegmentCountPercent(monetary), '%') }}</div>
    </div>

    <div class="d-flex border-top">
      <div class="w-50 border-right text-center py-2 d-flex justify-content-center">
        <div>{{ mainSegmentTotalCount.toLocaleString() }}</div>
        <div
          class="ml-3 text-small"
          :class="{
            'text-success': mainSegmentTotalCount > offsetSegmentTotalCount,
            'text-danger': mainSegmentTotalCount < offsetSegmentTotalCount,
          }"
        >{{ formatDiff(mainSegmentTotalCount, offsetSegmentTotalCount) }}</div>
      </div>

      <div class="ml-1 w-50 text-center py-2 d-flex justify-content-center">
        <div>{{ mainSegmentCountPercent }}%</div>
        <div
          class="ml-3 text-small"
          :class="{
            'text-success': mainSegmentCountPercent > offsetSegmentCountPercent,
            'text-danger': mainSegmentCountPercent < offsetSegmentCountPercent,
          }"
        >{{ formatDiff(mainSegmentCountPercent, offsetSegmentCountPercent, '%') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const COLUMN_COUNT = 'count(customer_id)';

export default {
  props: {
    title: {
      default: '',
    },
    checkedItems: {
      default: () => [],
    },
    recency: {
      default: 'low',
    },
    frequency: {
      default: 'low',
    },
    mainItems: {
      default: () => [],
    },
    offsetItems: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    mainTotalCount() {
      return this.mainItems.reduce((total, current) => total + +current[COLUMN_COUNT], 0);
    },

    offsetTotalCount() {
      return this.offsetItems.reduce((total, current) => total + +current[COLUMN_COUNT], 0);
    },

    mainSegmentItems() {
      return this.mainItems.filter((item) => item.recency === this.recency && item.frequency === this.frequency);
    },

    offsetSegmentItems() {
      return this.offsetItems.filter((item) => item.recency === this.recency && item.frequency === this.frequency);
    },

    mainSegmentTotalCount() {
      return this.mainSegmentItems.reduce((total, current) => total + +current[COLUMN_COUNT], 0);
    },

    offsetSegmentTotalCount() {
      return this.offsetSegmentItems.reduce((total, current) => total + +current[COLUMN_COUNT], 0);
    },

    mainSegmentCountPercent() {
      return this.mainTotalCount ? Math.round(100 * (this.mainSegmentTotalCount / this.mainTotalCount)) : 0;
    },

    offsetSegmentCountPercent() {
      return this.offsetTotalCount ? Math.round(100 * (this.offsetSegmentTotalCount / this.offsetTotalCount)) : 0;
    },
  },
  methods: {
    getMainSegmentCount(monetary) {
      return +this.mainSegmentItems.find((item) => item.monetary === monetary)?.[COLUMN_COUNT] || 0;
    },

    getOffsetSegmentCount(monetary) {
      return +this.offsetSegmentItems.find((item) => item.monetary === monetary)?.[COLUMN_COUNT] || 0;
    },

    getMainSegmentCountPercent(monetary) {
      return this.mainTotalCount ? Math.round(100 * (this.getMainSegmentCount(monetary) / this.mainTotalCount)) : 0;
    },

    getOffsetSegmentCountPercent(monetary) {
      return this.offsetTotalCount ? Math.round(100 * (this.getOffsetSegmentCount(monetary) / this.offsetTotalCount)) : 0;
    },

    formatDiff(value1, value2, postfix = '') {
      const diff = Math.abs(value1 - value2);

      if (value1 > value2) {
        return `+${diff.toLocaleString()}${postfix}`;
      } if (value1 < value2) {
        return `-${diff.toLocaleString()}${postfix}`;
      }

      return '';
    },

    isChecked(recency, frequency, monetary) {
      return !!this.checkedItems.find((item) => item.recency === recency && item.frequency === frequency && item.monetary === monetary);
    },

    toggleChecked(recency, frequency, monetary) {
      const copy = [...this.checkedItems];
      const index = copy.findIndex((item) => item.recency === recency && item.frequency === frequency && item.monetary === monetary);

      if (index > -1) {
        copy.splice(index, 1);
      } else {
        copy.push({ recency, frequency, monetary });
      }

      this.$emit('change', copy);
    },
  },
};
</script>

<style scoped>
.font-weight-semibold {
  font-weight: 500;
}
.text-small {
  font-size: 14px;
}

.mw-30 {
  min-width: 30px;
}

.mw-50 {
  min-width: 50px;
}
</style>
