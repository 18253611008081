/* eslint-disable guard-for-in */
/* eslint-disable no-continue */
import AnalyticsService from '../services/Analytics';
import datetime from '../utils/datetime';

const TABLE_SALES = 'iboe_sales';
const COLUMN_SUM_PRICE = 'sum(total_price)';
const COLUMN_SUM_COUNT = 'sum(total_count)';
const COLUMN_CINEMA_ID = 'iboe_cinema_id';
const COLUMN_DATE = 'date';

export default {
  props: {
    marketCinemas: {
      default: () => ({}),
    },
    targetCinemaIds: {
      default: () => [],
    },
    periods: {
      default: () => [],
    },
    date: {
      default: '',
    },
    referenceInterval: {
      default: 3,
    },
  },
  render() {
    return this.$scopedSlots.default({
      data: {
        analyticsData: this.analyticsData,
        marketData: this.marketData,
        cinemasData: this.cinemasData,
        loading: this.loading,
        referencePeriod: this.referencePeriod,
      },
    });
  },
  data() {
    return {
      analyticsData: {},
      loading: false,
    };
  },
  computed: {
    referencePeriod() {
      const dateEnd = new Date(this.date);
      const dateStart = datetime.subtractMonthes(dateEnd, this.referenceInterval);

      return {
        dateStart: datetime.convertDateToDbFormat(dateStart),
        dateEnd: datetime.convertDateToDbFormat(dateEnd),
      };
    },

    extendedPeriods() {
      return [
        ...this.periods,
        this.referencePeriod,
      ];
    },

    watchedValues() {
      let marketCinemaIds = [];

      for (const cinemaId in this.marketCinemas) {
        marketCinemaIds = [...marketCinemaIds, ...this.marketCinemas[cinemaId]];
      }

      return [...this.targetCinemaIds, ...marketCinemaIds, ...this.extendedPeriods.map(({ dateStart, dateEnd }) => `${dateStart}_${dateEnd}`)].join();
    },

    marketData() {
      const result = {};

      for (const cinemaId in this.marketCinemas) {
        result[cinemaId] = this.extendedPeriods.map((period) => {
          const { dateStart, dateEnd } = period;
          const key = this.getAnalyticsKey(`market_${cinemaId}`, dateStart, dateEnd);
          const data = this.analyticsData[key];

          return {
            ...period,
            price: data?.length ? data[0][COLUMN_SUM_PRICE] : 0,
            count: data?.length ? data[0][COLUMN_SUM_COUNT] : 0,
          };
        });
      }

      return result;
    },

    cinemasData() {
      const result = {};

      for (const cinemaId of this.targetCinemaIds) {
        result[cinemaId] = this.extendedPeriods.map((period) => {
          const { dateStart, dateEnd } = period;
          const key = this.getAnalyticsKey('cinemas', dateStart, dateEnd);
          const data = this.analyticsData[key] || [];
          const row = data.find((_row) => +_row[COLUMN_CINEMA_ID] === cinemaId);

          return {
            ...period,
            price: row ? row[COLUMN_SUM_PRICE] : 0,
            count: row ? row[COLUMN_SUM_COUNT] : 0,
          };
        });
      }

      return result;
    },
  },
  watch: {
    watchedValues: {
      handler() {
        this.fetchAnalyticsData();
      },
      deep: true,
    },
  },
  methods: {
    getAnalyticsKey(key, dateStart, dateEnd) {
      return `${key}_${dateStart}_${dateEnd}`;
    },

    async fetchAnalyticsData() {
      this.analyticsData = {};

      const queries = [];

      for (let i = 0; i < this.extendedPeriods.length; i += 1) {
        const { dateStart, dateEnd } = this.extendedPeriods[i];

        for (const cinemaId in this.marketCinemas) {
          const marketCinemaIds = this.marketCinemas[cinemaId];

          if (!marketCinemaIds.length) {
            continue;
          }

          queries.push({
            id: this.getAnalyticsKey(`market_${cinemaId}`, dateStart, dateEnd),
            table: TABLE_SALES,
            select: [COLUMN_SUM_PRICE, COLUMN_SUM_COUNT],
            where: {
              [COLUMN_CINEMA_ID]: marketCinemaIds,
              [COLUMN_DATE]: {
                gte: dateStart,
                lte: dateEnd,
              },
            },
          });
        }

        if (!this.targetCinemaIds.length) {
          continue;
        }

        queries.push({
          id: this.getAnalyticsKey('cinemas', dateStart, dateEnd),
          table: TABLE_SALES,
          select: [COLUMN_CINEMA_ID, COLUMN_SUM_PRICE, COLUMN_SUM_COUNT],
          where: {
            [COLUMN_CINEMA_ID]: this.targetCinemaIds,
            [COLUMN_DATE]: {
              gte: dateStart,
              lte: dateEnd,
            },
          },
          group_by: [COLUMN_CINEMA_ID],
        });
      }

      if (!queries.length) {
        return;
      }

      this.loading = true;

      const [, result] = await AnalyticsService.fetch(queries);

      this.loading = false;
      this.analyticsData = result;
    },
  },
};
