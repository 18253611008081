/* eslint-disable no-shadow */
import Vue from 'vue';
import axios from 'axios';
import http from '../api/http';

const plugin = {
  http: {},

  $eventHub: new Vue(),

  $on(event, callback) {
    this.$eventHub.$on(event, callback);
  },

  $off(event) {
    this.$eventHub.$off(event);
  },

  $emit(event, payload) {
    this.$eventHub.$emit(event, payload);
  },

  addHttpInterceptor() {
    http.interceptors.response.use(undefined, (err) => {
      if (err instanceof axios.Cancel) {
        return Promise.resolve();
      }

      this.$emit('error', err);

      return Promise.reject(err);
    });
  },

  async init() {
    this.addHttpInterceptor();

    this.http = http;
  },
};

export default {
  install(Vue) {
    plugin.init();

    Vue.mixin({
      beforeCreate() {
        this.$http = plugin;
      },
    });
  },
};
