<template>
  <div
    v-if="releases && releases.length"
    class="repertory-cinema"
  >
    <div class="repertory-cinema__cinema mb-3">{{cinema.name}}, {{cinema.city.name}}, залов: {{cinema.halls.length}}</div>
    <div class="d-flex mb-2">
      <span class="mr-1">Старт проката:</span>
      <span class="font-weight-bolder mr-1">{{ $datetime.formatDate(movie.dateStart) }}</span>
      <template v-if="movie.datePreview">
        <span class="mr-1">Предпоказ:</span>
        <span class="font-weight-bolder">{{ $datetime.formatDate(movie.datePreview) }}</span>
      </template>
    </div>
    <div class="d-flex mb-2">
      <div>
        <span v-if="releases && releases.length" class="mr-1">Период проката</span>
        <span v-if="!(releases && releases.length)" class="mr-1">Нет избранных форматов</span>
        <date-range
          class="mr-1"
          :date-start="dateStart"
          :date-end="dateEnd"
          :disabled="loading"
          @change="changeDates($event)"
        ></date-range>
      </div>
      <b-button
        v-if="repertoryItems.length || (dateStart && dateEnd)"
        variant="outline-danger"
        size="sm"
        class="ml-auto mr-1"
        :disabled="loading"
        @click="remove()"
      >Удалить</b-button>
    </div>
    <repertory-cinema-items
      :loading="loading"
      :releases="releases"
      :repertory-items="repertoryItems"
      :repertory-item-statuses="repertoryItemStatuses"
      :date-start="dateStart"
      :date-end="dateEnd"
      @prepend-week="prependWeek()"
      @append-week="appendWeek()"
      @remove-week="removeWeek($event)"
      @change-status="changeStatus($event)"
      @change-count="changeCount($event)"
    ></repertory-cinema-items>
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */

import { confirmDialog } from '../../utils/dialogs';

import RepertoryCinemaItems from './RepertoryCinemaItems.vue';
import DateRange from '../../components/fields/DateRange.vue';
import RepertoryItemDates from '../repertory/RepertoryItemDates';

export default {
  components: {
    RepertoryCinemaItems,
    DateRange,
  },
  props: {
    cinema: {
      default: () => ({}),
    },
    repertoryItems: {
      default: () => [],
    },
    repertoryItemStatuses: {
      default: () => [],
    },
    releases: {
      default: () => [],
    },
    movie: {
      default: () => [],
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
    };
  },
  computed: {
    repertoryItemsDateStart() {
      return this.repertoryItems
        .reduce((date, item) => (date && this.$datetime.isDateBefore(date, item.dateStart) ? date : item.dateStart), null);
    },

    repertoryItemsDateEnd() {
      return this.repertoryItems
        .reduce((date, item) => (date && this.$datetime.isDateAfter(date, item.dateEnd) ? date : item.dateEnd), null);
    },
  },
  watch: {
    repertoryItems: {
      handler() {
        this.dateStart = this.repertoryItemsDateStart;
        this.dateEnd = this.repertoryItemsDateEnd;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    changeDates({ dateStart, dateEnd }) {
      this.dateStart = dateStart;
      this.dateEnd = dateEnd;

      this.changeRepertoryItemDates();
    },

    async remove() {
      if (!await confirmDialog('Удалить?')) {
        return;
      }

      if (this.repertoryItems.length) {
        this.$emit('remove-items', this.repertoryItems.map((item) => item.id));
      }

      this.dateStart = null;
      this.dateEnd = null;
    },

    prependWeek() {
      this.dateStart = this.$datetime.subtractDays(this.dateStart, 7);

      this.changeRepertoryItemDates();
    },

    appendWeek() {
      this.dateEnd = this.$datetime.addDays(this.dateEnd, 7);

      this.changeRepertoryItemDates();
    },

    changeRepertoryItemDates() {
      const items = [];

      for (const release of this.releases) {
        const releaseId = release.id;
        const repertoryItem = this.repertoryItems.find((_item) => _item.releaseId === releaseId);

        if (!repertoryItem) {
          continue;
        }

        const { repertoryItemStatusId } = repertoryItem;
        const id = repertoryItem?.id;

        const dates = new RepertoryItemDates(repertoryItem);

        dates.changeDateStart(this.dateStart);
        dates.changeDateEnd(this.dateEnd);

        items.push(this.changeRepertoryItem(dates, id, releaseId, repertoryItemStatusId));
      }

      if (items.length) {
        this.$emit('change-items', items);
      } else {
        this.createdWeeks();
      }
    },

    removeWeek(index) {
      const items = [];

      for (const release of this.releases) {
        const releaseId = release.id;
        const repertoryItem = this.repertoryItems.find((_item) => _item.releaseId === releaseId);
        const { repertoryItemStatusId } = repertoryItem;
        const id = repertoryItem?.id;

        const dates = new RepertoryItemDates(repertoryItem);

        dates.changeDateStart(this.dateStart);
        dates.changeDateEnd(this.dateEnd);
        dates.removeWeek(index);

        items.push(this.changeRepertoryItem(dates, id, releaseId, repertoryItemStatusId));
      }

      if (items.length) {
        this.$emit('change-items', items);
      }
    },

    createdWeeks() {
      for (const release of this.releases) {
        const releaseId = release.id;
        const repertoryItem = this.repertoryItems.find((_item) => _item.releaseId === release.id);
        const repertoryItemStatusId = repertoryItem?.repertoryItemStatusId || this.repertoryItemStatuses[0].id;
        const id = repertoryItem?.id;

        const dates = new RepertoryItemDates(repertoryItem);

        dates.changeDateStart(this.dateStart);
        dates.changeDateEnd(this.dateEnd);

        this.$emit('change-items', [this.changeRepertoryItem(dates, id, releaseId, repertoryItemStatusId)]);
      }
    },

    changeStatus({ releaseId, statusId }) {
      const repertoryItem = this.repertoryItems.find((_item) => _item.releaseId === releaseId);
      const id = repertoryItem?.id;

      const dates = new RepertoryItemDates(repertoryItem);

      dates.changeDateStart(this.dateStart);
      dates.changeDateEnd(this.dateEnd);

      this.$emit('change-items', [this.changeRepertoryItem(dates, id, releaseId, statusId)]);
    },

    changeCount({ index, releaseId, count }) {
      const repertoryItem = this.repertoryItems.find((_item) => _item.releaseId === releaseId);
      const repertoryItemStatusId = repertoryItem?.repertoryItemStatusId || this.repertoryItemStatuses[0].id;
      const id = repertoryItem?.id;

      const dates = new RepertoryItemDates(repertoryItem);

      dates.changeDateStart(this.dateStart);
      dates.changeDateEnd(this.dateEnd);
      dates.changeCount(index, count);

      this.$emit('change-items', [this.changeRepertoryItem(dates, id, releaseId, repertoryItemStatusId)]);
    },

    changeRepertoryItem(dates, id, releaseId, repertoryItemStatusId) {
      return {
        id,
        releaseId,
        dateStart: this.$datetime.convertDateToDbFormat(dates.dateStart),
        dateEnd: this.$datetime.convertDateToDbFormat(dates.dateEnd),
        weeks: dates.weeks,
        count: dates.getCount(),
        repertoryItemStatusId,
      };
    },
  },
};
</script>

<style scoped>
.repertory-cinema__cinema {
  font-size: 20px;
  font-weight: 400;
  vertical-align: middle;
  line-height: 30px;
  min-width: 470px;
}
.repertory-cinema >>> .nav-link {
  padding: 0.25rem 0.5rem;
}
</style>
