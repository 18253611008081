<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Название:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Юр. лицо:"
          :invalid-feedback="errors.legalEntityId"
          :state="states.legalEntityId"
        >
          <dictionary-provider name="legalEntities">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.legalEntityId"
                :options="$array.convertToOptions(items)"
                :state="states.legalEntityId"
                :disabled="loading"
                @change="updateValue('legalEntityId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Цена:"
          :invalid-feedback="errors.price"
          :state="states.price"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.price"
            :state="states.price"
            :disabled="loading"
            @change="updateValue('price', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="НДС:"
          :invalid-feedback="errors.vat"
          :state="states.vat"
        >
          <dictionary-provider name="vats">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.vat"
                :options="$array.convertToOptions(items, { value: null, text: 'Без НДС' })"
                :state="states.vat"
                :disabled="loading"
                @change="updateValue('vat', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Группа для отображения:"
          :invalid-feedback="errors.productGroupId"
          :state="states.productGroupId"
        >
          <dictionary-provider
            name="productGroups"
            :filter="{ cinemaId }"
          >
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.productGroupId"
                :options="$array.convertToOptions(items)"
                :state="states.productGroupId"
                :disabled="loading"
                @change="updateValue('productGroupId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Группа для отчетов:"
          :invalid-feedback="errors.productReportGroupId"
          :state="states.productReportGroupId"
        >
          <dictionary-provider
            name="productReportGroups"
            :filter="{ cinemaId }"
            :auto-reset="false"
          >
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.productReportGroupId"
                :options="$array.convertToOptions(items)"
                :state="states.productReportGroupId"
                :disabled="loading"
                @change="updateValue('productReportGroupId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Признак товара:"
          :invalid-feedback="errors.paymentSubjectId"
          :state="states.paymentSubjectId"
        >
          <dictionary-provider name="paymentSubjects">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.paymentSubjectId"
                :options="$array.convertToOptions(items)"
                :state="states.paymentSubjectId"
                :disabled="loading"
                @change="updateValue('paymentSubjectId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Метод оплаты:"
          :invalid-feedback="errors.paymentMethodId"
          :state="states.paymentMethodId"
        >
          <dictionary-provider name="paymentMethods">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.paymentMethodId"
                :options="$array.convertToOptions(items)"
                :state="states.paymentMethodId"
                :disabled="loading"
                @change="updateValue('paymentMethodId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Продажа по агентскому договору:"
          :invalid-feedback="errors.agentLegalEntityId"
          :state="states.agentLegalEntityId"
        >
          <dictionary-provider name="legalEntities">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.agentLegalEntityId"
                :options="$array.convertToOptions(items)"
                :state="states.agentLegalEntityId"
                :disabled="loading"
                @change="updateValue('agentLegalEntityId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          :invalid-feedback="errors.offerWithTicket"
          :state="states.offerWithTicket"
        >
          <b-form-checkbox
            :checked="item.offerWithTicket"
            :state="states.offerWithTicket"
            :disabled="loading"
            @change="updateValue('offerWithTicket', !item.offerWithTicket)"
          >
            Предлагать с билетами
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <template>
      <b-row>
        <b-col>
          <b-form-group
            label="Группы приложений:"
            :invalid-feedback="errors.appGroups"
            :state="states.appGroups"
          >
            <dictionary-provider name="appGroups">
              <template #default="{ items: appGroups }">
                <toggle-list
                  placeholder="Все"
                  :options="appGroups"
                  :value="item.appGroups"
                  :disabled="loading"
                  @change="updateValue('appGroups', $event)"
                ></toggle-list>
              </template>
            </dictionary-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Форматы фильмов:"
            :invalid-feedback="errors.formats"
            :state="states.formats"
          >
            <dictionary-provider name="formats2">
              <template #default="{ items }">
                <toggle-list
                  placeholder="Все"
                  :options="items"
                  :value="item.formats"
                  :disabled="loading"
                  @change="updateValue('formats', $event)"
                ></toggle-list>
              </template>
            </dictionary-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Возрастное ограничение фильма:"
            :invalid-feedback="errors.ageLimit"
            :state="states.ageLimit"
          >
            <dictionary-provider name="ageLimits">
              <template #default="{ items }">
                <toggle-list
                  placeholder="Все"
                  :options="items"
                  :value="item.ageLimits"
                  :disabled="loading"
                  @change="updateValue('ageLimits', $event)"
                ></toggle-list>
              </template>
            </dictionary-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
  },
  props: {
    cinemaId: {
      default: null,
    },
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
