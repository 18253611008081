import _factory from './_factory';

import collectionServiceFactory from '../../services/collectionServiceFactory';

const dcpKeysService = collectionServiceFactory('dcpKeys');

export default {
  ..._factory(
    'dcpKeys',
    {
      actions: {
        async attach({ commit }, { id, movieId }) {
          commit('setLoading', true);

          const [err, data] = await dcpKeysService.execItemAction(id, 'attach', { movieId });

          commit('setLoading', false);

          return [err, data];
        },
      },
    },
    dcpKeysService,
  ),
};
