<template>
  <b-dropdown
    :size="size"
    :variant="variant"
    :disabled="disabled"
    no-caret
  >
    <template #button-content>
      <template v-if="icon"><icon :icon="icon"></icon>&nbsp;</template>{{text}}
    </template>
    <template v-for="(item, index) in items">
      <b-dropdown-header
        v-if="item.type === 'header'"
        :key="index"
        :size="size"
      >{{item.text}}</b-dropdown-header>
      <b-dropdown-item-button
        v-if="item.type === 'button'"
        :key="index"
        :size="size"
        :disabled="!!item.disabled"
        @click="item.action && $emit('click', item.action, item.args)"
      ><template v-if="item.icon"><icon :icon="item.icon"></icon>&nbsp;</template>{{item.text}}</b-dropdown-item-button>
      <b-dropdown-divider
        v-if="item.type === 'divider'"
        :key="index"
      ></b-dropdown-divider>
    </template>
  </b-dropdown>
</template>

<script>

export default {
  name: 'actions-dropdown',
  props: {
    variant: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
