<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Изменение баланса:"
          :invalid-feedback="errors.balance"
          :state="states.balance"
        >
          <price-formula
            :value="item.balance"
            :operations="['-', '+', '=']"
            :disabled="loading"
            @change="updateValue('balance', $event)"
          ></price-formula>
        </b-form-group>

        <b-form-group
          label="Описание:"
          :invalid-feedback="errors.description"
          :state="states.description"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.description"
            :state="states.description"
            :disabled="loading"
            @change="updateValue('description', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PriceFormula from '../../components/fields/PriceFormula.vue';

export default {
  components: {
    PriceFormula,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
