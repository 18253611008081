export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    sortable: false,
    filterable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'ФИО',
    sortable: true,
    filterable: true,
  },
  {
    key: 'positionId',
    type: 'select',
    label: 'Должность',
    sortable: false,
    filterable: true,
  },
  {
    key: 'phone',
    type: 'text',
    label: 'Телефон',
    sortable: false,
    filterable: true,
  },
];
