export default [
  {
    key: 'createdAt',
    type: 'text',
    label: 'Дата операции',
    sortable: false,
    filterable: false,
  },
  {
    key: 'text',
    type: 'text',
    label: 'Кинотеатр / Фильм / Дата и время сеанса',
    sortable: false,
    filterable: false,
  },
  {
    key: 'orderId',
    type: 'text',
    label: 'Заказ',
    sortable: false,
    filterable: false,
  },
];
