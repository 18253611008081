<template>
  <collection-provider
    collection="reportsRentrak"
    ref="collection"
    sort-by="id"
    :sort-desc="true"
    :filter="{ cinemaId, periodStart, periodEnd }"
    :disable-fetching="!cinemaId"
  >
    <template #default="{ data, actions }">
      <datatable
        v-bind="{ ...data, ...actions }"
        :fields="datatable.fields"
      >
        <template #cell(name)="data">
          <a class="text-primary cursor-pointer" @click="download(data.item)">{{ data.item.name }}</a>
        </template>
        <template #cell(createdAt)="data">
          {{ $datetime.formatDateTime(data.item.createdAt) }}
        </template>
        <template #cell(deliveredAt)="data">
          {{ data.item.deliveredAt ?  $datetime.formatDateTime(data.item.deliveredAt) : 'Файл пока не отправлен' }}
        </template>
        <template #cell(type)="data">
          {{ getTypeName(data.item.type) }}
        </template>
      </datatable>
    </template>
  </collection-provider>
</template>

<script>
import CollectionProvider from '../../../providers/CollectionProvider';
import Datatable from '../../../components/datatable/Datatable.vue';
import { downloadFileBlob } from '../../../api/file';
import { saveFile } from '../../../utils/file';

import fields from './_index.fields';

export default {
  components: {
    CollectionProvider,
    Datatable,
  },
  props: {
    periodStart: {
      default: null,
    },
    periodEnd: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        fields,
      },
      types: [
        {
          id: 1,
          name: 'Почасовой',
        },
        {
          id: 2,
          name: 'Еженедельный',
        },
      ],
    };
  },
  computed: {
    cinemaId() {
      return this.$store.state.cinemaId;
    },
  },
  methods: {
    async download({ id, name }) {
      const { blob, type } = await downloadFileBlob(`reports/rentrak/${id}`);
      saveFile({ blob, type, name });
    },

    getTypeName(id) {
      return this.types.find((item) => item.id === id)?.name;
    },
  },
};
</script>
