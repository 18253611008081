<template>
  <div class="d-flex">
    <b-select
      size="sm"
      class="w-25"
      :options="cinemasAsOptions"
      :value="cinemaId"
      :disabled="disabled"
      @change="$emit('change-cinema', $event)"
    ></b-select>

    <b-button
      v-if="unknownDcpKeysCount"
      size="sm"
      variant="outline-danger"
      class="ml-auto"
      :disabled="disabled"
      @click="$emit('show-unknown-dcp-keys')"
    ><icon icon="fa-exclamation-triangle"></icon> Нераспознанные ключи: {{ unknownDcpKeysCount }}</b-button>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      default: false,
    },
    cinemas: {
      default: () => [],
    },
    cinemaId: {
      default: null,
    },
    unknownDcpKeysCount: {
      default: 0,
    },
  },
  computed: {
    cinemasAsOptions() {
      return [
        {
          value: null,
          text: 'Все кинотеатры',
        },
        ...this.cinemas.map((cinema) => ({
          value: cinema.id,
          text: `${cinema?.name}, ${cinema?.city?.name}`,
        })),
      ];
    },
  },
};
</script>

<style scoped>
.dcp-keys-tabs >>> .nav-link {
  padding: 0.25rem 0.5rem;
}
.dcp-keys-tabs >>> .text-danger a:not(.active) {
  color: #dc3545 !important;
}
</style>
