<template>
  <div v-if="isVissible" class="overflow-hidden rounded shadow load-bar">
    <div class="d-flex align-items-center text-white py-3 pr-2 pl-3 load-bar__header" :class="classHeader">
      <div class="flex-fill d-flex align-items-center">
        {{ titleHeader }}
      </div>
      <div
        class="p-1 cursor-pointer load-bar__btn-close"
        @click="$emit('hide')"
      >
        <icon icon="mdi-close"></icon>
      </div>
    </div>
    <div
      v-if="this.processUploadFile > 0"
      class="px-3 py-2 bg-light d-flex justify-content-end load-bar__cancel-items">
      <div
        class="cursor-pointer text-uppercase text-primary"
        @click="cancelItems"
      >
        Отмена
      </div>
    </div>

    <transition-group
      name="loading-hide"
      tag="div"
      class="bg-white load-bar__items"
    >
      <div class="load-bar__item mw-100" v-for="item in items" :key="item.id">
        <div class="pl-3 pr-1 py-2 d-flex align-items-center">
          <div class="mr-3 text-truncate load-bar__name">
            {{ item.file.name }}
          </div>

          <div class="text-nowrap">
            <div v-if="!item.error" class="text-uppercase">
              {{ convertSize(item.progress) }} /
              {{ convertSize(item.file.size) }}
            </div>
            <div v-else class="text-secondary">
              {{ item.error }}
            </div>
          </div>

          <div
            v-if="!item.error && item.loaded"
            class="load-bar__actions">
            <div class="load-bar__status">
              <icon
                class="load-bar__action-icon text-success"
                icon="mdi-check-circle"
              ></icon>
            </div>
          </div>
          <div
            v-else-if="!item.error && !item.loaded"
            class="load-bar__actions load-bar__actions-hovered"
          >
            <div class="load-bar__status">
              <div
                class="ko-progress-circle"
                :data-progress="dataProgress(item.progress, item.file.size)"
              >
                <div class="ko-circle">
                  <div class="full ko-progress-circle__slice">
                    <div class="ko-progress-circle__fill"></div>
                  </div>
                  <div class="ko-progress-circle__slice">
                    <div class="ko-progress-circle__fill"></div>
                    <div
                      class="ko-progress-circle__fill ko-progress-circle__bar"
                    ></div>
                  </div>
                </div>
                <div class="ko-progress-circle__overlay"></div>
              </div>
            </div>
            <div
              class="load-bar__action"
              @click="item.onCancel()"
            >
              <icon
                class="load-bar__action-icon text-dark"
                icon="mdi-close-circle"
              ></icon>
            </div>
          </div>
          <div
            v-else-if="item.error"
            class="load-bar__actions load-bar__actions-hovered"
          >
            <div class="load-bar__status">
              <icon
                class="load-bar__action-icon text-danger"
                icon="mdi-alert-circle"
              ></icon>
            </div>
            <div
              class="load-bar__action"
              @click="$emit('reload-file', { id: item.id, file: item.file })"
            >
              <icon
                class="load-bar__action-icon text-dark"
                icon="mdi-reload"
              ></icon>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>
<script>
import { convertFileSize } from '../../utils/file';
import declOfNum from '../../utils/declOfNum';

export default {
  data() {
    return {
      showCencelIcon: true,
      isVissible: false,
    };
  },
  props: {
    items: {
      default: () => [],
    },
  },
  computed: {
    allItemsUpload() {
      return this.items.every((item) => item.loaded);
    },

    errorUploadFile() {
      return this.items.filter((item) => item.error).length;
    },

    processUploadFile() {
      return this.items.filter((item) => !item.loaded && !item.error).length;
    },

    classHeader() {
      if (this.processUploadFile > 0 || this.errorUploadFile > 0) {
        return 'bg-dark';
      }

      return 'bg-success';
    },

    countLoadedFile() {
      return this.items.filter((item) => item.loaded && !item.error).length;
    },

    titleHeader() {
      if (this.processUploadFile > 0) {
        return `Загружается ${this.processUploadFile} ${this.getDeclOfNum(this.processUploadFile, [
          'файл',
          'файла',
          'файлов',
        ])}`;
      }

      if (this.countLoadedFile > 0) {
        return `${this.getDeclOfNum(this.countLoadedFile, [
          'Загружен',
          'Загружено',
          'Загружено',
        ])} ${this.countLoadedFile} ${this.getDeclOfNum(this.countLoadedFile, [
          'файл',
          'файла',
          'файлов',
        ])}`;
      }

      return 'Загрузка завершена';
    },
  },
  watch: {
    items: {
      handler(values) {
        if (values.length > 0) {
          this.isVissible = true;

          return;
        }

        this.isVissible = false;
      },
      immediate: true,
    },
  },
  methods: {
    dataProgress(progress, total) {
      return Math.floor((progress * 100) / total);
    },

    convertSize(size) {
      return convertFileSize(size);
    },

    getDeclOfNum(n, titles) {
      return declOfNum(n, titles);
    },

    cancelItems() {
      this.items.map((item) => {
        item.onCancel();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.load-bar {
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: 400px;
  z-index: 9999;

  &__name {
    flex: 1;
  }

  &__action-icon {
    font-size: 25px;
  }

  &__success {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__status {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__action {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__actions {
    width: 40px;
    height: 45px;
    position: relative;

    &-hovered {
      &:hover {
        .load-bar__status {
          opacity: 0;
          pointer-events: none;
        }

        .load-bar__action {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }

  &__item {
    max-width: 400px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #dee2e6;
    }
  }

  &__items {
    max-height: 400px;
    overflow-y: auto;
  }

  &__cancel-items {
    border-bottom: 1px solid #dee2e6;
  }
}

.loading-hide-leave-active {
  transition: all 0.5s ease 0.5s;
}

.loading-hide-enter {
  opacity: 0;
  transition: all 0.5s;
}

.loading-hide-leave-to {
  opacity: 0;
}

$circle-size: 20px;
$circle-background: #d9d9d9;
$circle-color: #212529;
$inset-size: 14px;
$inset-color: #fbfbfb;
$transition-length: 1s;

.ko-progress-circle {
  width: $circle-size;
  height: $circle-size;

  background-color: $circle-background;
  border-radius: 50%;
  .ko-progress-circle__slice,
  .ko-progress-circle__fill {
    width: $circle-size;
    height: $circle-size;
    position: absolute;
    -webkit-backface-visibility: hidden;
    transition: transform $transition-length;
    border-radius: 50%;
  }
  .ko-progress-circle__slice {
    clip: rect(0px, $circle-size, $circle-size, $circle-size/2);
    .ko-progress-circle__fill {
      clip: rect(0px, $circle-size/2, $circle-size, 0px);
      background-color: $circle-color;
    }
  }
  .ko-progress-circle__overlay {
    width: $inset-size;
    height: $inset-size;
    position: absolute;
    margin-left: ($circle-size - $inset-size)/2;
    margin-top: ($circle-size - $inset-size)/2;

    background-color: $inset-color;
    border-radius: 50%;
  }

  $i: 0;
  $increment: 180deg / 100;
  @while $i <= 100 {
    &[data-progress='#{$i}'] {
      .ko-progress-circle__slice.full,
      .ko-progress-circle__fill {
        transform: rotate($increment * $i);
      }
      .ko-progress-circle__fill.ko-progress-circle__bar {
        transform: rotate($increment * $i * 2);
      }
      $i: $i + 1;
    }
  }
}
</style>
