import humps from 'humps';

import {
  getItem,
  fetchItems,
  fetchAll,
  createItem,
  patchItem,
  patchItems,
  removeItem,
  removeItems,
  exportItem,
  execItemAction,
} from '../api/collection';

import { convertBlobToString } from '../utils/file';

function camelizeKeys(data) {
  return humps.camelizeKeys(
    data,
    (field, convert) => {
      if (field.includes('__')) {
        const [group, key] = field.split('__');

        return `${convert(group)}__${convert(key)}`;
      }

      return convert(field);
    },
  );
}

function decamelizeKeys(data) {
  return humps.decamelizeKeys(
    data,
    (field, convert) => {
      if (field.includes('__')) {
        const [group, key] = field.split('__');

        return `${convert(group)}__${convert(key)}`;
      }

      return convert(field);
    },
  );
}

export default function factory(collection) {
  if (collection.includes('advertisement')) {
    // eslint-disable-next-line no-param-reassign
    collection = collection.replace(/advertisement/i, 'adv');
  }

  const decamelizedCollectionName = humps.decamelize(collection);

  return {
    async fetchAll() {
      const [err, data] = await fetchAll(decamelizedCollectionName);

      return [camelizeKeys(err), camelizeKeys(data)];
    },

    async fetchItems({
      page, limit, sort, filter, params,
    }) {
      const [err, data] = await fetchItems(decamelizedCollectionName, {
        page,
        limit,
        sort: {
          by: sort && sort.by ? humps.decamelize(sort.by) : '',
          desc: sort ? sort.desc : false,
        },
        filter: decamelizeKeys(filter),
        params: decamelizeKeys(params),
      });

      return [camelizeKeys(err), camelizeKeys(data)];
    },

    async getItem(id) {
      const [err, data] = await getItem(decamelizedCollectionName, id);

      return [camelizeKeys(err), camelizeKeys(data)];
    },

    async createItem(values) {
      const [err, data] = await createItem(decamelizedCollectionName, decamelizeKeys(values));

      return [camelizeKeys(err), camelizeKeys(data)];
    },

    async updateItem(id, values) {
      const [err, data] = await patchItem(decamelizedCollectionName, id, decamelizeKeys(values));

      return [camelizeKeys(err), camelizeKeys(data)];
    },

    async removeItem(id) {
      const [err, data] = await removeItem(decamelizedCollectionName, id);

      return [camelizeKeys(err), camelizeKeys(data)];
    },

    async removeItems(ids) {
      const results = await removeItems(decamelizedCollectionName, ids);

      return results.map(([err, data]) => [camelizeKeys(err), camelizeKeys(data)]);
    },

    async updateItems(ids, values) {
      const results = await patchItems(decamelizedCollectionName, ids, decamelizeKeys(values));

      return results.map(([err, data]) => [camelizeKeys(err), camelizeKeys(data)]);
    },

    async exportItem(id, actionName = 'export') {
      let [err, data] = await exportItem(decamelizedCollectionName, id, actionName);

      if (err && err.data && err.data instanceof Blob) {
        const text = await convertBlobToString(err.data);
        err.data = JSON.parse(text);
      }

      if (data instanceof Blob) {
        data = await convertBlobToString(data);
      }

      return [humps.camelizeKeys(err), data];
    },

    async execItemAction(id, actionName, params) {
      const [err, data] = await execItemAction(decamelizedCollectionName, id, actionName, decamelizeKeys(params));

      return [camelizeKeys(err), camelizeKeys(data)];
    },
  };
}
