<template>
  <div class="d-flex">
    <b-nav class="schedule-tabs mr-auto" pills>
      <b-nav-item
        :active="scheduleType === scheduleTypeDefault"
        @click="$emit('change-schedule-type', scheduleTypeDefault)"
      >Стандартный режим</b-nav-item>
      <b-nav-item
        :active="scheduleType === scheduleTypeTimeline"
        @click="$emit('change-schedule-type', scheduleTypeTimeline)"
      >Многозальный режим</b-nav-item>
    </b-nav>

    <b-dropdown
      v-if="intersections.length"
      class="mr-1 intersections"
      variant="outline-danger"
      size="sm"
    >
      <template v-slot:button-content>
        <icon icon="fa-exclamation-triangle"></icon> Пересечения сеансов!
      </template>
      <template v-for="(shows, date) in intersectionsAsText">
        <b-dropdown-header :key="date">{{ date }}</b-dropdown-header>
        <b-dropdown-item
          v-for="(show, index) in shows"
          :key="`${date}:${index}`"
        >{{ show }}</b-dropdown-item>
      </template>
    </b-dropdown>

    <b-button
      v-if="$user.can('schedule.edit') && scheduleType === scheduleTypeDefault"
      size="sm"
      variant="outline-info"
      :disabled="disabled"
      @click="$emit('clear-shows')"
    >
      <icon icon="fa-trash"></icon> Очистить расписание
    </b-button>

    <b-button
      v-if="$user.can('schedule.view')"
      size="sm"
      variant="outline-info"
      class="ml-1"
      :disabled="disabled"
      @click="$emit('open-sales')"
    >
      <icon icon="fa-money"></icon> Продажи
    </b-button>

    <b-button
      size="sm"
      variant="outline-info"
      class="ml-1"
      :disabled="disabled"
      @click="$emit('export-shows')"
    >
      <icon icon="fa-file"></icon> Экспорт
    </b-button>

    <b-button
      size="sm"
      variant="outline-info"
      class="ml-1"
      :disabled="disabled"
      @click="$emit('copy-shows')"
    >
      <icon icon="fa-copy"></icon> Копирование
    </b-button>
  </div>
</template>

<script>
import { SCHEDULE_TYPE_DEFAULT, SCHEDULE_TYPE_TIMELINE } from '../../constants';

export default {
  props: {
    disabled: {
      default: false,
    },
    scheduleType: {
      default: SCHEDULE_TYPE_DEFAULT,
    },
    intersections: {
      default: () => [],
    },
    halls: {
      default: () => [],
    },
  },
  data() {
    return {
      scheduleTypeDefault: SCHEDULE_TYPE_DEFAULT,
      scheduleTypeTimeline: SCHEDULE_TYPE_TIMELINE,
    };
  },
  computed: {
    intersectionsAsText() {
      return this.intersections.reduce((dates, show) => {
        const { time, date, hallId } = show;

        const formattedDate = this.$datetime.formatDate(date);

        if (!dates[formattedDate]) {
          // eslint-disable-next-line no-param-reassign
          dates[formattedDate] = [];
        }

        const hallName = this.halls.find((_hall) => _hall.id === hallId)?.name;

        dates[formattedDate].push(`${hallName} ${time}`);

        return dates;
      }, {});
    },
  },
};
</script>

<style scoped>
.schedule-tabs >>> .nav-link {
  padding: 0.25rem 0.5rem;
}
.intersections >>> .dropdown-menu {
  max-height: 600px;
  overflow-y:auto;
}
</style>
