/* eslint-disable no-return-await */
/* eslint-disable no-restricted-syntax */
import http from './http';
import { API_VERSION } from '../config';
import { convertError } from './utilities';

export default async function exportCertificateGroup(id) {
  try {
    const response = await http.post(`/${API_VERSION}/certificate_groups/${id}/export`, {}, {
      responseType: 'blob',
    });

    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}
