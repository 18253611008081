export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditAppForm',
        permission: 'apps.edit',
      },
      {
        type: 'button',
        text: item.enabled ? 'Остановить' : 'Активировать',
        action: item.enabled ? 'pauseApp' : 'resumeApp',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeApp',
        permission: 'apps.edit',
      },
    ],
  },
];
