import Customers from '../pages/customers/Index.vue';
import EmailBlacklistDomains from '../pages/email-blacklist-domains/Index.vue';
import Full from '../layout/Full.vue';
import MerchantPayments from '../pages/merchant-payments/Index.vue';
import Orders from '../pages/orders/Index.vue';
import Organizations from '../pages/organizations/Index.vue';
import SelectionGroups from '../pages/selection-groups/Index.vue';
import Selections from '../pages/selections/Index.vue';

export default {
  path: '/clients',
  redirect: '/clients/organizations',
  component: Full,
  children: [
    {
      name: 'organizations',
      path: 'organizations/:cinemaId?',
      component: Organizations,
      meta: {
        auth: true,
        title: 'Организации',
        breadcrumbs: [
          { name: 'Клиенты' },
          { name: 'Организации' },
        ],
        permissions: ['organizations.view'],
      },
    },
    {
      name: 'customers',
      path: 'customers',
      component: Customers,
      meta: {
        auth: true,
        title: 'Физические лица',
        breadcrumbs: [
          { name: 'Клиенты' },
          { name: 'Физические лица' },
        ],
        scrollable: true,
        permissions: ['customers.view'],
      },
    },
    {
      name: 'emailBlacklistDomains',
      path: 'email-blacklist-domains',
      component: EmailBlacklistDomains,
      meta: {
        auth: true,
        title: 'Блокировка почтовых доменов',
        breadcrumbs: [
          { name: 'Клиенты' },
          { name: 'Физические лица' },
          { name: 'Блокировка почтовых доменов' },
        ],
        scrollable: true,
        permissions: ['email_blacklist_domains.view'],
      },
    },
    {
      name: 'orders',
      path: 'orders/:cinemaId?',
      component: Orders,
      meta: {
        auth: true,
        title: 'Заказы',
        breadcrumbs: [
          { name: 'Клиенты' },
          { name: 'Заказы' },
        ],
        permissions: ['orders.view'],
      },
    },
    {
      name: 'merchantPayments',
      path: 'merchant-payments',
      component: MerchantPayments,
      meta: {
        auth: true,
        title: 'Транзакции',
        breadcrumbs: [
          { name: 'Клиенты' },
          { name: 'Транзакции' },
        ],
        permissions: ['merchant_payments.view'],
      },
    },
    {
      name: 'selections',
      path: 'selections',
      component: Selections,
      meta: {
        auth: true,
        title: 'Выборка',
        breadcrumbs: [
          { name: 'Клиенты' },
          { name: 'Выборка' },
        ],
        scrollable: true,
        permissions: ['selections.view'],
      },
    },
    {
      name: 'selectionGroups',
      path: 'selections/groups',
      component: SelectionGroups,
      meta: {
        auth: true,
        title: 'Группы Выборок',
        breadcrumbs: [
          { name: 'Клиенты' },
          { name: 'Группы Выборок' },
        ],
        scrollable: true,
        permissions: ['selections.view'],
      },
    },
  ],
};
