<template>
  <div class="table-container">
    <collection-provider
      collection="banners"
      ref="collection"
      :page="1"
      :limit="20"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(name)>
            <div class="text-search">
              <b-form-input
                type="text"
                size="sm"
                :disabled="data.loading"
                :value="data.filter.nameLike"
                @change="actions.changeFilter('nameLike', $event)"
              ></b-form-input>
              <span class="trash" @click="actions.changeFilter('nameLike', '')">
                <icon icon="fa-times"></icon>
              </span>
            </div>
          </template>
          <template #filter(cinemas)>
            <dictionary-provider name="cinemas">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="data.filter.cinemas"
                  :options="convertCinemas(items)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('cinemaId', $event)"
                >
                  <template #first>
                    <b-form-select-option :value="null">Все кинотеатры</b-form-select-option>
                  </template>
                </b-form-select>
              </template>
            </dictionary-provider>
          </template>

          <template #filter(type)>
            <b-form-select
              size="sm"
              :value="data.filter.active"
              :options="bannerTypeCaptions"
              :disabled="data.loading"
              @change="actions.changeFilter('type', $event)"
            ></b-form-select>
          </template>

          <template #filter(active)>
            <b-form-select
              size="sm"
              :value="data.filter.active"
              :options="bannerActiveStatuses"
              :disabled="data.loading"
              @change="actions.changeFilter('active', $event)"
            ></b-form-select>
          </template>

          <template #cell(period)="data">
            <template v-if="data.item.dateStart">
              c {{ formatDate(data.item.dateStart) }}
            </template>
            <template v-if="data.item.dateEnd">
              по {{ formatDate(data.item.dateEnd) }}
            </template>
          </template>

          <template #cell(cinemas)="data">
            <div class="info">
              <div :id="`cinemas_${data.item.id}`">
                {{ convertCinemasToText(data.item.cinemas) }}
              </div>
              <b-tooltip
                v-if="data.item.cinemas && data.item.cinemas.length"
                :target="`cinemas_${data.item.id}`"
              >
                <div
                  class="mb-1"
                  v-for="cinema in data.item.cinemas"
                  :key="cinema.id"
                >
                  <div>{{ cinema.name }}, {{ cinema.city.name }}</div>
                </div>
              </b-tooltip>
            </div>
          </template>

          <template #cell(active)="data">
            {{ getBannerActiveName(data.item.active) }}
          </template>

          <template #cell(type)="data">
            {{ getBannerTypeName(data.item.type) }}
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.banner.show"
      create-title="Создание баннера"
      edit-title="Редактирование баннера"
      collection="banners"
      :id="modals.banner.id"
      @close="modals.banner.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <banner-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></banner-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import CollectionProvider from '../../providers/CollectionProvider';
import DictionaryProvider from '../../providers/DictionaryProvider';

import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import Datatable from '../../components/datatable/Datatable.vue';
import BannerForm from './BannerForm.vue';

import datetime from '../../utils/datetime';

import {
  BANNER_TYPE_MOVIE,
  BANNER_TYPE_ADV_PROMOTION,
  BANNER_TYPE_OTHER,
} from '../../constants';

export default {
  components: {
    CollectionProvider,
    DictionaryProvider,
    Datatable,
    ModalModelForm,
    BannerForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        banner: {
          show: false,
          id: 0,
        },
      },
      bannerActiveStatuses: [
        {
          value: null,
          text: '',
        },
        {
          value: false,
          text: 'Нет',
        },
        {
          value: true,
          text: 'Да',
        },
      ],
      bannerTypeCaptions: [
        {
          value: null,
          text: '',
        },
        {
          value: BANNER_TYPE_MOVIE,
          text: 'Фильм',
        },
        {
          value: BANNER_TYPE_ADV_PROMOTION,
          text: 'Акция',
        },
        {
          value: BANNER_TYPE_OTHER,
          text: 'Другое',
        },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return datetime.formatDate(date);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateBannerForm() {
      this.modals.banner.id = '';
      this.modals.banner.show = true;
    },

    async removeCheckedBanners() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    pauseCheckedBanners() {
      this.$refs.collection.changeItems(this.datatable.checked, { active: 0 });
      this.datatable.checked = [];
    },

    resumeCheckedBanners() {
      this.$refs.collection.changeItems(this.datatable.checked, { active: 1 });
      this.datatable.checked = [];
    },

    openEditBannerForm(id) {
      this.modals.banner.id = id;
      this.modals.banner.show = true;
    },

    async removeBanner(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    pauseBanner(id) {
      this.$refs.collection.changeItem(id, { active: 0 });
    },

    resumeBanner(id) {
      this.$refs.collection.changeItem(id, { active: 1 });
    },

    getBannerActiveName(value) {
      return this.bannerActiveStatuses.find((status) => status.value === value)?.text;
    },

    getBannerTypeName(value) {
      return this.bannerTypeCaptions.find((status) => status.value === value)?.text;
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        value: cinema.id,
        text: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    convertCinemasToText(cinemas) {
      return cinemas.map((cinema) => `${cinema?.name} ${cinema?.city?.name}`).join(', ');
    },
  },
};
</script>

<style scoped>
.table-container >>> .column-active {
  width: 200px;
}
.table-container >>> .column-type {
  width: 200px;
}
.table-container >>> .column-cinemas {
  max-width: 400px;
}
.text-search {
  width: 300px;
}
.info {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}
</style>
