import { convertError, createQuery } from './utilities';

import { API_VERSION } from '../config';
import http from './http';

export default async function fetchReport(name, format, {
  sort, filter, params,
} = {}) {
  const query = createQuery({
    sort, filter, params,
  });

  try {
    const response = await http.get(`/${API_VERSION}/reports/${name}?&format=${format}&${query}`, {
      responseType: 'blob',
    });

    let filename = '';

    if ('content-disposition' in response.headers) {
      const disposition = response.headers['content-disposition'];

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*?=([^']*'')?([^;]*)/;
        const matches = filenameRegex.exec(disposition);

        if (matches != null && matches[2]) {
          filename = matches[2].replace(/['"]/g, '');
          filename = decodeURI(filename);
        }
      }
    }

    return [null, { filename, content: response.data } || {}];
  } catch (error) {
    return convertError(error);
  }
}
