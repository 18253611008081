export default () => [
  {
    type: 'button',
    text: 'Добавить организацию',
    variant: 'info',
    icon: 'fa-plus',
    class: 'mr-1',
    action: 'openCreateOrganizationForm',
    permission: 'organizations.edit',
  },
];
