export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditBannerForm',
        permission: 'banners.edit',
      },
      {
        type: 'button',
        text: item.active ? 'Остановить' : 'Активировать',
        action: item.active ? 'pauseBanner' : 'resumeBanner',
        permission: 'banners.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeBanner',
        permission: 'banners.edit',
      },
    ],
  },
];
