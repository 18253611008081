import _factory from './_factory';
import Orders from '../../services/Orders';

export default {
  ..._factory('orders', {
    state: {
      loading: false,
    },
    actions: {
      async refundOrder({ commit }, values) {
        commit('setLoading', true);

        const [err, result] = await Orders.refundOrder(values.compositeId, values.params);

        commit('setLoading', false);

        return [err, result];
      },
    },
  }),
};
