import _factory from './_factory';
import collectionServiceFactory from '../../services/collectionServiceFactory';

const positionBannersService = collectionServiceFactory('positions/banners');

export default {
  ..._factory('banners', {
    actions: {
      async changePositions({ commit, dispatch }, { values }) {
        commit('setLoading', true);

        const [err, item] = await positionBannersService.createItem(values);

        if (!err) {
          await dispatch('fetchItems');
        }

        commit('setLoading', false);

        return [err, item];
      },
    },
  }),
};
