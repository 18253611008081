import datetime from '../utils/datetime';

export default {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        this.$datetime = datetime;
      },
    });
  },
};
