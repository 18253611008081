<template>
  <b-checkbox
    :disabled="loading || disabledAll"
    :checked="allChecked"
    @change="toggleAllChecked()"
  >
    {{ release.format.name }}
  </b-checkbox>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    cinemas: {
      default: [],
    },
    items: {
      default: [],
    },
    index: {
      default: 0,
    },
    release: {
      default: null,
    },
  },
  computed: {
    ...mapState('data/favoriteReleases', [
      'loading',
      'selectedItems',
    ]),

    ...mapGetters('data/favoriteReleases', [
      'getSelectedItemsByReleaseIdCount',
      'getSelectedItemsByCinemaIdAndReleaseId',
    ]),

    getReleases() {
      const result = [];

      for (const item of this.items) {
        const release = item.releases[this.index];

        if (release.release_id === this.release.id) {
          result.push(release);
        }
      }

      return result;
    },

    disabledAll() {
      return this.items.length === this.getReleasesCountByDisabled(true);
    },

    allChecked() {
      return !this.disabledAll && (this.getReleasesCountByDisabled(false) === this.getSelectedItemsByReleaseIdCount(this.release.id));
    },
  },
  methods: {
    getReleasesCountByDisabled(value) {
      return this.getReleases
        .filter(({ disabled }) => disabled === value).length;
    },

    getReleasesByCinemaId(cinemaId) {
      return this.getReleases
        .find(({ cinema_id, release_id }) => cinema_id === cinemaId && release_id === this.release.id);
    },

    toggleAllChecked() {
      let enabledRemoveAll = this.allChecked;

      for (const cinema of this.cinemas) {
        if (this.getSelectedItemsByCinemaIdAndReleaseId(cinema.id, this.release.id)) {
          if (enabledRemoveAll) {
            this.$emit('remove', {
              cinemaId: cinema.id,
              releaseId: this.release.id,
            });
          }
        } else if (!this.getReleasesByCinemaId(cinema.id).disabled) {
          this.$emit('add', {
            cinemaId: cinema.id,
            releaseId: this.release.id,
          });
        }
      }

      enabledRemoveAll = false;
    },
  },
};
</script>
