// eslint-disable-next-line import/prefer-default-export
export class MaskHelper {
  static getTimeRange(rawValue) {
    const timePeriods = rawValue.split('-');
    const firstPeriod = timePeriods[0];
    const secondPeriod = timePeriods[1] || '';
    return [...MaskHelper.getTimeMask(firstPeriod), '-', ...MaskHelper.getTimeMask(secondPeriod)];
  }

  static getTimeMask(time) {
    const mask = [];
    const hour = time ? time.split(':')[0] : '';
    // eslint-disable-next-line no-bitwise
    if (!hour.match(/^[0-1][0-9]?$/)) {
      mask.push(/[2]/, /[0-3]/);
    } else {
      mask.push(/[0,1]/, /\d/);
    }
    mask.push(':', /[0-5]/, /\d/);
    return mask;
  }

  static getPhoneMask() {
    const getDigitsRegular = (count) => Array(count)
      .fill(/\d/);
    return ['+', '7', ' ', '(', ...getDigitsRegular(3), ')', ' ', ...getDigitsRegular(3), ' ', ...getDigitsRegular(2), ' ', ...getDigitsRegular(2)];
  }

  static getSimplePhoneMask() {
    const getDigitsRegular = (count) => Array(count)
      .fill(/\d/);
    return getDigitsRegular(11);
  }

  static getBirthDateMask() {
    return [/[0-3]/, /[0-9]/, '.', /[0-1]/, /[0-9]/, '.', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/];
  }
}
