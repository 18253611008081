var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cinema-page',{attrs:{"route-name":"organizations","route-param-name":"cinemaId"},scopedSlots:_vm._u([{key:"scroll",fn:function(ref){
var cinemaId = ref.cinemaId;
return [_c('div',[_c('collection-provider',{ref:"collection",attrs:{"collection":"organizations","filter":{ cinemaId: cinemaId },"disable-fetching":!cinemaId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"item-actions":_vm.datatable.itemActions,"panel-actions":_vm.datatable.panelActions},on:{"item-action":function($event){return _vm.performItemAction($event)},"panel-action":function($event){return _vm.performPanelAction($event)}},scopedSlots:_vm._u([{key:"cell(balance)",fn:function(data){return [_c('a',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){return _vm.openOrganizationOperations(data.item)}}},[_vm._v(_vm._s(data.item.balance))])]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}],null,true)}),(_vm.modals.organization.show)?_c('modal-model-form',{attrs:{"create-title":"Создание организации","edit-title":"Редактирование организации","collection":"organizations","id":_vm.modals.organization.id,"init":{ cinemaId: cinemaId }},on:{"close":function($event){_vm.modals.organization.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('organization-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,true)}):_vm._e(),(_vm.modals.organizationOperations.show)?_c('b-modal',{attrs:{"centered":"","cancel-title":"Закрыть","size":"xl","visible":true,"title":_vm.modals.organizationOperations.title,"ok-only":true,"ok-title":"Закрыть","ok-variant":"secondary"},on:{"hidden":function($event){return _vm.closeOrganizationOperations()}}},[_c('organization-operations',{attrs:{"id":_vm.modals.organizationOperations.id}})],1):_vm._e(),(_vm.modals.organizationBalance.show)?_c('modal-model-form',{attrs:{"id":"","collection":"organizationOperations","create-title":_vm.modals.organizationBalance.title,"init":{ organizationId: _vm.modals.organizationBalance.id }},on:{"close":function($event){_vm.modals.organizationBalance.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('organization-balance-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,true)}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }