import mediaPanels from '../../services/MediaPanels';

export default {
  namespaced: true,

  state: {
    loading: false,
    imagePath: null,
    intervalTime: 2000,
    maxTimeReques: 20000,
  },

  actions: {
    async getScreenshot({ state, commit, dispatch }, { id }) {
      commit('setLoading', true);

      commit('removeImagePath');

      let refreshIntervalId = null;

      const [err, result] = await dispatch('requestOnDownloadScreenshot', id);

      if (result) {
        refreshIntervalId = setInterval(async () => {
          await dispatch('getScreenshotStatus', id);
        }, state.intervalTime);

        commit('setInterval', refreshIntervalId);

        setTimeout(() => {
          commit('clearInterval');
        }, state.maxTimeReques);
      }

      commit('setLoading', false);

      return [err, result];
    },

    async getScreenshotStatus({ commit, dispatch }, id) {
      let [err, result] = await mediaPanels.getScreenshotStatus(id);

      if (result && result.exists) {
        commit('clearInterval');
        [err, result] = await dispatch('getScreenshotPath', id);
      }

      if (err) {
        commit('clearInterval');
      }

      return [err, result];
    },

    async getScreenshotPath({ commit }, id) {
      const [err, result] = await mediaPanels.getScreenshot(id);

      if (result) {
        commit('setImage', result);
      }

      return [err, result];
    },

    async requestOnDownloadScreenshot({ commit }, id) {
      commit('setLoading', true);

      const [err, result] = await mediaPanels.requestOnDownloadScreenshot(id);

      commit('setLoading', false);

      return [err, result];
    },

    async removeScreenshot({ commit }) {
      commit('clearInterval');
      commit('removeImagePath');
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setImage(state, path) {
      state.imagePath = path;
    },

    removeImagePath(state) {
      state.imagePath = null;
    },

    setInterval(state, id) {
      state.refreshIntervalId = id;
    },

    clearInterval(state) {
      clearInterval(state.refreshIntervalId);
    },
  },
};
