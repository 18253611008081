var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-box',{attrs:{"items":_vm.displayedReleases,"loading":_vm.loading,"search":_vm.releaseName,"draggable":true,"empty-text":_vm.emptyText,"search-text":"Поиск по всей базе"},on:{"search":function($event){return _vm.searchRelease($event)},"mouseover":function($event){return _vm.onItemMouseover($event)},"mouseleave":function($event){return _vm.onItemMouseleave($event)}},scopedSlots:_vm._u([{key:"top-content",fn:function(){return [_c('div',{staticClass:"p-2 border-bottom"},[_c('b-form-checkbox',{attrs:{"size":"sm","value":true,"unchecked-value":false,"disabled":_vm.loading},model:{value:(_vm.displayShowsReleases),callback:function ($$v) {_vm.displayShowsReleases=$$v},expression:"displayShowsReleases"}},[_vm._v(" Показать релизы без росписи ")])],1)]},proxy:true},{key:"title",fn:function(ref){
var ref_item = ref.item;
var release = ref_item.release;
var count = ref_item.count;
return [_c('div',{staticClass:"d-flex",class:{ 'active': _vm.hoverItemId === release.movieId || _vm.displayedCounterPopoverId === release.id }},[(release.movie)?_c('span',{staticClass:"release__name text-truncate",attrs:{"title":release.movie.name}},[_vm._v(_vm._s(release.movie.name))]):_vm._e(),_c('span',{staticClass:"release__popover-handler ml-auto cursor-pointer",attrs:{"id":("popover_target_" + (release.id))},on:{"click":function($event){return _vm.toggleCounterPopover(release.id)}}},[_vm._v(">")]),(_vm.displayedCounterPopoverId === release.id)?_c('releases-counter-popover',{attrs:{"target":("popover_target_" + (release.id)),"value":count},on:{"change":function($event){return _vm.saveCount(release.id, $event)},"hide":function($event){_vm.displayedCounterPopoverId = null}}}):_vm._e()],1)]}},{key:"subtitle",fn:function(ref){
var release = ref.item.release;
return [_c('span',[_vm._v("в прокате c "+_vm._s(_vm.$datetime.formatDate(release.movie.dateStart)))])]}},{key:"description",fn:function(ref){
var ref_item = ref.item;
var release = ref_item.release;
var repertoryCount = ref_item.repertoryCount;
var showsCount = ref_item.showsCount;
return [_c('div',{staticClass:"d-flex align-items-center"},[(release.repertoryItem)?_c('span',{staticClass:"mark mr-1",style:({ backgroundColor: _vm.getRepertoryItemColor(release.repertoryItem.repertoryItemStatusId) })}):_vm._e(),_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(release.movie.ageLimit)+"+,")]),_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(release.duration))]),_c('span',{staticClass:"mr-1"},[_vm._v("мин,")]),(release.format)?_c('span',{staticClass:"font-weight-bold",class:{'text-danger': _vm.format3D(release.format.name)}},[_vm._v(_vm._s(release.format.name))]):_vm._e(),_c('div',{staticClass:"ml-auto"},[_c('span',{staticClass:"mr-1",class:{
            'text-danger': showsCount && showsCount > repertoryCount,
            'text-success': showsCount && showsCount === repertoryCount,
          }},[_vm._v(_vm._s(showsCount))]),_c('span',{staticClass:"mr-1"},[_vm._v("/")]),_c('span',{class:{
            'text-success': showsCount && showsCount === repertoryCount,
          }},[_vm._v(_vm._s(repertoryCount))])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }