import _factory from './_factory';

export default {
  ..._factory('productReportGroups', {
    state: {
      page: null,
      limit: null,
    },
  }),
};
