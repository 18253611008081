import Vue from 'vue';
import axios from 'axios';
import MediaPanels from '../../services/MediaPanels';

import {
  MEDIA_PANEL_LIBRARY_TYPE_DIRECTORY as typeDirectory,
  MEDIA_PANEL_LIBRARY_TYPE_FILE as typeFile,
} from '../../constants';

const { CancelToken } = axios;

export default {
  namespaced: true,

  state: {
    loading: false,
    parentId: null,
    items: [],
    uploadFilesItems: [],
    storage: {
      free: 0,
      used: 0,
    },
    sort: {
      by: '',
      desc: false,
    },
  },

  actions: {
    async fetchItemsByDirectortId({ dispatch, commit }, parentId) {
      commit('setParentId', parentId);

      await dispatch('fetchItems');
      await dispatch('getStorage');
    },

    async fetchItems({ commit, state: { parentId, sort } }) {
      commit('setLoading', true);

      const [, result] = await MediaPanels.getFilesAndDirectory({
        params: { ...(parentId && { parentId }) },
        sort,
      });

      if (result) {
        const { items } = result;

        commit('setItems', { items });
      }

      commit('setLoading', false);
    },

    async resetSort({ commit }) {
      commit('setSort', { by: '', desc: false });
    },

    async setSort({ commit }, { by, desc }) {
      commit('setSort', { by, desc });
    },

    async changeSort({ dispatch }, { by, desc }) {
      dispatch('setSort', { by, desc });
      dispatch('fetchItems');
    },

    async uploadFiles({ dispatch }, files) {
      const promises = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        promises.push(dispatch('registerAndUploadFile', file));
      }

      await Promise.all(promises);

      await dispatch('fetchItems');
    },

    async registerAndUploadFile({ dispatch }, file) {
      const [err, id] = await dispatch('registerFileOrDirectory', {
        type: typeFile,
      });

      if (err) {
        return;
      }

      await dispatch('uploadFile', { id, file });
    },

    async reloadFile({ dispatch, commit }, { id, file }) {
      commit('deleteUploadFile', id);

      await dispatch('registerAndUploadFile', file);
    },

    async uploadFile({ commit, dispatch }, { id, file }) {
      commit('pushUploadFile', { id, file });

      const fd = new FormData();
      fd.append('file', file);

      const [err, data] = await MediaPanels.uploadFile(id, fd, {
        onUploadProgress: (e) => {
          commit('updateFileValue', {
            id,
            field: 'progress',
            value: Math.round(e.loaded),
          });
        },
        cancelToken: new CancelToken((c) => {
          commit('updateFileValue', { id, field: 'onCancel', value: c });
        }),
      });

      if (err) {
        commit('updateFileValue', { id, field: 'progress', value: 0 });
        commit('updateFileValue', { id, field: 'error', value: 'Ошибка' });
      }

      if (!err && data === undefined) {
        commit('updateFileValue', {
          id,
          field: 'error',
          value: 'Загрузка отменена',
        });
        await dispatch('deleteFileOrDirectory', { id });
      }

      if (data && data.status === 200) {
        commit('updateFileValue', { id, field: 'loaded', value: true });

        setTimeout(() => {
          commit('deleteUploadFile', id);
        }, 3000);
      }

      await dispatch('getStorage');
      await dispatch('fetchItems');
    },

    async resetUploadFiles({ commit }) {
      commit('resetUploadFiles');
    },

    async deleteFileOrDirectory({ dispatch, commit }, { id }) {
      commit('setLoading', true);

      const [err, result] = await MediaPanels.removeFileOrDirectory(id);

      if (!err) {
        await dispatch('fetchItems');
        await dispatch('getStorage');
      }

      commit('setLoading', false);

      return [err, result];
    },

    async createDirectory({ dispatch, commit, state }, { name }) {
      commit('setLoading', true);

      const [err, result] = await dispatch('registerFileOrDirectory', {
        parentId: state.parentId,
        type: typeDirectory,
        name,
      });

      if (!err) {
        await dispatch('fetchItems');
      }

      return [err, result];
    },

    async updateFileOrDirectory({ dispatch, commit }, { id, name }) {
      commit('setLoading', true);

      const [err, result] = await MediaPanels.updateFileOrDirectory(id, name);

      if (!err) {
        await dispatch('fetchItems');
      }

      commit('setLoading', false);

      return [err, result];
    },

    async registerFileOrDirectory({ state: { parentId } }, { type, name }) {
      const typeInfo = {
        parentId,
        type,
        ...(type === typeDirectory && { name }),
      };

      const [err, id] = await MediaPanels.registerFileOrDirectory(typeInfo);

      return [err, id];
    },

    async getStorage({ commit }) {
      const [, result] = await MediaPanels.getStorage();

      if (result) {
        const { used, free } = result;
        commit('setStorage', { used, free });
      }
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setParentId(state, id) {
      state.parentId = id;
    },

    setItems(state, { items }) {
      state.items = state.items.filter((item) => item.parentId !== state.parentId);

      const index = state.items.findIndex((item) => item.id === state.parentId);

      if (index > -1) {
        state.items.splice(index + 1, 0, ...items);

        return;
      }

      state.items = [...state.items, ...items];
    },

    updateFileValue(state, { id, field, value }) {
      const index = state.uploadFilesItems.findIndex((file) => file.id === id);

      if (index < 0) return;

      Vue.set(state.uploadFilesItems, index, {
        ...state.uploadFilesItems[index],
        [field]: value,
      });
    },

    resetUploadFiles(state) {
      state.uploadFilesItems = [];
    },

    deleteUploadFile(state, id) {
      const index = state.uploadFilesItems.findIndex((file) => file.id === id);

      if (index < 0) {
        return;
      }

      state.uploadFilesItems.splice(index, 1);
    },

    pushUploadFile(state, { id, file }) {
      const fileInfo = {
        id,
        file,
        progress: 0,
        error: null,
        loaded: false,
        onCancel: () => {},
      };

      state.uploadFilesItems.push(fileInfo);
    },

    setStorage(state, { used, free }) {
      state.storage = { used, free };
    },

    setSort(state, { by, desc }) {
      state.sort.by = by;
      state.sort.desc = desc;
    },
  },

  getters: {
    itemsDirectories: (state) => state.items.filter((item) => item.type === typeDirectory),

    directoriesNavigationInListMode: (state, getters) => {
      if (!state.parentId) {
        return [];
      }

      const lastItem = getters.itemsDirectories.find((d) => d.id === state.parentId);

      const navigation = getters.itemsDirectories
        .reduce((acc) => {
          const el = getters.itemsDirectories.find((d) => d.id === acc[0].parentId);

          if (el) {
            acc.unshift(el);
          }

          return acc;
        }, [lastItem])
        .map((item) => ({ id: item.id, name: item.name }));

      return [{ id: null, name: 'Home' }, ...navigation];
    },
  },
};
