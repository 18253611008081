<template>
  <div>
    <div class="text-uppercase border-bottom d-flex justify-content-between pb-2 mb-3">
      <div class="align-self-end">Кассовые устройства</div>
      <b-button
        size="sm"
        variant="outline-info"
        :disabled="disabled || !freeDevices.length"
        @click="addDevice()"
      >
        <icon icon="fa-plus"></icon> Добавить устройство
      </b-button>
    </div>
    <div
      class="text-center"
      v-if="!devices || (devices && !devices.length)"
    >{{ disabled ? 'Загрузка...' : 'Нет устройств' }}</div>
    <div>
      <div
        v-for="(device, index) in devices"
        class="border-bottom device-container mb-3 pb-2"
        :key="index"
        :class="{'device-container_new': !device.id}"
      >
        <b-row>
          <b-col>
            <b-select
              size="sm"
              :options="convertDevicesToOptions(getCurrentAndFreeDevices(device.cashierDeviceId))"
              :value="device.cashierDeviceId"
              :disabled="disabled"
              @change="changeCashierDeviceId(index, $event)"
            ></b-select>
          </b-col>
          <b-col>
            <b-form-checkbox
              v-if="availableFeatures.includes('fiscalTickets')"
              :checked="!!device.fiscalTickets"
              :disabled="disabled || !isFiscalableDevice(device.cashierDeviceId)"
              @change="setValue(index, 'fiscalTickets', $event)"
            >Использовать для фискализации билетов</b-form-checkbox>
            <b-form-checkbox
              v-if="availableFeatures.includes('fiscalProducts')"
              :checked="!!device.fiscalProducts"
              :disabled="disabled || !isFiscalableDevice(device.cashierDeviceId)"
              @change="setValue(index, 'fiscalProducts', $event)"
            >Использовать для фискализации товаров</b-form-checkbox>
            <b-form-checkbox
              v-if="availableFeatures.includes('fiscalBarItems')"
              :checked="!!device.fiscalBarItems"
              :disabled="disabled || !isFiscalableDevice(device.cashierDeviceId)"
              @change="setValue(index, 'fiscalBarItems', $event)"
            >Использовать для фискализации товаров бара</b-form-checkbox>
            <b-form-checkbox
              v-if="availableFeatures.includes('fiscalPrintable')"
              :checked="device.fiscalPrintable"
              :disabled="disabled || !isPrintableDevice(device.cashierDeviceId)"
              @change="setValue(index, 'fiscalPrintable', $event)"
            >Использовать для печати чека</b-form-checkbox>
            <b-form-checkbox
              v-if="availableFeatures.includes('ticketPrintable')"
              :checked="device.ticketPrintable"
              :disabled="disabled || !isPrintableDevice(device.cashierDeviceId)"
              @change="setValue(index, 'ticketPrintable', $event)"
            >Использовать для печати билетов</b-form-checkbox>
            <b-form-checkbox
              v-if="availableFeatures.includes('acquiringPrintable')"
              :checked="device.acquiringPrintable"
              :disabled="disabled || !isPrintableDevice(device.cashierDeviceId)"
              @change="setValue(index, 'acquiringPrintable', $event)"
            >Использовать для печати слипов</b-form-checkbox>
          </b-col>
        </b-row>
        <span
          class="device-container__trash-button"
          :disabled="disabled"
          @click="removeDevice(index)"
        >
          <icon icon="fa-times"></icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    availableFeatures: {
      default: () => [
        'fiscalTickets',
        'fiscalProducts',
        'fiscalBarItems',
        'fiscalPrintable',
        'ticketPrintable',
        'acquiringPrintable',
      ],
    },
    availableDevices: {
      default: () => [],
    },
    devices: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    freeDevices() {
      return this.availableDevices.filter(
        (cd) => !this.devices.find((device) => device.cashierDeviceId === cd.id),
      );
    },
  },
  methods: {
    getDevice(id) {
      return this.availableDevices.find((device) => device.id === id);
    },

    isPrintableDevice(id) {
      return this.getDevice(id)?.printable;
    },

    isFiscalableDevice(id) {
      return this.getDevice(id)?.fiscalable;
    },

    getCurrentAndFreeDevices(id) {
      const device = this.getDevice(id);

      if (!device) {
        return this.freeDevices;
      }

      return [
        device,
        ...this.freeDevices,
      ];
    },

    convertDevicesToOptions(devices) {
      return [
        { value: null, text: '-- выберите устройство --', disabled: true },
        ...devices.map((device) => ({ value: device.id, text: device.name })),
      ];
    },

    removeDevice(index) {
      const copy = [...this.devices];

      copy.splice(index, 1);

      this.emitChange(copy);
    },

    addDevice() {
      this.emitChange([...this.devices, this.createDevice(null)]);
    },

    changeCashierDeviceId(index, id) {
      const copy = [...this.devices];

      copy.splice(index, 1, this.createDevice(id));

      this.emitChange(copy);
    },

    createDevice(cashierDeviceId) {
      const device = {
        cashierDeviceId,
      };

      this.availableFeatures.forEach((deviceFeature) => {
        device[deviceFeature] = false;
      });

      return device;
    },

    setValue(index, field, value) {
      const copy = [...this.devices];

      const item = copy[index];

      copy.splice(index, 1, {
        ...item,
        [field]: value,
      });

      this.emitChange(copy);
    },

    emitChange(devices) {
      this.$emit('change', devices);
    },
  },
};
</script>

<style scoped>
.device-container {
  position: relative;
}
.device-container__trash-button {
  position: absolute;
  right: 0;
  top: -4px;
  color: #AA0000;
  cursor: pointer;
  z-index: 100;
}
.device-container_new .form-control {
  border-color: #0a0;
}
</style>
