/* eslint-disable guard-for-in */
import datetime from '../../../utils/datetime';

export const OFFSET_DAY = 1;
export const OFFSET_WEEK = 2;
export const OFFSET_MONTH = 3;
export const OFFSET_QUARTER = 4;
export const OFFSET_YEAR = 5;

export const getOffsetDate = (date, offset) => {
  if (offset === OFFSET_DAY) {
    return datetime.subtractDays(date, 1);
  }

  if (offset === OFFSET_WEEK) {
    return datetime.subtractDays(date, 7);
  }

  if (offset === OFFSET_MONTH) {
    return datetime.subtractMonthes(date, 1);
  }

  if (offset === OFFSET_QUARTER) {
    return datetime.subtractMonthes(date, 3);
  }

  if (offset === OFFSET_YEAR) {
    return datetime.subtractYears(date, 1);
  }

  return null;
};

export const getDates = (date, offset, count) => {
  const dates = [];

  let offsetDate = date;

  for (let i = 0; i < count; i += 1) {
    dates.push(offsetDate);

    offsetDate = getOffsetDate(offsetDate, offset);
  }

  dates.reverse();

  return dates;
};

export const getOffset = (dates) => {
  if (dates.length < 2) {
    return null;
  }

  const lastDate = dates[dates.length - 1];

  const prevDate = dates[dates.length - 2];

  for (const offset of [OFFSET_DAY, OFFSET_WEEK, OFFSET_MONTH, OFFSET_QUARTER, OFFSET_YEAR]) {
    if (datetime.isSameDay(getOffsetDate(lastDate, offset), prevDate)) {
      return offset;
    }
  }

  return null;
};
