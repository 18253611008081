<template>
  <date-picker
    class="form-control-sm"
    :value="localValue"
    :placeholder="placeholder"
    :disabled="disabled"
    :config="options"
    @dp-change="onSelected"
  ></date-picker>
</template>

<script>
import moment from 'moment';
import datePicker from 'vue-bootstrap-datetimepicker';

export default {
  components: {
    datePicker,
  },
  props: {
    value: null,
    format: {
      type: String,
      default: 'DD.MM.YYYY HH:mm',
    },
    useCurrent: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: 'ru',
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    formatOutput: {
      type: String,
      default: 'YYYY-MM-DD HH:mm',
    },
    stepping: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    options() {
      return {
        format: this.format,
        useCurrent: this.useCurrent,
        locale: this.locale,
        stepping: this.stepping,
      };
    },
    localValue() {
      if (!this.value) {
        return '';
      }

      return moment(this.value, this.formatOutput).format(this.format);
    },
  },
  methods: {
    onSelected({ date }) {
      if (moment(date).isSame(moment(this.value))) {
        return;
      }

      this.$emit('selected', date ? moment(date).format(this.formatOutput) : '');
    },
  },
};
</script>

<style>
@import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
</style>
