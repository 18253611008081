<template>
  <div :style="{'opacity': disabled ? 0.5 : 1}">
    <b-row
      v-for="frequency in ['high', 'normal', 'low']"
      :key="frequency"
      class="no-gutters"
    >
      <b-col
        v-for="recency in ['low', 'normal', 'high']"
        :key="recency"
      >
        <rfm-customers-group
          class="m-2"
          :title="getTitle(frequency, recency)"
          :recency="recency"
          :frequency="frequency"
          :main-items="mainItems"
          :offset-items="offsetItems"
          :checked-items="checkedItems"
          :disabled="disabled"
          @change="$emit('change-checked', $event)"
        ></rfm-customers-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RfmCustomersGroup from './RfmCustomersGroup.vue';

export default {
  components: {
    RfmCustomersGroup,
  },
  props: {
    titles: {
      default: () => ({}),
    },
    mainItems: {
      default: () => [],
    },
    offsetItems: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
    checkedItems: {
      default: () => [],
    },
  },
  methods: {
    getTitle(frequency, recency) {
      return this.titles[`frequency_${frequency}_recency_${recency}`] || 'Без названия';
    },
  },
};
</script>
