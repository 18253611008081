<template>
  <div class="item-header mb-3">
    <div v-if="image" class="item-header__image">
      <img class="img-responsive" :src="image">
    </div>
    <div class="item-header__title">
      <h1>{{title}}</h1>
      <h2>{{subTitle}}</h2>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      default: '',
    },
    title: {
      default: '',
    },
    subTitle: {
      default: '',
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.item-header__image {
  display: inline-block;
  vertical-align: top;
  min-width: 160px;
  max-width: 160px;
}

.item-header__image img {
  vertical-align: middle;
  border-radius: 4px;
  max-width: 160px;
}

.item-header__title {
  display: inline-block;
  padding: 12px 0 0 14px;
  vertical-align: top;
}

.item-header__title h1 {
  display: block;
  margin: 0 0 0 5px;
  font-size: 28px;
  line-height: 28px;
  color: #000;
  white-space: normal;
  background-color: transparent;
}

.item-header__title h2 {
  display: block;
  margin: 0;
  font-size: 20px;
  color: #9b9b9b;
  margin-left: 5px;
}
</style>
