<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Заголовок:"
          :invalid-feedback="errors.title"
          :state="states.title"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.title"
            :state="states.title"
            :disabled="loading"
            @change="updateValue('title', $event)"
          ></b-form-input>
        </b-form-group>

        <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
          <div class="align-self-end">Период действия уведомления</div>
        </div>

        <b-form-group
          label="Дата и время начала и окончания уведомления:"
          :invalid-feedback="errors.dateEnd || errors.dateStart"
          :state="states.dateEnd || states.dateStart"
        >
          <date-time-interval
            :value="{from: item.dateStart, to: item.dateEnd}"
            :disabled="loading"
            @selected="updateValue('dateStart', $event.from), updateValue('dateEnd', $event.to)">
          </date-time-interval>
        </b-form-group>

        <b-form-group
          label="Периодичность показа (в часах):"
          description="Если оставить поле пустым, уведомление будет показываться постоянно при обновлении страницы"
          :invalid-feedback="errors.showPeriod"
          :state="states.showPeriod"
        >
          <b-form-select
            v-model="item.showPeriod"
            size="sm"
            :state="states.showPeriod"
            :disabled="loading"
            @change="updateValue('showPeriod', $event)"
          >
            <b-form-select-option value=""></b-form-select-option>
            <b-form-select-option
              v-for="index in 24"
              :key="index"
              :value="index"
            >
              {{index}}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>

        <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
          <div class="align-self-end">Место действия уведомления</div>
        </div>

        <b-form-group
          label="Место показа:"
          :invalid-feedback="errors.notificationPlaceId"
          :state="states.notificationPlaceId"
        >
          <dictionary-provider name="notificationPlaces">
            <template #default="{ items, loading: dictionaryLoading }">
              <b-form-select
                size="sm"
                :value="item.notificationPlaceId"
                :options="$array.convertToOptions(items)"
                :disabled="loading || dictionaryLoading"
                @change="updateValue('notificationPlaceId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
          label="Кинотеатры:"
          :invalid-feedback="errors.cinemas"
          :state="states.cinemas"
        >
          <dictionary-provider name="cinemas">
            <template #default="{ items, loading: dictionaryLoading }">
              <toggle-list
                placeholder="Все"
                :options="convertCinemas($array.union(items, item.cinemas))"
                :value="item.cinemas"
                :disabled="loading || dictionaryLoading"
                @change="updateValue('cinemas', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
          label="Категории залов:"
          :invalid-feedback="errors.hallCategories"
          :state="states.hallCategories"
        >
          <dictionary-provider name="hallCategories">
            <template #default="{ items }">
              <toggle-list
                placeholder="Все"
                :options="items"
                :value="item.hallCategories"
                :disabled="loading"
                @change="updateValue('hallCategories', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
          label="Группы приложений:"
          :disabled="loading"
          :invalid-feedback="errors.appGroups"
          :state="states.appGroups"
        >
          <dictionary-provider name="appGroups">
            <template #default="{ items, loading: appGroupsLoading }">
              <toggle-list
                placeholder="Все"
                :options="items"
                :value="item.appGroups"
                :disabled="loading || appGroupsLoading"
                @change="updateAppGroups($event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
          label="Приложения:"
          :disabled="loading"
          :invalid-feedback="errors.apps"
          :state="states.apps"
        >
          <dictionary-provider
            name="apps"
            :filter="{ appGroupIds: localAppGroups }"
            :disable-fetching="!localAppGroups"
          >
            <template #default="{ items, loading: appsLoading }">
              <toggle-list
                placeholder="Все"
                :options="items"
                :value="item.apps"
                :disabled="appsLoading || loading || (!localAppGroups && !item.apps)"
                @change="updateValue('apps', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
          <div class="align-self-end">Фильмы, для которых действует уведомление</div>
        </div>

        <b-form-group
          label="Возрастное ограничение фильма:"
          :invalid-feedback="errors.ageLimit"
          :state="states.ageLimit"
        >
          <dictionary-provider name="ageLimits">
            <template #default="{ items }">
              <toggle-list
                placeholder="Все"
                :options="items"
                :value="item.ageLimits"
                :disabled="loading"
                @change="updateValue('ageLimits', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
          label="Релизы:"
          :invalid-feedback="errors.releases"
          :state="states.releases"
        >
          <dictionary-provider name="showsReleases">
            <template #default="{ items, loading: dictionaryLoading }">
              <toggle-list
                placeholder="Все"
                :options="convertReleases(items)"
                :value="item.releases"
                :searchable="true"
                :disabled="loading || dictionaryLoading"
                @change="updateValue('releases', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
          label="Форматы фильмов:"
          :invalid-feedback="errors.formats"
          :state="states.formats"
        >
          <dictionary-provider name="formats2">
            <template #default="{ items }">
              <toggle-list
                placeholder="Все"
                :options="items"
                :value="item.formats"
                :disabled="loading"
                @change="updateValue('formats', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
          label="Текст уведомления:"
          :invalid-feedback="errors.content"
          :state="states.content"
        >
          <v-md-editor
            :value="item.content"
            :state="states.content"
            :disabled="loading"
            left-toolbar="undo redo clear | bold"
            right-toolbar="preview sync-scroll"
            height="400px"
            @change="updateValue('content', $event)"
          ></v-md-editor>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';
import DateTimeInterval from '../../components/fields/DateTimeInterval.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
    DateTimeInterval,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  watch: {
    'item.id': {
      handler() {
        const appGroups = this.item?.appGroups || [];
        this.localAppGroups = appGroups.map((appGroup) => appGroup.id);
      },
    },
  },
  data() {
    return {
      localAppGroups: null,
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    convertReleases(releases) {
      if (!releases) {
        return [];
      }

      return releases.map((release) => ({
        id: release.id,
        name: release?.format?.name ? `${release?.movie?.name}, ${release?.format?.name}` : release?.movie?.name,
      }));
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    updateAppGroups($event) {
      this.localAppGroups = $event.map((item) => item.id);
      const { apps } = this.item;
      this.updateValue('appGroups', $event);
      if (this.localAppGroups.length) {
        const filteredApps = apps.filter((app) => this.localAppGroups.includes(app.appGroupId));
        this.updateValue('apps', filteredApps);
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/redactor.scss';
</style>
