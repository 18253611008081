import { mapState, mapActions, mapGetters } from 'vuex';

import colors from '../utils/colors';
import AnalyticsService from '../services/Analytics';

export const ANALYTICS_TICKETS_PRICE = 1;
export const ANALYTICS_TICKETS_COUNT = 2;
export const ANALYTICS_PRODUCTS_PRICE = 3;
export const ANALYTICS_PRODUCTS_COUNT = 4;

export const TARGET_TICKETS_COUNT_PERCENT = 1;
export const TARGET_TICKETS_PRICE_PERCENT = 2;
export const TARGET_TICKETS_PRICE = 3;
export const TARGET_TICKETS_COUNT = 4;
export const TARGET_PRODUCTS_PRICE = 5;
export const TARGET_PRODUCTS_COUNT = 6;

export const MARKET_TICKETS_PRICE = 1;
export const MARKET_TICKETS_COUNT = 2;

const TABLE_TICKETS = 'tickets';
const COLUMN_SUM_PRICE_TICKETS = 'sum(price)';
const COLUMN_COUNT_ITEMS_TICKETS = 'count(id)';

const TABLE_PRODUCTS = 'iiko_order_statistics';
const COLUMN_SUM_PRICE_PRODUCTS = 'sum(total_price)';
const COLUMN_COUNT_ITEMS_PRODUCTS = 'sum(total_count)';

const GROUP_BY_COLUMN = 'app_group_id';

export const AnalyticsSalesProvider = {
  props: {
    cinemaId: {
      default: '',
    },
    regionId: {
      default: '',
    },
    periods: {
      default: () => [],
    },
  },
  render() {
    return this.$scopedSlots.default({
      data: {
        appGroups: this.appGroups,
        appGroupsColors: this.appGroupsColors,
        targets: this.targets,
        marketIndicators: this.marketIndicators,
        analyticsData: this.analyticsData,
      },
      actions: {
        getAnalyticsData: this.getAnalyticsData,
        getTargetData: this.getTargetData,
        getLastAnalyticsDataForAppGroups: this.getLastAnalyticsDataForAppGroups,
        splitAnalyticsDataByAppGroups: this.splitAnalyticsDataByAppGroups,
        getMarketData: this.getMarketData,
      },
    });
  },
  data() {
    return {
      analyticsData: {},
    };
  },
  computed: {
    ...mapState('dictionaries/appGroups', {
      appGroups: (state) => state.items,
    }),

    ...mapGetters('dictionaries/marketIndicators', {
      getMarketIndicators: 'groupByPeriods',
    }),

    ...mapGetters('dictionaries/targets', {
      getTargets: 'groupByPeriods',
    }),

    ...mapGetters('dictionaries/holidays', ['getDay']),

    appGroupsColors() {
      return this.appGroups.map((group, index) => `#${colors[index * 2].toString(16).padStart(6, '0')}`);
    },

    targets() {
      return this.getTargets(this.periods, this.getDayInCinemaRegion);
    },

    marketIndicators() {
      return this.getMarketIndicators(this.periods);
    },
  },
  watch: {
    cinemaId() {
      this.fetchDictionaries();
      this.fetchAnalyticsData();
    },

    periods: {
      handler() {
        this.fetchDictionaries();
        this.fetchAnalyticsData();
      },
      deep: true,
    },
  },
  created() {
    this.fetchAppGroups();
  },
  methods: {
    ...mapActions('dictionaries/marketIndicators', {
      fetchMarketIndicators: 'fetchItems',
    }),

    ...mapActions('dictionaries/targets', {
      fetchTargets: 'fetchItems',
    }),

    ...mapActions('dictionaries/appGroups', {
      fetchAppGroups: 'fetchItems',
    }),

    ...mapActions('dictionaries/holidays', {
      fetchHolidays: 'fetchItems',
    }),

    getAnalyticsKey(source, dateStart, dateEnd) {
      return `${source}_${dateStart}_${dateEnd}`;
    },

    fetchDictionaries() {
      if (!this.cinemaId || !this.periods.length) {
        return;
      }

      const filter = {
        cinemaId: this.cinemaId,
        dateStart: this.periods[0].dateStart,
        dateEnd: this.periods[this.periods.length - 1].dateEnd,
      };

      this.fetchMarketIndicators({
        filter,
      });

      this.fetchTargets({
        filter,
      });

      this.fetchHolidays();
    },

    async fetchAnalyticsData() {
      const queries = [];

      this.analyticsData = {};

      for (let i = 0; i < this.periods.length; i += 1) {
        const { dateStart, dateEnd } = this.periods[i];

        queries.push({
          id: this.getAnalyticsKey(TABLE_TICKETS, dateStart, dateEnd),
          table: TABLE_TICKETS,
          select: [GROUP_BY_COLUMN, COLUMN_SUM_PRICE_TICKETS, COLUMN_COUNT_ITEMS_TICKETS],
          where: {
            cinema_id: this.cinemaId,
            show_date: {
              gte: dateStart,
              lte: dateEnd,
            },
          },
          group_by: [GROUP_BY_COLUMN],
        });

        queries.push({
          id: this.getAnalyticsKey(TABLE_PRODUCTS, dateStart, dateEnd),
          table: TABLE_PRODUCTS,
          select: [GROUP_BY_COLUMN, COLUMN_SUM_PRICE_PRODUCTS, COLUMN_COUNT_ITEMS_PRODUCTS],
          where: {
            cinema_id: this.cinemaId,
            date: {
              gte: dateStart,
              lte: dateEnd,
            },
          },
          group_by: [GROUP_BY_COLUMN],
        });
      }

      const [, result] = await AnalyticsService.fetch(queries);

      this.analyticsData = result;
    },

    getMarketData(name) {
      const result = [];

      for (let i = 0; i < this.periods.length; i += 1) {
        const { dateStart, dateEnd } = this.periods[i];

        const marketIndicator = this.marketIndicators.find((_marketIndicator) => _marketIndicator.dateStart === dateStart && _marketIndicator.dateEnd === dateEnd);

        let value = 0;

        if (marketIndicator) {
          if (name === MARKET_TICKETS_PRICE) {
            value = marketIndicator.ticketsPrice;
          } else if (name === MARKET_TICKETS_COUNT) {
            value = marketIndicator.ticketsCount;
          }
        }

        result.push(value);
      }

      return result;
    },

    getTargetData(name) {
      const result = [];

      for (let i = 0; i < this.periods.length; i += 1) {
        const { dateStart, dateEnd } = this.periods[i];

        const target = this.targets.find((_target) => _target.dateStart === dateStart && _target.dateEnd === dateEnd);

        let value = 0;

        if (target) {
          if (name === TARGET_TICKETS_PRICE) {
            value = target.ticketsPrice;
          } else if (name === TARGET_TICKETS_COUNT) {
            value = target.ticketsCount;
          } else if (name === TARGET_TICKETS_COUNT_PERCENT) {
            value = target.ticketsCountPercent;
          } else if (name === TARGET_TICKETS_PRICE_PERCENT) {
            value = target.ticketsPricePercent;
          } else if (name === TARGET_PRODUCTS_PRICE) {
            value = target.productsPrice;
          } else if (name === TARGET_PRODUCTS_COUNT) {
            value = target.productsCount;
          }
        }

        result.push(value);
      }

      return result;
    },

    getPeriodAnalyticsData(period, source) {
      const { dateStart, dateEnd } = period;
      const key = this.getAnalyticsKey(source, dateStart, dateEnd);

      return this.analyticsData?.[key];
    },

    getSource(name) {
      if (name === ANALYTICS_PRODUCTS_COUNT || name === ANALYTICS_PRODUCTS_PRICE) {
        return TABLE_PRODUCTS;
      }

      if (name === ANALYTICS_TICKETS_COUNT || name === ANALYTICS_TICKETS_PRICE) {
        return TABLE_TICKETS;
      }

      return '';
    },

    getAnalyticsColumn(name) {
      if (name === ANALYTICS_PRODUCTS_COUNT) {
        return COLUMN_COUNT_ITEMS_PRODUCTS;
      }

      if (name === ANALYTICS_TICKETS_COUNT) {
        return COLUMN_COUNT_ITEMS_TICKETS;
      }

      if (name === ANALYTICS_PRODUCTS_PRICE) {
        return COLUMN_SUM_PRICE_PRODUCTS;
      }

      if (name === ANALYTICS_TICKETS_PRICE) {
        return COLUMN_SUM_PRICE_TICKETS;
      }

      return '';
    },

    getAnalyticsData(name) {
      const result = [];

      const source = this.getSource(name);
      const analyticsColumn = this.getAnalyticsColumn(name);

      for (let i = 0; i < this.periods.length; i += 1) {
        let value = 0;
        const data = this.getPeriodAnalyticsData(this.periods[i], source);

        if (data) {
          for (const row of data) {
            value += +(row?.[analyticsColumn] || 0);
          }
        }

        result.push(value);
      }

      return result;
    },

    getLastAnalyticsDataForAppGroups(name) {
      if (!this.periods.length) {
        return [];
      }

      const result = [];

      const source = this.getSource(name);
      const analyticsColumn = this.getAnalyticsColumn(name);

      const period = this.periods[this.periods.length - 1];

      const data = this.getPeriodAnalyticsData(period, source);

      if (!data) {
        return [];
      }

      for (const appGroup of this.appGroups) {
        const row = data.find((_row) => +_row[GROUP_BY_COLUMN] === appGroup.id);

        result.push(+(row?.[analyticsColumn] || 0));
      }

      return result;
    },

    splitAnalyticsDataByAppGroups(name) {
      const result = new Array(this.appGroups.length).fill(null).map(() => new Array(this.periods.length));

      const source = this.getSource(name);
      const analyticsColumn = this.getAnalyticsColumn(name);

      for (let i = 0; i < this.periods.length; i += 1) {
        const data = this.getPeriodAnalyticsData(this.periods[i], source);

        for (let j = 0; j < this.appGroups.length; j += 1) {
          let value = 0;

          if (data) {
            const row = data.find((_row) => +_row?.[GROUP_BY_COLUMN] === this.appGroups[j].id);

            value = +(row?.[analyticsColumn] || 0);
          }

          result[j][i] = value;
        }
      }

      return result;
    },

    getDayInCinemaRegion(date) {
      return this.getDay(this.regionId, date);
    },
  },
};
