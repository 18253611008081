<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Социальная сеть:"
          :invalid-feedback="errors.socialNetworkId"
          :state="states.socialNetworkId"
        >
          <dictionary-provider name="socialNetworks">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.socialNetworkId"
                :options="$array.convertToOptions(items)"
                :state="states.socialNetworkId"
                :text="item.socialNetwork ? item.socialNetwork.name : null"
                :disabled="loading"
                @change="updateValue('socialNetworkId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Ссылка:"
          :invalid-feedback="errors.link"
          :state="states.link"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.link"
            :state="states.link"
            :disabled="loading"
            @change="updateValue('link', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    DictionaryProvider,
  },

  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    convertToOptions(items) {
      return items.map((item) => ({ value: item.id, text: item.name }));
    },

    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
