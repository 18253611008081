export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'status',
    type: 'text',
    label: '',
    filterable: false,
    sortable: false,
  },
  {
    key: 'show',
    type: 'text',
    label: 'Сеанс',
    sortable: false,
    filterable: false,
  },
  {
    key: 'price',
    type: 'text',
    label: 'Стоимость',
    sortable: false,
    filterable: false,
  },
  {
    key: 'paymentTypeId',
    type: 'select',
    label: 'Тип оплаты',
    sortable: false,
    filterable: false,
  },
  {
    key: 'appGroupId',
    type: 'select',
    label: 'Источник',
    sortable: false,
    filterable: false,
  },
  {
    key: 'customer',
    type: 'text',
    label: 'Клиент',
    sortable: false,
    filterable: false,
  },
  {
    key: 'createdAt',
    type: 'datetime',
    label: 'Создан',
    filterable: false,
    sortable: false,
  },
];
