<template>
  <div class="d-flex align-items-center">
    <b-button-group size="sm">
      <b-button
        variant="outline-info"
        :pressed="period == PERIOD_DAY && !manualPeriods.length"
        :disabled="disabled"
        @click="changePeriod(PERIOD_DAY)"
      >День</b-button>

      <b-button
        variant="outline-info"
        :pressed="period == PERIOD_THU_SUN && !manualPeriods.length"
        :disabled="disabled"
        @click="changePeriod(PERIOD_THU_SUN)"
      >Чт - Вс</b-button>

      <b-button
        variant="outline-info"
        :pressed="period == PERIOD_THU_WED && !manualPeriods.length"
        :disabled="disabled"
        @click="changePeriod(PERIOD_THU_WED)"
      >Чт - Ср</b-button>

      <b-button
        variant="outline-info"
        :pressed="period == PERIOD_WEEK && !manualPeriods.length"
        :disabled="disabled"
        @click="changePeriod(PERIOD_WEEK)"
      >Неделя</b-button>

      <b-button
        variant="outline-info"
        :pressed="period == PERIOD_MONTH && !manualPeriods.length"
        :disabled="disabled"
        @click="changePeriod(PERIOD_MONTH)"
      >Месяц</b-button>

      <b-button
        variant="outline-info"
        :pressed="period == PERIOD_QUARTER && !manualPeriods.length"
        :disabled="disabled"
        @click="changePeriod(PERIOD_QUARTER)"
      >Квартал</b-button>

      <b-button
        variant="outline-info"
        :pressed="period == PERIOD_YEAR && !manualPeriods.length"
        :disabled="disabled"
        @click="changePeriod(PERIOD_YEAR)"
      >Год</b-button>
    </b-button-group>

    <date-button
      class="mx-3"
      :date="date"
      :disabled="disabled"
      @select="changeDate($event)"
    ></date-button>

    <b-checkbox
      v-if="!onlyCalendarPeriods"
      class="mr-3"
      :checked="useCalendarPeriods"
      :disabled="disabled"
      @change="changeCalendarPeriods(!useCalendarPeriods)"
    >Календарные периоды</b-checkbox>

    <b-checkbox
      :checked="lfl"
      :disabled="disabled"
      @change="changeLfl(!lfl)"
    >LFL</b-checkbox>

    <slot></slot>

    <b-dropdown
      no-caret
      size="sm"
      class="periods ml-auto"
      text="Сравнение периодов"
      :disabled="disabled"
      :variant="manualPeriods.length ? 'info' : 'outline-info'"
    >
      <b-dropdown-form>
        <date-range
          v-for="(period, index) in manualPeriods"
          opens="left"
          :key="index"
          :date-start="period.dateStart"
          :date-end="period.dateEnd"
          :as-input="false"
          @change="changeManualPeriod(index, $event)"
        >
          <span
            class="text-danger"
            @click.stop="removeManualPeriod(index)"
          >
            <icon icon="fa-times"></icon>
          </span>
        </date-range>
        <date-range
          opens="left"
          :class="[manualPeriods.length ? 'mt-2' : '']"
          :date-start="null"
          :date-end="null"
          :as-input="false"
          @change="addManualPeriod($event)"
        ></date-range>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
import DateButton from '../../../components/fields/DateButton.vue';
import DateRange from '../../../components/fields/DateRange.vue';

import {
  getNotLflPeriods, getLflPeriods, formatPeriod, PERIOD_DAY, PERIOD_THU_SUN, PERIOD_THU_WED, PERIOD_WEEK, PERIOD_MONTH, PERIOD_QUARTER, PERIOD_YEAR,
} from '../../../utils/periods';

export default {
  components: {
    DateButton,
    DateRange,
  },
  props: {
    count: {
      default: 8,
    },
    defaultPeriod: {
      default: PERIOD_DAY,
    },
    disabled: {
      default: false,
    },
    onlyCalendarPeriods: {
      default: true,
    },
  },
  data() {
    return {
      period: this.defaultPeriod,
      manualPeriods: [],
      date: new Date(),
      lfl: false,
      useCalendarPeriods: true,
      PERIOD_DAY,
      PERIOD_THU_SUN,
      PERIOD_THU_WED,
      PERIOD_WEEK,
      PERIOD_MONTH,
      PERIOD_QUARTER,
      PERIOD_YEAR,
    };
  },
  computed: {
    periods() {
      if (this.manualPeriods.length) {
        return this.manualPeriods;
      }

      return this.lfl ? getLflPeriods(this.date, this.period, this.count, this.useCalendarPeriods) : getNotLflPeriods(this.date, this.period, this.count, this.useCalendarPeriods);
    },

    formattedPeriods() {
      const period = this.manualPeriods.length ? PERIOD_WEEK : this.period;

      return this.periods.map((_period) => formatPeriod(_period.dateStart, _period.dateEnd, period, this.lfl, this.useCalendarPeriods));
    },
  },
  watch: {
    count: {
      handler() {
        this.emitPeriods();
      },
      immediate: true,
    },
  },
  methods: {
    emitPeriods() {
      this.$emit('change-date', this.date);
      this.$emit('change', this.periods);
      this.$emit('format', this.formattedPeriods);
    },

    changePeriod(value) {
      this.period = value;
      this.manualPeriods = [];

      this.emitPeriods();
    },

    changeDate(value) {
      this.date = value;
      this.manualPeriods = [];

      this.emitPeriods();
    },

    changeLfl(value) {
      this.lfl = value;
      this.manualPeriods = [];

      this.emitPeriods();
    },

    changeCalendarPeriods(value) {
      this.useCalendarPeriods = value;
      this.manualPeriods = [];

      this.emitPeriods();
    },

    addManualPeriod({ dateStart, dateEnd }) {
      this.manualPeriods.push({ dateStart, dateEnd });

      this.emitPeriods();
    },

    changeManualPeriod(index, { dateStart, dateEnd }) {
      this.manualPeriods.splice(index, 1, { dateStart, dateEnd });

      this.emitPeriods();
    },

    removeManualPeriod(index) {
      this.manualPeriods.splice(index, 1);

      this.emitPeriods();
    },
  },
};
</script>

<style scoped>
.periods >>> .b-dropdown-form {
  min-width: 245px;
}
.ml-auto + .ml-auto {
  margin-left: 0 !important;
}
</style>
