<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Наименование:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        autofocus
        type="text"
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Описание:"
      :invalid-feedback="errors.description"
      :state="states.description"
    >
      <b-form-input
        size="sm"
        :value="item.description"
        :state="states.description"
        :disabled="loading"
        @change="updateValue('description', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Наименование для клиента:"
      :invalid-feedback="errors.nameForClients"
      :state="states.nameForClients"
    >
      <b-form-input
        autofocus
        type="text"
        size="sm"
        :value="item.nameForClients"
        :state="states.nameForClients"
        :disabled="loading"
        @change="updateValue('nameForClients', $event)"
      ></b-form-input>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Период действия скидки</div>
    </div>

    <b-form-group
      label="Дата и время начала и окончания скидки:"
      :invalid-feedback="errors.dateEnd || errors.dateStart"
      :state="states.dateEnd || states.dateStart"
    >
      <date-time-interval
        :value="{from: item.dateStart, to: item.dateEnd}"
        :disabled="loading"
        @selected="updateValue('dateStart', $event.from), updateValue('dateEnd', $event.to)">
      </date-time-interval>
    </b-form-group>

    <b-form-group
      label="Время действия:"
      :invalid-feedback="errors.timeStart || errors.timeEnd"
      :state="states.timeStart || states.timeEnd"
    >
      <time-interval
        :value="{from: item.timeStart, to: item.timeEnd}"
        :disabled="loading"
        @change="updateValue('timeStart', $event.from), updateValue('timeEnd', $event.to)"
      ></time-interval>
    </b-form-group>

    <b-form-group
      label="Дни недели:"
      :invalid-feedback="errors.days"
      :state="states.days"
    >
      <toggle-list
        placeholder="Все"
        :options="days"
        :value="item.days"
        :disabled="loading"
        @change="updateValue('days', $event)"
      ></toggle-list>
    </b-form-group>

    <b-form-group
      label="Активация"
      :invalid-feedback="errors.isEnabled"
      :state="states.isEnabled"
    >
      <b-form-checkbox
        :checked="!!item.isEnabled"
        :disabled="loading"
        :state="states.isEnabled"
        @change="updateValue('isEnabled', $event)"
      >Активировать скидку</b-form-checkbox>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Место действия скидки</div>
    </div>

    <b-form-group
      label="Кинотеатры:"
      :invalid-feedback="errors.cinemas"
      :state="states.cinemas"
    >
      <dictionary-provider name="cinemas">
        <template #default="{ items, loading: dictionaryLoading }">
          <toggle-list
            placeholder="Все"
            :options="convertCinemas($array.union(items, item.cinemas))"
            :value="item.cinemas"
            :disabled="loading || dictionaryLoading"
            @change="updateValue('cinemas', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Категории залов:"
      :invalid-feedback="errors.hallCategories"
      :state="states.hallCategories"
    >
      <dictionary-provider name="hallCategories">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.hallCategories"
            :disabled="loading"
            @change="updateValue('hallCategories', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Группы приложений:"
      :invalid-feedback="errors.appGroups"
      :state="states.appGroups"
    >
      <dictionary-provider name="appGroups">
        <template #default="{ items: appGroups }">
          <toggle-list
            placeholder="Все"
            :options="appGroups"
            :value="item.appGroups"
            :disabled="loading"
            @change="updateValue('appGroups', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Фильтры фильмов, для которых действует скидка</div>
    </div>

    <b-form-group
      label="Возрастное ограничение фильма:"
      :invalid-feedback="errors.ageLimit"
      :state="states.ageLimit"
    >
      <dictionary-provider name="ageLimits">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.ageLimits"
            :disabled="loading"
            @change="updateValue('ageLimits', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Релизы:"
      :invalid-feedback="errors.releases"
      :state="states.releases"
    >
      <dictionary-provider name="showsReleases">
        <template #default="{ items, loading: dictionaryLoading }">
          <toggle-list
            placeholder="Все"
            :options="convertReleases(items)"
            :value="item.releases"
            :searchable="true"
            :disabled="loading || dictionaryLoading"
            @change="updateValue('releases', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Форматы фильмов:"
      :invalid-feedback="errors.formats"
      :state="states.formats"
    >
      <dictionary-provider name="formats2">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.formats"
            :disabled="loading"
            @change="updateValue('formats', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Кому предоставляется скидка</div>
    </div>

    <b-form-group
      label="Льготные категории:"
      :invalid-feedback="errors.privileges"
      :state="states.privileges"
    >
      <dictionary-provider name="privileges">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.privileges"
            :disabled="loading"
            @change="updateValue('privileges', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Параметры изменения цены</div>
    </div>

    <b-form-group
      label="Изменение цены:"
      :invalid-feedback="errors.formula"
      :state="states.formula"
    >
      <price-formula
        :value="item.formula"
        :operations="['-', '=']"
        :disabled="loading"
        @change="updateValue('formula', $event)"
      ></price-formula>
    </b-form-group>

    <dictionary-provider name="colorSchemes">
      <template #default="{ items: colorSchemes }">
        <color-schemes-formulas
          class="mt-3"
          :color-schemes="colorSchemes"
          :color-scheme-formulas="item.colorSchemes"
          :disabled="loading"
          :operations="['-', '=']"
          @change="updateValue('colorSchemes', $event)"
        ></color-schemes-formulas>
      </template>
    </dictionary-provider>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import DateTimeInterval from '../../components/fields/DateTimeInterval.vue';
import PriceFormula from '../../components/fields/PriceFormula.vue';
import TimeInterval from '../../components/fields/TimeInterval.vue';
import ToggleList from '../../components/fields/ToggleList.vue';
import ColorSchemesFormulas from '../../components/special/ColorSchemesFormulas.vue';

export default {
  components: {
    DictionaryProvider,
    DateTimeInterval,
    PriceFormula,
    TimeInterval,
    ToggleList,
    ColorSchemesFormulas,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      days: [
        { id: 1, name: 'ПН' },
        { id: 2, name: 'ВТ' },
        { id: 3, name: 'СР' },
        { id: 4, name: 'ЧТ' },
        { id: 5, name: 'ПТ' },
        { id: 6, name: 'СБ' },
        { id: 7, name: 'ВС' },
      ],
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    convertReleases(releases) {
      if (!releases) {
        return [];
      }

      return releases.map((release) => ({
        id: release.id,
        name: release?.format?.name ? `${release?.movie?.name}, ${release?.format?.name}` : release?.movie?.name,
      }));
    },
    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },
  },
};
</script>
