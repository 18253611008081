<template>
  <div>
    <img
      class="image mw-100"
      :src="imagePath"
      :alt="alt"
    >
  </div>
</template>

<script>
import noPhoto from '../../assets/no_photo.png';

export default {
  props: {
    alt: null,
    image: null,
  },
  computed: {
    imagePath() {
      return this.image || noPhoto;
    },
  },
};
</script>
