<template>
  <layout>
    <template #panel>
      <buttons
        :loading="loading"
        :storage="storage"
        :parent-id="parentId"
        :directories="directories"
        @add-directory="
          modal.directory.show = true,
          modal.directory.id = null,
          modal.directory.name = null
        "
        @click-directory="fetchItemsByDirectortId($event)"
        @upload-files="uploadFiles($event)"
        @update-filter="filter = $event"
      ></buttons>
    </template>
    <template #content>
      <scroll>
        <div class="px-2">
          <files
            class="mt-3"
            mode="list"
            :filter="filter"
            :parent-directory-id="null"
            @click-file="
              modal.fileContent.show = true,
              modal.fileContent.item = $event
            "
          ></files>
        </div>
      </scroll>

      <modal-content
        v-if="modal.fileContent.show"
        :title="modal.fileContent.item.name"
        :hide-footer="true"
        @hide="
          modal.fileContent.show = false,
          modal.fileContent.item = null
        "
      >
        <file-content
          :file="modal.fileContent.item"
        ></file-content>
      </modal-content>

      <modal-directory
        v-if="modal.directory.show"
        :id="modal.directory.id"
        :name="modal.directory.name"
        @hide="
          modal.directory.show = false,
          modal.directory.name = null,
          modal.directory.id = null
        "
      >
      </modal-directory>

      <load-bar
        :items="uploadFilesItems"
        @hide="resetUploadFiles"
        @reload-file="reloadFile($event)"
      >
      </load-bar>
    </template>
  </layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import Layout from './Layout.vue';
import Buttons from './Buttons.vue';
import LoadBar from './LoadBar.vue';
import ModalContent from '../media-panels/ModalContent.vue';
import ModalDirectory from './ModalDirectory.vue';
import FileContent from './FileContent.vue';
import Files from './Files.vue';

export default {
  data: () => ({
    filter: null,
    modal: {
      directory: {
        show: false,
        name: null,
        id: null,
      },
      fileContent: {
        show: false,
        item: null,
        data: null,
      },
    },
  }),
  computed: {
    ...mapGetters({
      directories: 'media/library/directoriesNavigationInListMode',
    }),
    ...mapState('media/library', {
      uploadFilesItems: (state) => state.uploadFilesItems,
      loading: (state) => state.loading,
      parentId: (state) => state.parentId,
      storage: (state) => state.storage,
    }),
  },
  components: {
    Layout,
    Buttons,
    LoadBar,
    ModalContent,
    ModalDirectory,
    FileContent,
    Files,
  },
  methods: {
    ...mapActions({
      changeSort: 'media/library/changeSort',
      fetchItemsByDirectortId: 'media/library/fetchItemsByDirectortId',
      uploadFiles: 'media/library/uploadFiles',
      reloadFile: 'media/library/reloadFile',
      resetUploadFiles: 'media/library/resetUploadFiles',
    }),
  },
};
</script>

<style lang="scss">
img {
  max-width: 100%;
  max-height: 100%;
}
</style>
