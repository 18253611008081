<template>
  <div class="promotion-info">
    <div v-if="item.isPrimary" class="font-weight-bold">Приоритетная акция</div>

    <div
      class="items-index__cinemas"
      :id="`cinemas_${item.id}`"
    >{{convertCinemasToText(item.cinemas)}}</div>
    <b-tooltip
      v-if="item.cinemas && item.cinemas.length"
      :target="`cinemas_${item.id}`"
    >
      <div
        class="mb-1"
        v-for="cinema in item.cinemas"
        :key="cinema.id"
      >
        <div>{{ cinema.name }}, {{ cinema.city.name }}</div>
      </div>
    </b-tooltip>

    <div
      class="items-index__app_groups"
      :id="`app_groups_${item.id}`"
    >{{$array.convertToText(item.appGroups, 'Все источники продаж')}}</div>
    <b-tooltip
      v-if="item.appGroups && item.appGroups.length"
      :target="`app_groups_${item.id}`"
    >
      <div
        class="mb-1"
        v-for="appGroup in item.appGroups"
        :key="appGroup.id"
      >
        <div>{{ appGroup.name }}</div>
      </div>
    </b-tooltip>

    <div>{{$array.convertToText(item.formats, 'Все форматы')}}</div>

    <div
      class="items-index__releases"
      :id="`releases_${item.id}`"
    >{{convertReleasesToText(item.releases)}}</div>
    <b-tooltip
      v-if="item.releases && item.releases.length"
      :target="`releases_${item.id}`"
    >
      <div
        class="mb-1"
        v-for="release in item.releases"
        :key="release.id"
      >
        <div>{{ release.movie.name }}</div>
      </div>
    </b-tooltip>

    <div
      class="items-index__hall_categories"
      :id="`hall_categories_${item.id}`"
    >{{$array.convertToText(item.hallCategories, 'Все категории залов')}}</div>
    <b-tooltip
      v-if="item.hallCategories && item.hallCategories.length"
      :target="`hall_categories_${item.id}`"
    >
      <div
        class="mb-1"
        v-for="hallCategory in item.hallCategories"
        :key="hallCategory.id"
      >
        <div>{{ hallCategory.name }}</div>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: () => ({}),
    },
  },
  methods: {
    convertCinemasToText(cinemas) {
      if (!cinemas.length) {
        return 'Все кинотеатры';
      }

      return cinemas.map((cinema) => `${cinema?.name} ${cinema?.city?.name}`).join(', ');
    },

    convertReleasesToText(releases) {
      if (!releases.length) {
        return 'Все фильмы';
      }

      return releases.map((release) => `${release?.movie?.name}`).join(', ');
    },
  },
};
</script>

<style scoped>
.promotion-info > div {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
