import datetime from '../../utils/datetime';

const AVERAGE_DURATION = 90;

export default class Occupancy {
  constructor(cinema, weeks = [], repertoryItems = []) {
    this.setCinema(cinema);
    this.setWeeks(weeks);
    this.setRepertoryItems(repertoryItems);
  }

  setCinema(cinema) {
    this.cinema = cinema;
  }

  setWeeks(weeks) {
    this.weeks = weeks;
  }

  setRepertoryItems(repertoryItems) {
    this.repertoryItems = repertoryItems;
  }

  getCinemaCapacity(week, averageDuration) {
    if (!this.cinema.halls) {
      return 0;
    }

    // TODO: нужен ли расчет по рабочему времени с учетом выходных?
    let [start, end] = this.cinema.worktimeWeekday ? this.cinema.worktimeWeekday.split('-') : ['00:00', '00:00'];

    // временный хак для работы с границей бизнес-суток
    if (end === '06:00') {
      end = '05:59';
    }

    let worktime = datetime.getDiffInMinutes(end, start);

    if (worktime === 0) {
      worktime = 1440;
    }

    return this.cinema.halls
      .map((hall) => Math.floor(worktime / (averageDuration + hall.interval)) * averageDuration)
      .reduce((total, capacity) => total + capacity, 0);
  }

  getWeeksCapacity() {
    return this.weeks
      .map((week) => {
        const averageDuration = this.getReleasesAverageDuration(week);

        return this.getCinemaCapacity(week, averageDuration);
      });
  }

  getWeeksCharge() {
    return this.weeks.map((week) => this.getReleasesTotalDuration(week));
  }

  getReleasesAverageDuration(week) {
    const weekDateStart = week[0].date;
    const weekDateEnd = week[week.length - 1].date;

    const stat = this.repertoryItems
      .reduce((stats, item) => [
        ...stats,
        ...item.weeks
          .filter((_week) => datetime.isDateSameOrAfter(_week.dateStart, weekDateStart) && datetime.isDateSameOrBefore(_week.dateEnd, weekDateEnd))
          .map((_week) => ({
            duration: item.release.duration * _week.count,
            count: _week.count,
          })),
      ], [])
      .reduce((acc, _stat) => ({
        duration: acc.duration + _stat.duration,
        count: acc.count + _stat.count,
      }), {
        duration: 0,
        count: 0,
      });

    return stat.count === 0 ? AVERAGE_DURATION : Math.round(stat.duration / stat.count);
  }

  getReleasesTotalDuration(week) {
    const weekDateStart = week[0].date;
    const weekDateEnd = week[week.length - 1].date;

    return this.repertoryItems
      .reduce((durations, item) => [
        ...durations,
        ...item.weeks
          .filter((_week) => datetime.isDateSameOrAfter(_week.dateStart, weekDateStart) && datetime.isDateSameOrBefore(_week.dateEnd, weekDateEnd))
          .map((_week) => item.release.duration * _week.count),
      ], [])
      .reduce((acc, duration) => acc + duration, 0);
  }
}
