<template>
  <cinema-page>
    <template #fixed>
      <multi-view class="report report-eais">
        <template #side>
          <params
            :period-start="periodStart"
            :period-end="periodEnd"
            @change-period-start="periodStart = $event"
            @change-period-end="periodEnd = $event"
          ></params>
        </template>

        <template #panel>
          <b-button
            size="sm"
            variant="outline-info"
            :to="{ name: 'reportIndex' }"
          >
            <span slot="text">
              <icon icon="fa-chevron-left"></icon> Назад
            </span>
          </b-button>
        </template>

        <template #content>
          <div class="p-3 w-100">
            <report
              :period-start="periodStart"
              :period-end="periodEnd"
            ></report>
          </div>
        </template>
      </multi-view>
    </template>
  </cinema-page>
</template>

<script>
import CinemaPage from '../../../layout/CinemaPage.vue';
import MultiView from '../../../layout/MultiView.vue';
import Params from './Params.vue';
import Report from './Report.vue';

export default {
  components: {
    CinemaPage,
    MultiView,
    Params,
    Report,
  },
  data() {
    return {
      periodStart: null,
      periodEnd: null,
    };
  },
  destroyed() {
    this.periodStart = null;
    this.periodEnd = null;
  },
};
</script>
