import humps from 'humps';

import fetchDictionary from '../api/dictionary';

export default function factory(collection) {
  if (collection.includes('advertisement')) {
    // eslint-disable-next-line no-param-reassign
    collection = collection.replace(/advertisement/i, 'adv');
  }

  const decamelizedCollectionName = humps.decamelize(collection);

  return {
    async fetchDictionary({ filter }) {
      const [err, data] = await fetchDictionary(decamelizedCollectionName, {
        filter: humps.decamelizeKeys(filter),
      });

      return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
    },
  };
}
