<template>
  <b-row>
    <b-col cols="12">
      <b-form-group label="Состав карточки времени:">
        <b-form-checkbox
          v-model="settingLocal.custom.showScheduleFormat"
          :value="1"
          :unchecked-value="0"
        >
          Формат фильма
        </b-form-checkbox>
        <b-form-checkbox
          v-model="settingLocal.custom.showSchedulePrice"
          :value="1"
          :unchecked-value="0"
        >
          Цена
        </b-form-checkbox>
        <b-form-checkbox
          v-model="settingLocal.custom.showScheduleHall"
          :value="1"
          :unchecked-value="0"
        >
          Категория зала
        </b-form-checkbox>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          switch
          v-model="settingLocal.custom.showLimitDeliveryDays"
          :value="1"
          :unchecked-value="0"
          @click.native="setDaysAfterRelease"
        >
          <div class="h6 mt-1 mb-0">Ограничить дни показа</div>
        </b-form-checkbox>
        <b-form-group
          class="setting-posters__group mb-0 pt-3"
          v-if="convertToBoolean(settingLocal.custom.showLimitDeliveryDays)"
        >
          <div class="w-100 d-flex">
            <b-form-select
              v-model="settingLocal.custom.daysAfterRelease"
              size="sm"
              class="setting-posters__dates"
              :options="optionsDays"
            >
            </b-form-select>
            <div class="pl-2 text-nowrap d-flex align-items-center">
              после даты релиза
            </div>
          </div>
        </b-form-group>
      </b-form-group>

      <b-form-group label="Ротация карточек фильма (сек.):">
        <b-form-radio-group
          v-model="settingLocal.custom.duration"
          :options="durations"
        ></b-form-radio-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { HORIZONTAL_POSTER_MODE_OPTIONS as options } from './_index.settings';

import declOfNum from '../../utils/declOfNum';

export default {
  props: {
    setting: {
      default: () => ({}),
    },
    durations: {
      default: () => [],
    },
  },
  data: () => ({
    optionsDays: [...new Array(98)].map((item, index) => ({
      value: index + 1,
      text: `${index + 1} ${declOfNum(index + 1, ['день', 'дня', 'дней'])}`,
    })),
    options,
    disableLimitDeliveryDays: true,
    settingLocal: {
      mode: 'SCHEDULE',
      custom: {
        bigSessionCards: 1,
        showSchedule: 1,
        showScheduleHall: 1,
        showScheduleFormat: 1,
        showSchedulePrice: 1,
        showLimitDeliveryDays: 1,
        daysBeforeRelease: 0,
        daysAfterRelease: 7,
        duration: 5,
      },
    },
  }),
  computed: {
    mode() {
      return this.settingLocal.mode;
    },
  },
  watch: {
    mode: {
      handler(value) {
        if (value === 'SCHEDULE') {
          this.settingLocal.custom.daysAfterRelease = this.settingLocal.custom.showLimitDeliveryDays ? (
            this.setting ? (this.setting.custom.daysAfterRelease ? this.setting.custom.daysAfterRelease : 7) : 7
          ) : 0;
        }
      },
      immediate: true,
    },
    settingLocal: {
      handler(setting) {
        this.$emit('change', setting);
      },
      immediate: true,
      deep: true,
    },
    setting: {
      handler(setting) {
        if (!setting) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(this.settingLocal.custom)) {
          if (typeof setting.custom[key] !== 'undefined') {
            // отключаем поле "Ограничить дни показа" если "daysAfterRelease" со значением 0
            if (key === 'daysAfterRelease') {
              if (setting.custom[key]) {
                this.settingLocal.custom.showLimitDeliveryDays = 1;
              } else {
                this.settingLocal.custom.showLimitDeliveryDays = 0;
              }
            }

            this.settingLocal.custom[key] = setting.custom[key];
          }
        }

        if (setting.mode) {
          this.settingLocal.mode = this.setting.mode;
        }
      },
      immediate: true,
    },
  },
  methods: {
    setDaysAfterRelease(evt) {
      if (evt.target.checked) {
        this.settingLocal.custom.daysAfterRelease = 7;
      } else {
        this.settingLocal.custom.daysAfterRelease = 0;
      }
    },
    convertToBoolean(value) {
      return !!+value;
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-posters {
  &__dates,
  &__times {
    width: auto;
  }
  &__group {
    margin-left: 2.25rem;
  }
}
.custom-control.custom-switch {
  display: inline-block;
}
</style>
