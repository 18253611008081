<template>
  <i :class="classes"></i>
</template>

<script>
export default {
  props: {
    icon: {
      default: '',
    },
  },
  computed: {
    classes() {
      if (this.icon.startsWith('mdi')) {
        return [
          'mdi',
          this.icon,
        ];
      } if (this.icon.startsWith('fa')) {
        return [
          'fa',
          'fa-fw',
          this.icon,
        ];
      }

      return [];
    },
  },
};
</script>

<style>
@import '~@mdi/font/css/materialdesignicons.css';
@import '~font-awesome/css/font-awesome.min.css';
@import '~simple-line-icons/css/simple-line-icons.css';
</style>
