export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'hallId',
    type: 'text',
    label: 'Зал',
    sortable: false,
    filterable: false,
  },
  {
    key: 'mediaServerCertificateSerial',
    type: 'text',
    label: 'Серийный номер сертификата медиа-сервера',
    sortable: false,
    filterable: true,
  },
];
