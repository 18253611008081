<template>
  <cinema-page>
    <template #fixed>
      <multi-view class="report report-rentrak">
        <template #side>
          <params
            :period-start="periodStart"
            :period-end="periodEnd"
            @change-period-start="periodStart = $event"
            @change-period-end="periodEnd = $event"
          ></params>
        </template>

        <template #panel>
          <div class="d-flex align-items-center">
            <b-button
              size="sm"
              variant="outline-info"
              :to="{ name: 'reportIndex' }"
            >
              <span slot="text">
                <icon icon="fa-chevron-left"></icon> Назад
              </span>
            </b-button>

            <b-button
              v-if="$user.can('reports.rentrak.edit')"
              size="sm"
              variant="info"
              class="ml-auto"
              @click="modals.createForm.show = true"
            >
              <span slot="text">Сформировать отчёт</span>
            </b-button>
          </div>
        </template>

        <template #content>
          <div class="p-3 w-100">
            <report
              :period-start="periodStart"
              :period-end="periodEnd"
            ></report>
          </div>
        </template>
      </multi-view>

      <modal-model-form
        v-if="modals.createForm.show"
        create-title="Создание отчёта"
        collection="reports/rentrak"
        :init="{ cinemaId }"
        @close="modals.createForm.show = false"
        @saved="fetchItems()"
      >
        <template #default="{ data, actions }">
          <create-form
            :loading="data.loading"
            :errors="data.errors"
            :states="data.states"
            :item="data.item"
            @change="actions.setValue($event.field, $event.value)"
          ></create-form>
        </template>
      </modal-model-form>
    </template>
  </cinema-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CinemaPage from '../../../layout/CinemaPage.vue';
import MultiView from '../../../layout/MultiView.vue';
import Params from './Params.vue';
import Report from './Report.vue';
import CreateForm from './CreateForm.vue';
import ModalModelForm from '../../../components/modal/ModalModelForm.vue';

export default {
  components: {
    CinemaPage,
    MultiView,
    Params,
    Report,
    ModalModelForm,
    CreateForm,
  },
  data() {
    return {
      periodStart: null,
      periodEnd: null,
      modals: {
        createForm: {
          show: false,
        },
      },
    };
  },
  computed: {
    ...mapState(['cinemaId']),
  },
  destroyed() {
    this.periodStart = null;
    this.periodEnd = null;
  },
  methods: {
    ...mapActions({
      fetchItems: 'data/reportsRentrak/fetchItems',
    }),
  },
};
</script>
