<template>
  <div class="video-viewer text-center">
    <span
      class="video-viewer__trash-button"
      v-if="video"
      @click="$emit('remove')"
    >
      <icon icon="fa-times"></icon>
    </span>
    <video
      v-if="video"
      class="video-viewer__img"
      :src="video"
      autoplay="true"
      loop="true"
      muted="true"
      controls="true"
    ></video>
  </div>
</template>

<script>

export default {
  props: {
    video: {
      default: '',
    },
  },
};
</script>

<style scoped>
.video-viewer {
  position: relative;
}
.video-viewer__img {
  max-width: 250px;
}
.video-viewer__trash-button {
  position: absolute;
  right: 0;
  top: -4px;
  color: #AA0000;
  cursor: pointer;
}
</style>
