<template>
  <div class="movies__group">
    <div class="movies__group-header">
      {{ formatDate }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      default: null,
    },
  },
  computed: {
    formatDate() {
      if (!this.date.end) {
        return this.date.start && this.$datetime.format(this.date.start, 'D MMMM, dddd');
      }

      const format = 'MMMM YYYY';
      const dateStart = new Date(this.date.start);
      const dateEnd = new Date(this.date.end);
      const comparisonToYear = dateStart.getFullYear() === dateEnd.getFullYear();
      const comparisonToMonth = dateStart.getMonth() === dateEnd.getMonth();

      if (comparisonToMonth && comparisonToYear) {
        return this.$datetime.format(this.date.start, format);
      }

      if (comparisonToYear) {
        return `${this.$datetime.format(this.date.start, 'MMMM')} — ${this.$datetime.format(this.date.end, format)}`;
      }

      return `${this.$datetime.format(this.date.start, format)} — ${this.$datetime.format(this.date.end, format)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.movies__group-header {
  font-size: 1.85em;
}
</style>
