<template>
  <cinema-provider
    :route-name="routeName"
    :route-param-name="routeParamName"
    @loaded="$emit('loaded', $event)"
  >
    <template #default="{ cinemaId, cinema, cinemas, halls, openCinema, loading }">
      <div class="h-100 d-flex flex-column">
        <portal to="topbar">
          <cinemas-select
            :cinemas="cinemas"
            :cinema-id="cinemaId"
            :disabled="loading"
            @change="openCinema($event)"
          ></cinemas-select>
        </portal>
        <div
          v-if="$slots.buttons || $scopedSlots.buttons"
          class="d-flex border-bottom shadow-sm p-2 z-1"
        >
          <slot
            name="buttons"
            :cinema-id="cinemaId"
            :cinema="cinema"
            :cinemas="cinemas"
            :halls="halls"
            :loading="loading"
            :open-cinema="openCinema"
          ></slot>
        </div>
        <scroll v-if="$slots.scroll || $scopedSlots.scroll">
          <div class="pt-3 pb-3 pl-2 pr-2">
            <loading v-if="loading && displayLoadingStub"></loading>
            <slot
              v-if="!loading"
              name="scroll"
              :cinema-id="cinemaId"
              :cinema="cinema"
              :cinemas="cinemas"
              :halls="halls"
              :loading="loading"
              :open-cinema="openCinema"
            ></slot>
          </div>
        </scroll>
        <div
          v-if="$slots.fixed || $scopedSlots.fixed"
          class="w-100 h-100 p-0 overflow-hidden"
        >
          <loading v-if="loading && displayLoadingStub"></loading>
          <slot
            v-if="!loading"
            name="fixed"
            :cinema-id="cinemaId"
            :cinema="cinema"
            :cinemas="cinemas"
            :halls="halls"
            :loading="loading"
            :open-cinema="openCinema"
          ></slot>
        </div>
      </div>
    </template>
  </cinema-provider>
</template>

<script>
import CinemasSelect from '../components/special/CinemasSelect.vue';
import CinemaProvider from '../providers/CinemaProvider.vue';
import Loading from '../components/Loading.vue';

export default {
  components: {
    CinemaProvider,
    CinemasSelect,
    Loading,
  },
  props: {
    routeName: {
      default: null,
    },
    routeParamName: {
      default: null,
    },
    displayLoadingStub: {
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.z-1 {
  z-index: 1;
}
</style>
