<template>
  <div class="p-3 border rounded">
    <div class="d-flex mb-2">
      <div class="font-weight-bold">{{ title }}</div>
      <div class="ml-auto d-flex">
        <div>
          <span class=" font-weight-bold primary">{{ value }}</span>
          {{ yLabel }}
        </div>
        <div
          :class="['ml-1', 'percent', percent > 0 ? 'text-success' : 'text-danger']"
        >{{ sign }}{{ percent }}%</div>
      </div>
    </div>
    <chart-line
      :data="[data]"
      :periods="periods"
      :y-min="yMin"
      :y-max="yMax"
      :y-step="yStep"
      :y-label="yLabel"
      :y-auto-scale="yAutoScale"
      :aspect-ratio="aspectRatio"
    ></chart-line>
  </div>
</template>

<script>
import ChartLine from '../../../components/charts/ChartLine.vue';

export default {
  components: {
    ChartLine,
  },
  props: {
    title: {
      default: '',
    },
    data: {
      default: () => [],
    },
    periods: {
      default: () => [],
    },
    yMin: {
      default: 0,
    },
    yMax: {
      default: 100,
    },
    yStep: {
      default: 50,
    },
    yLabel: {
      default: '%',
    },
    yAutoScale: {
      default: false,
    },
    aspectRatio: {
      default: 1,
    },
  },
  computed: {
    value() {
      if (!this.data.length) {
        return 0;
      }

      return this.data[this.data.length - 1].toLocaleString();
    },

    percent() {
      if (this.data.length < 2) {
        return 0;
      }

      const lastValue = this.data[this.data.length - 1];
      const prevValue = this.data[this.data.length - 2];

      const ratio = prevValue ? (lastValue - prevValue) / prevValue : 0;

      return (ratio * 100).toFixed(2);
    },

    sign() {
      if (!this.percent) {
        return '';
      }

      return this.percent > 0 ? '+' : '';
    },
  },
};
</script>

<style scoped>
.percent {
  font-size: 0.9em;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.primary {
  color: #007BFF;
}
</style>
