<template>
  <cinema-page
    @loaded="resetCheckedRows()"
  >
    <template #buttons>
      <dictionary-provider
        name="advertisementTypes"
        @loaded="init($event)"
      >
        <template #default="{ items: advertisementTypes }">
          <div class="d-flex w-100">
            <index-tabs
              class="mr-2"
              :advertisement-type-id="advertisementTypeId"
              :advertisement-types="advertisementTypes"
              @select="setAdvertisementTypeId($event)"
            ></index-tabs>
            <b-button
              class="ml-auto"
              variant="info"
              size="sm"
              @click="exportAdvertisements()"
            >Эфирная справка</b-button>
          </div>
        </template>
      </dictionary-provider>
    </template>
    <template #scroll="{ halls, cinemaId, cinema }">
      <collection-provider
        collection="advertisements"
        ref="collection"
        :page="null"
        :limit="null"
        :filter="{
          advertisementTypeId,
          cinemaId,
          mode,
          dateStart: convertDateToDbFormat(dateStart),
          dateEnd: convertDateToDbFormat(dateEnd)
        }"
        :params="{ includePositions: !advertisementTypeId ? '1' : '0' }"
        :disable-fetching="!advertisementTypesLoaded || !cinemaId"
      >
        <template #default="{ data, actions }">
          <div>
            <datatable
              v-if="advertisementTypeId"
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :panel-actions="datatable.panelActions"
              :item-actions="datatable.itemActions"
              :checked="datatable.checked"
              @change-checked="datatable.checked = $event"
              @panel-action="performPanelAction($event)"
              @item-action="performItemAction($event)"
            >
              <template #toolbar-content>
                <b-dropdown
                  v-if="advertisementTypeId"
                  size="sm"
                  variant="info"
                  :text="modes[mode]"
                >
                  <b-dropdown-item
                  v-for="(name, code) in modes"
                  :key="code"
                  @click="setMode(code)"
                  >{{name}}</b-dropdown-item>
                </b-dropdown>
              </template>
              <template #filter(halls)>
                <b-form-select
                  size="sm"
                  :value="data.filter.hallId"
                  :options="$array.convertToOptions(halls)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('hallId', $event)"
                ></b-form-select>
              </template>
              <template #filter(period)>
                <calendar-picker
                  v-if="mode == 'weekly' || mode == 'daily'"
                  :calendar-type="mode == 'weekly' ? 'week' : 'day'"
                  :date="dateStart"
                  @select-date="changeFilterDates($event)"
                ></calendar-picker>
                <span v-else></span>
              </template>
              <template #cell(name)="data">
                <div>
                  <span
                    v-if="!data.item.enabled"
                    v-b-tooltip="'Остановлено'"
                    class="text-dark mr-1 cursor-pointer"
                    @click="resumeAdvertisement(data.item.id)"
                  ><icon icon="fa-pause-circle"></icon></span>
                  <span
                    v-if="data.item.enabled"
                    v-b-tooltip="'Запущено'"
                    class="text-success mr-1 cursor-pointer"
                    @click="pauseAdvertisement(data.item.id)"
                  ><icon icon="fa-circle"></icon></span>
                  <span>{{data.item.name}}</span>
                </div>
                <div
                  v-if="data.item.comment"
                  class="text-muted mt-2"
                >{{data.item.comment}}</div>
              </template>
              <template #cell(duration)="data">
                <div>{{formatTime(data.item.duration)}}</div>
              </template>
              <template #cell(positionType)="data">
                <div>
                  <b-badge v-if="data.item.positionType === 2" variant="danger">Последний</b-badge>
                  <b-badge v-if="data.item.positionType === 1" variant="warning">Предпоследний</b-badge>
                  <b-badge v-if="data.item.positionType === 0" variant="Secondary">Не важно</b-badge>
                </div>
              </template>
              <template #cell(period)="data">
                <template v-if="data.item.dateStart">
                  c {{formatDate(data.item.dateStart)}}
                </template>
                <template v-if="data.item.dateEnd">
                  по {{formatDate(data.item.dateEnd)}}
                </template>
              </template>
              <template #cell(halls)="data">
                <div>{{$array.convertToText(data.item.halls, 'Все залы')}}</div>
              </template>
              <template #cell(targeting)="data">
                <advertisement-info :advertisement="data.item"></advertisement-info>
              </template>
            </datatable>
            <advertisement-positions
              v-if="advertisementTypesLoaded && !advertisementTypeId"
              :advertisements="data.items"
              :advertisement-types="advertisementTypes"
              :loading="data.loading"
              :date-start="dateStart"
              :date-end="dateEnd"
              @select-date="changeFilterDates($event)"
              @change-positions="changePositions($event)"
            ></advertisement-positions>
          </div>
        </template>
      </collection-provider>

      <modal-model-form
        v-if="modals.advertisement.show"
        create-title="Создание ролика"
        edit-title="Редактирование ролика"
        collection="advertisements"
        :id="modals.advertisement.id"
        :init="{ cinemaId, advertisementTypeId, positionType: 0 }"
        @close="modals.advertisement.show = false"
        @saved="$refs.collection.fetchItems()"
      >
        <template #default="{ data, actions }">
          <advertisement-form
            :loading="data.loading"
            :errors="data.errors"
            :states="data.states"
            :item="data.item"
            :halls="halls"
            @change="actions.setValue($event.field, $event.value)"
          ></advertisement-form>
        </template>
      </modal-model-form>

      <modal-export-advertisements-form
        v-if="modals.export.show"
        :cinema="cinema"
        :init-date-start="modals.export.dateStart"
        :init-date-end="modals.export.dateEnd"
        :advertisement-type-id="modals.export.advertisementTypeId"
        :advertisement-ids="modals.export.advertisementIds"
        :sameAdvertisementForAllCinemas="modals.export.sameAdvertisementForAllCinemas"
        @hide="closeExportAdvertisementsModal()"
      ></modal-export-advertisements-form>
    </template>
  </cinema-page>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import CinemaPage from '../../layout/CinemaPage.vue';
import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import DictionaryProvider from '../../providers/DictionaryProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import AdvertisementForm from './AdvertisementForm.vue';
import CalendarPicker from '../../components/CalendarPicker.vue';
import AdvertisementPositions from './AdvertisementsPositions.vue';
import IndexTabs from './IndexTabs.vue';
import ModalExportAdvertisementsForm from './ModalExportAdvertisementsForm.vue';
import AdvertisementInfo from './AdvertisementInfo.vue';

import datetime from '../../utils/datetime';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    DictionaryProvider,
    ModalModelForm,
    Datatable,
    AdvertisementForm,
    CalendarPicker,
    AdvertisementPositions,
    IndexTabs,
    ModalExportAdvertisementsForm,
    AdvertisementInfo,
  },
  data() {
    return {
      advertisementTypes: [],
      advertisementTypeId: null,
      advertisementTypesLoaded: false,
      mode: 'active',
      dateStart: null,
      dateEnd: null,
      modes: {
        all: 'Все кампании',
        active: 'Активные',
        past: 'Прошедшие',
        weekly: 'Понедельно',
        daily: 'По дням',
      },
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        advertisement: {
          show: false,
          id: 0,
        },
        export: {
          show: false,
          advertisementTypeId: null,
          advertisementIds: [],
          dateStart: null,
          dateEnd: null,
          sameAdvertisementForAllCinemas: 0,
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    init(items) {
      this.advertisementTypes = items;
      this.advertisementTypeId = items.length ? items[0].id : null;
      this.advertisementTypesLoaded = true;
    },

    setAdvertisementTypeId(id) {
      this.datatable.checked = [];

      this.$refs.collection.reset();

      this.advertisementTypeId = id;

      if (!id) {
        this.setMode('weekly');
      } else {
        this.setMode('active');
      }
    },

    setMode(code) {
      this.mode = code;

      this.changeFilterDates(new Date());
    },

    changeFilterDates(date) {
      if (this.mode === 'weekly') {
        const { dateStart, dateEnd } = datetime.getWeekByDate(date);

        this.dateStart = dateStart;
        this.dateEnd = dateEnd;
      } else if (this.mode === 'daily') {
        this.dateStart = date;
        this.dateEnd = date;
      } else {
        this.dateStart = null;
        this.dateEnd = null;
      }
    },

    openCreateAdvertisementForm() {
      this.modals.advertisement.id = '';
      this.modals.advertisement.show = true;
    },

    async removeCheckedAdvertisements() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    pauseCheckedAdvertisements() {
      this.$refs.collection.changeItems(this.datatable.checked, { enabled: 0 });
      this.datatable.checked = [];
    },

    resumeCheckedAdvertisements() {
      this.$refs.collection.changeItems(this.datatable.checked, { enabled: 1 });
      this.datatable.checked = [];
    },

    openEditAdvertisementForm(id) {
      this.modals.advertisement.id = id;
      this.modals.advertisement.show = true;
    },

    async removeAdvertisement(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    pauseAdvertisement(id) {
      this.$refs.collection.changeItem(id, { enabled: 0 });
    },

    resumeAdvertisement(id) {
      this.$refs.collection.changeItem(id, { enabled: 1 });
    },

    formatDate(date) {
      return datetime.formatDate(date);
    },

    formatTime(seconds) {
      return seconds ? new Date(1000 * seconds).toISOString().substr(14, 5) : '00:00';
    },

    async changePositions(values) {
      await this.$store.dispatch('data/advertisements/changePositions', { values });
    },

    convertDateToDbFormat(date) {
      return date ? datetime.convertDateToDbFormat(date) : null;
    },

    exportAdvertisements() {
      const { dateStart, dateEnd } = datetime.getWeekByDate(new Date());

      this.modals.export.show = true;
      this.modals.export.advertisementTypeId = this.advertisementTypeId;
      this.modals.export.advertisementIds = this.datatable.checked;
      this.modals.export.dateStart = this.dateStart || dateStart;
      this.modals.export.dateEnd = this.dateEnd || dateEnd;
    },

    exportAdvertisementForAllCinemas(id) {
      const { dateStart, dateEnd } = datetime.getWeekByDate(new Date());

      this.modals.export.show = true;
      this.modals.export.advertisementTypeId = this.advertisementTypeId;
      this.modals.export.advertisementIds = [id];
      this.modals.export.dateStart = this.dateStart || dateStart;
      this.modals.export.dateEnd = this.dateEnd || dateEnd;
      this.modals.export.sameAdvertisementForAllCinemas = 1;
    },

    closeExportAdvertisementsModal() {
      const { dateStart, dateEnd } = datetime.getWeekByDate(new Date());

      this.modals.export.show = false;
      this.modals.export.advertisementTypeId = null;
      this.modals.export.advertisementIds = [];
      this.modals.export.dateStart = dateStart;
      this.modals.export.dateEnd = dateEnd;
      this.modals.export.sameAdvertisementForAllCinemas = 0;
    },

    resetCheckedRows() {
      this.datatable.checked = [];
    },
  },
};
</script>
