var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dictionary-map',{attrs:{"keys":_vm.apps,"items":_vm.createMapItems(_vm.appPrices),"disabled":_vm.disabled,"texts":{
    add: 'Добавить цену для группы приложений',
    empty: 'Цены для групп приложений не указаны',
    key: 'Группа приложений',
    value: 'Цена',
  }},on:{"change":_vm.emitChange},scopedSlots:_vm._u([{key:"value",fn:function(ref){
  var disabled = ref.disabled;
  var item = ref.item;
  var setValue = ref.setValue;
return [_c('b-form-input',{ref:"el",attrs:{"size":"sm","type":"text","value":item.value,"disabled":disabled},on:{"change":function($event){return setValue($event)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }