<template>
  <div>
    <h2 class="mb-2">Абсолютные показатели за {{ formattedPeriods.at(-1) }} в сравнении с {{ formattedPeriods.at(-2) }}</h2>

    <b-table
      class="datatable border"
      :bordered="true"
      :fields="fields"
      :items="items"
      :striped="true"
      :hover="false"
      :show-empty="true"
      :empty-text="loading ? 'Загрузка...' : 'Нет данных'"
    >
      <template #cell()="data">
        <div
          :class="[
            data.item.cinemaId === currentCinemaId ? 'font-weight-bold' : '',
            data.field.align ? `text-${data.field.align}` : '',
            data.field.color ? data.field.color(data.unformatted) : '',
          ]"
        >{{ data.value }}</div>
      </template>
    </b-table>
  </div>
</template>

<script>

export default {
  props: {
    loading: {
      default: false,
    },
    currentCinema: {
      default: () => ({}),
    },
    marketData: {
      default: () => ({}),
    },
    cinemasData: {
      default: () => ({}),
    },
    rentrakCinemas: {
      default: () => [],
    },
    periods: {
      default: () => [],
    },
    formattedPeriods: {
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: '',
          filterable: false,
          sortable: false,
          align: 'left',
        },
        {
          key: 'hallsCount',
          label: 'Кол-во залов',
          filterable: false,
          sortable: true,
          align: 'left',
        },
        {
          key: 'count',
          label: 'Билеты',
          filterable: false,
          sortable: true,
          align: 'center',
          formatter: (value) => value.toLocaleString(),
        },
        {
          key: 'price',
          label: 'Вал, руб',
          filterable: false,
          sortable: true,
          align: 'right',
          formatter: (value) => value.toLocaleString(),
        },
        {
          key: 'averagePrice',
          label: 'Ср. цена, руб',
          filterable: false,
          sortable: true,
          align: 'center',
          formatter: (value) => value.toLocaleString(),
        },
        {
          key: 'countPercent',
          label: 'Доля рынка, билеты',
          filterable: false,
          sortable: true,
          align: 'center',
          formatter: (value) => `${value}%`,
        },
        {
          key: 'pricePercent',
          label: 'Доля рынка, вал',
          filterable: false,
          sortable: true,
          align: 'center',
          formatter: (value) => `${value}%`,
        },
        {
          key: 'countRatio',
          label: 'Билеты',
          filterable: false,
          sortable: true,
          align: 'center',
          formatter: (value, key, item) => `${item.countDiff.toLocaleString()} (${item.countRatio}%)`,
          color: (value) => (value > 0 ? 'text-success' : 'text-danger'),
        },
        {
          key: 'priceRatio',
          label: 'Вал',
          filterable: false,
          sortable: true,
          align: 'center',
          formatter: (value, key, item) => `${item.priceDiff.toLocaleString()} (${item.priceRatio}%)`,
          color: (value) => (value > 0 ? 'text-success' : 'text-danger'),
        },
        {
          key: 'averagePriceDiff',
          label: 'Ср. цена, руб',
          filterable: false,
          sortable: true,
          align: 'center',
          formatter: (value) => value.toLocaleString(),
          color: (value) => (value > 0 ? 'text-success' : 'text-danger'),
        },
      ],
    };
  },
  computed: {
    cinemaIds() {
      if (!this.currentCinema || !this.currentCinema.id) {
        return [];
      }

      return [this.currentCinema.id, ...(this.currentCinema.relatedCinemas || [])];
    },

    currentCinemaId() {
      if (!this.currentCinema || !this.currentCinema.id) {
        return null;
      }

      return this.currentCinema.id;
    },

    currentMarketData() {
      return this.marketData[this.currentCinemaId] || [];
    },

    items() {
      return this.cinemaIds
        .map((cinemaId) => {
          const cinemaCount = this.getCinemaValue(cinemaId, this.periods.at(-1), 'count');
          const cinemaPrice = this.getCinemaValue(cinemaId, this.periods.at(-1), 'price');
          const averagePrice = cinemaCount ? Math.round(cinemaPrice / cinemaCount) : 0;
          const cinemaPrevCount = this.getCinemaValue(cinemaId, this.periods.at(-2), 'count');
          const cinemaPrevPrice = this.getCinemaValue(cinemaId, this.periods.at(-2), 'price');
          const prevAveragePrice = cinemaPrevCount ? Math.round(cinemaPrevPrice / cinemaPrevCount) : 0;
          const marketCount = this.getMarketValue(this.periods.at(-1), 'count');
          const marketPrice = this.getMarketValue(this.periods.at(-1), 'price');

          return {
            cinemaId,
            name: this.getCinemaName(cinemaId),
            hallsCount: this.getCinemaHallsCount(cinemaId),
            count: cinemaCount,
            price: cinemaPrice,
            averagePrice,
            countPercent: this.calcPercent(cinemaCount, marketCount),
            pricePercent: this.calcPercent(cinemaPrice, marketPrice),
            countRatio: this.calcPercent(cinemaCount - cinemaPrevCount, cinemaPrevCount, 100),
            priceRatio: this.calcPercent(cinemaPrice - cinemaPrevPrice, cinemaPrevPrice, 100),
            countDiff: cinemaCount - cinemaPrevCount,
            priceDiff: cinemaPrice - cinemaPrevPrice,
            averagePriceDiff: averagePrice - prevAveragePrice,
          };
        });
    },
  },
  methods: {
    getCinemaName(cinemaId) {
      const rentrakCinema = this.rentrakCinemas.find((_rentrakCinema) => _rentrakCinema.id === cinemaId);

      return rentrakCinema ? rentrakCinema.name : '';
    },

    getCinemaHallsCount(cinemaId) {
      const rentrakCinema = this.rentrakCinemas.find((_rentrakCinema) => _rentrakCinema.id === cinemaId);

      return rentrakCinema ? rentrakCinema.halls : '';
    },

    getCinemaValue(cinemaId, period, type) {
      const cinemaData = this.cinemasData[cinemaId].find((_data) => _data.dateStart === period.dateStart && _data.dateEnd === period.dateEnd);

      return cinemaData ? +cinemaData[type] : 0;
    },

    getMarketValue(period, type) {
      const marketData = this.currentMarketData.find((_data) => _data.dateStart === period.dateStart && _data.dateEnd === period.dateEnd);

      return marketData ? +marketData[type] : 0;
    },

    calcPercent(value, total, unknownPercent = 0) {
      return total ? ((value / total) * 100).toFixed(1) : unknownPercent;
    },
  },
};
</script>

<style scoped>
.datatable >>> th {
  text-align: center !important;
}
.table-bordered >>> th, .table-bordered >>> td {
  border: 1px solid #dee2e6 !important;
}
.font-weight-bold {
  font-weight: 500 !important;
}
</style>
