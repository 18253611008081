<template>
  <b-modal
    ref="modal"
    centered
    size="lg"
    ok-variant="success"
    ok-title="Сохранить"
    cancel-title="Закрыть"
    :visible="true"
    :title="title"
    :busy="loading"
    @ok="ok"
    @hidden="$emit('hide')"
  >
    <b-container fluid class="p-0">
      <b-form-group
        label="Название:"
        :invalid-feedback="errors.name"
        :state="states.name"
      >
        <b-form-input
          autofocus
          type="text"
          size="sm"
          v-model="item.name"
          :state="states.name"
          :disabled="loading"
        ></b-form-input>
      </b-form-group>
    </b-container>
  </b-modal>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export default {
  props: {
    id: null,
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      errors: {},
      states: {},
      item: {
        ext: this.name.split('.').pop(),
        name: this.name.split('.').slice(0, -1).join('.'),
      },
    };
  },
  computed: {
    title() {
      return this.id ? 'Редактирование' : 'Создание';
    },
  },
  methods: {
    setError(field, value) {
      this.$set(this.errors, field, value);
    },

    resetErrors() {
      this.errors = {};
    },

    setState(field, value) {
      this.$set(this.states, field, value);
    },

    resetStates() {
      this.states = {};
    },

    async ok(event) {
      event.preventDefault();

      if (this.item.name.trim() === '') {
        if (await confirmDialog('Введите название файлa!')) {
          return;
        }

        this.$refs.modal.hide();

        return;
      }

      this.loading = true;

      this.resetErrors();
      this.resetStates();

      const [err] = await this.$store.dispatch('media/library/updateFileOrDirectory', { id: this.id, name: `${this.item.name}.${this.item.ext}` });

      if (err && err.status === 422 && err?.data?.errors) {
        const errors = err?.data?.errors;

        for (const field in errors) {
          this.setError(field, errors[field].join(', '));
          this.setState(field, false);
        }
      }

      if (!err) {
        this.$emit('saved');
        this.$refs.modal.hide();
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal /deep/ .modal-title {
    max-width: 100%;
  }
</style>
