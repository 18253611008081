<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Зал:"
          :invalid-feedback="errors.hallId"
          :state="states.hallId"
        >
          <b-form-select
            size="sm"
            :value="item.hallId"
            :options="$array.convertToOptions(halls)"
            :state="states.hallId"
            :disabled="loading"
            @change="updateValue('hallId', $event)"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-2">
      <div class="align-self-end">Проектор</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Модель:"
          :invalid-feedback="errors.projectorTypeId"
          :state="states.projectorTypeId"
        >
          <dictionary-provider name="projectorTypes">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.projectorTypeId"
                :options="$array.convertToOptions(items)"
                :state="states.projectorTypeId"
                :disabled="loading"
                @change="updateValue('projectorTypeId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Серийный номер:"
          :invalid-feedback="errors.projectorSerial"
          :state="states.projectorSerial"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.projectorSerial"
            :state="states.projectorSerial"
            :disabled="loading"
            @change="updateValue('projectorSerial', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="IP адрес:"
          :invalid-feedback="errors.projectorAddress"
          :state="states.projectorAddress"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.projectorAddress"
            :state="states.projectorAddress"
            :disabled="loading"
            @change="updateValue('projectorAddress', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
      </b-col>
    </b-row>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-2">
      <div class="align-self-end">Медиасервер</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Модель:"
          :invalid-feedback="errors.mediaServerTypeId"
          :state="states.mediaServerTypeId"
        >
          <dictionary-provider name="mediaServerTypes">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.mediaServerTypeId"
                :options="$array.convertToOptions(items)"
                :state="states.mediaServerTypeId"
                :disabled="loading"
                @change="updateValue('mediaServerTypeId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Серийный номер:"
          :invalid-feedback="errors.mediaServerSerial"
          :state="states.mediaServerSerial"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.mediaServerSerial"
            :state="states.mediaServerSerial"
            :disabled="loading"
            @change="updateValue('mediaServerSerial', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="IP адрес управления:"
          :invalid-feedback="errors.mediaServerControlAddress"
          :state="states.mediaServerControlAddress"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.mediaServerControlAddress"
            :state="states.mediaServerControlAddress"
            :disabled="loading"
            @change="updateValue('mediaServerControlAddress', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="IP адрес контент-сети:"
          :invalid-feedback="errors.mediaServerContentAddress"
          :state="states.mediaServerContentAddress"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.mediaServerContentAddress"
            :state="states.mediaServerContentAddress"
            :disabled="loading"
            @change="updateValue('mediaServerContentAddress', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Серийный номер сертификата:"
          :invalid-feedback="errors.mediaServerCertificateSerial"
          :state="states.mediaServerCertificateSerial"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.mediaServerCertificateSerial"
            :state="states.mediaServerCertificateSerial"
            :disabled="loading"
            @change="updateValue('mediaServerCertificateSerial', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
      </b-col>
    </b-row>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-2">
      <div class="align-self-end">Звуковой процессор</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Модель:"
          :invalid-feedback="errors.soundProcessorTypeId"
          :state="states.soundProcessorTypeId"
        >
          <dictionary-provider name="soundProcessorTypes">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.soundProcessorTypeId"
                :options="$array.convertToOptions(items)"
                :state="states.soundProcessorTypeId"
                :disabled="loading"
                @change="updateValue('soundProcessorTypeId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="IP адрес:"
          :invalid-feedback="errors.soundProcessorAddress"
          :state="states.soundProcessorAddress"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.soundProcessorAddress"
            :state="states.soundProcessorAddress"
            :disabled="loading"
            @change="updateValue('soundProcessorAddress', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-2">
      <div class="align-self-end">Контроллер</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Тип контроллера:"
          :invalid-feedback="errors.relayBoardTypeId"
          :state="states.relayBoardTypeId"
        >
          <dictionary-provider name="relayBoardTypes">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.relayBoardTypeId"
                :options="$array.convertToOptions(items)"
                :state="states.relayBoardTypeId"
                :disabled="loading"
                @change="updateValue('relayBoardTypeId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="IP адрес:"
          :invalid-feedback="errors.relayBoardAddress"
          :state="states.relayBoardAddress"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.relayBoardAddress"
            :state="states.relayBoardAddress"
            :disabled="loading"
            @change="updateValue('relayBoardAddress', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    DictionaryProvider,
  },
  props: {
    cinemaId: {
      default: null,
    },
    halls: {
      default: () => [],
    },
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
