<template>
  <div class="h-100 d-flex flex-column">
    <div class="d-flex border-bottom shadow-sm p-2">
      <index-tabs
        :adv-promotion-type-id="advPromotionTypeId"
        @select="setAdvPromotionTypeId($event)"
      />
    </div>
    <scroll>
      <adv-promotions
        v-if="!advPromotionTypeId"
        ref="collection"
      />
      <adv-promotion-positions
        v-if="advPromotionTypeId"
        ref="collection"
      />
    </scroll>
  </div>
</template>

<script>
import AdvPromotionPositions from './AdvPromotionPositions.vue';
import AdvPromotions from './AdvPromotions.vue';
import IndexTabs from './IndexTabs.vue';

export default {
  components: {
    AdvPromotionPositions,
    AdvPromotions,
    IndexTabs,
  },
  data() {
    return {
      advPromotionTypeId: 0,
    };
  },
  methods: {
    setAdvPromotionTypeId(value) {
      this.advPromotionTypeId = value;
      this.$refs.collection.$children[0].reset();
    },
  },
};
</script>
