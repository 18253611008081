export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditMailAccountForm',
        permission: 'mail_accounts.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeMailAccount',
        permission: 'mail_accounts.edit',
      },
    ],
  },
];
