export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'date',
    type: 'date',
    label: 'Период действия',
    filterable: true,
    sortable: true,
  },
  {
    key: 'ticketsPricePercent',
    type: 'text',
    label: 'Доля рынка по валу кино',
    filterable: false,
    sortable: false,
  },
  {
    key: 'ticketsCountPercent',
    type: 'text',
    label: 'Доля рынка по зрителям',
    filterable: false,
    sortable: false,
  },
];
