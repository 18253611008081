/* eslint-disable no-return-await */
/* eslint-disable no-restricted-syntax */
import humps from 'humps';
import http from './http';
import { API_VERSION } from '../config';
import { convertError } from './utilities';

export async function exportSelection(id) {
  try {
    const response = await http.post(`/${API_VERSION}/selections/${id}/export`, {}, {
      responseType: 'blob',
    });

    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}

export async function getSelectionSize({ include, exclude }) {
  try {
    const response = await http.post(`/${API_VERSION}/selections/size`, {
      include: humps.decamelizeKeys(include),
      exclude: humps.decamelizeKeys(exclude),
    });

    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}
