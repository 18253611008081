<template>
  <div class="pushkins-card-events">
    <b-table
      class="datatable"
      empty-text="Нет файлов"
      :fields="columns"
      :items="pushkinsCardEvents"
      :striped="true"
      :hover="true"
      :no-local-sorting="false"
      :show-empty="true"
    >
      <template #cell(cinemaId)="data">
        {{ getCinemaName(data.item.cinemaId) }}
      </template>

      <template #cell(periodStart)="data">
        {{ $datetime.formatDateTime(data.item.periodStart) }}
      </template>

      <template #cell(periodEnd)="data">
        {{ $datetime.formatDateTime(data.item.periodEnd) }}
      </template>

      <template #cell(accepted)="data">
        {{ data.item.accepted ? 'Да' : 'Нет' }}
      </template>
    </b-table>
  </div>
</template>

<script>

export default {
  props: {
    pushkinsCardEvents: {
      default: () => [],
    },
    cinemas: {
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          key: 'cinemaId',
          label: 'Кинотеатр',
        },
        {
          key: 'name',
          label: 'Название',
        },
        {
          key: 'eventId',
          label: 'ID события',
        },
        {
          key: 'periodStart',
          label: 'Начало события',
        },
        {
          key: 'periodEnd',
          label: 'Конец события',
        },
        {
          key: 'accepted',
          label: 'Утверждено',
        },
      ],
    };
  },
  methods: {
    getCinemaName(cinemaId) {
      return this.cinemas.find((cinema) => cinema.id === cinemaId)?.name || '';
    },
  },
};
</script>

<style scoped>
</style>
