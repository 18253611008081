var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container users"},[_c('collection-provider',{ref:"collection",attrs:{"collection":"users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"panel-actions":_vm.datatable.panelActions,"item-actions":_vm.datatable.itemActions,"checked":_vm.datatable.checked},on:{"change-checked":function($event){_vm.datatable.checked = $event},"panel-action":function($event){return _vm.performPanelAction($event)},"item-action":function($event){return _vm.performItemAction($event)}},scopedSlots:_vm._u([{key:"filter(roleId)",fn:function(){return [_c('dictionary-provider',{attrs:{"name":"roles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":data.filter.roleId,"options":_vm.$array.convertToOptions(items),"disabled":data.loading},on:{"change":function($event){return actions.changeFilter('roleId', $event)}}})]}}],null,true)})]},proxy:true},{key:"filter(cinemaId)",fn:function(){return [_c('dictionary-provider',{attrs:{"name":"cinemas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":data.filter.cinemaId,"options":_vm.convertCinemas(items),"disabled":data.loading},on:{"change":function($event){return actions.changeFilter('cinemaId', $event)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}})]},proxy:true}],null,true)})]}}],null,true)})]},proxy:true},{key:"cell(name)",fn:function(data){return [_c('div',[_vm._v(_vm._s(data.item.name))]),(data.item.note)?_c('div',{staticClass:"text-muted mt-2"},[_vm._v(_vm._s(data.item.note))]):_vm._e()]}},{key:"cell(roleId)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$array.convertToText(data.item.roles))+" ")]}},{key:"cell(cinemaId)",fn:function(data){return [_c('user-cinemas',{attrs:{"item":data.item}})]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}])}),(_vm.modals.user.show)?_c('modal-model-form',{attrs:{"create-title":"Создание пользователя","edit-title":"Редактирование пользователя","collection":"users","id":_vm.modals.user.id},on:{"close":function($event){_vm.modals.user.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('user-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,false,1377286316)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }