<template>
  <div>
    <div
      :id="`day_${year}_${month}_${date}`"
      :class="['day-container', isRegional ? 'regional' : '', 'text-center']"
    >
      <div v-if="holiday" class="note">{{getDayName(holiday.asDay)}}</div>
      <div :class="classes">{{date}}</div>
    </div>
    <b-tooltip
      v-if="holiday"
      :target="`day_${year}_${month}_${date}`"
      placement="bottom"
    >
      <div v-if="holiday.note">{{holiday.note}}</div>
      <div v-if="holiday.note && isRegional">-----</div>
      <div v-for="(region, index) in regions" :key="index">{{region}}</div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    year: {
      default: null,
    },
    month: {
      default: null,
    },
    date: {
      default: null,
    },
    holiday: {
      default: null,
    },
  },
  data() {
    return {
      days: [
        { id: 1, name: 'ПН' },
        { id: 2, name: 'ВТ' },
        { id: 3, name: 'СР' },
        { id: 4, name: 'ЧТ' },
        { id: 5, name: 'ПТ' },
        { id: 6, name: 'СБ' },
        { id: 7, name: 'ВС' },
      ],
    };
  },
  computed: {
    jDate() {
      return new Date(this.year, this.month - 1, this.date);
    },

    isHoliday() {
      if (this.holiday) {
        if (this.holiday.asDay === 6 || this.holiday.asDay === 7) {
          return true;
        }
        return false;
      }
      if (this.jDate.getDay() === 6 || this.jDate.getDay() === 0) {
        return true;
      }
      return false;
    },

    isRegional() {
      return !!(this.holiday && this.holiday.regions && this.holiday.regions.length);
    },

    regions() {
      if (this.isRegional) {
        return this.holiday.regions.map((r) => r.name);
      }

      return [];
    },

    classes() {
      return [
        'day',
        this.isHoliday ? 'holiday' : '',
        this.isRegional ? 'regional' : '',
        'm-auto',
      ];
    },
  },

  methods: {
    getDayName(day) {
      return this.days.find((d) => d.id === day)?.name;
    },
  },
};
</script>

<style scoped>
.day-container {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.day {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding-top: 5px;
  border: 1px solid #e5e5e5;
  color: #337ab7;
  text-decoration: none;
}
.holiday {
  color: #fff;
  border: 1px solid #2cabe3;
  background: #2cabe3;
}
.regional .holiday {
  opacity: 0.5;
}
.regional .note {
  opacity: 0.5;
  z-index: 1;
}
.popover-form {
  padding: 15px 10px;
}
.note {
  position: absolute;
  display:  block;
  font-size: 10px;
  font-weight: 400;
  color: #fff;
  left: 21px;
  top: -8px;
  padding: 2px;
  background: #f39814;
  border-radius: 50%;
  padding: 4px;
}
</style>
