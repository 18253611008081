<template>
  <div class="scheme" @mousemove="toggleMode($event)">
    <div class="screen text-center mb-3">Экран</div>

    <div v-if="!localScheme.length" class="text-center">
      Нет данных
    </div>

    <draggable
      class="draggable-area"
      :sort="true"
      :forceFallback="true"
      :disabled="!sorting"
      :list="localScheme"
      @start="startDrag()"
      @end="endDrag()"
    >
      <transition-group name="scheme-show">
        <div v-for="(row, i) in localScheme" :key="`local_scheme_${i}`">
          <div class="d-flex scheme-row justify-content-between">
              <template v-if="row.length">
                <div class="scheme-row__name align-self-center">Ряд {{getRowNumber(row)}}</div>
                <div class="scheme-row__seats d-flex justify-content-center pl-4 pr-4">
                  <template v-for="(cell, j) in row">
                    <div
                      v-if="cell.length"
                      class="d-flex m-1"
                      :class="[cellClassName]"
                      :key="`cell_${i}_${j}`"
                      :i="i"
                      :j="j"
                    >
                      <template v-for="(seat, k) in cell">
                        <wheelchair
                          v-if="seat.type === 'wheelchair'"
                          :key="`seat_${i}_${j}_${k}_wheelchair`"
                          :class="[seatClassName]"
                          :color="getSeatColor(i, j, k)"
                        ></wheelchair>
                        <yamaguchi
                          v-else-if="seat.type === 'yamaguchi'"
                          :key="`seat_${i}_${j}_${k}_yamaguchi`"
                          :class="[seatClassName]"
                          :color="getSeatColor(i, j, k)"
                        ></yamaguchi>
                        <seat
                          v-else
                          class="align-self-center"
                          :class="[seatClassName]"
                          :info="getSeatInfo(seat.type)"
                          :background-color="getSeatColor(i, j, k)"
                          :font-color="getInvertedColor(getSeatColor(i, j, k))"
                          :data-type="seat.type"
                          :places="getSeatPlaces(i, j, k)"
                          :key="`seat_${i}_${j}_${k}`"
                        >
                        </seat>

                      </template>
                    </div>
                    <gap
                      v-else
                      class="align-self-center m-1"
                      :class="[gapClassName]"
                      :selected="isSelected(i, j)"
                      :i="i"
                      :j="j"
                      :key="`gap_${i}_${j}`"
                    ></gap>
                  </template>

                </div>
                <div class="d-flex align-self-center mr-2">
                  <div :title="'Добавить место'" class="add mr-1" @click="$emit('add-seat', i)">
                    <icon icon="fa-plus"></icon>
                  </div>
                  <div :title="'Удалить ряд'" class="trash" @click="$emit('remove-row', i)">
                    <icon icon="fa-times"></icon>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="scheme-row__name align-self-center"></div>
                <div class="road d-flex flex-grow-1 justify-content-center ml-5 mr-5"></div>
                <div class="trash align-self-center mr-2" @click="$emit('remove-road', i)">
                  <icon icon="fa-times"></icon>
                </div>
              </template>
          </div>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import Selection from '@simonwep/selection-js';

import { invertColor } from '../../utils/color';

import Seat from './Seat.vue';
import Gap from './Gap.vue';
import Wheelchair from './Wheelchair.vue';
import Yamaguchi from './Yamaguchi.vue';

import { PLACE_ID_BASE } from '../../constants';

export default {
  components: {
    Draggable,
    Seat,
    Gap,
    Wheelchair,
    Yamaguchi,
  },
  props: {
    seatTypes: {
      default: () => ([]),
    },
    colorSchemes: {
      default: () => ([]),
    },
    scheme: {
      default: () => ([]),
    },
    selected: {
      default: () => ([]),
    },
  },
  data() {
    return {
      selection: null,
      sorting: false,
      localSelected: [],
      localScheme: [],
      selectedSeatColor: 'yellow',
      defaultSeatColor: '#eee',
      seatClassName: 'seat',
      gapClassName: 'gap',
      cellClassName: 'scheme-cell',
      selectionClassName: 'selection',
      selectionBoundaryClassName: 'scheme',
      selectableClassName: 'scheme-row__seats',
    };
  },
  computed: {
    invertedColors() {
      return this.colorSchemes.map((cs) => ({
        color: cs.color,
        invertedColor: invertColor(cs.color),
      }));
    },
  },
  watch: {
    selected(value) {
      this.localSelected = [...value];
    },

    scheme(value) {
      this.localScheme = [...value];
    },
  },
  created() {
    this.localScheme = [...this.scheme];
    this.localSelected = [...this.selected];
  },
  mounted() {
    this.initSelection();
  },
  methods: {
    initSelection() {
      this.selection = new Selection({
        selectables: [this.cellClassName, this.gapClassName].map((n) => `.${n}`),
        class: this.selectionClassName,
        boundaries: [`.${this.selectionBoundaryClassName}`],
        singleClick: true,
      });

      this.selection.on('start', this.onSelectionStart);
      this.selection.on('move', this.onSelectionMove);
      this.selection.on('stop', this.onSelectionStop);

      this.selection.disable();
    },

    emitSelected() {
      this.$emit('change-selected', this.localSelected);
    },

    clearSelected() {
      this.localSelected = [];
    },

    select(i, j) {
      if (!this.isSelected(i, j)) {
        this.localSelected.push({ i, j });
      }
    },

    unselect(i, j) {
      const index = this.localSelected.findIndex((item) => item.i === i && item.j === j);

      if (index > -1) {
        this.localSelected.splice(index, 1);
      }
    },

    isSelected(i, j) {
      return this.localSelected.findIndex((item) => item.i === i && item.j === j) > -1;
    },

    toggleMode(evt) {
      if (evt.buttons === 0) {
        if (this.isSelectableArea(evt.target)) {
          this.sorting = false;
          this.selection.enable();
        } else {
          this.selection.disable();
          this.sorting = true;
        }
      }
    },

    isSelectableArea(el) {
      for (let parent = el && el.parentElement; parent; parent = parent.parentElement) {
        if (
          el.classList.contains(this.selectableClassName)
          || parent.classList.contains(this.selectableClassName)
        ) {
          return true;
        }
      }

      return false;
    },

    getRowNumber(row) {
      const cell = row.find((item) => item.length);

      if (!cell) {
        return 0;
      }

      const seat = cell[0];
      const place = seat.places ? seat.places[0] : 0;

      return Math.floor(place / PLACE_ID_BASE);
    },

    getSeatPlaces(i, j, k) {
      const places = this.localScheme[i][j][k]?.places || [];

      return places.map((placeId) => placeId % PLACE_ID_BASE);
    },

    getSeatInfo(type) {
      return this.seatTypes.find((seatType) => seatType.code === type);
    },

    getSeatColor(i, j, k) {
      let color = this.defaultSeatColor;

      if (this.localScheme[i][j][k].colorSchemeId) {
        const colorScheme = this.colorSchemes.find((cs) => cs.id === this.localScheme[i][j][k].colorSchemeId);

        if (colorScheme) {
          color = colorScheme.color;
        }
      }

      if (this.isSelected(i, j)) {
        color = this.selectedSeatColor;
      }

      return color;
    },

    getInvertedColor(color) {
      const invertedColor = this.invertedColors.find((ic) => ic.color === color);

      return invertedColor ? invertedColor.invertedColor : '#000';
    },

    getSelected(el) {
      const i = +el.getAttribute('i');
      const j = +el.getAttribute('j');

      if (this.isSelected(i, j)) {
        return [i, j];
      }

      return null;
    },

    onSelectionStart({ store, event }) {
      let prev = null;
      const { selected } = store;

      if (selected.length === 1) {
        prev = this.getSelected(selected[0]);
      }

      if (!event.ctrlKey && !event.metaKey) {
        this.clearSelected();
        this.selection.clearSelection();
      }

      this.$nextTick(() => {
        if (selected.length === 1) {
          const curr = this.getSelected(selected[0]);

          if (prev && curr && prev[0] === curr[0] && prev[1] === curr[1]) {
            this.unselect(curr[0], curr[1]);
            this.emitSelected();
          }
        }
      });
    },

    onSelectionMove({ store, event }) {
      store.changed.added.forEach((el) => this.select(+el.getAttribute('i'), +el.getAttribute('j')));
      store.changed.removed.forEach((el) => this.unselect(+el.getAttribute('i'), +el.getAttribute('j')));
    },

    onSelectionStop() {
      this.selection.keepSelection();
      this.emitSelected();
    },

    startDrag() {
      this.selection.disable();
      this.sorting = true;
    },

    endDrag() {
      this.clearSelected();
      this.$emit('change-scheme', this.localScheme);
    },
  },

};
</script>

<style scoped>
.screen {
  font-size: 22px;
  color: #2cabe3;
  text-transform: uppercase;
}
.scheme {
  user-select: none;
}
.scheme-row__seats {
  font-size: 20px;
}
.scheme-row:hover {
  background-color: #eaeef2;
}
.scheme-row__name, .seat, .gap {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.scheme-row__name {
  font-size: 1em;
  cursor: pointer;
  min-width: 75px;
}
.seat {
  font-size: 1em;
  width: 1em;
  height: 1em;
}
.gap {
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: 1em;
  opacity: 0.2;
}
.road {
  height: 1em;
}
.trash, .drag, .add {
  cursor: pointer;
  min-width: 10px;
  color: #ddd;
}

.scheme-show-enter-active, .scheme-show-leave-active {
  transition: all 1.7s;
}

.scheme-show-enter, .scheme-show-leave-to {
  opacity: 0;
  background-color: rgba(23, 162, 184, 1);
}
</style>
