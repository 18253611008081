<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Наименование:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        type="text"
        size="sm"
        autofocus
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>
    <b-form-group
        label="Группа выборок:"
        :invalid-feedback="errors.selectionGroupId"
        :state="states.selectionGroupId"
    >
      <dictionary-provider name="selectionGroups">
        <template #default="{ items, loading: dictionaryLoading }">
          <b-form-select
              size="sm"
              :value="item.selectionGroupId"
              :options="$array.convertToOptions(items, {
                value: null,
                text: '',
              })"
              :disabled="loading || dictionaryLoading"
              @change="updateValue('selectionGroupId', $event)"
          ></b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <!-- <b-form-group
      label="Период:"
      :invalid-feedback="errors.dateEnd || errors.dateStart"
      :state="states.dateEnd || states.dateStart"
    >
      <date-interval
        :value="{from: item.dateStart, to: item.dateEnd}"
        :disabled="loading"
        @selected="updateValue('dateStart', $event.from), updateValue('dateEnd', $event.to)">
      </date-interval>
    </b-form-group> -->

    <b-row align-v="center" class="mb-2">
      <b-col style="width: 220px; flex: none;"></b-col>
      <b-col></b-col>
      <b-col>Исключая</b-col>
    </b-row>

    <selection-form-dictionary
      name="Кинотеатры"
      dictionary="cinemas"
      :convert="convertCinemas"
      :disabled="loading"
      :include="getInclude('cinemaId', [])"
      :exclude="getExclude('cinemaId', [])"
      @include="updateInclude('cinemaId', $event)"
      @exclude="updateExclude('cinemaId', $event)"
    ></selection-form-dictionary>

    <selection-form-title>Фильм</selection-form-title>

    <selection-form-dictionary
      name="Прокатчик"
      dictionary="distributors2"
      :disabled="loading"
      :include="getInclude('distributorId', [])"
      :exclude="getExclude('distributorId', [])"
      @include="updateInclude('distributorId', $event)"
      @exclude="updateExclude('distributorId', $event)"
    ></selection-form-dictionary>

    <selection-form-dictionary
      name="Жанр"
      dictionary="genres2"
      :disabled="loading"
      :include="getInclude('genreId', [])"
      :exclude="getExclude('genreId', [])"
      @include="updateInclude('genreId', $event)"
      @exclude="updateExclude('genreId', $event)"
    ></selection-form-dictionary>

    <selection-form-dictionary
      name="Возрастное ограничение"
      dictionary="ageLimits"
      :disabled="loading"
      :include="getInclude('ageLimit', [])"
      :exclude="getExclude('ageLimit', [])"
      @include="updateInclude('ageLimit', $event)"
      @exclude="updateExclude('ageLimit', $event)"
    ></selection-form-dictionary>

    <selection-form-dictionary
      name="Формат"
      dictionary="formats2"
      :disabled="loading"
      :include="getInclude('formatId', [])"
      :exclude="getExclude('formatId', [])"
      @include="updateInclude('formatId', $event)"
      @exclude="updateExclude('formatId', $event)"
    ></selection-form-dictionary>

    <selection-form-dictionary
      name="Название"
      dictionary="showsReleases"
      :convert="convertReleases"
      :disabled="loading"
      :include="getInclude('releaseId', [])"
      :exclude="getExclude('releaseId', [])"
      @include="updateInclude('releaseId', $event)"
      @exclude="updateExclude('releaseId', $event)"
    ></selection-form-dictionary>

    <selection-form-range
      name="Неделя проката"
      :disabled="loading"
      :include="getInclude('week')"
      :exclude="getExclude('week')"
      @include="updateInclude('week', $event)"
      @exclude="updateExclude('week', $event)"
    ></selection-form-range>

    <selection-form-title>Зал</selection-form-title>

    <selection-form-dictionary
      name="Категория зала"
      dictionary="hallCategories"
      :disabled="loading"
      :include="getInclude('hallCategoryId', [])"
      :exclude="getExclude('hallCategoryId', [])"
      @include="updateInclude('hallCategoryId', $event)"
      @exclude="updateExclude('hallCategoryId', $event)"
    ></selection-form-dictionary>

    <selection-form-dictionary
      name="Тип кресла"
      dictionary="seatTypes"
      :convert="convertSeatTypes"
      :disabled="loading"
      :include="getInclude('seatType', [])"
      :exclude="getExclude('seatType', [])"
      @include="updateInclude('seatType', $event)"
      @exclude="updateExclude('seatType', $event)"
    ></selection-form-dictionary>

    <selection-form-title>Цена</selection-form-title>

    <selection-form-dictionary
      name="Скидка"
      dictionary="discounts"
      :disabled="loading"
      :include="getInclude('discountId', [])"
      :exclude="getExclude('discountId', [])"
      @include="updateInclude('discountId', $event)"
      @exclude="updateExclude('discountId', $event)"
    ></selection-form-dictionary>

    <selection-form-dictionary
      name="Акция"
      dictionary="promotions"
      :disabled="loading"
      :include="getInclude('promotionId', [])"
      :exclude="getExclude('promotionId', [])"
      @include="updateInclude('promotionId', $event)"
      @exclude="updateExclude('promotionId', $event)"
    ></selection-form-dictionary>

    <selection-form-dictionary
      name="Ценовая схема"
      dictionary="colorSchemes"
      :disabled="loading"
      :include="getInclude('colorSchemeId', [])"
      :exclude="getExclude('colorSchemeId', [])"
      @include="updateInclude('colorSchemeId', $event)"
      @exclude="updateExclude('colorSchemeId', $event)"
    ></selection-form-dictionary>

    <selection-form-range
      name="Цена"
      :disabled="loading"
      :include="getInclude('price')"
      :exclude="getExclude('price')"
      @include="updateInclude('price', $event)"
      @exclude="updateExclude('price', $event)"
    ></selection-form-range>

    <selection-form-title>Активность</selection-form-title>

    <selection-form-range
      name="Количество покупок"
      :disabled="loading"
      :include="getInclude('ticketsCount')"
      :exclude="getExclude('ticketsCount')"
      @include="updateInclude('ticketsCount', $event)"
      @exclude="updateExclude('ticketsCount', $event)"
    ></selection-form-range>

    <selection-form-range
      name="Сумма покупок"
      :disabled="loading"
      :include="getInclude('ticketsPrice')"
      :exclude="getExclude('ticketsPrice')"
      @include="updateInclude('ticketsPrice', $event)"
      @exclude="updateExclude('ticketsPrice', $event)"
    ></selection-form-range>

    <selection-form-range
      name="Средний чек"
      :disabled="loading"
      :include="getInclude('averagePrice')"
      :exclude="getExclude('averagePrice')"
      @include="updateInclude('averagePrice', $event)"
      @exclude="updateExclude('averagePrice', $event)"
    ></selection-form-range>

    <selection-form-range
      name="Последняя покупка"
      placeholder-from="от _ дней назад"
      placeholder-to="до _ дней назад"
      :disabled="loading"
      :include="getInclude('lastOrderPeriod')"
      :exclude="getExclude('lastOrderPeriod')"
      @include="updateInclude('lastOrderPeriod', $event)"
      @exclude="updateExclude('lastOrderPeriod', $event)"
    ></selection-form-range>

    <selection-form-range
      name="Последнее извещение"
      placeholder-from="от _ дней назад"
      placeholder-to="до _ дней назад"
      :disabled="loading"
      :include="getInclude('lastNotificationPeriod')"
      :exclude="getExclude('lastNotificationPeriod')"
      @include="updateInclude('lastNotificationPeriod', $event)"
      @exclude="updateExclude('lastNotificationPeriod', $event)"
    ></selection-form-range>

    <selection-form-dictionary
      name="Канал покупки"
      dictionary="appGroups"
      :disabled="loading"
      :include="getInclude('appGroupId', [])"
      :exclude="getExclude('appGroupId', [])"
      @include="updateInclude('appGroupId', $event)"
      @exclude="updateExclude('appGroupId', $event)"
    ></selection-form-dictionary>

    <selection-form-title>Информация о клиенте</selection-form-title>

    <!-- <selection-form-select
      name="Пол"
      class="text-danger"
      :disabled="loading"
      :options="genders"
      :include="getInclude('gender')"
      :exclude="getExclude('gender')"
      @include="updateInclude('gender', $event)"
      @exclude="updateExclude('gender', $event)"
    ></selection-form-select> -->

    <selection-form-range
      name="Возраст"
      :disabled="loading"
      :include="getInclude('age')"
      :exclude="getExclude('age')"
      @include="updateInclude('age', $event)"
      @exclude="updateExclude('age', $event)"
    ></selection-form-range>

    <selection-form-dates
      name="Дата регистрации"
      :disabled="loading"
      :include="getInclude('createdAt')"
      :exclude="getExclude('createdAt')"
      @include="updateInclude('createdAt', $event)"
      @exclude="updateExclude('createdAt', $event)"
    ></selection-form-dates>

    <selection-form-amount
      name="Посчитать количество"
      :include="item.include"
      :exclude="item.exclude"
      :disabled="loading"
    ></selection-form-amount>
  </b-container>
</template>

<script>
import { MaskHelper } from '../../utils/mask';

import DateInterval from '../../components/fields/DateInterval.vue';
import DictionaryProvider from '../../providers/DictionaryProvider';

import SelectionFormSelect from './SelectionFormSelect.vue';
import SelectionFormInput from './SelectionFormInput.vue';
import SelectionFormRange from './SelectionFormRange.vue';
import SelectionFormDictionary from './SelectionFormDictionary.vue';
import SelectionFormTitle from './SelectionFormTitle.vue';
import SelectionFormAmount from './SelectionFormAmount.vue';
import SelectionFormDates from './SelectionFormDates.vue';

export default {
  components: {
    DateInterval,
    DictionaryProvider,
    SelectionFormDictionary,
    SelectionFormTitle,
    SelectionFormRange,
    SelectionFormInput,
    SelectionFormSelect,
    SelectionFormAmount,
    SelectionFormDates,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      genders: [
        {
          value: null,
          text: '',
        },
        {
          value: 'male',
          text: 'Мужской',
        },
        {
          value: 'female',
          text: 'Женский',
        },
      ],

      mask: MaskHelper,
    };
  },
  methods: {
    getInclude(field, defaultValue = '') {
      return this.item?.include?.[field] || defaultValue;
    },

    getExclude(field, defaultValue = '') {
      return this.item?.exclude?.[field] || defaultValue;
    },

    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    updateInclude(field, value) {
      let include = {};

      if (this.item?.include && Object.keys(this.item.include).length) {
        include = JSON.parse(JSON.stringify(this.item.include));
      }

      if (Array.isArray(value) && !value.length) {
        delete include[field];
      } else if (typeof value === 'object' && !Object.keys(value).length) {
        delete include[field];
      } else {
        include[field] = value;
      }

      this.updateValue('include', include);
    },

    updateExclude(field, value) {
      let exclude = {};

      if (this.item?.exclude && Object.keys(this.item.exclude).length) {
        exclude = JSON.parse(JSON.stringify(this.item.exclude));
      }

      if (Array.isArray(value) && !value.length) {
        delete exclude[field];
      } else if (typeof value === 'object' && !Object.keys(value).length) {
        delete exclude[field];
      } else {
        exclude[field] = value;
      }

      this.updateValue('exclude', exclude);
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    convertReleases(releases) {
      if (!releases) {
        return [];
      }

      return releases.map((release) => ({
        id: release.id,
        name: release?.format?.name ? `${release?.movie?.name}, ${release?.format?.name}` : release?.movie?.name,
      }));
    },

    convertSeatTypes(seatTypes) {
      if (!seatTypes) {
        return [];
      }

      return seatTypes.map((seatType) => ({
        id: seatType.code,
        name: seatType.name,
      }));
    },
  },
};
</script>
