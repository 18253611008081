<template>
  <div class="orders p-2">
    <collection-provider
      collection="merchantPayments"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :show-top-toolbar="false"
        >
          <template #cell(status)="data">
            {{ formatStatus(data.item.status) }}
          </template>

          <template #cell(cinema)="data">
            {{ data.item.cinema.name }}, {{ data.item.cinema.city.name }}
          </template>

          <template #cell(show)="data">
            <div
              :id="`order_show__${data.item.id}`"
              class="show_column"
              v-if="hasTickets(data.item)"
            >
              <span class="show_datetime text-primary">
                {{ data.item.content.tickets[0].show.datetime }}
              </span>
              <span class="show_movie">
                {{ data.item.content.tickets[0].show.movie }}
              </span>
            </div>
            <b-tooltip
              :target="`order_show__${data.item.id}`"
              v-if="hasTickets(data.item) && data.item.content.tickets[0].show.movie.length > 36"
            >
              <div class="mb-1">
                {{ data.item.content.tickets[0].show.movie }}
              </div>
            </b-tooltip>
          </template>

          <template #cell(amount)="data">
            <span
              :id="`order_content__${data.item.id}`"
              class="order_amount text-primary"
            >
              {{ formatPrice(data.item.amount) }}
            </span>
            <span class="order_contents">
              ({{ formatOrderContents(data.item.content) }})
            </span>
            <b-tooltip
              :target="`order_content__${data.item.id}`"
              v-if="data.item.content.tickets.length"
            >
              <div class="mb-1">
                <div
                  v-for="ticket in data.item.content.tickets"
                >
                  {{ Math.floor(ticket.placeId / 1000) }} ряд {{ ticket.placeId % 1000 }} место
                </div>
              </div>
            </b-tooltip>
          </template>

          <template #cell(paymentTypeId)="data">
            {{ data.item.paymentType ? data.item.paymentType.name : '' }}
          </template>

          <template #cell(appGroupId)="data">
            {{ data.item.appGroup.name }}
          </template>

          <template #cell(customer)="data">
            <div
              :id="`order_customer__${data.item.id}`"
              v-if="data.item.customer"
            >
              {{ formatter.phoneFormat(data.item.customer.phone) }}
            </div>
            <b-tooltip
              :target="`order_customer__${data.item.id}`"
              v-if="data.item.customer"
            >
              <div class="mb-1">
                {{ formatCustomerData(data.item.customer) }}
              </div>
            </b-tooltip>
          </template>

          <template #cell(createdAt)="data">
            {{ timeHelper.format(data.item.createdAt, timeHelper.datetimeFormat) }}
          </template>

        </datatable>
      </template>
    </collection-provider>
  </div>
</template>

<script>
import {
  PAYMENT_STATUS_CREATED,
  PAYMENT_STATUS_APPROVED,
  PAYMENT_STATUS_REVERSED,
  PAYMENT_STATUS_REFUNDED,
  PAYMENT_STATUS_DECLINED,
  PAYMENT_STATUS_EXPIRED,
  PAYMENT_STATUS_REFUND_ACTIVATED,
} from '../../constants';

import { Formatter } from '../../utils/formatter';
import { confirmDialog } from '../../utils/dialogs';
import TimeHelper from '../../utils/datetime';

import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';

import fields from './_index.fields';

export default {
  components: {
    CollectionProvider,
    Datatable,
  },
  props: {
  },
  data() {
    return {
      formatter: Formatter,
      timeHelper: TimeHelper,
      datatable: {
        fields,
      },
      modals: {
      },
    };
  },
  computed: {
  },
  methods: {
    hasTickets(item) {
      return item.content.tickets?.length;
    },

    formatStatus(status) {
      const labels = [
        'создан',                   // PAYMENT_STATUS_CREATED
        'оплачен',                  // PAYMENT_STATUS_APPROVED
        'авторизация отменена',     // PAYMENT_STATUS_REVERSED
        'возвращен',                // PAYMENT_STATUS_REFUNDED
        'заказ отменен',            // PAYMENT_STATUS_DECLINED
        'истекло время ожидания',   // PAYMENT_STATUS_EXPIRED
        'активирован возврат',      // PAYMENT_STATUS_REFUND_ACTIVATED
      ];

      return labels[status];
    },

    formatCustomerData(customer) {
      let elements = [
        customer.name || customer.surname ? customer.name + ' ' + customer.surname : null,
        customer.email,
      ];

      return elements.filter((item) => item).join(', ');
    },

    formatPrice(price) {
      return new Intl.NumberFormat("ru-RU").format(price);
    },

    formatOrderContents(order) {
      let elements = [];

      if (order.tickets?.length > 0) {
        elements.push(order.tickets.length + ' ' + this.getNoun(order.tickets.length, 'билет', 'билета', 'билетов'));
      }

      if (order.products?.length > 0) {
        elements.push(order.products.length + ' ' + this.getNoun(order.products.length, 'товар', 'товара', 'товаров'));
      }

      if (order.certificates?.length > 0) {
        elements.push(order.certificates.length + ' ' + this.getNoun(order.certificates.length, 'сертификат', 'сертификата', 'сертификатов'));
      }

      return elements.join(', ');
    },

    getNoun(number, one, two, five) {
      let n = number % 100;

      if (n >= 5 && n <= 20) {
        return five;
      }

      n = number % 10;

      if (n == 1) {
        return one;
      }

      if (n >= 2 && n <= 4) {
        return two;
      }

      return five;
    },
  },
};
</script>

<style scoped>
.orders >>> .column-id {
  min-width: 100px;
  text-align: left;
}
.show_datetime {
  display: inline-block;
  min-width: 130px;
  white-space: nowrap;
  vertical-align: bottom;
}
.show_movie {
  display: inline-block;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: bottom;
}
.order_amount {
  display: inline-block;
  min-width: 50px;
  white-space: nowrap;
}
</style>
