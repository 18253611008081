import humps from 'humps';
import { convertError, createQuery } from './utilities';

import { API_VERSION } from '../config';
import http from './http';

export async function downloadFileBlob(collection, { filter, sort } = {}) {
  const query = createQuery({
    filter: humps.decamelizeKeys(filter),
    sort: {
      by: sort && sort.by ? humps.decamelize(sort.by) : '',
      desc: sort ? sort.desc : false,
    },
  });

  try {
    const { data, headers } = await http.get(`/${API_VERSION}/${humps.decamelize(collection)}?${query}`, {
      responseType: 'blob',
    });

    return {
      blob: data,
      type: headers['content-type'],
    };
  } catch (error) {
    return convertError(error);
  }
}

export async function registerFile() {
  try {
    const response = await http.get(`/${API_VERSION}/upload/init`);
    return [null, response.data.id];
  } catch (error) {
    return convertError(error);
  }
}

export async function uploadFile(id, formData, processCallback) {
  try {
    const response = await http.post(`/${API_VERSION}/upload/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (e) => {
        processCallback(Math.round((e.loaded * 100) / e.total));
      },
    });

    return [null, response];
  } catch (error) {
    return convertError(error);
  }
}

export async function getFilepath(id) {
  try {
    const response = await http.get(`/${API_VERSION}/upload/${id}`);
    console.log(response);
    // return [null, response.data];
  } catch (error) {
    return convertError(error);
  }
}

export async function downloadFile(id) {
  try {
    const response = await http.get(`/${API_VERSION}/upload/${id}?download`);
    console.log(response);
    // return [null, response.data];
  } catch (error) {
    return convertError(error);
  }
}

export async function uploadFile2(formData, processCallback) {
  try {
    const response = await http.post(`/${API_VERSION}/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (e) => {
        processCallback(Math.round((e.loaded * 100) / e.total));
      },
    });

    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}
