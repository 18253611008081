<template>
  <div class="d-flex flex-grow-1">
    <b-button
      size="sm"
      variant="outline-info"
      :disabled="disabled"
      @click="$emit('prev-day')"
    >
      <icon icon="fa-chevron-left"></icon>
    </b-button>

    <date-button
      class="mx-1"
      :date="date"
      @select="$emit('select-date', $event)"
    ></date-button>

    <b-button
      size="sm"
      variant="outline-info"
      class="mr-3"
      :disabled="disabled"
      @click="$emit('next-day')"
    >
      <icon icon="fa-chevron-right"></icon>
    </b-button>

    <b-button
      v-if="$user.can('schedule.edit')"
      size="sm"
      variant="outline-info"
      class="mr-1"
      :disabled="disabled"
      @click="$emit('open-sales')"
    >
      Открыть продажи на день
    </b-button>

    <b-button
      v-if="$user.can('schedule.edit')"
      size="sm"
      variant="outline-info"
      class="mr-1"
      :disabled="disabled"
      @click="$emit('close-sales')"
    >
      Закрыть продажи на день
    </b-button>

    <b-button
      v-if="$user.can('schedule.edit')"
      size="sm"
      variant="outline-info"
      class="mr-3"
      :disabled="disabled"
      @click="$emit('open-sales-period-form')"
    >
      Продажи на период
    </b-button>

    <b-button
      v-if="$user.can('schedule.edit')"
      size="sm"
      variant="outline-info"
      class="mr-1"
      :disabled="disabled"
      @click="$emit('set-min-price')"
    >
      <icon icon="fa-dollar"></icon>min
    </b-button>

    <b-dropdown
      v-if="intersections.length"
      class="mr-1 intersections"
      variant="outline-danger"
      size="sm"
      boundary="viewport"
    >
      <template v-slot:button-content>
        <icon icon="fa-exclamation-triangle"></icon> Пересечения сеансов!
      </template>
      <template v-for="(shows, date) in intersectionsAsText">
        <b-dropdown-header :key="date">{{ date }}</b-dropdown-header>
        <b-dropdown-item
          v-for="(show, index) in shows"
          :key="`${date}:${index}`"
        >{{ show }}</b-dropdown-item>
      </template>
    </b-dropdown>

    <b-button
      v-if="$user.can('schedule.view')"
      size="sm"
      variant="outline-info"
      class="ml-auto mr-1"
      :disabled="disabled"
      @click="$emit('open-schedule')"
    >
      <icon icon="fa-clock-o"></icon> Расписание
    </b-button>

    <b-button-group class="mr-1">
      <b-button
        size="sm"
        variant="outline-info"
        @click="$emit('decrease-height')"
      >↑</b-button>
      <b-button
        size="sm"
        variant="outline-info"
        @click="$emit('increase-height')"
      >↓</b-button>
    </b-button-group>

    <b-button-group class="">
      <b-button
        size="sm"
        variant="outline-info"
        @click="$emit('decrease-width')"
      >-</b-button>
      <b-button
        size="sm"
        variant="outline-info"
        @click="$emit('increase-width')"
      >+</b-button>
    </b-button-group>
  </div>
</template>

<script>
import DateButton from '../../components/fields/DateButton.vue';

export default {
  components: {
    DateButton,
  },
  props: {
    disabled: {
      default: false,
    },
    date: {
      default: null,
    },
    intersections: {
      default: () => [],
    },
    halls: {
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    formattedDate() {
      return this.$datetime.formatDate(this.date);
    },

    intersectionsAsText() {
      return this.intersections.reduce((dates, show) => {
        const { time, date, hallId } = show;

        const formattedDate = this.$datetime.formatDate(date);

        if (!dates[formattedDate]) {
          // eslint-disable-next-line no-param-reassign
          dates[formattedDate] = [];
        }

        const hallName = this.halls.find((_hall) => _hall.id === hallId)?.name;

        dates[formattedDate].push(`${hallName} ${time}`);

        return dates;
      }, {});
    },
  },
};
</script>

<style scoped>
.intersections >>> .dropdown-menu {
  max-height: 600px;
  overflow-y:auto;
}
</style>
