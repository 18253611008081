import humps from 'humps';

import { convertBlobToString } from '../utils/file';

import {
  fetchShows,
  getShow,
  createShows,
  updateShows,
  deleteShows,
  clearShows,
  copyShows,
  toggleLocked,
  exportShows,
} from '../api/shows';

export default {
  async fetchShows({
    filter, params,
  }) {
    const [err, data] = await fetchShows({
      filter: humps.decamelizeKeys(filter),
      params: humps.decamelizeKeys(params),
    });

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async createShows(shows, dates) {
    const [err, data] = await createShows(humps.decamelizeKeys(shows), humps.decamelizeKeys(dates));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async updateShows(shows, dates) {
    const [err, data] = await updateShows(humps.decamelizeKeys(shows), humps.decamelizeKeys(dates));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async deleteShows(shows, dates) {
    const [err, data] = await deleteShows(humps.decamelizeKeys(shows), humps.decamelizeKeys(dates));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async getShow(id, params) {
    const [err, data] = await getShow(id, humps.decamelizeKeys(params));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async clearShows({
    cinemaId, hallId, date, dateStart, dateEnd,
  }) {
    const [err, data] = await clearShows({
      cinemaId, hallId, date, dateStart, dateEnd,
    });

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async copyShows({
    hallIdFrom, hallIdTo, dateFrom, dateTo, hallIdsFrom, cinemaIdsTo,
  }) {
    const [err, data] = await copyShows({
      hallIdFrom, hallIdTo, dateFrom, dateTo, hallIdsFrom, cinemaIdsTo,
    });

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async toggleLocked(showId, locked) {
    const [err, data] = await toggleLocked(showId, locked);

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async exportShows(params) {
    let [err, data] = await exportShows(humps.decamelizeKeys(params));

    if (err && err.data && err.data instanceof Blob) {
      const text = await convertBlobToString(err.data);
      err.data = JSON.parse(text);
    }

    if (params.format === 'html' && data instanceof Blob) {
      data = await convertBlobToString(data);
    }

    if (params.format === 'json' && data instanceof Blob) {
      data = await convertBlobToString(data);
      data = JSON.parse(data);
      data = humps.camelizeKeys(data);
    }

    return [humps.camelizeKeys(err), data];
  },
};
