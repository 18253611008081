<template>
  <div class="p-2">
    <b-form-group
      label="Период:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.dateStart']} ${errors['filter.dateEnd']}`"
      :state="states['filter.dateStart'] && states['filter.dateEnd']"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="filter.dateStart"
        :state="states['filter.dateStart']"
        :disabled="loading"
        @change="setFilter({ field: 'dateStart', value: $event })"
      ></date-popover>
      <date-popover
        size="sm"
        :value="filter.dateEnd"
        :state="states['filter.dateEnd']"
        :disabled="loading"
        @change="setFilter({ field: 'dateEnd', value: $event })"
      ></date-popover>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Учет типа суток:"
      :disabled="loading"
      :invalid-feedback="errors['params.dayType']"
    >
      <b-form-radio-group
        name="day-type"
        :state="states['filter.dayType']"
        :checked="filter.dayType"
        :disabled="loading"
        @change="setFilter({ field: 'dayType', value: $event })"
      >
        <b-form-radio value="business">Бизнес-сутки</b-form-radio>
        <b-form-radio value="calendar">Календарные</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-form-group
      label="Статусы:"
      :invalid-feedback="errors['filter.statusIds']"
      :state="states['filter.statusIds']"
    >
      <toggle-list
        :options="convertOptions(statuses)"
        :value="convertToDictionaryFromIds(filter.statusIds, statuses)"
        :searchable="false"
        :disabled="loading"
        @change="setFilter({ field: 'statusIds', value: convertFromDictionaryToIds($event) })"
      ></toggle-list>
    </b-form-group>

    <b-form-group
      label="Группы сертификатов:"
      :invalid-feedback="errors['filter.certificateGroupIds']"
      :state="states['filter.certificateGroupIds']"
    >
      <dictionary-provider
        name="certificateGroups"
        :disable-fetching="loading"
        :filter="{ cinemaId: this.cinemaId }"
      >
        <template #default="{ items, loading: dictionaryLoading }">
          <toggle-list
            :options="convertOptions(items)"
            :value="convertToDictionaryFromIds(filter.certificateGroupIds, items)"
            :searchable="false"
            :disabled="loading || dictionaryLoading"
            @change="setFilter({ field: 'certificateGroupIds', value: convertFromDictionaryToIds($event) })"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="mt-4">
      <b-button
        variant="info"
        size="sm"
        :disabled="loading"
        @click="fetchReport({ format: 'html' })"
      >Просмотр</b-button>
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
        @click="printReport()"
      ><icon icon="fa-print"></icon> Печать</b-button>
      <b-dropdown
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
      >
        <template #button-content>
          <icon icon="fa-save"></icon>
        </template>
        <b-dropdown-item
          v-for="format in formats"
          :key="format.value"
          @click="fetchReport({ format: format.value })"
        >{{format.text}}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DatePopover from '../../../components/fields/DatePopover.vue';
import DictionaryProvider from '../../../providers/DictionaryProvider';
import ToggleList from '../../../components/fields/ToggleList.vue';

import datetime from '../../../utils/datetime';

export default {
  components: {
    DatePopover,
    DictionaryProvider,
    ToggleList,
  },
  data() {
    return {
      statuses: [
        { id: 1, name: 'Активирован' },
        { id: 2, name: 'Срок вышел' },
        { id: 3, name: 'Возвращен' },
        { id: 4, name: 'Погашен' },
      ],
      format: 'xlsx',
      formats: [
        { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'XLSX' },
      ],
    };
  },
  computed: {
    ...mapState(['cinemaId']),

    ...mapState('reports/certificates', {
      params: (state) => state.params,
      filter: (state) => state.filter,
      loading: (state) => state.loading,
      errors: (state) => state.errors,
      states: (state) => state.states,
    }),
  },
  created() {
    const date = datetime.convertDateToDbFormat(new Date());

    this.setParam({ field: 'cinemaId', value: this.cinemaId });
    this.setFilter({ field: 'dateStart', value: date });
    this.setFilter({ field: 'dateEnd', value: date });
    this.setFilter({ field: 'dayType', value: 'business' });
    this.setFilter({ field: 'statusIds', value: null });
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      setParam: 'reports/certificates/setParam',
      setFilter: 'reports/certificates/setFilter',
      fetchReport: 'reports/certificates/fetchReport',
      reset: 'reports/certificates/reset',
    }),

    convertOptions(options) {
      if (!options) {
        return [];
      }

      return options.map((option) => ({
        id: option.id,
        name: option.name,
      }));
    },

    convertToDictionaryFromIds(ids, items) {
      if (!ids || !items) {
        return [];
      }

      return ids.map((id) => items.find((item) => item.id === id));
    },

    convertFromDictionaryToIds(items) {
      if (!items) {
        return [];
      }

      return items.map((item) => item.id);
    },

    async printReport() {
      const [err, data] = await this.fetchReport({ format: 'html' });

      if (err || !data) {
        return;
      }

      this.$nextTick(() => {
        this.$htmlToPaper('htmlReport');
      });
    },
  },
};
</script>
