<template>
  <div class="value-interval-wrap">
    <b-form-row>
      <b-col cols="6">
        <div class="text-search">
          <masked-input
            type="text"
            class="form-control form-control-sm"
            :value="convertValue(from)"
            :mask="mask.getTimeMask"
            :guide="false"
            :placeholder="placeholderFrom"
            :disabled="disabled"
            @change="changeFrom($event.target.value)"
          >
          </masked-input>
          <span class="trash" @click="changeFrom('')"><icon icon="fa-times"></icon></span>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="text-search">
          <masked-input
            type="text"
            class="form-control form-control-sm"
            placeholderChar="_"
            :value="convertValue(to)"
            :mask="mask.getTimeMask"
            :guide="false"
            :placeholder="placeholderTo"
            :disabled="disabled"
            @change="changeTo($event.target.value)"
          >
          </masked-input>
          <span class="trash" @click="changeTo('')"><icon icon="fa-times"></icon></span>
        </div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import { MaskHelper } from '../../utils/mask';
import { placeholder } from '../../utils/placeholder';

export default {
  components: {
    MaskedInput,
  },
  props: {
    value: {
      default: () => ({
        from: '',
        to: '',
      }),
    },
    placeholderFrom: {
      type: String,
      default: `От ${placeholder.TIME}`,
    },
    placeholderTo: {
      type: String,
      default: `До ${placeholder.TIME}`,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      mask: MaskHelper,
    };
  },
  computed: {
    from() {
      return this.value && this.value.from;
    },
    to() {
      return this.value && this.value.to;
    },
  },
  methods: {
    changeFrom(value) {
      this.$emit('change', {
        from: value,
        to: this.to,
      });
    },
    changeTo(value) {
      this.$emit('change', {
        from: this.from,
        to: value,
      });
    },
    convertValue(value) {
      if (!value) {
        return '';
      }

      return value;
    },
  },
};
</script>
