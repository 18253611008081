<template>
  <analytics-rfm-provider
    v-bind="filter"
  >
    <template #default="{ items, loading }">
      <multi-view>
        <template #side>
          <params
            :filter="filter"
            :disabled="loading"
            @change="filter[$event.field] = $event.value"
          ></params>
        </template>

        <template #content>
          <div class="d-flex p-2 mb-3">
            <dates
              class="mr-3"
              :value="filter.dates"
              :disabled="loading"
              @change="filter.dates = $event"
            ></dates>

            <cinemas
              class="mr-3"
              :cinemas="cinemas"
              :value="filter.cinemaIds"
              :disabled="loading"
              @change="filter.cinemaIds = $event"
            ></cinemas>
          </div>

          <div class="d-flex mb-3">
            <div class="flex-grow-1">
              <chart-line
                y-label=""
                :aspect-ratio="3"
                :y-auto-scale="true"
                :line-width="1.5"
                :x-ticks-auto="true"
                :data="getChartItems(items)"
                :periods="formattedDates"
                :colors="colors"
                :show-tooltips="true"
                :labels="labels"
              ></chart-line>
            </div>

            <div class="w-15">
              <div class="font-weight-bold mb-2">Сегменты:</div>

              <segments
                class="mb-3"
                :titles="rfmTitles"
                :recency-titles="recencyTitles"
                :frequency-titles="frequencyTitles"
                :monetary-titles="monetaryTitles"
                :value="segments"
                :disabled="loading"
                @change="segments = $event"
              ></segments>

              <div
                v-for="(segment, index) in convertedSegments"
                :key="index"
                class="d-flex align-items-center mb-1"
              >
                <div
                  class="mark mr-2"
                  :style="{'background-color': colors[index]}"
                ></div>
                <div>{{ getSegmentTitle(segment) }}</div>
              </div>

              <b-form-group
                label="Тип отображения:"
                class="mt-3"
                :disabled="loading"
              >
                <b-form-radio
                  v-model="type"
                  value="count"
                >
                  Клиенты
                </b-form-radio>
                <b-form-radio
                  v-model="type"
                  value="price"
                >
                  Деньги
                </b-form-radio>
              </b-form-group>
            </div>
          </div>

          <b-table
            class="datatable border"
            :bordered="true"
            :fields="fields"
            :items="getTableItems(items)"
            :striped="true"
            :hover="false"
            :show-empty="true"
            :empty-text="loading ? 'Загрузка...' : 'Нет данных'"
            :busy="loading"
          >
            <template #cell(segment)="data">
              <div>{{ data.value }}</div>
            </template>

            <template #cell(recency)="data">
              <div>{{ data.value }}</div>
            </template>

            <template #cell(frequency)="data">
              <div>{{ data.value }}</div>
            </template>

            <template #cell(monetary)="data">
              <div>{{ data.value }}</div>
            </template>

            <template #cell()="data">
              <div
                class="h-100 d-flex align-items-center justify-content-center"
                :style="{'background-color': levels[getLevel(data.item.values, data.value)] }"
              >{{ data.value.toLocaleString() }}</div>
            </template>

            <template #cell(average)="data">
              <div class="h-100 d-flex align-items-center justify-content-center">{{ data.value.toLocaleString() }}</div>
            </template>
          </b-table>
        </template>
      </multi-view>
    </template>
  </analytics-rfm-provider>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import MultiView from '../../../layout/MultiView.vue';
import AnalyticsRfmProvider from '../../../providers/AnalyticsRfmProvider';
import ChartLine from '../../../components/charts/ChartLine.vue';
import Dates from './Dates.vue';
import Segments from './Segments.vue';
import Cinemas from '../rfm/Cinemas.vue';
import Params from '../rfm/Params.vue';

import colors from '../../../utils/colors';

import {
  OFFSET_MONTH, getDates,
} from '../rfm/dates';

import rfmTitles, { recencyTitles, frequencyTitles, monetaryTitles } from '../rfm/rfmTitles';

const COLUMN_COUNT = 'count(customer_id)';
const COLUMN_PRICE = 'sum(orders_price)';

const RMF_FILTER_STORAGE_KEY = 'rfmFilter';

export default {
  components: {
    MultiView,
    Dates,
    AnalyticsRfmProvider,
    ChartLine,
    Segments,
    Cinemas,
    Params,
  },
  data() {
    return {
      rfmTitles,
      recencyTitles,
      frequencyTitles,
      monetaryTitles,
      filter: {
        dates: getDates(new Date(), OFFSET_MONTH, 6),
        cinemaIds: [],
        period: 30,
        ticketsCount: 0,
        ticketsOperation: '>',
        ordersCount: 0,
        ordersOperation: '>',
        recencyLowValue: 10,
        recencyHighValue: 2,
        frequencyLowValue: 2,
        frequencyHighValue: 5,
        monetaryLowValue: 100,
        monetaryHighValue: 1000,
      },
      levels: ['#ea9999', '#f4cccc', '#ffffff', '#d9ead3', '#b6d7a8'],
      segments: [],
      type: 'count',
    };
  },
  computed: {
    ...mapState('dictionaries/cinemas', {
      cinemas: (state) => state.items,
    }),

    colors() {
      return colors.map((color) => `#${color.toString(16).padStart(6, '0')}`);
    },

    formattedDates() {
      return this.filter.dates.map((date) => this.$datetime.formatDate(date));
    },

    fields() {
      const columns = [];

      if (!this.segments.length || this.segments.find((segment) => Object.keys(segment).length > 1)) {
        columns.push({
          key: 'segment',
          label: 'Сегмент',
          filterable: false,
          sortable: false,
        });
      } else {
        if (this.segments.find((segment) => 'recency' in segment)) {
          columns.push({
            key: 'recency',
            label: 'Давность',
            filterable: false,
            sortable: false,
          });
        }

        if (this.segments.find((segment) => 'frequency' in segment)) {
          columns.push({
            key: 'frequency',
            label: 'Частота',
            filterable: false,
            sortable: false,
          });
        }

        if (this.segments.find((segment) => 'monetary' in segment)) {
          columns.push({
            key: 'monetary',
            label: 'Ценность',
            filterable: false,
            sortable: false,
          });
        }
      }

      return [
        ...columns,
        ...this.filter.dates.map((date, index) => ({
          key: this.$datetime.formatDate(date),
          label: this.$datetime.formatDate(date),
          filterable: false,
          sortable: true,
          class: 'p-0 h-100 text-center',
          dateIndex: index,
        })),
        {
          key: 'average',
          label: 'Среднее значение',
          class: 'text-center',
          filterable: false,
          sortable: true,
        },
      ];
    },

    convertedSegments() {
      if (!this.segments.length) {
        return [];
      }

      if (this.segments.find((segment) => Object.keys(segment).length > 1)) {
        return this.segments.filter((segment) => Object.keys(segment).length > 1);
      }

      const recencySegments = this.segments.filter((segment) => 'recency' in segment);
      const frequencySegments = this.segments.filter((segment) => 'frequency' in segment);
      const monetarySegments = this.segments.filter((segment) => 'monetary' in segment);

      const segments = [recencySegments, frequencySegments, monetarySegments].filter((_segments) => _segments.length);

      let result = segments.shift();

      for (const currentSegments of segments) {
        const newResult = [];

        for (const currentSegment of currentSegments) {
          for (const resultSegment of result) {
            newResult.push({
              ...resultSegment,
              ...currentSegment,
            });
          }
        }

        result = newResult;
      }

      return result;
    },

    labels() {
      return this.convertedSegments.map((segment) => this.getSegmentTitle(segment));
    },

  },
  watch: {
    filter: {
      handler() {
        this.saveFilter();
      },
      deep: true,
    },
  },
  async created() {
    await this.fetchCinemas();
    this.filter.cinemaIds = this.cinemas.map((cinema) => cinema.id);

    this.loadFilter();
  },
  methods: {
    ...mapActions('dictionaries/cinemas', {
      fetchCinemas: 'fetchItems',
    }),

    getTitle(recency, frequency) {
      return this.rfmTitles[`frequency_${frequency}_recency_${recency}`] || 'Без названия';
    },

    getSegmentTitle({ recency, frequency, monetary }) {
      if (this.segments.find((segment) => Object.keys(segment).length > 1)) {
        return this.getTitle(recency, frequency);
      }

      const parts = [];

      if (recency) {
        parts.push(`Давность: ${this.recencyTitles[recency]}`);
      }

      if (frequency) {
        parts.push(`Частота: ${this.frequencyTitles[frequency]}`);
      }

      if (monetary) {
        parts.push(`Ценность: ${this.monetaryTitles[monetary]}`);
      }

      return parts.join(', ');
    },

    getTableItems(items) {
      const rows = [];

      for (const { recency, frequency, monetary } of this.convertedSegments) {
        const row = {};

        if (this.segments.find((segment) => Object.keys(segment).length > 1)) {
          row.segment = this.getTitle(recency, frequency);
        } else {
          if (recency) {
            row.recency = this.recencyTitles[recency];
          }

          if (frequency) {
            row.frequency = this.frequencyTitles[frequency];
          }

          if (monetary) {
            row.monetary = this.monetaryTitles[monetary];
          }
        }

        let total = 0;
        const values = [];

        for (const date of this.filter.dates) {
          const key = this.$datetime.formatDate(date);

          const value = this.getValue(items, date, recency, frequency, monetary);

          row[key] = value;

          total += value;
          values.push(value);
        }

        row.average = Math.round(total / this.filter.dates.length);
        row.values = values;

        rows.push(row);
      }

      return rows;
    },

    getChartItems(items) {
      return this.convertedSegments
        .map(({ recency, frequency, monetary }) => this.filter.dates.map((date) => this.getValue(items, date, recency, frequency, monetary)));
    },

    getValue(items, date, recency, frequency, monetary) {
      const key = `rfm_${this.$datetime.formatDate(date)}`;

      const currentItems = items[key] || [];

      return currentItems
        .filter((item) => (!recency || item.recency === recency) && (!frequency || item.frequency === frequency) && (!monetary || item.monetary === monetary))
        .reduce((total, item) => total + (this.type === 'count' ? +item[COLUMN_COUNT] : +item[COLUMN_PRICE]), 0);
    },

    getThresholds(values) {
      const levelsCount = this.levels.length;

      const thresholds = [];
      const min = Math.min(...values);
      const step = (Math.max(...values) - min) / levelsCount;

      for (let i = 0; i < levelsCount; i += 1) {
        thresholds.push(min + step * (i + 1));
      }

      return thresholds;
    },

    getLevel(values, value) {
      return this.getThresholds(values).findIndex((threshold) => value <= threshold);
    },

    saveFilter() {
      localStorage.setItem(RMF_FILTER_STORAGE_KEY, JSON.stringify(this.filter));
    },

    loadFilter() {
      const storageRfmFilter = localStorage.getItem(RMF_FILTER_STORAGE_KEY);

      if (storageRfmFilter) {
        const rfmFilter = JSON.parse(storageRfmFilter);

        this.filter = rfmFilter;

        this.filter.dates = getDates(new Date(), OFFSET_MONTH, 6);
      }
    },
  },
};
</script>

<style scoped>
.w-15 {
  width: 15%;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.datatable {
  height: 1px;
}
.datatable >>> th, .datatable >>> td {
  height: 100%;
  border: 1px solid #dee2e6 !important;
}
.datatable >>> th.text-center {
  text-align: center !important;
}
.mark {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
}
</style>
