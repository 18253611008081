var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Название:","invalid-feedback":_vm.errors.name,"state":_vm.states.name}},[_c('b-form-input',{attrs:{"type":"text","size":"sm","autofocus":"","value":_vm.item.name,"state":_vm.states.name,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('name', $event)}}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Юр. лицо:","invalid-feedback":_vm.errors.legalEntityId,"state":_vm.states.legalEntityId}},[_c('dictionary-provider',{attrs:{"name":"legalEntities"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":_vm.item.legalEntityId,"options":_vm.$array.convertToOptions(items),"state":_vm.states.legalEntityId,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('legalEntityId', $event)}}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Цена:","invalid-feedback":_vm.errors.price,"state":_vm.states.price}},[_c('b-form-input',{attrs:{"type":"text","size":"sm","value":_vm.item.price,"state":_vm.states.price,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('price', $event)}}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"НДС:","invalid-feedback":_vm.errors.vat,"state":_vm.states.vat}},[_c('dictionary-provider',{attrs:{"name":"vats"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":_vm.item.vat,"options":_vm.$array.convertToOptions(items, { value: null, text: 'Без НДС' }),"state":_vm.states.vat,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('vat', $event)}}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Группа для отображения:","invalid-feedback":_vm.errors.productGroupId,"state":_vm.states.productGroupId}},[_c('dictionary-provider',{attrs:{"name":"productGroups","filter":{ cinemaId: _vm.cinemaId }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":_vm.item.productGroupId,"options":_vm.$array.convertToOptions(items),"state":_vm.states.productGroupId,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('productGroupId', $event)}}})]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Группа для отчетов:","invalid-feedback":_vm.errors.productReportGroupId,"state":_vm.states.productReportGroupId}},[_c('dictionary-provider',{attrs:{"name":"productReportGroups","filter":{ cinemaId: _vm.cinemaId },"auto-reset":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":_vm.item.productReportGroupId,"options":_vm.$array.convertToOptions(items),"state":_vm.states.productReportGroupId,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('productReportGroupId', $event)}}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Признак товара:","invalid-feedback":_vm.errors.paymentSubjectId,"state":_vm.states.paymentSubjectId}},[_c('dictionary-provider',{attrs:{"name":"paymentSubjects"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":_vm.item.paymentSubjectId,"options":_vm.$array.convertToOptions(items),"state":_vm.states.paymentSubjectId,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('paymentSubjectId', $event)}}})]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Метод оплаты:","invalid-feedback":_vm.errors.paymentMethodId,"state":_vm.states.paymentMethodId}},[_c('dictionary-provider',{attrs:{"name":"paymentMethods"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":_vm.item.paymentMethodId,"options":_vm.$array.convertToOptions(items),"state":_vm.states.paymentMethodId,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('paymentMethodId', $event)}}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Продажа по агентскому договору:","invalid-feedback":_vm.errors.agentLegalEntityId,"state":_vm.states.agentLegalEntityId}},[_c('dictionary-provider',{attrs:{"name":"legalEntities"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":_vm.item.agentLegalEntityId,"options":_vm.$array.convertToOptions(items),"state":_vm.states.agentLegalEntityId,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('agentLegalEntityId', $event)}}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"invalid-feedback":_vm.errors.offerWithTicket,"state":_vm.states.offerWithTicket}},[_c('b-form-checkbox',{attrs:{"checked":_vm.item.offerWithTicket,"state":_vm.states.offerWithTicket,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('offerWithTicket', !_vm.item.offerWithTicket)}}},[_vm._v(" Предлагать с билетами ")])],1)],1)],1),[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Группы приложений:","invalid-feedback":_vm.errors.appGroups,"state":_vm.states.appGroups}},[_c('dictionary-provider',{attrs:{"name":"appGroups"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var appGroups = ref.items;
return [_c('toggle-list',{attrs:{"placeholder":"Все","options":appGroups,"value":_vm.item.appGroups,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('appGroups', $event)}}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Форматы фильмов:","invalid-feedback":_vm.errors.formats,"state":_vm.states.formats}},[_c('dictionary-provider',{attrs:{"name":"formats2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('toggle-list',{attrs:{"placeholder":"Все","options":items,"value":_vm.item.formats,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('formats', $event)}}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Возрастное ограничение фильма:","invalid-feedback":_vm.errors.ageLimit,"state":_vm.states.ageLimit}},[_c('dictionary-provider',{attrs:{"name":"ageLimits"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('toggle-list',{attrs:{"placeholder":"Все","options":items,"value":_vm.item.ageLimits,"disabled":_vm.loading},on:{"change":function($event){return _vm.updateValue('ageLimits', $event)}}})]}}])})],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }