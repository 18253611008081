<template>
  <div class="table-container selections">
    <collection-provider
      collection="selections"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(selectionGroupId)>
            <b-form-select
                size="sm"
                :value="data.filter.selectionGroupId"
                :options="$array.convertToOptions(getSelectionItems())"
                :disabled="data.loading"
                @change="actions.changeFilter('selectionGroupId', $event)"
            ></b-form-select>
          </template>
          <template #cell(selectionGroupId)="data">
            {{ getSelectionGroupName(data.item.selectionGroupId) }}
          </template>
          <template #cell(period)="data">
            <template v-if="data.item.dateStart">
              c {{ $datetime.formatDate(data.item.dateStart) }}
            </template>
            <template v-if="data.item.dateEnd">
              по {{ $datetime.formatDate(data.item.dateEnd) }}
            </template>
          </template>

          <template #cell(export)="data">
            <a
              class="text-primary cursor-pointer"
              @click="exportSelection(data.item)"
            >Экспорт</a>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.selection.show"
      create-title="Создание выборки"
      edit-title="Редактирование выборки"
      collection="selections"
      size="xl"
      :id="modals.selection.id"
      :copy="modals.selection.copy"
      :init="{ status: true, include: {}, exclude: {} }"
      @close="modals.selection.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <selection-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></selection-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import SelectionForm from './SelectionForm.vue';

export default {
  components: {
    CollectionProvider,
    ModalModelForm,
    Datatable,
    SelectionForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        selection: {
          show: false,
          copy: false,
          id: 0,
        },
      },
    };
  },
  computed: {
    ...mapState('dictionaries/selectionGroups', {
      selectionGroups: (state) => state.items,
    }),
  },
  async created() {
    await this.$store.dispatch('dictionaries/selectionGroups/fetchItems');
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateSelectionForm() {
      this.modals.selection.id = '';
      this.modals.selection.show = true;
      this.modals.selection.copy = false;
    },

    copySelection(id) {
      this.modals.selection.id = id;
      this.modals.selection.show = true;
      this.modals.selection.copy = true;
    },

    openEditSelectionForm(id) {
      this.modals.selection.id = id;
      this.modals.selection.show = true;
      this.modals.selection.copy = false;
    },

    getSelectionGroupName(selectionGroupId) {
      const selectionGroup = this.selectionGroups.find(({ id }) => id === selectionGroupId);
      return selectionGroup ? selectionGroup.name : '';
    },

    getSelectionItems() {
      return this.selectionGroups;
    },

    async removeCheckedSelections() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    async removeSelection(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    exportSelection({ id, name }) {
      this.$store.dispatch('data/selections/exportSelection', {
        id,
        filename: name,
      });
    },
  },
};
</script>
