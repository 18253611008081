<template>
  <b-nav-item-dropdown
    class="navbar-top-item"
    :text="text"
    :class="classes"
    @show="show"
    :no-caret="auto"
    @click="item.action && $emit('click', item.action, item.args)"
  >
    <template #button-content>
      <icon v-if="icon" :icon="icon" /> {{text}}
    </template>

    <template v-for="(item, index) in items">
      <b-dropdown-item
        v-if="!item.children"
        class="navbar-top-item__subitem"
        :to="getUrl(item)"
        :disabled="item.disabled"
        :key="index"
        @click="item.action && $emit('click', item.action, item.args)"
      >
        <icon
          v-if="item.icon"
          :icon="item.icon"
        />
        {{item.name}}
        <b-badge
          v-if="item.badge && item.badge.text"
          :variant="item.badge.variant"
        >{{item.badge.text}}</b-badge>
      </b-dropdown-item>

      <b-dropdown-item
        v-if="item.children"
        class="navbar-top-item__subitem has-submenu"
        :key="index"
      >
        <icon
          v-if="item.icon"
          :icon="item.icon"
        />
        {{item.name}}
        <ul class="dropdown-menu">
          <b-dropdown-item
            v-for="(item, index) in item.children"
            class="navbar-top-item__subitem"
            exact
            :to="getUrl(item)"
            :disabled="item.disabled"
            :key="index"
            @click="item.action && $emit('click', item.action, item.args)"
          >
            <icon v-if="item.icon" :icon="item.icon" />
            {{item.name}}
            <b-badge
              v-if="item.badge && item.badge.text"
              :variant="item.badge.variant"
            >{{item.badge.text}}</b-badge>
          </b-dropdown-item>
        </ul>
      </b-dropdown-item>
    </template>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  props: {
    text: {
      default: '',
    },
    icon: {
      default: '',
    },
    auto: {
      type: Boolean,
      default: false,
    },
    items: {
      default: () => ([]),
    },
    active: {
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        this.auto ? 'navbar-top-item_auto' : '',
        this.active ? 'active' : '',
      ];
    },
  },
  methods: {
    show(evt) {
      if (this.auto) {
        evt.preventDefault();
      }
    },

    getUrl(item) {
      if (item.url) {
        return item.url;
      }

      if (item.routeName) {
        const route = this.$router.resolve({ name: item.routeName, params: item.routeParams });
        return route?.resolved?.path;
      }

      return false;
    },
  },
};
</script>

<style>
.navbar-top-item > a {
  padding: 10px 18px !important;
  color: #97999f !important;
  border-left: 0px solid #2f323e !important;
}
.navbar-top-item.active > a {
  background: #2cabe3 !important;
  color: #ffffff  !important;
}
.navbar-top-item > a.active {
  font-weight: 500  !important;
}
.navbar-top-item__subitem.active, .navbar-top-item__subitem:active {
  color: #2cabe3;
  text-decoration: none;
  background-color: inherit;
}
.navbar-top-item_auto > .dropdown-menu {
  margin-top: -1px;
  min-width: 15rem;
}
.navbar-top-item_auto:hover > .dropdown-menu {
  display: block;
}
.navbar-top-item__subitem {
  position: relative;
}
.navbar-top-item__subitem  .dropdown-menu {
  top: 0;
  margin-top: -8px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
  left: 100%;
}
.navbar-top-item__subitem:hover .dropdown-menu {
  display: block;
}
.navbar-top-item__subitem.has-submenu > .dropdown-item:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 7px;
  margin-left: 9px;
}
.navbar-top-item__subitem.has-submenu:hover:after {
  border-left-color: #fff;
}
</style>
