/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
import Vue from 'vue';

import userService from '../services/User';

const User = {
  storage: new Vue({
    data: {},
  }),

  $on(event, callback) {
    this.storage.$on(event, callback);
  },

  $off(event) {
    this.storage.$off(event);
  },

  $emit(event, payload) {
    this.storage.$emit(event, payload);
  },

  async loadUser() {
    const [, user] = await userService.fetchUser();

    if (user) {
      for (const key of Object.keys(user)) {
        this.storage.$data[key] = user[key];

        if (!Object.prototype.hasOwnProperty.call(this, key)) {
          Object.defineProperty(this, key, { get() { return this.storage.$data[key]; } });
        }
      }

      this.$emit('logged');
    }
  },

  async resetUserInfo() {
    for (const key of Object.keys(this.storage.$data)) {
      this.storage.$data[key] = null;
    }
  },

  async login({ email, password }) {
    const [err, result] = await userService.loginUser({ email, password });

    if (result) {
      await this.loadUser();

      return [null, true];
    }

    return [err, null];
  },

  async logout() {
    await userService.logoutUser();
    this.resetUserInfo();
    this.$emit('logout');
  },

  canEvery(permissions) {
    if (Array.isArray(this.storage.$data.permissions) && Array.isArray(permissions)) {
      return permissions.every((item) => this.storage.$data.permissions.includes(item));
    }

    return false;
  },

  canSome(permissions) {
    if (Array.isArray(this.storage.$data.permissions) && Array.isArray(permissions)) {
      return permissions.some((item) => this.storage.$data.permissions.includes(item));
    }

    return false;
  },

  can(permission) {
    if (Array.isArray(this.storage.$data.permissions) && permission) {
      return this.storage.$data.permissions.includes(permission);
    }

    return false;
  },

  async guard(to, next, loginPageRoute) {
    if (to.meta.auth && !this.storage.$data.id) {
      await this.loadUser();
    }

    if (to.meta.auth && !this.storage.$data.id) {
      next({
        path: loginPageRoute,
        query: { redirect: to.fullPath },
      });

      return;
    }

    if (to.meta.permissions && !this.canSome(to.meta.permissions)) {
      next({ name: 'dashboard' });

      return;
    }

    next();
  },
};

export default {
  install(Vue) {
    Vue.prototype.$user = User;
  },
};
