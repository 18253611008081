<template>
  <multi-view-provider
    collection="cinemas"
    route-name="cinemas"
    route-param-name="cinemaId"
    :default-item-id="cinemaId"
  >
    <template #default="{ data, actions }">
      <div class="h-100 d-flex flex-column">
        <portal to="topbar">
          <cinemas-select
            :cinemas="data.items"
            :cinema-id="data.item && data.item.id"
            :disabled="data.loading"
            @change="actions.open($event), saveCinemaId($event)"
          ></cinemas-select>
        </portal>
        <div class="d-flex border-bottom shadow-sm p-2 z-1">
          <index-tabs
            v-if="data.item && data.item.id"
            class="mr-auto"
            :cinema-id="data.item && data.item.id"
          ></index-tabs>
          <cinema-buttons
            v-if="tab === 'cinema'"
            :disabled="data.loading"
            :cinema-id="data.item && data.item.id"
            @create-cinema="openCreateCinemaForm()"
            @edit-cinema="openEditCinemaForm(data.item && data.item.id)"
          ></cinema-buttons>
          <halls-buttons
            v-if="tab === 'halls'"
            :disabled="data.loading"
            @create-cinema="openCreateCinemaForm()"
            @create-hall="openCreateHallForm(data.item && data.item.id)"
          ></halls-buttons>
          <employees-buttons
            v-if="tab === 'employees'"
            :disabled="data.loading"
            @create-cinema="openCreateCinemaForm()"
            @create-employee="$refs.employees.openCreateEmployeeForm()"
          ></employees-buttons>
          <cashier-places-buttons
            v-if="tab === 'cashier-places'"
            :disabled="data.loading"
            @create-cinema="openCreateCinemaForm()"
            @create-cashier-place="$refs.cashierPlaces.openCreateCashierPlaceForm()"
          ></cashier-places-buttons>
          <cashier-devices-buttons
            v-if="tab === 'cashier-devices'"
            :disabled="data.loading"
            @create-cinema="openCreateCinemaForm()"
            @create-cashier-device="$refs.cashierDevices.openCreateCashierDeviceForm()"
          ></cashier-devices-buttons>
          <kiosks-buttons
            v-if="tab === 'kiosks'"
            :disabled="data.loading"
            @create-cinema="openCreateCinemaForm()"
            @create-kiosk="$refs.kiosks.openCreateKioskForm()"
          ></kiosks-buttons>
          <documents-buttons
            v-if="tab === 'documents'"
            :disabled="data.loading"
            @create-cinema="openCreateCinemaForm()"
            @create-document="$refs.documents.openCreateDocumentForm()"
          ></documents-buttons>
          <settings-buttons
            v-if="tab === 'settings'"
            :disabled="data.loading"
            @create-cinema="openCreateCinemaForm()"
            @edit-settings="openEditSettingsForm()"
          ></settings-buttons>
          <cinema-social-networks-buttons
            v-if="tab === 'social-networks'"
            :disabled="data.loading"
            @create-cinema="openCreateCinemaForm()"
            @create-cinema-social-network="$refs.cinemaSocialNetworks.openCreateCinemaSocialNetworkForm()"
          ></cinema-social-networks-buttons>
          <cinema-photos-buttons
            v-if="tab === 'cinema-photos'"
            :disabled="data.loading"
            @create-cinema="openCreateCinemaForm()"
            @create-cinema-photo="$refs.cinemaPhotos.openCreateCinemaPhotoForm()"
          ></cinema-photos-buttons>
        </div>
        <scroll>
          <loading v-if="data.loading"></loading>
          <template v-if="!data.loading">
            <cinema
              v-if="tab === 'cinema' && $user.can('cinemas.view')"
              ref="cinema"
              :item="data.item"
            ></cinema>
            <employees
              v-if="tab === 'employees' && $user.can('employees.view')"
              ref="employees"
              :cinema-id="data.item && data.item.id"
            ></employees>
            <halls
              v-if="tab === 'halls' && $user.can('halls.view')"
              ref="halls"
              :cinema-id="data.item && data.item.id"
            ></halls>
            <cashier-places
              v-if="tab === 'cashier-places' && $user.can('cashier_places.view')"
              ref="cashierPlaces"
              :cinema-id="data.item && data.item.id"
            ></cashier-places>
            <cashier-devices
              v-if="tab === 'cashier-devices' && $user.can('cashier_devices.view')"
              ref="cashierDevices"
              :cinema-id="data.item && data.item.id"
            ></cashier-devices>
            <kiosks
              v-if="tab === 'kiosks' && $user.can('kiosks.view')"
              ref="kiosks"
              :cinema-id="data.item && data.item.id"
            ></kiosks>
            <documents
              v-if="tab === 'documents' && $user.can('documents.view')"
              ref="documents"
              :cinema-id="data.item && data.item.id"
            ></documents>
            <template v-if="tab === 'settings' && $user.can('settings.view')">
              <model-provider
                ref="settings"
                collection="settings"
                :id="data.item && data.item.id"
              >
                <template #default="{ data: { loading, item } }">
                  <settings
                    :item="item"
                    @edit-settings="openEditSettingsForm"
                  ></settings>
                </template>
              </model-provider>
            </template>
            <cinema-social-networks
              v-if="tab === 'social-networks' && $user.can('social_networks.view')"
              ref="cinemaSocialNetworks"
              :cinema-id="data.item && data.item.id"
            ></cinema-social-networks>
            <cinema-photos
              v-if="tab === 'cinema-photos' && $user.can('cinema_photos.view')"
              ref="cinemaPhotos"
              :cinema-id="data.item && data.item.id"
            ></cinema-photos>
          </template>
        </scroll>
        <portal to="main">
          <modal-model-form
            v-if="modals.cinema.show"
            create-title="Создание кинотеатра"
            edit-title="Редактирование кинотеатра"
            collection="cinemas"
            :id="modals.cinema.id"
            @close="modals.cinema.show = false"
            @saved="actions.load($event)"
          >
            <template #default="{ data, actions }">
              <cinema-form
                :loading="data.loading"
                :errors="data.errors"
                :states="data.states"
                :item="data.item"
                @change="actions.setValue($event.field, $event.value)"
              ></cinema-form>
            </template>
          </modal-model-form>

          <modal-model-form
            v-if="modals.settings.show"
            edit-title="Редактирование настроек"
            collection="settings"
            :id="data.item && data.item.id"
            :init="{
              mediapanels__loggingEnabled: false,
              sms__disabled: false,
              bonusSystem__enabledCustomerApproval: false,
              bonusSystem__enabledOrderApproval: false,
              fiscalization__ticketsUseAgent: false,
              bonusSystem__enabled: false,
              bonusSystem__enabledMixedMode: false,
              extApi__smsCodeEnabled: false,
              iikoServer__enabled: false,
            }"
            @close="modals.settings.show = false"
            @saved="$refs.settings.load()"
            @shown="scrollToSelectedSettings"
          >
            <template #default="{ data, actions }">
              <settings-form
                :loading="data.loading"
                :errors="data.errors"
                :states="data.states"
                :item="data.item"
                :scroll-to="modals.settings.scrollTo"
                @change="actions.setValue($event.field, $event.value)"
              ></settings-form>
            </template>
          </modal-model-form>
        </portal>
      </div>
    </template>
  </multi-view-provider>
</template>

<script>
import { mapState } from 'vuex';

import CinemasSelect from '../../components/special/CinemasSelect.vue';
import Loading from '../../components/Loading.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';

import MultiViewProvider from '../../providers/MultiViewProvider.vue';
import ModelProvider from '../../providers/ModelProvider';

import CashierDevices from './CashierDevices.vue';
import CashierDevicesButtons from './CashierDevicesButtons.vue';
import CashierPlaces from './CashierPlaces.vue';
import CashierPlacesButtons from './CashierPlacesButtons.vue';
import Cinema from './Cinema.vue';
import CinemaButtons from './CinemaButtons.vue';
import CinemaForm from './CinemaForm.vue';
import CinemaPhotos from './CinemaPhotos.vue';
import CinemaPhotosButtons from './CinemaPhotosButtons.vue';
import CinemaSocialNetworks from './CinemaSocialNetworks.vue';
import CinemaSocialNetworksButtons from './CinemaSocialNetworksButtons.vue';
import Documents from './Documents.vue';
import DocumentsButtons from './DocumentsButtons.vue';
import Employees from './Employees.vue';
import EmployeesButtons from './EmployeesButtons.vue';
import Halls from './Halls.vue';
import HallsButtons from './HallsButtons.vue';
import IndexTabs from './IndexTabs.vue';
import Kiosks from './Kiosks.vue';
import KiosksButtons from './KiosksButtons.vue';
import Settings from './Settings.vue';
import SettingsButtons from './SettingsButtons.vue';
import SettingsForm from './SettingsForm.vue';

export default {
  components: {
    CashierDevices,
    CashierDevicesButtons,
    CashierPlaces,
    CashierPlacesButtons,
    Cinema,
    CinemaButtons,
    CinemaForm,
    CinemaPhotos,
    CinemaPhotosButtons,
    CinemaSocialNetworks,
    CinemaSocialNetworksButtons,
    CinemasSelect,
    Documents,
    DocumentsButtons,
    Employees,
    EmployeesButtons,
    Halls,
    HallsButtons,
    IndexTabs,
    Kiosks,
    KiosksButtons,
    Loading,
    ModalModelForm,
    ModelProvider,
    MultiViewProvider,
    Settings,
    SettingsButtons,
    SettingsForm,
  },
  data() {
    return {
      modals: {
        cinema: {
          show: false,
          id: null,
        },
        settings: {
          show: false,
          scrollTo: null,
        },
      },
    };
  },
  computed: {
    ...mapState(['cinemaId']),

    tab() {
      const tabs = [
        'cashier-devices',
        'cashier-places',
        'cinema-photos',
        'cinema',
        'documents',
        'employees',
        'halls',
        'kiosks',
        'settings',
        'social-networks',
      ];
      const tab = this.$route.params.tab || 'cinema';

      return tabs.includes(tab) ? tab : 'cinema';
    },
  },
  methods: {
    openCreateCinemaForm() {
      this.modals.cinema.id = null;
      this.modals.cinema.show = true;
    },

    openEditCinemaForm(id) {
      this.modals.cinema.id = id;
      this.modals.cinema.show = true;
    },

    openEditSettingsForm(args) {
      this.modals.settings.show = true;
      this.modals.settings.scrollTo = args?.scrollTo;
    },

    scrollToSelectedSettings(event) {
      const element = document.getElementById(this.modals.settings.scrollTo);

      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 50);
      }
    },

    openCreateHallForm(id) {
      this.$router.push({ name: 'createHall', params: { cinema: id } });
    },

    saveCinemaId(id) {
      this.$store.commit('setCinemaId', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.z-1 {
  z-index: 1;
}
</style>
