<template>
  <b-row>
    <b-col cols="12">
      <b-form-group label="Режим работы:">
        <b-form-select
          size="sm"
          v-model="settingLocal.mode"
          :options="options"
        >
        </b-form-select>
      </b-form-group>

      <b-form-group v-if="!isModeFavoriteReleases">
        <b-form-checkbox
          v-model="settingLocal.custom.showSchedule"
          switch
          :value="1"
          :unchecked-value="0"
        >
          <div class="h6 mt-1 mb-0">Отображать карточки времени</div>
        </b-form-checkbox>
        <b-row
          class="mt-2"
          v-if="settingLocal.custom.showSchedule"
        >
          <b-col cols="6">
            <b-form-group
              class="mb-0 setting-trailers__group"
              label="Состав:"
            >
              <b-form-checkbox
                v-model="settingLocal.custom.showScheduleFormat"
                :value="1"
                :unchecked-value="0"
              >
                Формат фильма
              </b-form-checkbox>
              <b-form-checkbox
                v-model="settingLocal.custom.showSchedulePrice"
                :value="1"
                :unchecked-value="0"
              >
                Цена
              </b-form-checkbox>
              <b-form-checkbox
                v-model="settingLocal.custom.showScheduleHall"
                :value="1"
                :unchecked-value="0"
              >
                Категория зала
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Размер:">
              <b-form-radio
                v-model="settingLocal.custom.bigSessionCards"
                :value="0"
              >
                Обычный
              </b-form-radio>
              <b-form-radio
                v-model="settingLocal.custom.bigSessionCards"
                :value="1"
              >
                Увеличенный
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          switch
          v-model="settingLocal.custom.showLimitDeliveryDays"
          :value="1"
          :unchecked-value="0"
          @click.native="setDaysAfterRelease"
          :disabled="isModeOpenSalePlusFavoriteReleases"
        >
          <div class="h6 mt-1 mb-0">Ограничить дни показа</div>
        </b-form-checkbox>
        <b-form-group
          class="setting-posters__group mb-0 pt-3"
          v-if="convertToBoolean(settingLocal.custom.showLimitDeliveryDays)"
        >
          <div class="w-100 d-flex mb-2">
            <b-form-select
              v-if="settingLocal.mode === 'OPEN_SALES'"
              v-model="settingLocal.custom.daysBeforeRelease"
              size="sm"
              class="setting-posters__dates"
              :options="optionsDisabledDays"
              :disabled="isModeOpenSale"
            >
            </b-form-select>
            <b-form-select
              v-else
              v-model="settingLocal.custom.daysBeforeRelease"
              size="sm"
              class="setting-posters__dates"
              :options="optionsDays"
              :disabled="isModeOpenSale"
            >
            </b-form-select>
            <div class="pl-2 text-nowrap d-flex align-items-center">
              до даты релиза
            </div>
          </div>
          <div class="w-100 d-flex">
            <b-form-select
              v-if="settingLocal.mode === 'FAVORITE_RELEASES'"
              v-model="settingLocal.custom.daysAfterRelease"
              size="sm"
              class="setting-posters__dates"
              :options="optionsDisabledDays"
              :disabled="isModeFavoriteReleases"
            >
            </b-form-select>
            <b-form-select
              v-else
              v-model="settingLocal.custom.daysAfterRelease"
              size="sm"
              class="setting-posters__dates"
              :options="optionsDays"
              :disabled="isModeFavoriteReleases"
            >
            </b-form-select>
            <div class="pl-2 text-nowrap d-flex align-items-center">
              после даты релиза
            </div>
          </div>
        </b-form-group>
      </b-form-group>

      <b-form-group label="Ротация карточек фильма (сек.):">
        <b-form-radio-group
          v-model="settingLocal.custom.duration"
          :options="durations"
        ></b-form-radio-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { VERTICAL_POSTER_MODE_OPTIONS as options } from './_index.settings';

import declOfNum from '../../utils/declOfNum';

export default {
  props: {
    setting: {
      default: () => ({}),
    },
    durations: {
      default: () => [],
    },
  },
  data: () => ({
    optionsDays: [...new Array(98)].map((item, index) => ({
      value: index + 1,
      text: `${index + 1} ${declOfNum(index + 1, ['день', 'дня', 'дней'])}`,
    })),
    optionsDisabledDays: [{
      value: 0,
      text: '0 дней',
    }],
    options,
    disableLimitDeliveryDays: true,
    settingLocal: {
      changeModeState: false,
      mode: 'OPEN_SALES',
      custom: {
        bigSessionCards: 1,
        showSchedule: 1,
        showScheduleHall: 1,
        showScheduleFormat: 1,
        showSchedulePrice: 1,
        showLimitDeliveryDays: 1,
        daysBeforeRelease: 0,
        daysAfterRelease: 0,
        duration: 5,
      },
    },
  }),
  computed: {
    mode() {
      return this.settingLocal.mode;
    },
    isModeOpenSalePlusFavoriteReleases() {
      return this.mode === 'OPEN_SALES_PLUS_FAVORITE_RELEASES';
    },
    isModeFavoriteReleases() {
      return this.mode === 'FAVORITE_RELEASES';
    },
    isModeOpenSale() {
      return this.mode === 'OPEN_SALES';
    },
  },
  watch: {
    mode: {
      handler(value) {
        if (value === 'OPEN_SALES') {
          this.settingLocal.custom.showSchedule = 1;
          this.settingLocal.custom.daysBeforeRelease = 0;
          if (this.settingLocal.changeModeState) {
            this.settingLocal.custom.daysAfterRelease = 7;
          } else {
            this.settingLocal.custom.daysAfterRelease = this.settingLocal.custom.showLimitDeliveryDays ? (
              this.setting ? (this.setting.custom.daysAfterRelease ? this.setting.custom.daysAfterRelease : 7) : 7
            ) : 0;
          }
        }
        if (value === 'OPEN_SALES_PLUS_FAVORITE_RELEASES') {
          this.settingLocal.custom.showLimitDeliveryDays = 1;
          this.settingLocal.custom.showSchedule = 1;
          if (this.settingLocal.changeModeState) {
            this.settingLocal.custom.daysBeforeRelease = 7;
            this.settingLocal.custom.daysAfterRelease = 7;
          } else {
            this.settingLocal.custom.daysBeforeRelease = this.settingLocal.custom.showLimitDeliveryDays ? (
              this.setting ? (this.setting.custom.daysBeforeRelease ? this.setting.custom.daysBeforeRelease : 7) : 7
            ) : 0;
            this.settingLocal.custom.daysAfterRelease = this.settingLocal.custom.showLimitDeliveryDays ? (
              this.setting ? (this.setting.custom.daysAfterRelease ? this.setting.custom.daysAfterRelease : 7) : 7
            ) : 0;
          }
        }
        if (value === 'FAVORITE_RELEASES') {
          this.settingLocal.custom.showSchedule = 0;
          this.settingLocal.custom.daysAfterRelease = 0;
          if (this.settingLocal.changeModeState) {
            this.settingLocal.custom.daysBeforeRelease = 7;
          } else {
            this.settingLocal.custom.daysBeforeRelease = this.settingLocal.custom.showLimitDeliveryDays ? (
              this.setting ? (this.setting.custom.daysBeforeRelease ? this.setting.custom.daysBeforeRelease : 7) : 7
            ) : 0;
          }
        }

        this.settingLocal.changeModeState = true;
      },
      immediate: true,
    },
    settingLocal: {
      handler(setting) {
        this.$emit('change', setting);
      },
      immediate: true,
      deep: true,
    },
    setting: {
      handler(setting) {
        if (!setting) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(this.settingLocal.custom)) {
          if (typeof setting.custom[key] !== 'undefined') {
            if (key === 'daysBeforeRelease' || key === 'daysAfterRelease') {
              if (setting.custom.daysBeforeRelease === 0 && setting.custom.daysAfterRelease === 0) {
                this.settingLocal.custom.showLimitDeliveryDays = 0;
              } else {
                this.settingLocal.custom.showLimitDeliveryDays = 1;
              }
            }

            this.settingLocal.custom[key] = setting.custom[key];
          }
        }

        if (setting.mode) {
          this.settingLocal.mode = this.setting.mode;
        }
      },
      immediate: true,
    },
  },
  methods: {
    setDaysAfterRelease(evt) {
      if (evt.target.checked) {
        if (this.isModeOpenSale) {
          this.settingLocal.custom.daysAfterRelease = 7;
        }
        if (this.isModeFavoriteReleases) {
          this.settingLocal.custom.daysBeforeRelease = 7;
        }
      } else {
        if (this.isModeOpenSale) {
          this.settingLocal.custom.daysAfterRelease = 0;
        }
        if (this.isModeFavoriteReleases) {
          this.settingLocal.custom.daysBeforeRelease = 0;
        }
      }
    },
    convertToBoolean(value) {
      return !!+value;
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-posters {
  &__dates,
  &__times {
    width: auto;
  }
  &__group {
    margin-left: 2.25rem;
  }
}
.setting-posters__dates {
  width: 100px;
}
.custom-control.custom-switch {
  display: inline-block;
}
</style>
