<template>
  <b-modal
    centered
    size="lg"
    ok-title="Очистить"
    ok-variant="danger"
    cancel-title="Закрыть"
    :visible="true"
    @ok.prevent="clear"
    @hidden="close"
  >
    <template #modal-title>
      <h3>Очистить день {{formatDate(date)}}</h3>
    </template>

    <b-table
      class="datatable"
      :fields="columns"
      :items="items"
    >
      <template #head(itemChecked)="data">
        <b-form-checkbox
          :checked="allChecked"
          @click.native.stop
          @change="toggleAllChecked()"
        >
        </b-form-checkbox>
      </template>

      <template #cell(itemChecked)="data">
        <b-form-checkbox
          :checked="isChecked(data.item.id)"
          @click.native.stop
          @change="toggleChecked(data.item.id)"
        ></b-form-checkbox>
      </template>

      <template #cell(name)="data">
        Зал {{ data.item.name }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
/* eslint-disable no-restricted-syntax */
export default {
  props: {
    halls: {
      default: false,
    },
    date: null,
  },
  data() {
    return {
      checked: [],
      fields: [
        {
          key: 'name',
          label: 'Название зала',
        },
      ],
    };
  },
  computed: {
    items() {
      return this.halls.map((hall) => hall);
    },

    allChecked() {
      return this.items.length && this.items.every((item) => this.checked.includes(item.id));
    },

    columns() {
      return [
        {
          key: 'itemChecked',
          label: '',
          sortable: false,
          filterable: false,
          type: 'checkbox',
          tdClass: 'column-checkbox',
        },
        ...this.fields.map((field) => ({ ...field, tdClass: `column-${field.key}` })),
      ];
    },
  },
  methods: {
    formatDate(date) {
      return date.toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' });
    },

    isChecked(id) {
      return this.checked.includes(id);
    },

    toggleChecked(id) {
      if (this.isChecked(id)) {
        this.updateChecked(this.checked.filter((_id) => _id !== id));
      } else {
        this.updateChecked([...this.checked, id]);
      }
    },

    toggleAllChecked() {
      if (!this.allChecked) {
        this.updateChecked(this.items.map((item) => item.id));
      } else {
        this.updateChecked([]);
      }
    },

    updateChecked(checked) {
      this.checked = checked;
    },

    async clear() {
      if (!this.checked.length) {
        this.$notify({
          type: 'warn',
          text: 'Выберите хоть один зал',
        });
        return;
      }

      for (let i = 0; i < this.checked.length; i++) {
        const hallId = this.checked[i];

        this.$store.dispatch('data/shows/clearDate', { date: this.date, hallId });
      }

      this.close();
    },

    close() {
      this.$emit('hide');
    },
  },
};
</script>

<style scoped>
.datatable >>> .column-checkbox {
  width: 1px;
  text-align: center;
}
</style>
