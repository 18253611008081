export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'distributorName',
    type: 'text',
    label: 'Дистрибьютор',
    sortable: false,
    filterable: true,
  },
  {
    key: 'emails',
    type: 'text',
    label: 'Адреса',
    sortable: false,
    filterable: true,
  },
];
