<template>
  <div class="timeline-item-release" :title="title">
    <span class="timeline-item-release__name">{{release.movie.name}}</span>
    <span class="ml-1">{{release.format ? release.format.name : ''}}</span>
  </div>
</template>

<script>

export default {
  props: {
    release: {
      default: () => ({}),
    },
  },
  computed: {
    title() {
      return `${this.release.movie.name} ${this.release?.format?.name}`;
    },
  },
};
</script>

<style scoped>
.timeline-item-release {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.timeline-item-release__name {
  font-weight: 500;
}
</style>
