<template>
  <div class="d-flex flex-wrap">
    <div v-if="!screenshots.length" class="text-center w-100">Нет скриншотов</div>
    <div
      v-for="screenshot in screenshots"
      :key="screenshot.id"
      class="mr-2 mb-2 w-25"
    >
      <a :href="screenshot.imageUrl" target="_blank">
        <img :src="screenshot.thumbUrl" />
      </a>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    screenshots: {
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
</style>
