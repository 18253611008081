export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Наименование',
    filterable: false,
    sortable: false,
  },
  {
    key: 'title',
    type: 'title',
    label: 'Заголовок',
    filterable: false,
    sortable: false,
  },
  {
    key: 'url',
    type: 'text',
    label: 'Url',
    filterable: false,
    sortable: false,
  },
];
