<template>
  <div :class="classes"></div>
</template>

<script>
export default {
  props: {
    selected: {
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        this.selected ? 'gap-selected' : '',
      ];
    },
  },
};
</script>

<style scoped>
.gap-selected {
  background-color: yellow;
}
</style>
