import MediaPanels from '../../services/MediaPanels';

const DEFAULT_LIMIT = 20;

export default {
  namespaced: true,

  state: {
    loading: false,
    items: [],
    levels: [],
    tags: [],
    filter: {},
    page: 1,
    limit: DEFAULT_LIMIT,
    total: 0,
  },

  actions: {
    async changeFilter({ dispatch, commit }, { field, value }) {
      commit('setFilterValue', { field, value });

      await dispatch('fetchItems');
    },

    async setPage({ commit }, page) {
      commit('setPage', { page });
    },

    async changePage({ dispatch }, page) {
      dispatch('setPage', page);
      dispatch('fetchItems');
    },

    async resetPage({ commit, state: { page } }) {
      if (page) {
        commit('setPage', { page: 1 });
      }
    },

    async setLimit({ commit, dispatch }, limit) {
      dispatch('resetPage');
      commit('setLimit', { limit });
    },

    async changeLimit({ dispatch }, limit) {
      dispatch('setLimit', limit);
      await dispatch('fetchItems');
    },

    async refreshFilter({ dispatch }) {
      await dispatch('fetchItems');
    },

    async resetLimit({ commit, state: { limit } }) {
      if (limit) {
        commit('setLimit', { limit: DEFAULT_LIMIT });
      }
    },

    async resetFilter({ commit }) {
      commit('setFilter', {});
    },

    async fetchItems({ commit, state: { filter, page, limit } }) {
      commit('setLoading', true);

      const [, data] = await MediaPanels.getLogs({ filter, page, limit });

      if (data) {
        const { items, total } = data;

        commit('setItems', { items });
        commit('setTotal', { total });
      }

      commit('setLoading', false);
    },

    async fetchLevels({ commit }, cinemaId) {
      commit('setLoading', true);

      const [, data] = await MediaPanels.getLogsLevels({ filter: { cinemaId } });

      if (data) {
        commit('setLevels', data);
      }

      commit('setLoading', false);
    },

    async fetchTags({ commit }, cinemaId) {
      commit('setLoading', true);

      const [, data] = await MediaPanels.getLogsTags({ filter: { cinemaId } });

      if (data) {
        commit('setTags', data);
      }

      commit('setLoading', false);
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setTags(state, tags) {
      state.tags = tags;
    },

    setLevels(state, levels) {
      state.levels = levels;
    },

    setItems(state, { items }) {
      state.items = items;
    },

    setPage(state, { page }) {
      state.page = page;
    },

    setTotal(state, { total }) {
      state.total = total;
    },

    setLimit(state, { limit }) {
      state.limit = limit;
    },

    setFilter(state, filter) {
      state.filter = filter;
    },

    setFilterValue(state, { field, value }) {
      state.filter = {
        ...state.filter,
        [field]: value,
      };
    },
  },
};
