<template>
  <cinema-page>
    <template #fixed>
      <multi-view>
        <template #side>
          <params></params>
        </template>

        <template #panel>
          <b-button
            size="sm"
            variant="outline-info"
            :to="{ name: 'reportIndex' }"
          >
            <span slot="text">
              <icon icon="fa-chevron-left"></icon> Назад
            </span>
          </b-button>
        </template>

        <template #content>
          <div
            v-if="report"
            class="p-3 w-100"
            v-html="report"
            id="htmlReport"
            @click="handleClick"
          ></div>
        </template>
      </multi-view>
    </template>
  </cinema-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CinemaPage from '../../../layout/CinemaPage.vue';
import MultiView from '../../../layout/MultiView.vue';
import Params from './Params.vue';

export default {
  components: {
    CinemaPage,
    MultiView,
    Params,
  },
  computed: {
    ...mapState('reports/kiosksDetailed', {
      report: (state) => state.report,
      filter: (state) => state.filter,
    }),
  },
  methods: {
    ...mapActions({
      setFilter: 'reports/kiosksDetailed/setFilter',
      fetchReport: 'reports/kiosksDetailed/fetchReport',
    }),

    handleClick(e) {
      const $target = e.target;
      const value = $target?.dataset?.value;

      if (!value) {
        return;
      }

      const filterArray = [...this.filter.kiosksIdsDetailed];
      const i = filterArray.indexOf(value);

      if (i !== -1) {
        filterArray.splice(i, 1);
      } else {
        filterArray.push(value);
      }

      this.setFilter({ field: 'kiosksIdsDetailed', value: filterArray });

      this.fetchReport({ format: 'html' });
    },
  },
};
</script>

<style scoped>
@import '../../../assets/reports/consolidated-sales.css';
</style>
