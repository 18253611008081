<template>
  <b-container fluid class="p-0">
    <div
      class="text-uppercase d-flex justify-content-between mb-2 mb-3"
      id="common"
    >
      <div class="align-self-end">Общие настройки</div>
    </div>

    <b-row class="align-items-end">
      <b-col>
        <b-form-group
          label="Минимальная стоимость билета:"
          :invalid-feedback="errors.prices__minPrice"
          :state="states.prices__minPrice"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.prices__minPrice"
            :state="states.prices__minPrice"
            :disabled="loading"
            @change="updateValue('prices__minPrice', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Время начала работы сл. поддержки:"
          :invalid-feedback="errors.support__workTimeStart"
          :state="states.support__workTimeStart"
        >
          <masked-input
            type="text"
            class="form-control form-control-sm"
            :value="item.support__workTimeStart"
            :disabled="loading"
            :mask="mask.getTimeMask"
            :guide="false"
            @change="updateValue('support__workTimeStart', $event.target.value)"
          >
          </masked-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Время окончания работы сл. поддержки:"
          :invalid-feedback="errors.support__workTimeEnd"
          :state="states.support__workTimeEnd"
        >
          <masked-input
            type="text"
            class="form-control form-control-sm"
            :value="item.support__workTimeEnd"
            :disabled="loading"
            :mask="mask.getTimeMask"
            :guide="false"
            @change="updateValue('support__workTimeEnd', $event.target.value)"
          >
          </masked-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Дополнительный заголовок в билете:"
          :invalid-feedback="errors.fiscalization__ticketHeader"
          :state="states.fiscalization__ticketHeader"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.fiscalization__ticketHeader"
            :state="states.fiscalization__ticketHeader"
            :disabled="loading"
            @change="updateValue('fiscalization__ticketHeader', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
            label="Сообщение при отсутствии сеансов:"
            :invalid-feedback="errors.message__noShows"
            :state="states.message__noShows"
        >
          <b-form-input
              type="text"
              size="sm"
              :value="item.message__noShows"
              :state="states.message__noShows"
              :disabled="loading"
              @change="updateValue('message__noShows', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
            label="Владелец терминалов для приема платежей по Пушкинской карте (ИНН):"
            :invalid-feedback="errors.pushkinsCard__terminalOwner"
            :state="states.pushkinsCard__terminalOwner"
        >
          <b-form-input
              type="text"
              size="sm"
              :value="item.pushkinsCard__terminalOwner"
              :state="states.pushkinsCard__terminalOwner"
              :disabled="loading"
              @change="updateValue('pushkinsCard__terminalOwner', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <b-form-checkbox
            :checked="item.mediapanels__loggingEnabled == '1'"
            :state="states.mediapanels__loggingEnabled"
            :disabled="loading"
            @change="updateValue('mediapanels__loggingEnabled', $event)"
          >
            Включить логи медиапанелей
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="qrticket"
    >
      <div class="align-self-end">Настройки QR-ticket</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Видимость сеанса до начала, мин."
          :invalid-feedback="errors.qrTicket__beforeShowTimeout"
          :state="states.qrTicket__beforeShowTimeout"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.qrTicket__beforeShowTimeout"
              :state="states.qrTicket__beforeShowTimeout"
              :disabled="loading"
              @change="updateValue('qrTicket__beforeShowTimeout', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Видимость сеанса после окончания, мин."
          :invalid-feedback="errors.qrTicket__afterShowTimeout"
          :state="states.qrTicket__afterShowTimeout"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.qrTicket__afterShowTimeout"
              :state="states.qrTicket__afterShowTimeout"
              :disabled="loading"
              @change="updateValue('qrTicket__afterShowTimeout', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Период прохода до начала сеанса, мин."
          :invalid-feedback="errors.qrTicket__openedShowBeforeTimeout"
          :state="states.qrTicket__openedShowBeforeTimeout"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.qrTicket__openedShowBeforeTimeout"
              :state="states.qrTicket__openedShowBeforeTimeout"
              :disabled="loading"
              @change="updateValue('qrTicket__openedShowBeforeTimeout', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Период прохода после начала сеанса, мин."
          :invalid-feedback="errors.qrTicket__openedShowAfterTimeout"
          :state="states.qrTicket__openedShowAfterTimeout"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.qrTicket__openedShowAfterTimeout"
              :state="states.qrTicket__openedShowAfterTimeout"
              :disabled="loading"
              @change="updateValue('qrTicket__openedShowAfterTimeout', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="sms"
    >
      <div class="align-self-end">Настройки SMS</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
        >
          <b-form-checkbox
            :checked="item.sms__disabled == '1'"
            :state="states.sms__disabled"
            :disabled="loading"
            @change="updateValue('sms__disabled', $event)"
          >
            Отключить SMS для внешних источников
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="URL SMS-центра:"
          :invalid-feedback="errors.bonusSystem__smsUrl"
          :state="states.bonusSystem__smsUrl"
        >
          <b-form-input
            type="text"
            size="sm"
            :placeholder="placeholder.URL"
            :value="item.bonusSystem__smsUrl"
            :state="states.bonusSystem__smsUrl"
            :disabled="loading"
            @change="updateValue('bonusSystem__smsUrl', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Логин:"
          :invalid-feedback="errors.bonusSystem__smsLogin"
          :state="states.bonusSystem__smsLogin"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.bonusSystem__smsLogin"
            :state="states.bonusSystem__smsLogin"
            :disabled="loading"
            @change="updateValue('bonusSystem__smsLogin', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Пароль:"
          :invalid-feedback="errors.bonusSystem__smsPassword"
          :state="states.bonusSystem__smsPassword"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.bonusSystem__smsPassword"
            :state="states.bonusSystem__smsPassword"
            :disabled="loading"
            @change="updateValue('bonusSystem__smsPassword', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Время ожидания повторной отправки SMS (сек):"
          :invalid-feedback="errors.bonusSystem__smsTimeout"
          :state="states.bonusSystem__smsTimeout"
        >
          <b-form-input
            type="number"
            min="1"
            max="150"
            size="sm"
            :value="item.bonusSystem__smsTimeout"
            :state="states.bonusSystem__smsTimeout"
            :disabled="loading"
            @change="updateValue('bonusSystem__smsTimeout', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="SMS подтверждения:"
        >
          <b-form-checkbox
            :checked="item.bonusSystem__enabledCustomerApproval == '1'"
            :state="states.bonusSystem__enabledCustomerApproval"
            :disabled="loading"
            @change="updateValue('bonusSystem__enabledCustomerApproval', $event)"
          >
            Подтверждать создание клиента
          </b-form-checkbox>
          <b-form-checkbox
            :checked="item.bonusSystem__enabledOrderApproval == '1'"
            :state="states.bonusSystem__enabledOrderApproval"
            :disabled="loading"
            @change="updateValue('bonusSystem__enabledOrderApproval', $event)"
          >
            Подтверждать списание бонусов
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Шаблон для отправки кода:"
          description="Доступные переменные: {code}"
          :invalid-feedback="errors.bonusSystem__smsCodeTemplate"
          :state="states.bonusSystem__smsCodeTemplate"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.bonusSystem__smsCodeTemplate"
            :state="states.bonusSystem__smsCodeTemplate"
            :disabled="loading"
            @change="updateValue('bonusSystem__smsCodeTemplate', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Шаблон для отправки заказа:"
          description="Доступные переменные: {order_id}, {hall_name}, {rows_places_list}, {date}, {time}, {night_prefix_1|2} (в ночь |в ночь на )"
          :invalid-feedback="errors.bonusSystem__smsOrderTemplate"
          :state="states.bonusSystem__smsOrderTemplate"
        >
          <b-form-textarea
            type="text"
            size="sm"
            :value="item.bonusSystem__smsOrderTemplate"
            :state="states.bonusSystem__smsOrderTemplate"
            :disabled="loading"
            @change="updateValue('bonusSystem__smsOrderTemplate', $event)"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Группы приложений:"
          :invalid-feedback="errors.sms__orderAppGroups"
          :state="states.sms__orderAppGroups"
        >
          <dictionary-provider name="appGroups">
            <template #default="{ items: appGroups, loading: appGroupsLoading }">
              <toggle-list
                placeholder="Все"
                :options="appGroups"
                :value="convertToValue(item.sms__orderAppGroups, appGroups)"
                :disabled="loading || appGroupsLoading"
                @change="updateValue('sms__orderAppGroups', `[${$event.map((item) => item.id).toString()}]`)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Данные для идентификации в SMS-провайдере:"
          :invalid-feedback="errors.smsProvider__credentials"
          :state="states.smsProvider__credentials"
        >
          <b-form-input
            size="sm"
            :value="item.smsProvider__credentials"
            :state="states.smsProvider__credentials"
            :disabled="loading"
            @change="updateValue('smsProvider__credentials', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4"
      id="proculture"
    >
      <div class="align-self-end">Идентификация кинотеатра в системе PRO.Культура</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Идентификатор организации:"
          :invalid-feedback="errors.proculture__organizationId"
          :state="states.proculture__organizationId"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.proculture__organizationId"
            :state="states.proculture__organizationId"
            :disabled="loading"
            @change="updateValue('proculture__organizationId', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Идентификатор кинотеатра:"
          :invalid-feedback="errors.proculture__placeId"
          :state="states.proculture__placeId"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.proculture__placeId"
            :state="states.proculture__placeId"
            :disabled="loading"
            @change="updateValue('proculture__placeId', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4"
      id="online-orders"
    >
      <div class="align-self-end">Настройки онлайн заказов для внешних источников</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Максимальное количество мест в заказе:"
          :invalid-feedback="errors.orders__onlineMaxCountSeats"
          :state="states.orders__onlineMaxCountSeats"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.orders__onlineMaxCountSeats"
            :state="states.orders__onlineMaxCountSeats"
            :disabled="loading"
            @change="updateValue('orders__onlineMaxCountSeats', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Таймаут возврата до начала сеанса (мин):"
          :invalid-feedback="errors.orders__onlineRefundTimeout"
          :state="states.orders__onlineRefundTimeout"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.orders__onlineRefundTimeout"
            :state="states.orders__onlineRefundTimeout"
            :disabled="loading"
            @change="updateValue('orders__onlineRefundTimeout', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Таймаут покупки после начала сеанса (мин):"
          :invalid-feedback="errors.orders__onlineSalesTimeout"
          :state="states.orders__onlineSalesTimeout"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.orders__onlineSalesTimeout"
            :state="states.orders__onlineSalesTimeout"
            :disabled="loading"
            @change="updateValue('orders__onlineSalesTimeout', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="fiscal"
    >
      <div class="align-self-end">Настройки сервиса фискализации</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Ключ сервиса фискализации:"
          :invalid-feedback="errors.fiscalization__qkkmApiKey"
          :state="states.fiscalization__qkkmApiKey"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.fiscalization__qkkmApiKey"
            :state="states.fiscalization__qkkmApiKey"
            :disabled="loading"
            @change="updateValue('fiscalization__qkkmApiKey', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Клиент для фискализации полной оплаты:"
          :invalid-feedback="errors.fiscalization__ofdClientContact"
          :state="states.fiscalization__ofdClientContact"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.fiscalization__ofdClientContact"
            :state="states.fiscalization__ofdClientContact"
            :disabled="loading"
            @change="updateValue('fiscalization__ofdClientContact', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox
            :checked="item.fiscalization__ticketsUseAgent == '1'"
            :state="states.fiscalization__ticketsUseAgent"
            :disabled="loading"
            @change="updateValue('fiscalization__ticketsUseAgent', $event)"
          >
            Продажа билетов по агентскому договору
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox
            :checked="item.fiscalization__barItemsUseAgent == '1'"
            :state="states.fiscalization__barItemsUseAgent"
            :disabled="loading"
            @change="updateValue('fiscalization__barItemsUseAgent', $event)"
          >
            Продажа товаров бара по агентскому договору
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="trueApi"
    >
      <div class="align-self-end">Настройки для честного знака</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Токен доступа:"
          :invalid-feedback="errors.trueApi__token"
          :state="states.trueApi__token"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.trueApi__token"
              :state="states.trueApi__token"
              :disabled="loading"
              @change="updateValue('trueApi__token', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="egais"
    >
      <div class="align-self-end">Настройки ЕГАИС</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Адрес УТМ:"
          :invalid-feedback="errors.egais__utmAddress"
          :state="states.egais__utmAddress"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.egais__utmAddress"
              :state="states.egais__utmAddress"
              :disabled="loading"
              @change="updateValue('egais__utmAddress', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="ФСРАР ИД:"
          :invalid-feedback="errors.egais__fsrarId"
          :state="states.egais__fsrarId"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.egais__fsrarId"
              :state="states.egais__fsrarId"
              :disabled="loading"
              @change="updateValue('egais__fsrarId', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="report"
    >
      <div class="align-self-end">Настройки для отчетов</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Отчетное лицо в ЕПО:"
          :invalid-feedback="errors.reports__dailyShowsPersonId"
          :state="states.reports__dailyShowsPersonId"
        >
          <dictionary-provider name="users">
            <template #default="{ items }">
              <b-select
                size="sm"
                :options="$array.convertToOptions(items)"
                :value="item.reports__dailyShowsPersonId"
                :state="states.reports__dailyShowsPersonId"
                @change="updateValue('reports__dailyShowsPersonId', $event)"
              ></b-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Процент отчислений в РАО:"
          :invalid-feedback="errors.reports__raoRate"
          :state="states.reports__raoRate"
        >
          <b-input-group append="%" size="sm">
            <b-form-input
              size="sm"
              :value="item.reports__raoRate"
              :state="states.reports__raoRate"
              :disabled="loading"
              @change="updateValue('reports__raoRate', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Номер договора РАО:"
          :invalid-feedback="errors.reports__raoContractId"
          :state="states.reports__raoContractId"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.reports__raoContractId"
              :state="states.reports__raoContractId"
              :disabled="loading"
              @change="updateValue('reports__raoContractId', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4"
      id="ekb"
    >
      <div class="align-self-end">Идентификация в системе ЕКБ</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Идентификатор кинотеатра:"
          :invalid-feedback="errors.reports__ekbId"
          :state="states.reports__ekbId"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.reports__ekbId"
            :state="states.reports__ekbId"
            :disabled="loading"
            @change="updateValue('reports__ekbId', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Логин:"
          :invalid-feedback="errors.reports__ekbLogin"
          :state="states.reports__ekbLogin"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.reports__ekbLogin"
            :state="states.reports__ekbLogin"
            :disabled="loading"
            @change="updateValue('reports__ekbLogin', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Пароль:"
          :invalid-feedback="errors.reports__ekbPassword"
          :state="states.reports__ekbPassword"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.reports__ekbPassword"
            :state="states.reports__ekbPassword"
            :disabled="loading"
            @change="updateValue('reports__ekbPassword', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Регион:"
          :invalid-feedback="errors.reports__ekbRegionId"
          :state="states.reports__ekbRegionId"
        >
          <dictionary-provider name="ekbRegions">
            <template #default="{ items }">
              <b-select
                size="sm"
                :options="$array.convertToOptions(items)"
                :value="item.reports__ekbRegionId"
                :state="states.reports__ekbRegionId"
                @change="updateValue('reports__ekbRegionId', $event)"
              ></b-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4"
      id="rentrak"
    >
      <div class="align-self-end">Идентификация в системе Rentrak</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Параметры соединения:"
          :invalid-feedback="errors.reports__rentrakFtpCredentials"
          :state="states.reports__rentrakFtpCredentials"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.reports__rentrakFtpCredentials"
            :state="states.reports__rentrakFtpCredentials"
            :disabled="loading"
            :placeholder="placeholder.FTP_URL"
            @change="updateValue('reports__rentrakFtpCredentials', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Идентификатор сети:"
          :invalid-feedback="errors.reports__rentrakCircuitId"
          :state="states.reports__rentrakCircuitId"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.reports__rentrakCircuitId"
            :state="states.reports__rentrakCircuitId"
            :disabled="loading"
            @change="updateValue('reports__rentrakCircuitId', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Название сети в системе Rentrak:"
          :invalid-feedback="errors.reports__rentrakCircuitName"
          :state="states.reports__rentrakCircuitName"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.reports__rentrakCircuitName"
            :state="states.reports__rentrakCircuitName"
            :disabled="loading"
            @change="updateValue('reports__rentrakCircuitName', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Идентификатор кинотеатра:"
          :invalid-feedback="errors.reports__rentrakTheatreNo"
          :state="states.reports__rentrakTheatreNo"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.reports__rentrakTheatreNo"
            :state="states.reports__rentrakTheatreNo"
            :disabled="loading"
            @change="updateValue('reports__rentrakTheatreNo', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Название кинотеатра в системе Rentrak:"
          :invalid-feedback="errors.reports__rentrakTheatreName"
          :state="states.reports__rentrakTheatreName"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.reports__rentrakTheatreName"
            :state="states.reports__rentrakTheatreName"
            :disabled="loading"
            @change="updateValue('reports__rentrakTheatreName', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="bonuses"
    >
      <div class="align-self-end">Настройки бонусной системы</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox
            :checked="item.bonusSystem__enabled == '1'"
            :state="states.bonusSystem__enabled"
            :disabled="loading"
            @change="updateValue('bonusSystem__enabled', $event)"
          >
            Использовать бонусную систему
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox
            :checked="item.bonusSystem__enabledMixedMode == '1'"
            :state="states.bonusSystem__enabledMixedMode"
            :disabled="loading"
            @change="updateValue('bonusSystem__enabledMixedMode', $event)"
          >
            Использовать одновременное списание и начисление бонусов на остаток
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="align-items-end">
      <b-col>
        <b-form-group
          label="Минимальная стоимость билета при оплате бонусами:"
          :invalid-feedback="errors.bonusSystem__minTicketPrice"
          :state="states.bonusSystem__minTicketPrice"
        >
          <b-form-input
            type="number"
            min="1"
            size="sm"
            :value="item.bonusSystem__minTicketPrice"
            :state="states.bonusSystem__minTicketPrice"
            :disabled="loading"
            @change="updateValue('bonusSystem__minTicketPrice', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Процент начисления бонусов:"
          :invalid-feedback="errors.bonusSystem__accumulationPercent"
          :state="states.bonusSystem__accumulationPercent"
        >
          <b-input-group append="%" size="sm">
            <b-form-input
              type="number"
              min="1"
              size="sm"
              :value="item.bonusSystem__accumulationPercent"
              :state="states.bonusSystem__accumulationPercent"
              :disabled="loading"
              @change="updateValue('bonusSystem__accumulationPercent', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Ключ для доступа к API:"
          :invalid-feedback="errors.cinemaRequestSecret"
          :state="states.cinemaRequestSecret"
        >
          <b-form-input
            size="sm"
            :value="item.cinemaRequestSecret"
            :state="states.cinemaRequestSecret"
            :disabled="loading"
            @change="updateValue('cinemaRequestSecret', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="mobile-app"
    >
      <div class="align-self-end">Настройки для мобильного приложения</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Телефон:"
          :invalid-feedback="errors.contacts__phone"
          :state="states.contacts__phone"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.contacts__phone"
            :state="states.contacts__phone"
            :disabled="loading"
            @change="updateValue('contacts__phone', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Email:"
          :invalid-feedback="errors.contacts__email"
          :state="states.contacts__email"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.contacts__email"
            :state="states.contacts__email"
            :disabled="loading"
            @change="updateValue('contacts__email', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Ссылка на пользовательское соглашение:"
          :invalid-feedback="errors.documents__termOfUseUrl"
          :state="states.documents__termOfUseUrl"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.documents__termOfUseUrl"
            :state="states.documents__termOfUseUrl"
            :disabled="loading"
            @change="updateValue('documents__termOfUseUrl', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Ссылка на политику конфиденциальности:"
          :invalid-feedback="errors.documents__privacyPolicyUrl"
          :state="states.documents__privacyPolicyUrl"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.documents__privacyPolicyUrl"
            :state="states.documents__privacyPolicyUrl"
            :disabled="loading"
            @change="updateValue('documents__privacyPolicyUrl', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Ссылка на условия online продаж билета:"
          :invalid-feedback="errors.documents__onlineSalesRulesUrl"
          :state="states.documents__onlineSalesRulesUrl"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.documents__onlineSalesRulesUrl"
            :state="states.documents__onlineSalesRulesUrl"
            :disabled="loading"
            @change="updateValue('documents__onlineSalesRulesUrl', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Ссылка на правила посещения кинотеатра:"
          :invalid-feedback="errors.documents__cinemaUsingRulesUrl"
          :state="states.documents__cinemaUsingRulesUrl"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.documents__cinemaUsingRulesUrl"
            :state="states.documents__cinemaUsingRulesUrl"
            :disabled="loading"
            @change="updateValue('documents__cinemaUsingRulesUrl', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Ссылка на правила системы лояльности:"
          :invalid-feedback="errors.documents__bonusesSystemRulesUrl"
          :state="states.documents__bonusesSystemRulesUrl"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.documents__bonusesSystemRulesUrl"
            :state="states.documents__bonusesSystemRulesUrl"
            :disabled="loading"
            @change="updateValue('documents__bonusesSystemRulesUrl', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Ссылка на android приложение (для письма):"
          :invalid-feedback="errors.mobileApp__androidLink"
          :state="states.mobileApp__androidLink"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.mobileApp__androidLink"
            :state="states.mobileApp__androidLink"
            :disabled="loading"
            @change="updateValue('mobileApp__androidLink', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Ссылка на ios приложение (для письма):"
          :invalid-feedback="errors.mobileApp__iosLink"
          :state="states.mobileApp__iosLink"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.mobileApp__iosLink"
            :state="states.mobileApp__iosLink"
            :disabled="loading"
            @change="updateValue('mobileApp__iosLink', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="vpn"
    >
      <div class="align-self-end">Настройки VPN-API</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Адрес API кинотеатра:"
          :invalid-feedback="errors.vpnApi__cinemaUrl"
          :state="states.vpnApi__cinemaUrl"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.vpnApi__cinemaUrl"
              :state="states.vpnApi__cinemaUrl"
              :disabled="loading"
              @change="updateValue('vpnApi__cinemaUrl', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Адрес API сети:"
          :invalid-feedback="errors.vpnApi__networkUrl"
          :state="states.vpnApi__networkUrl"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.vpnApi__networkUrl"
              :state="states.vpnApi__networkUrl"
              :disabled="loading"
              @change="updateValue('vpnApi__networkUrl', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Таймаут соединения (сек):"
          :invalid-feedback="errors.vpnApi__connectTimeout"
          :state="states.vpnApi__connectTimeout"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.vpnApi__connectTimeout"
              :state="states.vpnApi__connectTimeout"
              :disabled="loading"
              @change="updateValue('vpnApi__connectTimeout', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Секретный ключ:"
          :invalid-feedback="errors.vpnApi__secret"
          :state="states.vpnApi__secret"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.vpnApi__secret"
              :state="states.vpnApi__secret"
              :disabled="loading"
              @change="updateValue('vpnApi__secret', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="ext-api"
    >
      <div class="align-self-end">Настройки внешних продаж</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Максимальное число билетов в одном заказе:"
          :invalid-feedback="errors.extApi__maxOrderTickets"
          :state="states.extApi__maxOrderTickets"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.extApi__maxOrderTickets"
              :state="states.extApi__maxOrderTickets"
              :disabled="loading"
              @change="updateValue('extApi__maxOrderTickets', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Количество минут после начала сеанса, в течение которых разрешена покупка билета:"
          :invalid-feedback="errors.extApi__createOrderTimeout"
          :state="states.extApi__createOrderTimeout"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.extApi__createOrderTimeout"
              :state="states.extApi__createOrderTimeout"
              :disabled="loading"
              @change="updateValue('extApi__createOrderTimeout', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Количество минут после начала сеанса, в течение которых разрешен возврат билетов:"
          :invalid-feedback="errors.extApi__refundOrderTimeout"
          :state="states.extApi__refundOrderTimeout"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.extApi__refundOrderTimeout"
              :state="states.extApi__refundOrderTimeout"
              :disabled="loading"
              @change="updateValue('extApi__refundOrderTimeout', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Количество минут, предоставляемых пользователю для оплаты заказа:"
          :invalid-feedback="errors.extApi__paymentTimeout"
          :state="states.extApi__paymentTimeout"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.extApi__paymentTimeout"
              :state="states.extApi__paymentTimeout"
              :disabled="loading"
              @change="updateValue('extApi__paymentTimeout', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Префикс для строкового представления идентификатора заказа:"
          :invalid-feedback="errors.extApi__orderIdPrefix"
          :state="states.extApi__orderIdPrefix"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.extApi__orderIdPrefix"
              :state="states.extApi__orderIdPrefix"
              :disabled="loading"
              @change="updateValue('extApi__orderIdPrefix', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Мерчант-аккаунт для приема платежей:"
          :invalid-feedback="errors.extApi__merchantAccountId"
          :state="states.extApi__merchantAccountId"
        >
          <dictionary-provider name="merchantAccounts">
            <template #default="{ items }">
              <b-select
                size="sm"
                :options="$array.convertToOptions(items)"
                :value="item.extApi__merchantAccountId"
                :state="states.extApi__merchantAccountId"
                @change="updateValue('extApi__merchantAccountId', $event)"
              ></b-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Мерчант-аккаунт для приема платежей через СБП:"
          :invalid-feedback="errors.extApi__sbpMerchantAccountId"
          :state="states.extApi__sbpMerchantAccountId"
        >
          <dictionary-provider name="merchantAccounts">
            <template #default="{ items }">
              <b-select
                size="sm"
                :options="$array.convertToOptions(items)"
                :value="item.extApi__sbpMerchantAccountId"
                :state="states.extApi__sbpMerchantAccountId"
                @change="updateValue('extApi__sbpMerchantAccountId', $event)"
              ></b-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Мерчант-аккаунт для приема платежей по пушкинской карте:"
          :invalid-feedback="errors.extApi__pushkinsCardMerchantAccountId"
          :state="states.extApi__pushkinsCardMerchantAccountId"
        >
          <dictionary-provider name="merchantAccounts">
            <template #default="{ items }">
              <b-select
                size="sm"
                :options="$array.convertToOptions(items)"
                :value="item.extApi__pushkinsCardMerchantAccountId"
                :state="states.extApi__pushkinsCardMerchantAccountId"
                @change="updateValue('extApi__pushkinsCardMerchantAccountId', $event)"
              ></b-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Мерчант-аккаунт для приема платежей по пушкинской карте для культурных мероприятий:"
          :invalid-feedback="errors.extApi__pushkinsCardCulturalEventMerchantAccountId"
          :state="states.extApi__pushkinsCardCulturalEventMerchantAccountId"
        >
          <dictionary-provider name="merchantAccounts">
            <template #default="{ items }">
              <b-select
                size="sm"
                :options="$array.convertToOptions(items)"
                :value="item.extApi__pushkinsCardCulturalEventMerchantAccountId"
                :state="states.extApi__pushkinsCardCulturalEventMerchantAccountId"
                @change="updateValue('extApi__pushkinsCardCulturalEventMerchantAccountId', $event)"
              ></b-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Группы приложений, для которых будет отправляться СМС с описанием заказа:"
          :invalid-feedback="errors.extApi__smsOrderAppGroups"
          :state="states.extApi__smsOrderAppGroups"
        >
          <dictionary-provider name="appGroups">
            <template #default="{ items: appGroups, loading: appGroupsLoading }">
              <toggle-list
                placeholder="Все"
                :options="appGroups"
                :value="convertToValue(item.extApi__smsOrderAppGroups, appGroups)"
                :disabled="loading || appGroupsLoading"
                @change="updateValue('extApi__smsOrderAppGroups', `[${$event.map((item) => item.id).toString()}]`)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Шаблон СМС с описанием заказа:"
          description="Доступные переменные: {order_id}, {hall}, {places}, {datetime}"
          :invalid-feedback="errors.extApi__smsOrderTemplate"
          :state="states.extApi__smsOrderTemplate"
        >
          <b-form-textarea
            type="text"
            size="sm"
            :value="item.extApi__smsOrderTemplate"
            :state="states.extApi__smsOrderTemplate"
            :disabled="loading"
            @change="updateValue('extApi__smsOrderTemplate', $event)"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Секретный ключ для подписи идентификатора заказа:"
          :invalid-feedback="errors.extApi__secret"
          :state="states.extApi__secret"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.extApi__secret"
              :state="states.extApi__secret"
              :disabled="loading"
              @change="updateValue('extApi__secret', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
        >
          <b-form-checkbox
            :checked="item.extApi__smsCodeEnabled == '1'"
            :state="states.extApi__smsCodeEnabled"
            :disabled="loading"
            @change="updateValue('extApi__smsCodeEnabled', $event)"
          >
            Включить отправку проверочных смс-кодов
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="agencyContract"
    >
      <div class="align-self-end">Настройки агентского договора</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group
          label="Номер агентского договора:"
          :invalid-feedback="errors.agencyContract__number"
          :state="states.agencyContract__number"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.agencyContract__number"
              :state="states.agencyContract__number"
              :disabled="loading"
              @change="updateValue('agencyContract__number', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Дата агентского договора:"
          :invalid-feedback="errors.agencyContract__date"
          :state="states.agencyContract__date"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.agencyContract__date"
              :state="states.agencyContract__date"
              :disabled="loading"
              @change="updateValue('agencyContract__date', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Подписант:"
          :invalid-feedback="errors.agencyContract__signer"
          :state="states.agencyContract__signer"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.agencyContract__signer"
              :state="states.agencyContract__signer"
              :disabled="loading"
              @change="updateValue('agencyContract__signer', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Размер вознаграждения за отчетный период:"
          :invalid-feedback="errors.agencyContract__fee"
          :state="states.agencyContract__fee"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.agencyContract__fee"
              :state="states.agencyContract__fee"
              :disabled="loading"
              @change="updateValue('agencyContract__fee', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="iiko"
    >
      <div class="align-self-end">Настройки сервисов IIKO</div>
    </div>

    <b-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox
            :checked="item.iikoServer__enabled == '1'"
            :state="states.iikoServer__enabled"
            :disabled="loading"
            @change="updateValue('iikoServer__enabled', $event)"
          >
            Использовать сервисы IIKO
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Адрес хоста:"
          :invalid-feedback="errors.iikoServer__url"
          :state="states.iikoServer__url"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              :value="item.iikoServer__url"
              :state="states.iikoServer__url"
              :disabled="loading"
              @change="updateValue('iikoServer__url', $event)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Логин:"
          :invalid-feedback="errors.iikoServer__login"
          :state="states.iikoServer__login"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.iikoServer__login"
            :state="states.iikoServer__login"
            :disabled="loading"
            @change="updateValue('iikoServer__login', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Пароль:"
          :invalid-feedback="errors.iikoServer__password"
          :state="states.iikoServer__password"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.iikoServer__password"
            :state="states.iikoServer__password"
            :disabled="loading"
            @change="updateValue('iikoServer__password', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <div
      class="text-uppercase d-flex justify-content-between mt-3 mb-3"
      id="analytics_rentrak"
    >
      <div class="align-self-end">Настройки аналитики</div>
    </div>
    <dictionary-provider name="rentrakCinemas">
      <template #default="{ items: rentrakCinemas, loading: rentrakCinemasLoading }">
        <div>
          <b-row>
            <b-col>
              <b-form-group
                label="Данный кинотеатр в аналитике:"
                :invalid-feedback="errors.iboe__cinemaId"
                :state="states.iboe__cinemaId"
              >
                <toggle-list
                  placeholder="Не выбрано"
                  :options="rentrakCinemas"
                  :value="convertToValue([+item.iboe__cinemaId], rentrakCinemas)"
                  :disabled="loading || rentrakCinemasLoading"
                  :searchable="true"
                  :multiple="false"
                  :closeOnSelect="true"
                  @change="updateValue('iboe__cinemaId', $event[$event.length-1].id)"
                ></toggle-list>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Связанные кинотеатры:"
                :invalid-feedback="errors.iboe__relatedCinemaIds"
                :state="states.iboe__relatedCinemaIds"
              >
                <toggle-list
                  placeholder="Не выбрано"
                  :options="rentrakCinemas"
                  :value="convertToValue(item.iboe__relatedCinemaIds, rentrakCinemas)"
                  :disabled="loading || rentrakCinemasLoading"
                  :searchable="true"
                  @change="updateValue('iboe__relatedCinemaIds', `[${$event.map((item) => item.id).toString()}]`)"
                ></toggle-list>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </template>
    </dictionary-provider>
  </b-container>
</template>

<script>
// eslint-disable-next-line import/named
import MaskedInput from 'vue-text-mask';

import { MaskHelper } from '../../utils/mask';
import { placeholder } from '../../utils/placeholder';
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';
import Date from '../../components/fields/Date.vue';

export default {
  components: {
    DictionaryProvider,
    MaskedInput,
    ToggleList,
    Date,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      placeholder,
      mask: MaskHelper,
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    convertToValue(ids, options) {
      if (!ids) {
        return [];
      }

      if (!Array.isArray(ids)) {
        ids = JSON.parse(ids);
      }

      // eslint-disable-next-line no-bitwise
      return options.filter((option) => !!~ids.indexOf(option.id));
    },
  },
};
</script>
