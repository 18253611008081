<template>
  <div class="rounded shadow-sm p-2 screen">
    <div class="d-flex screen__wrap">
      <div class="d-flex align-items-center screen__image-wrap">
        <img
          class="rounded border mw-100 w-100 screen__image"
          :src="require(`@/assets/media-panels/${icon}`)"
          :alt="name"
        />
      </div>
      <div class="flex-grow-1 d-flex screen__info">
        <div
          class="flex-grow-1 py-1 pl-2 pr-1 d-flex flex-column justify-content-between screen__content"
        >
          <div class="screen__name">
            <div>{{ name }}</div>
            <div class="mt-n1 screen__sub-name">{{ deviceId }}</div>
          </div>
          <div class="screen__type">{{ mode }}</div>
        </div>
        <slot name="action"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPES_SETTINGS as options } from './_index.settings';

export default {
  props: {
    screen: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    options,
  }),
  computed: {
    name() {
      return this.screen?.name;
    },
    deviceId() {
      return this.screen?.deviceId;
    },
    mediablockSettingsId() {
      return this.screen?.mediablockSettingsId;
    },
    code() {
      return this.settings.find((setting) => setting.id === this.mediablockSettingsId)?.type;
    },
    icon() {
      return this.options.find((option) => option.value === this.code)?.icon || 'nomode.svg';
    },
    mode() {
      return this.options.find((option) => option.value === this.code)?.text || 'Режим не выбран';
    },
  },
};
</script>

<style lang="scss">
.screen {
  cursor: grab;
  background: var(--white);

  &__menu-icon {
    color: var(--dark);
    font-size: 20px;
  }

  &__image-wrap {
    width: 150px;
  }

  &__image {
    max-height: 75px;
  }

  &__sub-name {
    font-size: 0.7em;
    color: var(--gray);
  }
}
</style>
