<template>
  <div
    class="timeline-show d-flex justify-content-between flex-column h-100 w-100 pl-1 pr-1"
    :class="classes"
    :id="`show_${show.id}`"
  >
    <div
      class="timeline-show__name align-self-start mb-auto"
      :title="movieName"
    >
      <div>{{movieName}}</div>
      <div>{{formatName}}</div>
    </div>
    <div>
      <div>{{show.ticketsCount}} / {{hall.placesCount}}</div>
    </div>
    <div class="timeline-show__prices">
      <span v-if="show.minPrice">{{show.minPrice}}₽ / </span>
      <span>{{minPrice}}₽</span>
      <span v-if="minPrice !== maxPrice">- {{maxPrice}}₽</span>
    </div>
    <div class="timeline-show__times d-flex align-self-stretch justify-content-center flex-wrap mt-auto">
      <div class="mr-auto">{{timeStart}}</div>
      <div class="ml-auto">{{timeEnd}}</div>
    </div>
    <b-tooltip
      v-if="show.id && $slots.tooltip"
      boundary="window"
      :target="`show_${show.id}`"
    >
      <slot name="tooltip"></slot>
    </b-tooltip>
  </div>
</template>

<script>
import datetime from '../../utils/datetime';

export default {
  props: {
    show: {
      default: () => ({}),
    },
    hall: {
      default: () => ({}),
    },
    selected: {
      default: false,
    },
    expired: {
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        this.selected && 'timeline-show_selected',
        (!this.expired && this.show.appGroups.length) && 'timeline-show_opened',
        (!this.expired && !this.show.appGroups.length) && 'timeline-show_closed',
        this.expired && 'timeline-show_expired',
      ];
    },

    movieName() {
      return this.show?.release?.movie?.name;
    },

    formatName() {
      return this.show?.release?.format?.name;
    },

    timeStart() {
      return datetime.formatTime(this.show.time);
    },

    timeEnd() {
      return datetime.formatTime(datetime.getIntervalEndTime(this.show.time, this.show.release.duration + this.show.advertisementsDuration));
    },

    freePlacesCount() {
      return this.hall.placesCount - this.show.ticketsCount;
    },

    minPrice() {
      if (!this.show.prices || this.show.prices.length === 0) {
        return 0;
      }

      const minPrice = this.show.prices.reduce((prev, curr) => (prev.price < curr.price ? prev : curr));

      if (!minPrice.priceId) {
        return 0;
      }

      return minPrice.price;
    },

    maxPrice() {
      if (!this.show.prices || this.show.prices.length === 0) {
        return 0;
      }

      const maxPrice = this.show.prices.reduce((prev, curr) => (prev.price > curr.price ? prev : curr));

      if (!maxPrice.priceId) {
        return 0;
      }

      return maxPrice.price;
    },
  },
};
</script>

<style scoped>
.timeline-show {
  background-color: #fff;
  border-right: 1px solid #ccc;
  font-size: 11px;
}
.timeline-show__name {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.timeline-show__info {
  color: #555;
}
.timeline-show__times {
  font-size: 10px;
  max-width: 100%;
}
.timeline-show__times > div {
  text-align: center;
}
.timeline-show_opened {
  background-color:mintcream;
}
.timeline-show_closed {
  background-color:mistyrose;
}
.timeline-show_expired {
  background-color: lightblue;
}
.timeline-show_opened.timeline-show_selected,
.timeline-show_closed.timeline-show_selected,
.timeline-show_expired.timeline-show_selected {
  background-color: #fff3e0;
}
</style>
