<template>
  <div>
    <span class="mr-1">Редактировать:</span>
    <b-select
      class="w-auto"
      size="sm"
      :value="editMode"
      @change="$emit('change-edit-mode', $event)"
    >
      <option :value="scheduleEditModeDay">Текущую дату</option>
      <option :value="scheduleEditModeWeek">Всю неделю</option>
    </b-select>
  </div>
</template>

<script>
import { SCHEDULE_EDIT_MODE_DAY, SCHEDULE_EDIT_MODE_WEEK } from '../../constants';

export default {
  props: {
    editMode: {
      default: SCHEDULE_EDIT_MODE_DAY,
    },
  },
  data() {
    return {
      scheduleEditModeDay: SCHEDULE_EDIT_MODE_DAY,
      scheduleEditModeWeek: SCHEDULE_EDIT_MODE_WEEK,
    };
  },
};
</script>
