import datetime from './datetime';

export const PERIOD_DAY = 1;
export const PERIOD_THU_SUN = 2;
export const PERIOD_THU_WED = 3;
export const PERIOD_WEEK = 4;
export const PERIOD_MONTH = 5;
export const PERIOD_QUARTER = 6;
export const PERIOD_YEAR = 7;

function pushPeriod(periods, dateStart, dateEnd) {
  periods.push({
    dateStart: datetime.convertDateToDbFormat(dateStart),
    dateEnd: datetime.convertDateToDbFormat(dateEnd),
  });
}

export function formatPeriod(start, end, period, textWithYear = false) {
  let from = '';
  let to = '';

  const dateStart = new Date(start);
  const dateEnd = new Date(end);

  if (period === PERIOD_DAY) {
    to = dateEnd.toLocaleString('ru', {
      month: 'short',
      day: 'numeric',
    });

    if (textWithYear) {
      to = `${to} ${dateEnd.getFullYear()} г`;
    }
  } else if ([PERIOD_THU_SUN, PERIOD_THU_WED, PERIOD_WEEK].includes(period)) {
    from = dateStart.toLocaleString('ru', {
      month: 'short',
      day: 'numeric',
    });

    to = dateEnd.toLocaleString('ru', {
      month: 'short',
      day: 'numeric',
    });

    if (textWithYear) {
      to = `${to} ${dateEnd.getFullYear()} г`;
    }
  } else if (period === PERIOD_QUARTER) {
    const quarter = Math.ceil((dateEnd.getMonth() + 1) / 3);
    to = `${quarter} кв ${dateEnd.getFullYear()} г`;
  } else if (period === PERIOD_MONTH) {
    const month = dateEnd.toLocaleString('ru', {
      month: 'short',
    });

    to = `${month} ${dateEnd.getFullYear()} г`;
  } else if (period === PERIOD_YEAR) {
    to = `${dateEnd.getFullYear()} г`;
  }

  const text = from ? `${from}-${to}` : to;

  return text.replaceAll('.', '');
}

export function getNotLflPeriods(date, period, periodsCount, useCalendarPeriods = true) {
  const periods = [];

  let dateStart = null;
  let dateEnd = new Date(date);
  let gap = 1;

  if (period === PERIOD_THU_SUN) {
    gap = 4;

    dateEnd = datetime.getEndOf(date, 'week');

    if (datetime.getDay(date) < 4) {
      dateEnd = datetime.subtractDays(dateEnd, 7);
    }
  } else if (period === PERIOD_THU_WED) {
    dateEnd = datetime.getWeekDay(date, 3);

    if (datetime.getDay(date) >= 4) {
      dateEnd = datetime.addDays(dateEnd, 7);
    }
  } else if (useCalendarPeriods && period !== PERIOD_DAY) {
    let calendarPeriodName = 'week';

    if (period === PERIOD_MONTH) {
      calendarPeriodName = 'month';
    } else if (period === PERIOD_QUARTER) {
      calendarPeriodName = 'quarter';
    } else if (period === PERIOD_YEAR) {
      calendarPeriodName = 'year';
    }

    dateEnd = datetime.getEndOf(date, calendarPeriodName);
  }

  for (let i = 0; i < periodsCount; i += 1) {
    if (period === PERIOD_DAY) {
      dateStart = new Date(dateEnd);
    } else if (period === PERIOD_THU_SUN) {
      dateStart = datetime.subtractDays(dateEnd, 3);
    } else if (period === PERIOD_THU_WED || period === PERIOD_WEEK) {
      dateStart = datetime.subtractDays(dateEnd, 6);
    } else if (period === PERIOD_MONTH) {
      dateStart = useCalendarPeriods ? datetime.getStartOf(dateEnd, 'month') : datetime.subtractMonthes(dateEnd, 1);
    } else if (period === PERIOD_QUARTER) {
      dateStart = useCalendarPeriods ? datetime.getStartOf(dateEnd, 'quarter') : datetime.subtractMonthes(dateEnd, 3);
    } else if (period === PERIOD_YEAR) {
      dateStart = useCalendarPeriods ? datetime.getStartOf(dateEnd, 'year') : datetime.subtractYears(dateEnd, 1);
    }

    pushPeriod(periods, dateStart, dateEnd, period);

    dateEnd = datetime.subtractDays(dateStart, gap);
  }

  periods.reverse();

  return periods;
}

export function getLflPeriods(date, period, periodsCount, useCalendarPeriods = true) {
  const periods = [];

  let refDate = new Date(date);

  for (let i = 0; i < periodsCount; i += 1) {
    const [{ dateStart, dateEnd }] = getNotLflPeriods(refDate, period, 1, useCalendarPeriods);

    pushPeriod(periods, dateStart, dateEnd, period, true);

    refDate = datetime.subtractYears(refDate, 1);
  }

  periods.reverse();

  return periods;
}
