<template>
  <analytics-customers-provider
    :periods="periods"
    :cinema-ids="checkedCinemaIds"
  >
    <template #default="{
      data: { loading, cinemasData, exportData },
    }">
      <div class="p-3">
        <periods-selector
          class="mb-4"
          :count="periodsCount"
          :default-period="PERIOD_MONTH"
          :disabled="loading"
          :only-calendar-periods="false"
          @change-date="date = $event"
          @change="periods = $event"
          @format="formattedPeriods = $event"
        >
          <b-button-group size="sm" class="ml-3">
            <b-button
              variant="outline-info"
              :disabled="loading"
              @click="periodsCount > 1 && periodsCount--"
            >-</b-button>
            <b-button
              variant="outline-info"
              :disabled="loading"
              @click="periodsCount++"
            >+</b-button>
          </b-button-group>

          <b-button
            class="ml-auto mr-2"
            variant="outline-info"
            size="sm"
            v-b-tooltip.hover="'Выгрузка контактов за последний период по выбранным кинотеатрам'"
            :disabled="loading"
            @click="exportData(checkedCinemaIds, periods[periods.length - 1])"
          >Выгрузить контакты</b-button>
        </periods-selector>

        <div>
          <b-row class="mb-4">
            <b-col cols="9">
              <chart-line
                y-label=""
                :aspect-ratio="3"
                :y-auto-scale="true"
                :line-width="1.5"
                :x-ticks-auto="true"
                :data="getChartItems(cinemasData)"
                :periods="formattedPeriods"
                :colors="checkedCinemasColors"
                :show-tooltips="true"
                :labels="checkedCinemasNames"
              ></chart-line>
            </b-col>
            <b-col>
              <div class="mb-1 pl-1 font-weight-bold">
                <b-checkbox
                  :checked="allCinemasChecked"
                  :disabled="loading"
                  @change="toggleAllCinemas()"
                >Все кинотеатры</b-checkbox>
              </div>
              <div class="cinemas pl-1">
                <div v-for="cinema in cinemas" :key="cinema.id">
                  <b-checkbox
                    :checked="checkedCinemaIds.includes(cinema.id)"
                    :disabled="loading"
                    @change="toggleCheckedCinemaId(cinema.id)"
                  >
                    <div class="d-flex align-items-center">
                      <div
                        class="mark mr-1"
                        :style="{'background-color': cinemaColors[cinema.id]}"
                      ></div>
                      <div>{{ cinema.name }} ({{cinema.city.name}})</div>
                    </div>
                  </b-checkbox>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-table
            class="datatable border"
            :bordered="true"
            :fields="fields"
            :items="getTableItems(cinemasData)"
            :striped="true"
            :hover="false"
            :show-empty="true"
            :empty-text="loading ? 'Загрузка...' : 'Нет данных'"
            :busy="loading"
          >
            <template #cell(name)="data">
              <div>{{ data.value }}</div>
            </template>

            <template #cell()="data">
              <div
                class="h-100 d-flex align-items-center justify-content-center text-primary cursor-pointer"
                :style="{'background-color': levels[getLevel(data.item.values, data.value)] }"
                @click="exportData([data.item.cinemaId], data.field.period, data.item.name)"
              >{{ data.value.toLocaleString() }}</div>
            </template>

            <template #cell(total)="data">
              <div class="h-100 d-flex align-items-center justify-content-center">{{ data.value }}</div>
            </template>
          </b-table>
        </div>
      </div>
    </template>
  </analytics-customers-provider>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import AnalyticsCustomersProvider from '../../../providers/AnalyticsCustomersProvider';
import PeriodsSelector from '../components/PeriodsSelector.vue';
import ChartLine from '../../../components/charts/ChartLine.vue';

import colors from '../../../utils/colors';
import { PERIOD_MONTH } from '../../../utils/periods';

export default {
  components: {
    PeriodsSelector,
    AnalyticsCustomersProvider,
    ChartLine,
  },
  data() {
    return {
      date: null,
      periodsCount: 6,
      periods: [],
      formattedPeriods: [],
      checkedCinemaIds: [],
      PERIOD_MONTH,
      levels: ['#ea9999', '#f4cccc', '#ffffff', '#d9ead3', '#b6d7a8'],
    };
  },
  computed: {
    ...mapState('dictionaries/cinemas', {
      cinemas: (state) => state.items,
    }),

    cinemaColors() {
      const convertedColors = colors.map((color) => `#${color.toString(16).padStart(6, '0')}`);

      const cinemaColors = {};

      for (let i = 0; i < this.cinemas.length; i += 1) {
        const cinema = this.cinemas[i];

        cinemaColors[cinema.id] = convertedColors[i];
      }

      return cinemaColors;
    },

    checkedCinemasColors() {
      return this.checkedCinemaIds.map((cinemaId) => this.cinemaColors[cinemaId]);
    },

    checkedCinemasNames() {
      return this.checkedCinemaIds.map((cinemaId) => this.cinemas.find((_cinema) => _cinema.id === cinemaId)?.name || '');
    },

    fields() {
      return [
        {
          key: 'name',
          label: '',
          filterable: false,
          sortable: false,
        },
        ...this.formattedPeriods.map((period, index) => ({
          key: period,
          label: period,
          filterable: false,
          sortable: true,
          class: 'p-0 h-100',
          period: this.periods[index],
        })),
        {
          key: 'total',
          label: 'Итого',
          filterable: false,
          sortable: true,
        },
      ];
    },

    allCinemasChecked() {
      return this.cinemas.length === this.checkedCinemaIds.length;
    },
  },
  created() {
    this.fetchDictionaries();
  },
  methods: {
    ...mapActions('dictionaries/cinemas', {
      fetchCinemas: 'fetchItems',
    }),

    fetchDictionaries() {
      this.fetchCinemas();
    },

    toggleCheckedCinemaId(cinemaId) {
      const index = this.checkedCinemaIds.indexOf(cinemaId);

      if (index > -1) {
        this.checkedCinemaIds.splice(index, 1);
      } else {
        this.checkedCinemaIds.push(cinemaId);
      }
    },

    getChartItems(cinemasData) {
      return this.checkedCinemaIds
        .map((cinemaId) => this.periods.map((period) => this.getValue(cinemasData, cinemaId, period)));
    },

    getTableItems(cinemasData) {
      return this.checkedCinemaIds
        .map((cinemaId) => {
          const cinema = this.cinemas.find((_cinema) => _cinema.id === cinemaId);
          const name = cinema?.name || '';
          const city = cinema?.city?.name || '';

          const row = {
            cinemaId,
            name: `${name} ${city}`,
          };

          let total = 0;
          const values = [];

          for (let i = 0; i < this.periods.length; i += 1) {
            const value = this.getValue(cinemasData, cinemaId, this.periods[i]);

            row[this.formattedPeriods[i]] = value;
            total += value;
            values.push(value);
          }

          row.total = total;
          row.values = values;

          return row;
        });
    },

    getValue(cinemasData, cinemaId, period) {
      return +cinemasData[cinemaId].find((_data) => _data.dateStart === period.dateStart && _data.dateEnd === period.dateEnd)?.count || 0;
    },

    getThresholds(values) {
      const levelsCount = this.levels.length;

      const thresholds = [];
      const min = Math.min(...values);
      const step = (Math.max(...values) - min) / levelsCount;

      for (let i = 0; i < levelsCount; i += 1) {
        thresholds.push(min + step * (i + 1));
      }

      return thresholds;
    },

    getLevel(values, value) {
      return this.getThresholds(values).findIndex((threshold) => value <= threshold);
    },

    toggleAllCinemas() {
      this.checkedCinemaIds = !this.allCinemasChecked ? this.cinemas.map((cinema) => cinema.id) : [];
    },
  },
};
</script>

<style scoped>
.datatable {
  height: 1px;
}
.datatable >>> th, .datatable >>> td {
  height: 100%;
  border: 1px solid #dee2e6 !important;
}

.datatable >>> th {
  text-align: center !important;
}
.mark {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.cinemas {
  overflow: scroll;
  max-height: 300px;
}
</style>
