<template>
  <div>
    <toolbar
      v-if="showTopToolbar"
      class="mb-3"
      :disabled="loading"
      :actions="preparedPanelActions"
      :pagination="{ page, limit, total }"
      @change-page="changePage($event)"
      @change-limit="changeLimit($event)"
      @click="performPanelAction(...arguments)"
    >
      <slot name="toolbar-content"></slot>
    </toolbar>

    <b-table
      class="datatable"
      :fields="columns"
      :items="items"
      :striped="true"
      :hover="true"
      :no-local-sorting="true"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :busy="loading"
      :show-empty="true"
      :empty-text="loading ? 'Загрузка...' : 'Нет данных'"
      @sort-changed="changeSort($event.sortBy, !!$event.sortDesc)"
    >
      <template
        v-if="isSomeFieldFilterable"
        #top-row="data"
      >
        <template v-for="column in columns">
          <td
            class="column-filter"
            :key="column.key"
          >
            <slot :name="`filter(${column.key})`">
              <column-filter
                v-if="isFieldFilterable(column.key)"
                :type="getFieldType(column.key)"
                :value="filter[column.key]"
                :options="filtersOptions[column.key] || []"
                :disabled="loading"
                @update="changeFilter(column.key, $event)"
              ></column-filter>
            </slot>
          </td>
        </template>
      </template>

      <template #head(itemChecked)="data">
        <b-form-checkbox
          :checked="allChecked"
          @click.native.stop
          @change="toggleAllChecked()"
        ></b-form-checkbox>
      </template>

      <template #cell(itemChecked)="data">
        <b-form-checkbox
          :checked="isChecked(data.item.id)"
          @click.native.stop
          @change="toggleChecked(data.item.id)"
        ></b-form-checkbox>
      </template>

      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>

      <template #cell(itemActions)="data">
        <actions
          class="row-actions"
          :actions="getItemActions(data.item)"
          @click="performItemAction(data.item.id, ...arguments)"
        ></actions>
      </template>
    </b-table>

    <toolbar
      v-if="localShowBottomToolbar"
      class="mt-4"
      :disabled="loading"
      :actions="preparedPanelActions"
      :pagination="{ page, limit, total }"
      @change-page="changePage($event)"
      @change-limit="changeLimit($event)"
      @click="performPanelAction(...arguments)"
    >
      <slot name="toolbar-content"></slot>
    </toolbar>
  </div>
</template>

<script>
import Toolbar from './Toolbar.vue';
import ColumnFilter from './ColumnFilter.vue';
import Actions from './Actions.vue';

export default {
  components: {
    Toolbar,
    ColumnFilter,
    Actions,
  },
  props: {
    loading: {
      default: false,
    },
    page: {
      default: 1,
    },
    limit: {
      default: 20,
    },
    total: {
      default: 0,
    },
    filter: {
      default: () => ({}),
    },
    items: {
      default: () => [],
    },
    fields: {
      default: () => [],
    },
    sortBy: {
      default: null,
    },
    sortDesc: {
      default: false,
    },
    checked: {
      default: () => [],
    },
    panelActions: {
      type: Function,
      default: () => [],
    },
    itemActions: {
      type: Function,
      default: () => [],
    },
    filtersOptions: {
      default: () => ({}),
    },
    changePage: {
      default: () => {},
    },
    changeLimit: {
      default: () => {},
    },
    changeSort: {
      default: () => {},
    },
    changeFilter: {
      default: () => {},
    },
    showTopToolbar: {
      default: true,
    },
    showBottomToolbar: {
      default: true,
    },
    smartBottomToolbar: {
      default: true,
    },
    permissions: {
      default: null,
    },
  },
  computed: {
    allChecked() {
      return this.items.length && this.items.every((item) => this.checked.includes(item.id));
    },

    preparedPanelActions() {
      const panelActions = this.panelActions({
        checked: this.checked,
      });

      return this.filterActionsByPermissions(panelActions);
    },

    hasBulkActions() {
      return this.preparedPanelActions && !!this.preparedPanelActions.filter((action) => action.bulk).length;
    },

    hasItemActions() {
      return this.items.some((item) => this.getItemActions(item).length);
    },

    isSomeFieldFilterable() {
      return this.fields.some((field) => this.isFieldFilterable(field.key));
    },

    columns() {
      let columns = [];

      if (this.hasBulkActions) {
        columns = [
          ...columns,
          {
            key: 'itemChecked',
            label: '',
            sortable: false,
            filterable: false,
            type: 'checkbox',
            tdClass: 'column-checkbox',
          },
        ];
      }

      columns = [
        ...columns,
        ...this.fields.map((field) => ({ ...field, tdClass: `column-${field.key}` })),
      ];

      if (this.hasItemActions) {
        columns = [
          ...columns,
          {
            key: 'itemActions',
            label: 'Действия',
            filterable: false,
            type: 'buttons',
            tdClass: 'column-actions',
          },
        ];
      }

      return columns;
    },

    localShowBottomToolbar() {
      if (!this.smartBottomToolbar) {
        return this.showBottomToolbar;
      }

      if (!this.showTopToolbar) {
        return true;
      }

      if (this.items && this.items.length > 10) {
        return true;
      }

      return false;
    },
  },
  methods: {
    isFieldFilterable(key) {
      return this.fields.find((field) => field.key === key)?.filterable;
    },

    getFieldType(key) {
      return this.fields.find((field) => field.key === key)?.type || 'text';
    },

    getItemActions(item) {
      const itemActions = this.itemActions({ item });
      return this.filterActionsByPermissions(itemActions);
    },

    performPanelAction(action, args) {
      this.$emit('panel-action', {
        action,
        args,
        ids: this.checked.filter((id) => this.items.find((item) => item.id === id)),
      });
    },

    performItemAction(id, action, args) {
      this.$emit('item-action', {
        action,
        args,
        id,
      });
    },

    isChecked(id) {
      return this.checked.includes(id);
    },

    toggleChecked(id) {
      if (this.isChecked(id)) {
        this.updateChecked(this.checked.filter((_id) => _id !== id));
      } else {
        this.updateChecked([...this.checked, id]);
      }
    },

    toggleAllChecked() {
      if (!this.allChecked) {
        this.updateChecked(this.items.map((item) => item.id));
      } else {
        this.updateChecked([]);
      }
    },

    updateChecked(checked) {
      this.$emit('change-checked', checked);
    },

    filterActionsByPermissions(actions) {
      if (!this.permissions || !Array.isArray(this.permissions)) {
        return actions;
      }

      return actions.filter((item) => {
        if (item.items && Array.isArray(item.items)) {
          item.items = item.items.filter((child) => {
            if (child.permission) {
              return this.permissions.includes(child.permission);
            }

            return true;
          });

          if (item.items.length === 0 || item.items.filter((item) => item.type !== 'divider' && item.type !== 'header').length === 0) {
            return false;
          }

          if (item.items[item.items.length - 1].type === 'divider' || item.items[item.items.length - 1].type === 'header') {
            item.items.pop();
          }
        }

        if (item.permission) {
          return this.permissions.includes(item.permission);
        }

        return true;
      });
    },
  },
};
</script>

<style scoped>
.datatable >>> .column-checkbox {
  width: 1px;
  text-align: center;
}
.datatable >>> .column-actions {
  width: 1px;
  text-align: center;
}
.datatable >>> .column-id {
  width: 1px;
  text-align: center;
}
</style>
