<template>
  <div
    class="d-flex align-items-center"
    :class="{'cursor-pointer': clickable}"
    @[clickEvent]="$emit('click-file', item)"
  >
    <div class="table-library__image-wrap mr-2">
      <img
        v-if="item.mediaInfo && item.mediaInfo.thumb"
        class="rounded"
        :src="item.mediaInfo.thumb"
        :alt="item.name"
      />
    </div>
    <div class="table-library__name text-truncate text-nowrap">
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    clickable: {
      default: true,
    },
  },
  computed: {
    clickEvent() {
      return this.clickable ? 'click' : null;
    },
  },
};
</script>
