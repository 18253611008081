<template>
  <label
    :class="[
      'vts-file',
      {
        'vts-file--droppable': droppable,
        'vts-file--selected': !!localFiles.length,
      },
      classes.label,
    ]"
  >
    <input
      ref="input"
      type="file"
      :accept="accept"
      :class="['vts-file__input', classes.input]"
      :multiple="multiple"
      @change="onChange"
    />

    <span :class="['vts-file__text', classes.text]">
      <slot name="label">{{ label }}</slot>
    </span>

    <div @dragenter.prevent="droppable = true" class="vts-file__dropzone cursor-pointer">
      <slot v-bind="{ files: localFiles, droppable }">
        <span v-if="localFiles.length" aria-hidden="true">
          {{
            localFiles.length > 1
              ? `${localFiles.length} files selected`
              : localFiles[0].name
          }}
        </span>

        <span v-else aria-hidden="true">
          Выберите или перетащите файл
        </span>
      </slot>

      <span
        v-if="droppable"
        class="vts-file__overlay"
        @drop.prevent="onDrop"
        @dragenter.stop="droppable = true"
        @dragleave.stop="droppable = false"
        @dragover.prevent
      >
        <slot name="overlay" />
      </span>
    </div>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'files',
    event: 'update',
  },
  props: {
    label: {
      type: String,
    },
    files: {
      type: Array,
      default: () => [],
    },
    classes: {
      type: Object,
      default: () => ({}),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: 'image/*',
    },
  },
  data: () => ({
    localFiles: [],
    droppable: false,
  }),
  watch: {
    files(files) {
      this.localFiles = files;
    },
    localFiles() {
      this.droppable = false;
    },
  },
  methods: {
    reset() {
      this.localFiles = [];
      this.$refs.input.value = '';
    },
    onChange(event) {
      const files = Array.from(event.target.files);
      this.localFiles = files;
      this.$emit('change', files);
    },
    onDrop(event) {
      const files = Array.from(event.dataTransfer.files);
      if (!this.multiple && files.length > 1) {
        files.length = 1;
      }
      this.localFiles = files;
      this.$emit('change', files);
    },
  },
};
</script>

<style scoped>
.vts-file {
  display: block;
}
.vts-file__input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
}
.vts-file__dropzone {
  position: relative;
}
.vts-file__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
input:focus ~ .vts-file__dropzone {
  outline-width: 1px;
  outline-style: auto;
  outline-color: Highlight;
  outline-color: -webkit-focus-ring-color;
}
.vts-file__dropzone {
  display: flex;
  justify-content: center;
  border: 3px dashed skyblue;
  border-radius: 5px;
  padding: 30px;
  background: lightblue;
  transition: background-color 0.2s ease;
}

.vts-file--droppable .vts-file__dropzone {
  background: skyblue;
}

.vts-file--selected .vts-file__dropzone {
  background: greenyellow;
}
</style>
