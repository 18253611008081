import datetime from '../../utils/datetime';

const MAX_COUNT = 99;

export default class RepertoryItemDates {
  constructor(item) {
    this.dateStart = item?.dateStart || new Date();
    this.dateEnd = item?.dateEnd || new Date();
    this.weeks = item?.weeks || [];
  }

  init({ dateStart, dateEnd, weeks }) {
    this.dateStart = dateStart;
    this.dateEnd = dateEnd;
    this.weeks = weeks || [];

    this.fillWeeks();
  }

  getCount() {
    return this.weeks.reduce((acc, cur) => acc + cur.count, 0);
  }

  changeDateStart(date) {
    this.dateStart = date;
    this.fillWeeks();
  }

  changeDateEnd(date) {
    this.dateEnd = date;
    this.fillWeeks();
  }

  fillWeeks() {
    let start = this.dateStart;
    let end = this.dateStart;

    const weeks = [];

    while (datetime.isDateBefore(end, this.dateEnd)) {
      if (datetime.getDay(end) === 3) {
        weeks.push({
          dateStart: start,
          dateEnd: end,
          count: this.findWeek(start, end)?.count || 0,
        });

        start = datetime.addDays(end, 1);
      }

      end = datetime.addDays(end, 1);
    }

    if (datetime.isDateSameOrBefore(start, end)) {
      weeks.push({
        dateStart: start,
        dateEnd: end,
        count: this.findWeek(start, end)?.count || 0,
      });
    }

    this.weeks = weeks;
  }

  findWeek(start, end) {
    return this.weeks.find((_week) => datetime.isSameDay(_week.dateStart, start)
      || datetime.isSameDay(_week.dateEnd, end));
  }

  getWeekByIndex(index) {
    return this.weeks[index];
  }

  decreaseCount(index) {
    const week = this.getWeekByIndex(index);

    if (!week) {
      return;
    }

    this.changeCount(index, week.count - 1);
  }

  increaseCount(index) {
    const week = this.getWeekByIndex(index);

    if (!week) {
      return;
    }

    this.changeCount(index, week.count + 1);
  }

  changeCount(index, value) {
    const intValue = +value;

    if (intValue < 0) {
      return;
    }

    const weeks = this.weeks.map((w) => ({ ...w }));

    const week = weeks[index];

    week.count = Math.min(intValue, MAX_COUNT);

    this.weeks = weeks;
  }

  async removeWeek(index) {
    if (index > 0 && index < this.weeks.length - 1) {
      return;
    }

    const weeks = this.weeks.map((w) => ({ ...w }));

    weeks.splice(index, 1);

    this.weeks = weeks;

    this.dateStart = weeks[0].dateStart;
    this.dateEnd = weeks[weeks.length - 1].dateEnd;
  }

  prependWeek() {
    const firstWeek = this.weeks[0];

    const date = datetime.subtractDays(firstWeek.dateStart, 7);

    this.changeDateStart(date);
  }

  appendWeek() {
    const lastWeek = this.weeks[this.weeks.length - 1];

    const date = datetime.addDays(lastWeek.dateEnd, 7);

    this.changeDateEnd(date);
  }
}
