export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: true,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Наименование',
    filterable: false,
    sortable: false,
  },
  {
    key: 'merchantProviderId',
    type: 'select',
    label: 'Провайдер',
    filterable: true,
    sortable: false,
  },
  {
    key: 'username',
    type: 'text',
    label: 'Пользователь',
    filterable: false,
    sortable: false,
  },
  {
    key: 'password',
    type: 'text',
    label: 'Пароль',
    filterable: false,
    sortable: false,
  },
  {
    key: 'merchantLogin',
    type: 'text',
    label: 'Дочерний клиент',
    filterable: false,
    sortable: false,
  },
  {
    key: 'legalEntityId',
    type: 'text',
    label: 'Владелец терминала',
    filterable: false,
    sortable: false,
  },
];
