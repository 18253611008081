<template>
  <b-table
    :items="item.weeks"
    :fields="['period', 'count', 'actions']"
    thead-class="d-none"
    :busy="loading"
    :show-empty="true"
    :empty-text="loading ? 'Загрузка...' : 'Нет данных'"
  >
    <template #top-row v-if="item.id">
      <b-td colspan="3">
        <span
          class="cursor-pointer text-primary"
          :disabled="loading"
          @click="prependWeek()"
        >+ Добавить неделю</span>
      </b-td>
    </template>

    <template #cell(period)="data">
      <span>{{ $datetime.formatDate(data.item.dateStart, 'D MMM') }}</span>
      <span> - </span>
      <span>{{ $datetime.formatDate(data.item.dateEnd, 'D MMM') }}</span>
    </template>

    <template #cell(count)="data">
      <div class="d-flex align-items-center">
        <span
          :disabled="loading"
          class="cursor-pointer text-primary"
          @click="decreaseCount(data.index)"
        >
          <icon icon="fa-minus-square"></icon>
        </span>
        <b-input
          type="number"
          size="sm"
          class="w-25"
          :value="data.value"
          :disabled="loading"
          @focus="$event.target.select()"
          @input="changeCount(data.index, $event)"
        ></b-input>
        <span
          class="cursor-pointer text-primary"
          @click="increaseCount(data.index)"
        >
          <icon icon="fa-plus-square"></icon>
        </span>
      </div>
    </template>

    <template #cell(actions)="data">
      <span
        v-if="item.weeks.length > 1 && (data.index === 0 || data.index === item.weeks.length - 1)"
        class="cursor-pointer text-danger"
        :disabled="loading"
        @click="removeWeek(data.index)"
      >
        <icon icon="mdi-minus-circle-outline"></icon>
      </span>
    </template>

    <template #bottom-row v-if="item.id">
      <b-td colspan="3">
        <span
          class="cursor-pointer text-primary"
          :disabled="loading"
          @click="appendWeek()"
        >+ Добавить неделю</span>
      </b-td>
    </template>
  </b-table>
</template>

<script>
import debounce from 'lodash.debounce';
import RepertoryItemDates from './RepertoryItemDates';

import { DEBOUNCE_DELAY } from '../../constants';

export default {
  props: {
    item: {
      default: () => ({}),
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      dates: new RepertoryItemDates(),
    };
  },
  watch: {
    item: {
      handler() {
        this.setRepertoryItemDates(this.item);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.changeCount = debounce(this.changeCount, DEBOUNCE_DELAY, {
      trailing: true,
      leading: false,
    });
  },
  methods: {
    setRepertoryItemDates(item) {
      this.dates.init(item);
    },

    changePeriod({ dateStart, dateEnd }) {
      this.dates.changeDateStart(dateStart);
      this.dates.changeDateEnd(dateEnd);
      this.emitChanges();
    },

    decreaseCount(index) {
      this.dates.decreaseCount(index);
      this.emitChanges();
    },

    changeCount(index, value) {
      this.dates.changeCount(index, value);
      this.emitChanges();
    },

    increaseCount(index) {
      this.dates.increaseCount(index);
      this.emitChanges();
    },

    removeWeek(index) {
      this.dates.removeWeek(index);
      this.emitChanges();
    },

    prependWeek() {
      this.dates.prependWeek();
      this.emitChanges();
    },

    appendWeek() {
      this.dates.appendWeek();
      this.emitChanges();
    },

    emitChanges() {
      this.$emit('change', {
        dateStart: this.$datetime.convertDateToDbFormat(this.dates.dateStart),
        dateEnd: this.$datetime.convertDateToDbFormat(this.dates.dateEnd),
        weeks: this.dates.weeks,
        count: this.dates.getCount(),
      });
    },
  },
};
</script>

<style scoped>
table {
  margin: 0;
}
table >>> td {
  vertical-align: middle;
}
</style>
