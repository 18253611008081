<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Наименование:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        type="text"
        size="sm"
        autofocus
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Наименование ЕАИС:"
      :invalid-feedback="errors.eaisName"
      :state="states.eaisName"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.eaisName"
        :state="states.eaisName"
        :disabled="loading"
        @change="updateValue('eaisName', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Наименование РЕНТРАК:"
      :invalid-feedback="errors.rentrakName"
      :state="states.rentrakName"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.rentrakName"
        :state="states.rentrakName"
        :disabled="loading"
        @change="updateValue('rentrakName', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Примечание:"
      :invalid-feedback="errors.note"
      :state="states.note"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.note"
        :state="states.note"
        :disabled="loading"
        @change="updateValue('note', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Время между сеансами (мин):"
      :invalid-feedback="errors.interval"
      :state="states.interval"
    >
      <b-form-input
        type="number"
        size="sm"
        min="0"
        :value="item.interval"
        :state="states.interval"
        :disabled="loading"
        @change="updateValue('interval', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Категория зала:"
      :invalid-feedback="errors.hallCategoryId"
      :state="states.hallCategoryId"
    >
      <dictionary-provider name="hallCategories">
        <template #default="{ items }">
          <b-form-select
            size="sm"
            :value="item.hallCategoryId"
            :options="$array.convertToOptions(items)"
            :state="states.hallCategoryId"
            :disabled="loading"
            @change="updateValue('hallCategoryId', $event)"
          ></b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Поддерживаемые форматы:"
      :invalid-feedback="errors.formats"
      :state="states.formats"
    >
      <dictionary-provider name="formats2">
        <template #default="{ items }">
          <div>
            <div
              v-for="format in items"
              :key="format.id"
            >
              <b-form-checkbox
                :value="true"
                :checked="isFormatChecked(format.id, item.formats)"
                :disabled="loading"
                @change="toggleFormat(format.id)"
              >
                <span :class="{'text-danger': format3D(format.name)}">{{format.name}}</span>
              </b-form-checkbox>
            </div>
          </div>
        </template>
      </dictionary-provider>
    </b-form-group>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    DictionaryProvider,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    format3D(value) {
      return value.indexOf('3D') !== -1;
    },

    isFormatChecked(id, formats) {
      if (!formats) {
        return false;
      }

      return !!formats.find((format) => format.id === id);
    },

    toggleFormat(id) {
      const copy = this.item.formats ? [...this.item.formats] : [];

      const index = copy.findIndex((item) => item.id === id);

      if (index > -1) {
        copy.splice(index, 1);
      } else {
        copy.push({ id });
      }

      this.updateValue('formats', copy);
    },
  },
};
</script>
