import swal from 'sweetalert';

export async function confirmDialog(text, okButton = 'Да', cancelButton = 'Нет') {
  return !!await swal({
    text,
    buttons: [cancelButton, okButton],
    dangerMode: true,
    closeOnEsc: false,
  });
}
