import Vue from 'vue';
import Vuex from 'vuex';

import dictionaries from './dictionaries';
import data from './data';
import page from './page';
import reports from './reports';
import media from './media';

Vue.use(Vuex);

const CINEMA_ID_KEY = 'cinemaId';
const savedCinemaId = +localStorage.getItem(CINEMA_ID_KEY);

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  modules: {
    dictionaries,
    data,
    page,
    reports,
    media,
  },

  state: {
    cinemaId: savedCinemaId,
  },

  mutations: {
    setCinemaId(state, cinemaId) {
      state.cinemaId = cinemaId;
      localStorage.setItem(CINEMA_ID_KEY, cinemaId);
    },
  },
});
