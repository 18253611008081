<template>
  <b-dropdown
    variant="outline-info"
    size="sm"
    :disabled="disabled"
    v-b-tooltip="getStatusName(value)"
  >
    <template #button-content>
      <span
        class="color mr-1"
        :style="`background-color: ${getStatusColor(value)}`"
      ></span>
    </template>
    <b-dropdown-item
      v-for="status in statuses"
      :key="status.id"
      @click="$emit('change', status.id)"
    >
      <span class="color mr-1" :style="`background-color: ${status.color}`"></span>
      {{status.name}}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    value: {
      default: null,
    },
    disabled: {
      default: false,
    },
    statuses: {
      default: () => [],
    },
  },
  methods: {
    getStatusName(value) {
      const item = this.statuses.find((_item) => _item.id === value);

      return item ? item.name : 'Не запланировано';
    },

    getStatusColor(value) {
      const item = this.statuses.find((_item) => _item.id === value);

      return item ? item.color : '#eee';
    },
  },
};
</script>

<style scoped>
.color {
  display: inline-block;
  width: 10px;
  height: 10px;
}
</style>
