export default ({ checked }) => [
  {
    type: 'dropdown',
    text: 'Действия с выделенными',
    variant: 'outline-info',
    class: 'mr-2',
    bulk: true,
    disabled: checked && checked.length === 0,
    items: [
      // {
      //   type: 'button',
      //   text: 'Удалить',
      //   icon: 'fa-times',
      //   action: 'removeCheckedCertificateGroups',
      //   permission: 'certificates.edit',
      // },
    ],
  },
  {
    type: 'button',
    text: 'Добавить группу сертификатов',
    variant: 'info',
    icon: 'fa-plus',
    class: 'mr-1',
    action: 'openCreateCertificateGroupForm',
    permission: 'certificates.edit',
  },
];
