export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Наименование',
    filterable: true,
    sortable: true,
  },
  {
    key: 'documents',
    type: 'text',
    label: 'Необходимые документы',
    sortable: false,
    filterable: true,
  },
];
