import _factory from './_factory';

export default {
  ..._factory('mediaPanels/playlists', {
    state: {
      page: null,
      limit: null,
    },
  }),
};
