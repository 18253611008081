export default ({ checked }) => [
  {
    type: 'dropdown',
    text: 'Действия с выделенными',
    variant: 'outline-info',
    class: 'mr-2',
    bulk: true,
    disabled: checked && checked.length === 0,
    items: [
      {
        type: 'button',
        text: 'Приостановить',
        action: 'pauseCheckedPromotions',
      },
      {
        type: 'button',
        text: 'Возобновить',
        action: 'resumeCheckedPromotions',
      },
      {
        type: 'button',
        text: 'Удалить',
        icon: 'times',
        action: 'removeCheckedAdvPromotions',
      },
    ],
  },
  {
    type: 'button',
    text: 'Добавить рекламную акцию',
    variant: 'info',
    icon: 'plus',
    class: 'mr-1',
    action: 'openCreateAdvPromotionForm',
  },
];
