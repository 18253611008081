var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dictionary-map',{attrs:{"keys":_vm.appGroups,"items":_vm.createMapItems(_vm.appGroupFormulas),"disabled":_vm.disabled,"texts":{
    add: 'Добавить группу приложений',
    empty: 'Группы приложений не указаны',
    key: 'Группа приложений',
    value: 'Изменение цены',
  }},on:{"change":_vm.onChangeMap},scopedSlots:_vm._u([{key:"value",fn:function(ref){
  var disabled = ref.disabled;
  var item = ref.item;
  var setValue = ref.setValue;
return [_c('price-formula',{attrs:{"value":item.value,"operations":_vm.operations,"disabled":disabled},on:{"change":function($event){return setValue($event)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }