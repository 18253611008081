import FileSaver from 'file-saver';
import _factory from './_factory';

import promocodeGroupsService from '../../services/PromocodeGroups';

export default {
  ..._factory('promocodeGroups', {
    actions: {
      async export({ state }, {
        id,
        filename,
      }) {
        const [err, data] = await promocodeGroupsService.exportPromocodeGroup(id);

        if (err) {
          return [err, null];
        }

        if (!err && data) {
          const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
          FileSaver.saveAs(blob, filename);
        }

        return [null, true];
      },
    },
  }),
};
