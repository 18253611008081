<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Адрес сервера:"
      :invalid-feedback="errors.host"
      :state="states.host"
    >
      <b-form-input
        type="text"
        autofocus
        size="sm"
        :value="item.host"
        :state="states.host"
        :disabled="loading"
        @change="updateValue('host', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Порт:"
      :invalid-feedback="errors.port"
      :state="states.port"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.port"
        :state="states.port"
        :disabled="loading"
        @change="updateValue('port', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Пользователь:"
      :invalid-feedback="errors.username"
      :state="states.username"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.username"
        :state="states.username"
        :disabled="loading"
        @change="updateValue('username', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Пароль:"
      :invalid-feedback="errors.password"
      :state="states.password"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.password"
        :state="states.password"
        :disabled="loading"
        @change="updateValue('password', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Тип шифрования"
      :invalid-feedback="errors.encryption"
      :state="states.encryption"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.encryption"
        :state="states.encryption"
        :disabled="loading"
        @change="updateValue('encryption', $event)"
      ></b-form-input>
    </b-form-group>
  </b-container>
</template>

<script>
import CollectionProvider from '../../providers/CollectionProvider';
import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    DictionaryProvider,
    CollectionProvider,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>

<style scoped>
</style>
