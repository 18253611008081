var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cinema-page',{attrs:{"route-name":"mediaPanelsLogs","route-param-name":"cinemaId"},on:{"loaded":function($event){return _vm.setCinemaId($event)}},scopedSlots:_vm._u([{key:"buttons",fn:function(ref){
var loading = ref.loading;
return [_c('buttons',{attrs:{"tags":_vm.tags,"screens":_vm.screens,"levels":_vm.levels,"filter":_vm.filter,"disabled":loading || _vm.logsLoading},on:{"reset-filter":_vm.reset,"refresh":_vm.refresh,"change":function (ref) {
	var field = ref.field;
	var value = ref.value;

	return _vm.changeFilter({ field: field, value: value });
}}})]}},{key:"scroll",fn:function(){return [_c('scroll',[_c('div',{staticClass:"px-3 mt-4"},[_c('toolbar',{staticClass:"mb-5",attrs:{"disabled":_vm.logsLoading,"pagination":{ page: _vm.page, limit: _vm.limit, total: _vm.total }},on:{"change-page":function($event){return _vm.changePage($event)},"change-limit":function($event){return _vm.changeLimit($event)}}}),_c('div',{staticClass:"overflow-hidden mw-100"},[_c('b-table',{attrs:{"table-class":['table_logs', 'mw-100'],"fields":_vm.datatable.fields,"items":_vm.items,"busy":_vm.logsLoading,"show-empty":true,"empty-text":_vm.logsLoading ? 'Загрузка...' : 'Нет данных'},scopedSlots:_vm._u([{key:"cell(screenId)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(_vm.getScreenName(data.item.screenId))+" ")])]}}])})],1),_c('toolbar',{staticClass:"mt-5 mb-3",attrs:{"disabled":_vm.logsLoading,"pagination":{ page: _vm.page, limit: _vm.limit, total: _vm.total }},on:{"change-page":function($event){return _vm.changePage($event)},"change-limit":function($event){return _vm.changeLimit($event)}}})],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }