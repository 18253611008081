<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Наименование:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        type="text"
        autofocus
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Провайдер"
      :invalid-feedback="errors.merchantProviderId"
      :state="states.merchantProviderId"
    >
      <dictionary-provider name="merchantProviders">
        <template #default="{ items: merchantProviders, loading: merchantProvidersLoading }">
          <b-form-select
            size="sm"
            :value="item.merchantProviderId"
            :options="$array.convertToOptions(merchantProviders, null)"
            :disabled="loading || merchantProvidersLoading"
            @change="updateValue('merchantProviderId', $event)"
          ></b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Пользователь:"
      :invalid-feedback="errors.username"
      :state="states.username"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.username"
        :state="states.username"
        :disabled="loading"
        @change="updateValue('username', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Пароль:"
      :invalid-feedback="errors.password"
      :state="states.password"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.password"
        :state="states.password"
        :disabled="loading"
        @change="updateValue('password', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Дочерний клиент:"
      :invalid-feedback="errors.merchantLogin"
      :state="states.merchantLogin"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.merchantLogin"
        :state="states.merchantLogin"
        :disabled="loading"
        @change="updateValue('merchantLogin', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Ключ для SberPay SDK:"
      :invalid-feedback="errors.sberpayApiKey"
      :state="states.sberpayApiKey"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.sberpayApiKey"
        :state="states.sberpayApiKey"
        :disabled="loading"
        @change="updateValue('sberpayApiKey', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Владелец терминала"
      :invalid-feedback="errors.legalEntityId"
      :state="states.legalEntityId"
    >
      <dictionary-provider name="legalEntities">
        <template #default="{ items: legalEntities, loading: legalEntitiesLoading }">
          <b-form-select
            size="sm"
            :value="item.legalEntityId"
            :options="$array.convertToOptions(legalEntities, null)"
            :disabled="loading || legalEntitiesLoading"
            @change="updateValue('legalEntityId', $event)"
          ></b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    DictionaryProvider,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>

<style scoped>
</style>
