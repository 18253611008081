<template>
  <div>
    <div>
      <template v-if="advertisement.days && advertisement.days.length === 7">
        Все дни недели
      </template>
      <template v-else>
        {{$array.convertToText(advertisement.days, 'Все дни недели', 'short')}}
      </template>
    </div>
    <div v-if="!advertisement.timeStart && !advertisement.timeEnd">Всё время</div>
    <div v-if="advertisement.timeStart || advertisement.timeEnd">
      <span v-if="advertisement.timeStart">с {{advertisement.timeStart}}</span>
      <span v-if="advertisement.timeEnd"> до {{advertisement.timeEnd}}</span>
    </div>
    <div>{{$array.convertToText(advertisement.formats, 'Все форматы')}}</div>
    <div>{{$array.convertToText(advertisement.ageLimits, 'Все возрасты')}}</div>
    <div
      class="advertisements-index__releases"
      :id="`releases_${advertisement.id}`"
    >{{$array.convertToText(advertisement.releases, 'Все фильмы')}}</div>
    <b-tooltip
      v-if="advertisement.releases && advertisement.releases.length"
      :target="`releases_${advertisement.id}`"
    >
      <div
        class="mb-1"
        v-for="release in advertisement.releases"
        :key="release.id"
      >
        <div>{{ release.name }}</div>
        <div>---</div>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    advertisement: {
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.advertisements-index__releases {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
