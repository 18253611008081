<template>
  <div class="d-flex justify-content-center align-items-center mb-4">
    <span class="mr-3 cursor-pointer" @click="prevYear()">
      <icon icon="fa-chevron-left"></icon>
    </span>
    <div class="text-center">
      <h1>{{year}}</h1>
    </div>
    <span class="ml-3 cursor-pointer" @click="nextYear()">
      <icon icon="fa-chevron-right"></icon>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    year: {
      default: '',
    },
  },
  methods: {
    prevYear() {
      this.$emit('select-year', +this.year - 1);
    },

    nextYear() {
      this.$emit('select-year', +this.year + 1);
    },
  },
};
</script>
