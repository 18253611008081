/* eslint-disable no-return-await */
/* eslint-disable no-restricted-syntax */
import http from './http';
import { API_VERSION } from '../config';
import { convertError } from './utilities';

export async function refundOrder(compositeId, params) {
  try {
    const response = await http.put(`/${API_VERSION}/orders/${compositeId}/refund`, params);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}
