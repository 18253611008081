/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

export function createQuery({
  page, limit, sort, filter, params,
}) {
  const query = [];

  if (page) {
    query.push(`_page=${page}`);
  }

  if (limit) {
    query.push(`_limit=${limit}`);
  }

  if (sort && sort.by) {
    query.push(`_sort=${sort.by}`);

    if (sort.desc) {
      query.push('_order=desc');
    } else {
      query.push('_order=asc');
    }
  }

  for (const field in filter) {
    const value = filter[field];

    if (
      value
      && (
        typeof value.from !== 'undefined'
        || typeof value.to !== 'undefined'
      )
    ) {
      if (value.from) {
        query.push(`_filter[${field}][gte]=${value.from}`);
      }

      if (value.to) {
        query.push(`_filter[${field}][lte]=${value.to}`);
      }
    } else if (Array.isArray(value)) {
      value.map((item) => {
        query.push(`_filter[${field}][]=${encodeURIComponent(item)}`);
      });
    } else if (
      value !== ''
      && value !== null
    ) {
      query.push(`_filter[${field}]=${encodeURIComponent(value)}`);
    }
  }

  for (const field in params) {
    query.push(`${field}=${encodeURIComponent(params[field])}`);
  }

  return query.join('&');
}

export function convertResponse(response) {
  if (response) {
    const { data, headers } = response;

    const items = data;
    const total = headers['x-total-count'] ? +headers['x-total-count'] : items.length;
    const time = headers['x-time'] ? +headers['x-time'] : 0;

    return {
      items,
      total,
      time,
    };
  }

  return {
    items: [],
    total: 0,
    time: 0,
  };
}

export function convertError(error) {
  if (error.response) {
    const { status, data } = error.response;
    return [{ status, data }, null];
  }

  return [error, null];
}
