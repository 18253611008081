export default [
  {
    key: 'id',
    label: 'ID',
    sortable: false,
    filterable: false,
  },
  {
    key: 'tag',
    label: 'Тег',
    tdClass: 'table__td-break-word table__td-tag',
    sortable: false,
    filterable: false,
  },
  {
    key: 'level',
    label: 'Тип',
    sortable: false,
    filterable: false,
  },
  {
    key: 'screenId',
    label: 'Экран',
    tdClass: 'text-center',
    sortable: false,
    filterable: false,
  },
  {
    key: 'msg',
    label: 'Сообщение',
    tdClass: 'table__td-break-word',
    sortable: false,
    filterable: false,
  },
];
