export default [
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    filterable: false,
    sortable: false,
  },
  {
    key: 'period',
    type: 'text',
    label: 'Период действия',
    sortable: false,
    filterable: false,
  },
  {
    key: 'type',
    type: 'text',
    label: 'Тип',
    filterable: false,
    sortable: false,
  },
  {
    key: 'rotation',
    type: 'text',
    label: 'Период ротации',
    filterable: false,
    sortable: false,
  },
  {
    key: 'active',
    type: 'text',
    label: 'Активен',
    sortable: false,
    filterable: false,
  },
];
