export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: true,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    filterable: false,
    sortable: true,

  },
  {
    key: 'appGroupId',
    type: 'select',
    label: 'Группа приложений',
    sortable: true,
    filterable: true,
  },
  {
    key: 'apiKey',
    type: 'text',
    label: 'Ключ для апи',
    sortable: false,
    filterable: false,
  },
  {
    key: 'private',
    type: 'text',
    label: 'Ключ для доступа в приватную зону',
    sortable: false,
    filterable: false,
  },
  {
    key: 'ticketOperator',
    type: 'text',
    label: 'Билетный оператор',
    sortable: false,
    filterable: false,
  },
  {
    key: 'serviceFeePercent',
    type: 'text',
    label: 'Сервисный сбор',
    sortable: false,
    filterable: false,
  },
  {
    key: 'enabled',
    type: 'select',
    label: 'Активно',
    filterable: true,
    sortable: true,
  },
];
