<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Наименование:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Описание:"
          :invalid-feedback="errors.description"
          :state="states.description"
        >
          <v-md-editor
            :value="item.description"
            :state="states.description"
            :disabled="loading"
            :disabled-menus="['h/h1', 'image/upload-image']"
            left-toolbar="undo redo clear | h bold italic quote | ul ol hr | link image"
            right-toolbar="preview sync-scroll fullscreen"
            height="400px"
            @change="updateValue('description', $event)"
          ></v-md-editor>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Постер:"
          :invalid-feedback="errors['file']"
          :state="states['file']"
        >
          <file-uploader
            :disabled="loading"
            @change="handleFilePoster($event, 'file')"
          ></file-uploader>
        </b-form-group>
      </b-col>
      <b-col v-if="item.imagePath">
        <image-viewer
          :image="item.imagePath"
          @remove="resetPoster('file')"
        ></image-viewer>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Правила акции:"
          :invalid-feedback="errors['document_file']"
          :state="states['document_file']"
        >
          <file-uploader
            :accept="'image/*,text/plain,application/pdf,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
            :disabled="loading"
            @change="handleFileDocument($event, 'document_file')"
          ></file-uploader>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Дата начала и окончания показа:"
          :invalid-feedback="errors.dateEnd || errors.dateStart"
          :state="states.dateEnd || states.dateStart"
        >
          <date-interval
            :value="{from: item.dateStart, to: item.dateEnd}"
            :disabled="loading"
            @selected="updateValue('dateStart', $event.from), updateValue('dateEnd', $event.to)">
          </date-interval>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Группы приложений:"
          :invalid-feedback="errors.appGroups"
          :state="states.appGroups"
        >
          <dictionary-provider name="appGroups">
            <template #default="{ items: appGroups }">
              <toggle-list
                placeholder="Все"
                :options="appGroups"
                :value="item.appGroups"
                :disabled="loading"
                @change="updateValue('appGroups', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Кинотеатры:"
          :invalid-feedback="errors.cinemas"
          :state="states.cinemas"
        >
          <dictionary-provider name="cinemas">
            <template #default="{ items, loading: dictionaryLoading }">
              <toggle-list
                placeholder="Все"
                :options="convertCinemas($array.union(items, item.cinemas))"
                :value="item.cinemas"
                :disabled="loading || dictionaryLoading"
                @change="updateValue('cinemas', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FileUploader from '../../components/fields/FileUploader.vue';
import ImageViewer from '../../components/ImageViewer.vue';
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';
import { FILE_UPLOAD_TYPE_ADV_PROMOTION_DOCUMENT, FILE_UPLOAD_TYPE_ADV_PROMOTION_IMAGE } from '../../constants';
import DateInterval from '../../components/fields/DateInterval.vue';

export default {
  components: {
    FileUploader,
    ImageViewer,
    DictionaryProvider,
    ToggleList,
    DateInterval,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    async handleFilePoster(files, field) {
      const type = FILE_UPLOAD_TYPE_ADV_PROMOTION_IMAGE;

      const image = await this.$fileManager.getBase64(files[0], { maxWidth: 1600 });
      const fileId = await this.$fileManager.registerAndUpload(files[0], { type });

      this.updateValue(field, {
        image,
        fileId,
      });

      this.fileId = fileId;
    },

    async handleFileDocument(files, field) {
      const type = FILE_UPLOAD_TYPE_ADV_PROMOTION_DOCUMENT;

      const fileId = await this.$fileManager.registerAndUpload(files[0], { type });

      this.updateValue(field, {
        fileId,
      });

      this.fileId = fileId;
    },

    resetPoster(field) {
      this.updateValue(field, {
        image: null,
        fileId: null,
      });
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },
  },
};
</script>

<style>
  .column-description,
  .column-cinemaId {
    max-width: 435px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
