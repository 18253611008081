<template>
  <dictionary-provider
    name="cinemas"
    @loaded="cinemas = $event"
  >
    <template #default="{ loading: cinemasLoading }">
      <multi-view :scroll-side="false">
        <template #side>
          <movies
            :movies="filteredMovies"
            :movie-id="movieId"
            :disabled="loading || cinemasLoading"
            :no-keys="noKeys"
            @select="movieId = $event"
            @search="search = $event"
            @change-no-keys="noKeys = $event"
          ></movies>
        </template>

        <template #panel>
          <buttons
            :disabled="loading || cinemasLoading"
            :cinemas="cinemas"
            :unknown-dcp-keys-count="unknownDcpKeys.length"
            @change-cinema="cinemaId = $event"
            @show-unknown-dcp-keys="showUnknownDcpKeys = true"
          ></buttons>
        </template>

        <template #content>
          <dcp-keys
            :cinemas="cinema ? [ cinema ] : cinemas"
            :movie="movie"
            :loading="loading || cinemasLoading"
            @export="exportDcpKey($event)"
          ></dcp-keys>

          <b-modal
            v-if="showUnknownDcpKeys"
            centered
            cancel-title="Закрыть"
            title="Нераспознанные ключи"
            size="xl"
            ok-title="Сохранить"
            ok-variant="success"
            :visible="true"
            :busy="loading || cinemasLoading"
            @ok="attachDcpKeys"
            @hidden="showUnknownDcpKeys = false, unknownDcpKeyMovies = []"
          >
            <div
              v-for="file in unknownDcpKeyFiles"
              class="d-flex align-items-center justify-content-between mb-2"
              :key="file"
            >
              <div>{{ file }}</div>
              <div>
                <b-select
                  size="sm"
                  :value="null"
                  :options="$array.convertToOptions(moviesWithoutDcpKeys, { value: null, text: '-- Выберите фильм --' })"
                  @change="setMovieForDcpKey(file, $event)"
                ></b-select>
              </div>
            </div>
          </b-modal>
        </template>
      </multi-view>
    </template>
  </dictionary-provider>
</template>

<script>
import { mapState } from 'vuex';

import DictionaryProvider from '../../providers/DictionaryProvider';
import MultiView from '../../layout/MultiView.vue';

import DcpKeys from './DcpKeys.vue';
import Movies from './Movies.vue';
import Buttons from './Buttons.vue';

export default {
  components: {
    DictionaryProvider,
    MultiView,
    DcpKeys,
    Movies,
    Buttons,
  },
  data() {
    return {
      movieId: null,
      cinemas: [],
      search: '',
      noKeys: false,
      cinemaId: null,
      now: this.$datetime.convertDateToDbFormat(new Date()),
      showUnknownDcpKeys: false,
      unknownDcpKeyMovies: [],
    };
  },
  computed: {
    ...mapState('dictionaries/showsReleases', {
      releases: (state) => state.items,
      releasesLoading: (state) => state.loading,
    }),

    ...mapState('dictionaries/dcpKeys', {
      dcpKeys: (state) => state.items,
      dcpKeysLoading: (state) => state.loading,
    }),

    ...mapState('dictionaries/shows', {
      shows: (state) => state.items,
      showsLoading: (state) => state.loading,
    }),

    loading() {
      return this.releasesLoading || this.dcpKeysLoading || this.showsLoading;
    },

    cinema() {
      return this.cinemas.find((_cinema) => _cinema.id === this.cinemaId);
    },

    filteredShows() {
      if (!this.cinema) {
        return this.shows;
      }

      return this.shows.filter((show) => this.cinema.halls.find((hall) => hall.id === show.hallId));
    },

    filteredReleases() {
      if (!this.cinema) {
        return this.releases;
      }

      return this.releases.filter((release) => this.filteredShows.find((show) => show.releaseId === release.id));
    },

    movies() {
      return this.filteredReleases
        .reduce((acc, release) => {
          let movie = acc.find((_movie) => _movie.id === release.movieId);

          if (!movie) {
            movie = {
              id: release.movieId,
              name: release.movie.name,
              releases: [],
            };

            acc.push(movie);
          }

          const shows = this.filteredShows.filter((show) => show.releaseId === release.id);

          if (shows.length) {
            movie.releases.push({
              ...release,
              shows,
            });
          }

          return acc;
        }, [])
        .filter((movie) => movie.releases.length);
    },

    moviesWithDcpKeys() {
      return this.movies
        .map((movie) => ({
          ...movie,
          dcpKeys: this.dcpKeys.filter((dcpKey) => movie.releases.find((release) => release.id === dcpKey.releaseId)),
          showsWithoutDcpKeys: this.showsWithoutDcpKeys.filter((show) => movie.releases.find((release) => show.releaseId === release.id)),
        }));
    },

    filteredMovies() {
      let movies = this.moviesWithDcpKeys;

      if (this.search) {
        movies = movies.filter((movie) => movie.name.toLowerCase().includes(this.search.toLowerCase()));
      }

      if (this.noKeys) {
        movies = movies.filter((movie) => !movie.dcpKeys.length || movie.showsWithoutDcpKeys.length);
      }

      return movies;
    },

    movie() {
      if (!this.movieId) {
        return null;
      }

      return this.moviesWithDcpKeys.find((movie) => movie.id === this.movieId);
    },

    showsWithoutDcpKeys() {
      const dcpKeysMap = {};
      const datesComparatorCache = {};

      for (const dcpKey of this.dcpKeys) {
        if (!(dcpKey.hallId in dcpKeysMap)) {
          dcpKeysMap[dcpKey.hallId] = {};
        }

        if (!(dcpKey.releaseId in dcpKeysMap[dcpKey.hallId])) {
          dcpKeysMap[dcpKey.hallId][dcpKey.releaseId] = [];
        }

        dcpKeysMap[dcpKey.hallId][dcpKey.releaseId].push(dcpKey);
      }

      return this.filteredShows
        .filter((show) => {
          if (!(show.hallId in dcpKeysMap)) {
            return true;
          }

          if (!(show.releaseId in dcpKeysMap[show.hallId])) {
            return true;
          }

          return !dcpKeysMap[show.hallId][show.releaseId].find((dcpKey) => {
            const timeKey = `${show.date}_${show.time}_${dcpKey.dateStart}_${dcpKey.dateEnd}`;

            if (datesComparatorCache[timeKey]) {
              return datesComparatorCache[timeKey];
            }

            datesComparatorCache[timeKey] = this.$datetime.isDateBetween(`${show.date} ${show.time}`, dcpKey.dateStart, dcpKey.dateEnd);

            return datesComparatorCache[timeKey];
          });
        });
    },

    unknownDcpKeys() {
      return this.dcpKeys.filter((dcpKey) => !dcpKey.releaseId);
    },

    moviesWithoutDcpKeys() {
      return this.moviesWithDcpKeys.filter((movie) => !movie.dcpKeys.length || movie.showsWithoutDcpKeys.length);
    },

    unknownDcpKeyFiles() {
      return Array.from(new Set(this.unknownDcpKeys.map((dcpKey) => dcpKey.filename))).sort();
    },
  },
  watch: {
    filteredMovies() {
      if (this.filteredMovies.length) {
        this.movieId = this.filteredMovies[0].id;
      }
    },
  },
  async created() {
    await this.fetchShows();
    await this.fetchReleases();
    await this.fetchDcpKeys();
  },
  methods: {
    async fetchReleases() {
      await this.$store.dispatch('dictionaries/showsReleases/fetchItems', {
        filter: {
          dateStart: this.now,
        },
      });
    },

    async fetchDcpKeys() {
      await this.$store.dispatch('dictionaries/dcpKeys/fetchItems', {
        filter: {
          dateStart: this.now,
        },
      });
    },

    async fetchShows() {
      await this.$store.dispatch('dictionaries/shows/fetchItems', {
        filter: {
          dateStart: this.now,
        },
      });
    },

    exportDcpKey({ id, filename }) {
      this.$store.dispatch('data/dcpKeys/exportItem', {
        id,
        filename,
      });
    },

    setMovieForDcpKey(filename, movieId) {
      this.unknownDcpKeyMovies.push({ filename, movieId });
    },

    async attachDcpKeys(event) {
      event.preventDefault();

      const promises = [];

      for (const { filename, movieId } of this.unknownDcpKeyMovies) {
        // отправляем первый попавшийся, бэк сам сопоставит остальные
        const dcpKey = this.unknownDcpKeys.find((_dcpKey) => _dcpKey.filename === filename);

        const promise = this.$store.dispatch('data/dcpKeys/attach', {
          id: dcpKey.id,
          movieId,
        });

        promises.push(promise);
      }

      await Promise.all(promises);

      await this.fetchDcpKeys();

      this.showUnknownDcpKeys = false;
    },
  },
};
</script>
