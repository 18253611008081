<template>
  <cinema-page
    route-name="mediaPanelsLogs"
    route-param-name="cinemaId"
    @loaded="setCinemaId($event)"
  >
    <template #buttons="{ loading }">
      <buttons
        :tags="tags"
        :screens="screens"
        :levels="levels"
        :filter="filter"
        :disabled="loading || logsLoading"
        @reset-filter="reset"
        @refresh="refresh"
        @change="({ field, value }) => changeFilter({ field, value })"
      ></buttons>
    </template>

    <template #scroll>
      <scroll>
        <div class="px-3 mt-4">
          <toolbar
            class="mb-5"
            :disabled="logsLoading"
            :pagination="{ page, limit, total }"
            @change-page="changePage($event)"
            @change-limit="changeLimit($event)"
          ></toolbar>
          <div class="overflow-hidden mw-100">
            <b-table
              :table-class="['table_logs', 'mw-100']"
              :fields="datatable.fields"
              :items="items"
              :busy="logsLoading"
              :show-empty="true"
              :empty-text="logsLoading ? 'Загрузка...' : 'Нет данных'"
            >
              <template #cell(screenId)="data">
                <div>
                  {{ getScreenName(data.item.screenId) }}
                </div>
              </template>
            </b-table>
          </div>
          <toolbar
            class="mt-5 mb-3"
            :disabled="logsLoading"
            :pagination="{ page, limit, total }"
            @change-page="changePage($event)"
            @change-limit="changeLimit($event)"
          ></toolbar>
        </div>
      </scroll>
    </template>
  </cinema-page>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import { mapState, mapActions } from 'vuex';

import Toolbar from '../../components/datatable/Toolbar.vue';
import CinemaPage from '../../layout/CinemaPage.vue';

import fields from './_index.fields';
import Buttons from './Buttons.vue';

export default {
  data: () => ({
    cinemaId: null,
    datatable: {
      fields,
    },
  }),
  computed: {
    ...mapState('media/screens', {
      screens: (state) => [
        { value: null, text: '-- Выберите экран --' },
        ...(state.items.map((item) => ({
          value: item.id,
          text: item.name,
        }))),
      ],
      screensLoading: (state) => state.loading,
    }),

    ...mapState('media/logs', {
      page: (state) => state.page,
      total: (state) => state.total,
      items: (state) => state.items,
      limit: (state) => state.limit,
      tags: (state) => [
        { value: null, text: '-- Выберите метку --' },
        ...(state.tags.map((item) => ({
          value: item,
          text: item,
        }))),
      ],
      levels: (state) => [
        { value: null, text: '-- Выберите тип --' },
        ...(state.levels.map((item) => ({
          value: item,
          text: item,
        }))),
      ],
      filter: (state) => state.filter,
      logsLoading: (state) => state.loading,
    }),

    hasPages() {
      return this.total / this.limit > 1;
    },
  },
  components: {
    CinemaPage,
    Buttons,
    Toolbar,
  },
  methods: {
    ...mapActions({
      changeFilterScreens: 'media/screens/changeFilter',

      changeFilter: 'media/logs/changeFilter',
      changeLimit: 'media/logs/changeLimit',
      changePage: 'media/logs/changePage',
      refreshFilter: 'media/logs/refreshFilter',
      resetFilter: 'media/logs/resetFilter',
      fetchLevels: 'media/logs/fetchLevels',
      fetchTags: 'media/logs/fetchTags',
    }),

    async setCinemaId({ id }) {
      this.cinemaId = id;
      await this.changeFilterScreens({ field: 'cinemaId', value: id });
      await this.resetFilter();
      await this.changeFilter({ field: 'cinemaId', value: id });
      await this.fetchLevels(id);
      await this.fetchTags(id);
    },

    getScreenName(id) {
      return this.screens.find((item) => item.value === id)?.text || 'text';
    },

    async reset() {
      await this.resetFilter();
      await this.changeFilter({ field: 'cinemaId', value: this.cinemaId });
    },

    async refresh() {
      await this.refreshFilter();
    },
  },
};
</script>

<style lang="scss">
.table_logs {
  /deep/ .table__td-break-word {
    word-break: break-all;
  }

  /deep/ .table__td-break-word {
    word-break: break-all;
  }

  /deep/ .table__td-tag {
    width: 200px;
  }
}
</style>
