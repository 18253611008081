<template>
  <div
    class="schedule-show d-flex flex-row pl-1 pr-1 w-100 h-100 align-items-center cursor-pointer"
    :class="{ 'schedule-show_highlight': highlight, 'schedule-show_same': isSame, 'schedule-show_selected': selected }"
    :id="`show_${show.id}`"
  >
    <div
      class="schedule-show__time mr-1"
      :id="`show_time_${show.id}`"
    >{{time}}</div>
    <div class="schedule-show__movie flex-grow-1">
      <div :title="`${movieName}, ${formatName}`">{{movieName}}</div>
    </div>
  </div>
</template>

<script>
import datetime from '../../utils/datetime';

export default {
  props: {
    show: {
      default: () => ({}),
    },
    isSame: {
      default: false,
    },
    highlight: {
      default: false,
    },
    selected: {
      default: false,
    },
  },
  computed: {
    time() {
      return datetime.formatTime(this.show.time);
    },

    movieName() {
      return this.show?.release?.movie?.name;
    },

    formatName() {
      return this.show?.release?.format?.name;
    },
  },
};
</script>

<style scoped>
.schedule-show {
  font-size: 12px;
  line-height: 12px;
}
.schedule-show__time {
  color: #888;
  padding: 0 3px 0 3px;
}
.schedule-show__movie {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.schedule-show_same .schedule-show__time,
.schedule-show_same .schedule-show__movie {
  display: none;
}
.schedule-show_same:hover .schedule-show__time,
.schedule-show_same:hover .schedule-show__movie {
  display: block;
}
.schedule-show_highlight {
  background-color: #dbe9f9;
}
.schedule-show_selected {
  background-color: #fff3e0;
}
</style>
