<template>
  <div
    class="timeline-line"
    :style="styles"
    :class="classes"
  >
    <div
      class="timeline-line__item"
      v-for="show in shows"
      :key="`show_${show.id}`"
      :style="getItemStyles(show.time, show.release.duration + show.advertisementsDuration)"
    >
      <div :style="{ width: convertMinutesToWidth(show.advertisementsDuration) + 'px' }">
        <slot name="advertisement"></slot>
      </div>
      <div :style="{ width: convertMinutesToWidth(show.release.duration) + 'px' }">
        <slot
          name="show"
          :show="show"
          :hall="hall"
        ></slot>
      </div>
    </div>
    <div
      class="timeline-line__item"
      v-for="gap in gaps"
      :key="`gap_${gap.id}`"
      :style="getItemStyles(gap.time, gap.interval)"
    >
      <slot
        name="gap"
        :warning="gap.warning"
        :interval="gap.interval"
      ></slot>
    </div>
  </div>
</template>

<script>
import datetime from '../../utils/datetime';

export default {
  props: {
    disabled: {
      default: false,
    },
    shows: {
      default: () => [],
    },
    gaps: {
      default: () => [],
    },
    minInterval: {
      default: 5,
    },
    timeStart: {
      default: '06:00',
    },
    minuteWidth: {
      default: 1,
    },
    step: {
      default: 5,
    },
    visibleGapInterval: {
      default: 60,
    },
    hall: {
      default: () => ({}),
    },
    hallHeight: {},
  },
  computed: {
    timelineWidth() {
      return 24 * 60 * this.minuteWidth;
    },

    styles() {
      return {
        width: `${this.timelineWidth}px`,
        height: `${this.hallHeight}px`,
      };
    },

    classes() {
      return {
        disabled: this.disabled,
      };
    },
  },
  methods: {
    getItemStyles(time, interval) {
      return {
        left: `${this.convertTimeToPosition(time)}px`,
        width: `${this.convertMinutesToWidth(interval)}px`,
      };
    },

    convertTimeToPosition(time) {
      return Math.ceil(datetime.getDiffInMinutes(time, this.timeStart) * this.minuteWidth);
    },

    convertMinutesToWidth(minutes) {
      return Math.ceil(minutes * this.minuteWidth);
    },
  },
};
</script>

<style scoped>
.timeline-line {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  position: relative;
}
.timeline-line.disabled {
  opacity: 0.5;
}
.timeline-line__item {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
}
.timeline-line__item_warning {
  opacity: 0.5;
}
</style>
