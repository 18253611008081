export default ({ checked }) => [
  {
    type: 'dropdown',
    text: 'Действия с выделенными',
    variant: 'outline-info',
    class: 'mr-2',
    bulk: true,
    disabled: checked && checked.length === 0,
    items: [
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeCheckedAgreements',
        permission: 'distributor_agreements.edit',
      },
    ],
  },
  {
    type: 'button',
    text: 'Добавить договор',
    variant: 'info',
    icon: 'fa-plus',
    class: 'mr-1',
    action: 'openCreateAgreementForm',
    permission: 'distributor_agreements.edit',
  },

];
