<template>
  <div id="app">
    <router-view></router-view>
    <portal-target name="main" multiple />
    <mounting-portal mount-to="body" append>
      <notifications position="bottom right"></notifications>
    </mounting-portal>
  </div>
</template>

<script>
export default {
  computed: {
    title() {
      return this.$store.getters['page/title'];
    },
  },
  watch: {
    title(value) {
      this.setTitle(value);
    },
  },
  created() {
    this.$http.$on('error', this.handleHttpErrors);

    this.$user.$on('logged', () => {
      // выполнить действия сразу после авторизации
    });

    this.$user.$on('logout', () => {
      if (this.$route.name !== 'login') {
        // this.$router.push({ name: 'login' });
        window.location.reload();
      }
    });

    this.setTitle(this.title);
  },
  methods: {
    handleHttpErrors(err) {
      if (err.response) {
        const { status, data } = err.response;

        switch (status) {
          case 401:
            this.$user.logout();

            console.log(status, data);

            break;

          case 403:
            this.$notify({
              type: 'error',
              text: 'Нет прав на выполнение операции!',
            });

            console.log(status, data);

            break;

          case 404:
            this.$notify({
              type: 'error',
              text: 'Не найден путь для обработки запроса! Обратитесь к администратору!',
            });

            console.log(status, data);

            break;

          case 500:
            this.$notify({
              type: 'error',
              text: 'Ошибка на стороне сервера! Обратитесь к администратору!',
            });

            console.log(status, data);

            break;

          default:
            break;
        }
      } else {
        this.$notify({
          type: 'error',
          text: 'Ошибка при выполнении запроса!',
        });

        console.log(err);
      }
    },

    setTitle(title) {
      window.document.title = title;
    },
  },
};
</script>

<style>
/* Import Bootstrap Vue Styles */
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~@kangc/v-md-editor/lib/style/base-editor.css';

@import './assets/style.css';
</style>

<style lang="scss">
@import './assets/vue-context.scss';
</style>
