export const DEBOUNCE_DELAY = 350;
export const START_CORNER_TOP_LEFT = 1;
export const START_CORNER_TOP_RIGHT = 2;
export const START_CORNER_BOTTOM_RIGHT = 3;
export const START_CORNER_BOTTOM_LEFT = 4;
export const DEFAULT_ROWS_COUNT = 20;
export const DEFAULT_SEATS_COUNT = 20;
export const DEFAULT_COLOR_SCHEME_ID = 1;
export const MAX_ROWS_COUNT = 40;
export const MAX_SEATS_COUNT = 40;
export const DEFAULT_SEAT_TYPE = 'standart';
export const PLACE_ID_BASE = 1000;
export const SCHEDULE_TYPE_DEFAULT = 1;
export const SCHEDULE_TYPE_TIMELINE = 2;
export const SCHEDULE_EDIT_MODE_DAY = 1;
export const SCHEDULE_EDIT_MODE_WEEK = 2;
export const DATE_FORMAT_REPORT = 'YYYY-MM-DD';
export const FILE_UPLOAD_TYPE_TRAILER = 0;
export const FILE_UPLOAD_TYPE_VERTICAL_POSTER = 1;
export const FILE_UPLOAD_TYPE_HORIZONTAL_POSTER = 2;
export const FILE_UPLOAD_TYPE_DOCUMENT = 3;
export const FILE_UPLOAD_TYPE_CINEMA_PHOTO = 4;
export const FILE_UPLOAD_TYPE_ADV_PROMOTION_IMAGE = 5;
export const FILE_UPLOAD_TYPE_ADV_PROMOTION_DOCUMENT = 6;
export const BUSINESS_DATE_BORDER = '06:00';
export const MEDIA_PANEL_STATUS_ACTIVE = 'ACTIVE';
export const MEDIA_PANEL_STATUS_REGISTER = 'REGISTERED';
export const MEDIA_PANEL_LIBRARY_TYPE_DIRECTORY = 0;
export const MEDIA_PANEL_LIBRARY_TYPE_FILE = 1;
export const MEDIA_PANEL_LIBRARY_STATUS_OK = 0;
export const MEDIA_PANEL_LIBRARY_STATUS_UPLOADING = 1;
export const MEDIA_PANEL_LIBRARY_STATUS_CONVERTING = 2;
export const MEDIA_PANEL_LIBRARY_STATUS_UPLOADING_ERROR = 3;
export const MEDIA_PANEL_LIBRARY_STATUS_CONVERTING_ERROR = 4;
export const DEFAULT_REPERTORY_ITEM_STATUS_ID = 1;
export const CASHIERS_APP_GROUP_ID = 1;
export const KIOSK_APP_GROUP_ID = 2;
export const DANGER_TIME_INTERVAL_BETWEEN_SHOWS = 3;
export const FISCAL_OPERATION_SALE = 1;
export const FISCAL_OPERATION_REFUND = 2;
export const FISCAL_ITEM_TYPE_TICKET = 1;
export const FISCAL_ITEM_TYPE_PRODUCT = 2;
export const FISCAL_ITEM_TYPE_CERTIFICATE = 3;
export const FISCAL_ITEM_TYPE_BAR_ITEM = 4;
export const FISCAL_ITEM_TYPE_SERVICE_FEE = 5;
export const PRINT_ITEM_TYPE_TICKET = 1;
export const PRINT_ITEM_TYPE_SLIP = 2;
export const PRINT_ITEM_TYPE_RECEIPT = 3;
export const BANNER_TYPE_MOVIE = 0;
export const BANNER_TYPE_ADV_PROMOTION = 1;
export const BANNER_TYPE_OTHER = 2;
export const PAYMENT_STATUS_CREATED = 0;
export const PAYMENT_STATUS_APPROVED = 1;
export const PAYMENT_STATUS_REVERSED = 2;
export const PAYMENT_STATUS_REFUNDED = 3;
export const PAYMENT_STATUS_DECLINED = 4;
export const PAYMENT_STATUS_EXPIRED = 5;
export const PAYMENT_STATUS_REFUND_ACTIVATED = 6;
