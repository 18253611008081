<template>
  <div class="calendar-stat d-flex">
    <div
      v-for="(week, index) in weeks"
      class="calendar-stat__week d-flex flex-column"
      :key="index"
      :style="`flex-grow:${week.length}`"
    >
      <div class="calendar-stat__info d-flex align-items-center border-right border-top pl-1 pr-1">
        <span
          class="d-flex"
          :class="{'text-danger': getChargeAsPercents(index) > 100}"
          :id="`percent_${index}`"
        >{{getChargeAsPercents(index)}}%</span>
        <b-tooltip
          :target="`percent_${index}`"
        >
          <div>Занято: {{weeksCharge[index]}} мин. ({{getChargeAsPercents(index)}}%)</div>
          <div v-if="getFreeTime(index) > 0">Свободно: {{getFreeTime(index)}} мин.</div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    weeks: {
      default: () => [],
    },
    weeksCapacity: {
      default: () => [],
    },
    weeksCharge: {
      default: () => [],
    },
  },
  methods: {
    getChargeAsPercents(index) {
      const percent = Math.round(this.weeksCharge[index] / this.weeksCapacity[index] * 100);

      return !Number.isNaN(percent) ? percent : 0;
    },

    getFreeTime(index) {
      return this.weeksCapacity[index] - this.weeksCharge[index];
    },
  },
};
</script>

<style scoped>
.calendar-stat {
  min-height: 25px;
  font-size: 12px;
}
.calendar-stat__week {
  flex: 1 0 0;
}
.calendar-stat__info {
  min-height: 25px;
}
</style>
