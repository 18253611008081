<template>
  <div class="table-container">
    <collection-provider
      collection="cinemaPhotos"
      ref="collection"
      :filter="{ cinemaId }"
      :disable-fetching="!cinemaId"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :item-actions="datatable.itemActions"
          @item-action="performItemAction($event)"
        >
          <template #cell(name)="data">
            <div>{{data.item.name}}</div>
          </template>
          <template #cell(file)="data">
            <div v-if="data.item.file">
              <img :src="data.item.file.filepath" class="cinema-photos__image" alt="">
            </div>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.cinemaPhoto.show"
      create-title="Добавление фото"
      edit-title="Редактирование фото"
      collection="cinemaPhotos"
      :id="modals.cinemaPhoto.id"
      :init="{ cinemaId }"
      @close="modals.cinemaPhoto.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <cinema-photo-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></cinema-photo-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import CinemaPhotoForm from './CinemaPhotoForm.vue';

import fields from './_cinemaPhotos.fields';
import itemActions from './_cinemaPhotos.itemActions';

export default {
  components: {
    CollectionProvider,
    Datatable,
    ModalModelForm,
    CinemaPhotoForm,
  },
  props: {
    cinemaId: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
      },
      modals: {
        cinemaPhoto: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateCinemaPhotoForm() {
      this.modals.cinemaPhoto.id = null;
      this.modals.cinemaPhoto.show = true;
    },

    openEditCinemaPhotoForm(id) {
      this.modals.cinemaPhoto.id = id;
      this.modals.cinemaPhoto.show = true;
    },

    async removeCinemaPhoto(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>
  .cinema-photos__image {
    max-width: 200px;
    max-height: 200px;
  }
</style>
