export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'socialNetwork.name',
    type: 'text',
    label: 'Социальная сеть',
    filterable: false,
    sortable: false,
  },
  {
    key: 'link',
    type: 'text',
    label: 'Ссылка',
    sortable: false,
    filterable: false,
  },
];
