<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Имя:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Фамилия:"
          :invalid-feedback="errors.surname"
          :state="states.surname"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.surname"
            :state="states.surname"
            :disabled="loading"
            @change="updateValue('surname', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Телефон:"
          :invalid-feedback="errors.phone"
          :state="states.phone"
        >
          <masked-input
            type="text"
            class="form-control"
            :placeholder="placeholder.PHONE"
            :value="formatPhone(item.phone)"
            :mask="mask.getPhoneMask()"
            :guide="true"
            :disabled="loading"
            :state="states.phone"
            @change="updateValue('phone', clearPhone($event.target.value))"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="E-mail:"
          :invalid-feedback="errors.email"
          :state="states.email"
        >
          <b-form-input
            type="text"
            :value="item.email"
            :state="states.email"
            :disabled="loading"
            :placeholder="placeholder.EMAIL"
            @change="updateValue('email', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Дата рождения:"
          :invalid-feedback="errors.birthDate"
          :state="states.birthDate"
        >
          <masked-input
            type="text"
            class="form-control form-control-sm"
            :value="formatDate(item.birthDate)"
            :mask="mask.getBirthDateMask()"
            :guide="true"
            :disabled="loading"
            @change="updateValue('birthDate', convertDateToDbFormat($event.target.value))"
          >
          </masked-input>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            :checked="!!item.approvedAt"
            :state="states.approvedAt"
            :disabled="loading"
            @change="updateValue('approved', $event)"
          >Клиент подтвержден</b-form-checkbox>
          <b-form-text v-if="!!item.approvedAt">
            Дата подтверждения: {{ formatDateTime(item.approvedAt) }}
          </b-form-text>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            :checked="!!item.blockedAt"
            :state="states.blockedAt"
            :disabled="loading"
            @change="updateValue('blocked', $event)"
          >Клиент заблокирован</b-form-checkbox>
          <b-form-text v-if="!!item.blockedAt">
            Дата блокировки: {{ formatDateTime(item.blockedAt) }}
          </b-form-text>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import { placeholder } from '../../utils/placeholder';
import { MaskHelper } from '../../utils/mask';
import { DATE_FORMAT_REPORT } from '../../constants';

import datetime from '../../utils/datetime';

export default {
  components: {
    MaskedInput,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      DATE_FORMAT_REPORT,
      placeholder,
      mask: MaskHelper,
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    formatDateTime(date) {
      return datetime.formatDateTime(date);
    },

    formatPhone(phone) {
      return phone ? phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5') : '';
    },

    clearPhone(phone) {
      return phone ? phone.replace(/\D/g, '') : '';
    },

    formatDate(date) {
      return date ? datetime.formatDate(date) : '';
    },

    convertDateToDbFormat(date) {
      return date ? datetime.convertDateToDbFormat(date, 'DD.MM.YYYY') : '';
    },
  },
};
</script>
