import advertisements from './advertisements';
import advPromotions from './advPromotions';
import appGroups from './appGroups';
import apps from './apps';
import banners from './banners';
import cashierDevices from './cashierDevices';
import cashierPlaces from './cashierPlaces';
import certificateGroups from './certificateGroups';
import certificateOperations from './certificateOperations';
import certificates from './certificates';
import cinemaPhotos from './cinemaPhotos';
import cinemas from './cinemas';
import cinemaSocialNetworks from './cinemaSocialNetworks';
import colorSchemes from './colorSchemes';
import customerDevices from './customerDevices';
import customerOperations from './customerOperations';
import customers from './customers';
import dcpKeys from './dcpKeys';
import discounts from './discounts';
import distributorAgreements from './distributorAgreements';
import distributorLegalEntities from './distributorLegalEntities';
import distributorReportEmails from './distributorReportEmails';
import distributors from './distributors';
import distributors2 from './distributors2';
import documents from './documents';
import emailBlacklistDomains from './emailBlacklistDomains';
import employees from './employees';
import equipment from './equipment';
import favoriteReleases from './favoriteReleases';
import formats from './formats';
import hallCategories from './hallCategories';
import halls from './halls';
import holidays from './holidays';
import kiosks from './kiosks';
import laboratories2 from './laboratories2';
import labs from './labs';
import legalEntities from './legalEntities';
import mailAccounts from './mailAccounts';
import merchantAccounts from './merchantAccounts';
import merchantPayments from './merchantPayments';
import movies from './movies';
import movies2 from './movies2';
import notifications from './notifications';
import orders from './orders';
import organizationOperations from './organizationOperations';
import organizations from './organizations';
import playlists from './playlists';
import prices from './prices';
import privileges from './privileges';
import productGroups from './productGroups';
import productReportGroups from './productReportGroups';
import products from './products';
import promocodeGroups from './promocodeGroups';
import promocodeOperations from './promocodeOperations';
import promocodes from './promocodes';
import promotions from './promotions';
import pushkinsCardEvents from './pushkinsCardEvents';
import pushkinsCardEvents2 from './pushkinsCardEvents2';
import qaCategories from './qaCategories';
import qaItems from './qaItems';
import releases from './releases';
import releasesCount from './releasesCount';
import repertoryItems from './repertoryItems';
import reportsEais from './reportsEais';
import reportsEaisInfo from './reportsEaisInfo';
import reportsRentrak from './reportsRentrak';
import reserveTypes from './reserveTypes';
import rfmSettings from './rfmSettings';
import roles from './roles';
import sales from './sales';
import screenGroups from './screenGroups';
import selectionGroups from './selectionGroups';
import selections from './selections';
import settingsMediaPanels from './settingsMediaPanels';
import shows from './shows';
import tags from './tags';
import targets from './targets';
import taxSystems from './taxSystems';
import users from './users';
import utcOffsets from './utcOffsets';
import webPages from './webPages';
import webMenus from './webMenus';

export default {
  namespaced: true,

  modules: {
    advertisements,
    advPromotions,
    appGroups,
    apps,
    banners,
    cashierDevices,
    cashierPlaces,
    certificateGroups,
    certificateOperations,
    certificates,
    cinemaPhotos,
    cinemas,
    cinemaSocialNetworks,
    colorSchemes,
    customerDevices,
    customerOperations,
    customers,
    dcpKeys,
    discounts,
    distributorAgreements,
    distributorLegalEntities,
    distributorReportEmails,
    distributors,
    distributors2,
    documents,
    emailBlacklistDomains,
    employees,
    equipment,
    favoriteReleases,
    formats,
    hallCategories,
    halls,
    holidays,
    kiosks,
    laboratories2,
    labs,
    legalEntities,
    mailAccounts,
    merchantAccounts,
    merchantPayments,
    movies,
    movies2,
    notifications,
    orders,
    organizationOperations,
    organizations,
    playlists,
    prices,
    privileges,
    productGroups,
    productReportGroups,
    products,
    promocodeGroups,
    promocodeOperations,
    promocodes,
    promotions,
    pushkinsCardEvents,
    pushkinsCardEvents2,
    qaCategories,
    qaItems,
    releases,
    releasesCount,
    repertoryItems,
    reportsEais,
    reportsEaisInfo,
    reportsRentrak,
    reserveTypes,
    rfmSettings,
    roles,
    sales,
    screenGroups,
    selectionGroups,
    selections,
    settingsMediaPanels,
    shows,
    tags,
    targets,
    taxSystems,
    users,
    utcOffsets,
    webPages,
    webMenus,
  },
};
