import FileSaver from 'file-saver';
import AnalyticsService from '../services/Analytics';

import datetime from '../utils/datetime';

const TABLE_RFM = 'rfm';
const COLUMN_RECENCY = 'recency';
const COLUMN_FREQUENCY = 'frequency';
const COLUMN_MONETARY = 'monetary';
const COLUMN_CUSTOMER_ID = 'customer_id';
const COLUMN_COUNT_CUSTOMERS = 'count(customer_id)';
const COLUMN_ORDERS_PRICE = 'sum(orders_price)';
const COLUMN_COUNT_EMAIL = 'count(distinct(email))';
const COLUMN_COUNT_EMAIL_ALIAS = 'uniqExact(email)';
const COLUMN_COUNT_PHONE = 'count(distinct(phone))';
const COLUMN_COUNT_PHONE_ALIAS = 'uniqExact(phone)';

const TABLE_TICKETS = 'tickets';
const COLUMN_CUSTOMERS_COUNT = 'count(distinct(customer_id))';
const COLUMN_CUSTOMERS_COUNT_ALIAS = 'uniqExact(customer_id)';
const COLUMN_ORDERS_COUNT = 'count(distinct(unique_order_id))';
const COLUMN_ORDERS_COUNT_ALIAS = 'uniqExact(unique_order_id)';
const COLUMN_TICKETS_COUNT = 'count(id)';
const COLUMN_TICKETS_PRICE = 'sum(price)';
const COLUMN_CINEMA_ID = 'cinema_id';
const COLUMN_DATE_APPROVED_AT = 'date(approved_at)';

export default {
  props: {
    cinemaIds: {
      default: () => [],
    },
    dates: {
      default: () => [],
    },
    period: {
      default: 0,
    },
    ticketsCount: {
      default: 0,
    },
    ticketsOperation: {
      default: '>',
    },
    ordersCount: {
      default: 0,
    },
    ordersOperation: {
      default: '>',
    },
    recencyLowValue: {
      default: 0,
    },
    recencyHighValue: {
      default: 0,
    },
    frequencyLowValue: {
      default: 0,
    },
    frequencyHighValue: {
      default: 0,
    },
    monetaryLowValue: {
      default: 0,
    },
    monetaryHighValue: {
      default: 0,
    },
  },
  render() {
    return this.$scopedSlots.default({
      items: this.items,
      getRfmData: this.getRfmData,
      emailsCount: this.emailsCount,
      phonesCount: this.phonesCount,
      loading: this.loading,
      exportData: this.exportData,
      allTicketsCount: this.allTicketsCount,
      allTicketsPrice: this.allTicketsPrice,
      allOrdersCount: this.allOrdersCount,
      customersTicketsCount: this.customersTicketsCount,
      customersTicketsPrice: this.customersTicketsPrice,
      customersCount: this.customersCount,
      customersOrdersCount: this.customersOrdersCount,
    });
  },
  data() {
    return {
      items: {},
      loading: false,
      emailsCount: 0,
      phonesCount: 0,
      allTicketsCount: 0,
      allTicketsPrice: 0,
      allOrdersCount: 0,
      customersTicketsCount: 0,
      customersTicketsPrice: 0,
      customersCount: 0,
      customersOrdersCount: 0,
    };
  },
  computed: {
    params() {
      return {
        cinema_ids: this.cinemaIds.join(','),
        tickets_count: this.ticketsCount,
        tickets_operation: this.ticketsOperation,
        orders_count: this.ordersCount,
        orders_operation: this.ordersOperation,
        frequency_low_value: this.frequencyLowValue,
        frequency_high_value: this.frequencyHighValue,
        monetary_low_value: this.monetaryLowValue,
        monetary_high_value: this.monetaryHighValue,
      };
    },

    watchedValues() {
      return [
        ...this.dates.map((date) => datetime.formatDate(date)),
        this.period,
        JSON.stringify(this.params),
      ].join();
    },
  },
  watch: {
    watchedValues: {
      handler() {
        this.resetData();

        this.fetchStatData();
        this.fetchAnalyticsData();
      },
    },
  },
  created() {
    this.fetchStatData();
    this.fetchAnalyticsData();
  },
  methods: {
    resetData() {
      this.items = {};
      this.emailsCount = 0;
      this.phonesCount = 0;
      this.allTicketsCount = 0;
      this.allTicketsPrice = 0;
      this.allOrdersCount = 0;
      this.customersTicketsCount = 0;
      this.customersTicketsPrice = 0;
      this.customersOrdersCount = 0;
      this.customersCount = 0;
    },

    formatQueryKey(date) {
      return `rfm_${datetime.formatDate(date)}`;
    },

    async fetchStatData() {
      if (!this.dates.length || !this.cinemaIds.length) {
        return;
      }

      const queries = [];

      const dateEnd = datetime.convertDateToDbFormat(this.dates[this.dates.length - 1]);
      const dateStart = datetime.convertDateToDbFormat(datetime.subtractDays(dateEnd, this.period));

      queries.push({
        id: 'stat_all',
        table: TABLE_TICKETS,
        select: [
          COLUMN_ORDERS_COUNT,
          COLUMN_TICKETS_COUNT,
          COLUMN_TICKETS_PRICE,
        ],
        where: {
          [COLUMN_CINEMA_ID]: this.cinemaIds,
          [COLUMN_DATE_APPROVED_AT]: {
            gte: dateStart,
            lte: dateEnd,
          },
        },
      });

      queries.push({
        id: 'stat_with_customers',
        table: TABLE_TICKETS,
        select: [
          COLUMN_CUSTOMERS_COUNT,
          COLUMN_ORDERS_COUNT,
          COLUMN_TICKETS_COUNT,
          COLUMN_TICKETS_PRICE,
        ],
        where: {
          [COLUMN_CUSTOMER_ID]: {
            gt: 0,
          },
          [COLUMN_CINEMA_ID]: this.cinemaIds,
          [COLUMN_DATE_APPROVED_AT]: {
            gte: dateStart,
            lte: dateEnd,
          },
        },
      });

      this.loading = true;

      const [err, result] = await AnalyticsService.fetch(queries);

      this.loading = false;

      if (err || !result) {
        return;
      }

      if (result.stat_all) {
        this.allTicketsCount = +result.stat_all[0][COLUMN_TICKETS_COUNT];
        this.allTicketsPrice = +result.stat_all[0][COLUMN_TICKETS_PRICE];
        this.allOrdersCount = +result.stat_all[0][COLUMN_ORDERS_COUNT] || +result.stat_all[0][COLUMN_ORDERS_COUNT_ALIAS];
      }

      if (result.stat_with_customers) {
        this.customersTicketsCount = +result.stat_with_customers[0][COLUMN_TICKETS_COUNT];
        this.customersTicketsPrice = +result.stat_with_customers[0][COLUMN_TICKETS_PRICE];
        this.customersOrdersCount = +result.stat_with_customers[0][COLUMN_ORDERS_COUNT] || +result.stat_with_customers[0][COLUMN_ORDERS_COUNT_ALIAS];
        this.customersCount = +result.stat_with_customers[0][COLUMN_CUSTOMERS_COUNT] || +result.stat_with_customers[0][COLUMN_CUSTOMERS_COUNT_ALIAS];
      }
    },

    async fetchAnalyticsData() {
      if (!this.dates.length || !this.cinemaIds.length) {
        return;
      }

      const queries = [];

      for (let i = 0; i < this.dates.length; i += 1) {
        const date = this.dates[i];
        const ageStart = datetime.getDiffInDays(new Date(), date);

        queries.push({
          id: this.formatQueryKey(date),
          table: TABLE_RFM,
          params: {
            order_age_start: ageStart,
            order_age_end: ageStart + +this.period,
            recency_low_value: this.recencyLowValue + ageStart,
            recency_high_value: this.recencyHighValue + ageStart,
            ...this.params,
          },
          select: [
            COLUMN_RECENCY,
            COLUMN_FREQUENCY,
            COLUMN_MONETARY,
            COLUMN_COUNT_CUSTOMERS,
            COLUMN_ORDERS_PRICE,
            COLUMN_COUNT_EMAIL,
            COLUMN_COUNT_PHONE,
          ],
          group_by: [
            COLUMN_RECENCY,
            COLUMN_FREQUENCY,
            COLUMN_MONETARY,
          ],
        });
      }

      this.loading = true;

      const [err, result] = await AnalyticsService.fetch(queries);

      this.loading = false;

      if (err || !result) {
        return;
      }

      for (let i = 0; i < this.dates.length; i += 1) {
        const key = this.formatQueryKey(this.dates[i]);

        if (result[key]) {
          this.items[key] = result[key];

          if (i === this.dates.length - 1) {
            for (const row of result[key]) {
              this.emailsCount += +row[COLUMN_COUNT_EMAIL_ALIAS];
              this.phonesCount += +row[COLUMN_COUNT_PHONE_ALIAS];
            }
          }
        }
      }
    },

    getRfmData(date) {
      const key = this.formatQueryKey(date);

      return this.items[key] || [];
    },

    async exportData(groups = []) {
      if (!this.dates.length || !this.cinemaIds.length) {
        return;
      }

      this.loading = true;

      const queries = [];

      const date = this.dates[this.dates.length - 1];
      const ageStart = datetime.getDiffInDays(new Date(), date);

      const params = {
        order_age_start: ageStart,
        order_age_end: ageStart + +this.period,
        recency_low_value: this.recencyLowValue + ageStart,
        recency_high_value: this.recencyHighValue + ageStart,
        ...this.params,
      };

      if (groups.length) {
        for (const group of groups) {
          queries.push({
            id: `export_${group.recency}_${group.frequency}_${group.monetary}`,
            table: TABLE_RFM,
            params,
            select: ['*'],
            where: {
              [COLUMN_RECENCY]: group.recency,
              [COLUMN_FREQUENCY]: group.frequency,
              [COLUMN_MONETARY]: group.monetary,
            },
          });
        }
      } else {
        queries.push({
          id: 'export',
          table: TABLE_RFM,
          params,
          select: ['*'],
        });
      }

      const [err, data] = await AnalyticsService.exportCustomers(queries);

      this.loading = false;

      if (err && !data) {
        return;
      }

      const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });

      FileSaver.saveAs(blob, 'rfm_customers.csv');
    },
  },
};
