<template>
  <div class="table-container">
    <collection-provider
      collection="qaItems"
      ref="collection"
      sort-by="position"
      :sort-desc="false"
      :page="null"
      :limit="1000"
    >
      <template #default="{ data, actions }">
        <datatable-drag
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
          @change-positions="changePositions($event)"
        >
          <template #filter-category>
            <dictionary-provider name="qaCategories">
              <template #default="{ items }">
                <toggle-list
                  placeholder="Все категории"
                  :options="items"
                  :searchable="true"
                  :hideSelected="false"
                  :disabled="data.loading"
                  :value="convertToValue(filter.qaCategoryIds, items)"
                  @change="actions.changeFilter('qaCategoryIds', $array.getValues($event, 'id'))"
                ></toggle-list>
              </template>
            </dictionary-provider>
          </template>
          <template #filter-apps>
            <dictionary-provider name="apps">
              <template #default="{ items }">
                <toggle-list
                  placeholder="Все источники"
                  :options="items"
                  :searchable="true"
                  :hideSelected="false"
                  :disabled="data.loading"
                  :value="convertToValue(filter.appIds, items)"
                  @change="actions.changeFilter('appIds', $array.getValues($event, 'id'))"
                ></toggle-list>
              </template>
            </dictionary-provider>
          </template>
          <template #cell-apps="data">
            <div>{{$array.convertToText(data.item.apps, 'Все')}}</div>
          </template>
          <template #cell-category="data">
            <div v-if="data.item.category">{{data.item.category.name}}</div>
            <div v-else>-</div>
          </template>
        </datatable-drag>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.qa.show"
      create-title="Создание вопроса"
      edit-title="Редактирование вопроса"
      collection="qaItems"
      :id="modals.qa.id"
      @close="modals.qa.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <qa-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></qa-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { confirmDialog } from '../../utils/dialogs';

import DictionaryProvider from '../../providers/DictionaryProvider';
import CollectionProvider from '../../providers/CollectionProvider';

import DatatableDrag from '../../components/datatable/DatatableDrag.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import ToggleList from '../../components/fields/ToggleList.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import QaForm from './QaForm.vue';

export default {
  components: {
    DictionaryProvider,
    CollectionProvider,
    ModalModelForm,
    DatatableDrag,
    QaForm,
    ToggleList,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        qa: {
          show: false,
          id: '',
        },
      },
    };
  },
  computed: {
    ...mapState('data/qaItems', {
      filter: (state) => state.filter,
    }),
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateQaForm() {
      this.modals.qa.id = '';
      this.modals.qa.show = true;
    },

    openEditQaForm(id) {
      this.modals.qa.id = id;
      this.modals.qa.show = true;
    },

    async removeCheckedQa() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    async removeQa(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    async changePositions(values) {
      await this.$store.dispatch('data/qaItems/changePositions', { values });
    },

    convertToValue(ids, options) {
      if (!ids) {
        return [];
      }

      // eslint-disable-next-line no-bitwise
      return options.filter((option) => !!~ids.indexOf(option.id));
    },
  },
};
</script>
