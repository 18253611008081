export default () => [
  {
    type: 'button',
    text: 'Добавить товар',
    variant: 'info',
    icon: 'fa-plus',
    class: 'mr-1',
    action: 'openCreateProductForm',
    permission: 'products.edit',
  },
];
