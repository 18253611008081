<template>
  <div class="header header_movie">
    <div class="d-inline-flex align-items-center">
      <h2 class="header__title">{{ name }}</h2>
        <b-badge
          v-if="ageLimit"
          class="header__age-limit ml-2"
        >{{ ageLimit }} +</b-badge>
    </div>
    <div v-if="nameEn" class="text-small">{{ nameEn }}</div>
    <div
      v-if="releases.length"
      class="mt-2"
    >
      <div
        class="header__badge d-inline-block mr-2 py-1 px-2 border rounded"
        variant="secondary"
        v-for="(release, index) in releases"
        :key="`release-format-${index}`"
      >{{ release.format.name }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    name: null,
    ageLimit: null,
    nameEn: null,
    releases: {
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &_movie {
    .header__title {
      font-size: 1.85em;
    }

    .header__age-limit {
      font-size: 17px;
    }

    .header__badge {
      font-size: 1em;
    }
  }
}
</style>
