<template>
  <div class="datepicker-interval-wrap">
    <b-form-row>
      <b-col cols="6">
        <date
          :value="from"
          :placeholder="placeholderFrom"
          :disabled="disabled"
          @selected="selectedFrom"
        ></date>
      </b-col>
      <b-col cols="6">
        <date
          :value="to"
          :placeholder="placeholderTo"
          :disabled="disabled"
          @selected="selectedTo"
        ></date>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import Date from './Date.vue';

export default {
  props: {
    value: {
      default: () => ({
        from: '',
        to: '',
      }),
    },
    format: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    placeholderFrom: {
      type: String,
      default: 'От',
    },
    placeholderTo: {
      type: String,
      default: 'До',
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    from() {
      return this.value && this.value.from;
    },
    to() {
      return this.value && this.value.to;
    },
  },
  methods: {
    selectedFrom(value) {
      this.$emit('selected', {
        from: value,
        to: this.to,
      });
    },
    selectedTo(value) {
      this.$emit('selected', {
        from: this.from,
        to: value.replace('00:00:00', '23:59:59'),
      });
    },
  },
  components: {
    Date,
  },
};
</script>
