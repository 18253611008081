<template>
  <div class="timeline-halls">
    <div
      v-for="hall in halls"
      class="timeline-halls__hall d-flex flex-column justify-content-between"
      :key="hall.id"
      :style="styles"
    >
      <div class="timeline-halls__hall-name">Зал {{hall.name}}</div>
      <div class="timeline-halls__hall-info">
        <div
          class="timeline-halls__value"
          :title="hall.hallCategory.name"
        >{{hall.hallCategory.name}}</div>
        <div
          class="timeline-halls__value"
          :title="$array.convertToText(hall.formats)"
        >{{$array.convertToText(hall.formats)}}</div>
        <div>{{hall.placesCount}} мест</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    halls: {
      default: () => [],
    },
    hallHeight: {},
  },
  computed: {
    styles() {
      return {
        height: `${this.hallHeight}px`,
      };
    },
  },
};
</script>

<style scoped>
.timeline-halls {
  margin-top: 35px;
  border-right: 1px solid #ccc;
}
.timeline-halls__hall {
  padding: 5px;
  border-bottom: 1px solid #ccc;
  min-width: 75px;
}
.timeline-halls__hall-name, .timeline-halls__hall-info {
  font-size: 11px;
}
.timeline-halls__value {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
