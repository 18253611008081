<template>
  <div class="image-viewer text-center">
    <span
      class="image-viewer__trash-button"
      v-if="image"
      @click="$emit('remove')"
    >
      <icon icon="fa-times"></icon>
    </span>
    <img class="image-viewer__img" v-if="image" :src="image">
  </div>
</template>

<script>

export default {
  props: {
    image: {
      default: '',
    },
  },
};
</script>

<style scoped>
.image-viewer {
  position: relative;
}
.image-viewer__img {
  max-width: 250px;
}
.image-viewer__trash-button {
  position: absolute;
  right: 0;
  top: -4px;
  color: #AA0000;
  cursor: pointer;
}
</style>
