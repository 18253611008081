<template>
  <div class="container login-container">
    <div class="d-flex align-items-center justify-content-center login-wrapper" >

      <b-card class="login-card p-2">
        <h4 class="mb-3">Вход</h4>
        <logo class="login-logo"></logo>
        <b-form @submit.prevent="login">
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text>
                <icon icon="fa-user fa-fw"></icon>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              type="text"
              size="sm"
              v-model="email"
              placeholder="Email"
            ></b-form-input>
          </b-input-group>
          <b-input-group class="mb-3">
            <b-input-group-prepend>
              <b-input-group-text>
                <icon icon="fa-lock fa-fw"></icon>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              type="password"
              size="sm"
              v-model="password"
              placeholder="Пароль"
            ></b-form-input>
          </b-input-group>
          <b-button
            variant="primary"
            size="sm"
            type="submit"
          >Войти</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import Logo from '../components/Logo.vue';

export default {
  components: {
    Logo,
  },

  data() {
    return {
      email: '',
      password: '',
    };
  },

  methods: {
    async login() {
      const { email, password } = this;

      if (!email || !password) {
        this.$notify({
          type: 'warn',
          text: 'Введите логин и пароль!',
        });

        return;
      }

      const [err, result] = await this.$user.login({ email, password });

      if (result) {
        this.$router.push(this.$route.query.redirect || '/');

        return;
      }

      if (err) {
        if (err.status && err.status === 401) {
          this.$notify({
            type: 'error',
            text: 'Неправильный логин или пароль!',
          });
        }

        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  min-height: 100vh;
}
.login-wrapper {
  min-height: 100vh;
  padding-bottom: 10%;
}
.login-card {
  min-width: 33%;
  position: relative;
}
.login-logo {
  position: absolute;
  width: 150px;
  top: 1.25rem;
  right: 1.75rem;
}
</style>
