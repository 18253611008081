export default [
  { key: 'requestId', label: 'Номер транзакции' },
  { key: 'type', label: 'Тип транзакции' },
  { key: 'paid', label: 'Успешно' },
  { key: 'code', label: 'Код' },
  { key: 'transactionTime', label: 'Время транзакции' },
  { key: 'cardNumber', label: 'Номер карты' },
  { key: 'cardType', label: 'Тип карты' },
  { key: 'createdAt', label: 'Дата создания' },
];
