import {
  MEDIA_PANEL_LIBRARY_TYPE_FILE as typeFile,
  MEDIA_PANEL_LIBRARY_TYPE_DIRECTORY as typeDirectory,
  MEDIA_PANEL_LIBRARY_STATUS_OK as statusOk,
  MEDIA_PANEL_LIBRARY_STATUS_UPLOADING as statusUploading,
  MEDIA_PANEL_LIBRARY_STATUS_CONVERTING as statusConverting,
  MEDIA_PANEL_LIBRARY_STATUS_UPLOADING_ERROR as statusUploadError,
  MEDIA_PANEL_LIBRARY_STATUS_CONVERTING_ERROR as statusConvertingError,
} from '../../constants';
import { convertFileSize } from '../../utils/file';

export function isDirectory(type) {
  return type === typeDirectory;
}

export function isFile(type) {
  return type === typeFile;
}

export function isImage(mimeType) {
  return /^image/.test(mimeType);
}

export function isVideo(mimeType) {
  return /^video/.test(mimeType);
}

export function getTitleType(mimeType) {
  if (isImage(mimeType)) {
    return 'Изображение';
  }

  if (isVideo(mimeType)) {
    return 'Видео';
  }

  return 'Файл';
}

export function getTitleByStatus(status) {
  let title;

  switch (status) {
    case statusOk:
      title = 'Файл загружен';
      break;
    case statusUploading:
      title = 'Файл загружается...';
      break;
    case statusConverting:
      title = 'Файл конвертируется...';
      break;
    case statusUploadError:
      title = 'Произошла ошибка во время загрузки.';
      break;
    case statusConvertingError:
      title = 'Произошла ошибка во время конвертации.';
      break;
    default:
      title = 'Неизвестна ошибка.';
  }

  return title;
}

export const arrStatusForReloadPage = [statusUploading, statusConverting];

export const fields = [
  {
    key: 'name',
    label: 'Название',
    sortable: true,
  },
  {
    key: 'size',
    label: 'Размер',
    sortable: true,
    formatter: (value) => convertFileSize(value),
  },
  {
    key: 'contentType',
    label: 'Тип',
    sortable: true,
    formatter: (value, key, item) => {
      if (isDirectory(item.type)) {
        return 'Каталог';
      }

      return getTitleType(value);
    },
  },
  {
    key: 'status',
    label: 'Cтатус',
    sortable: true,
    formatter: (value, key, item) => {
      if (!isFile(item.type)) {
        return '';
      }

      return getTitleByStatus(value);
    },
  },
  {
    key: 'mediaInfo',
    label: 'Разрешение',
  },
  {
    key: 'duration',
    label: 'Длительность',
    sortable: true,
  },
  {
    key: 'updatedAt',
    label: 'Дата',
    sortable: true,
  },
  {
    key: 'action',
    label: 'Действие',
  },
];
