<template>
  <collection-provider
    ref="collection"
    :collection="collection"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    @loaded="onCollectionLoaded()"
  >
    <template #default="{ data, actions }">
      <model-provider
        ref="model"
        :collection="collection"
        :id="itemId"
        @notfound="openFirstItem()"
        @loaded="$emit('item-loaded', $event)"
      >
        <template #default="{ data: { loading, item } }">
          <slot
            :data="{ ...data, item, loading }"
            :actions="{ ...actions, open, remove, load }"
          ></slot>
        </template>
      </model-provider>
    </template>
  </collection-provider>
</template>

<script>
import { confirmDialog } from '../utils/dialogs';
import { toIntOrNull } from '../utils/cast';

import CollectionProvider from './CollectionProvider';
import ModelProvider from './ModelProvider';

export default {
  components: {
    CollectionProvider,
    ModelProvider,
  },
  props: {
    collection: {
      required: true,
    },
    routeName: {
      required: true,
    },
    routeParamName: {
      required: true,
    },
    sortBy: {},
    sortDesc: {
      default: false,
    },
    defaultItemId: {
      default: null,
    },
  },
  data() {
    return {
      itemId: null,
    };
  },
  computed: {
    itemIdInRoute() {
      return toIntOrNull(this.$route.params[this.routeParamName]);
    },
  },
  watch: {
    itemId(itemId) {
      if (this.$route.name === this.routeName && this.itemIdInRoute !== this.itemId) {
        this.replaceItemIdInRoute(itemId);
      }
    },

    $route(to) {
      if (!this.itemIdInRoute && this.$refs.collection.data.items.length) {
        this.openFirstItem();
        return;
      }

      if (to.name === this.routeName && this.itemIdInRoute !== this.itemId) {
        this.itemId = this.itemIdInRoute;
      }
    },
  },
  created() {
    if (this.itemIdInRoute) {
      this.itemId = this.itemIdInRoute;
    } else if (this.defaultItemId) {
      this.itemId = this.defaultItemId;
    }
  },
  methods: {
    replaceItemIdInRoute(itemId) {
      this.$router.replace({
        params: {
          ...this.$route.params,
          [this.routeParamName]: itemId,
        },
        query: this.$route.query,
      });
    },

    onCollectionLoaded() {
      if (this.itemId) {
        return;
      }

      this.openFirstItem();
    },

    openFirstItem() {
      const firstItem = this.$refs.collection.data.items[0];

      if (firstItem) {
        this.itemId = firstItem.id;
      }
    },

    open(id) {
      this.itemId = id;
    },

    load(id) {
      if (id) {
        if (this.itemId !== id) {
          this.itemId = id;
        } else {
          this.$refs.model.load();
        }
        this.$refs.collection.fetchItems();
      }
    },

    async remove() {
      if (await confirmDialog('Удалить?')) {
        await this.$refs.collection.removeItem(this.itemId);
        this.openFirstItem();
      }
    },
  },
};
</script>
