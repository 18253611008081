<template>
  <div class="calendar-day d-flex align-items-center">
    <div class="flex-grow-1 flex-column d-flex">
      <div :class="{'calendar-day__date_holiday': holiday}">{{formatDate(date)}}</div>
      <a
        class="calendar-day__clear"
        @click="$emit('clear-date', date)"
      >Очистить день</a>
    </div>
    <div class="mr-1">
      <span>{{formatDay(date)}}</span>
    </div>
    <div>
      <a
        class="calendar-day__timeline"
        @click.prevent="$emit('select-date', date)"
      >
        <icon class="ml-1" icon="fa-list"></icon>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      default: () => [],
    },
    holiday: {
      default: false,
    },
  },
  methods: {
    formatDate(date) {
      return date.toLocaleString('ru', { day: 'numeric', month: 'short' });
    },

    formatDay(date) {
      return date.toLocaleString('ru', { weekday: 'short' }).toUpperCase();
    },
  },
};
</script>

<style scoped>
.calendar-day {
  min-height: 46px;
  border-bottom: 0.1em solid #ccc;
}
.calendar-day__clear {
  font-size: 12px;
  display: none;
}
.calendar-day:hover .calendar-day__clear {
  display: block;
}
.calendar-day__clear, .calendar-day__timeline {
  color: blue !important;
  cursor: pointer;
}
.calendar-day__date_holiday {
  color: #A00;
}
</style>
