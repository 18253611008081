<template>
  <b-checkbox
    :disabled="loading || release.disabled"
    :checked="isChecked"
    @change="toggleChecked()"
  >
    {{ release.format_name }}
  </b-checkbox>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    cinemaId: {
      default: null,
    },
    release: {
      default: null,
    },
  },
  computed: {
    ...mapState('data/favoriteReleases', [
      'loading',
      'selectedItems',
    ]),

    isChecked() {
      return !!this.selectedItems
        .find(({ release_id, cinema_id }) => cinema_id === this.cinemaId && release_id === this.release.release_id);
    },
  },
  methods: {
    toggleChecked() {
      const item = {
        cinemaId: this.cinemaId,
        releaseId: this.release.release_id,
      };

      if (this.isChecked) {
        this.$emit('remove', item);
      } else {
        this.$emit('add', item);
      }
    },
  },
};
</script>
