export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditDomainForm',
        permission: 'email_blacklist_domains.edit',
      },
      {
        type: 'button',
        text: item.enabled ? 'Отключить' : 'Включить',
        action: item.enabled ? 'disableDomain' : 'enableDomain',
        permission: 'email_blacklist_domains.edit',
      },
      /*
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeDomain',
        permission: 'email_blacklist_domains.edit',
      },
       */
    ],
  },
];
