<template>
  <modal-form-dialog
    :title="title"
    :save="save"
    :close="close"
    :busy="busy"
    :hide-footer="hideFooter"
    :size="size"
    @hidden="$emit('close')"
    @shown="$emit('shown', $event)"
  >
    <model-provider
      ref="model"
      :collection="collection"
      :id="id"
      :init="init"
      :copy="copy"
      @error="notifyErrors"
      @saved="$emit('saved', $event)"
      @loaded="$emit('loaded', $event)"
    >
      <template #default="props">
        <slot
          name="default"
          v-bind="props"
        ></slot>
      </template>
    </model-provider>
  </modal-form-dialog>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import ModelProvider from '../../providers/ModelProvider';

import ModalFormDialog from './ModalFormDialog.vue';

export default {
  components: {
    ModelProvider,
    ModalFormDialog,
  },
  props: {
    collection: {
      required: true,
    },
    id: {
      default: null,
    },
    copy: {
      default: false,
    },
    init: {
      default: () => ({}),
    },
    createTitle: {
      default: 'Создание',
    },
    editTitle: {
      default: 'Редактирование',
    },
    hideFooter: {
      default: null,
    },
    size: {
      default: 'lg',
    },
  },
  data() {
    return {
      busy: false,
    };
  },
  computed: {
    title() {
      return (this.id && !this.copy) ? this.editTitle : this.createTitle;
    },
  },
  methods: {
    async save() {
      this.busy = true;

      const result = await this.$refs.model.save();

      this.busy = false;

      if (!result) {
        return false;
      }

      return true;
    },

    async close() {
      if (!this.$refs.model.dirty) {
        return true;
      }

      if (await confirmDialog('Отменить изменения?')) {
        return true;
      }

      return false;
    },

    notifyErrors(errors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const field of Object.keys(errors)) {
        this.$notify({
          type: 'error',
          text: errors[field],
        });
      }
    },
  },
};
</script>
