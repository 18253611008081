<template>
  <multi-view-provider
    collection="distributors2"
    route-name="distributors2"
    route-param-name="distributorId"
    sort-by="name"
  >
    <template #default="{ data, actions }">
      <multi-view :scroll-side="false">
        <template #side>
          <distributors
            :id="data.item && data.item.id"
            :items="data.items"
            :loading="data.loading"
            :name="data.filter.name"
            @search="actions.changeFilter('name', $event)"
            @fetch-next="actions.fetchNextItems()"
            @select="actions.open($event)"
          ></distributors>
        </template>

        <template #panel>
          <buttons
            :distributor-id="data.item && data.item.id"
            :disabled="data.loading"
            @create="openCreateDistributorForm()"
            @edit="openEditDistributorForm(data.item && data.item.id)"
            @remove="actions.remove()"
          ></buttons>
        </template>

        <template #content>
          <loading v-if="data.loading"></loading>
          <distributor v-else :item="data.item"></distributor>
        </template>

        <template #portal>
          <modal-model-form
            v-if="modals.distributor.show"
            create-title="Создание прокатчика"
            edit-title="Редактирование прокатчика"
            collection="distributors2"
            :id="modals.distributor.id"
            @close="modals.distributor.show = false"
            @saved="actions.load($event)"
          >
            <template #default="{ data, actions }">
              <distributor-form
                :loading="data.loading"
                :errors="data.errors"
                :states="data.states"
                :item="data.item"
                @change="actions.setValue($event.field, $event.value)"
              ></distributor-form>
            </template>
          </modal-model-form>
        </template>
      </multi-view>
    </template>
  </multi-view-provider>
</template>

<script>
import MultiView from '../../layout/MultiView.vue';
import Loading from '../../components/Loading.vue';

import Distributor from './Distributor.vue';
import Distributors from './Distributors.vue';
import Buttons from './Buttons.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import DistributorForm from './DistributorForm.vue';
import MultiViewProvider from '../../providers/MultiViewProvider.vue';

export default {
  components: {
    MultiView,
    Loading,
    Distributor,
    Distributors,
    Buttons,
    ModalModelForm,
    DistributorForm,
    MultiViewProvider,
  },
  data() {
    return {
      modals: {
        distributor: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    openCreateDistributorForm() {
      this.modals.distributor.id = '';
      this.modals.distributor.show = true;
    },

    openEditDistributorForm(id) {
      this.modals.distributor.id = id;
      this.modals.distributor.show = true;
    },
  },
};
</script>
