<template>
  <div class="form-setting">
    <div
      class="d-flex w-100 pt-2"
      v-for="({ id, isMain, name }, index) in screens"
      :key="`screen_${index}`"
    >
      <div
        v-if="isGroupScreens"
        class="flex-grow-1 form-setting__icon-wrap"
      >
        <div
          class="d-inline-flex align-items-center cursor-pointer"
          @click="changeScreensMain(id)"
        >
          <icon
            v-if="screens.length > 1 && type !== 'MEDIASTREAM' && type !== 'TRAILERS'"
            icon="mdi-crown"
            :class="[
              'd-flex align-items-center pr-2 form-setting__icon',
              { 'form-setting__icon-main': isMain },
            ]"
          ></icon>
          <div>
            {{ name }}
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <b-button
          size="sm"
          variant="outline-secondary"
          :disabled="disabledButtonPreview"
          @click="$emit('preview-screen-for-settings', { screenId: id })"
        >
          Предпросмотр
        </b-button>
        <b-button
          size="sm"
          class="ml-2"
          variant="outline-secondary"
          @click="$emit('screenshot', { screenId: id })"
        >
          Снимок экрана
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    type: null,
    disabledButtonPreview: {
      default: true,
    },
    hideChangeTop: {
      type: Boolean,
      default: false,
    },
    findScreensByField: {
      type: Object,
      validator({ field }) {
        return ['groupId', 'id'].includes(field);
      },
    },
  },
  data: () => ({
    dirty: false,
    screens: [],
  }),
  watch: {
    items: {
      handler(items) {
        this.screens = items
          .filter((item) => item[this.findScreensByField.field] === this.findScreensByField.id)
          .sort(
            (a, b) => a.positionIndex - b.positionIndex,
          );
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('media/screens', {
      items: (state) => state.items,
      loading: (state) => state.loading,
    }),

    idsScreens() {
      return this.items.map((item) => item.id);
    },

    isGroupScreens() {
      return this.findScreensByField.field === 'groupId';
    },

    isScreen() {
      return this.findScreensByField.field === 'id';
    },
  },
  methods: {
    ...mapActions({
      updateScreen: 'media/screens/updateItem',
      updateScreens: 'media/screens/updateItems',
    }),

    changeScreensMain(id) {
      if (this.screens.length && this.type === 'MEDIASTREAM') {
        return;
      }

      this.screens.forEach((item, index) => {
        this.$set(this.screens, index, {
          ...item,
          isMain: id === item.id ? !item.isMain : false,
        });
      });

      this.dirty = true;
    },

    async save(id) {
      /**
       * Для настройки одиночных экранов сохраненяется только id настройки
       */
      if (this.isScreen) {
        await this.updateScreen({ id: this.findScreensByField.id, values: { mediablockSettingsId: id } });
      }

      /**
       * Для настройки группы экранов сохраненяется TOP (isMain)
       * @hideChangeTop {Boolean} параметр для сброса TOP (isMain)
       */
      if (this.isGroupScreens) {
        if (this.hideChangeTop) {
          this.updateScreens({ ids: this.idsScreens, values: { isMain: false } });
        }

        if (this.dirty) {
          const promises = [];

          /* eslint-disable no-restricted-syntax */
          for (const screen of this.screens) {
            promises.push(this.updateScreen({ id: screen.id, values: { isMain: screen.isMain } }));
          }

          await Promise.all(promises);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-setting {
  &__icon {
    transform: 0.3s ease;
    opacity: 0.5;

    &-main {
      opacity: 1;
    }

    &::before {
      color: var(--yellow);
      font-size: 20px;
    }
  }
}
</style>
