<template>
  <b-nav class=" advertisements-tabs" pills>
    <b-nav-item
      v-for="type in advertisementTypes"
      :active="type.id === advertisementTypeId"
      :key="type.id"
      @click="$emit('select', type.id)"
    >{{type.name}}</b-nav-item>
    <b-nav-item
      :active="!!(advertisementTypes.length && !advertisementTypeId)"
      @click="$emit('select', null)"
    >Порядок воспроизведения</b-nav-item>
  </b-nav>
</template>

<script>
export default {
  props: {
    advertisementTypeId: {
      default: null,
    },
    advertisementTypes: {
      default: () => [],
    },
  },
};
</script>

<style scoped>
.advertisements-tabs >>> .nav-link {
  padding: 0.25rem 0.5rem;
}
</style>
