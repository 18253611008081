<template>
  <div class="table-container email-blacklist-domains">
    <collection-provider
      collection="emailBlacklistDomains"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(domain)>
            <div class="text-search">
              <b-form-input
                  type="text"
                  size="sm"
                  :disabled="data.loading"
                  :value="data.filter.domainLike"
                  @change="actions.changeFilter('domainLike', $event)"
              ></b-form-input>
              <span class="trash" @click="actions.changeFilter('domainLike', '')">
                <icon icon="fa-times"></icon>
              </span>
            </div>
          </template>
          <template #filter(enabled)>
            <b-form-select
                size="sm"
                :value="data.filter.enabled"
                :options="domainEnabledStatuses"
                :disabled="data.loading"
                @change="actions.changeFilter('enabled', $event)"
            ></b-form-select>
          </template>
          <template #cell(enabled)="data">
            {{getDomainEnabledName(data.item.enabled)}}
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.domain.show"
      create-title="Добавление домена"
      edit-title="Редактирование домена"
      collection="emailBlacklistDomains"
      :id="modals.domain.id"
      @close="modals.domain.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <domain-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></domain-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import DomainForm from './DomainForm.vue';

export default {
  components: {
    CollectionProvider,
    ModalModelForm,
    Datatable,
    DomainForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        domain: {
          show: false,
          id: '',
        },
      },
      domainEnabledStatuses: [
        {
          value: null,
          text: '',
        },
        {
          value: false,
          text: 'Нет',
        },
        {
          value: true,
          text: 'Да',
        },
      ],
      yesNoOptions: [
        {
          value: 1,
          text: 'Да',
        },
        {
          value: 0,
          text: 'Нет',
        },
      ],
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateDomainForm() {
      this.modals.domain.id = '';
      this.modals.domain.show = true;
    },

    openEditDomainForm(id) {
      this.modals.domain.id = id;
      this.modals.domain.show = true;
    },

    async removeCheckedDomains() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    async removeDomain(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    disableCheckedDomains() {
      this.$refs.collection.changeItems(this.datatable.checked, { enabled: 0 });
      this.datatable.checked = [];
    },

    enableCheckedDomains() {
      this.$refs.collection.changeItems(this.datatable.checked, { enabled: 1 });
      this.datatable.checked = [];
    },

    disableDomain(id) {
      this.$refs.collection.changeItem(id, { enabled: 0 });
    },

    enableDomain(id) {
      this.$refs.collection.changeItem(id, { enabled: 1 });
    },

    getDomainEnabledName(value) {
      return this.domainEnabledStatuses.find((status) => status.value === value)?.text;
    },
  },
};
</script>
