<template>
  <div class="timeline d-flex">
    <timeline-halls
      class="timeline__halls"
      :halls="halls"
      :hall-height="hallHeight"
    >
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
    </timeline-halls>
    <div class="timeline__lines flex-grow-1">
      <div
        ref="scroll"
        class="timeline__scroll"
        v-dragscroll="true"
        @mousewheel.prevent="onMouseWheel"
      >
        <timeline-ruler
          :time-start="timelineTimeStart"
          :step="step"
          :minute-width="minuteWidth"
        ></timeline-ruler>
        <timeline-line
          v-for="hall in halls"
          :key="`timeline_${hall.id}`"
          :shows="getHallShows(hall.id)"
          :gaps="getHallGaps(hall.id)"
          :time-start="timelineTimeStart"
          :step="step"
          :minute-width="minuteWidth"
          :disabled="disabled"
          :hall="hall"
          :hall-height="hallHeight"
        >
          <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
          >
            <slot :name="name" v-bind="slotData" />
          </template>
        </timeline-line>
      </div>
    </div>
  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll';

import TimelineRuler from './TimelineRuler.vue';
import TimelineHalls from './TimelineHalls.vue';
import TimelineLine from './TimelineLine.vue';

import { BUSINESS_DATE_BORDER } from '../../constants';

export default {
  components: {
    TimelineRuler,
    TimelineHalls,
    TimelineLine,
  },
  directives: {
    dragscroll,
  },
  props: {
    disabled: {
      default: false,
    },
    halls: {
      default: () => [],
    },
    shows: {
      default: () => [],
    },
    gaps: {
      default: () => [],
    },
    minuteWidth: {
      default: 1.2,
    },
    hallHeight: {},
  },
  data() {
    return {
      timelineTimeStart: BUSINESS_DATE_BORDER,
      step: 5,
    };
  },
  methods: {
    getHallShows(hallId) {
      return this.shows.filter((item) => item.hallId === hallId);
    },
    getHallGaps(hallId) {
      return this.gaps.filter((item) => item.hallId === hallId);
    },
    onMouseWheel(e) {
      this.$refs.scroll.scrollLeft += e.deltaX;
    },
  },
};
</script>

<style scoped>
.timeline {
  color: #313131;
  width: 100%;
  overflow: hidden;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
.timeline__scroll {
  overflow: hidden;
}
.timeline__lines {
  width: 0;
}
</style>
