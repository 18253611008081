<template>
  <div class="table-container color-schemes">
    <collection-provider
      collection="colorSchemes"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          :permissions="$user.permissions"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #cell(name)="data">
            <div>{{data.item.name}}</div>
            <div
              v-if="data.item.description"
              class="text-muted mt-2"
            >{{data.item.description}}</div>
          </template>
          <template v-slot:cell(color)="data">
            <span
              class="color"
              :style="`background-color: ${data.item.color}`"
            ></span>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.color.show"
      create-title="Создание цветовой схемы"
      edit-title="Редактирование цветовой схемы"
      collection="colorSchemes"
      :id="modals.color.id"
      @close="modals.color.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <color-scheme-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></color-scheme-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import ColorSchemeForm from './ColorSchemeForm.vue';

export default {
  components: {
    CollectionProvider,
    ModalModelForm,
    Datatable,
    ColorSchemeForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        color: {
          show: false,
          id: 0,
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateColorSchemeForm() {
      this.modals.color.id = '';
      this.modals.color.show = true;
    },

    async removeCheckedColorSchemes() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    openEditColorSchemeForm(id) {
      this.modals.color.id = id;
      this.modals.color.show = true;
    },

    async removeColorScheme(id) {
      if (await confirmDialog('Удалить?')) {
        const [err] = await this.$refs.collection.removeItem(id);

        if (err) {
          if (err.status && err.status === 422) {
            this.$notify({
              type: 'error',
              text: err.data.error,
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.color {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 3px;
}
</style>
