<template>
  <dictionary-provider name="repertoryItemStatuses">
    <template #default="{ items: statuses }">
      <b-dropdown
        variant="outline-info"
        size="sm"
        :disabled="disabled"
      >
        <template #button-content>
          <span
            class="color mr-1"
            :style="`background-color: ${getDictionaryValueField(statuses, value, 'color')}`"
          ></span>
          {{getDictionaryValueField(statuses, value, 'name')}}
        </template>
        <b-dropdown-item
          v-for="status in statuses"
          :key="status.id"
          @click="$emit('change', status.id)"
        >
          <span class="color mr-1" :style="`background-color: ${status.color}`"></span>
          {{status.name}}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </dictionary-provider>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    DictionaryProvider,
  },
  props: {
    value: {
      default: null,
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    getDictionaryValueField(items, id, field) {
      if (!items || !Array.isArray(items)) {
        return '';
      }

      const item = items.find((_item) => _item.id === id);

      return item && item[field];
    },
  },
};
</script>

<style scoped>
.color {
  display: inline-block;
  width: 10px;
  height: 10px;
}
</style>
