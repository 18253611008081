var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[(_vm.frequency === 'high' && _vm.monetary === 'high')?_c('td',{staticClass:"bg p-0",class:{
      'bg-success': _vm.recency === 'high',
      'bg-warning': _vm.recency === 'normal',
      'bg-danger': _vm.recency === 'low',
    },attrs:{"rowspan":"9"}}):_vm._e(),(_vm.monetary === 'high')?_c('td',{staticClass:"align-middle border-bottom",attrs:{"rowspan":"3"}},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('td',{staticClass:"p-0 segment-icons text-nowrap align-middle",class:{'border-bottom': _vm.monetary === 'low'}},[_c('img',{staticClass:"rfm-icon",attrs:{"src":require(("@/assets/analytics/rfm_recency_" + _vm.recency + ".svg"))}}),_c('img',{staticClass:"rfm-icon",attrs:{"src":require(("@/assets/analytics/rfm_frequency_" + _vm.frequency + ".svg"))}}),_c('img',{staticClass:"rfm-icon",attrs:{"src":require(("@/assets/analytics/rfm_monetary_" + _vm.monetary + ".svg"))}})]),_c('td',{staticClass:"p-1 text-right align-middle border-left border-right bg-col",class:{'border-bottom': _vm.monetary === 'low'}},[_vm._v(_vm._s(_vm.getMainSegmentCount(_vm.monetary).toLocaleString()))]),(_vm.monetary === 'high')?_c('td',{staticClass:"p-1 align-middle border-bottom border-right bg-col",attrs:{"rowspan":"3"}},[_c('div',{staticClass:"h-100 w-100 d-flex align-items-center justify-content-center",style:(("background: linear-gradient(to right, #b6e1fc " + _vm.mainSegmentCountPercent + "%, transparent 0);"))},[_vm._v(_vm._s(_vm.mainSegmentTotalCount.toLocaleString()))])]):_vm._e(),(_vm.monetary === 'high')?_c('td',{staticClass:"p-1 align-middle border-bottom border-right bg-col",attrs:{"rowspan":"3"}},[_c('div',{staticClass:"h-100 w-100 d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"p-1",class:{
          'bg-success': _vm.mainSegmentTotalCount > _vm.offsetSegmentTotalCount,
          'bg-danger': _vm.mainSegmentTotalCount < _vm.offsetSegmentTotalCount,
        }},[_vm._v(_vm._s(_vm.mainSegmentCountPercent)+"%")])])]):_vm._e(),(_vm.monetary === 'high')?_c('td',{staticClass:"p-1 align-middle border-bottom border-right bg-col",attrs:{"rowspan":"3"}},[_c('div',{staticClass:"h-100 w-100 d-flex align-items-center justify-content-center",class:{
        'text-success': _vm.mainSegmentTotalCount > _vm.offsetSegmentTotalCount,
        'text-danger': _vm.mainSegmentTotalCount < _vm.offsetSegmentTotalCount,
      }},[_vm._v(_vm._s(_vm.formatDiff(_vm.mainSegmentTotalCount, _vm.offsetSegmentTotalCount)))])]):_vm._e(),_c('td',{staticClass:"p-1 text-right align-middle border-left border-right",class:{'border-bottom': _vm.monetary === 'low'}},[_vm._v(_vm._s(_vm.getMainSegmentPrice(_vm.monetary).toLocaleString()))]),(_vm.monetary === 'high')?_c('td',{staticClass:"p-1 align-middle border-bottom border-right",attrs:{"rowspan":"3"}},[_c('div',{staticClass:"h-100 w-100 d-flex align-items-center justify-content-center",style:(("background: linear-gradient(to right, #b6e1fc " + _vm.mainSegmentPricePercent + "%, transparent 0);"))},[_vm._v(_vm._s(_vm.mainSegmentTotalPrice.toLocaleString()))])]):_vm._e(),(_vm.monetary === 'high')?_c('td',{staticClass:"p-1 align-middle border-bottom border-right",attrs:{"rowspan":"3"}},[_c('div',{staticClass:"h-100 w-100 d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"p-1",class:{
          'bg-success': _vm.mainSegmentTotalPrice > _vm.offsetSegmentTotalPrice,
          'bg-danger': _vm.mainSegmentTotalPrice < _vm.offsetSegmentTotalPrice,
        }},[_vm._v(_vm._s(_vm.mainSegmentPricePercent)+"%")])])]):_vm._e(),(_vm.monetary === 'high')?_c('td',{staticClass:"p-1 align-middle border-bottom border-right",attrs:{"rowspan":"3"}},[_c('div',{staticClass:"h-100 w-100 d-flex align-items-center justify-content-center",class:{
        'text-success': _vm.mainSegmentTotalPrice > _vm.offsetSegmentTotalPrice,
        'text-danger': _vm.mainSegmentTotalPrice < _vm.offsetSegmentTotalPrice,
      }},[_vm._v(_vm._s(_vm.formatDiff(_vm.mainSegmentTotalPrice, _vm.offsetSegmentTotalPrice)))])]):_vm._e(),(_vm.monetary === 'high')?_c('td',{staticClass:"p-1 align-middle border-bottom bg-col",attrs:{"rowspan":"3"}},[_c('div',{staticClass:"h-100 w-100 d-flex align-items-center justify-content-center"},[_vm._v(_vm._s(_vm.mainSegmentPricePerCustomer.toLocaleString()))])]):_vm._e(),_c('td',{staticClass:"p-1 text-right align-middle border-right bg-col",class:{'border-bottom': _vm.monetary === 'low'}},[_vm._v(_vm._s(_vm.getMainSegmentPricePerCustomer(_vm.monetary).toLocaleString()))]),(_vm.monetary === 'high')?_c('td',{staticClass:"p-1 align-middle border-bottom border-right bg-col",attrs:{"rowspan":"3"}},[_c('div',{staticClass:"h-100 w-100 d-flex align-items-center justify-content-center",class:{
        'text-success': _vm.mainSegmentPricePerCustomer > _vm.offsetSegmentPricePerCustomer,
        'text-danger': _vm.mainSegmentPricePerCustomer < _vm.offsetSegmentPricePerCustomer,
      }},[_vm._v(_vm._s(_vm.formatDiff(_vm.mainSegmentPricePerCustomer, _vm.offsetSegmentPricePerCustomer)))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }