var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container apps"},[_c('collection-provider',{ref:"collection",attrs:{"collection":"apps"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('dictionaries-provider',{attrs:{"names":['appGroups']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var appGroups = ref.appGroups;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"panel-actions":_vm.datatable.panelActions,"item-actions":_vm.datatable.itemActions,"checked":_vm.datatable.checked,"permissions":_vm.$user.permissions},on:{"change-checked":function($event){_vm.datatable.checked = $event},"panel-action":function($event){return _vm.performPanelAction($event)},"item-action":function($event){return _vm.performItemAction($event)}},scopedSlots:_vm._u([{key:"filter(appGroupId)",fn:function(){return [_c('b-form-select',{attrs:{"size":"sm","value":data.filter.appGroupId,"options":_vm.$array.convertToOptions(appGroups.filter(function (group) { return group.id > _vm.KIOSK_APP_GROUP_ID; })),"disabled":data.loading},on:{"change":function($event){return actions.changeFilter('appGroupId', $event)}}})]},proxy:true},{key:"cell(name)",fn:function(data){return [_c('div',[_vm._v(_vm._s(data.item.name))]),(data.item.description)?_c('div',{staticClass:"text-muted mt-2"},[_vm._v(" "+_vm._s(data.item.description)+" ")]):_vm._e()]}},{key:"cell(appGroupId)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.appGroup.name)+" ")]}},{key:"cell(ticketOperator)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.ticketOperator ? 'Да' : 'Нет')+" ")]}},{key:"cell(serviceFeePercent)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.serviceFeePercent)+" % ")]}},{key:"cell(enabled)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getAppsActiveName(data.item.enabled))+" ")]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}],null,true)})]}}])}),(_vm.modals.app.show)?_c('modal-model-form',{attrs:{"create-title":"Создание приложения","edit-title":"Редактирование приложения","collection":"apps","size":"xl","id":_vm.modals.app.id},on:{"close":function($event){_vm.modals.app.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('app-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,false,372552156)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }