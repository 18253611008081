<template>
  <b-row align-v="center" class="mb-2">
    <b-col style="width: 220px; flex: none;">
      <div class="selection-form__title">{{ name }}</div>
    </b-col>
    <b-col>
      <div class="d-flex">
        <date
          class="w-50 mr-1"
          format-output="YYYY-MM-DD"
          :placeholder="placeholderFrom"
          :value="include.gte"
          :disabled="disabled"
          @selected="includeGte($event)"
        >
        </date>
        <date
          class="w-50"
          format-output="YYYY-MM-DD"
          :placeholder="placeholderTo"
          :value="include.lte"
          :disabled="disabled"
          @selected="includeLte($event)"
        >
        </date>
      </div>
    </b-col>
    <b-col>
      <div class="d-flex">
        <date
          class="w-50 mr-1"
          format-output="YYYY-MM-DD"
          :placeholder="placeholderFrom"
          :value="exclude.gte"
          :disabled="disabled"
          @selected="excludeGte($event)"
        >
        </date>
        <date
          class="w-50"
          format-output="YYYY-MM-DD"
          :placeholder="placeholderTo"
          :value="exclude.lte"
          :disabled="disabled"
          @selected="excludeLte($event)"
        >
        </date>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Date from '../../components/fields/Date.vue';

export default {
  components: {
    Date,
  },
  props: {
    name: {
      default: '',
    },
    disabled: {
      default: false,
    },
    include: {
      default: () => ({}),
    },
    exclude: {
      default: () => ({}),
    },
    placeholderFrom: {
      default: 'от',
    },
    placeholderTo: {
      default: 'до',
    },
  },
  methods: {
    includeGte(value) {
      const include = { ...this.include };

      if (value) {
        include.gte = value;
      } else {
        delete include.gte;
      }

      this.$emit('include', include);
    },

    includeLte(value) {
      const include = { ...this.include };

      if (value) {
        include.lte = value;
      } else {
        delete include.lte;
      }

      this.$emit('include', include);
    },

    excludeGte(value) {
      const exclude = { ...this.exclude };

      if (value) {
        exclude.gte = value;
      } else {
        delete exclude.gte;
      }

      this.$emit('exclude', exclude);
    },

    excludeLte(value) {
      const exclude = { ...this.exclude };

      if (value) {
        exclude.lte = value;
      } else {
        delete exclude.lte;
      }

      this.$emit('exclude', exclude);
    },
  },
};
</script>

<style scoped>
.selection-form__title {
  line-height: 30px;
  font-weight: 500;
}
</style>
