<template>
  <div class="p-2">
    <b-form-group
      label="Время наблюдения (В днях):"
      :disabled="disabled"
    >
      <b-input
        type="number"
        size="sm"
        :value="filter.period"
        :disabled="disabled"
        @change="$emit('change', { field: 'period', value: +$event })"
      ></b-input>
    </b-form-group>

    <b-form-group
      label="Куплено билетов за раз:"
      :disabled="disabled"
    >
      <value-comparator
        :disabled="disabled"
        :value="filter.ticketsCount"
        :operation="filter.ticketsOperation"
        @change-value="$emit('change', { field: 'ticketsCount', value: +$event })"
        @change-operation="$emit('change', { field: 'ticketsOperation', value: $event })"
      ></value-comparator>
    </b-form-group>

    <b-form-group
      label="Всего заказов:"
      :disabled="disabled"
    >
      <value-comparator
        :disabled="disabled"
        :value="filter.ordersCount"
        :operation="filter.ordersOperation"
        @change-value="$emit('change', { field: 'ordersCount', value: +$event })"
        @change-operation="$emit('change', { field: 'ordersOperation', value: $event })"
      ></value-comparator>
    </b-form-group>

    <b-form-group
      label="R: последняя покупка (в днях):"
      :disabled="disabled"
    >
      <value-range
        low-text="Давно"
        high-text="Недавно"
        :disabled="disabled"
        :inverted="true"
        :low-value="filter.recencyLowValue"
        :high-value="filter.recencyHighValue"
        @change-low-value="$emit('change', { field: 'recencyLowValue', value: +$event })"
        @change-high-value="$emit('change', { field: 'recencyHighValue', value: +$event })"
      ></value-range>
    </b-form-group>

    <b-form-group
      label="F: количество покупок за период:"
      :disabled="disabled"
    >
      <value-range
        low-text="Редко"
        high-text="Часто"
        :disabled="disabled"
        :low-value="filter.frequencyLowValue"
        :high-value="filter.frequencyHighValue"
        @change-low-value="$emit('change', { field: 'frequencyLowValue', value: +$event })"
        @change-high-value="$emit('change', { field: 'frequencyHighValue', value: +$event })"
      ></value-range>
    </b-form-group>

    <b-form-group
      label="M: сумма покупок за период:"
      :disabled="disabled"
    >
      <value-range
        low-text="Мало"
        high-text="Много"
        :disabled="disabled"
        :low-value="filter.monetaryLowValue"
        :high-value="filter.monetaryHighValue"
        @change-low-value="$emit('change', { field: 'monetaryLowValue', value: +$event })"
        @change-high-value="$emit('change', { field: 'monetaryHighValue', value: +$event })"
      ></value-range>
    </b-form-group>

    <!-- <b-button
      class="mb-2"
      variant="outline-info"
      size="sm"
      @click="$emit('save')"
    >Сохранить настройки</b-button>

    <b-button
      variant="outline-info"
      size="sm"
      @click="$emit('load')"
    >Загрузить настройки</b-button> -->
  </div>
</template>

<script>
import ValueComparator from '../../../components/fields/ValueComparator.vue';
import ValueRange from '../../../components/fields/ValueRange.vue';

export default {
  components: {
    ValueRange,
    ValueComparator,
  },
  props: {
    disabled: {
      default: false,
    },
    filter: {
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.cinema {
  max-width: 240px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
