import humps from 'humps';
import collectionServiceFactory from './collectionServiceFactory';
import { convertBlobToString } from '../utils/file';
import exportCertificateGroup from '../api/certificateGroup';

const certificateGroupsService = collectionServiceFactory('certificateGroups');

export default {
  ...certificateGroupsService,

  async exportCertificateGroup(id) {
    let [err, data] = await exportCertificateGroup(id);

    if (err && err.data && err.data instanceof Blob) {
      const text = await convertBlobToString(err.data);
      err.data = JSON.parse(text);
    }

    if (data instanceof Blob) {
      data = await convertBlobToString(data);
    }

    return [humps.camelizeKeys(err), data];
  },
};
