<template>
  <cinema-page>
    <template #scroll="{ cinemaId, regionId }">
      <div class="px-2">
        <periods-selector
          class="mb-4"
          :count="periodsCount"
          @change="periods = $event"
          @format="formattedPeriods = $event"
        ></periods-selector>

        <sales
          :cinema-id="cinemaId"
          :region-id="regionId"
          :periods="periods"
          :formatted-periods="formattedPeriods"
          @add-period="periodsCount++"
          @remove-period="periodsCount > 1 && periodsCount--"
        ></sales>

        <shows-occupation
          class="mt-4"
          :cinema-id="cinemaId"
          :date-start="dateStart"
          :date-end="dateEnd"
        ></shows-occupation>

        <movies-sales
          class="mt-4"
          :cinema-id="cinemaId"
          :date-start="dateStart"
          :date-end="dateEnd"
        ></movies-sales>
      </div>
    </template>
  </cinema-page>
</template>

<script>
import CinemaPage from '../../../layout/CinemaPage.vue';

import PeriodsSelector from '../components/PeriodsSelector.vue';
import Sales from './Sales.vue';
import ShowsOccupation from './ShowsOccupation.vue';
import MoviesSales from './MoviesSales.vue';

export default {
  components: {
    CinemaPage,
    PeriodsSelector,
    Sales,
    ShowsOccupation,
    MoviesSales,
  },
  data() {
    return {
      periodsCount: 8,
      periods: [],
      formattedPeriods: [],
    };
  },
  computed: {
    lastPeriod() {
      if (!this.periods.length) {
        return null;
      }

      return this.periods[this.periods.length - 1];
    },

    dateStart() {
      return this.lastPeriod?.dateStart;
    },

    dateEnd() {
      return this.lastPeriod?.dateEnd;
    },
  },
};
</script>

<style scoped>
</style>
