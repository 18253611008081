<template>
  <div class="listbox d-flex flex-column h-100">
    <div class="listbox__search">
      <b-input
        type="text"
        size="sm"
        :placeholder="searchText"
        class="w-100"
        :value="search"
        @input="$emit('search', $event)"
      ></b-input>
    </div>
    <div>
      <slot name="top-content"></slot>
    </div>
    <scroll @end-reached="$emit('end-reached')">
      <div class=" p-0 m-0 listbox__items" :loading="loading">
        <div v-if="loading && !items.length" class="text-center p-3">{{loadingText}}</div>
        <div v-if="!loading && !items.length" class="text-center p-3">{{emptyText}}</div>
        <ul class="pl-0" v-if="items.length">
          <template v-if="draggable">
            <drag
              tag="li"
              :transfer-data="item"
              class="listbox-item"
              v-for="item in items"
              :class="{active: current === item.id}"
              :key="item.id"
              drop-effect="none"
              @click="$emit('click', item.id)"
              @mouseover.native="$emit('mouseover', item)"
              @mouseleave.native="$emit('mouseleave', item)"
            >
              <div class="listbox-item__title">
                <slot name="title" :item="item"></slot>
              </div>
              <div class="listbox-item__subtitle mb-1">
                <slot name="subtitle" :item="item"></slot>
              </div>
              <div class="listbox-item__subtitle mb-1">
                <slot name="description" :item="item"></slot>
              </div>
              <template slot="image"><div>&nbsp;</div></template>
            </drag>
          </template>
          <template v-if="!draggable">
            <li
              class="listbox-item"
              v-for="item in items"
              :class="{active: current === item.id}"
              :key="item.id"
              @click="$emit('click', item.id)"
              @mouseover="$emit('mouseover', item)"
              @mouseleave="$emit('mouseleave', item)"
            >
              <div class="listbox-item__title">
                <slot name="title" :item="item"></slot>
              </div>
              <div class="listbox-item__subtitle mb-1">
                <slot name="subtitle" :item="item"></slot>
              </div>
              <div class="listbox-item__subtitle mb-1">
                <slot name="description" :item="item"></slot>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </scroll>
  </div>
</template>

<script>
import { Drag } from 'vue-drag-drop';

export default {
  components: {
    Drag,
  },
  props: {
    current: {
      default: '',
    },
    items: {
      default: () => ([]),
    },
    loading: {
      default: false,
    },
    search: {
      default: '',
    },
    draggable: {
      default: false,
    },
    emptyText: {
      default: 'Нет данных',
    },
    searchText: {
      default: 'Поиск...',
    },
    loadingText: {
      default: 'Загрузка...',
    },
  },
};
</script>

<style scoped>
.listbox__items {
  flex: 1;
}
.listbox__items[loading] {
  opacity: 0.5;
}
.listbox__search {
  padding: 6px;
  border-bottom: 1px solid #e5e5e5;
}
.listbox-item {
  display: block;
  padding: 2px 12px 2px 6px;
  color: #333;
  cursor: pointer;
  background-color: #fff;
  transition: background-color .3s;
  min-height: 62px;
  border-bottom: 1px solid #e5e5e5;
  width: 279px;
}
.listbox-item.active {
  background-color: #eee;
}
.listbox-item:hover {
  background-color: #eaeef2;
}
.listbox-item__title {
  font-size: 16px;
}
.listbox-item__subtitle {
  font-size: 12px;
  color: #9b9b9b;
  line-height: 14px;
}
</style>
