<template>
  <b-modal
    centered
    :size="size"
    :ok-variant="okVariant"
    :ok-title="okTitle"
    :cancel-title="cancelTitle"
    :title="title"
    :visible="visible"
    :busy="busy"
    :hide-footer="hideFooter"
    @ok="ok"
    @hide="hide"
    @hidden="$emit('hidden')"
    @shown="$emit('shown', $event)"
  >
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
    },
    close: {
      type: Function,
      default: () => {},
    },
    save: {
      type: Function,
      default: () => {},
    },
    busy: {
      default: false,
    },
    hideFooter: {
      default: null,
    },
    okTitle: {
      default: 'Сохранить',
    },
    okVariant: {
      default: 'success',
    },
    cancelTitle: {
      default: 'Закрыть',
    },
    size: {
      default: 'lg',
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    async hide(event) {
      if (!event.trigger) {
        return;
      }

      event.preventDefault();

      if (event.trigger === 'ok') {
        return;
      }

      if (await this.close()) {
        this.visible = false;
      }
    },

    async ok(event) {
      event.preventDefault();

      if (await this.save()) {
        this.visible = false;
      }
    },
  },
};
</script>
