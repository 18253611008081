export default ({ checked }) => [
  {
    type: 'dropdown',
    text: 'Действия с выделенными',
    variant: 'outline-info',
    class: 'mr-2',
    bulk: true,
    disabled: checked && checked.length === 0,
    items: [
      {
        type: 'button',
        text: 'Отключить',
        action: 'disableCheckedDomains',
      },
      {
        type: 'button',
        text: 'Включить',
        action: 'enableCheckedDomains',
      },
      /*
      {
        type: 'button',
        text: 'Удалить',
        icon: 'fa-times',
        action: 'removeCheckedDomains',
        permission: 'email_blacklist_domains.edit',
      },
       */
    ],
  },
  {
    type: 'button',
    text: 'Добавить домен',
    variant: 'info',
    icon: 'fa-plus',
    class: 'mr-1',
    action: 'openCreateDomainForm',
    permission: 'email_blacklist_domains.edit',
  },
];
