<template>
  <div class="d-flex align-items-center">
    <b-button-group size="sm">
      <b-button
        variant="outline-info"
        :pressed="offset == OFFSET_DAY"
        :disabled="disabled"
        @click="setOffset(OFFSET_DAY)"
      >День</b-button>
      <b-button
        variant="outline-info"
        :pressed="offset == OFFSET_WEEK"
        :disabled="disabled"
        @click="setOffset(OFFSET_WEEK)"
      >Неделя</b-button>
      <b-button
        variant="outline-info"
        :pressed="offset == OFFSET_MONTH"
        :disabled="disabled"
        @click="setOffset(OFFSET_MONTH)"
      >Месяц</b-button>
      <b-button
        variant="outline-info"
        :pressed="offset == OFFSET_QUARTER"
        :disabled="disabled"
        @click="setOffset(OFFSET_QUARTER)"
      >Квартал</b-button>
      <b-button
        variant="outline-info"
        :pressed="offset == OFFSET_YEAR"
        :disabled="disabled"
        @click="setOffset(OFFSET_YEAR)"
      >Год</b-button>
    </b-button-group>

    <date-button
      class="mx-3"
      :date="date"
      :disabled="disabled"
      @select="setDate($event)"
    ></date-button>

    <span>Сравнить с</span>

    <date-button
      class="ml-2"
      :date="prevDate"
      :disabled="disabled"
      :pressed="!offset"
      @select="setPrevDate($event)"
    ></date-button>
  </div>
</template>

<script>
import DateButton from '../../../components/fields/DateButton.vue';

import {
  OFFSET_DAY, OFFSET_WEEK, OFFSET_MONTH, OFFSET_QUARTER, OFFSET_YEAR, getOffsetDate, getOffset,
} from './dates';

export default {
  components: {
    DateButton,
  },
  props: {
    value: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      date: null,
      prevDate: null,
      offset: null,
      OFFSET_DAY,
      OFFSET_WEEK,
      OFFSET_MONTH,
      OFFSET_QUARTER,
      OFFSET_YEAR,
    };
  },
  watch: {
    value: {
      handler() {
        this.init();
      },
      immediate: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.date = this.value.length ? this.value[this.value.length - 1] : new Date();
      this.prevDate = this.value.length > 1 ? this.value[this.value.length - 2] : null;
      this.offset = getOffset(this.value);
    },

    emitValue() {
      this.$emit('change', [this.prevDate, this.date]);
    },

    setOffset(value) {
      this.offset = value;
      this.prevDate = getOffsetDate(this.date, this.offset);
      this.emitValue();
    },

    setDate(value) {
      this.date = value;

      if (this.offset) {
        this.prevDate = getOffsetDate(this.date, this.offset);
      }

      this.emitValue();
    },

    setPrevDate(value) {
      this.prevDate = value;
      this.offset = null;
      this.emitValue();
    },
  },
};
</script>
