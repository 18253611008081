<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Наименование:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        autofocus
        type="text"
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Описание:"
      :invalid-feedback="errors.description"
      :state="states.description"
    >
      <b-form-input
        size="sm"
        :value="item.description"
        :state="states.description"
        :disabled="loading"
        @change="updateValue('description', $event)"
      ></b-form-input>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Характеристики промокодов</div>
    </div>

    <b-form-group
      label="Изменение цены:"
      :invalid-feedback="errors.formula"
      :state="states.formula"
    >
      <price-formula
        :value="item.formula"
        :operations="['-', '=']"
        :disabled="loading"
        @change="updateValue('formula', $event)"
      ></price-formula>
    </b-form-group>

    <b-form-group
      label="Вариант применения промокода"
      :invalid-feedback="errors.useMode"
      :state="states.useMode"
    >
      <dictionary-provider name="promocodeUseModes">
        <template #default="{ items }">
          <b-form-radio-group
            name="useMode"
            stacked
            :state="states.useMode"
            :checked="item.useMode"
            :disabled="loading"
            @change="updateValue('useMode', $event)"
          >
            <b-form-radio
              v-for="item in items"
              :key="item.ud"
              :value="item.id"
            >{{ item.name }}</b-form-radio>
          </b-form-radio-group>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Место действия промокодов</div>
    </div>

    <b-form-group
      label="Кинотеатры:"
      :invalid-feedback="errors.cinemas"
      :state="states.cinemas"
    >
      <dictionary-provider name="cinemas">
        <template #default="{ items, loading: dictionaryLoading }">
          <toggle-list
            placeholder="Все"
            :options="convertCinemas($array.union(items, item.cinemas))"
            :value="item.cinemas"
            :disabled="loading || dictionaryLoading"
            @change="updateValue('cinemas', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Категории залов:"
      :invalid-feedback="errors.hallCategories"
      :state="states.hallCategories"
    >
      <dictionary-provider name="hallCategories">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.hallCategories"
            :disabled="loading"
            @change="updateValue('hallCategories', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Группы приложений:"
      :invalid-feedback="errors.appGroups"
      :state="states.appGroups"
    >
      <dictionary-provider name="appGroups">
        <template #default="{ items: appGroups }">
          <toggle-list
            placeholder="Все"
            :options="appGroups"
            :value="item.appGroups"
            :disabled="loading"
            @change="updateValue('appGroups', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Фильтры фильмов, для которых действуют промокоды</div>
    </div>

    <b-form-group
      label="Возрастное ограничение фильма:"
      :invalid-feedback="errors.ageLimit"
      :state="states.ageLimit"
    >
      <dictionary-provider name="ageLimits">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.ageLimits"
            :disabled="loading"
            @change="updateValue('ageLimits', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Форматы фильмов:"
      :invalid-feedback="errors.formats"
      :state="states.formats"
    >
      <dictionary-provider name="formats2">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.formats"
            :disabled="loading"
            @change="updateValue('formats', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Релизы:"
      :invalid-feedback="errors.releases"
      :state="states.releases"
    >
      <dictionary-provider
        name="showsReleases"
        :filter="{
          formatIds: $array.getValues(item.formats, 'id'),
        }"
      >
        <template #default="{ items, loading: dictionaryLoading }">
          <toggle-list
            placeholder="Все"
            :options="convertReleases(items)"
            :value="item.releases"
            :searchable="true"
            :disabled="loading || dictionaryLoading"
            @change="updateValue('releases', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Период действия скидки по промокоду</div>
    </div>

    <b-form-group
      label="Начало сеанса попадает в указанный период:"
      :invalid-feedback="errors.showDatetimeEnd || errors.showDatetimeStart"
      :state="states.showDatetimeEnd || states.showDatetimeStart"
    >
      <date-time-interval
        :value="{from: item.showDatetimeStart, to: item.showDatetimeEnd}"
        :disabled="loading"
        @selected="updateValue('showDatetimeStart', $event.from), updateValue('showDatetimeEnd', $event.to)">
      </date-time-interval>
    </b-form-group>

    <b-form-group
      label="Время начала сеанса попадает в указанный период:"
      :invalid-feedback="errors.showTimeStart || errors.showTimeEnd"
      :state="states.showTimeStart || states.showTimeEnd"
    >
      <time-interval
        :value="{from: item.showTimeStart, to: item.showTimeEnd}"
        :disabled="loading"
        @change="updateValue('showTimeStart', $event.from), updateValue('showTimeEnd', $event.to)"
      ></time-interval>
    </b-form-group>

    <b-form-group
      label="Дни недели сеансов:"
      :invalid-feedback="errors.days"
      :state="states.days"
    >
      <toggle-list
        placeholder="Все"
        :options="days"
        :value="item.days"
        :disabled="loading"
        @change="updateValue('days', $event)"
      ></toggle-list>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Срок действия промокодов</div>
    </div>

    <b-form-group
      label="Дата и время начала и окончания действия промокодов:"
      :invalid-feedback="errors.datetimeEnd || errors.datetimeStart"
      :state="states.datetimeEnd || states.datetimeStart"
    >
      <date-time-interval
        :value="{from: item.datetimeStart, to: item.datetimeEnd}"
        :disabled="loading"
        @selected="updateValue('datetimeStart', $event.from), updateValue('datetimeEnd', $event.to)">
      </date-time-interval>
    </b-form-group>

    <b-form-group
      label="Максимально возможное количество активаций каждого промокода:"
      :invalid-feedback="errors.activationsLimit"
      :state="states.activationsLimit"
    >
      <b-form-input
        type="number"
        size="sm"
        :value="item.activationsLimit"
        :state="states.activationsLimit"
        :disabled="loading"
        @change="updateValue('activationsLimit', $event)"
      ></b-form-input>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Генерация промокодов</div>
    </div>

    <b-form-group
      label="Количество промокодов:"
      :invalid-feedback="errors.promocodesCount"
      :state="states.promocodesCount"
    >
      <b-form-input
        type="number"
        size="sm"
        :value="item.promocodesCount"
        :state="states.promocodesCount"
        :disabled="loading || !!item.id"
        @change="updateValue('promocodesCount', $event)"
      ></b-form-input>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group
          label="Префикс:"
          :invalid-feedback="errors.prefix"
          :state="states.prefix"
        >
          <b-form-input
            type="text"
            size="sm"
            ref="prefix"
            :value="item.prefix"
            :state="states.prefix"
            :disabled="loading || !!item.id"
            @keyup.native="updatePrefix($event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Длина случайной части:"
          :invalid-feedback="errors.numberLength"
          :state="states.numberLength"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.numberLength"
            :state="states.numberLength"
            :disabled="loading || !!item.id"
            @change="updateValue('numberLength', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Набор символов:"
          :invalid-feedback="errors.numberCharset"
          :state="states.numberCharset"
        >
          <b-form-select
            size="sm"
            :value="item.numberCharset"
            :disabled="loading || !!item.id"
            @change="updateValue('numberCharset', $event)"
          >
            <b-form-select-option value="1">Только буквы</b-form-select-option>
            <b-form-select-option value="2">Только цифры</b-form-select-option>
            <b-form-select-option value="3">Буквы и цифры</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';
import DateTimeInterval from '../../components/fields/DateTimeInterval.vue';
import TimeInterval from '../../components/fields/TimeInterval.vue';
import PriceFormula from '../../components/fields/PriceFormula.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
    DateTimeInterval,
    TimeInterval,
    PriceFormula,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      days: [
        { id: 1, name: 'ПН' },
        { id: 2, name: 'ВТ' },
        { id: 3, name: 'СР' },
        { id: 4, name: 'ЧТ' },
        { id: 5, name: 'ПТ' },
        { id: 6, name: 'СБ' },
        { id: 7, name: 'ВС' },
      ],
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    convertReleases(releases) {
      if (!releases) {
        return [];
      }

      return releases.map((release) => ({
        id: release.id,
        name: release?.format?.name ? `${release?.movie?.name}, ${release?.format?.name}` : release?.movie?.name,
      }));
    },

    updatePrefix(e) {
      const value = e.target.value.toUpperCase();

      if (value && !/^[0-9A-Z]+$/.test(value)) {
        this.$refs.prefix.$refs.input.value = this.item.prefix || '';
        return;
      }

      this.updateValue('prefix', value);
    },
  },
};
</script>

<style scoped>
.maw-width-80px {
  max-width: 80px;
}
</style>
