var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 d-flex flex-column movies"},[_c('b-row',{staticClass:"p-2 border-bottom shadow-sm"},[_c('b-col',[_c('movies-filters')],1),_c('b-col',{attrs:{"cols":"auto"}},[(_vm.$user.can('movies.edit'))?_c('b-button',{attrs:{"size":"sm","variant":"info"},on:{"click":_vm.addMovie}},[_vm._v("Добавить фильм")]):_vm._e()],1)],1),_c('div',{staticClass:"movies__wrap"},[(_vm.moviesLoading && !_vm.movies.length)?_c('div',{staticClass:"movies__loader"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e(),(!!_vm.movies.length)?_c('scroll',{on:{"end-reached":_vm.onNextMonth}},[(_vm.filter.dateStartGte)?_c('div',{staticClass:"d-flex justify-content-center pt-3"},[_c('div',{staticClass:"d-flex"},[(!!_vm.movies.length && _vm.loadingPrevItems)?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"light","disabled":_vm.moviesLoading},on:{"click":_vm.onPrevMonth}},[_vm._v("к предыдущему месяцу")]):_vm._e()],1)]):_vm._e(),_vm._l((_vm.dates),function(date,index){return _c('movies-group',{key:("movies_group_" + index),staticClass:"p-3",attrs:{"date":date}},[_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","hover":"","thead-class":"movies__thead","items":_vm.getMoviesForDate(date),"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [(!!data.item.releases.length)?_c('icon',{staticClass:"movies__favorite",class:{ 'selected': _vm.favoriteEnabled(data.item.releases) },attrs:{"icon":"fa-star"},nativeOn:{"click":function($event){return _vm.openFavoriteMovieModal(data.item)}}}):_vm._e()]}},{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"movies__poster",style:(("background-image: url(" + (data.item.posterUrl || _vm.noPhoto) + ");"))}),_c('div',[_c('div',[_c('router-link',{attrs:{"id":("movie_" + (data.item.id)),"to":{
                      name: 'movie2',
                      params: {
                        movieId: data.item.id,
                        tab: 'info',
                      },
                      query: _vm.filter,
                    }}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1),(data.item.nameEn)?_c('div',{staticClass:"text-small"},[_vm._v(_vm._s(data.item.nameEn))]):_vm._e()])])]}},{key:"cell(releases)",fn:function(data){return _vm._l((data.item.releases),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item.format.name)+" - "+_vm._s(item.duration)+" мин. ")])})}},{key:"cell(ageLimit)",fn:function(data){return [_c('div',[_vm._v(_vm._s(data.item.ageLimit)+"+")])]}},{key:"cell(distributor)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.distributor && data.item.distributor.name)+" ")]}},{key:"cell(laboratories)",fn:function(data){return _vm._l((data.item.laboratories),function(laboratory,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(laboratory.name)+" ")])})}},{key:"cell(memorandumPeriodEnd)",fn:function(ref){
                    var memorandumPeriodEnd = ref.item.memorandumPeriodEnd;
return [_vm._v(" "+_vm._s(memorandumPeriodEnd && _vm.datetime.formatDate(memorandumPeriodEnd))+" ")]}},{key:"cell(datePreview)",fn:function(ref){
                    var datePreview = ref.item.datePreview;
return [_vm._v(" "+_vm._s(datePreview && _vm.datetime.formatDate(datePreview))+" ")]}},{key:"cell(dateStart)",fn:function(ref){
                    var ref_item = ref.item;
                    var dateStart = ref_item.dateStart;
                    var dateEnd = ref_item.dateEnd;
return [_vm._v(" "+_vm._s(dateStart && _vm.datetime.formatDate(dateStart))+" "),(dateEnd)?[_vm._v(" - "+_vm._s(_vm.datetime.formatDate(dateEnd))+" ")]:_vm._e()]}}],null,true)})],1)}),(_vm.moviesLoading)?_c('div',{staticClass:"text-center pt-2 pb-2"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e()],2):_vm._e(),(!_vm.movies.length)?_c('div',{staticClass:"text-center pt-2 pb-2"},[_vm._v(" Нет фильмов для выбранных условий ")]):_vm._e()],1),(_vm.modals.favorite.show)?_c('movies-favorite-modal',{attrs:{"movie":_vm.modals.favorite.movie},on:{"saved":function($event){return _vm.fetchItemsByParams(_vm.filter, _vm.modals.favorite.movie.id)},"close":_vm.closeFavoriteMovieModal}}):_vm._e(),(_vm.modals.movie.show)?_c('modal-model-form',{attrs:{"create-title":"Новый фильм","edit-title":"Редактирование фильма","collection":"movies2","init":{ useBonuses: true }},on:{"close":function($event){_vm.modals.movie.show = false},"save":_vm.init},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var data = ref.data;
                    var actions = ref.actions;
return [_c('movie-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,false,855252933)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }