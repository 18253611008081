<template>
  <div>
    <table
      class="table border table-striped w-100"
      :style="{'opacity': disabled ? 0.5 : 1}"
    >
      <thead>
        <tr>
          <th></th>
          <th>По базе</th>
          <th>Всего</th>
          <th>Доля</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Количество клиентов</td>
          <td>{{ customersCount.toLocaleString() }}</td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Количество посещений (заказов)</td>
          <td>{{ customersOrdersCount.toLocaleString() }}</td>
          <td>{{ allOrdersCount.toLocaleString() }}</td>
          <td>{{ calculatePercent(customersOrdersCount, allOrdersCount) }}%</td>
        </tr>

        <tr>
          <td>Среднее количество билетов в заказе</td>
          <td>{{ roundValue(customersTicketsCount / customersOrdersCount) }}</td>
          <td>{{ roundValue(allTicketsCount / allOrdersCount) }}</td>
          <td></td>
        </tr>

        <tr>
          <td>Количество билетов</td>
          <td>{{ customersTicketsCount.toLocaleString() }}</td>
          <td>{{ allTicketsCount.toLocaleString() }}</td>
          <td>{{ calculatePercent(customersTicketsCount, allTicketsCount) }}%</td>
        </tr>

        <tr>
          <td>Сумма, руб</td>
          <td>{{ customersTicketsPrice.toLocaleString() }}</td>
          <td>{{ allTicketsPrice.toLocaleString() }}</td>
          <td>{{ calculatePercent(customersTicketsPrice, allTicketsPrice) }}%</td>
        </tr>

        <tr>
          <td>Средняя цена билета, руб</td>
          <td>{{ roundValue(customersTicketsPrice / customersTicketsCount) }}</td>
          <td>{{ roundValue(allTicketsPrice / allTicketsCount) }}</td>
          <td></td>
        </tr>

        <tr>
          <td>Средняя частота посещений</td>
          <td>{{ roundValue(customersOrdersCount / customersCount) }}</td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Средняя стоимость заказа (AOV)</td>
          <td>{{ roundValue(customersTicketsPrice / customersOrdersCount) }}</td>
          <td>{{ roundValue(allTicketsPrice / allOrdersCount) }}</td>
          <td></td>
        </tr>

        <tr>
          <td>Средняя прибыль с клиента (ARPPU)</td>
          <td>{{ roundValue(customersTicketsPrice / customersCount) }}</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  props: {
    disabled: {
      default: false,
    },
    allTicketsCount: {
      default: 0,
    },
    allTicketsPrice: {
      default: 0,
    },
    allOrdersCount: {
      default: 0,
    },
    customersTicketsCount: {
      default: 0,
    },
    customersTicketsPrice: {
      default: 0,
    },
    customersCount: {
      default: 0,
    },
    customersOrdersCount: {
      default: 0,
    },
  },
  methods: {
    roundValue(value, precision = 2) {
      const multiplier = 10 ** (precision || 0);

      return Math.round(value * multiplier) / multiplier;
    },

    calculatePercent(value1, value2) {
      return value2 ? this.roundValue((value1 / value2) * 100) : 0;
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  overflow-wrap: break-word;
}
.table th {
  font-weight: 500;
}
</style>
