<template>
  <analytics-sales-provider
    :cinema-id="cinemaId"
    :periods="periods"
  >
    <template #default="{
      data: { appGroups, appGroupsColors },
      actions: { getAnalyticsData, getTargetData, getMarketData, splitAnalyticsDataByAppGroups },
    }">
      <div>
        <b-row>
          <b-col md="2">
            <small-fill-chart
              class="h-100"
              title="Кино, вал"
              :data="getAnalyticsData(ANALYTICS_TICKETS_PRICE)"
              :line-color="'#14CC70'"
              :fill-color="'rgba(20, 204, 112, 0.2)'"
            ></small-fill-chart>
          </b-col>

          <b-col md="6">
            <wide-bar-chart
              class="h-100"
              tooltip-title="Кино, вал"
              :periods="formattedPeriods"
              :actual-data="getAnalyticsData(ANALYTICS_TICKETS_PRICE)"
              :target-data="getTargetData(TARGET_TICKETS_PRICE)"
              :backgroundColor="'rgba(20, 204, 112, 0.2)'"
              :label-color="'#14CC70'"
            >
              <template #right>
                <b-button-group size="sm">
                  <b-button
                    variant="outline-info"
                    @click="$emit('remove-period')"
                  >-</b-button>
                  <b-button
                    variant="outline-info"
                    @click="$emit('add-period')"
                  >+</b-button>
                </b-button-group>
              </template>
            </wide-bar-chart>
          </b-col>

          <b-col md="4">
            <percents-chart
              class="h-100"
              title="Каналы продаж по валу"
              :labels="$array.getValues(appGroups)"
              :data="splitAnalyticsDataByAppGroups(ANALYTICS_TICKETS_PRICE)"
              :periods="formattedPeriods"
              :colors="appGroupsColors"
            >
            </percents-chart>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col md="2">
            <small-fill-chart
              class="h-100"
              title="Кино, билеты"
              :data="getAnalyticsData(ANALYTICS_TICKETS_COUNT)"
              :line-color="'#14CC70'"
              :fill-color="'rgba(20, 204, 112, 0.2)'"
            ></small-fill-chart>
          </b-col>

          <b-col md="6">
            <wide-bar-chart
              class="h-100"
              tooltip-title="Кино, билеты"
              :periods="formattedPeriods"
              :actual-data="getAnalyticsData(ANALYTICS_TICKETS_COUNT)"
              :target-data="getTargetData(TARGET_TICKETS_COUNT)"
              :backgroundColor="'rgba(20, 204, 112, 0.2)'"
              :label-color="'#14CC70'"
            >
              <template #right>
                <b-button-group size="sm">
                  <b-button
                    variant="outline-info"
                    @click="$emit('remove-period')"
                  >-</b-button>
                  <b-button
                    variant="outline-info"
                    @click="$emit('add-period')"
                  >+</b-button>
                </b-button-group>
              </template>
            </wide-bar-chart>
          </b-col>

          <b-col md="4">
            <percents-chart
              class="h-100"
              title="Каналы продаж по билетам"
              :labels="$array.getValues(appGroups)"
              :data="splitAnalyticsDataByAppGroups(ANALYTICS_TICKETS_COUNT)"
              :periods="formattedPeriods"
              :colors="appGroupsColors"
            >
            </percents-chart>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col md="2">
            <small-fill-chart
              class="h-100"
              title="Доля рынка, вал"
              unit="%"
              :data="$array.convertToPercents(
                $array.divideArrays(
                  getAnalyticsData(ANALYTICS_TICKETS_PRICE),
                  getMarketData(MARKET_TICKETS_PRICE),
                  '',
                  false
                )
              )"
            ></small-fill-chart>
          </b-col>

          <b-col md="6">
            <wide-bar-chart
              class="h-100"
              tooltip-title="Доля рынка, вал"
              unit="%"
              :periods="formattedPeriods"
              :actual-data="$array.convertToPercents(
                $array.divideArrays(
                  getAnalyticsData(ANALYTICS_TICKETS_PRICE),
                  getMarketData(MARKET_TICKETS_PRICE),
                  '',
                  false
                )
              )"
              :target-data="getTargetData(TARGET_TICKETS_PRICE_PERCENT)"
            >
              <template #right>
                <b-button-group size="sm">
                  <b-button
                    variant="outline-info"
                    @click="$emit('remove-period')"
                  >-</b-button>
                  <b-button
                    variant="outline-info"
                    @click="$emit('add-period')"
                  >+</b-button>
                </b-button-group>
              </template>
            </wide-bar-chart>
          </b-col>

          <b-col md="4">
            <small-line-chart
              class="h-100"
              title="Ср. цена"
              y-label=""
              aspect-ratio="3"
              :y-auto-scale="true"
              :data="$array.divideArrays(
                getAnalyticsData(ANALYTICS_TICKETS_PRICE),
                getAnalyticsData(ANALYTICS_TICKETS_COUNT),
              )"
              :periods="formattedPeriods"
            ></small-line-chart>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col md="2">
            <small-fill-chart
              class="h-100"
              title="Доля рынка, билеты"
              unit="%"
              :data="$array.convertToPercents(
                $array.divideArrays(
                  getAnalyticsData(ANALYTICS_TICKETS_COUNT),
                  getMarketData(MARKET_TICKETS_COUNT),
                  '',
                  false
                )
              )"
            ></small-fill-chart>
          </b-col>

          <b-col md="6">
            <wide-bar-chart
              class="h-100"
              tooltip-title="Доля рынка, билеты"
              unit="%"
              :periods="formattedPeriods"
              :actual-data="$array.convertToPercents(
                $array.divideArrays(
                  getAnalyticsData(ANALYTICS_TICKETS_COUNT),
                  getMarketData(MARKET_TICKETS_COUNT),
                  '',
                  false
                )
              )"
              :target-data="getTargetData(TARGET_TICKETS_COUNT_PERCENT)"
            >
              <template #right>
                <b-button-group size="sm">
                  <b-button
                    variant="outline-info"
                    @click="$emit('remove-period')"
                  >-</b-button>
                  <b-button
                    variant="outline-info"
                    @click="$emit('add-period')"
                  >+</b-button>
                </b-button-group>
              </template>
            </wide-bar-chart>
          </b-col>

        </b-row></div>
    </template>
  </analytics-sales-provider>
</template>

<script>

import {
  AnalyticsSalesProvider,
  ANALYTICS_TICKETS_PRICE,
  ANALYTICS_TICKETS_COUNT,
  ANALYTICS_PRODUCTS_PRICE,
  ANALYTICS_PRODUCTS_COUNT,
  TARGET_TICKETS_COUNT_PERCENT,
  TARGET_TICKETS_PRICE_PERCENT,
  TARGET_TICKETS_PRICE,
  TARGET_TICKETS_COUNT,
  TARGET_PRODUCTS_PRICE,
  TARGET_PRODUCTS_COUNT,
  MARKET_TICKETS_PRICE,
  MARKET_TICKETS_COUNT,
} from '../../../providers/AnalyticsSalesProvider';

import SmallFillChart from '../components/SmallFillChart.vue';
import WideBarChart from '../components/WideBarChart.vue';
import PercentsChart from '../components/PercentsChart.vue';
import SmallLineChart from '../components/SmallLineChart.vue';

export default {
  components: {
    AnalyticsSalesProvider,
    SmallFillChart,
    WideBarChart,
    PercentsChart,
    SmallLineChart,
  },
  props: {
    cinemaId: {
      default: '',
    },
    periods: {
      default: () => [],
    },
    formattedPeriods: {
      default: () => [],
    },
  },
  data() {
    return {
      ANALYTICS_TICKETS_PRICE,
      ANALYTICS_TICKETS_COUNT,
      ANALYTICS_PRODUCTS_PRICE,
      ANALYTICS_PRODUCTS_COUNT,
      TARGET_TICKETS_COUNT_PERCENT,
      TARGET_TICKETS_PRICE_PERCENT,
      TARGET_TICKETS_PRICE,
      TARGET_TICKETS_COUNT,
      TARGET_PRODUCTS_PRICE,
      TARGET_PRODUCTS_COUNT,
      MARKET_TICKETS_PRICE,
      MARKET_TICKETS_COUNT,
    };
  },
};
</script>

<style scoped>
</style>
