var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cinema-page',{attrs:{"route-name":"repertory","route-param-name":"cinemaId"},on:{"loaded":function($event){return _vm.setCinema($event)}},scopedSlots:_vm._u([{key:"fixed",fn:function(ref){
var cinemaId = ref.cinemaId;
var cinema = ref.cinema;
return [(cinemaId && _vm.dateStart && _vm.dateEnd)?_c('collection-provider',{ref:"collection",attrs:{"collection":"repertoryItems","filter":{ cinemaId: cinemaId, dateStart: _vm.dateStartInDbFormat, dateEnd: _vm.dateEndInDbFormat },"disable-fetching":!cinemaId || !_vm.dateStartInDbFormat || !_vm.dateEndInDbFormat},on:{"loaded":function($event){return _vm.calculateOccupancy(cinema, _vm.weeks, $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_data = ref.data;
var items = ref_data.items;
var loading = ref_data.loading;
return [_c('multi-view',{attrs:{"scroll-side":false},scopedSlots:_vm._u([{key:"side",fn:function(){return [_c('releases',{attrs:{"cinema-id":cinemaId,"formats":_vm.cinemaFormats,"date-start":_vm.dateStart,"date-end":_vm.dateEnd}})]},proxy:true},{key:"panel",fn:function(){return [_c('buttons',{attrs:{"calendar-type":_vm.calendarType,"view-mode":_vm.viewMode,"disabled":loading},on:{"change-calendar-type":function($event){return _vm.changeCalendarType($event)},"change-view-mode":function($event){return _vm.changeViewMode($event)}}})]},proxy:true},{key:"subpanel",fn:function(){return [_c('calendar',[_c('calendar-period',{attrs:{"weeks":_vm.weeks,"calendar-type":_vm.calendarType},on:{"prev-month":function($event){return _vm.setPrevMonth()},"next-month":function($event){return _vm.setNextMonth()},"prev-week":function($event){return _vm.setPrevWeek()},"next-week":function($event){return _vm.setNextWeek()}}}),_c('calendar-stat',{attrs:{"weeks":_vm.weeks,"weeks-capacity":_vm.weeksCapacity,"weeks-charge":_vm.weeksCharge}}),_c('calendar-days',{attrs:{"weeks":_vm.weeks}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('timeline',{attrs:{"items":items,"statuses":_vm.repertoryItemStatuses,"weeks":_vm.weeks,"loading":loading,"view-mode":_vm.viewMode},on:{"add-item":function($event){return _vm.addRepertoryItem($event)},"click-item":function($event){return _vm.openRepertoryItem($event)},"change-date-start":function($event){return _vm.changeRepertoryItemDateStart($event)},"change-date-end":function($event){return _vm.changeRepertoryItemDateEnd($event)}},scopedSlots:_vm._u([{key:"release",fn:function(ref){
var repertoryItem = ref.repertoryItem;
return [_c('timeline-release',{attrs:{"release":repertoryItem.release}})]}},{key:"counter",fn:function(ref){
var repertoryItem = ref.repertoryItem;
var week = ref.week;
return [_vm._v(" "+_vm._s(_vm.getItemChargePercent(repertoryItem.release.duration, week.count, _vm.findWeekIndex(_vm.weeks, week)))+" "),(week.count)?[_vm._v("("+_vm._s(week.count)+")")]:_vm._e()]}}],null,true)})]},proxy:true},{key:"portal",fn:function(){return [(_vm.modals.item.show)?_c('modal-repertory-item-form',{attrs:{"id":_vm.modals.item.id},on:{"hide":function($event){_vm.modals.item.show = false},"saved":function($event){return _vm.fetchRepertoryItems()},"removed":function($event){return _vm.fetchRepertoryItems()}}}):_vm._e()]},proxy:true}],null,true)})]}}],null,true)}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }