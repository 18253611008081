<template>
  <div class="table-container">
    <collection-provider
      collection="cinemaSocialNetworks"
      ref="collection"
      :filter="{ cinemaId }"
      :disable-fetching="!cinemaId"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @item-action="performItemAction($event)"
        >
          <template #cell(name)="data">
            <div>{{data.item.name}}</div>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.cinemaSocialNetwork.show"
      create-title="Создание соцсети кинотеатра"
      edit-title="Редактирование соцсети кинотеатра"
      collection="cinemaSocialNetworks"
      :id="modals.cinemaSocialNetwork.id"
      :init="{ cinemaId }"
      @close="modals.cinemaSocialNetwork.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <cinema-social-network-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></cinema-social-network-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_cinemaSocialNetworks.fields';
import itemActions from './_cinemaSocialNetworks.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import CinemaSocialNetworkForm from './CinemaSocialNetworksForm.vue';

export default {
  components: {
    CollectionProvider,
    ModalModelForm,
    Datatable,
    CinemaSocialNetworkForm,
  },
  props: {
    cinemaId: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
      },
      modals: {
        cinemaSocialNetwork: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateCinemaSocialNetworkForm() {
      this.modals.cinemaSocialNetwork.id = '';
      this.modals.cinemaSocialNetwork.show = true;
    },

    openEditCinemaSocialNetworkForm(id) {
      this.modals.cinemaSocialNetwork.id = id;
      this.modals.cinemaSocialNetwork.show = true;
    },

    async removeCinemaSocialNetwork(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>
