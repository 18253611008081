<template>
  <div class="pt-3">
    <b-table
      caption-top
      class="mb-0"
      :items="convertItems"
      :fields="fields"
    >
      <template #cell(usedAt)="data">
        <div v-html="data.item.usedAt"></div>
      </template>
      <template #cell(refundedAt)="data">
        <div v-html="data.item.refundedAt"></div>
      </template>
      <template #cell(price)="data">
        <div v-html="data.item.price"></div>
      </template>
      <template #cell(discount)="data">
        <div v-html="data.item.discount"></div>
      </template>
      <template #cell(bonuses)="data">
        <div v-html="data.item.bonuses"></div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: null,
    },
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Название' },
        { key: 'description', label: 'Детали' },
        { key: 'usedAt', label: 'Использован' },
        { key: 'refundedAt', label: 'Возвращен' },
        { key: 'price', label: 'Итоговая цена' },
        { key: 'discount', label: 'Скидка' },
        { key: 'promocode', label: 'Скидка по промокоду' },
        { key: 'certificate', label: 'Погашено сертификатом' },
        { key: 'bonuses', label: 'Бонусы' },
      ],
    };
  },
  computed: {
    convertItems() {
      if (!this.item?.tickets || !this.item?.products || !this.item?.certificates) {
        return [];
      }

      const tickets = this.item.tickets
        .map((item) => ({
          name: `Билет ${item.id}`,
          description: `${Math.floor(item.placeId / 1000)} ряд ${+item.placeId % 1000} место`,
          usedAt: item.usedAt ? `${this.$datetime.format(item.usedAt, this.$datetime.datetimeFormat)} ${item.useEmployee ? `<span class="text-secondary">(${item.useEmployee.name})</span>` : ''}` : '—',
          refundedAt: item.refundedAt ? `<span class="text-danger">${this.$datetime.format(item.refundedAt, this.$datetime.datetimeFormat)}</span>` : '—',
          price: `${this.formatPrice(item.price)} ${item.promotion ? `<span class="text-secondary">(${item.promotion.name})</span>` : ''}`,
          discount: `${this.formatPrice(item.discount)} ${item.discountInfo ? `<span class="text-secondary">(${item.discountInfo.name})</span>` : ''}`,
          promocode: `${+item.promocode}`,
          certificate: `${+item.certificate}`,
          bonuses: `<span class="text-nowrap">${this.formatBonuses(item.bonusesAdd, item.bonusesSub)}</span>`,
        }));
      const products = this.item.products
        .map((item) => ({
          name: `Товар`,
          description: `${item.product.name}`,
          usedAt: item.usedAt ? `${this.$datetime.format(item.usedAt, this.$datetime.datetimeFormat)} ${item.useEmployee ? `<span class="text-secondary">(${item.useEmployee.name})</span>` : ''}` : '—',
          refundedAt: item.refundedAt ? `<span class="text-danger">${this.$datetime.format(item.refundedAt, this.$datetime.datetimeFormat)}</span>` : '—',
          price: `${this.formatPrice(item.price)} ${item.promotion ? `<span class="text-secondary">(${item.promotion.name})</span>` : ''}`,
          discount: `${this.formatPrice(item.discount)} ${item.discountInfo ? `<span class="text-secondary">(${item.discountInfo.name})</span>` : ''}`,
          promocode: '—',
          certificate: '—',
          bonuses: '—',
        }));
      const certificates = this.item.certificates
        .map((item) => ({
          name: `Сертификат`,
          description: `${item.certificate.number}`,
          usedAt: '—',
          refundedAt: item.refundedAt ? `<span class="text-danger">${this.$datetime.format(item.refundedAt, this.$datetime.datetimeFormat)}</span>` : '—',
          price: `${this.formatPrice(item.price)}`,
          discount: '—',
          promocode: '—',
          certificate: '—',
          bonuses: '—',
        }));

      return [
        ...tickets,
        ...products,
        ...certificates,
      ];
    },
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat("ru-RU").format(price);
    },
    formatBonuses(bonusesAdd, bonusesSub) {
      let elements = [];

      if (bonusesAdd) {
        elements.push('<span class="text-success">+' + bonusesAdd + '</span>');
      }

      if (bonusesSub) {
        elements.push('<span class="text-danger">-' + bonusesSub + '</span>');
      }

      return elements.join(' / ');
    }
  }
};
</script>

<style scoped>
.table >>> th {
  font-weight: 400;
  font-size: 14px;
}
.table >>> td {
  font-weight: 300;
  font-size: 14px;
}
</style>
