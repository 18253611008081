<template>
  <div class="table-container certificates">
    <collection-provider
      collection="certificates"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <dictionaries-provider :names="['certificateGroups', 'certificateUseModes', 'appGroups', 'organizations']">
          <template #default="{ certificateGroups, certificateUseModes, appGroups, organizations }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :panel-actions="datatable.panelActions"
              :item-actions="datatable.itemActions"
              :checked="datatable.checked"
              :permissions="$user.permissions"
              @change-checked="datatable.checked = $event"
              @panel-action="performPanelAction($event)"
              @item-action="performItemAction($event)"
            >
              <template #filter(certificateGroupId)>
                <b-form-select
                  size="sm"
                  :value="data.filter.certificateGroupId"
                  :options="$array.convertToOptions(certificateGroups)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('certificateGroupId', $event)"
                ></b-form-select>
              </template>

              <template #filter(useMode)>
                <b-form-select
                  size="sm"
                  :value="data.filter.certificateGroupId"
                  :options="$array.convertToOptions(certificateUseModes, { value: null, text: '' }, 'id', 'altName')"
                  :disabled="data.loading"
                  @change="actions.changeFilter('useMode', $event)"
                ></b-form-select>
              </template>

              <template #filter(organizationId)>
                <b-form-select
                  size="sm"
                  :value="data.filter.organizationId"
                  :options="$array.convertToOptions(organizations)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('organizationId', $event)"
                ></b-form-select>
              </template>

              <template #cell(certificateGroupId)="data">
                {{$array.getItemText(certificateGroups, data.item.certificateGroupId)}}
              </template>

              <template #cell(useMode)="data">
                {{$array.getItemText(certificateUseModes, data.item.certificateGroup.useMode, 'altName')}}
              </template>

              <template #cell(organizationId)="data">
                {{$array.getItemText(organizations, data.item.certificateGroup.organizationId)}}
              </template>

              <template #cell(nominal)="data">
                {{ data.item.certificateGroup ? data.item.certificateGroup.nominal : '' }}
              </template>

              <template #cell(numberPart)="data">
                {{ data.item.number }}
              </template>

              <template #cell(orderAppGroupId)="data">
                {{$array.getItemText(appGroups, data.item.orderAppGroupId)}}
              </template>

              <template #cell(balance)="data">
                <a
                  class="text-primary cursor-pointer"
                  @click="openCertificateOperations(data.item)"
                >{{ data.item.balance }}</a>
              </template>

              <template #cell(invalidateDate)="data">
                {{ data.item.invalidateDate ? data.item.invalidateDate : '' }}
              </template>

              <template #cell(activatedAt)="data">
                {{ data.item.activatedAt ?  $datetime.formatDateTime(data.item.activatedAt) : '' }}
              </template>

              <template #cell(status)="data">
                <div v-if="
                  data.item.activatedAt
                  && !data.item.closedAt
                  && !data.item.refundedAt
                ">Активирован</div>
                <div v-if="
                  data.item.invalidateDate
                  && !$datetime.isTimeBefore($datetime.now(), data.item.invalidateDate)
                  && !data.item.closedAt
                  && !data.item.refundedAt
                ">Срок вышел</div>
                <div v-if="data.item.refundedAt && !data.item.closedAt">Возвращён</div>
                <div v-if="data.item.closedAt">Погашен</div>
              </template>
            </datatable>
          </template>
        </dictionaries-provider>
      </template>
    </collection-provider>

    <b-modal
      v-if="modals.certificateOperations.show"
      centered
      cancel-title="Закрыть"
      size="xl"
      :visible="true"
      :title="modals.certificateOperations.title"
      :ok-only="true"
      ok-title="Закрыть"
      ok-variant="secondary"
      @hidden="closeCertificateOperations()"
    >
      <certificate-operations
        :id="modals.certificateOperations.id"
      ></certificate-operations>
    </b-modal>

    <modal-model-form
      v-if="modals.certificate.show"
      collection="certificates"
      edit-title="Информация по сертификату"
      :id="modals.certificate.id"
      :hide-footer="true"
      @close="modals.certificate.show = false"
    >
      <template #default="{ data }">
        <certificate-info
          v-if="data.item && data.item.id"
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
        ></certificate-info>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import CertificateInfo from './CertificateInfo.vue';
import CollectionProvider from '../../providers/CollectionProvider';
import DictionariesProvider from '../../providers/DictionariesProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import CertificateOperations from './CertificateOperations.vue';

import { Formatter } from '../../utils/formatter';
import { placeholder } from '../../utils/placeholder';

import fields from './_index.fields';
import itemActions from './_index.itemActions';
import panelActions from './_index.panelActions';

export default {
  components: {
    CertificateInfo,
    CollectionProvider,
    Datatable,
    ModalModelForm,
    DictionariesProvider,
    CertificateOperations,
  },
  data() {
    return {
      formatter: Formatter,
      placeholder,
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        certificate: {
          show: false,
          id: '',
        },
        certificateOperations: {
          show: false,
          id: null,
          title: '',
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCertificateForm(id) {
      this.modals.certificate.id = id;
      this.modals.certificate.show = true;
    },

    openCertificateOperations(item) {
      this.modals.certificateOperations.show = true;
      this.modals.certificateOperations.id = item.id;
      this.modals.certificateOperations.title = `Сертификат ${item.number}`;
    },

    closeCertificateOperations() {
      this.modals.certificateOperations.show = false;
      this.modals.certificateOperations.id = null;
      this.modals.certificateOperations.title = '';
    },
  },
};
</script>
