<template>
  <div class="table-container promocode-balance-operations">
    <collection-provider
      collection="promocodeOperations"
      ref="collection"
      sort-by="id"
      :sort-desc="true"
      :filter="{ promocodeId: id }"
      :disable-fetchind="!id"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :show-top-toolbar="false"
          :show-bottom-toolbar="true"
        >
          <template #cell(createdAt)="data">
            {{ data.item.createdAt ?  $datetime.formatDateTime(data.item.createdAt) : '' }}
          </template>

          <template #cell(text)="{ item: { cinema, show, description }}">
            <template v-if="show">
              <div class="mb-2">{{ cinema.name }} {{ cinema.city.name }}</div>
              <div class="movie-name mb-1">{{ show.release && show.release.movie && show.release.movie.name }}</div>
              <div class="mb-1"> {{ $datetime.formatDate(show.date) }} {{ $datetime.formatTime(show.time) }}</div>
            </template>
            <div v-if="description">{{ description }}</div>
          </template>

          <template #cell(orderId)="data">
            <span v-if="data.item.orderId"> {{ data.item.orderId }}</span>
          </template>
        </datatable>
      </template>
    </collection-provider>
  </div>
</template>

<script>
import fields from './_operations.fields';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';

export default {
  components: {
    CollectionProvider,
    Datatable,
  },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      datatable: {
        fields,
      },
    };
  },
};
</script>

<style scoped>
.movie-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 450px;
}
</style>
