<template>
  <div v-if="item.id">
    <item-header
      :image="item.imagePath"
      :title="item.name"
      :sub-title="item.code"
    ></item-header>
    <div class="w-50">
      <info-card class="mb-4" title="Информация о лаборатории">
        <info-card-row v-if="item.website">
          <template #label>сайт</template>
          <template #value>
            <a :href="item.website" target="_blank">{{item.website}}</a>
          </template>
        </info-card-row>

        <info-card-row v-if="item.address">
          <template #label>адрес</template>
          <template #value>{{item.address}}</template>
        </info-card-row>

        <info-card-row v-if="item.phone">
          <template #label>телефон</template>
          <template #value>{{item.phone}}</template>
        </info-card-row>

        <info-card-row v-if="item.email">
          <template #label>email</template>
          <template #value>{{item.email}}</template>
        </info-card-row>
      </info-card>
    </div>
  </div>
</template>

<script>
import ItemHeader from '../../components/card/ItemHeader.vue';
import InfoCard from '../../components/card/InfoCard.vue';
import InfoCardRow from '../../components/card/InfoCardRow.vue';

export default {
  components: {
    ItemHeader,
    InfoCard,
    InfoCardRow,
  },
  props: {
    item: {
      default: () => ({}),
    },
  },
};
</script>
