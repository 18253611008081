<template>
  <b-row>
    <b-col cols="12">
      <b-form-group label="Режим работы:">
        <b-form-select
          size="sm"
          v-model="settingLocal.mode"
          :options="options"
        >
        </b-form-select>
      </b-form-group>

      <b-form-group
        class="mb-0"
        label="Карточка времени:"
      >
        <b-row>
          <b-col cols="6">
            <b-form-group label="Состав:">
              <b-form-checkbox
                v-model="settingLocal.custom.showScheduleFormat"
                :value="1"
                :unchecked-value="0"
              >
                Формат фильма
              </b-form-checkbox>
              <b-form-checkbox
                v-model="settingLocal.custom.showSchedulePrice"
                :value="1"
                :unchecked-value="0"
              >
                Цена
              </b-form-checkbox>
              <b-form-checkbox
                v-model="settingLocal.custom.showScheduleHall"
                :value="1"
                :unchecked-value="0"
              >
                Категория зала
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Размер:">
              <b-form-radio
                v-model="settingLocal.custom.bigSessionCards"
                :value="0"
              >
                Обычный
              </b-form-radio>
              <b-form-radio
                v-model="settingLocal.custom.bigSessionCards"
                :value="1"
              >
                Увеличенный
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Количество карточек фильма на одном экране:">
        <b-form-radio-group
          v-model="settingLocal.custom.maxCardsPerScreen"
          :disabled="disabledMaxCards"
          :options="optionsMaxCardsScreen"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group label="Ротация карточек фильма (сек.):">
        <b-form-radio-group
          v-model="settingLocal.custom.duration"
          :options="durations"
        ></b-form-radio-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { CARD_MODE_OPTIONS as options } from './_index.settings';

export default {
  props: {
    setting: {
      default: () => ({}),
    },
    durations: {
      default: () => [],
    },
  },
  data: () => ({
    options,
    disabledMaxCards: false,
    settingLocal: {
      mode: 'AUTO',
      custom: {
        bigSessionCards: 0,
        showScheduleHall: 1,
        showScheduleFormat: 1,
        showSchedulePrice: 1,
        maxCardsPerScreen: 4,
        duration: 5,
      },
    },
  }),
  computed: {
    optionsMaxCardsScreen() {
      return [4, 9].map((item) => ({
        value: item,
        text: `до ${item}`,
      }));
    },
    mode() {
      return this.settingLocal.mode;
    },
  },
  watch: {
    mode: {
      handler(value) {
        if (['FOCUS_ON_SESSIONS', 'FOCUS_ON_FILM_FEES'].includes(value)) {
          this.disabledMaxCards = true;
          this.settingLocal.custom.maxCardsPerScreen = 9;

          return;
        }

        this.disabledMaxCards = false;
      },
      immediate: true,
    },
    settingLocal: {
      handler(setting) {
        this.$emit('change', setting);
      },
      immediate: true,
      deep: true,
    },
    setting: {
      handler(setting) {
        if (!setting) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(this.settingLocal.custom)) {
          if (typeof setting.custom[key] !== 'undefined') {
            this.settingLocal.custom[key] = setting.custom[key];
          }
        }

        if (setting.mode) {
          this.settingLocal.mode = this.setting.mode;
        }
      },
      immediate: true,
    },
  },
};
</script>
