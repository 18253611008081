<template>
  <div
    class="cursor-pointer d-flex align-items-center"
    @click="clickDirectory"
  >
    <div class="table-library__image-wrap mr-2">
      <template v-if="isListMode">
        <img
          class="rounded"
          :src="require(`@/assets/media-panels/library/directory.png`)"
          :alt="item.name"
        />
      </template>

      <template v-if="isTreeMode">
        <img
          class="rounded"
          :src="require(`@/assets/media-panels/library/${!item.isOpen ? 'directory.png' : 'directory_open.png'}`)"
          :alt="item.name"
        />
      </template>
    </div>
    <div>
      <div class="table-library__name text-truncate text-nowrap">{{ item.name }}</div>
      <div
        v-if="item.mediaInfo"
        class="text-secondary mt-0">
        {{ item.mediaInfo.filesCount }} {{ getDeclOfNum(item.mediaInfo.filesCount, ['файл', 'файла', 'файлов']) }}
      </div>
    </div>
  </div>
</template>
<script>
import declOfNum from '../../utils/declOfNum';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isListMode: {
      type: Boolean,
      default: true,
    },
    isTreeMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getDeclOfNum(n, titles = []) {
      return declOfNum(n, titles);
    },

    clickDirectory() {
      if (this.isListMode) {
        this.$emit('open-directory', this.item.id);
      }

      if (this.isTreeMode) {
        if (!this.item.isOpen) {
          this.$emit('open-directory', this.item.id);
          return;
        }

        this.$emit('close-directory', this.item.id);
      }
    },
  },
};
</script>
