/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

export default {
  props: {
    name: {
      required: true,
    },
    filter: {
      default: () => ({}),
    },
    disableFetching: {
      default: false,
    },
  },
  render() {
    return this.$scopedSlots.default({
      items: this.items,
      total: this.total,
      loading: this.loading,
    });
  },
  computed: {
    items() {
      return this.$store.state.dictionaries[this.name].items;
    },
    total() {
      return this.$store.state.dictionaries[this.name].total;
    },
    loading() {
      return this.$store.state.dictionaries[this.name].loading;
    },
    filterValues() {
      return Object.values(this.filter).join();
    },
  },
  watch: {
    name: {
      handler() {
        this.fetchItems();
      },
    },
    filterValues: {
      handler() {
        this.fetchItems();
      },
    },
  },
  created() {
    this.fetchItems();
  },
  methods: {
    async fetchItems() {
      if (this.disableFetching) {
        return;
      }

      await this.$store.dispatch(`dictionaries/${this.name}/fetchItems`, { filter: this.filter });

      this.$emit('loaded', this.items);
    },
  },
};
