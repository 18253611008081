<template>
  <div class="d-flex w-100">
    <div class="flex-grow-1 d-flex justify-content-end">
      <div class="d-flex">
        <b-select
          size="sm"
          :value="filter['screenId'] || null"
          :options="screens"
          :disabled="disabled"
          @change="$emit('change', { field: 'screenId', value: $event })"
        >
        </b-select>
        <b-select
          class="ml-2"
          size="sm"
          :value="filter['level'] || null"
          :options="levels"
          :disabled="disabled"
          @change="$emit('change', { field: 'level', value: $event })"
        >
        </b-select>
        <b-select
          class="ml-2"
          size="sm"
          :value="filter['tag'] || null"
          :options="tags"
          :disabled="disabled"
          @change="$emit('change', { field: 'tag', value: $event })"
        >
        </b-select>
        <b-button
          class="ml-2 text-nowrap"
          size="sm"
          variant="outline-danger"
          :disabled="disabled"
          @click="$emit('reset-filter')"
        >Сбросить</b-button>
        <b-button
          class="ml-2 text-nowrap"
          size="sm"
          variant="outline-primary"
          :disabled="disabled"
          @click="$emit('refresh')"
        >Обновить</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      default: false,
    },
    screens: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    levels: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
