<template>
  <div class="table-container">
    <collection-provider
      collection="cashierPlaces"
      ref="collection"
      :filter="{ cinemaId }"
      :disable-fetching="!cinemaId"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :item-actions="datatable.itemActions"
          @item-action="performItemAction($event)"
        >
          <template #cell(devices)="data">
            <div
              v-for="(device, index) in data.item.cashierPlaceDevices"
              :key="index"
            >
              {{ device.name }} ({{ device.address }})
            </div>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.cashierPlace.show"
      create-title="Создание кассового места"
      edit-title="Редактирование кассового места"
      collection="cashierPlaces"
      :id="modals.cashierPlace.id"
      :init="{ cinemaId }"
      @close="modals.cashierPlace.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <cashier-place-form
          :cinema-id="cinemaId"
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></cashier-place-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import CashierPlaceForm from './CashierPlaceForm.vue';

import fields from './_cashierPlaces.fields';
import itemActions from './_cashierPlaces.itemActions';

export default {
  components: {
    CollectionProvider,
    Datatable,
    CashierPlaceForm,
    ModalModelForm,
  },
  props: {
    cinemaId: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
      },
      modals: {
        cashierPlace: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateCashierPlaceForm() {
      this.modals.cashierPlace.id = null;
      this.modals.cashierPlace.show = true;
    },

    openEditCashierPlaceForm(id) {
      this.modals.cashierPlace.id = id;
      this.modals.cashierPlace.show = true;
    },

    async removeCashierPlace(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>
.cashier-places {
  padding: 0;
  overflow-y: scroll;
  overflow-y: auto;
}
</style>
