<template>
  <drop
    class="timeline-line"
    :style="styles"
    :class="classes"
    @drop="onDrop"
    @dragenter="onDragEnter"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @mousemove.native="onMouseMove"
    @mouseleave.native="onMouseLeave"
  >
    <div
      class="timeline-line__item"
      v-for="show in localShows"
      :key="`show_${show.id}`"
      :style="getItemStyles(show.time, show.release.duration + show.advertisementsDuration)"
      @mousedown.stop="onMouseDown($event, show)"
    >
      <div :style="{ width: convertMinutesToWidth(show.advertisementsDuration) + 'px' }">
        <slot name="advertisement"></slot>
      </div>
      <div :style="{ width: convertMinutesToWidth(show.release.duration) + 'px' }">
        <slot
          name="show"
          :show="show"
          :hall="hall"
          :disabled="disabled"
          :gap="findGapAfterShow(show.id)"
        ></slot>
      </div>
    </div>
    <div
      class="timeline-line__item"
      v-for="gap in localGaps"
      :key="`gap_${gap.id}`"
      :style="getItemStyles(gap.time, gap.interval)"
    >
      <slot
        name="gap"
        :warning="gap.warning"
        :interval="gap.interval"
      ></slot>
    </div>
    <div
      class="timeline-line__item timeline-line__stub"
      v-for="(sortableShow, index) in sortableShows"
      ref="sortable"
      :key="index"
      :class="{'d-none': !isSortableShowVisible(sortableShow)}"
    >
      <div :style="{ width: convertMinutesToWidth(sortableShow.advertisementsDuration) + 'px' }">
        <slot name="advertisement"></slot>
      </div>
      <div :style="{ width: convertMinutesToWidth(sortableShow.release.duration) + 'px' }">
        <slot
          name="show"
          :show="sortableShow"
          :hall="hall"
          :disabled="disabled"
          :sorting="true"
        ></slot>
      </div>
    </div>
    <div
      class="timeline-line__item timeline-line__stub"
      v-for="(draggableShow, index) in draggableShows"
      ref="draggable"
      :key="index"
    >
      <div :style="{ width: convertMinutesToWidth(draggableShow.advertisementsDuration) + 'px' }">
        <slot name="advertisement"></slot>
      </div>
      <div :style="{ width: convertMinutesToWidth(draggableShow.release.duration) + 'px' }">
        <slot
          name="show"
          :show="draggableShow"
          :hall="hall"
          :disabled="disabled"
        ></slot>
      </div>
    </div>
  </drop>
</template>

<script>
import { Drop } from 'vue-drag-drop';
import datetime from '../../utils/datetime';

export default {
  components: {
    Drop,
  },
  props: {
    disabled: {
      default: false,
    },
    shows: {
      default: () => [],
    },
    gaps: {
      default: () => [],
    },
    minInterval: {
      default: 5,
    },
    timeStart: {
      default: '06:00',
    },
    minuteWidth: {
      default: 1,
    },
    step: {
      default: 5,
    },
    roundToStep: {
      default: true,
    },
    visibleGapInterval: {
      default: 60,
    },
    allowedFormats: {
      default: () => [],
    },
    hall: {
      default: () => ({}),
    },
    sortableShows: {
      default: () => [],
    },
    sortableShowsIntervals: {
      default: () => ({}),
    },
    sortableShowsOffset: {
      default: 0,
    },
    date: {
      default: null,
    },
    getAdvertisementsDuration: {
      type: Function,
      default: () => 0,
    },
    hallHeight: {},
  },
  data() {
    return {
      dragEnterCounter: 0,
      draggableShows: [],
      sorting: false,
      localSortableShows: [],
      localSortableShowsIntervals: {},
      localSortableShowsOffset: 0,
      lastPosition: 0,
    };
  },
  computed: {
    timelineWidth() {
      return 24 * 60 * this.minuteWidth;
    },

    localShows() {
      if (this.sortableShows.length) {
        return this.shows.filter((_show) => !this.sortableShows.find((sortableShow) => sortableShow.id === _show.id));
      }

      return this.shows;
    },

    localGaps() {
      if (this.sortableShows.length) {
        return this.gaps.filter((_gap) => !this.sortableShows.find((sortableShow) => (sortableShow.id === _gap.id || sortableShow.id === _gap.nextId)));
      }

      return this.gaps; // .filter(gap => gap.interval < 60);
    },

    styles() {
      return {
        width: `${this.timelineWidth}px`,
        height: `${this.hallHeight}px`,
      };
    },

    classes() {
      return {
        disabled: this.disabled,
      };
    },
  },
  mounted() {
    this.addMouseUpListener();
  },
  destroyed() {
    this.removeMouseUpListener();
  },
  methods: {
    findGapAfterShow(showId) {
      return this.gaps.find((gap) => gap.id === showId);
    },

    addMouseUpListener() {
      document.addEventListener('mouseup', this.onMouseUp);
    },

    removeMouseUpListener() {
      document.removeEventListener('mouseup', this.onMouseUp);
    },

    getItemStyles(time, interval) {
      return {
        left: `${this.convertTimeToPosition(time)}px`,
        width: `${this.convertMinutesToWidth(interval)}px`,
      };
    },

    isChangingAllowed(show) {
      if (show.locked || show.ticketsCount || (show.appGroups && show.appGroups.length)) {
        return false;
      }

      return true;
    },

    convertTimeToPosition(time) {
      return datetime.getDiffInMinutes(time, this.timeStart) * this.minuteWidth;
    },

    convertPositionToTime(position) {
      let minutes = position / this.minuteWidth;

      if (this.roundToStep) {
        minutes = Math.round(minutes / this.step) * this.step;
      }

      return datetime.convertTimeToDbFormat(datetime.getIntervalEndTime(this.timeStart, minutes));
    },

    convertMinutesToWidth(minutes) {
      return minutes * this.minuteWidth;
    },

    getEventPosition(event) {
      const x = event.pageX;
      return x - this.$el.getBoundingClientRect().x;
    },

    getCorrectPosition(position, width) {
      if (position < 0) {
        return 0;
      }

      if (position + width > this.timelineWidth) {
        return this.timelineWidth - width;
      }

      return position;
    },

    isAllowedFormat(formatId) {
      return this.allowedFormats.find((format) => format.id === formatId);
    },

    getDirection(position) {
      const direction = position > this.lastPosition ? 1 : -1;
      this.lastPosition = position;

      return direction;
    },

    isSortableShowVisible(show) {
      return show.hallId === this.hall.id && this.isAllowedFormat(show.release.formatId);
    },

    calculateShowInfoByPosition(release, position) {
      const showDuration = release.duration;

      let newPosition = this.getCorrectPosition(position, this.convertMinutesToWidth(showDuration));
      let time = this.convertPositionToTime(newPosition);

      const advertisementsDuration = this.getAdvertisementsDuration(this.hall.id, this.date, time, release);
      const totalWidth = this.convertMinutesToWidth(showDuration + advertisementsDuration);

      const checkedPosition = this.getCorrectPosition(newPosition, totalWidth);

      if (checkedPosition < newPosition) {
        newPosition = checkedPosition;
        time = this.convertPositionToTime(checkedPosition);
      }

      return {
        time,
        position: newPosition,
        totalWidth,
        advertisementsDuration,
      };
    },

    calculateNextShowPosition(position, releaseDuration, advertisementsDuration, interval) {
      let minutes = releaseDuration + advertisementsDuration + interval;

      if (this.roundToStep) {
        minutes = Math.round(minutes / this.step) * this.step;
      }

      return position + this.convertMinutesToWidth(minutes);
    },

    // sorting functions below

    onMouseDown(event, show) {
      if (this.disabled) {
        return;
      }

      if (!this.isChangingAllowed(show)) {
        return;
      }

      this.localSortableShows = [];

      const showIndex = this.localShows.findIndex((_show) => _show.id === show.id);

      // eslint-disable-next-line no-restricted-syntax
      for (let i = showIndex; i < this.localShows.length; i += 1) {
        const currentShow = this.localShows[i];
        const prevShow = this.localSortableShows.length ? this.localSortableShows[this.localSortableShows.length - 1] : null;

        if (currentShow.locked) {
          break;
        }

        if (!this.isChangingAllowed(currentShow)) {
          // eslint-disable-next-line no-continue
          continue;
        }

        if (prevShow) {
          let interval = datetime.getDiffInMinutes(currentShow.time, prevShow.time);
          interval -= prevShow.release.duration + prevShow.advertisementsDuration;

          this.localSortableShowsIntervals[prevShow.id] = interval;
        }

        this.localSortableShows.push({ ...currentShow });
      }

      if (this.localSortableShows.length) {
        this.localSortableShowsOffset = this.getEventPosition(event) - this.convertTimeToPosition(this.localSortableShows[0].time);
      }
    },

    onMouseMove(event) {
      if (this.disabled) {
        return;
      }

      if (!this.sortableShows.length && this.localSortableShows.length) {
        this.startSorting();
        return;
      }

      if (!this.sortableShows.length) {
        return;
      }

      this.sorting = true;

      this.moveSortableShows(this.getEventPosition(event) - this.sortableShowsOffset);
    },

    onMouseUp() {
      this.sorting = false;
      this.resetLocalSortingData();
      this.$emit('sorting-stop');
    },

    onMouseLeave() {
      this.sorting = false;
    },

    startSorting() {
      this.$emit('sorting-start', {
        shows: this.localSortableShows,
        intervals: this.localSortableShowsIntervals,
        offset: this.localSortableShowsOffset,
      });

      this.resetLocalSortingData();
    },

    moveSortableShows(position) {
      if (!this.sortableShows.length) {
        return;
      }

      const direction = this.getDirection(position);

      let currentItemPosition = position;
      const sortableShowsChanges = {};

      for (let i = 0; i < this.sortableShows.length; i += 1) {
        const { id, release } = this.sortableShows[i];

        const {
          time,
          position: newPosition,
          totalWidth,
          advertisementsDuration,
        } = this.calculateShowInfoByPosition(release, currentItemPosition);

        if (this.isAllowedFormat(release.formatId)) {
          // для оптимизации отрисовки отправляем только изменения!
          sortableShowsChanges[i] = {
            hallId: this.hall.id,
            time,
            advertisementsDuration,
          };
        }

        const $sortableElement = this.$refs.sortable && this.$refs.sortable[i];

        if ($sortableElement) {
          $sortableElement.style.left = `${newPosition}px`;
          $sortableElement.style.width = `${totalWidth}px`;
        }

        currentItemPosition = this.calculateNextShowPosition(newPosition, release.duration, advertisementsDuration, this.sortableShowsIntervals[id]);
      }

      this.$emit('sorting', { changes: sortableShowsChanges, direction });
    },

    resetLocalSortingData() {
      this.localSortableShows = [];
      this.localSortableShowsIntervals = {};
      this.localSortableShowsOffset = 0;
    },

    // drag and drop functions below

    onDragEnter({ release, count }, event) {
      if (this.disabled) {
        return;
      }

      this.dragEnterCounter += 1;

      if (this.dragEnterCounter === 1) {
        this.displayDraggableShows(release, this.getEventPosition(event), count);
      }
    },

    onDragOver({ release, count }, event) {
      if (this.disabled) {
        return;
      }

      if (this.dragEnterCounter) {
        this.moveDraggableShows(this.getEventPosition(event));
      }
    },

    onDragLeave() {
      if (this.disabled) {
        return;
      }

      this.dragEnterCounter -= 1;

      if (!this.dragEnterCounter) {
        this.draggableShows = [];
      }
    },

    onDrop() {
      if (this.disabled) {
        return;
      }

      this.dragEnterCounter = 0;

      if (!this.draggableShows.length) {
        return;
      }

      this.$emit('drop', this.draggableShows);

      this.draggableShows = [];
    },

    displayDraggableShows(release, position, count) {
      if (!this.isAllowedFormat(release.formatId)) {
        return;
      }

      const date = this.$datetime.convertDateToDbFormat(this.date);
      const interval = this.hall.interval || this.step;

      let currentItemPosition = position;

      for (let i = 0; i < count; i += 1) {
        const {
          time,
          position: newPosition,
          totalWidth,
          advertisementsDuration,
        } = this.calculateShowInfoByPosition(release, currentItemPosition);

        this.draggableShows.push({
          releaseId: release.id,
          hallId: this.hall.id,
          release,
          date,
          time,
          advertisementsDuration,
        });

        currentItemPosition = this.calculateNextShowPosition(newPosition, release.duration, advertisementsDuration, interval);

        // eslint-disable-next-line no-loop-func
        this.$nextTick(() => {
          const $draggableElement = this.$refs.draggable[i];
          $draggableElement.style.left = `${newPosition}px`;
          $draggableElement.style.width = `${totalWidth}px`;
        });
      }
    },

    moveDraggableShows(position) {
      if (!this.draggableShows.length) {
        return;
      }

      const direction = this.getDirection(position);

      this.$emit('drag', { shows: this.draggableShows, direction });

      const interval = this.hall.interval || this.step;
      let currentItemPosition = position;

      for (let i = 0; i < this.draggableShows.length; i += 1) {
        const draggableShow = this.draggableShows[i];
        const $draggableElement = this.$refs.draggable[i];

        const {
          time,
          position: newPosition,
          totalWidth,
          advertisementsDuration,
        } = this.calculateShowInfoByPosition(draggableShow.release, currentItemPosition);

        $draggableElement.style.left = `${newPosition}px`;
        $draggableElement.style.width = `${totalWidth}px`;

        draggableShow.time = time;
        draggableShow.advertisementsDuration = advertisementsDuration;

        currentItemPosition = this.calculateNextShowPosition(newPosition, draggableShow.release.duration, advertisementsDuration, interval);
      }
    },
  },

};
</script>

<style scoped>
.timeline-line {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  position: relative;
}
.timeline-line.disabled {
  opacity: 0.5;
}
.timeline-line__item {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
}
.timeline-line__stub {
  left: -1000px;
}
.timeline-line__item_warning {
  opacity: 0.5;
}
</style>
