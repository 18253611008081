<template>
  <div class="p-2">
    <b-form-group
      label="Период:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.dateStart']} ${errors['filter.dateEnd']}`"
      :state="states['filter.dateStart'] && states['filter.dateEnd']"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="filter.dateStart"
        :state="states['filter.dateStart']"
        :disabled="loading"
        @change="setFilter({ field: 'dateStart', value: $event }), setFilter({ field: 'releaseId', value: null })"
      ></date-popover>
      <date-popover
        size="sm"
        :value="filter.dateEnd"
        :state="states['filter.dateEnd']"
        :disabled="loading"
        @change="setFilter({ field: 'dateEnd', value: $event }), setFilter({ field: 'releaseId', value: null })"
      ></date-popover>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Релиз:"
      :disabled="loading"
      :invalid-feedback="errors['filter.releaseId']"
    >
      <dictionary-provider
        :name="params.compareDatesBy == 'show_date' ? 'showsReleases' : 'salesReleases'"
        :filter="{ dateStart: filter.dateStart, dateEnd: filter.dateEnd }"
        :disable-fetching="!filter.dateStart || !filter.dateEnd"
      >
        <template #default="{ items: releases, loading: releasesLoading }">
          <toggle-list
              size="sm"
              :options="convertReleases(releases)"
              :value="convertToDictionaryFromIds(filter.releaseIds, releases)"
              :disabled="releasesLoading || loading || (!filter.dateStart || !filter.dateEnd)"
              :state="states['filter.releaseIds']"
              @change="setFilter({ field: 'releaseIds', value: convertFromDictionaryToIds($event) })"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Кинотеатры:"
      :disabled="loading"
      :invalid-feedback="errors['filter.cinemaIds']"
    >
      <dictionary-provider name="cinemas">
        <template #default="{ items: cinemas, loading: cinemasLoading }">
          <toggle-list
            :options="convertCinemas(cinemas)"
            :value="convertToDictionaryFromIds(filter.cinemaIds, cinemas)"
            :disabled="loading || cinemasLoading"
            @change="setFilter({ field: 'cinemaIds', value: convertFromDictionaryToIds($event) })"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Выборка:"
      :disabled="loading"
      :invalid-feedback="errors['params.compareDatesBy']"
    >
      <b-form-radio-group
        name="compare-dates-by"
        :state="states['params.compareDatesBy']"
        :checked="params.compareDatesBy"
        :disabled="loading"
        @change="setParam({ field: 'compareDatesBy', value: $event })"
      >
        <b-form-radio value="created_at">По дате продажи</b-form-radio>
        <b-form-radio value="show_date">По дате сеанса</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Учет типа суток:"
      :disabled="loading"
      :invalid-feedback="errors['params.dayType']"
    >
      <b-form-radio-group
        name="day-type"
        :state="states['params.dayType']"
        :checked="params.dayType"
        :disabled="loading"
        @change="setParam({ field: 'dayType', value: $event })"
      >
        <b-form-radio value="business">Бизнес-сутки</b-form-radio>
        <b-form-radio value="calendar">Календарные</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <div class="mt-2">
      <b-button
        variant="info"
        size="sm"
        :disabled="loading"
        @click="fetchReport({ format: 'html' })"
      >Посмотреть</b-button>
      <b-dropdown
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
      >
        <template #button-content>
          <icon icon="fa-save"></icon>
        </template>
        <b-dropdown-item
          v-for="format in formats"
          :key="format.value"
          @click="fetchReport({ format: format.value })"
        >{{format.text}}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DictionaryProvider from '../../../providers/DictionaryProvider';
import DatePopover from '../../../components/fields/DatePopover.vue';
import ToggleList from '../../../components/fields/ToggleList.vue';

import datetime from '../../../utils/datetime';

export default {
  components: {
    DictionaryProvider,
    DatePopover,
    ToggleList,
  },
  data() {
    return {
      format: 'xlsx',
      formats: [
        { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'XLSX' },
      ],
    };
  },
  computed: {
    ...mapState('reports/proceedsByCinemas', {
      params: (state) => state.params,
      filter: (state) => state.filter,
      loading: (state) => state.loading,
      errors: (state) => state.errors,
      states: (state) => state.states,
    }),
  },
  created() {
    const date = datetime.convertDateToDbFormat(new Date());

    this.setFilter({ field: 'releaseIds', value: [] });
    this.setFilter({ field: 'dateStart', value: date });
    this.setFilter({ field: 'dateEnd', value: date });
    this.setParam({ field: 'compareDatesBy', value: 'show_date' });
    this.setParam({ field: 'dayType', value: 'business' });
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      setParam: 'reports/proceedsByCinemas/setParam',
      setFilter: 'reports/proceedsByCinemas/setFilter',
      fetchReport: 'reports/proceedsByCinemas/fetchReport',
      reset: 'reports/proceedsByCinemas/reset',
    }),

    convertReleases(releases) {
      return releases.map((release) => ({
        id: release.id,
        name: `${release.movie.name} ${release.format.name}`,
      }));
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    convertToDictionaryFromIds(ids, items) {
      if (!ids || !items) {
        return [];
      }

      return ids.map((id) => items.find((item) => item.id === id));
    },

    convertFromDictionaryToIds(items) {
      if (!items) {
        return [];
      }

      return items.map((item) => item.id);
    },
  },
};
</script>

<style scoped>
 .report >>> .multiselect__tag,
 .report >>> .multiselect__option {
   white-space: normal;
 }
</style>
