export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Имя',
    filterable: true,
    sortable: true,
  },
  {
    key: 'email',
    type: 'text',
    label: 'Почта',
    filterable: true,
    sortable: true,
  },
  {
    key: 'roleId',
    type: 'select',
    label: 'Роли',
    sortable: false,
    filterable: true,
  },
  {
    key: 'cinemaId',
    type: 'select',
    label: 'Кинотеатры',
    sortable: false,
    filterable: true,
  },
];
