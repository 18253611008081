<template>
  <b-modal
    ref="modal"
    centered
    size="lg"
    ok-variant="success"
    ok-title="Сохранить"
    cancel-title="Закрыть"
    :visible="true"
    :title="title"
    :busy="loading"
    @ok="ok"
    @hidden="$emit('hide')"
  >
    <b-container fluid class="p-0">
      <b-form-group
        label="Название экрана:"
        :invalid-feedback="errors.name"
        :state="states.name"
      >
        <b-form-input
          autofocus
          type="text"
          size="sm"
          v-model="item.name"
          :state="states.name"
          :disabled="loading"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        v-if="!id"
        label="Код подтверждения:"
        :invalid-feedback="errors.deviceId"
        :state="states.deviceId"
      >
        <b-form-input
          type="text"
          size="sm"
          v-model="item.deviceId"
          :state="states.deviceId"
          :disabled="loading"
        ></b-form-input>
      </b-form-group>
    </b-container>
  </b-modal>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { mapState } from 'vuex';

export default {
  props: {
    cinemaId: {
      required: true,
    },
    loading: {
      type: Boolean,
    },
    id: null,
  },
  data() {
    return {
      errors: {},
      states: {},
      item: {
        name: null,
        deviceId: null,
        cinemaId: this.cinemaId,
      },
    };
  },
  created() {
    if (this.id) {
      const { name, deviceId } = this.items.find((item) => item.id === this.id);
      this.item.name = name;
      this.item.deviceId = deviceId;
    }
  },
  computed: {
    ...mapState('media/screens', {
      items: (state) => state.items,
    }),
    title() {
      return this.id ? 'Редактирование экрана' : 'Добавление экрана';
    },
  },
  methods: {
    setError(field, value) {
      this.$set(this.errors, field, value);
    },

    resetErrors() {
      this.errors = {};
    },

    setState(field, value) {
      this.$set(this.states, field, value);
    },

    resetStates() {
      this.states = {};
    },

    async ok(event) {
      event.preventDefault();

      let err;
      this.resetErrors();
      this.resetStates();

      if (!this.id) {
        [err] = await this.$store.dispatch('media/screens/createItem', this.item);
      } else {
        [err] = await this.$store.dispatch('media/screens/updateItem', { id: this.id, values: this.item });
      }

      if (err && err.status === 422 && err?.data?.errors) {
        const errors = err?.data?.errors;

        for (const field in errors) {
          this.setError(field, errors[field].join(', '));
          this.setState(field, false);
        }
      }

      if (!err) {
        this.$emit('saved');
        this.$refs.modal.hide();
      }
    },
  },
};
</script>
