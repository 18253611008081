<template>
  <div class="table-container">
    <collection-provider
      collection="cashierDevices"
      ref="collection"
      :filter="{ cinemaId }"
      :disable-fetching="!cinemaId"
    >
      <template #default="{ data, actions }">
        <dictionaries-provider :names="['cashierDeviceProtocols', 'legalEntities']">
          <template #default="{ cashierDeviceProtocols, legalEntities }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :permissions="$user.permissions"
              :item-actions="datatable.itemActions"
              @item-action="performItemAction($event)"
            >
              <template #filter(legalEntityId)>
                <b-form-select
                  size="sm"
                  :value="data.filter.legalEntityId"
                  :options="$array.convertToOptions(legalEntities)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('legalEntityId', $event)"
                ></b-form-select>
              </template>
              <template #filter(cashierDeviceProtocolId)>
                <b-form-select
                  size="sm"
                  :value="data.filter.cashierDeviceProtocolId"
                  :options="$array.convertToOptions(cashierDeviceProtocols)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('cashierDeviceProtocolId', $event)"
                ></b-form-select>
              </template>
              <template #cell(useProxy)="data">
                {{ data.item.useProxy ? 'Да' : 'Нет' }}
              </template>
              <template #cell(networkDevice)="data">
                {{ data.item.networkDevice ? 'Да' : 'Нет' }}
              </template>
              <template #cell(legalEntityId)="data">
                {{$array.getItemText(legalEntities, data.item.legalEntityId)}}
              </template>
              <template #cell(cashierDeviceProtocolId)="data">
                {{$array.getItemText(cashierDeviceProtocols, data.item.cashierDeviceProtocolId)}}
              </template>
              <template #cell(using)="data">
                <span v-if="data.item.fiscalable">Фискализация</span>
                <span v-if="data.item.fiscalable && data.item.printable">+</span>
                <span v-if="data.item.printable">Печать</span>
              </template>
            </datatable>
          </template>
        </dictionaries-provider>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.cashierDevice.show"
      create-title="Создание устройства"
      edit-title="Редактирование устройства"
      collection="cashierDevices"
      :id="modals.cashierDevice.id"
      :init="{ cinemaId, printable: false, fiscalable: false }"
      @close="modals.cashierDevice.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <cashierDevice-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></cashierDevice-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import CollectionProvider from '../../providers/CollectionProvider';
import DictionariesProvider from '../../providers/DictionariesProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import CashierDeviceForm from './CashierDeviceForm.vue';

import fields from './_cashierDevices.fields';
import itemActions from './_cashierDevices.itemActions';

export default {
  components: {
    CollectionProvider,
    DictionariesProvider,
    Datatable,
    CashierDeviceForm,
    ModalModelForm,
  },
  props: {
    cinemaId: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
      },
      modals: {
        cashierDevice: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateCashierDeviceForm() {
      this.modals.cashierDevice.id = null;
      this.modals.cashierDevice.show = true;
    },

    openEditCashierDeviceForm(id) {
      this.modals.cashierDevice.id = id;
      this.modals.cashierDevice.show = true;
    },

    async removeCashierDevice(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>
.cashier-devices {
  padding: 0;
  overflow-y: scroll;
  overflow-y: auto;
}
</style>
