<template>
  <b-button-toolbar
    key-nav
    aria-label
    class="table-actions__pagination d-flex align-items-center justify-content-end flex-1"
  >
    <span class="label">{{label}}</span>

    <b-button
      size="sm"
      variant="info"
      class="left mx-1"
      :disabled="disabled"
      @click.prevent="previousPage"
    >
      <i class="fa fa-caret-left"></i>
    </b-button>

    <b-form-input
      type="number"
      class="page-input d-inline-block"
      min="1"
      :max="countOfPages"
      :value="page"
      :disabled="disabled"
      size="sm"
      @change="setPage"
      ref="el"
    ></b-form-input>

    <b-button
      size="sm"
      variant="info"
      class="right mx-1"
      :disabled="disabled"
      @click.prevent="nextPage"
    >
      <i class="fa fa-caret-right"></i>
    </b-button>

    <span class="label">из <span class="num">{{ countOfPages }}</span></span>
  </b-button-toolbar>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    page: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    countOfPages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  methods: {
    previousPage() {
      if (this.page > 1) {
        this.$emit('update', this.page - 1);
      }
    },
    nextPage() {
      if (this.page < this.countOfPages) {
        this.$emit('update', this.page + 1);
      }
    },
    setPage(value) {
      const valueInt = +value;

      if (valueInt >= 1 && valueInt <= this.countOfPages) {
        this.$emit('update', valueInt);
      } else {
        this.$refs.el.$refs.input.value = this.page;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-input {
  padding: 0;
  max-width: 40px;
  text-align: center;
}
</style>
