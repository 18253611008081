<template>
  <cinema-page
    route-name="sales"
    route-param-name="cinemaId"
    :display-loading-stub="true"
    @loaded="onCinemaLoaded($event)"
  >
    <template #buttons="{ halls, loading }">
      <buttons
        :date="date"
        :disabled="loading"
        :intersections="intersections"
        :halls="halls"
        @change-cinema="openCinema($event)"
        @open-sales="openSales()"
        @close-sales="closeSales()"
        @open-sales-period-form="openSalesPeriodForm()"
        @select-date="openDate($event)"
        @set-min-price="modals.setMinPrice.show = true"
        @decrease-width="decreaseMinuteWidth()"
        @increase-width="increaseMinuteWidth()"
        @decrease-height="decreaseHallHeight()"
        @increase-height="increaseHallHeight()"
        @prev-day="prevDay()"
        @next-day="nextDay()"
        @open-schedule="openSchedule()"
      ></buttons>
    </template>
    <template #scroll="{ halls, loading }">
      <timeline
        ref="timeline"
        :halls="halls"
        :shows="shows"
        :gaps="gaps"
        :disabled="loading"
        :minute-width="minuteWidth"
        :hall-height="hallHeight"
      >
        <template #hall-buttons="{ hall }">
          <div class="d-flex justify-content-center">
            <b-button
              v-if="$user.can('schedule.edit')"
              size="sm"
              variant="outline-info"
              class="mr-1"
              v-b-tooltip="'Открыть продажи в зале'"
              :disabled="loading"
              @click="openSalesInHall(hall.id)"
            >
              <icon icon="mdi-cart"></icon>
            </b-button>
            <b-button
              v-if="$user.can('schedule.edit')"
              size="sm"
              variant="outline-info"
              class="mr-1"
              v-b-tooltip="'Закрыть продажи в зале'"
              :disabled="loading"
              @click="closeSalesInHall(hall.id)"
            >
              <icon icon="mdi-cart-off"></icon>
            </b-button>
          </div>
        </template>
        <template #advertisement>
          <timeline-advertisement></timeline-advertisement>
        </template>
        <template #show="{ show, hall }">
          <timeline-show
            :show="show"
            :hall="hall"
            :selected="isShowSelected(show)"
            :expired="isExpired(show)"
            @click.native="toggleSelectedShow($event, show)"
            @contextmenu.native.prevent="openContextMenu($event, show)"
            @dblclick.native="openShowSettingsForm(show.id)"
          ></timeline-show>
        </template>
        <template #gap="{ interval }">
          <timeline-gap
            :interval="interval"
          ></timeline-gap>
        </template>
      </timeline>
      <vue-context ref="menu">
        <li>
          <a @click.prevent="openSalesForSelectedShows()">Открыть продажи</a>
        </li>
        <li>
          <a @click.prevent="closeSalesForSelectedShows()">Закрыть продажи</a>
        </li>
        <li v-if="selectedShowIds.length === 1">
          <a @click.prevent="openShowSettingsForm()">Настройки</a>
        </li>
      </vue-context>

      <modal-set-min-price
        v-if="modals.setMinPrice.show"
        @hide="modals.setMinPrice.show = false"
      ></modal-set-min-price>

      <modal-sales-status
        v-if="modals.salesStatus.show"
        :show-ids="selectedShowIds"
        :type="modals.salesStatus.type"
        :hall-id="modals.salesStatus.hallId"
        :status="modals.salesStatus.status"
        @hide="closeSalesStatusForm()"
      ></modal-sales-status>

      <modal-sales-period
        v-if="modals.salesPeriod.show"
        @hide="closeSalesPeriodForm()"
      ></modal-sales-period>

      <modal-model-form
        v-if="modals.showSettings.show"
        size="xl"
        collection="sales"
        :id="modals.showSettings.id"
        :create-title="modals.showSettings.title"
        :edit-title="modals.showSettings.title"
        @close="closeShowSettingsForm()"
        @saved="fetchShows()"
      >
        <template #default="{ data, actions }">
          <show-settings-form
            :loading="data.loading"
            :errors="data.errors"
            :states="data.states"
            :item="data.item"
            @change="actions.setValue($event.field, $event.value)"
          ></show-settings-form>
        </template>
      </modal-model-form>
    </template>
  </cinema-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import VueContext from 'vue-context';
import datetime from '../../utils/datetime';

import CinemaPage from '../../layout/CinemaPage.vue';
import Buttons from './Buttons.vue';
import Timeline from './Timeline.vue';
import TimelineShow from './TimelineShow.vue';
import TimelineGap from './TimelineGap.vue';
import TimelineAdvertisement from './TimelineAdvertisement.vue';
import ModalSetMinPrice from './ModalSetMinPrice.vue';
import ModalSalesStatus from './ModalSalesStatus.vue';
import ModalSalesPeriod from './ModalSalesPeriod.vue';
import ShowSettingsForm from './ShowSettingsForm.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';

import { SALES_STATUS_FORM_TYPE_CINEMA, SALES_STATUS_FORM_TYPE_HALL, SALES_STATUS_FORM_TYPE_SHOWS } from './constants';

const LOCAL_STORAGE_KEY_FOR_MINUTE_WIDTH = 'minuteWidthSales';

const DEFAULT_MINUTE_WIDTH = 1.2;
const DEFAULT_MINUTE_WIDTH_STEP = 0.02;
const TIMELINE_HALLS_WIDTH = 110;

const DEFAULT_HALL_HEIGHT = 80;
const DEFAULT_HALL_HEIGHT_STEP = 3;

export default {
  components: {
    CinemaPage,
    Buttons,
    Timeline,
    TimelineShow,
    TimelineGap,
    TimelineAdvertisement,
    VueContext,
    ModalSetMinPrice,
    ModalModelForm,
    ShowSettingsForm,
    ModalSalesStatus,
    ModalSalesPeriod,
  },
  data() {
    return {
      cinema: null,
      selectedShowIds: [],
      minuteWidth: DEFAULT_MINUTE_WIDTH,
      hallHeight: DEFAULT_HALL_HEIGHT,
      modals: {
        setMinPrice: {
          show: false,
        },
        showSettings: {
          show: false,
          id: null,
          title: null,
        },
        salesStatus: {
          show: false,
          type: null,
          hallId: null,
          status: false,
        },
        salesPeriod: {
          show: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters('data/sales', ['shows', 'gaps', 'intersections']),

    ...mapState('data/sales', {
      date: (state) => state.date,
    }),
  },
  async created() {
    this.initDate();
  },
  destroyed() {
    this.resetShows();
    this.resetDate();
  },
  methods: {
    increaseMinuteWidth() {
      this.minuteWidth += DEFAULT_MINUTE_WIDTH_STEP;
      this.saveMinuteWidth();
    },

    decreaseMinuteWidth() {
      if (this.minuteWidth > DEFAULT_MINUTE_WIDTH_STEP) {
        this.minuteWidth -= DEFAULT_MINUTE_WIDTH_STEP;
        this.saveMinuteWidth();
      }
    },

    loadMinuteWidth() {
      const minuteWidth = localStorage.getItem(LOCAL_STORAGE_KEY_FOR_MINUTE_WIDTH);

      if (minuteWidth) {
        this.minuteWidth = parseFloat(minuteWidth);
      } else {
        this.minuteWidth = this.calculateMinuteWidth();
      }
    },

    saveMinuteWidth() {
      localStorage.setItem(LOCAL_STORAGE_KEY_FOR_MINUTE_WIDTH, this.minuteWidth);
    },

    calculateMinuteWidth() {
      if (!this.$refs.timeline) {
        return DEFAULT_MINUTE_WIDTH;
      }

      const rect = this.$refs.timeline.$el.getBoundingClientRect();

      return (rect.width - TIMELINE_HALLS_WIDTH) / 1440;
    },

    increaseHallHeight() {
      this.hallHeight += DEFAULT_HALL_HEIGHT_STEP;
    },

    decreaseHallHeight() {
      if (this.hallHeight > DEFAULT_HALL_HEIGHT_STEP) {
        this.hallHeight -= DEFAULT_HALL_HEIGHT_STEP;
      }
    },

    openSchedule() {
      this.$store.commit('data/shows/setDate', this.date);
      this.$router.push({ name: 'schedule', params: { cinemaId: this.cinemaId } });
    },

    onCinemaLoaded(cinema) {
      this.cinema = cinema;
      this.$store.dispatch('data/sales/fetchCinemaShows', cinema.id);

      this.$nextTick(this.loadMinuteWidth);
    },

    async initDate() {
      await this.$store.dispatch('data/sales/initDate');
    },

    async resetDate() {
      await this.$store.dispatch('data/sales/resetDate');
    },

    async fetchShows() {
      this.$store.dispatch('data/sales/fetchItems');
    },

    async resetShows() {
      this.$store.dispatch('data/sales/resetItems');
    },

    openDate(date) {
      this.$store.dispatch('data/sales/changeDate', date);
    },

    prevDay() {
      this.$store.dispatch('data/sales/changeDate', datetime.subtractDays(this.date, 1));
    },

    nextDay() {
      this.$store.dispatch('data/sales/changeDate', datetime.addDays(this.date, 1));
    },

    isExpired(show) {
      const timeInCinema = datetime.getCurrentTimeInTimezone(this.cinema.utcOffset);
      const showTime = datetime.getShowTime(show.date, show.time);

      return datetime.isTimeAfter(timeInCinema, showTime);
    },

    isShowSelected(show) {
      return this.selectedShowIds.includes(show.id);
    },

    toggleSelectedShow($event, show) {
      if (this.isExpired(show)) {
        return;
      }

      if (!$event.ctrlKey && !$event.altKey && !$event.metaKey) {
        this.selectedShowIds = [];
        return;
      }

      if (this.isShowSelected(show)) {
        this.selectedShowIds = this.selectedShowIds.filter((_id) => _id !== show.id);
      } else {
        this.selectedShowIds.push(show.id);
      }
    },

    openContextMenu($event, show) {
      if (this.isExpired(show) || !this.$user.can('schedule.edit')) {
        return;
      }

      if (!this.selectedShowIds.includes(show.id)) {
        if ($event.ctrlKey || $event.altKey || $event.metaKey) {
          this.selectedShowIds.push(show.id);
        } else {
          this.selectedShowIds = [show.id];
        }
      }

      this.$refs.menu.open($event);
    },

    openShowSettingsForm(showId = null) {
      const id = showId || this.selectedShowIds[0];

      this.modals.showSettings.show = true;
      this.modals.showSettings.id = id;

      const show = this.shows.find((_show) => _show.id === id);

      const {
        date,
        time,
        hallId,
        ticketsCount,
        release: {
          duration,
          movie: {
            name: movieName,
            ageLimit,
          },
          format: {
            name: formatName,
          },
        },
      } = show;

      const hall = this.cinema.halls.find((_hall) => _hall.id === hallId);

      this.modals.showSettings.title = `${movieName}, ${formatName}, ${ageLimit}+, ${duration} мин. / ${hall.name} / ${time} ${this.$datetime.formatDate(date)} / Продано билетов: ${ticketsCount}`;
    },

    closeShowSettingsForm() {
      this.modals.showSettings.show = false;
      this.modals.showSettings.id = null;
      this.modals.showSettings.title = null;

      this.selectedShowIds = [];
    },

    closeSalesStatusForm() {
      this.modals.salesStatus.show = false;
      this.modals.salesStatus.type = null;
      this.modals.salesStatus.hallId = null;
      this.modals.salesStatus.status = false;

      this.selectedShowIds = [];
    },

    async openSales() {
      this.modals.salesStatus.show = true;
      this.modals.salesStatus.type = SALES_STATUS_FORM_TYPE_CINEMA;
      this.modals.salesStatus.status = true;
    },

    async closeSales() {
      this.modals.salesStatus.show = true;
      this.modals.salesStatus.type = SALES_STATUS_FORM_TYPE_CINEMA;
      this.modals.salesStatus.status = false;
    },

    async openSalesInHall(hallId) {
      this.modals.salesStatus.show = true;
      this.modals.salesStatus.type = SALES_STATUS_FORM_TYPE_HALL;
      this.modals.salesStatus.hallId = hallId;
      this.modals.salesStatus.status = true;
    },

    async closeSalesInHall(hallId) {
      this.modals.salesStatus.show = true;
      this.modals.salesStatus.type = SALES_STATUS_FORM_TYPE_HALL;
      this.modals.salesStatus.hallId = hallId;
      this.modals.salesStatus.status = false;
    },

    async openSalesForSelectedShows() {
      if (!this.selectedShowIds.length) {
        return;
      }

      this.modals.salesStatus.show = true;
      this.modals.salesStatus.type = SALES_STATUS_FORM_TYPE_SHOWS;
      this.modals.salesStatus.status = true;
    },

    async closeSalesForSelectedShows() {
      if (!this.selectedShowIds.length) {
        return;
      }

      this.modals.salesStatus.show = true;
      this.modals.salesStatus.type = SALES_STATUS_FORM_TYPE_SHOWS;
      this.modals.salesStatus.status = false;
    },

    async openSalesPeriodForm() {
      this.modals.salesPeriod.show = true;
    },

    async closeSalesPeriodForm() {
      this.modals.salesPeriod.show = false;
    },
  },
};
</script>
