export default ({ checked }) => [
  {
    type: 'dropdown',
    text: 'Действия с выделенными',
    variant: 'outline-info',
    class: 'mr-2',
    bulk: true,
    disabled: checked && checked.length === 0,
    items: [
    ],
  },
];
