<template>
  <div v-if="file.mediaInfo">
    <div
      v-if="helperFile.isImage(file.mediaInfo.mimeType)"
      class="d-flex justify-content-center"
    >
      <b-img :src="getPathToFile(fileId, convertSize)"></b-img>
    </div>
    <div
      v-if="helperFile.isVideo(file.mediaInfo.mimeType)"
      class="d-flex justify-content-center"
    >
      <b-embed type="video" :src="getPathToFile(fileId, convertSize)" aspect="16by9" controls></b-embed>
    </div>
    <b-row
      v-if="helperFile.isImage(file.mediaInfo.mimeType) || helperFile.isVideo(file.mediaInfo.mimeType)"
      class="mt-3 mb-3"
    >
      <b-col cols="6">
        <div>
          Разрешение: {{ file.mediaInfo.resolution }}
        </div>
        <div class="d-flex mt-2">
          <template v-if="file.mediaInfo.ratioStandart">
            <div class="mr-1">16x9:</div>
            <div :class="[isStandartRatio(file.mediaInfo.ratioStandart) ? 'text-success' : 'text-danger']">
              {{ isStandartRatio(file.mediaInfo.ratioStandart) ? 'Да' : 'Нет' }}
            </div>
          </template>
          <template v-else>
            -
          </template>
        </div>
      </b-col>
      <b-col cols="6">
        <b-form-group class="mb-0">
          <b-form-radio
            v-model="convertSize"
            :value="'original'"
          >
            Оригинал
          </b-form-radio>
        </b-form-group>
        <b-form-group class="mt-2 mb-0">
          <b-form-radio
            v-model="convertSize"
            :value="'1080p'"
          >
            1080p
          </b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="w-100 pt-3 pb-2 d-flex justify-content-center border-top">
      <a
        class="text-primary"
        :href="`${getPathToFile(fileId, convertSize)}&download=1`"
        :download="file.name"
      >Скачать</a>
    </div>
  </div>
</template>

<script>
import {
  isFile, isDirectory, isVideo, isImage,
} from './_index.fields';
import { getFileUrl } from '../../api/mediaPanels';

export default {
  props: {
    file: {
      type: Object,
    },
  },
  data: () => ({
    helperFile: {
      isFile,
      isDirectory,
      isVideo,
      isImage,
    },
    convertSize: '1080p',
  }),
  computed: {
    fileId() {
      return this.file.id;
    },
  },
  methods: {
    isStandartRatio(ratio) {
      return ratio === '16:9';
    },

    getPathToFile(id, size) {
      return getFileUrl(id, size);
    },
  },
};
</script>
