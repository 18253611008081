<template>
<b-tabs card>
  <b-tab title="Информация">
    <b-container fluid class="p-0">
      <b-row>
        <b-col>
          <b-form-group
            label="Наименование:"
            :invalid-feedback="errors.name"
            :state="states.name"
          >
            <b-form-input
              type="text"
              autofocus
              size="sm"
              :value="item.name"
              :state="states.name"
              :disabled="loading"
              @change="updateValue('name', $event)"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Описание:"
            :invalid-feedback="errors.description"
            :state="states.description"
          >
            <b-form-textarea
              type="text"
              size="sm"
              rows="10"
              :value="item.description"
              :state="states.description"
              :disabled="loading"
              @change="updateValue('description', $event)"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Режиссеры:"
            :invalid-feedback="errors.directors"
            :state="states.directors"
          >
            <b-form-input
              type="text"
              size="sm"
              :value="item.directors"
              :state="states.directors"
              :disabled="loading"
              @change="updateValue('directors', $event)"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Актеры:"
            :invalid-feedback="errors.actors"
            :state="states.actors"
          >
            <b-form-textarea
              type="text"
              size="sm"
              :value="item.actors"
              :state="states.actors"
              :disabled="loading"
              @change="updateValue('actors', $event)"
              rows="3"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Жанры:"
            :invalid-feedback="errors.genres"
            :state="states.genres"
          >
            <dictionary-provider name="genres2">
              <template #default="{ items }">
                <toggle-list
                  :options="items"
                  :value="item.genres"
                  :disabled="loading"
                  @change="updateValue('genres', $event)"
                ></toggle-list>
              </template>
            </dictionary-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Страны:"
            :invalid-feedback="errors.countries"
            :state="states.countries"
          >
            <dictionary-provider name="countries2">
              <template #default="{ items }">
                <toggle-list
                  :options="items"
                  :value="item.countries"
                  :disabled="loading"
                  @change="updateValue('countries', $event)"
                ></toggle-list>
              </template>
            </dictionary-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Номер прокатного удостоверения:"
            :invalid-feedback="errors.rentalLicenseNumber"
            :state="states.rentalLicenseNumber"
          >
            <b-form-input
              type="text"
              size="sm"
              :value="item.rentalLicenseNumber"
              :state="states.rentalLicenseNumber"
              :disabled="loading"
              @change="updateValue('rentalLicenseNumber', $event)"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Дистрибьютор:"
            :invalid-feedback="errors.distributors"
            :state="states.distributors"
          >
            <dictionary-provider name="distributors2">
              <template #default="{ items }">
                <b-select
                  size="sm"
                  :options="$array.convertToOptions(items)"
                  :value="item.distributorId"
                  :state="states.distributorId"
                  :disabled="loading"
                  @change="updateValue('distributorId', $event)"
                ></b-select>
              </template>
            </dictionary-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Возрастное ограничение:"
            :invalid-feedback="errors.ageLimit"
            :state="states.ageLimit"
          >
            <dictionary-provider name="ageLimits">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="item.ageLimit"
                  :state="states.ageLimit"
                  :disabled="loading"
                  :options="$array.convertToOptions(items)"
                  @change="updateValue('ageLimit', $event)"
                ></b-form-select>
              </template>
            </dictionary-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Меморандум до:"
            :invalid-feedback="errors.memorandumPeriodEnd"
            :state="states.memorandumPeriodEnd"
          >
            <date
              size="sm"
              :value="item.memorandumPeriodEnd"
              :state="states.memorandumPeriodEnd"
              :disabled="loading"
              @selected="updateValue('memorandumPeriodEnd', $event)"
            ></date>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Старт проката в мире:"
            :invalid-feedback="errors.worldDateStart"
            :state="states.worldDateStart"
          >
            <date
              size="sm"
              :value="item.worldDateStart"
              :state="states.worldDateStart"
              :disabled="loading"
              @selected="updateValue('worldDateStart', $event)"
            ></date>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Предпоказ в России:"
            :invalid-feedback="errors.datePreview"
            :state="states.datePreview"
          >
            <date
              size="sm"
              :value="item.datePreview"
              :state="states.datePreview"
              :disabled="loading"
              @selected="updateValue('datePreview', $event)"
            ></date>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Старт проката в России:"
            :invalid-feedback="errors.dateStart"
            :state="states.dateStart"
          >
            <date
              size="sm"
              :value="item.dateStart"
              :state="states.dateStart"
              :disabled="loading"
              @selected="updateValue('dateStart', $event)"
            ></date>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Конец проката в России:"
            :invalid-feedback="errors.dateEnd"
            :state="states.dateEnd"
          >
            <date
              size="sm"
              :value="item.dateEnd"
              :state="states.dateEnd"
              :disabled="loading"
              @selected="updateValue('dateEnd', $event)"
            ></date>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group
        label="Постер:"
        :invalid-feedback="errors.posterPath"
        :state="states.posterPath"
      >
        <b-row class="p-0">
          <b-col>
            <file-uploader
              ref="posterUploader"
              :disabled="loading"
              @change="uploadPoster($event)"
            ></file-uploader>
          </b-col>

          <b-col>
            <image-viewer
              v-if="item.posterUrl"
              :image="item.posterUrl"
              @remove="resetPoster()"
            ></image-viewer>
            <div
              v-if="!item.posterUrl && !$fileManager2.files[posterFileId]"
              class="text-center"
            >
              <img :src="noPhoto" class="w-50" />
            </div>
            <div
              v-if="!item.posterUrl && $fileManager2.files[posterFileId]"
              class="loader"
            >
              <template v-if="!$fileManager2.files[posterFileId].error"><span class="mr-2">{{ $fileManager2.files[posterFileId].progress }}%</span> <b-spinner variant="primary"></b-spinner></template>
              <template v-else>Ошибка при загрузке постера!</template>
            </div>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label="Трейлер:"
        :invalid-feedback="errors.trailerPath"
        :state="states.trailerPath"
      >
        <b-row class="p-0">
          <b-col>
            <file-uploader
              ref="trailerUploader"
              accept="video/*"
              :disabled="loading"
              :multiple="false"
              @change="uploadTrailer($event)"
            ></file-uploader>
          </b-col>

          <b-col>
            <video-viewer
              v-if="item.trailerUrl"
              :video="item.trailerUrl"
              @remove="resetTrailer()"
            ></video-viewer>
            <div
              v-if="!item.trailerUrl && !$fileManager2.files[trailerFileId]"
              class="text-center"
            >
              <img :src="noPhoto" class="w-50" />
            </div>
            <div
              v-if="!item.trailerUrl && $fileManager2.files[trailerFileId]"
              class="loader"
            >
              <template v-if="!$fileManager2.files[trailerFileId].error"><span class="mr-2">{{ $fileManager2.files[trailerFileId].progress }}%</span> <b-spinner variant="primary"></b-spinner></template>
              <template v-else>Ошибка при загрузке трейлера!</template>
            </div>
          </b-col>
        </b-row>
      </b-form-group>

      <div class="border-bottom d-flex align-items-center justify-content-between pb-2 mb-3 mt-3">
        <div class="text-uppercase">Форматы</div>
        <b-button
          v-if="item.releases === undefined || (item.releases && item.releases.length !== formats.length)"
          size="sm"
          variant="outline-info"
          :disabled="loading"
          @click="addRelease()"
        >
          <icon icon="fa-plus"></icon> Добавить формат
        </b-button>
      </div>

      <div
        class="text-center"
        v-if="!item.releases || (!item.releases && !item.releases.length)"
      >Нет форматов</div>

      <div
        v-for="(release, index) in item.releases"
        :key="index"
        class="border-bottom items-container mb-4"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Формат:"
              :invalid-feedback="errors[`releases.${index}.formatId`]"
              :state="states[`releases.${index}.formatId`]"
            >
              <b-form-select
                size="sm"
                :value="release.formatId"
                :state="states[`releases.${index}.formatId`]"
                :disabled="release.id !== '' || loading"
                @change="updateRelease(index, { type: 'formatId', value: $event })"
              >
                <b-form-select-option
                  v-if="release.formatId"
                  :value="release.formatId"
                >{{ formatNameRelease(release.formatId) }}</b-form-select-option>
                <template v-for="(format, formatIndex) in formats">
                  <b-form-select-option
                    v-if="(release.id !== '' || filterRelease(format.id))"
                    :key="formatIndex"
                    :value="format.id"
                  >{{ format.name }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Продолжительность:"
              :invalid-feedback="errors[`releases.${index}.duration`]"
              :state="states[`releases.${index}.duration`]"
            >
              <b-input-group size="sm" append="мин">
                <b-form-input
                  type="number"
                  :value="release.duration"
                  :state="states[`releases.${index}.duration`]"
                  :disabled="loading"
                  @change="updateRelease(index, { type: 'duration', value: $event })"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <span
          class="items-container__trash-button"
          :disabled="loading"
          @click="removeRelease(release.formatId)"
        >
          <icon icon="fa-times"></icon>
        </span>
      </div>
    </b-container>
  </b-tab>
  <!-- <b-tab title="Трейлеры">
    <movie-form-trailers
      :trailers="item.movieTrailers"
      @change="updateValue('movieTrailers', $event)"
    ></movie-form-trailers>
  </b-tab> -->
  <!-- <b-tab title="Файлы">
    <movie-form-files
      :files="item.movieFiles"
      @change="updateValue('movieFiles', $event)"
    ></movie-form-files>
  </b-tab> -->
  <b-tab title="Настройки">
    <b-container fluid class="p-0">
      <b-row v-if="$user.can('movies.edit_report_name')">
        <b-col>
          <b-form-group
            label="Наименование для отчетов:"
            :invalid-feedback="errors.reportName"
            :state="states.reportName"
          >
            <b-form-input
              type="text"
              size="sm"
              :value="item.reportName"
              :state="states.reportName"
              :disabled="loading"
              @change="updateValue('reportName', $event)"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="$user.can('movies.edit_report_name')">
        <b-col>
          <b-form-group
            label="Номер прокатного удостоверения для отчетов:"
            :invalid-feedback="errors.reportRentalLicenseNumber"
            :state="states.reportRentalLicenseNumber"
          >
            <b-form-input
              type="text"
              size="sm"
              :value="item.reportRentalLicenseNumber"
              :state="states.reportRentalLicenseNumber"
              :disabled="loading"
              @change="updateValue('reportRentalLicenseNumber', $event)"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group
        class="mt-2"
        :disabled="loading"
        :invalid-feedback="errors.includeEkb"
        :state="states.includeEkb"
      >
        <b-form-checkbox
          :checked="!!item.includeEkb"
          :state="states.includeEkb"
          :disabled="loading"
          @change="updateValue('includeEkb', $event)"
        >
          Выгружать данные о продажах в ЕАИС
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        class="mt-2"
        :disabled="loading"
        :invalid-feedback="errors.includeRentrak"
        :state="states.includeRentrak"
      >
        <b-form-checkbox
          :checked="!!item.includeRentrak"
          :state="states.includeRentrak"
          :disabled="loading"
          @change="updateValue('includeRentrak', $event)"
        >
          Выгружать данные о продажах в Рентрак
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        class="mt-2"
        :disabled="loading"
        :invalid-feedback="errors.includeRao"
        :state="states.includeRao"
      >
        <b-form-checkbox
          :checked="!!item.includeRao"
          :state="states.includeRao"
          :disabled="loading"
          @change="updateValue('includeRao', $event)"
        >
          Включать данные о продажах в отчеты РАО
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        class="mt-2"
        :disabled="loading"
        :invalid-feedback="errors.displayDuration"
        :state="states.displayDuration"
      >
        <b-form-checkbox
          :checked="!!item.displayDuration"
          :state="states.displayDuration"
          :disabled="loading"
          @change="updateValue('displayDuration', $event)"
        >
          Показывать продолжительность фильма
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        class="mt-2"
        :disabled="loading"
        :invalid-feedback="errors.culturalEvent"
        :state="states.culturalEvent"
      >
        <b-form-checkbox
          :checked="!!item.culturalEvent"
          :state="states.culturalEvent"
          :disabled="loading"
          @change="updateValue('culturalEvent', $event)"
        >
          Культурное мероприятие
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        class="mt-2"
        :disabled="loading"
        :invalid-feedback="errors.useBonuses"
        :state="states.useBonuses"
      >
        <b-form-checkbox
          :checked="!!item.useBonuses"
          :state="states.useBonuses"
          :disabled="loading"
          @change="updateValue('useBonuses', $event)"
        >
          Разрешить использовать бонусы
        </b-form-checkbox>
      </b-form-group>

    </b-container>
  </b-tab>
</b-tabs>
</template>

<script>
import { mapState } from 'vuex';

import noPhoto from '../../assets/no_photo.png';

import DictionaryProvider from '../../providers/DictionaryProvider';
import {
  API_SERVER, API_PORT,
} from '../../config';
import Date from '../../components/fields/Date.vue';
import FileUploader from '../../components/fields/FileUploader.vue';
import ImageViewer from '../../components/ImageViewer.vue';
import VideoViewer from '../../components/VideoViewer.vue';
import ToggleList from '../../components/fields/ToggleList.vue';

export default {
  components: {
    Date,
    DictionaryProvider,
    FileUploader,
    ImageViewer,
    ToggleList,
    VideoViewer,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      noPhoto,
      posterFileId: -1,
      trailerFileId: -1,
    };
  },
  computed: {
    ...mapState('dictionaries/formats2', {
      formats: (state) => state.items,
    }),

    allFormatReleases() {
      return this.item.releases && this.item.releases.map(({ formatId }) => formatId);
    },

    trailer() {
      return this.item.trailer?.file && `${API_SERVER + (API_PORT ? `:${API_PORT}` : '')}/${this.item.trailer.file.filepath}`;
    },
  },
  async created() {
    await this.$store.dispatch('dictionaries/formats2/fetchItems');
  },
  methods: {
    removeRelease(formatId) {
      this.updateValue('releases', this.item.releases.filter((release) => release.formatId !== formatId));
    },

    addRelease() {
      let copy = [];
      if (this.item && this.item.releases) {
        copy = [...this.item.releases];
      }

      copy.push({ id: '' });

      this.updateValue('releases', copy);
    },

    filterRelease(formatId) {
      return !this.allFormatReleases.includes(formatId);
    },

    formatNameRelease(formatId) {
      return this.formats?.find((format) => format.id === formatId)?.name;
    },

    updateRelease(index, { type, value }) {
      const copy = [...this.item.releases];

      copy[index][type] = value;

      this.updateValue('releases', copy);
    },

    async uploadPoster(files) {
      this.updateValue('posterPath', '');
      this.updateValue('posterUrl', '');

      this.$fileManager2.upload(files[0]).then((data) => {
        this.$refs.posterUploader.reset();

        if (!data) {
          return;
        }

        this.updateValue('posterPath', data.path);
        this.updateValue('posterUrl', data.url);

        this.posterFileId = -1;
      });

      this.posterFileId = this.$fileManager2.getFileIndex(files[0]);
    },

    resetPoster() {
      this.updateValue('posterPath', '');
      this.updateValue('posterUrl', '');

      this.$refs.posterUploader.reset();
      this.posterFileId = -1;
    },

    async uploadTrailer(files) {
      this.updateValue('trailerPath', '');
      this.updateValue('trailerUrl', '');

      this.$fileManager2.upload(files[0]).then((data) => {
        this.$refs.trailerUploader.reset();

        if (!data) {
          return;
        }

        this.updateValue('trailerPath', data.path);
        this.updateValue('trailerUrl', data.url);

        this.trailerFileId = -1;
      });

      this.trailerFileId = this.$fileManager2.getFileIndex(files[0]);
    },

    resetTrailer() {
      this.updateValue('trailerPath', '');
      this.updateValue('trailerUrl', '');

      this.$refs.trailerUploader.reset();
      this.trailerFileId = -1;
    },

    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .card-header {
  background-color: inherit;
  padding-top: 0;
}
.items-container {
  position: relative;

  &__trash-button {
    position: absolute;
    right: 0;
    top: -4px;
    color: #AA0000;
    cursor: pointer;
  }
}
video {
  width: 100%;
  height: 100%;
  display: block;
}
.loader {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
</style>
