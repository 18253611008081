<template>
  <div class="date-time-popover">
    <b-input-group
      class="date-time-popover__group"
      :size="size"
      :id="id"
    >
      <template #prepend>
        <div
          class="d-flex align-items-center h-100 p-1 border rounded-left date-time-popover__bg"
          @click="showPicker = !showPicker"
        >
          <icon icon="fa-calendar"></icon>
        </div>
      </template>

      <b-form-input
        readonly
        type="text"
        class="date-time-popover__bg"
        :state="state"
        :value="localValue"
        :placeholder="placeholder"
        @click.native="showPicker = !showPicker"
      ></b-form-input>

      <template #append>
        <div
          v-if="value"
          class="d-flex align-items-center h-100 p-1 border border-left-0 rounded-right date-time-popover__trash cursor-pointer"
          @click="$emit('change', null)"
        >
          <icon icon="fa-times"></icon>
        </div>
      </template>
    </b-input-group>
    <b-popover
      placement="right"
      boundary="window"
      triggers=""
      :target="id"
      :show.sync="showPicker"
    >
      <date-picker
        :value="localValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :config="options"
        @dp-change="onSelected"
        v-click-outside="closePicker"
      ></date-picker>

    </b-popover>
  </div>
</template>

<script>
import moment from 'moment';

import datePicker from 'vue-bootstrap-datetimepicker';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    state: {
      default: null,
    },
    size: {
      default: 'sm',
    },
    format: {
      type: String,
      default: 'DD.MM.YYYY HH:mm',
    },
    useCurrent: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: 'ru',
    },
    placeholder: {
      type: String,
    },
    formatOutput: {
      type: String,
      default: 'YYYY-MM-DD HH:mm',
    },
  },
  components: {
    datePicker,
  },
  data() {
    return {
      idPrefix: 'date_time_popover_',
      showPicker: false,
    };
  },
  computed: {
    id() {
      return this.idPrefix + Math.random() * 1000000;
    },

    options() {
      return {
        format: this.format,
        useCurrent: this.useCurrent,
        locale: this.locale,
        inline: true,
      };
    },

    localValue() {
      if (!this.value) {
        return '';
      }

      return moment(this.value, this.formatOutput).format(this.format);
    },
  },
  methods: {
    closePicker() {
      this.showPicker = false;
    },

    onSelected({ date }) {
      if (moment(date).isSame(moment(this.value))) {
        return;
      }

      this.$emit('change', date ? moment(date).format(this.formatOutput) : '');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

.date-time-popover__trash {
  background: rgba(#6c757d, 0.1);
}
.popover {
  min-width: 300px;
}
</style>
