<template>
  <div>
    <div class="text-uppercase border-bottom d-flex justify-content-between pb-2 mb-3">
      <div class="align-self-end">Контакты</div>
      <b-button
        size="sm"
        variant="outline-info"
        :disabled="disabled"
        @click="addContact()"
      >
        <icon icon="fa-plus"></icon> Добавить контакт
      </b-button>
    </div>
    <div
      class="text-center"
      v-if="!contacts || (contacts && !contacts.length)"
    >Нет контактов</div>
    <div>
      <div
        v-for="(contact, index) in contacts"
        :key="index"
        class="border-bottom contact-container mb-3 pb-2"
        :class="{'contact-container_new': !contact.id}"
      >
        <b-row>
          <b-col>
            <b-form-group label="Заголовок:">
              <b-form-input
                type="text"
                size="sm"
                :value="contact.title"
                :disabled="disabled"
                @change="setValue(index, 'title', $event)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Тип:"
              :invalid-feedback="contact.contactTypeId"
            >
              <dictionary-provider name="contactTypes">
                <template #default="{ items }">
                  <b-form-select
                    size="sm"
                    :value="contact.contactTypeId"
                    :options="$array.convertToOptions(items)"
                    :text="contact.contactType ? contact.contactType.title : null"
                    :disabled="loading"
                    @change="setValue(index,'contactTypeId', $event)"
                  ></b-form-select>
                </template>
              </dictionary-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Значение:">
              <b-form-input
                type="text"
                size="sm"
                :value="contact.value"
                :disabled="disabled"
                @change="setValue(index, 'value', $event)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <span
          class="contact-container__trash-button"
          :disabled="disabled"
          @click="removeContact(index)"
        >
          <icon icon="fa-times"></icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { MaskHelper } from '../../utils/mask';
import { placeholder } from '../../utils/placeholder';
import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    DictionaryProvider,
  },
  props: {
    contacts: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      placeholder,
      mask: MaskHelper,
    };
  },
  methods: {
    removeContact(index) {
      const copy = [...this.contacts];

      copy.splice(index, 1);

      this.emitChange(copy);
    },

    addContact() {
      this.emitChange([{}, ...this.contacts]);
    },

    setValue(index, field, value) {
      const copy = [...this.contacts];

      const item = copy[index];

      copy.splice(index, 1, {
        ...item,
        [field]: value,
      });

      this.emitChange(copy);
    },

    emitChange(contacts) {
      this.$emit('change', contacts);
    },
  },
};
</script>

<style scoped>
.contact-container {
  position: relative;
}
.contact-container__trash-button {
  position: absolute;
  right: 0;
  top: -4px;
  color: #AA0000;
  cursor: pointer;
}
.contact-container_new .form-control {
  border-color: #0a0;
}
</style>
