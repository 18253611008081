<template>
  <div class="table-container">
    <collection-provider
      collection="webMenus"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #cell(cities)="data">{{ $array.convertToText(data.item.cities) }}</template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.webMenu.show"
      create-title="Создание меню"
      edit-title="Редактирование меню"
      collection="webMenus"
      :id="modals.webMenu.id"
      :init="{ webPages: [] }"
      @close="modals.webMenu.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <web-menu-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></web-menu-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import WebMenuForm from './WebMenuForm.vue';

export default {
  components: {
    CollectionProvider,
    ModalModelForm,
    Datatable,
    WebMenuForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        webMenu: {
          show: false,
          id: 0,
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateWebMenuForm() {
      this.modals.webMenu.id = '';
      this.modals.webMenu.show = true;
    },

    async removeCheckedWebMenus() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    openEditWebMenuForm(id) {
      this.modals.webMenu.id = id;
      this.modals.webMenu.show = true;
    },

    async removeWebMenu(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>

</style>
