import humps from 'humps';

import {
  generateSchedulesForSettings,
  previewHtml,
  getPreviewForSettings,
  getScreenshotStatus,
  requestOnDownloadScreenshot,
  getScreenshot,
  getLogs,
  getLogsTags,
  getLogsLevels,
  getFilesAndDirectory,
  uploadFile,
  registerFileOrDirectory,
  getStorage,
  updateFileOrDirectory,
  removeFileOrDirectory,
} from '../api/mediaPanels';

function convertBlobToString(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener('loadend', (e) => {
      resolve(e.srcElement.result);
    });

    reader.readAsDataURL(blob);
  });
}

export default {
  async generateSchedulesForSettings(id) {
    const [err, data] = await generateSchedulesForSettings(id);

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  // preview
  async previewHtml(params) {
    const [err, data] = await previewHtml(humps.decamelizeKeys(params));

    return [humps.camelizeKeys(err), data];
  },

  async getPreviewForSettings(settings) {
    const [err, data] = await getPreviewForSettings(
      humps.decamelizeKeys(settings),
    );

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  // screenshot
  async getScreenshotStatus(screenId) {
    const [err, data] = await getScreenshotStatus(humps.decamelizeKeys(screenId));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async requestOnDownloadScreenshot(screenId) {
    const [err, data] = await requestOnDownloadScreenshot(
      humps.decamelizeKeys(screenId),
    );

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async getScreenshot(screenId) {
    let [err, data] = await getScreenshot(humps.decamelizeKeys(screenId));

    if (data instanceof Blob) {
      data = await convertBlobToString(data);
    }

    return [humps.camelizeKeys(err), data];
  },

  // logs
  async getLogs({ filter, page, limit }) {
    const [err, data] = await getLogs({
      filter: humps.decamelizeKeys(filter),
      page: humps.decamelizeKeys(page),
      limit,
    });

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async getLogsTags({ filter }) {
    const [err, data] = await getLogsTags({
      filter: humps.decamelizeKeys(filter),
    });

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async getLogsLevels({ filter }) {
    const [err, data] = await getLogsLevels({
      filter: humps.decamelizeKeys(filter),
    });

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  // library
  async getFilesAndDirectory({ sort, params }) {
    const [err, data] = await getFilesAndDirectory({
      sort: {
        by: sort && sort.by ? humps.decamelize(sort.by) : '',
        desc: sort ? sort.desc : false,
      },
      params: humps.decamelizeKeys(params),
    });

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async registerFileOrDirectory(resourceInfo) {
    const [err, data] = await registerFileOrDirectory(humps.decamelizeKeys(resourceInfo));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async uploadFile(id, formData, config) {
    const [err, data] = await uploadFile(id, formData, config);

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async removeFileOrDirectory(id) {
    const [err, data] = await removeFileOrDirectory(id);

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async getStorage() {
    const [err, data] = await getStorage();

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async updateFileOrDirectory(id, name) {
    const [err, data] = await updateFileOrDirectory(id, name);

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },
};
