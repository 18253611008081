<template>
  <div class="top d-flex justify-content-between p-3">
    <div class="d-flex">
      <span class="page-title">{{title}}</span>
      <portal-target name="topbar" class="d-flex"></portal-target>
    </div>
    <breadcrumb :items="breadcrumbs"></breadcrumb>
  </div>
</template>

<script>
import Breadcrumb from './Breadcrumb.vue';

export default {
  components: {
    Breadcrumb,
  },
  computed: {
    title() {
      return this.$store.getters['page/title'];
    },

    breadcrumbs() {
      return this.$store.getters['page/breadcrumbs'];
    },
  },
};
</script>

<style scoped>
.top {
  background: #f1f2f7;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.top .page-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
}
</style>
