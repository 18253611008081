<template>
  <b-input-group>
    <template #prepend>
      <b-dropdown
        size="sm"
        variant="outline-primary"
        no-caret
        :disabled="disabled"
        :text="text"
      >
        <b-dropdown-item
          v-for="operation in operations"
          :key="operation.value"
          @click="$emit('change-operation', operation.value)"
        >{{operation.text}}</b-dropdown-item>
      </b-dropdown>
    </template>
    <b-form-input
      size="sm"
      type="number"
      :value="value"
      :disabled="disabled"
      @change="$emit('change-value', $event)"
    ></b-form-input>
  </b-input-group>
</template>

<script>
export default {
  props: {
    value: {
      default: '',
    },
    operation: {
      default: '=',
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      operations: [
        {
          value: '<',
          text: 'Менее',
        }, {
          value: '<=',
          text: 'Не более',
        }, {
          value: '=',
          text: 'Равно',
        }, {
          value: '>=',
          text: 'Не менее',
        }, {
          value: '>',
          text: 'Более',
        }],
    };
  },
  computed: {
    text() {
      return this.operations.find((operation) => operation.value === this.operation)?.text;
    },
  },
};
</script>
