var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border rounded"},[_c('div',{staticClass:"border-bottom text-center font-weight-semibold py-1"},[_vm._v(_vm._s(_vm.title))]),_vm._l((['high', 'normal', 'low']),function(monetary,index){return _c('div',{key:monetary,staticClass:"d-flex align-items-center mx-3 py-2",class:{'border-top': index > 0}},[_c('b-checkbox',{attrs:{"checked":_vm.isChecked(_vm.recency, _vm.frequency, monetary),"disabled":_vm.disabled},on:{"change":function($event){return _vm.toggleChecked(_vm.recency, _vm.frequency, monetary)}}}),_c('img',{attrs:{"src":require(("@/assets/analytics/rfm_recency_" + _vm.recency + ".svg"))}}),_c('img',{attrs:{"src":require(("@/assets/analytics/rfm_frequency_" + _vm.frequency + ".svg"))}}),_c('img',{attrs:{"src":require(("@/assets/analytics/rfm_monetary_" + monetary + ".svg"))}}),_c('div',{staticClass:"ml-3 mw-50"},[_vm._v(_vm._s(_vm.getMainSegmentCount(monetary).toLocaleString()))]),_c('div',{staticClass:"ml-1 text-small",class:{
        'text-success': _vm.getMainSegmentCount(monetary) > _vm.getOffsetSegmentCount(monetary),
        'text-danger': _vm.getMainSegmentCount(monetary) < _vm.getOffsetSegmentCount(monetary),
      }},[_vm._v(_vm._s(_vm.formatDiff(_vm.getMainSegmentCount(monetary), _vm.getOffsetSegmentCount(monetary))))]),_c('div',{staticClass:"ml-auto text-right"},[_vm._v(_vm._s(_vm.getMainSegmentCountPercent(monetary))+"%")]),_c('div',{staticClass:"ml-1 mw-30 text-right text-small",class:{
        'text-success': _vm.getMainSegmentCountPercent(monetary) > _vm.getOffsetSegmentCountPercent(monetary),
        'text-danger': _vm.getMainSegmentCountPercent(monetary) < _vm.getOffsetSegmentCountPercent(monetary),
      }},[_vm._v(_vm._s(_vm.formatDiff(_vm.getMainSegmentCountPercent(monetary), _vm.getOffsetSegmentCountPercent(monetary), '%')))])],1)}),_c('div',{staticClass:"d-flex border-top"},[_c('div',{staticClass:"w-50 border-right text-center py-2 d-flex justify-content-center"},[_c('div',[_vm._v(_vm._s(_vm.mainSegmentTotalCount.toLocaleString()))]),_c('div',{staticClass:"ml-3 text-small",class:{
          'text-success': _vm.mainSegmentTotalCount > _vm.offsetSegmentTotalCount,
          'text-danger': _vm.mainSegmentTotalCount < _vm.offsetSegmentTotalCount,
        }},[_vm._v(_vm._s(_vm.formatDiff(_vm.mainSegmentTotalCount, _vm.offsetSegmentTotalCount)))])]),_c('div',{staticClass:"ml-1 w-50 text-center py-2 d-flex justify-content-center"},[_c('div',[_vm._v(_vm._s(_vm.mainSegmentCountPercent)+"%")]),_c('div',{staticClass:"ml-3 text-small",class:{
          'text-success': _vm.mainSegmentCountPercent > _vm.offsetSegmentCountPercent,
          'text-danger': _vm.mainSegmentCountPercent < _vm.offsetSegmentCountPercent,
        }},[_vm._v(_vm._s(_vm.formatDiff(_vm.mainSegmentCountPercent, _vm.offsetSegmentCountPercent, '%')))])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }