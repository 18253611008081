<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Наименование:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Описание:"
          :invalid-feedback="errors.note"
          :state="states.note"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.note"
            :state="states.note"
            :disabled="loading"
            @change="updateValue('note', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Права:"
          :invalid-feedback="errors.permissions"
          :state="states.permissions"
        >
          <dictionary-provider name="permissions">
            <template #default="{ items, loading: permissionsLoading }">
              <div>
                <b-form-input
                  type="text"
                  size="sm"
                  v-model="searchPermission"
                  class="mb-2"
                  placeholder="Поиск по названию"
                ></b-form-input>
                <b-form-checkbox-group
                  class="custom-checkbox-group"
                  stacked
                  :disabled="loading || permissionsLoading"
                  :state="states.permissions"
                  :checked="item.permissions"
                  :options="convertPermissionsToOptions(items)"
                  @change="updateValue('permissions', $event)"
                ></b-form-checkbox-group>
              </div>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import stringComparer from '../../utils/string';

export default {
  components: {
    DictionaryProvider,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      searchPermission: '',
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    convertPermissionsToOptions(items) {
      return [...items]
        .sort((item1, item2) => stringComparer(item1.name, item2.name))
        .map((item) => ({ value: item.id, text: item.name }))
        .filter(
          ({ text }) => (this.searchPermission
            ? text.toLowerCase().includes(this.searchPermission.toLowerCase())
            : true),
        );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
