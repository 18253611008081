export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    sortable: false,
    filterable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Наименование',
    sortable: false,
    filterable: false,
  },
  {
    key: 'url',
    type: 'text',
    label: 'Ссылка',
    sortable: false,
    filterable: false,
  },
];
