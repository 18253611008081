<template>
  <div>
    <div class="text-uppercase border-bottom d-flex justify-content-between pb-2 mb-3">
      <div class="align-self-end">Сотрудники</div>
      <b-button
        size="sm"
        variant="outline-info"
        :disabled="disabled"
        @click="addContact()"
      >
        <icon icon="fa-plus"></icon> Добавить сотрудника
      </b-button>
    </div>
    <div
      class="text-center"
      v-if="!contacts || (contacts && !contacts.length)"
    >Нет сотрудников</div>
    <div>
      <div
        v-for="(contact, index) in contacts"
        :key="index"
        class="border-bottom contact-container mb-3 pb-2"
        :class="{'contact-container_new': !contact.id}"
      >
        <b-row>
          <b-col>
            <b-form-group label="Должность:">
              <b-form-input
                type="text"
                size="sm"
                :value="contact.position"
                :disabled="disabled"
                @change="setValue(index, 'position', $event)"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Имя:">
              <b-form-input
                type="text"
                size="sm"
                :value="contact.name"
                :disabled="disabled"
                @change="setValue(index, 'name', $event)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Email:">
              <b-form-input
                type="text"
                size="sm"
                :value="contact.emails"
                :disabled="disabled"
                @change="setValue(index, 'emails', $event)"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Телефон:">
              <masked-input
                type="text"
                class="form-control form-control-sm"
                :placeholder="placeholder.PHONE"
                :value="contact.phones"
                :mask="mask.getPhoneMask"
                :guide="true"
                :disabled="disabled"
                :state="contact.phones"
                @change="setValue(index, 'phones', $event.target.value)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <span
          class="contact-container__trash-button"
          :disabled="disabled"
          @click="removeContact(index)"
        >
          <icon icon="fa-times"></icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import { MaskHelper } from '../../utils/mask';
import { placeholder } from '../../utils/placeholder';

export default {
  components: {
    MaskedInput,
  },
  props: {
    contacts: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      placeholder,
      mask: MaskHelper,
    };
  },
  methods: {
    removeContact(index) {
      const copy = [...this.contacts];

      copy.splice(index, 1);

      this.emitChange(copy);
    },

    addContact() {
      this.emitChange([{ id: '' }, ...this.contacts]);
    },

    setValue(index, field, value) {
      const copy = [...this.contacts];

      const item = copy[index];

      copy.splice(index, 1, {
        ...item,
        [field]: value,
      });

      this.emitChange(copy);
    },

    emitChange(contacts) {
      this.$emit('change', contacts);
    },
  },
};
</script>

<style scoped>
.contact-container {
  position: relative;
}
.contact-container__trash-button {
  position: absolute;
  right: 0;
  top: -4px;
  color: #AA0000;
  cursor: pointer;
}
.contact-container_new .form-control {
  border-color: #0a0;
}
</style>
