<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Название:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        autofocus
        type="text"
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Города:"
      :invalid-feedback="errors.cities"
      :state="states.cities"
    >
      <dictionary-provider name="cinemas">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="convertCinemas(items)"
            :value="item.cities"
            :disabled="loading"
            @change="updateValue('cities', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      v-if="item.webPages"
      label="Страницы:"
      :invalid-feedback="errors.webPages"
      :state="states.webPages"
    >
      <div v-if="!item.webPages.length">Нет страниц</div>
      <draggable
        v-else
        class="draggable-area"
        :sort="true"
        :forceFallback="true"
        :list="item.webPages"
        @end="endDrag()"
      >
        <div
          v-for="webPage in item.webPages"
          :key="webPage.id"
          class="d-flex align-items-center mb-1 cursor-move"
        >
          <div class="mr-1">
            <icon icon="fa-bars"></icon>
          </div>
          <div>{{ webPage.name }}</div>
          <div
            class="trash-button ml-auto"
            :disabled="loading"
            @click="removeWebPage(webPage.id)"
          >
            <icon icon="fa-times"></icon>
          </div>
        </div>

      </draggable>
    </b-form-group>

    <b-form-group
      label="Добавить страницу:"
    >
      <collection-provider
        collection="webPages"
        ref="collection"
        :page="null"
        :limit="null"
      >
        <template #default="{ data: { items, loading: webPagesLoading } }">
          <toggle-list
            :options="items"
            :value="[]"
            :searchable="true"
            :disabled="loading || webPagesLoading"
            :multiple="false"
            :hide-selected="false"
            :close-on-select="true"
            @change="addWebPage($event)"
          ></toggle-list>
        </template>
      </collection-provider>
    </b-form-group>
  </b-container>
</template>

<script>
import Draggable from 'vuedraggable';
import DictionaryProvider from '../../providers/DictionaryProvider';
import CollectionProvider from '../../providers/CollectionProvider';
import ToggleList from '../../components/fields/ToggleList.vue';

export default {
  components: {
    DictionaryProvider,
    CollectionProvider,
    ToggleList,
    Draggable,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {

    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    convertCinemas(cinemas) {
      return cinemas.reduce((cities, cinema) => {
        if (!cities.find((city) => city.id === cinema.city.id)) {
          return [...cities, { ...cinema.city }];
        }

        return cities;
      }, []);
    },

    addWebPage(value) {
      const webPage = value[0];

      if (this.item.webPages.find((_webPage) => _webPage.id === webPage.id)) {
        return;
      }

      this.updateValue('webPages', [
        ...this.item.webPages,
        {
          id: webPage.id,
          name: webPage.name,
          pivot: { position: this.item.webPages.length },
        },
      ]);
    },

    removeWebPage(id) {
      this.updateValue('webPages', this.item.webPages.filter((webPage) => webPage.id !== id));
    },

    endDrag() {
      this.updateValue('webPages', this.item.webPages.map((webPage, index) => ({
        id: webPage.id,
        name: webPage.name,
        pivot: { position: index },
      })));
    },
  },
};
</script>

<style scoped>
.trash-button {
  color: #AA0000;
  cursor: pointer;
}
.cursor-move {
  cursor: ns-resize;
}
</style>
