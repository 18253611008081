<template>
  <b-dropdown
    v-if="cinemas.length && cinemaId"
    variant="link"
    toggle-class="text-decoration-none"
    class="cinemas-select ml-1"
    :size="size"
    :disabled="disabled"
    @hidden="search = ''"
  >
    <template v-slot:button-content>
      <span class="cinemas-select__name">{{title}}</span>
    </template>
    <b-dropdown-form>
      <b-input
        placeholder="Поиск"
        v-model="search"
        :size="size"
      ></b-input>
    </b-dropdown-form>
    <b-dropdown-divider></b-dropdown-divider>
    <div class="cinemas-select__list">
      <b-dropdown-item-button
        v-for="cinema in filteredCinemas"
        :key="cinema.id"
        :active="cinema.id === cinemaId"
        @click="select(cinema.id)"
      >
        <div>{{cinema.name}}</div>
        <div class="cinemas-select__city">{{ cinema.city && cinema.city.name }}</div>
      </b-dropdown-item-button>
      <b-dropdown-item-button
        v-if="!filteredCinemas.length"
        :disabled="true"
      >Не найдено</b-dropdown-item-button>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  components: {
  },
  props: {
    cinemas: {
      default: () => [],
    },
    cinemaId: {
      default: null,
    },
    disabled: {
      default: false,
    },
    size: {
      default: 'sm',
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    cinema() {
      return this.cinemas.find((cinema) => cinema.id === this.cinemaId);
    },
    city() {
      return this.cinema?.city?.name;
    },
    title() {
      return this.city ? `${this.cinema?.name}, ${this.city}` : this.cinema?.name;
    },
    filteredCinemas() {
      return this.cinemas.filter((cinema) => {
        const search = this.search.toLowerCase();

        if (search) {
          if (cinema.name.toLowerCase().includes(search) || cinema?.city?.name.toLowerCase().includes(search)) {
            return true;
          }

          return false;
        }

        return true;
      });
    },
  },
  methods: {
    select(cinemaId) {
      if (cinemaId !== this.cinemaId) {
        this.$emit('change', cinemaId);
      }
    },
  },
};
</script>

<style scoped>
.cinemas-select >>> .dropdown-toggle {
  padding: 0;
  border: 0;
}
.cinemas-select__list {
  max-height: 700px;
  overflow-y: auto;
}
.cinemas-select >>> .dropdown-item, .cinemas-select >>> .b-dropdown-form {
  padding: 2px 10px;
}
.cinemas-select__name {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
.cinemas-select__city {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.cinemas-select >>> .active .cinemas-select__city {
  color: #fff;
}
</style>
