<template>
  <div class="table-container users">
    <collection-provider
      collection="users"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(roleId)>
            <dictionary-provider name="roles">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="data.filter.roleId"
                  :options="$array.convertToOptions(items)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('roleId', $event)"
                ></b-form-select>
              </template>
            </dictionary-provider>
          </template>
          <template #filter(cinemaId)>
            <dictionary-provider name="cinemas">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="data.filter.cinemaId"
                  :options="convertCinemas(items)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('cinemaId', $event)"
                >
                  <template #first>
                    <b-form-select-option :value="null"></b-form-select-option>
                  </template>
                </b-form-select>
              </template>
            </dictionary-provider>
          </template>
          <template #cell(name)="data">
            <div>{{data.item.name}}</div>
            <div
              class="text-muted mt-2"
              v-if="data.item.note"
            >{{data.item.note}}</div>
          </template>

          <template #cell(roleId)="data">
            {{$array.convertToText(data.item.roles)}}
          </template>

          <template #cell(cinemaId)="data">
            <user-cinemas :item="data.item"></user-cinemas>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.user.show"
      create-title="Создание пользователя"
      edit-title="Редактирование пользователя"
      collection="users"
      :id="modals.user.id"
      @close="modals.user.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <user-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></user-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import get from 'lodash.get';
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import DictionaryProvider from '../../providers/DictionaryProvider';
import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import UserForm from './UserForm.vue';
import UserCinemas from './UserCinemas';

export default {
  components: {
    CollectionProvider,
    ModalModelForm,
    Datatable,
    UserCinemas,
    UserForm,
    DictionaryProvider,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        user: {
          show: false,
          id: 0,
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateUserForm() {
      this.modals.user.id = '';
      this.modals.user.show = true;
    },

    async removeCheckedUsers() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    openEditUserForm(id) {
      this.modals.user.id = id;
      this.modals.user.show = true;
    },

    async removeUser(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        value: cinema.id,
        text: `${get(cinema, 'name', '')}, ${get(cinema, 'city.name', '')}`,
      }));
    },
  },
};
</script>
