export default [
  {
    key: 'status',
    type: 'text',
    label: 'Статус',
    filterable: false,
    sortable: false,
  },
  {
    key: 'cinema',
    type: 'text',
    label: 'Кинотеатр',
    filterable: false,
    sortable: false,
  },
  {
    key: 'orderId',
    type: 'text',
    label: 'Номер заказа',
    sortable: false,
    filterable: false,
  },
  {
    key: 'show',
    type: 'text',
    label: 'Сеанс',
    sortable: false,
    filterable: false,
  },  
  {
    key: 'amount',
    type: 'text',
    label: 'Размер платежа',
    sortable: false,
    filterable: false,
  },
  {
    key: 'cardNumber',
    type: 'text',
    label: 'Номер карты',
    sortable: false,
    filterable: false,
  },
  {
    key: 'paymentTypeId',
    type: 'select',
    label: 'Тип оплаты',
    sortable: false,
    filterable: false,
  },
  {
    key: 'appGroupId',
    type: 'select',
    label: 'Источник',
    sortable: false,
    filterable: false,
  },
  {
    key: 'customer',
    type: 'text',
    label: 'Клиент',
    sortable: false,
    filterable: false,
  },
  {
    key: 'createdAt',
    type: 'datetime',
    label: 'Создан',
    filterable: false,
    sortable: false,
  },
];
