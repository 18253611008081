<template>
  <b-modal
    centered
    ok-only
    ok-title="Закрыть"
    ok-variant="secondary"
    title-class="w-75 text-truncate text-nowrap"
    :title="title"
    :size="size"
    :visible="true"
    :busy="loading"
    :hide-footer="hideFooter"
    @hidden="$emit('hide')"
  >
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'lg',
    },
    title: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
img {
  max-width: 100%;
  max-height: 100%;
}
</style>
