<template>
  <div
    v-if="item.id"
    class="p-3"
  >
    <info-card class="mb-4" title="Общая информация">
      <info-card-row v-if="item.legalEntity">
        <template #label>Юридическое лицо</template>
        <template #value>{{item.legalEntity && item.legalEntity.name}}</template>
      </info-card-row>
      <info-card-row v-if="item.utcOffset">
        <template #label>Часовой пояс (смещение UTC)</template>
        <template #value>{{item.utcOffset}}</template>
      </info-card-row>
    </info-card>
    <info-card class="mb-4" title="Время работы">
      <info-card-row v-if="item.worktimeWeekday">
        <template #label>Время работы в будние дни</template>
        <template #value>{{item.worktimeWeekday}}</template>
      </info-card-row>
      <info-card-row v-if="item.worktimeHoliday">
        <template #label>Время работы в выходные дни</template>
        <template #value>{{item.worktimeHoliday}}</template>
      </info-card-row>
    </info-card>
    <info-card v-if="item.latitude && item.longitude" class="mb-4" title="Местоположение">
      <info-card-row v-if="item.address">
        <template #label>Адрес</template>
        <template #value>{{item.address}}</template>
      </info-card-row>
      <info-card-row>
        <template #label>Широта</template>
        <template #value>{{item.latitude}}</template>
      </info-card-row>
      <info-card-row>
        <template #label>Долгота</template>
        <template #value>{{item.longitude}}</template>
      </info-card-row>
      <info-card-row>
        <template #value>
          <yandex-map
            class="maps"
            zoom="15"
            :scroll-zoom="false"
            :coords="coords"
          >
            <ymap-marker
              :coords="coords"
              :marker-id="item.id"
            />
          </yandex-map>
        </template>
      </info-card-row>
    </info-card>
    <info-card class="mb-4" title="Контакты">
      <div
        v-for="contact in item.contacts"
        :class="{'mb-4': contact.title}"
        :key="contact.id"
      >
        <info-card-row v-if="contact.title">
          <template #label></template>
          <template #value><h4>{{contact.title}}</h4></template>
        </info-card-row>
        <info-card-row>
          <template #label>{{contact.contactType.name}}</template>
          <template #value>{{contact.value}}</template>
        </info-card-row>
      </div>
    </info-card>
    <info-card class="mb-4" title="Контакты службы поддержки">
      <info-card-row
        v-for="contact in item.supportContacts"
        :key="contact.id"
      >
        <template #label>{{contact.contactType.name}}</template>
        <template #value>{{contact.value}}</template>
      </info-card-row>
    </info-card>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';

import InfoCard from '../../components/card/InfoCard.vue';
import InfoCardRow from '../../components/card/InfoCardRow.vue';

export default {
  components: {
    yandexMap,
    ymapMarker,
    InfoCard,
    InfoCardRow,
  },
  props: {
    item: {
      default: () => ({}),
    },
  },
  computed: {
    coords() {
      return this.item && [
        this.item.latitude,
        this.item.longitude,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.maps {
  height: 400px;
  width: 600px;
}
</style>
