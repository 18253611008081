<template>
  <b-modal
    ref="modal"
    centered
    ok-variant="success"
    ok-title="Сохранить"
    cancel-title="Закрыть"
    :visible="true"
    :busy="loading"
    @ok="ok"
    @hidden="$emit('hide')"
  >
    <template #modal-title><h4>{{title}}</h4></template>
    <dictionary-provider
      name="appGroups"
      @loaded="onAppGroupsLoaded($event)"
    >
      <template #default="{ items: appGroups, loading: appGroupsLoading }">
        <b-container fluid class="p-0">
          <b-form-group label="Статус для группы приложений:">
            <b-form-checkbox
              v-for="appGroup in appGroups"
              :key="appGroup.id"
              :checked="isAppGroupChecked(appGroup)"
              :disabled="appGroupsLoading || loading"
              :class="[ isAppGroupChecked(appGroup) ? 'text-success' : 'text-danger' ]"
              @change="toggleAppGroup(appGroup)"
            >{{ appGroup.name }} ({{ isAppGroupChecked(appGroup) ? 'открыть продажи' : 'закрыть продажи' }})</b-form-checkbox>
          </b-form-group>
        </b-container>
      </template>
    </dictionary-provider>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';

import DictionaryProvider from '../../providers/DictionaryProvider';

import { SALES_STATUS_FORM_TYPE_CINEMA, SALES_STATUS_FORM_TYPE_HALL, SALES_STATUS_FORM_TYPE_SHOWS } from './constants';

export default {
  components: {
    DictionaryProvider,
  },
  props: {
    showIds: {
      default: () => [],
    },
    hallId: {
      default: null,
    },
    type: {
      default: null,
    },
    status: {
      default: false,
    },
  },
  data() {
    return {
      appGroups: [],
    };
  },
  computed: {
    ...mapState('data/sales', {
      loading: (state) => state.loading,
      cinemaId: (state) => state.cinemaId,
      date: (state) => state.date,
    }),

    title() {
      switch (this.type) {
        case SALES_STATUS_FORM_TYPE_CINEMA:
          return 'Статус продаж всех сеансов в кинотеатре на день';

        case SALES_STATUS_FORM_TYPE_HALL:
          return 'Статус продаж сеансов в зале на день';

        case SALES_STATUS_FORM_TYPE_SHOWS:
          return 'Статус продаж выбранных сеансов на день';

        default:
          return 'Статус продаж';
      }
    },
  },
  methods: {
    onAppGroupsLoaded(appGroups) {
      if (this.status) {
        this.appGroups = appGroups;
      }
    },

    isAppGroupChecked(appGroup) {
      return !!this.appGroups.find((ag) => ag.id === appGroup.id);
    },

    toggleAppGroup(appGroup) {
      if (this.isAppGroupChecked(appGroup)) {
        this.appGroups = this.appGroups.filter((ag) => ag.id !== appGroup.id);
      } else {
        this.appGroups = [...this.appGroups, appGroup];
      }
    },

    async ok(event) {
      event.preventDefault();

      if (this.type === SALES_STATUS_FORM_TYPE_SHOWS) {
        const promises = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const showId of this.showIds) {
          promises.push(this.$store.dispatch('data/sales/toggleSales', {
            showId,
            appGroups: this.appGroups,
          }));
        }

        await Promise.all(promises);
      } else {
        const params = {
          dates: [this.date],
          appGroups: this.appGroups,
        };

        if (this.type === SALES_STATUS_FORM_TYPE_HALL) {
          params.hallId = this.hallId;
        }

        await this.$store.dispatch('data/sales/toggleSales', params);
      }

      this.$refs.modal.hide();
    },
  },
};
</script>
