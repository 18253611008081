import { urlBase64Decode } from '../utils/base64';

const AUTH_TOKEN_KEY = 'authToken';

// "кэш" токена в памяти, чтобы не лазить постоянно в localStorage
let authToken;

export function getAuthToken() {
  if (authToken) {
    // берем токен с "кэша"
    return authToken;
  }

  return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function setAuthToken(token) {
  authToken = token;
  localStorage.setItem(AUTH_TOKEN_KEY, token);
}

export function resetAuthToken() {
  authToken = null;
  localStorage.setItem(AUTH_TOKEN_KEY, ''); // пишем всегда строку, запись null приведет к непредсказуемому поведению
}

export function decodeToken(token) {
  if (!token || token === '') {
    return null;
  }

  const parts = token.split('.');

  if (parts.length !== 3) {
    return null;
  }

  const decoded = urlBase64Decode(parts[1]);

  if (!decoded) {
    return null;
  }

  return JSON.parse(decoded);
}

export function getTokenExpirationDate(token) {
  const decoded = decodeToken(token);

  if (!decoded || !decoded.exp) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(decoded.exp);

  return date;
}

export function isTokenExpired(token, offsetSeconds = 0) {
  if (!token || token === '') {
    return true;
  }

  const date = getTokenExpirationDate(token);

  if (date === null) {
    return false;
  }

  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}
