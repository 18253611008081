<template>
  <div class="table-container tags-items">
    <collection-provider
      collection="tags"
      ref="collection"
      sort-by="position"
      :sort-desc="false"
      :page="null"
      :limit="1000"
    >
      <template #default="{ data, actions }">
        <datatable-drag
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          :local-sortable="true"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
          @change-positions="changePositions($event)"
        >
          <template #toolbar-content>
            <div class="d-flex align-items-center justify-content-between">
              <div class="w-300 mr-2">
                <dictionary-provider name="cinemas">
                  <template #default="{ items }">
                    <b-form-select
                      size="sm"
                      :value="data.filter.cinemas"
                      :options="convertCinemas(items)"
                      :disabled="data.loading"
                      @change="actions.changeFilter('cinemaId', $event)"
                    >
                      <template #first>
                        <b-form-select-option :value="null">Все кинотеатры</b-form-select-option>
                      </template>
                    </b-form-select>
                  </template>
                </dictionary-provider>
              </div>
              <div class="d-flex justify-content-between">
                <b-form-select
                  size="sm"
                  :value="data.filter.active"
                  :options="tagActiveStatuses"
                  :disabled="data.loading"
                  @change="actions.changeFilter('active', $event)"
                ></b-form-select>
              </div>
            </div>
          </template>

          <template #cell-releases="data">
            <tag-releases :item="data.item"></tag-releases>
          </template>

          <template #cell-period="data">
            <div class="w-300">
              <template v-if="data.item.dateStart">
                c {{formatDate(data.item.dateStart)}}
              </template>
              <template v-if="data.item.dateEnd">
                по {{formatDate(data.item.dateEnd)}}
              </template>
            </div>
          </template>

          <template #cell-color="data">
            <div
              class="tag-color"
              :style="`background-color: ${data.item.color};`"
            ></div>
          </template>

          <template #cell-cinemas="data">
            <tag-cinemas :item="data.item"></tag-cinemas>
          </template>

          <template #cell-active="data">
            {{getTagActiveName(data.item.active)}}
          </template>
        </datatable-drag>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.tags.show"
      create-title="Добавить метку"
      edit-title="Редактировать метку"
      collection="tags"
      :id="modals.tags.id"
      @close="modals.tags.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <tag-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></tag-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import DatatableDrag from '../../components/datatable/DatatableDrag.vue';

import DictionaryProvider from '../../providers/DictionaryProvider';
import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import TagCinemas from './TagCinemas.vue';
import TagReleases from './TagReleases.vue';
import TagForm from './TagForm.vue';
import datetime from '../../utils/datetime';

export default {
  components: {
    DictionaryProvider,
    CollectionProvider,
    ModalModelForm,
    DatatableDrag,
    TagCinemas,
    TagReleases,
    TagForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        tags: {
          show: false,
          id: 0,
        },
      },
      tagActiveStatuses: [
        {
          value: null,
          text: '',
        },
        {
          value: false,
          text: 'Нет',
        },
        {
          value: true,
          text: 'Да',
        },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return datetime.formatDate(date);
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        value: cinema.id,
        text: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    getTagActiveName(value) {
      return this.tagActiveStatuses.find((status) => status.value === value)?.text;
    },

    pauseCheckedTags() {
      this.$refs.collection.changeItems(this.datatable.checked, { active: 0 });
      this.datatable.checked = [];
    },

    resumeCheckedTags() {
      this.$refs.collection.changeItems(this.datatable.checked, { active: 1 });
      this.datatable.checked = [];
    },

    pauseTag(id) {
      this.$refs.collection.changeItem(id, { active: 0 });
    },

    resumeTag(id) {
      this.$refs.collection.changeItem(id, { active: 1 });
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateTagForm() {
      this.modals.tags.id = '';
      this.modals.tags.show = true;
    },

    openEditTagForm(id) {
      this.modals.tags.id = id;
      this.modals.tags.show = true;
    },

    async removeCheckedTags() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    async removeTag(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    async changePositions(values) {
      await this.$store.dispatch('data/tags/changePositions', { values });
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-color {
  border-radius: 3px;
  display: inline-block;
  width: 20px;
  height: 20px;
}
</style>
