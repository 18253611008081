<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="ФИО:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Пароль:"
          :invalid-feedback="errors.password"
          :state="states.password"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.password"
            :state="states.password"
            :disabled="loading"
            @change="updateValue('password', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Должность:"
          :invalid-feedback="errors.positionId"
          :state="states.positionId"
        >
          <dictionary-provider name="employeeTypes">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.positionId"
                :options="$array.convertToOptions(items)"
                :state="states.positionId"
                :disabled="loading"
                @change="updateValue('positionId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Телефон:"
          :invalid-feedback="errors.phone"
          :state="states.phone"
        >
          <masked-input
            type="text"
            class="form-control form-control-sm"
            :placeholder="placeholder.PHONE"
            :value="item.phone"
            :mask="mask.getPhoneMask()"
            :guide="true"
            :disabled="loading"
            :state="states.phone"
            @change="updateValue('phone', $event.target.value)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Примечание:"
          :invalid-feedback="errors.note"
          :state="states.note"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.note"
            :state="states.note"
            :disabled="loading"
            @change="updateValue('note', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import DictionaryProvider from '../../providers/DictionaryProvider';
import { placeholder } from '../../utils/placeholder';
import { MaskHelper } from '../../utils/mask';

export default {
  components: {
    DictionaryProvider,
    MaskedInput,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      placeholder,
      mask: MaskHelper,
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
