import humps from 'humps';

import {
  loginUser,
  logoutUser,
  fetchUser,
} from '../api/auth';

export default {
  loginUser({ email, password }) {
    return loginUser({ email, password });
  },

  logoutUser() {
    return logoutUser();
  },

  async fetchUser() {
    const [err, data] = await fetchUser();

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },
};
