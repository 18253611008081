<template>
  <analytics-rfm-provider
    v-bind="filter"
  >
    <template
      #default="{
        getRfmData,
        emailsCount,
        phonesCount,
        exportData,
        loading,
        allTicketsCount,
        allTicketsPrice,
        allOrdersCount,
        customersTicketsCount,
        customersTicketsPrice,
        customersCount,
        customersOrdersCount
      }"
    >
      <multi-view>
        <template #side>
          <params
            :filter="filter"
            :disabled="loading"
            @change="filter[$event.field] = $event.value"
          ></params>
        </template>

        <template #content>
          <div>
            <div class="d-flex p-2">
              <div class="mt-1">
                <dates
                  class="mb-2"
                  :value="filter.dates"
                  :disabled="loading"
                  @change="filter.dates = $event"
                ></dates>

                <cinemas
                  :cinemas="cinemas"
                  :value="filter.cinemaIds"
                  :disabled="loading"
                  @change="filter.cinemaIds = $event"
                ></cinemas>
              </div>

              <div class="ml-auto mt-1 mr-3 d-flex flex-column">
                <b-button
                  class="mb-2"
                  variant="outline-info"
                  size="sm"
                  :disabled="loading || !checkedItems.length"
                  @click="exportData(checkedItems), checkedItems = []"
                >Выгрузить контакты</b-button>

                <!-- <b-button
                  variant="outline-info"
                  size="sm"
                  :disabled="loading"
                >Перейти в выборку</b-button> -->
              </div>

              <div class="border rounded px-3 py-1 total-info">
                <h3>Найдено</h3>
                <div>База телефонов: {{ phonesCount.toLocaleString() }}</div>
                <div>База email: {{ emailsCount.toLocaleString() }}</div>
              </div>
            </div>

            <rfm-customers
              :titles="rfmTitles"
              :main-items="getRfmData(filter.dates[1])"
              :offset-items="getRfmData(filter.dates[0])"
              :disabled="loading"
              :checked-items="checkedItems"
              @change-checked="checkedItems = $event"
            ></rfm-customers>

            <rfm-money
              class="mx-2 my-4"
              :titles="rfmTitles"
              :main-items="getRfmData(filter.dates[1])"
              :offset-items="getRfmData(filter.dates[0])"
              :disabled="loading"
            ></rfm-money>

            <div class="mx-2 my-4 d-flex">
              <quick-rfm-customers
                class="w-50 mr-2"
                :items="getRfmData(filter.dates[1])"
                :disabled="loading"
                :percentage="false"
              ></quick-rfm-customers>

              <quick-rfm-customers
                class="w-50"
                :items="getRfmData(filter.dates[1])"
                :disabled="loading"
                :percentage="true"
              ></quick-rfm-customers>
            </div>

            <stat
              class="mx-2 my-4"
              :disabled="loading"
              :all-tickets-count="allTicketsCount"
              :all-tickets-price="allTicketsPrice"
              :all-orders-count="allOrdersCount"
              :customers-tickets-count="customersTicketsCount"
              :customers-tickets-price="customersTicketsPrice"
              :customers-count="customersCount"
              :customers-orders-count="customersOrdersCount"
            ></stat>

          </div>
        </template>
      </multi-view>
    </template>
  </analytics-rfm-provider>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import rfmTitles from './rfmTitles';

import MultiView from '../../../layout/MultiView.vue';
import Params from './Params.vue';
import Dates from './Dates.vue';
import Cinemas from './Cinemas.vue';
import RfmCustomers from './RfmCustomers.vue';
import RfmMoney from './RfmMoney.vue';
import QuickRfmCustomers from './QuickRfmCustomers.vue';
import Stat from './Stat.vue';

import AnalyticsRfmProvider from '../../../providers/AnalyticsRfmProvider';

import {
  OFFSET_MONTH, getDates,
} from './dates';

const RMF_FILTER_STORAGE_KEY = 'rfmFilter';

export default {
  components: {
    MultiView,
    Params,
    AnalyticsRfmProvider,
    Dates,
    Cinemas,
    RfmCustomers,
    RfmMoney,
    QuickRfmCustomers,
    Stat,
  },
  data() {
    return {
      rfmTitles,
      filter: {
        dates: getDates(new Date(), OFFSET_MONTH, 2),
        cinemaIds: [],
        period: 30,
        ticketsCount: 0,
        ticketsOperation: '>',
        ordersCount: 0,
        ordersOperation: '>',
        recencyLowValue: 10,
        recencyHighValue: 2,
        frequencyLowValue: 2,
        frequencyHighValue: 5,
        monetaryLowValue: 100,
        monetaryHighValue: 1000,
      },
      checkedItems: [],
    };
  },
  computed: {
    ...mapState('dictionaries/cinemas', {
      cinemas: (state) => state.items,
    }),
  },
  watch: {
    filter: {
      handler() {
        this.saveFilter();
      },
      deep: true,
    },
  },
  async created() {
    await this.fetchCinemas();
    this.filter.cinemaIds = this.cinemas.map((cinema) => cinema.id);

    this.loadFilter();
  },
  methods: {
    ...mapActions('dictionaries/cinemas', {
      fetchCinemas: 'fetchItems',
    }),

    saveFilter() {
      localStorage.setItem(RMF_FILTER_STORAGE_KEY, JSON.stringify(this.filter));
    },

    loadFilter() {
      const storageRfmFilter = localStorage.getItem(RMF_FILTER_STORAGE_KEY);

      if (storageRfmFilter) {
        const rfmFilter = JSON.parse(storageRfmFilter);

        this.filter = rfmFilter;

        this.filter.dates = getDates(new Date(), OFFSET_MONTH, 2);
      }
    },
  },
};
</script>

<style computed>
.total-info {
  width: 220px;
  background-color: rgba(220, 220, 220, 0.3);
}
</style>
