import FileSaver from 'file-saver';
import _factory from './_factory';
import datetime from '../../utils/datetime';
import collectionServiceFactory from '../../services/collectionServiceFactory';
import movieService from '../../services/Movie';

const moviesService = collectionServiceFactory('movies');

export default {
  ..._factory('movies', {
    state: {
      page: null,
      limit: null,
      loadingMoviesDirection: {
        next: true,
        prev: true,
      },
      selectedDate: new Date(),
    },

    actions: {
      async initLoadMoviesOfMonthForDate({
        commit, state: {
          page, limit, filter,
        },
      }, date) {
        commit('setLoading', true);

        const { dateStart, dateEnd } = datetime.getMonthByDate(date, 2);

        const [, data] = await moviesService.fetchItems({
          page, limit, filter: { ...filter, dateStart, dateEnd },
        });

        if (data) {
          const { items } = data;

          commit('setFilterValue', { field: 'dateStart', value: dateStart });
          commit('setFilterValue', { field: 'dateEnd', value: dateEnd });
          commit('setItems', { items });
          commit('changeSelectedDate', date);
        }

        commit('setLoading', false);
      },

      async loadMoviesOfNextMonthForDate({
        commit, state: {
          page, limit, filter, loadingMoviesDirection,
        },
      }, date) {
        if (!loadingMoviesDirection.next) return;

        commit('setLoading', true);

        const dateEnd = datetime.convertDateToDbFormat(datetime.findMonthEnd(date));
        const dateStart = datetime.convertDateToDbFormat(datetime.findMonthStart(date));

        const [, data] = await moviesService.fetchItems({
          page, limit, filter: { ...filter, dateEnd, dateStart },
        });

        if (data) {
          const { items } = data;

          if (items.length) {
            commit('pushItems', { items });
            commit('setFilterValue', { field: 'dateEnd', value: dateEnd });
            commit('changeSelectedDate', date);
          } else {
            commit('setLoadingMoviesDirection', { type: 'next', value: false });
          }
        }

        commit('setLoading', false);
      },

      async loadMoviesOfPrevMonthForDate({
        commit, state: {
          page, limit, filter, loadingMoviesDirection,
        },
      }, date) {
        if (!loadingMoviesDirection.prev) return;

        commit('setLoading', true);

        const dateEnd = datetime.convertDateToDbFormat(datetime.findMonthEnd(date));
        const dateStart = datetime.convertDateToDbFormat(datetime.findMonthStart(date));

        const [, data] = await moviesService.fetchItems({
          page, limit, filter: { ...filter, dateStart, dateEnd },
        });

        if (data) {
          const { items } = data;

          if (items.length) {
            commit('prependItems', { items });
            commit('setFilterValue', { field: 'dateStart', value: dateStart });
            commit('changeSelectedDate', date);
          } else {
            commit('setLoadingMoviesDirection', { type: 'prev', value: false });
          }
        }

        commit('setLoading', false);
      },

      changeFilterMovies({ commit, dispatch }, { field, value }) {
        commit('setLoadingMoviesDirection', { type: 'prev', value: true });
        commit('setLoadingMoviesDirection', { type: 'next', value: true });
        dispatch('changeFilter', { field, value });
      },

      async patchItem({ commit }, { item }) {
        if (!item) {
          return;
        }

        commit('patchItem', item);
      },

      async exportRepertory({ state }, {
        releaseIds,
        filename,
      }) {
        const [err, data] = await movieService.exportRepertory({
          releaseIds,
        });

        if (err) {
          return [err, null];
        }

        if (!err && data) {
          FileSaver.saveAs(data, filename);
        }
        return [null, true];
      },
    },

    mutations: {
      prependItems(state, { items }) {
        state.items = [...items, ...state.items];
      },

      changeSelectedDate(state, date) {
        state.selectedDate = date;
      },

      setLoadingMoviesDirection(state, { type, value }) {
        state.loadingMoviesDirection[type] = value;
      },

      patchItem(state, item) {
        const items = state.items.slice();
        const index = items.findIndex((value) => value.id === item.id);
        if (index === -1) {
          return;
        }
        items[index] = item;
        state.items = [...items];
      },
    },
  }),
};
