<template>
  <form
    class="p-2"
    @submit.prevent="handleSubmit"
  >
    <b-form-group label="Номер заказа">
      <b-form-input
        type="text"
        class="form-control form-control-sm"
        :disabled="loading"
        :value="filter.orderId"
        @change="setFilter({field: 'orderId', value: $event})"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Номер телефона">
      <b-form-input
        type="text"
        class="form-control form-control-sm"
        :disabled="loading"
        :value="filter.customerPhone"
        @change="setFilter({field: 'customerPhone', value: $event})"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Email">
      <b-form-input
        type="text"
        class="form-control form-control-sm"
        :disabled="loading"
        :value="filter.customerEmail"
        @change="setFilter({field: 'customerEmail', value: $event})"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Номер карты">
      <masked-input
        type="text"
        class="form-control form-control-sm"
        :disabled="loading"
        :mask="[/\d/, /\d/, /\d/, /\d/]"
        :placeholder="cardNumberPlaceholder"
        :guide="false"
        :value="filter.cardNumber"
        @change="setFilter({field: 'cardNumber', value: $event.target.value})"
      ></masked-input>
    </b-form-group>

    <b-form-group label="RRN">
      <b-form-input
        type="text"
        class="form-control form-control-sm"
        :disabled="loading"
        :value="filter.authRefNum"
        @change="setFilter({field: 'authRefNum', value: $event})"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Код авторизации платежа">
      <b-form-input
        type="text"
        class="form-control form-control-sm"
        :disabled="loading"
        :value="filter.approvalCode"
        @change="setFilter({field: 'approvalCode', value: $event})"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Размер платежа">
      <b-form-input
        type="text"
        class="form-control form-control-sm"
        :disabled="loading"
        :value="filter.amount"
        @change="setFilter({field: 'amount', value: $event})"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Дата и время транзакции (по мск)">
      <date-time-popover
        size="sm"
        class="mb-2"
        :value="filter.createdAtStart"
        :disabled="loading"
        @change="setFilter({ field: 'createdAtStart', value: roundToDayStart($event) })"
      ></date-time-popover>

      <date-time-popover
        size="sm"
        :value="filter.createdAtEnd"
        :disabled="loading"
        @change="setFilter({ field: 'createdAtEnd', value: roundToDayEnd($event) })"
      ></date-time-popover>
    </b-form-group>

    <div class="mt-4">
      <b-button
        variant="info"
        size="sm"
        type="submit"
        class="mr-2"
        :disabled="loading"
      >Применить</b-button>
    </div>
  </form>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import MaskedInput from 'vue-text-mask';
import { placeholder } from '../../utils/placeholder';
import { MaskHelper } from '../../utils/mask';
import DictionaryProvider from '../../providers/DictionaryProvider';
import FilterDropdown from '../../components/filters/Dropdown.vue';
import DateTimePopover from '../../components/fields/DateTimePopover.vue';

export default {
  components: {
    MaskedInput,
    DateTimePopover,
    DictionaryProvider,
    FilterDropdown,
  },
  props: {
    cinemaId: {
      default: null,
    },
    cardNumberPlaceholder: {
      default: '0000',
    },    
  },
  data() {
    return {
      placeholder,
      mask: MaskHelper,
    };
  },
  computed: {
    ...mapState('data/merchantPayments', {
      filter: (state) => state.filter,
      loading: (state) => state.loading,
    }),
  },
  destroyed() {
    this.resetFilter();
  },
  methods: {
    ...mapActions({
      setFilter: 'data/merchantPayments/setFilter',
      fetchItems: 'data/merchantPayments/fetchItems',
      resetFilter: 'data/merchantPayments/resetFilter',
    }),

    async handleSubmit() {
      await this.fetchItems();
    },

    roundToDayStart(value) {
      const now = new Date();
      const selected = new Date(value);

      if (now.getHours() !== selected.getHours() || now.getMinutes() !== selected.getMinutes()) {
        return value;
      }

      selected.setHours(0, 0, 0, 0);

      return moment(selected).format('YYYY-MM-DD HH:mm');
    },

    roundToDayEnd(value) {
      const now = new Date();
      const selected = new Date(value);

      if (now.getHours() !== selected.getHours() || now.getMinutes() !== selected.getMinutes()) {
        return value;
      }

      selected.setHours(23, 59, 0, 0);

      return moment(selected).format('YYYY-MM-DD HH:mm');
    },
  },
};
</script>
