<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Название:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            autofocus
            size="sm"
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Примечание:"
          :invalid-feedback="errors.note"
          :state="states.note"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.note"
            :state="states.note"
            :disabled="loading"
            @change="updateValue('note', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Эквайринг"
          :invalid-feedback="errors.useTerminal || errors.usepushkinsTerminal"
          :state="states.useTerminal || states.usePushkinsTerminal"
        >
          <b-form-checkbox
            :checked="!!item.useTerminal"
            :disabled="loading"
            @change="updateValue('useTerminal', $event)"
          >Прямое подключение терминала оплаты</b-form-checkbox>
          <b-form-checkbox
            :checked="!!item.usePushkinsTerminal"
            :disabled="loading"
            @change="updateValue('usePushkinsTerminal', $event)"
          >Прямое подключение терминала оплаты по пушкинской карте</b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <collection-provider
      collection="cashierDevices"
      :filter="{ cinemaId }"
      :disable-fetching="!cinemaId"
    >
      <template #default="{ data: { items: cashierDevices, loading: cashierDevicesLoading } }">
        <cashier-place-devices
          :devices="convertDevicesFromDbFormat(item.cashierPlaceDevices)"
          :available-devices="cashierDevices"
          :disabled="loading || cashierDevicesLoading"
          @change="updateValue('cashierPlaceDevices', convertDevicesToDbFormat($event))"
        ></cashier-place-devices>
      </template>
    </collection-provider>
  </b-container>
</template>

<script>
import CashierPlaceDevices from './CashierPlaceDevices.vue';
import CollectionProvider from '../../providers/CollectionProvider';

export default {
  components: {
    CashierPlaceDevices,
    CollectionProvider,
  },
  props: {
    cinemaId: {
      default: null,
    },
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    convertDevicesFromDbFormat(items) {
      return items ? items.map((item) => item.pivot) : [];
    },
    convertDevicesToDbFormat(items) {
      return items ? items.map((item) => ({ id: item.cashierDeviceId, pivot: item })) : [];
    },
  },
};
</script>
