import _factory from './_factory';
import dictionaryServiceFactory from '../../services/dictionaryServiceFactory';

const legalEntitiesService = dictionaryServiceFactory('legalEntities');

export default {
  ..._factory('legalEntities', {
    actions: {
      async fetchItems({ commit }, { filter } = {}) {
        commit('setLoading', true);

        const [, data] = await legalEntitiesService.fetchDictionary({ filter });

        if (data) {
          const { items, total } = data;

          commit('setItems', { items: items.map(({ name, inn, ...rest }) => ({ name: `${name} (${inn})`, ...rest })) });
          commit('setTotal', { total });
        }

        commit('setLoading', false);
      },
    },
  }),
};
