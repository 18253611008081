var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container customer-balance-operations"},[_c('collection-provider',{ref:"collection",attrs:{"collection":"customerOperations","sort-by":"id","sort-desc":true,"filter":{ customerId: _vm.id },"disable-fetchind":!_vm.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"show-top-toolbar":false,"show-bottom-toolbar":true},scopedSlots:_vm._u([{key:"cell(createdAt)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.createdAt ? _vm.$datetime.formatDateTime(data.item.createdAt) : '')+" ")]}},{key:"cell(text)",fn:function(ref){
var ref_item = ref.item;
var cinema = ref_item.cinema;
var show = ref_item.show;
var description = ref_item.description;
return [(show)?[_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(cinema.name)+" "+_vm._s(cinema.city.name))]),_c('div',{staticClass:"movie-name mb-1"},[_vm._v(_vm._s(show.release && show.release.movie && show.release.movie.name))]),_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$datetime.formatDate(show.date))+" "+_vm._s(_vm.$datetime.formatTime(show.time)))])]:_vm._e(),(description)?_c('div',[_vm._v(_vm._s(description))]):_vm._e()]}},{key:"cell(orderId)",fn:function(data){return [(data.item.orderId)?_c('span',[_vm._v(" "+_vm._s(data.item.orderId))]):_vm._e()]}},{key:"cell(value)",fn:function(data){return [(data.item.balanceSub)?_c('span',{staticClass:"text-danger"},[_vm._v("-"+_vm._s(data.item.balanceSub))]):_vm._e(),(data.item.balanceSub && data.item.balanceAdd)?_c('span',{staticClass:"text-danger"},[_vm._v(" / ")]):_vm._e(),(data.item.balanceAdd)?_c('span',{staticClass:"text-success"},[_vm._v("+"+_vm._s(data.item.balanceAdd))]):_vm._e()]}},{key:"cell(type)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.getTypeName(data.item.type)))])]}},{key:"cell(balance)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.balance))])]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }