<template>
  <dictionary-map
    :keys="appGroups"
    :items="createMapItems(appGroupFormulas)"
    :disabled="disabled"
    @change="onChangeMap"
    :texts="{
      add: 'Добавить группу приложений',
      empty: 'Группы приложений не указаны',
      key: 'Группа приложений',
      value: 'Изменение цены',
    }"
  >
    <template #value="{disabled, item, setValue}">
      <price-formula
        :value="item.value"
        :operations="operations"
        :disabled="disabled"
        @change="setValue($event)"
      ></price-formula>
    </template>
  </dictionary-map>
</template>

<script>
import PriceFormula from '../fields/PriceFormula.vue';
import DictionaryMap from './DictionaryMap.vue';

export default {
  components: {
    DictionaryMap,
    PriceFormula,
  },
  props: {
    disabled: {
      default: false,
    },
    appGroups: {
      default: () => [],
    },
    appGroupFormulas: {
      default: () => [],
    },
    operations: {
      default: () => ['-', '=', '+'],
    },
  },
  methods: {
    createMapItems(appGroupFormulas) {
      return appGroupFormulas
        ? appGroupFormulas.map(
          (appGroupFormula) => ({
            keyId: appGroupFormula.id,
            value: appGroupFormula.pivot.formula,
          }),
        )
        : [];
    },
    createAppGroupFormulas(mapItems) {
      return mapItems
        ? mapItems.map(
          (mapItem) => ({
            id: mapItem.keyId,
            pivot: { formula: mapItem.value },
          }),
        )
        : [];
    },
    onChangeMap(items) {
      this.$emit('change', this.createAppGroupFormulas(items));
    },
  },
};
</script>
