<template>
  <div class="d-flex align-items-center">
    <b-button-group size="sm">
      <b-button
        variant="outline-info"
        :pressed="offset == OFFSET_DAY"
        :disabled="disabled"
        @click="setOffset(OFFSET_DAY)"
      >День</b-button>
      <b-button
        variant="outline-info"
        :pressed="offset == OFFSET_WEEK"
        :disabled="disabled"
        @click="setOffset(OFFSET_WEEK)"
      >Неделя</b-button>
      <b-button
        variant="outline-info"
        :pressed="offset == OFFSET_MONTH"
        :disabled="disabled"
        @click="setOffset(OFFSET_MONTH)"
      >Месяц</b-button>
      <b-button
        variant="outline-info"
        :pressed="offset == OFFSET_QUARTER"
        :disabled="disabled"
        @click="setOffset(OFFSET_QUARTER)"
      >Квартал</b-button>
      <b-button
        variant="outline-info"
        :pressed="offset == OFFSET_YEAR"
        :disabled="disabled"
        @click="setOffset(OFFSET_YEAR)"
      >Год</b-button>
    </b-button-group>

    <date-button
      class="mx-3"
      :date="date"
      :disabled="disabled"
      @select="setDate($event)"
    ></date-button>

    <b-button-group size="sm">
      <b-button
        variant="outline-info"
        :disabled="disabled"
        @click="decreaseCount()"
      >-</b-button>
      <b-button
        variant="outline-info"
        :disabled="disabled"
        @click="increaseCount()"
      >+</b-button>
    </b-button-group>
  </div>
</template>

<script>
import DateButton from '../../../components/fields/DateButton.vue';

import {
  OFFSET_DAY, OFFSET_WEEK, OFFSET_MONTH, OFFSET_QUARTER, OFFSET_YEAR, getDates, getOffset,
} from '../rfm/dates';

export default {
  components: {
    DateButton,
  },
  props: {
    value: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      date: null,
      offset: null,
      count: 0,
      OFFSET_DAY,
      OFFSET_WEEK,
      OFFSET_MONTH,
      OFFSET_QUARTER,
      OFFSET_YEAR,
    };
  },
  watch: {
    value: {
      handler() {
        this.init();
      },
      immediate: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.date = this.value.length ? this.value[this.value.length - 1] : new Date();
      this.count = this.value.length;
      const offset = getOffset(this.value);

      if (offset) {
        this.offset = getOffset(this.value);
      }
    },

    emitValue() {
      this.$emit('change', getDates(this.date, this.offset, this.count));
    },

    setOffset(value) {
      this.offset = value;
      this.emitValue();
    },

    setDate(value) {
      this.date = value;
      this.emitValue();
    },

    decreaseCount() {
      if (this.count === 1) {
        return;
      }

      this.count -= 1;
      this.emitValue();
    },

    increaseCount() {
      this.count += 1;
      this.emitValue();
    },
  },
};
</script>
