import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Full from '../layout/Full.vue';
import Login from '../pages/Login.vue';
import NotFound from '../pages/NotFound.vue';
import Dashboard from '../pages/Dashboard.vue';
import marketing from './marketing';
import distribution from './distribution';
import administration from './administration';
import reports from './reports';
import clients from './clients';
import analytics from './analytics';

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '*',
      redirect: '/404',
      component: Full,
      children: [
        {
          path: '/404',
          component: NotFound,
          meta: {
            auth: true,
            title: 'Страница не найдена',
            breadcrumbs: [],
          },
        },
      ],
    },
    {
      name: 'login',
      path: '/login',
      component: Login,
      meta: {
        title: 'Вход',
      },
    },
    {
      path: '/logout',
      beforeEnter: () => {
        router.app.$user.logout();
      },
    },
    {
      path: '/',
      redirect: '/dashboard',
      component: Full,
      children: [
        {
          name: 'dashboard',
          path: 'dashboard',
          component: Dashboard,
          meta: {
            auth: true,
            title: 'Главная',
            breadcrumbs: [
              { name: 'Главная' },
            ],
          },
        },
      ],
    },
    reports,
    marketing,
    distribution,
    administration,
    clients,
    analytics,
  ],
});

router.beforeEach((to, from, next) => {
  router.app.$user.guard(to, next, '/login');

  if (to.name !== from.name) {
    if (to.meta && to.meta.title) {
      store.commit('page/setTitle', to.meta.title);
    }

    if (to.meta && to.meta.breadcrumbs) {
      store.commit('page/setBreadcrumbs', to.meta.breadcrumbs);
    }
  }
});

export default router;
