<template>
  <div class="timeline-ruler d-flex align-items-end">
    <div
      v-for="hour in hours"
      class="timeline-ruler__hour"
      :key="hour"
    >
      <div class="timeline-ruler__hour-name">{{hour}}</div>
      <div class="timeline-ruler__hour-lines d-flex">
        <div
          v-for="segment in segments"
          class="timeline-ruler__segment align-self-end"
          :key="segment"
          :class="getSegmentClasses(segment)"
          :style="getSegmentStyles()"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import datetime from '../../utils/datetime';

export default {
  props: {
    timeStart: {
      default: '06:00',
    },
    scale: {
      default: 5,
    },
    minuteWidth: {
      default: 1,
    },
  },
  computed: {
    hours() {
      return datetime.getHours(this.timeStart);
    },

    segments() {
      return Math.round(60 / this.scale);
    },
  },
  methods: {
    getSegmentClasses(segment) {
      return {
        first: segment === 1,
        half: segment === Math.round(60 / this.scale / 2 + 1),
      };
    },

    getSegmentStyles() {
      return {
        marginRight: `${this.scale * this.minuteWidth - 1}px`,
      };
    },
  },
};
</script>

<style scoped>
.timeline-ruler {
  height: 35px;
  border-bottom: 1px solid #ccc;
}
.timeline-ruler__segment {
  width: 1px;
  height: 4px;
  border-left: 1px solid #ccc;
}
.timeline-ruler__segment.first {
  height: 8px;
}
.timeline-ruler__segment.half {
  height: 6px;
}
</style>
