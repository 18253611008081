<template>
  <drop
    class="timeline-week d-flex flex-row justify-content-end"
    ref="root"
    :style="styles"
    :class="classes"
    @drop="onDrop(...arguments)"
    @dragenter="onDragEnter(...arguments)"
    @dragover="onDragOver(...arguments)"
    @dragleave="onDragLeave(...arguments)"
    @mouseover.native="mouseover = true"
    @mouseleave.native="mouseover = false"
  >
    <div
      v-for="(day, index) in week"
      class="timeline-week__day border-right border-bottom"
      :key="index"
      :class="{'timeline-week__day_holiday': day.holiday}"
      :data-day="true"
    >
    </div>
    <div
      class="timeline-week__add-button border-right border-bottom text-center cursor-pointer align-items-center"
      :style="buttonStyles"
      @click.stop.prevent="$emit('click', $event)"
      v-b-tooltip="'Добавить неделю проката'"
    >
      <icon
        icon="mdi-plus-circle-outline"
        class="m-auto"
      ></icon>
    </div>
  </drop>
</template>

<script>
import { Drop } from 'vue-drag-drop';

export default {
  components: {
    Drop,
  },
  props: {
    week: {
      requried: true,
      type: Array,
    },
    allowDrop: {
      default: true,
    },
    showAddButton: {
      default: false,
    },
    freeDays: {
      default: 7,
      type: Number,
    },
  },
  data() {
    return {
      dragover: false,
      mouseover: false,
      dragEnterCounter: 0,
    };
  },
  computed: {
    styles() {
      return {
        flexGrow: this.week.length,
      };
    },
    classes() {
      return {
        dragover: this.dragover,
        active: this.mouseover && this.showAddButton,
      };
    },
    buttonStyles() {
      const freeDays = Math.min(this.week.length, this.freeDays);
      const widthInPercent = (freeDays / this.week.length) * 100;

      return {
        width: `${widthInPercent}%`,
      };
    },
  },
  methods: {
    onDrop(release) {
      if (!this.allowDrop) {
        return;
      }

      this.dragover = false;
      this.dragEnterCounter = 0;

      this.$emit('drop', release);
    },

    onDragEnter(release, event) {
      if (!this.allowDrop) {
        return;
      }

      if (!event.target.dataset.day) {
        this.dragEnterCounter += 1;
      }

      this.dragover = true;
    },

    onDragOver() {
      if (!this.allowDrop) {
        return;
      }

      if (this.dragover) {
        this.$emit('dragover');
      }
    },

    onDragLeave(release, event) {
      if (!this.allowDrop) {
        return;
      }

      if (!event.target.dataset.day) {
        this.dragEnterCounter -= 1;
      }

      if (!this.dragEnterCounter) {
        this.dragover = false;
      }
    },
  },
};
</script>

<style scoped>
.timeline-week,
.timeline-week__day {
  min-height: 35px;
  height: 35px;
  flex: 1 0 0;
}
.timeline-week__add-button {
  display: none;
  background-color: #fff;
}
.timeline-week.dragover .timeline-week__day,
.timeline-week.active .timeline-week__day {
  display: none;
}
.timeline-week.dragover .timeline-week__add-button,
.timeline-week.active .timeline-week__add-button {
  display: flex;
  width: 100%;
}
.timeline-week__day_holiday {
  background-color: #f6f6f6;
}
</style>
