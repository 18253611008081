<template>
  <div class="calendar-layout d-flex border-top align-items-stretch">
    <div
      v-for="(date, index) in dates"
      :key="index"
      class="calendar-layout__day ml-1 mr-1 flex-grow-1"
    >
      <slot :name="`day(${index})`"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dates: {
      default: () => [],
    },
  },
};
</script>

<style scoped>
.calendar-layout {
  min-height: 43px;
  padding: 0 6px;
}
.calendar-layout__day {
  width: 0;
}
</style>
