<template>
  <model-provider
    collection="holidays"
    :id="holiday && holiday.id"
    :init="{ date: dbDate }"
    @error="notifyErrors"
    @saved="$emit('saved', $event)"
  >
    <template #default="{ data: { item, states, errors, loading }, actions: { save, setValue } }">
      <div>
        <b-form-group
          label="Работаем по:"
          :invalid-feedback="errors.asDay"
          :state="states.asDay"
        >
          <b-form-select
            size="sm"
            autofocus
            :options="days"
            :value="item.asDay"
            :state="states.asDay"
            :disabled="loading"
            @change="setValue('asDay', $event)"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Комментарий:">
          <b-form-input
            size="sm"
            type="text"
            :value="item.note"
            :disabled="loading"
            @change="setValue('note', $event)"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Регионы:">
          <dictionary-provider name="regions">
            <template #default="{ items }">
              <toggle-list
                :options="items"
                :value="item.regions"
                :disabled="loading"
                :hideSelected="false"
                :limit="3"
                @change="setValue('regions', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>
        <div class="d-flex">
          <b-button
            variant="outline-info"
            size="sm"
            class="mr-2"
            :disabled="loading"
            @click="save()"
          >Сохранить</b-button>
          <b-button
            v-if="item.id"
            variant="outline-danger"
            size="sm"
            :disabled="loading"
            @click="$emit('remove', item.id)"
          >Удалить</b-button>
        </div>
      </div>
    </template>
  </model-provider>
</template>

<script>
import datetime from '../../utils/datetime';
import ModelProvider from '../../providers/ModelProvider';
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
    ModelProvider,
  },
  props: {
    holiday: {
      default: null,
    },
    year: {
      default: null,
    },
    month: {
      default: null,
    },
    date: {
      default: null,
    },
  },
  computed: {
    dbDate() {
      const date = new Date(this.year, this.month - 1, this.date);
      return datetime.convertDateToDbFormat(date);
    },
  },
  data() {
    return {
      days: [
        { text: 'Понедельнику', value: '1' },
        { text: 'Вторнику', value: '2' },
        { text: 'Среде', value: '3' },
        { text: 'Четвергу', value: '4' },
        { text: 'Пятнице', value: '5' },
        { text: 'Субботе', value: '6' },
        { text: 'Воскресенью', value: '7' },
      ],
    };
  },
  methods: {
    notifyErrors(errors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const field of Object.keys(errors)) {
        this.$notify({
          type: 'error',
          text: errors[field],
        });
      }
    },
  },
};
</script>
