export default () => [
  {
    type: 'button',
    text: 'Добавить оборудование',
    variant: 'info',
    icon: 'fa-plus',
    class: 'mr-1',
    action: 'openCreateEquipmentForm',
    permission: 'equipment.edit',
  },
];
