export default [
  {
    key: 'name',
    type: 'text',
    label: 'Название',
  },
  {
    key: 'appGroups',
    type: 'text',
    label: 'Приложения',
  },
  {
    key: 'status',
    type: 'text',
    label: 'Активность',
    thStyle: {
      width: '160px',
    },
  },
];
