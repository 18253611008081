<template>
  <collection-provider
    collection="holidays"
    ref="collection"
  >
    <template #default="{ data, actions }">
      <div class="calendar p-3">
        <year
          :year="year"
          @select-year="year = $event"
        ></year>

        <calendar
          :year="year"
          :loading="data.loading"
        >
          <template #month="{ year, month }">
            <month
              :year="year"
              :month="month"
            >
              <template #day="{ year, month, date }">
                <day
                  :id="`day_${month}_${date}`"
                  :year="year"
                  :month="month"
                  :date="date"
                  :holiday="findHoliday(data.items, year, month, date)"
                  @click.native="togglePopover(month, date)"
                ></day>
              </template>
            </month>
          </template>
        </calendar>

        <b-popover
          placement="bottom"
          v-if="popover.show"
          :target="popover.target"
          :show.sync="popover.show"
        >
          <template #title>
            <div class="d-flex justify-content-between">
              <span>{{popover.title}}</span>
              <span class="cursor-pointer" @click="popover.show = false">
                <icon icon="fa-times"></icon>
              </span>
            </div>
          </template>
          <day-form
            :year="year"
            :month="popover.month"
            :date="popover.date"
            :holiday="findHoliday(data.items, year, popover.month, popover.date)"
            @remove="removeHoliday($event)"
            @saved="popover.show = false, $refs.collection.fetchItems()"
          ></day-form>
        </b-popover>
      </div>
    </template>
  </collection-provider>
</template>

<script>
import datetime from '../../utils/datetime';
import { confirmDialog } from '../../utils/dialogs';

import CollectionProvider from '../../providers/CollectionProvider';
import Calendar from './Calendar.vue';
import Month from './Month.vue';
import Day from './Day.vue';
import Year from './Year.vue';
import DayForm from './DayForm.vue';

export default {
  components: {
    CollectionProvider,
    Calendar,
    Year,
    Month,
    Day,
    DayForm,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      popover: {
        show: false,
        target: null,
        title: null,
        month: null,
        date: null,
      },
    };
  },
  methods: {
    togglePopover(month, date) {
      if (!this.$user.can('holidays.edit')) {
        return;
      }

      const id = `day_${month}_${date}`;

      if (this.popover.target !== id) {
        this.popover.show = false;

        this.$nextTick(() => {
          this.popover.target = id;
          this.popover.show = true;
        });
      } else {
        this.popover.show = !this.popover.show;
      }

      this.popover.title = this.getDateAsText(this.year, month, date);

      this.popover.month = month;
      this.popover.date = date;
    },

    getDateAsText(year, month, date) {
      const days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

      const d = new Date(year, month - 1, date);

      const day = d.toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      const dayName = days[d.getDay()];

      return `${dayName}, ${day}`;
    },

    findHoliday(holidays, year, month, date) {
      const d = new Date(year, month - 1, date);
      return holidays.find((h) => datetime.isSameDay(h.date, d));
    },

    async removeHoliday(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
        this.popover.show = false;
      }
    },
  },
};
</script>

<style scoped>
.calendar {
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
.busy {
  opacity: 0.7;
}
</style>
