<template>
  <div class="multiview d-flex flex-row">
    <div class="multiview__left">
      <scroll v-if="scrollSide">
        <slot name="side"></slot>
      </scroll>
      <slot v-if="!scrollSide" name="side"></slot>
    </div>
    <div class="multiview__right d-flex flex-column">
      <div
        v-if="$slots.panel"
        class="multiview__panel"
      >
        <slot name="panel"></slot>
      </div>
      <div
        v-if="$slots.subpanel"
        class="multiview__subpanel-wrapper"
      >
        <div class="multiview__subpanel">
          <slot name="subpanel"></slot>
        </div>
      </div>
      <scroll class="multiview__content-wrapper">
        <div
          ref="content"
          class="multiview__content"
        >
          <slot name="content"></slot>
        </div>
      </scroll>
      <div
        v-if="$slots.footer"
        class="multiview__footer-wrapper"
      >
        <div class="multiview__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>

    <portal
      v-if="$slots.portal"
      to="main"
    >
      <slot name="portal"></slot>
    </portal>
  </div>
</template>

<script>
export default {
  props: {
    scrollSide: {
      default: true,
    },
  },
};
</script>

<style scoped>
.multiview {
  padding: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.multiview__left {
  width: 280px;
  min-width: 280px;
  height: 100%;
  border-right: 1px solid #e5e5e5;
}
.multiview__right {
  width: calc(100% - 280px);
  height: 100%;
}
.multiview__panel {
  min-height: 43px;
  padding: 6px;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
}
.multiview__subpanel-wrapper {
  box-sizing: border-box;
}
.multiview__subpanel {
  padding: 6px;
}
.multiview__content-wrapper {
  overflow-y: auto;
  flex: 1 1 auto;
}
.multiview__content {
  padding: 6px;
}
</style>
