<template>
  <div class="p-2 border rounded">
    <div class="d-flex flex-column h-100">
      <div class="flex-grow-1">
        <chart-bars
          :periods="periods"
          :actual-data="actualData"
          :target-data="targetData"
          :tooltip-title="tooltipTitle"
          :backgroundColor="backgroundColor"
          :label-color="labelColor"
          :target-color="targetColor"
          :max-color="maxColor"
          :tooltip-target="tooltipTarget"
          :tooltip-actual="tooltipActual"
          :unit="unit"
        ></chart-bars>
      </div>
      <div class="mt-2 d-flex justify-content-between align-items-center">
        <div class="d-flex pl-3">
          <div class="d-flex align-items-center mr-3">
            <div class="mr-1 annotation" :style="{ 'border-top-color': targetColor }"></div>
            <div>План</div>
          </div>
          <div class="d-flex align-items-center">
            <div class="mr-1 annotation annotation-dashed" :style="{ 'border-top-color': maxColor }"></div>
            <div>Max</div>
          </div>
        </div>
        <div>
          <slot name="center"></slot>
        </div>
        <div>
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartBars from '../../../components/charts/ChartBars.vue';

export default {
  components: {
    ChartBars,
  },
  props: {
    actualData: {
      default: () => [],
    },
    targetData: {
      default: () => [],
    },
    periods: {
      default: () => [],
    },
    backgroundColor: {
      default: 'rgba(26, 141, 255, 0.2)',
    },
    labelColor: {
      default: '#1A8CFF',
    },
    targetColor: {
      default: '#1A8CFF',
    },
    minColor: {
      default: '#DC3545',
    },
    maxColor: {
      default: '#28A745',
    },
    tooltipTarget: {
      default: 'План',
    },
    tooltipActual: {
      default: 'Факт',
    },
    tooltipTitle: {
      default: '',
    },
    unit: {
      default: '',
    },
  },
};
</script>

<style scoped>
.annotation {
  width: 15px;
  border-top-width: 2px;
  border-top-style: solid;
}
.annotation-dashed {
  border-top-style: dashed;
}
</style>
