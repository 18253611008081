<template>
  <b-button
    :size="size"
    :variant="variant"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <icon v-if="icon" :icon="icon"></icon>
    <template v-if="icon && text">
      &nbsp;
    </template>
    {{text}}
  </b-button>
</template>

<script>

export default {
  props: {
    variant: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
