<template>
  <div class="h-100">
    <model-provider
      ref="movies"
      collection="movies2"
      :id="movieId"
      @loaded="setTitle($event)"
    >
      <template #default="{ data }">
        <div
          v-if="data.loading"
          class="movie__loader"
          :class="{ active: data.loading }"
        >
          <b-spinner variant="primary"></b-spinner>
        </div>
        <layout v-else-if="data.item && data.item.id">
          <template #panel>
            <buttons
              :item="data.item"
              @favorite-movie="openModalFavoriteMovie(data.item)"
              @edit-movie="openEditForm(data.item.id)"
            ></buttons>
          </template>

          <template #image>
            <div
              class="movie__poster"
            >
              <movie-image
                :image="data.item.posterUrl"
                :alt="data.item.name"
              ></movie-image>
              <div
                v-if="data.item.trailerUrl"
                class="movie__play"
                @click="openModalVideo(data.item.trailerUrl)"
              >
                <svg data-v-aa60413e="" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg"><path data-v-aa60413e="" fill-rule="evenodd" clip-rule="evenodd" d="M0.666664 2.30988C0.666664 0.731703 2.40897 -0.224724 3.74042 0.622563L12.6818 6.31255C13.9168 7.09846 13.9168 8.90129 12.6818 9.6872L3.74042 15.3772C2.40897 16.2245 0.666664 15.268 0.666664 13.6899V2.30988Z"></path></svg>
              </div>
            </div>
          </template>

          <template #header>
            <movie-header
              :name="data.item.name"
              :name-en="data.item.nameEn"
              :age-limit="data.item.ageLimit"
              :releases="data.item.releases"
            >
              <div class="mt-3">
                <div class="d-inline-block">
                  <ul class="custom-tabs">
                    <li
                      v-for="_tab in tabs"
                      :key="_tab.id"
                      :class="{'active': tab === _tab.id}"
                    >
                      <router-link
                        :to="{
                          name: 'movie2',
                          params: {
                            movieId,
                            tab: _tab.id,
                          },
                          query: $route.query,
                        }"
                      >
                        {{ _tab.name }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </movie-header>
          </template>

          <template #content>
            <info
              v-if="tab === 'info'"
              :movie="data.item"
            ></info>

            <settings
              v-if="tab === 'settings'"
              :movie="data.item"
            ></settings>

            <repertory
              v-if="tab === 'repertory'"
              :movie="data.item"
            ></repertory>

            <trailers
              v-if="tab === 'trailers'"
              :trailers="data.item.movieTrailers"
            ></trailers>

            <files-movie
              v-if="tab === 'files'"
              :files="data.item.movieFiles"
              :screenshots="data.item.movieScreenshots"
            ></files-movie>

            <screenshots
              v-if="tab === 'screenshots'"
              :screenshots="data.item.movieScreenshots"
            ></screenshots>

            <pushkins-card-events
              v-if="tab === 'pushkinsCardEvents'"
              :pushkins-card-events="data.item.pushkinsCardEvents"
              :cinemas="cinemas"
            ></pushkins-card-events>
          </template>
        </layout>
      </template>
    </model-provider>

    <movies-favorite-modal
      v-if="modals.favorite.show"
      :movie="modals.favorite.movie"
      @saved="$refs.movies.load()"
      @close="closeModalFavoriteMovie"
    />

    <modal-model-form
      v-if="modals.movie.show"
      create-title="Новый фильм"
      edit-title="Редактирование фильма"
      collection="movies2"
      :id="modals.movie.id"
      :init="{ useBonuses: true }"
      @close="modals.movie.show = false"
      @saved="$refs.movies.load()"
    >
      <template #default="{ data, actions }">
        <movie-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></movie-form>
      </template>
    </modal-model-form>

    <b-modal
      v-if="modals.video.show"
      centered
      size="xl"
      :visible="true"
      :hide-header="true"
      :hide-footer="true"
      @hidden="closeModalVideo()"
    >
      <div class="h-100 w-100 text-center" @click="closeModalVideo()">
        <video
          class="mw-100"
          :src="modals.video.trailerUrl"
          autoplay="true"
          loop="true"
          muted="true"
          controls="true"
        ></video>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ModelProvider from '../../providers/ModelProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';

import MoviesFavoriteModal from '../movies/MoviesFavoriteModal.vue';

import Layout from './Layout.vue';
import MovieImage from './Image.vue';
import Buttons from './Buttons.vue';
import MovieHeader from './Header.vue';
import Info from './Info.vue';
import Settings from './Settings.vue';
import Repertory from './Repertory.vue';
import Trailers from './Trailers.vue';
import FilesMovie from './Files.vue';
import Screenshots from './Screenshots.vue';
import MovieForm from './MovieForm.vue';
import PushkinsCardEvents from './PushkinsCardEvents.vue';

export default {
  components: {
    ModelProvider,
    Layout,
    MovieHeader,
    MovieImage,
    Buttons,
    Info,
    Trailers,
    Repertory,
    FilesMovie,
    MovieForm,
    ModalModelForm,
    MoviesFavoriteModal,
    Settings,
    Screenshots,
    PushkinsCardEvents,
  },
  data() {
    return {
      modals: {
        favorite: {
          show: false,
          movie: null,
        },
        movie: {
          show: false,
          id: '',
        },
        video: {
          show: false,
          trailerUrl: null,
        },
      },
      tabs: [
        {
          id: 'info',
          name: 'Информация',
        },
        {
          id: 'repertory',
          name: 'Роспись',
        },
        {
          id: 'trailers',
          name: 'Трейлеры DCP',
        },
        {
          id: 'files',
          name: 'Файлы',
        },
        {
          id: 'screenshots',
          name: 'Скриншоты',
        },
        {
          id: 'pushkinsCardEvents',
          name: 'События ПК',
        },
        {
          id: 'settings',
          name: 'Настройки',
        },
      ],
    };
  },
  computed: {
    ...mapState('dictionaries/cinemas', {
      cinemas: (state) => state.items,
    }),

    tab() {
      const tab = this.$route.params.tab || 'info';

      return this.tabs.find((_tab) => _tab.id === tab) ? tab : 'info';
    },

    movieId() {
      return +this.$route.params.movieId;
    },
  },
  async created() {
    await this.fetchCinemas();
  },

  methods: {
    ...mapActions('dictionaries/cinemas', {
      fetchCinemas: 'fetchItems',
    }),

    setTitle(item) {
      this.$store.commit('page/setTitle', `Просмотр фильма "${item.name}"`);
    },

    openEditForm(id) {
      this.modals.movie.id = id;
      this.modals.movie.show = true;
    },

    openModalFavoriteMovie(movie) {
      this.modals.favorite.movie = {
        id: movie.id,
        name: movie.name,
        ageLimit: movie.ageLimit,
        releases: movie.releases,
      };
      this.modals.favorite.show = true;
    },

    closeModalFavoriteMovie() {
      this.modals.favorite.movie = null;
      this.modals.favorite.show = false;
    },

    openModalVideo(trailerUrl) {
      this.modals.video.show = true;
      this.modals.video.trailerUrl = trailerUrl;
    },

    closeModalVideo() {
      this.modals.video.show = false;
      this.modals.video.trailerUrl = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.movie__loader {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

/deep/ .modal-content {
  background-color: inherit;
  border: 0;
}
.movie__poster {
  position: relative;
}
.movie__play {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .25s;
  background-color: hsla(0,0%,95.1%,.2);
  backdrop-filter: blur(12px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 100%;
  cursor: pointer;
  z-index: 1;
}
.movie__play:hover {
    background-color: #fff;
}
.movie__play svg {
  width: 12px;
  transition: fill .25s;
  fill: #fff;
  margin-left: 2px;
}
.movie__play:hover svg {
  fill: #000;
}
</style>
