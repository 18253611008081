var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('collection-provider',{ref:"collection",attrs:{"collection":"employees","filter":{ cinemaId: _vm.cinemaId },"disable-fetching":!_vm.cinemaId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('dictionaries-provider',{attrs:{"names":['employeeTypes']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var employeeTypes = ref.employeeTypes;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"item-actions":_vm.datatable.itemActions},on:{"item-action":function($event){return _vm.performItemAction($event)}},scopedSlots:_vm._u([{key:"filter(positionId)",fn:function(){return [_c('b-form-select',{attrs:{"size":"sm","value":data.filter.positionId,"options":_vm.$array.convertToOptions(employeeTypes),"disabled":data.loading},on:{"change":function($event){return actions.changeFilter('positionId', $event)}}})]},proxy:true},{key:"cell(positionId)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$array.getItemText(employeeTypes , data.item.positionId))+" ")]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}],null,true)})]}}])}),(_vm.modals.employee.show)?_c('modal-model-form',{attrs:{"create-title":"Создание сотрудника","edit-title":"Редактирование данных сотрудника","collection":"employees","id":_vm.modals.employee.id,"init":{ cinemaId: _vm.cinemaId }},on:{"close":function($event){_vm.modals.employee.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('employee-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,false,2923413375)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }