/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import humps from 'humps';

export default {
  props: {
    names: {
      required: true,
    },
  },
  render() {
    return this.$scopedSlots.default(this.items);
  },
  computed: {
    items() {
      const items = {};

      for (const name of this.names) {
        items[name] = this.$store.state.dictionaries[name].items;
      }

      return items;
    },
  },
  created() {
    this.fetchDictionaries();
  },
  methods: {
    async fetchDictionaries() {
      const promises = [];

      for (const name of this.names) {
        promises.push(this.fetchDictionary(name));
      }

      await Promise.all(promises);

      this.$emit('loaded', this.items);
    },

    async fetchDictionary(name) {
      return this.$store.dispatch(`dictionaries/${name}/fetchItems`).then(() => {
        this.$emit(`${this.decamelize(name)}-loaded`, this.items[name]);
      });
    },

    decamelize(name) {
      return humps.decamelize(name, { separator: '-' });
    },
  },
};
