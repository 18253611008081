<template>
  <div class="table-container promotions">
    <collection-provider
      collection="promotions"
      ref="collection"
      sort-by="updatedAt"
      :filter="{ mode: 'all' }"
      :sort-desc="true"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(settings)>
            <dictionary-provider name="cinemas">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="data.filter.cinemaId"
                  :options="convertCinemas(items)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('cinemaId', $event)"
                >
                  <template #first>
                    <b-form-select-option :value="null"></b-form-select-option>
                  </template>
                </b-form-select>
              </template>
            </dictionary-provider>
          </template>
          <template #filter(enabled)>
            <b-form-select
              size="sm"
              :value="data.filter.mode"
              :options="modes"
              :disabled="data.loading"
              @change="actions.changeFilter('mode', $event)"
            ></b-form-select>
          </template>
          <template #cell(name)="data">
            <div>{{data.item.name}}</div>
            <div
              v-if="data.item.description"
              class="text-muted mt-2 description"
              :title="data.item.description"
            >{{data.item.description}}</div>
            <promotion-formula
              class="mt-2"
              :item="data.item"
            ></promotion-formula>
          </template>
          <template #cell(date)="data">
            <promotion-period :item="data.item"></promotion-period>
          </template>
          <template #cell(createdAt)="data">
            {{formatDateTime(data.item.createdAt)}}
          </template>
          <template #cell(updatedAt)="data">
            {{formatDateTime(data.item.updatedAt)}}
          </template>
          <template #cell(enabled)="data">
            {{getPromotionStatusName(data.item.isEnabled)}}
          </template>
          <template #cell(settings)="data">
            <promotion-settings :item="data.item"></promotion-settings>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.promotion.show"
      create-title="Создание акции"
      edit-title="Редактирование акции"
      collection="promotions"
      :id="modals.promotion.id"
      :copy="modals.promotion.copy"
      @close="modals.promotion.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <promotion-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></promotion-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import DictionaryProvider from '../../providers/DictionaryProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import PromotionForm from './PromotionForm.vue';
import PromotionSettings from './PromotionSettings.vue';
import PromotionFormula from './PromotionFormula.vue';
import PromotionPeriod from './PromotionPeriod.vue';

import datetime from '../../utils/datetime';

export default {
  components: {
    CollectionProvider,
    DictionaryProvider,
    ModalModelForm,
    Datatable,
    PromotionForm,
    PromotionSettings,
    PromotionFormula,
    PromotionPeriod,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        promotion: {
          show: false,
          copy: false,
          id: 0,
        },
      },
      promotionStatuses: [
        {
          value: false,
          text: 'Остановлена',
        },
        {
          value: true,
          text: 'Активна',
        },
      ],
      modes: [
        {
          value: 'all',
          text: 'Все',
        },
        {
          value: 'enabled',
          text: 'Активные',
        },
        {
          value: 'disabled',
          text: 'Остановленные',
        },
        {
          value: 'past',
          text: 'Прошедшие',
        },
      ],
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    getPromotionStatusName(value) {
      return this.promotionStatuses.find((status) => status.value === value)?.text;
    },

    openCreatePromotionForm() {
      this.modals.promotion.id = '';
      this.modals.promotion.show = true;
      this.modals.promotion.copy = false;
    },

    copyPromotion(id) {
      this.modals.promotion.id = id;
      this.modals.promotion.show = true;
      this.modals.promotion.copy = true;
    },

    openEditPromotionForm(id) {
      this.modals.promotion.id = id;
      this.modals.promotion.show = true;
      this.modals.promotion.copy = false;
    },

    async removeCheckedPromotions() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    pauseCheckedPromotions() {
      this.$refs.collection.changeItems(this.datatable.checked, { isEnabled: 0 });
      this.datatable.checked = [];
    },

    resumeCheckedPromotions() {
      this.$refs.collection.changeItems(this.datatable.checked, { isEnabled: 1 });
      this.datatable.checked = [];
    },

    async removePromotion(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    pausePromotion(id) {
      this.$refs.collection.changeItem(id, { isEnabled: 0 });
    },

    resumePromotion(id) {
      this.$refs.collection.changeItem(id, { isEnabled: 1 });
    },

    formatDateTime(date) {
      return datetime.formatDateTime(date);
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        value: cinema.id,
        text: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },
  },
};
</script>

<style scoped>
.description {
  max-width: 20vw;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
