<template>
  <div class="d-flex w-100 p-2 align-items-center mediastream__wrap">
    <div
      v-if="drop"
      class="d-flex align-items-center cursor-pointer p-2 mr-1"
    >
      <icon icon="mdi-menu"></icon>
    </div>
    <div class="d-flex align-items-center justify-content-center mediastream__img-wrap mr-2">
      <img class="rounded" :src="item.mediaInfo.thumb" :alt="item.name">
    </div>
    <div class="mediastream__name">
      <div class="text-truncate text-nowrap">{{ item.name }}</div>
    </div>
    <div class="flex-grow-1 px-3 mediastream__type">
      {{ helperFile.getTitleType(item.mediaInfo.mimeType) }}
    </div>
    <div class="px-2 text-center mediastream__duration">
      <div v-if="!showInputDuration" @click="showInputDuration = true">
        {{ convertDuration(item.duration) }}
      </div>
      <div v-else>
        <input
          class="form-control form-control-sm"
          type="time"
          step='1'
          min="00:00:01" max="23:59:59"
          v-click-outside="() => showInputDuration = !showInputDuration"
          :value="convertDuration(item.duration)"
          @change="(event) => change(event.target.value)"
        />
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center text-danger mediastream__action">
      <a
        title="Скачать"
        class="mx-2 px-2 py-1 cursor-pointer border rounded bg-white"
        :href="downloadLink()"
        :download="item.name"
      >
        <icon
          icon="mdi-download"
        ></icon>
      </a>
      <div
        title="Убрать"
        class="mx-2 px-2 py-1 cursor-pointer border border-danger rounded bg-white"
        @click="$emit('delete')"
      >
        <icon
          icon="mdi-delete"
        ></icon>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import { getFileUrl } from '../../api/mediaPanels';
import {
  getTitleType,
} from '../media-library/_index.fields';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    drop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showInputDuration: false,
    helperFile: {
      getTitleType,
    },
  }),
  methods: {
    convertDuration(second) {
      return moment.utc(moment.duration(second, 'seconds').asMilliseconds()).format('HH:mm:ss');
    },

    change(value) {
      this.$emit('change-duration', moment.duration(value, 'seconds').asSeconds());
    },

    downloadLink() {
      return `${getFileUrl(this.item.libraryFileId, '1080p')}&download=1`;
    },
  },
};
</script>
