export default () => [
  {
    type: 'button',
    text: 'Добавить почтовый аккаунт',
    variant: 'info',
    icon: 'fa-plus',
    class: 'mr-1',
    action: 'openCreateMailAccountForm',
    permission: 'mail_accounts.edit',
  },
];
