import FileSaver from 'file-saver';

export function saveFile({ blob, type, name }) {
  FileSaver.saveAs(new Blob([blob], { type }), name);
}

export function convertFileSize(num) {
  if (typeof num !== 'number' || isNaN(num)) {
    return;
  }

  let exponent;
  let unit;
  const neg = num < 0;
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return `${(neg ? '-' : '') + num} B`;
  }

  exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1,
  );
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return `${(neg ? '-' : '') + num} ${unit}`;
}

export function convertBlobToString(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener('loadend', (e) => {
      resolve(e.srcElement.result);
    });

    reader.readAsText(blob);
  });
}
