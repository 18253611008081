export default () => [
  {
    type: 'button',
    text: 'Добавить мерчант аккаунт',
    variant: 'info',
    icon: 'fa-plus',
    class: 'mr-1',
    action: 'openCreateMerchantAccountForm',
    permission: 'merchant_accounts.edit',
  },
];
