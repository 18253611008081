<template>
  <div class="h-100 d-flex justify-content-center welcome-wrapper">
    <div class="align-items-center d-flex flex-column justify-content-center welcome-wrapper">
      <div class="title">Добро пожаловать в экосистему</div>
      <logo class="logo"></logo>
    </div>
  </div>
</template>

<script>
import Logo from '../components/Logo.vue';

export default {
  components: {
    Logo,
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
}
.logo {
  margin-top: 10px;
  width: 198px;
}
</style>
