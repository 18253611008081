export default [
  {
    key: 'id',
    label: '',
    thStyle: {
      width: '1px',
    },
  },
  {
    key: 'name',
    label: 'Название',
    thStyle: {
      width: '200px',
    },
  },
  {
    key: 'releases',
    label: 'Формат - хроно',
    thStyle: {
      width: '200px',
    },
  },
  {
    key: 'distributor',
    label: 'Прокат',
    thStyle: {
      width: '300px',
    },
  },
  // {
  //   key: 'laboratories',
  //   label: 'Доставка',
  //   thStyle: {
  //     width: '300px',
  //   },
  // },
  {
    key: 'ageLimit',
    label: 'Возр.',
    thStyle: {
      width: '60px',
    },
  },
  {
    key: 'rentalLicenseNumber',
    label: 'ПУ',
    thStyle: {
      width: '160px',
    },
  },
  {
    key: 'memorandumPeriodEnd',
    label: 'Меморандум',
    thStyle: {
      width: '160px',
    },
  },
  {
    key: 'datePreview',
    label: 'Предпоказ',
    thStyle: {
      width: '160px',
    },
  },
  {
    key: 'dateStart',
    label: 'Даты проката в России',
    thStyle: {
      width: '220px',
    },
  },
];
