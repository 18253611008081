<template>
  <div class="rounded mx-2 p-2 d-flex flex-column group">
    <div class="group__top">
      <div class="d-flex justify-content-center w-100">
        <div class="flex-grow-1 d-flex flex-column group__title">
          <div class="h6 mb-0">{{ title }}</div>
          <div class="group__count">
            {{ countTitle }}
          </div>
        </div>
        <div v-if="showDropDownMenu" class="group__menu">
          <slot name="group-action"></slot>
        </div>
      </div>
    </div>
    <div class="flex-fill d-flex flex-column group__screens">
      <slot name="screens"></slot>
    </div>
  </div>
</template>

<script>
import declOfNum from '../../utils/declOfNum';

export default {
  props: {
    group: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    countTitle() {
      return this.group.countScreens > 0
        ? `${this.group.countScreens} ${this.getDeclOfNum(this.group.countScreens, ['экран', 'экрана', 'экранов'])}`
        : 'Нет экранов';
    },

    title() {
      return this.group.name;
    },

    showDropDownMenu() {
      return this.group.id !== null;
    },
  },
  methods: {
    getDeclOfNum(n, titles) {
      return declOfNum(n, titles);
    },
  },
};
</script>

<style lang="scss">
.group {
  flex: 0 0 400px;
  background: #f4f5f6;

  &__menu-icon {
    color: var(--gray-dark);
    font-size: 25px;

    &:hover {
      background: #747f8d14;
    }
  }

  &__count {
    color: var(--gray-dark);
  }
}
</style>
