<template>
  <cinema-page
    route-name="products"
    route-param-name="cinemaId"
  >
    <template #scroll="{ cinemaId }">
      <div>
        <collection-provider
          collection="products"
          ref="collection"
          :filter="{ cinemaId }"
          :disable-fetching="!cinemaId"
        >
          <template #default="{ data, actions }">
            <dictionaries-provider :names="['paymentSubjects', 'paymentMethods', 'legalEntities']">
              <template #default="{ paymentSubjects, paymentMethods, legalEntities }">
                <datatable
                  v-bind="{ ...data, ...actions }"
                  :fields="datatable.fields"
                  :permissions="$user.permissions"
                  :item-actions="datatable.itemActions"
                  :panel-actions="datatable.panelActions"
                  @item-action="performItemAction($event)"
                  @panel-action="performPanelAction($event)"
                >
                  <template #filter(legalEntityId)>
                    <b-form-select
                      size="sm"
                      :value="data.filter.legalEntityId"
                      :options="$array.convertToOptions(legalEntities)"
                      :disabled="data.loading"
                      @change="actions.changeFilter('legalEntityId', $event)"
                    ></b-form-select>
                  </template>
                  <template #filter(productGroupId)>
                    <b-form-select
                      size="sm"
                      :value="data.filter.productGroupId"
                      :options="$array.convertToOptions(productGroups)"
                      :disabled="data.loading"
                      @change="actions.changeFilter('productGroupId', $event)"
                    ></b-form-select>
                  </template>
                  <template #filter(productReportGroupId)>
                    <b-form-select
                      size="sm"
                      :value="data.filter.productReportGroupId"
                      :options="$array.convertToOptions(productReportGroups)"
                      :disabled="data.loading"
                      @change="actions.changeFilter('productReportGroupId', $event)"
                    ></b-form-select>
                  </template>
                  <template #filter(isEnabled)>
                    <b-form-select
                      size="sm"
                      :value="data.filter.isEnabled"
                      :options="productStatuses"
                      :disabled="data.loading"
                      @change="actions.changeFilter('isEnabled', $event)"
                    ></b-form-select>
                  </template>
                  <template #cell(legalEntityId)="data">
                    {{$array.getItemText(legalEntities, data.item.legalEntityId)}}
                  </template>
                  <template #cell(productGroupId)="data">
                    {{$array.getItemText(productGroups, data.item.productGroupId)}}
                  </template>
                  <template #cell(productReportGroupId)="data">
                    {{$array.getItemText(productReportGroups, data.item.productReportGroupId)}}
                  </template>
                  <template #cell(isEnabled)="data">
                    {{data.item.isEnabled ? 'Активен' : 'Не активен'}}
                  </template>
                </datatable>
              </template>
            </dictionaries-provider>
          </template>
        </collection-provider>

        <modal-model-form
          v-if="modals.product.show"
          create-title="Создание товара"
          edit-title="Редактирование товара"
          collection="products"
          :id="modals.product.id"
          :init="{ cinemaId }"
          @close="modals.product.show = false"
          @saved="$refs.collection.fetchItems()"
        >
          <template #default="{ data, actions }">
            <product-form
              :cinema-id="cinemaId"
              :loading="data.loading"
              :errors="data.errors"
              :states="data.states"
              :item="data.item"
              @change="actions.setValue($event.field, $event.value)"
            ></product-form>
          </template>
        </modal-model-form>
      </div>
    </template>
  </cinema-page>
</template>

<script>

import { mapState } from 'vuex';
import CinemaPage from '../../layout/CinemaPage.vue';

import { confirmDialog } from '../../utils/dialogs';
import CollectionProvider from '../../providers/CollectionProvider';
import DictionariesProvider from '../../providers/DictionariesProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import ProductForm from './ProductForm.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    Datatable,
    ProductForm,
    ModalModelForm,
    DictionariesProvider,
  },
  props: {
    cinemaId: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
        panelActions,
      },
      modals: {
        product: {
          show: false,
          id: '',
        },
      },
      productStatuses: [
        {
          value: null,
          text: '',
        },
        {
          value: false,
          text: 'Не активен',
        },
        {
          value: true,
          text: 'Активен',
        },
      ],
    };
  },
  computed: {
    ...mapState('dictionaries/productGroups', {
      productGroups: (state) => state.items,
    }),

    ...mapState('dictionaries/productReportGroups', {
      productReportGroups: (state) => state.items,
    }),
  },
  watch: {
    cinemaId: {
      handler() {
        this.fetchProductGroups();
        this.fetchProductReportGroups();
      },
      immediate: true,
    },
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    openCreateProductForm() {
      this.modals.product.id = null;
      this.modals.product.show = true;
    },

    openEditProductForm(id) {
      this.modals.product.id = id;
      this.modals.product.show = true;
    },

    async removeProduct(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    fetchProductGroups() {
      this.$store.dispatch('dictionaries/productGroups/fetchItems', { filter: { cinemaId: this.cinemaId } });
    },

    fetchProductReportGroups() {
      this.$store.dispatch('dictionaries/productReportGroups/fetchItems', { filter: { cinemaId: this.cinemaId } });
    },

    enableProduct(id) {
      this.$refs.collection.changeItem(id, { isEnabled: 1 });
    },

    disableProduct(id) {
      this.$refs.collection.changeItem(id, { isEnabled: 0 });
    },
  },
};
</script>
