<template>
  <div>
    <div class="d-flex justify-content-center mb-3">
      <calendar-picker
        calendar-type="week"
        :date="dateStart"
        @select-date="$emit('select-date', $event)"
      ></calendar-picker>
    </div>
    <div>
      <datatable-drag
        :items="advertisements"
        :fields="fields"
        :permissions="$user.permissions"
        :isItemActions="false"
        :isPanelActions="false"
        @change-positions="changePositions($event)"
      >
        <template #cell-name="data">
          <div class="d-flex align-items-center">
            <span
              class="mark mr-2"
              :style="{ backgroundColor: getAdvertisementTypeColor(data.item.type.id) }"
            ></span>
            <span>{{ data.item.name }}</span>
          </div>
        </template>

        <template #cell-duration="data">
          {{ formatTime(data.item.duration) }}
        </template>

        <template #cell-positionType="data">
          <b-badge v-if="data.item.positionType === 2" variant="danger">Последний</b-badge>
          <b-badge v-if="data.item.positionType === 1" variant="warning">Предпоследний</b-badge>
          <b-badge v-if="data.item.positionType === 0" variant="Secondary">Не важно</b-badge>
        </template>

        <template #cell-period="data">
          <template v-if="data.item.dateStart">
            c {{ $datetime.formatDate(data.item.dateStart) }}
          </template>
          <template v-if="data.item.dateEnd">
            по {{ $datetime.formatDate(data.item.dateEnd) }}
          </template>
        </template>

        <template #cell-type="data">
          {{ data.item.type.name }}
        </template>

        <template #cell-halls="data">
          {{ $array.convertToText(data.item.halls, 'Все залы') }}
        </template>

        <template #cell-targeting="data">
          <advertisement-info :advertisement="data"></advertisement-info>
        </template>
      </datatable-drag>
    </div>
  </div>
</template>

<script>
import fields from './_index.fieldsPositions';
import DatatableDrag from '../../components/datatable/DatatableDrag.vue';
import CalendarPicker from '../../components/CalendarPicker.vue';
import AdvertisementInfo from './AdvertisementInfo.vue';

export default {
  components: {
    CalendarPicker,
    AdvertisementInfo,
    DatatableDrag,
  },
  props: {
    advertisements: {
      default: () => [],
    },
    advertisementTypes: {
      default: () => [],
    },
    dateStart: {
      default: null,
    },
    dateEnd: {
      default: null,
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      fields,
    };
  },
  methods: {
    formatTime(seconds) {
      return seconds ? new Date(1000 * seconds).toISOString().substr(14, 5) : '00:00';
    },

    getAdvertisementTypeColor(typeId) {
      return this.advertisementTypes.find((type) => type.id === typeId)?.color;
    },

    async changePositions(values) {
      const result = {
        dateStart: this.$datetime.convertDateToDbFormat(this.dateStart),
        dateEnd: this.$datetime.convertDateToDbFormat(this.dateEnd),
        positions: values.positions.map((item, index) => ({ id: item.id, position: index })),
      };

      this.$emit('change-positions', result);
    },
  },
};
</script>

<style scoped>
.mark {
  width: 20px;
  height: 20px;
  border: 1px solid #eee;
  display: inline-block;
  border-radius: 50%;
}
</style>
