var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cinema-page',{attrs:{"route-name":"sales","route-param-name":"cinemaId","display-loading-stub":true},on:{"loaded":function($event){return _vm.onCinemaLoaded($event)}},scopedSlots:_vm._u([{key:"buttons",fn:function(ref){
var halls = ref.halls;
var loading = ref.loading;
return [_c('buttons',{attrs:{"date":_vm.date,"disabled":loading,"intersections":_vm.intersections,"halls":halls},on:{"change-cinema":function($event){return _vm.openCinema($event)},"open-sales":function($event){return _vm.openSales()},"close-sales":function($event){return _vm.closeSales()},"open-sales-period-form":function($event){return _vm.openSalesPeriodForm()},"select-date":function($event){return _vm.openDate($event)},"set-min-price":function($event){_vm.modals.setMinPrice.show = true},"decrease-width":function($event){return _vm.decreaseMinuteWidth()},"increase-width":function($event){return _vm.increaseMinuteWidth()},"decrease-height":function($event){return _vm.decreaseHallHeight()},"increase-height":function($event){return _vm.increaseHallHeight()},"prev-day":function($event){return _vm.prevDay()},"next-day":function($event){return _vm.nextDay()},"open-schedule":function($event){return _vm.openSchedule()}}})]}},{key:"scroll",fn:function(ref){
var halls = ref.halls;
var loading = ref.loading;
return [_c('timeline',{ref:"timeline",attrs:{"halls":halls,"shows":_vm.shows,"gaps":_vm.gaps,"disabled":loading,"minute-width":_vm.minuteWidth,"hall-height":_vm.hallHeight},scopedSlots:_vm._u([{key:"hall-buttons",fn:function(ref){
var hall = ref.hall;
return [_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.$user.can('schedule.edit'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Открыть продажи в зале'),expression:"'Открыть продажи в зале'"}],staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-info","disabled":loading},on:{"click":function($event){return _vm.openSalesInHall(hall.id)}}},[_c('icon',{attrs:{"icon":"mdi-cart"}})],1):_vm._e(),(_vm.$user.can('schedule.edit'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Закрыть продажи в зале'),expression:"'Закрыть продажи в зале'"}],staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-info","disabled":loading},on:{"click":function($event){return _vm.closeSalesInHall(hall.id)}}},[_c('icon',{attrs:{"icon":"mdi-cart-off"}})],1):_vm._e()],1)]}},{key:"advertisement",fn:function(){return [_c('timeline-advertisement')]},proxy:true},{key:"show",fn:function(ref){
var show = ref.show;
var hall = ref.hall;
return [_c('timeline-show',{attrs:{"show":show,"hall":hall,"selected":_vm.isShowSelected(show),"expired":_vm.isExpired(show)},nativeOn:{"click":function($event){return _vm.toggleSelectedShow($event, show)},"contextmenu":function($event){$event.preventDefault();return _vm.openContextMenu($event, show)},"dblclick":function($event){return _vm.openShowSettingsForm(show.id)}}})]}},{key:"gap",fn:function(ref){
var interval = ref.interval;
return [_c('timeline-gap',{attrs:{"interval":interval}})]}}],null,true)}),_c('vue-context',{ref:"menu"},[_c('li',[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.openSalesForSelectedShows()}}},[_vm._v("Открыть продажи")])]),_c('li',[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.closeSalesForSelectedShows()}}},[_vm._v("Закрыть продажи")])]),(_vm.selectedShowIds.length === 1)?_c('li',[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.openShowSettingsForm()}}},[_vm._v("Настройки")])]):_vm._e()]),(_vm.modals.setMinPrice.show)?_c('modal-set-min-price',{on:{"hide":function($event){_vm.modals.setMinPrice.show = false}}}):_vm._e(),(_vm.modals.salesStatus.show)?_c('modal-sales-status',{attrs:{"show-ids":_vm.selectedShowIds,"type":_vm.modals.salesStatus.type,"hall-id":_vm.modals.salesStatus.hallId,"status":_vm.modals.salesStatus.status},on:{"hide":function($event){return _vm.closeSalesStatusForm()}}}):_vm._e(),(_vm.modals.salesPeriod.show)?_c('modal-sales-period',{on:{"hide":function($event){return _vm.closeSalesPeriodForm()}}}):_vm._e(),(_vm.modals.showSettings.show)?_c('modal-model-form',{attrs:{"size":"xl","collection":"sales","id":_vm.modals.showSettings.id,"create-title":_vm.modals.showSettings.title,"edit-title":_vm.modals.showSettings.title},on:{"close":function($event){return _vm.closeShowSettingsForm()},"saved":function($event){return _vm.fetchShows()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('show-settings-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,true)}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }