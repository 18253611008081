import _factory from './_factory';
import datetime from '../../utils/datetime';

export default {
  ..._factory('holidays', {
    getters: {
      getHolidays: (state) => (regionId) => state.items.filter((h) => {
        if (!h.regions || !h.regions.length) {
          return true;
        }

        if (h.regions.find((region) => region.id === regionId)) {
          return true;
        }

        return false;
      }),

      getDay: (state, getters) => (regionId, date) => {
        const holiday = getters.getHolidays(regionId).find((h) => datetime.isSameDay(h.date, date));

        if (holiday) {
          return holiday.asDay;
        }

        const day = date.getDay();

        return day === 0 ? 7 : day;
      },

      isHoliday: (state, getters) => (regionId, date) => {
        const day = getters.getDay(regionId, date);

        if (day === 6 || day === 7) {
          return true;
        }

        return false;
      },
    },
  }),
};
