<template>
  <div>
    <div class="text-uppercase border-bottom d-flex justify-content-between pb-2 mb-3">
      <div class="align-self-end"></div>
      <b-button
        size="sm"
        variant="outline-info"
        :disabled="disabled || !availableKeys.length"
        @click="addItem()"
      >
        <icon icon="fa-plus"></icon> {{ texts.add }}
      </b-button>
    </div>
    <div>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="border-bottom item-container mb-2"
      >
        <b-row>
          <b-col>
            <b-form-group :label="`${texts.key}:`">
              <b-form-select
                size="sm"
                :value="item.keyId"
                :options="getAvailableKeys(item.keyId)"
                :disabled="disabled"
                @change="setKeyId(index, $event)"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="`${texts.value}:`">
              <slot
                name="value"
                :disabled="disabled"
                :item="item"
                :setValue="(value) => setValue(index, value)"
              ></slot>
            </b-form-group>
          </b-col>
        </b-row>
        <span
          class="item-container__trash-button"
          :disabled="disabled"
          @click="removePair(index)"
        >
          <icon icon="fa-times"></icon>
        </span>
      </div>
    </div>
    <div
      v-if="!items || !items.length"
      class="text-center"
    >{{ texts.empty }}</div>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      add: {
        type: String,
        default: 'Добавить',
      },
      empty: {
        type: String,
        default: 'Нет данных',
      },
      key: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },
    },
    disabled: {
      default: false,
    },
    keys: {
      type: Array,
      default: () => [],
      validator: (value) => value.every((item) => item.id && item.name),
    },
    items: {
      default: () => [],
      validator: (value) => value.every((item) => item.keyId && typeof item.value === 'string'),
    },
  },
  computed: {
    availableKeys() {
      return this.keys.filter((key) => !this.items.find((item) => item.keyId === key.id));
    },
  },
  methods: {
    getAvailableKeys(keyId) {
      const availableKeys = [...this.availableKeys];
      const foundKey = this.keys.find((key) => key.id === keyId);

      if (foundKey) {
        availableKeys.push(foundKey);
      }

      return availableKeys.map((key) => ({ value: key.id, text: key.name }));
    },

    setKeyId(index, value) {
      const itemsCopy = [...this.items];

      itemsCopy.splice(index, 1, {
        keyId: value,
        value: itemsCopy[index].value,
      });

      this.emitChange(itemsCopy);
    },

    setValue(index, value) {
      const itemsCopy = [...this.items];

      itemsCopy.splice(index, 1, {
        value,
        keyId: itemsCopy[index].keyId,
      });

      this.emitChange(itemsCopy);
    },

    removePair(index) {
      const copy = [...this.items];

      copy.splice(index, 1);

      this.emitChange(copy);
    },

    addItem() {
      if (!this.availableKeys.length) {
        return;
      }

      this.emitChange([
        ...this.items,
        {
          keyId: this.availableKeys[0].id,
          value: '',
        },
      ]);
    },

    emitChange(items) {
      this.$emit('change', items);
    },
  },
};
</script>

<style scoped>
.item-container {
  position: relative;
}
.item-container__trash-button {
  position: absolute;
  right: 0;
  top: -4px;
  color: #AA0000;
  cursor: pointer;
}
</style>
