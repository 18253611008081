<template>
  <div>
    <div class="font-weight-bold text-center p-1">
      {{name}}
    </div>
    <div class="d-flex justify-content-between">
      <div class="day text-center p-1">ПН</div>
      <div class="day text-center p-1">ВТ</div>
      <div class="day text-center p-1">СР</div>
      <div class="day text-center p-1">ЧТ</div>
      <div class="day text-center p-1">ПТ</div>
      <div class="day text-center p-1">СБ</div>
      <div class="day text-center p-1">ВС</div>
    </div>
    <div
      v-for="(week, weekIndex) in weeks"
      class="d-flex flex-row"
      :key="weekIndex"
    >
      <div
        v-for="(day, dayIndex) in week"
        class="day text-center p-1"
        :class="{'ml-auto': (weekIndex === 0 && dayIndex === 0)}"
        :key="dayIndex"
      >
        <slot
          name="day"
          :year="year"
          :month="month"
          :date="day.getDate()"
        ></slot>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import datetime from '../../utils/datetime';

export default {
  props: {
    year: {},
    month: {},
  },
  computed: {
    firstDate() {
      return new Date(this.year, this.month - 1);
    },

    lastDate() {
      if (this.month === 12) {
        return new Date(this.year + 1, 0, 0);
      }
      return new Date(this.year, this.month, 0);
    },

    name() {
      const name = this.firstDate.toLocaleString('ru', { month: 'long' });

      return name[0].toUpperCase() + name.slice(1);
    },

    dates() {
      return datetime.getDates(this.firstDate, this.lastDate);
    },

    weeks() {
      const weeks = [];
      let week = [];

      for (const date of this.dates) {
        week.push(date);

        if (date.getDay() === 0) {
          weeks.push(week);
          week = [];
        }
      }

      if (week.length) {
        weeks.push(week);
      }

      return weeks;
    },
  },
};
</script>
