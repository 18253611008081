import ScreenService from '../../services/Screens';

import { MEDIA_PANEL_STATUS_REGISTER } from '../../constants';

export default {
  namespaced: true,

  state: {
    loading: false,
    items: [],
    filter: {},
  },

  actions: {
    async changeFilter({ dispatch, commit }, { field, value }) {
      commit('setFilterValue', { field, value });
      await dispatch('fetchItems');
    },

    async resetFilter({ commit }) {
      commit('setFilter', {});
    },

    async fetchItems({ commit, state: { filter } }) {
      commit('setLoading', true);

      const [, data] = await ScreenService.fetchItems({ filter });

      if (data) {
        const { items } = data;

        commit('setItems', { items });
      }

      commit('setLoading', false);
    },

    async resetItems({ commit }) {
      commit('setItems', { items: [] });
    },

    async rebootItem({ commit }, { id }) {
      commit('setLoading', true);

      await ScreenService.rebootItemById(id);

      commit('setLoading', false);
    },

    async updateGroupAndPosition(
      { commit, dispatch },
      values,
    ) {
      commit('setLoading', true);

      const [err, result] = await ScreenService.updateGroupAndPosition(
        values,
      );

      if (result) {
        await dispatch('fetchItems');
      }

      commit('setLoading', false);

      return [err, result];
    },

    async updateItem({ commit, dispatch }, { id, values }) {
      commit('setLoading', true);

      const [err, result] = await ScreenService.updateItem(id, values);

      await dispatch('fetchItems');

      commit('setLoading', false);

      return [err, result];
    },

    async updateItems({ commit, dispatch }, { ids, values }) {
      commit('setLoading', true);

      await ScreenService.updateItems(ids, values);

      await dispatch('fetchItems');

      commit('setLoading', false);
    },

    async createItem({ commit, dispatch }, value) {
      commit('setLoading', true);

      const [err, result] = await ScreenService.createItem(value);

      dispatch('fetchItems');

      commit('setLoading', false);

      return [err, result];
    },

    async removeItem({ commit, dispatch }, { id }) {
      commit('setLoading', true);

      const [err, result] = await ScreenService.updateItem(id, {
        status: MEDIA_PANEL_STATUS_REGISTER,
        mediablockSettingsId: null,
        groupId: null,
      });

      dispatch('fetchItems');

      commit('setLoading', false);

      return [err, result];
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setItems(state, { items }) {
      state.items = items;
    },

    setFilter(state, filter) {
      state.filter = filter;
    },

    setFilterValue(state, { field, value }) {
      state.filter = {
        ...state.filter,
        [field]: value,
      };
    },
  },
};
