<template>
  <multi-view class="report report-proceeds-by-cinemas">
    <template #side>
      <params></params>
    </template>

    <template #panel>
      <b-button
        size="sm"
        variant="outline-info"
        :to="{ name: 'reportIndex' }"
      >
        <span slot="text">
          <icon icon="fa-chevron-left"></icon> Назад
        </span>
      </b-button>
    </template>

    <template #content>
      <div
        v-if="report"
        class="p-3 w-100"
        v-html="report"
        id="htmlReport"
        @click="handleClick"
      ></div>
    </template>
  </multi-view>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MultiView from '../../../layout/MultiView.vue';
import Params from './Params.vue';

export default {
  components: {
    MultiView,
    Params,
  },
  computed: {
    ...mapState('reports/proceedsByCinemasForDistribution', {
      report: (state) => state.report,
    }),
  },
  methods: {
    ...mapActions({
      setSort: 'reports/proceedsByCinemasForDistribution/setSort',
      fetchReport: 'reports/proceedsByCinemasForDistribution/fetchReport',
    }),

    handleClick(e) {
      const isSortable = e?.target?.dataset?.sortable;
      const sortingName = e?.target?.dataset?.sortingName;
      const sortingOrder = e?.target?.dataset?.sortingOrder;

      if (!isSortable || !sortingName) {
        return;
      }

      this.setSort({
        by: sortingName,
        desc: !sortingOrder || sortingOrder === 'asc',
      });

      this.fetchReport({ format: 'html' });
    },
  },
};
</script>

<style scoped>
  @import '../../../assets/reports/proceeds_by_cinemas.css';
</style>
