<template>
  <b-row align-v="center" class="mb-2">
    <b-col style="width: 220px; flex: none;">
      <div class="selection-form__title">{{ name }}</div>
    </b-col>
    <b-col>
      <div class="d-flex align-items-center">
        <div
          class="w-50 mr-1"
        >{{ labelInclude }}</div>
        <b-input
          class="w-50"
          size="sm"
          type="number"
          :value="include"
          :disabled="disabled"
          @change="$emit('include', $event)"
        ></b-input>
      </div>
    </b-col>
    <b-col>
      <div class="d-flex align-items-center">
        <div
          class="w-50 mr-1"
        >{{ labelInclude }}</div>
        <b-input
          class="w-50"
          size="sm"
          type="number"
          :value="exclude"
          :disabled="disabled"
          @change="$emit('exclude', $event)"
        ></b-input>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    name: {
      default: '',
    },
    labelInclude: {
      default: 'Не позднее (дней)',
    },
    labelExclude: {
      default: 'Не ранее (дней)',
    },
    disabled: {
      default: false,
    },
    include: {
      default: () => [],
    },
    exclude: {
      default: () => [],
    },
  },
};
</script>

<style scoped>
.selection-form__title {
  line-height: 30px;
  font-weight: 500;
}
</style>
