/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import _factory from './_factory';
import mediaPanelService from '../../services/MediaPanels';

export default {
  ..._factory('mediaPanels/settings', {
    state: {
      page: null,
      limit: null,
      previewHtml: null,
      previewForSettings: null,
    },

    mutations: {
      setPreviewHtml(state, html) {
        state.previewHtml = html;
      },

      setPreviewForSettings(state, preview) {
        state.previewForSettings = preview;
      },

      resetPreviewHtml(state) {
        state.previewHtml = null;
      },

      resetPreviewForSettings(state) {
        state.previewForSettings = null;
      },
    },

    actions: {
      async generateSchedulesForSettings({ commit }, id) {
        commit('setLoading', true);

        const [err, result] = await mediaPanelService.generateSchedulesForSettings(id);

        commit('setLoading', false);

        return [err, result];
      },

      async getPreviewHtml({ commit }, { screenId, settingsId }) {
        commit('setLoading', true);

        const [err, result] = await mediaPanelService.previewHtml({ screenId, settingsId });

        if (result) {
          commit('setPreviewHtml', result);
        }

        commit('setLoading', false);

        return [err, result];
      },

      async getPreviewForSettings({ commit }, settings) {
        commit('setLoading', true);

        const [err, result] = await mediaPanelService.getPreviewForSettings(settings);

        if (result) {
          commit('setPreviewForSettings', result);
        }

        commit('setLoading', false);

        return [err, result];
      },

      async removePreviewForSetting({ commit }) {
        commit('resetPreviewForSettings');
      },
    },
  }),
};
