<template>
  <b-row align-v="center" class="mb-2">
    <b-col style="width: 220px; flex: none;">
      <div class="selection-form__title">{{ name }}</div>
    </b-col>
    <b-col>
      <div class="d-flex">
        <masked-input
          type="text"
          class="form-control form-control-sm w-50 mr-1"
          :placeholder="placeholderFrom"
          :value="include.gte"
          :mask="mask"
          :guide="false"
          :disabled="disabled"
          @change="includeGte($event.target.value)"
        >
        </masked-input>
        <masked-input
          type="text"
          class="form-control form-control-sm w-50"
          :placeholder="placeholderTo"
          :value="include.lte"
          :mask="mask"
          :guide="false"
          :disabled="disabled"
          @change="includeLte($event.target.value)"
        >
        </masked-input>
      </div>
    </b-col>
    <b-col>
      <div class="d-flex">
        <masked-input
          type="text"
          class="form-control form-control-sm w-50 mr-1"
          :placeholder="placeholderFrom"
          :value="exclude.gte"
          :mask="mask"
          :guide="false"
          :disabled="disabled"
          @change="excludeGte($event.target.value)"
        >
        </masked-input>
        <masked-input
          type="text"
          class="form-control form-control-sm w-50"
          :placeholder="placeholderTo"
          :value="exclude.lte"
          :mask="mask"
          :guide="false"
          :disabled="disabled"
          @change="excludeLte($event.target.value)"
        >
        </masked-input>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import MaskedInput from 'vue-text-mask';

export default {
  components: {
    MaskedInput,
  },
  props: {
    name: {
      default: '',
    },
    mask: {
      default: () => Array(10).fill(/\d/),
    },
    disabled: {
      default: false,
    },
    include: {
      default: () => ({}),
    },
    exclude: {
      default: () => ({}),
    },
    placeholderFrom: {
      default: 'от',
    },
    placeholderTo: {
      default: 'до',
    },
  },
  methods: {
    includeGte(value) {
      const include = { ...this.include };

      if (value) {
        include.gte = value;
      } else {
        delete include.gte;
      }

      this.$emit('include', include);
    },

    includeLte(value) {
      const include = { ...this.include };

      if (value) {
        include.lte = value;
      } else {
        delete include.lte;
      }

      this.$emit('include', include);
    },

    excludeGte(value) {
      const exclude = { ...this.exclude };

      if (value) {
        exclude.gte = value;
      } else {
        delete exclude.gte;
      }

      this.$emit('exclude', exclude);
    },

    excludeLte(value) {
      const exclude = { ...this.exclude };

      if (value) {
        exclude.lte = value;
      } else {
        delete exclude.lte;
      }

      this.$emit('exclude', exclude);
    },
  },
};
</script>

<style scoped>
.selection-form__title {
  line-height: 30px;
  font-weight: 500;
}
</style>
