<template>
  <div>
    <table
      class="table b-table border m-0"
      :style="{'opacity': disabled ? 0.5 : 1}"
    >
      <thead>
        <tr>
          <th colspan="3" class="border-bottom"></th>
          <th class="text-center px-1 py-1 border-left border-bottom">Кол-во кл-в</th>
          <th class="text-center px-1 py-1 border-left border-bottom">Итого кл-в в сегменте</th>
          <th class="text-center px-1 py-1 border-left border-bottom">Доля от всей базы, %</th>
          <th class="text-center px-1 py-1 border-left border-bottom">Откл.</th>
          <th class="text-center px-1 py-1 border-left border-bottom">LTV, руб</th>
          <th class="text-center px-1 py-1 border-left border-bottom">LTV сегмента, руб</th>
          <th class="text-center px-1 py-1 border-left border-bottom">Доля от всей базы, %</th>
          <th class="text-center px-1 py-1 border-left border-bottom">Откл.</th>
          <th colspan="2" class="text-center px-1 py-1 border-left border-bottom">Ср. LTV на 1 клиента</th>
          <th class="text-center px-1 py-1 border-left border-bottom">Откл., руб</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="recency in ['high', 'normal', 'low']">
          <template v-for="frequency in ['high', 'normal', 'low']">
            <template v-for="monetary in ['high', 'normal', 'low']">
              <template>
                <tr v-if="frequency === 'high' && monetary === 'high'" :key="`${recency}_${frequency}_${monetary}_gap`">
                  <td colspan="14" class="row-gap"></td>
                </tr>
                <rfm-money-group
                  :key="`${recency}_${frequency}_${monetary}_main`"
                  :title="getTitle(frequency, recency)"
                  :recency="recency"
                  :frequency="frequency"
                  :monetary="monetary"
                  :main-items="mainItems"
                  :offset-items="offsetItems"
                ></rfm-money-group>

              </template>
            </template>
          </template>
        </template>
        <tr>
          <td colspan="3"></td>
          <td class="border-left p-1 text-right align-middle">{{ totalCount.toLocaleString() }}</td>
          <td class="border-left"></td>
          <td class="border-left"></td>
          <td class="border-left"></td>
          <td class="border-left"></td>
          <td class="border-left p-1 text-right align-middle">{{ totalPrice.toLocaleString() }}</td>
          <td class="border-left"></td>
          <td class="border-left"></td>
          <td class="border-left p-1 text-right align-middle" colspan="2">{{ averageLtv.toLocaleString() }}</td>
          <td class="border-left"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import RfmMoneyGroup from './RfmMoneyGroup.vue';

const COLUMN_COUNT = 'count(customer_id)';
const COLUMN_PRICE = 'sum(orders_price)';

export default {
  components: {
    RfmMoneyGroup,
  },
  props: {
    titles: {
      default: () => ({}),
    },
    mainItems: {
      default: () => [],
    },
    offsetItems: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    totalCount() {
      return this.mainItems.reduce((total, current) => total + +current[COLUMN_COUNT], 0);
    },

    totalPrice() {
      return this.mainItems.reduce((total, current) => total + +current[COLUMN_PRICE], 0);
    },

    averageLtv() {
      return Math.round(this.totalPrice / this.totalCount);
    },
  },
  methods: {
    getTitle(frequency, recency) {
      return this.titles[`frequency_${frequency}_recency_${recency}`] || 'Без названия';
    },
  },
};
</script>

<style scoped>
.table {
  height: 1px;
  width: 100%;
  overflow-wrap: break-word;
}
.table th {
  font-weight: 400 !important;
}
.row-gap {
  height: 5px;
  background-color: #eee;
  padding: 0 !important;
}
</style>
