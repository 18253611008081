<template>
  <b-navbar class="menu" type="dark" :toggleable="false">
    <b-navbar-toggle target="nav_dropdown_collapse"></b-navbar-toggle>
    <b-collapse is-nav id="nav_dropdown_collapse">
      <logo></logo>
      <b-navbar-nav>
        <template v-for="(item, index) in items">
          <b-nav-item
            v-if="item.routeName"
            :key="index"
            :to="getUrl(item)"
          ><icon v-if="item.icon" :icon="item.icon" /> {{item.name}}</b-nav-item>
          <navbar-item-dropdown
            v-else
            :key="index"
            :text="item.name"
            :icon="item.icon"
            :auto="item.auto"
            :items="item.children"
            :active="isActive(item)"
          >
          </navbar-item-dropdown>
        </template>
        <b-nav-item
          href="//wiki.cinemabox.team"
          target="_blank"
        >Инструкция</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <navbar-user v-if="$user.id"></navbar-user>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Logo from './Logo.vue';
import NavbarItemDropdown from './NavbarItemDropdown.vue';
import NavbarUser from './NavbarUser.vue';

export default {
  components: {
    Logo,
    NavbarItemDropdown,
    NavbarUser,
  },
  props: {
    items: {
      default: () => [],
    },
  },
  mounted() {
    if (this.fixed) {
      document.body.style.paddingTop = `${this.$el.offsetHeight}px`;
    }
  },
  destroyed() {
    document.body.style.paddingTop = '0';
  },
  methods: {
    isActive(item) {
      return item.url === this.$route.matched[0].path;
    },

    getUrl(item) {
      if (item.url) {
        return item.url;
      }

      if (item.routeName) {
        const route = this.$router.resolve({ name: item.routeName, params: item.routeParams });
        return route?.resolved?.path;
      }

      return false;
    },
  },
};
</script>

<style>
.menu {
  padding: 0;
  background: #2f323e;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}
.menu .nav-item.navbar-top-item_auto > .dropdown-menu {
  min-width: 260px;
}
.menu .nav-item > a {
  padding: 11px 18px !important;
  color: #97999f !important;
  border-left: 0px solid #2f323e !important;
}
.menu .nav-item .navbar-top-item__subitem .dropdown-item {
  line-height: 1.2;
  padding: 8px 32px 8px 48px;
}
.menu .nav-item .navbar-top-item__subitem.has-submenu>.dropdown-item:after {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
.menu .nav-item .navbar-top-item__subitem .dropdown-item i {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}
.menu .logo {
  margin-left: 20px;
  width: 24px;
}
</style>
