import FileSaver from 'file-saver';
import _factory from './_factory';
import selectionsService from '../../services/Selections';

export default {
  ..._factory('selections', {
    actions: {
      async exportSelection({ commit }, { id, filename }) {
        commit('setLoading', true);

        const [err, data] = await selectionsService.exportSelection(id);

        if (err) {
          return [err, null];
        }

        if (!err && data) {
          const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
          FileSaver.saveAs(blob, `${filename}.csv`);
        }

        commit('setLoading', false);

        return [null, true];
      },
    },
  }),
};
