export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditProductForm',
        permission: 'products.edit',
      },
      {
        type: 'button',
        text: !item.isEnabled ? 'Активировать' : 'Убрать с продажи',
        action: !item.isEnabled ? 'enableProduct' : 'disableProduct',
        permission: 'products.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeProduct',
        permission: 'products.edit',
      },
    ],
  },
];
