import humps from 'humps';
import collectionServiceFactory from './collectionServiceFactory';
import { convertBlobToString } from '../utils/file';
import exportPromocodeGroup from '../api/promocodeGroup';

const promocodeGroupsService = collectionServiceFactory('promocodeGroups');

export default {
  ...promocodeGroupsService,

  async exportPromocodeGroup(id) {
    let [err, data] = await exportPromocodeGroup(id);

    if (err && err.data && err.data instanceof Blob) {
      const text = await convertBlobToString(err.data);
      err.data = JSON.parse(text);
    }

    if (data instanceof Blob) {
      data = await convertBlobToString(data);
    }

    return [humps.camelizeKeys(err), data];
  },
};
