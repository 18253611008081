<template>
  <div class="value-interval-wrap">
    <b-form-row>
      <b-col cols="6">
        <div class="text-search">
          <b-input
            type="text"
            size="sm"
            :value="from"
            :placeholder="placeholderFrom"
            @change="changeFrom"
          ></b-input>
          <span class="trash" @click="changeFrom('')"><i class="fa fa-times"></i></span>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="text-search">
          <b-input
            type="text"
            size="sm"
            :value="to"
            :placeholder="placeholderTo"
            @change="changeTo"
          ></b-input>
          <span class="trash" @click="changeTo('')"><i class="fa fa-times"></i></span>
        </div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      default: () => ({
        from: '',
        to: '',
      }),
    },
    placeholderFrom: {
      type: String,
      default: 'От',
    },
    placeholderTo: {
      type: String,
      default: 'До',
    },
  },
  computed: {
    from() {
      return this.value && this.value.from;
    },
    to() {
      return this.value && this.value.to;
    },
  },
  methods: {
    changeFrom(value) {
      this.$emit('change', {
        from: value,
        to: this.to,
      });
    },
    changeTo(value) {
      this.$emit('change', {
        from: this.from,
        to: value,
      });
    },
  },
};
</script>
