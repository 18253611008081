<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Название:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            autofocus
            size="sm"
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Юр. лицо:"
          :invalid-feedback="errors.legalEntityId"
          :state="states.legalEntityId"
        >
          <dictionary-provider name="legalEntities">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.legalEntityId"
                :options="$array.convertToOptions(items)"
                :state="states.legalEntityId"
                :disabled="loading"
                @change="updateValue('legalEntityId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Протокол:"
          :invalid-feedback="errors.cashierDeviceProtocolId"
          :state="states.cashierDeviceProtocolId"
        >
          <dictionary-provider name="cashierDeviceProtocols">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.cashierDeviceProtocolId"
                :options="$array.convertToOptions(items)"
                :state="states.cashierDeviceProtocolId"
                :disabled="loading"
                @change="updateValue('cashierDeviceProtocolId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Адрес:"
          :invalid-feedback="errors.address"
          :state="states.address"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.address"
            :state="states.address"
            :disabled="loading"
            @change="updateValue('address', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Ширина печати:"
          :invalid-feedback="errors.printWidth"
          :state="states.printWidth"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.printWidth"
            :state="states.printWidth"
            :disabled="loading"
            @change="updateValue('printWidth', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Варианты использования:">
          <b-form-checkbox
            :checked="!!item.fiscalable"
            :state="states.fiscalable"
            :disabled="loading"
            @change="updateValue('fiscalable', $event)"
          >Для фискализации</b-form-checkbox>
          <b-form-checkbox
            :checked="item.printable"
            :state="states.printable"
            :disabled="loading"
            @change="updateValue('printable', $event)"
          >Для печати</b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Использовать прокси:">
          <b-form-checkbox
            :checked="!!item.useProxy"
            :state="states.useProxy"
            :disabled="loading"
            @change="updateValue('useProxy', $event)"
          >Да</b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Время переключения смены:">
          <masked-input
            type="text"
            class="form-control form-control-sm"
            :value="item.toggleSessionTime"
            :disabled="loading"
            :mask="mask.getTimeMask"
            :guide="false"
            @change="updateValue('toggleSessionTime', $event.target.value)"
          >
          </masked-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Фискализация онлайн продаж:">
          <b-form-checkbox
            :checked="!!item.networkDevice"
            :state="states.networkDevice"
            :disabled="loading"
            @change="updateValue('networkDevice', $event)"
          >Да</b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MaskedInput from 'vue-text-mask';

import DictionaryProvider from '../../providers/DictionaryProvider';
import { MaskHelper } from '../../utils/mask';

export default {
  components: {
    DictionaryProvider,
    MaskedInput,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      mask: MaskHelper,
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
