import humps from 'humps';
import {
  refundOrder,
} from '../api/orders';

export default {
  async refundOrder(compositeId, params) {
    const [err, data] = await refundOrder(compositeId, humps.decamelizeKeys(params));
    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },
};
