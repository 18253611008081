export default () => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditDistributorReportEmailsForm',
        permission: 'distributor_report_emails.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeDistributorReportEmails',
        permission: 'distributor_report_emails.edit',
      },
    ],
  },
];
