<template>
  <drop
    @drop="onDrop"
    @dragenter="onDragEnter"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
  >
    <slot></slot>
  </drop>
</template>

<script>
import { Drop } from 'vue-drag-drop';

export default {
  components: {
    Drop,
  },
  data() {
    return {
      first: false,
      second: false,
    };
  },
  methods: {
    onDragEnter(transferData) {
      if (this.first) {
        this.second = true;
      } else {
        this.first = true;
      }

      this.$emit('dragenter', transferData);
    },

    onDragOver(transferData) {
      this.$emit('dragover', transferData);
    },

    onDragLeave(transferData) {
      if (this.second) {
        this.second = false;
      } else if (this.first) {
        this.first = false;
      }

      if (!this.first && !this.second) {
        this.$emit('dragleave', transferData);
      }
    },

    onDrop(transferData) {
      this.$emit('drop', transferData);

      this.first = false;
      this.second = false;
    },
  },
};
</script>
