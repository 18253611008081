<template>
  <b-modal
    ref="modal"
    centered
    ok-variant="success"
    ok-title="Сохранить"
    cancel-title="Закрыть"
    :visible="true"
    :busy="loading"
    @ok="ok"
    @hidden="$emit('hide')"
  >
    <template #modal-title><h4>Продажи на период</h4></template>

    <b-form-group
      label="Даты начала и окончания периода:"
    >
      <date-interval
        :value="{from: dateFrom, to: dateTo}"
        :disabled="loading"
        @selected="dateFrom = $event.from, dateTo = $event.to"
      ></date-interval>
    </b-form-group>

    <dictionary-provider
      name="appGroups"
      @loaded="onAppGroupsLoaded($event)"
    >
      <template #default="{ items: appGroups, loading: appGroupsLoading }">
        <b-container fluid class="p-0">
          <b-form-group label="Статус для группы приложений:">
            <b-form-checkbox
              v-for="appGroup in appGroups"
              :key="appGroup.id"
              :checked="isAppGroupChecked(appGroup)"
              :disabled="appGroupsLoading || loading"
              :class="[ isAppGroupChecked(appGroup) ? 'text-success' : 'text-danger' ]"
              @change="toggleAppGroup(appGroup)"
            >{{ appGroup.name }} ({{ isAppGroupChecked(appGroup) ? 'открыть продажи' : 'закрыть продажи' }})</b-form-checkbox>
          </b-form-group>
        </b-container>
      </template>
    </dictionary-provider>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';

import DateInterval from '../../components/fields/DateInterval.vue';
import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    DateInterval,
    DictionaryProvider,
  },
  props: {
  },
  data() {
    return {
      appGroups: [],
      dateFrom: null,
      dateTo: null,
    };
  },
  computed: {
    ...mapState('data/sales', {
      loading: (state) => state.loading,
      cinemaId: (state) => state.cinemaId,
      date: (state) => state.date,
    }),
  },
  watch: {
    date: {
      handler() {
        this.dateFrom = this.$datetime.convertDateToDbFormat(this.date);
        this.dateTo = this.$datetime.convertDateToDbFormat(this.date);
      },
      immediate: true,
    },
  },
  methods: {
    onAppGroupsLoaded(appGroups) {
      this.appGroups = appGroups;
    },

    isAppGroupChecked(appGroup) {
      return !!this.appGroups.find((ag) => ag.id === appGroup.id);
    },

    toggleAppGroup(appGroup) {
      if (this.isAppGroupChecked(appGroup)) {
        this.appGroups = this.appGroups.filter((ag) => ag.id !== appGroup.id);
      } else {
        this.appGroups = [...this.appGroups, appGroup];
      }
    },

    async ok(event) {
      event.preventDefault();

      await this.$store.dispatch('data/sales/toggleSales', {
        appGroups: this.appGroups,
        dates: this.$datetime.getDates(this.dateFrom, this.dateTo),
      });

      this.$refs.modal.hide();
    },
  },
};
</script>
