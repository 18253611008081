<template>
  <div>
    <toolbar
      v-if="showTopToolbar"
      class="mb-3"
      :disabled="loading"
      :actions="preparedPanelActions"
      :pagination="{ page, limit, total }"
      @change-page="changePage($event)"
      @change-limit="changeLimit($event)"
      @click="performPanelAction(...arguments)"
    >
      <slot name="toolbar-content"></slot>
    </toolbar>

    <b-table-simple
      class="datatable mb-0"
      :striped="true"
    >
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th v-if="isPanelActions">
            <b-form-checkbox
              :checked="allChecked"
              @click.native.stop
              @change="toggleAllChecked()"
            ></b-form-checkbox>
          </b-th>
          <b-th
            v-for="field in filteredFields"
            :key="field.key"
          >
            {{field.label}}
          </b-th>
          <b-th v-if="isItemActions">Действия</b-th>
        </b-tr>
        <b-tr
          v-if="isSomeFieldFilterable"
          class="b-table-top-row"
        >
          <b-td></b-td>
          <b-td v-if="isPanelActions"></b-td>
          <b-td
            class="column-filter"
            v-for="field in filteredFields"
            :key="field.id"
          >
            <slot :name="`filter-${field.key}`">
              <column-filter
                v-if="isFieldFilterable(field.key)"
                :type="getFieldType(field.key)"
                :value="filter[field.key]"
                :options="filtersOptions[field.key] || []"
                :disabled="loading"
                @update="changeFilter(field.key, $event)"
              ></column-filter>
            </slot>
          </b-td>
          <b-td v-if="isItemActions"></b-td>
        </b-tr>
        <b-tr
          v-if="!localItems.length"
          class="b-table-empty-row"
        >
          <b-td :colspan="emptyColSpan">
            <div class="text-center my-2">{{ loading ? 'Загрузка...' : 'Нет данных' }}</div>
          </b-td>
        </b-tr>
      </b-thead>
      <draggable
        v-if="localItems.length"
        tag="tbody"
        handle=".handle"
        :list="localItems"
        v-bind="{
          draggable: '.active'
        }"
        @end="endDrag()"
      >
        <b-tr
          v-for="(item, index) in localItems"
          :key="index"
          :class="localSortable ? item.sortable ? 'active' : 'row-disabled' : 'active'"
        >
          <b-td class="column-drag">
            <div
              v-if="!localSortable || item.sortable"
              class="handle"
            >
              <icon icon="fa-th-large"></icon>
            </div>
          </b-td>
          <b-td
            class="column-checkbox"
            v-if="isPanelActions"
          >
            <b-form-checkbox
              :checked="isChecked(item.id)"
              @click.native.stop
              @change="toggleChecked(item.id)"
            ></b-form-checkbox>
          </b-td>
          <b-td
            v-for="field in filteredFields"
            :key="field.id"
            :class="[`column-${field.key}`]"
          >
            <div v-if="$slots[`cell-${field.key}`] || $scopedSlots[`cell-${field.key}`]">
              <slot
                :name="`cell-${field.key}`"
                :item="item"
                :value="item[field.key]"
              ></slot>
            </div>
            <div v-else>{{ item[field.key] }}</div>
          </b-td>
          <b-td
            v-if="isItemActions"
            class="column-actions"
          >
            <actions
              class="row-actions"
              :actions="getItemActions(item)"
              @click="performItemAction(item.id, ...arguments)"
            ></actions>
          </b-td>
        </b-tr>
      </draggable>
    </b-table-simple>

    <toolbar
      v-if="localShowBottomToolbar"
      class="mt-4"
      :disabled="loading"
      :actions="preparedPanelActions"
      :pagination="{ page, limit, total }"
      @change-page="changePage($event)"
      @change-limit="changeLimit($event)"
      @click="performPanelAction(...arguments)"
    >
      <slot name="toolbar-content"></slot>
    </toolbar>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

import ColumnFilter from './ColumnFilter.vue';
import Toolbar from './Toolbar.vue';
import Actions from './Actions.vue';

export default {
  components: {
    ColumnFilter,
    Draggable,
    Toolbar,
    Actions,
  },
  props: {
    localSortable: {
      default: false,
    },
    loading: {
      default: false,
    },
    page: {
      default: 1,
    },
    limit: {
      default: 20,
    },
    total: {
      default: 0,
    },
    filter: {
      default: () => ({}),
    },
    items: {
      default: () => [],
    },
    fields: {
      default: () => [],
    },
    checked: {
      default: () => [],
    },
    panelActions: {
      type: Function,
      default: () => [],
    },
    itemActions: {
      type: Function,
      default: () => [],
    },
    isPanelActions: {
      default: true,
    },
    isItemActions: {
      default: true,
    },
    filtersOptions: {
      default: () => ({}),
    },
    changePage: {
      default: () => {},
    },
    changeLimit: {
      default: () => {},
    },
    changeFilter: {
      default: () => {},
    },
    showTopToolbar: {
      default: true,
    },
    showBottomToolbar: {
      default: true,
    },
    smartBottomToolbar: {
      default: true,
    },
    permissions: {
      default: null,
    },
  },
  data() {
    return {
      localItems: [],
    };
  },
  watch: {
    items: {
      handler() {
        const result = [...this.items].sort((a, b) => a.position - b.position);
        if (this.localSortable) {
          result.sort((a, b) => b.sortable - a.sortable);
        }
        this.localItems = result;
      },
      immediate: true,
    },
  },
  computed: {
    isSomeFieldFilterable() {
      return this.fields.some((field) => this.isFieldFilterable(field.key));
    },

    emptyColSpan() {
      return this.fields.length + 3;
    },

    allChecked() {
      return this.items.length && this.items.every((item) => this.checked.includes(item.id));
    },

    preparedPanelActions() {
      const panelActions = this.panelActions({
        checked: this.checked,
      });

      return this.filterActionsByPermissions(panelActions);
    },

    localShowBottomToolbar() {
      if (!this.smartBottomToolbar) {
        return this.showBottomToolbar;
      }

      if (!this.showTopToolbar) {
        return true;
      }

      if (this.items && this.items.length > 10) {
        return true;
      }

      return false;
    },

    filteredFields() {
      return this.fields.filter(({ id }) => id !== 'id' && id !== 'actions');
    },
  },
  methods: {
    isFieldFilterable(key) {
      return this.fields.find((field) => field.key === key)?.filterable;
    },

    getFieldType(key) {
      return this.fields.find((field) => field.key === key)?.type || 'text';
    },

    endDrag() {
      const result = {
        positions: this.localItems.map((item, index) => ({
          id: item.id,
          position: index,
        })),
      };

      this.$emit('change-positions', result);
    },

    getItemActions(item) {
      const itemActions = this.itemActions({ item });
      return this.filterActionsByPermissions(itemActions);
    },

    performPanelAction(action, args) {
      this.$emit('panel-action', {
        action,
        args,
        ids: this.checked.filter((id) => this.items.find((item) => item.id === id)),
      });
    },

    performItemAction(id, action, args) {
      this.$emit('item-action', {
        action,
        args,
        id,
      });
    },

    isChecked(id) {
      return this.checked.includes(id);
    },

    toggleChecked(id) {
      if (this.isChecked(id)) {
        this.updateChecked(this.checked.filter((_id) => _id !== id));
      } else {
        this.updateChecked([...this.checked, id]);
      }
    },

    toggleAllChecked() {
      if (!this.allChecked) {
        this.updateChecked(this.items.map((item) => item.id));
      } else {
        this.updateChecked([]);
      }
    },

    updateChecked(checked) {
      this.$emit('change-checked', checked);
    },

    filterActionsByPermissions(actions) {
      if (!this.permissions || !Array.isArray(this.permissions)) {
        return actions;
      }

      return actions.filter((item) => {
        if (item.items && Array.isArray(item.items)) {
          item.items = item.items.filter((child) => {
            if (child.permission) {
              return this.permissions.includes(child.permission);
            }

            return true;
          });

          if (item.items.length === 0 || item.items.filter((item) => item.type !== 'divider' && item.type !== 'header').length === 0) {
            return false;
          }

          if (item.items[item.items.length - 1].type === 'divider' || item.items[item.items.length - 1].type === 'header') {
            item.items.pop();
          }
        }

        if (item.permission) {
          return this.permissions.includes(item.permission);
        }

        return true;
      });
    },

    isFieldUsed(key) {
      return !!this.fields.find((field) => field.key === key);
    },
  },
};
</script>

<style scoped>
.datatable >>> .column-checkbox {
  width: 1px;
  text-align: center;
}
.datatable >>> .column-actions {
  width: 1px;
  text-align: center;
}
.datatable >>> .column-id {
  width: 1px;
  text-align: center;
}
.datatable >>> .column-drag {
  width: 1px;
  text-align: center;
}
.datatable >>> .row-disabled {
  background-color: rgba(0,0,0,.05)!important;
}
.handle {
  cursor: move;
}
</style>
