import isSameDay from 'date-fns/isSameDay';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import addDays from 'date-fns/addDays';
import formatISO from 'date-fns/formatISO';

export default {
  dateFormat: 'DD.MM.YYYY',
  timeFormat: 'HH:mm',
  datetimeFormat: 'DD.MM.YYYY HH:mm',
  timezone: 'Europe/Moscow',

  isSameDay(date1, date2) {
    return isSameDay(parseISO(date1), parseISO(date2));
  },

  isDateBefore(date1, date2) {
    const d1 = parseISO(date1);
    const d2 = parseISO(date2);

    return isBefore(d1, d2);
  },

  isDateAfter(date1, date2) {
    const d1 = parseISO(date1);
    const d2 = parseISO(date2);

    return isAfter(d1, d2);
  },

  isDateSameOrBefore(date1, date2) {
    const d1 = parseISO(date1);
    const d2 = parseISO(date2);

    return isBefore(d1, d2) || isSameDay(d1, d2);
  },

  isDateSameOrAfter(date1, date2) {
    const d1 = parseISO(date1);
    const d2 = parseISO(date2);

    return isAfter(d1, d2) || isSameDay(d1, d2);
  },

  getDates(dateStart, dateEnd) {
    const dates = [];

    let date = parseISO(dateStart);
    const last = parseISO(dateEnd);

    while (isBefore(date, last) || isSameDay(date, last)) {
      dates.push(date);

      date = addDays(date, 1);
    }

    return dates;
  },

  convertToDbFormat(date) {
    return formatISO(date, { representation: 'date' });
  },
};
