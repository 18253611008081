<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Название:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        type="text"
        autofocus
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Группа приложений"
      :invalid-feedback="errors.appGroupId"
      :state="states.appGroupId"
    >
      <dictionary-provider name="appGroups">
        <template #default="{ items: appGroups, loading: appGroupsLoading }">
          <b-form-select
            size="sm"
            :value="item.appGroupId"
            :options="$array.convertToOptions(appGroups, null)"
            :disabled="loading || appGroupsLoading"
            @change="updateValue('appGroupId', $event)"
          ></b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Кинотеатры"
      :invalid-feedback="errors.cinemas"
      :state="states.cinemas"
    >
      <dictionary-provider name="cinemas">
        <template #default="{ items, loading: dictionaryLoading }">
          <toggle-list
            :options="convertCinemas($array.union(items, item.cinemas))"
            :value="item.cinemas"
            :disabled="loading || dictionaryLoading"
            @change="updateValue('cinemas', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Ключ для апи:"
      :invalid-feedback="errors.apiKey"
      :state="states.apiKey"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.apiKey"
        :state="states.apiKey"
        :disabled="loading"
        @change="updateValue('apiKey', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Ключ для доступа в приватную зону:"
      :invalid-feedback="errors.private"
      :state="states.private"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.private"
        :state="states.private"
        :disabled="loading"
        @change="updateValue('private', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Процент сервисного сбора:"
      :invalid-feedback="errors.serviceFeePercent"
      :state="states.serviceFeePercent"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.serviceFeePercent"
        :state="states.serviceFeePercent"
        :disabled="loading"
        @change="updateValue('serviceFeePercent', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      class="mt-2"
      :disabled="loading"
      :invalid-feedback="errors.pushkinsCardEnabled"
      :state="states.pushkinsCardEnabled"
    >
      <b-form-checkbox
        :checked="!!item.pushkinsCardEnabled"
        :state="states.pushkinsCardEnabled"
        :disabled="loading"
        @change="updateValue('pushkinsCardEnabled', $event)"
      >
        Приложению разрешены продажи по Пушкинской карте
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      class="mt-2"
      :disabled="loading"
      :invalid-feedback="errors.ticketOperator"
      :state="states.ticketOperator"
    >
      <b-form-checkbox
        :checked="!!item.ticketOperator"
        :state="states.ticketOperator"
        :disabled="loading"
        @change="updateValue('ticketOperator', $event)"
      >
        Билетный оператор
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      v-if="item.ticketOperator"
      label="ИНН владельца терминала для приема платежей по Пушкинской карте:"
      :invalid-feedback="errors.pushkinsCardTerminalOwner"
      :state="states.pushkinsCardTerminalOwner"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.pushkinsCardTerminalOwner"
        :state="states.pushkinsCardTerminalOwner"
        :disabled="loading"
        @change="updateValue('pushkinsCardTerminalOwner', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      v-if="item.cinemas && item.cinemas.length"
      label="Фискализация в кинотеатрах"
    >
      <b-card no-body class="cashier-devices">
        <b-tabs pills card vertical>
          <b-tab
            v-for="cinema in item.cinemas"
            lazy
            :key="cinema.id"
            @activate-tab="clearEmptyAppDevices()"
          >
            <template #title>
              <div>{{ cinema.name }}</div>
              <div v-if="cinema.city">{{ cinema.city.name }}</div>
            </template>
            <collection-provider
              collection="cashierDevices"
              :filter="{ cinemaId: cinema.id }"
              :disable-fetching="!cinema.id"
            >
              <template #default="{ data: { items: cinemaDevices, loading: cinemaDevicesLoading } }">
                <cashier-place-devices
                  :devices="getAppDevices(cinemaDevices)"
                  :available-devices="cinemaDevices"
                  :disabled="loading || cinemaDevicesLoading"
                  @change="setAppDevices(cinemaDevices, $event)"
                ></cashier-place-devices>
              </template>
            </collection-provider>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-form-group>

    <b-form-group
      class="mt-2"
      :disabled="loading"
      :invalid-feedback="errors.recaptchaEnabled"
      :state="states.recaptchaEnabled"
    >
      <b-form-checkbox
        :checked="!!item.recaptchaEnabled"
        :state="states.recaptchaEnabled"
        :disabled="loading"
        @change="updateValue('recaptchaEnabled', $event)"
      >
        Включить капчу
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      v-if="item.recaptchaEnabled"
      label="Секретный ключ:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        type="text"
        autofocus
        size="sm"
        :value="item.recaptchaSecretKey"
        :state="states.recaptchaSecretKey"
        :disabled="loading"
        @change="updateValue('recaptchaSecretKey', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      v-if="item.recaptchaEnabled"
      label="Открытый ключ:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        type="text"
        autofocus
        size="sm"
        :value="item.recaptchaPublicKey"
        :state="states.recaptchaPublicKey"
        :disabled="loading"
        @change="updateValue('recaptchaPublicKey', $event)"
      ></b-form-input>
    </b-form-group>
  </b-container>
</template>

<script>
import CollectionProvider from '../../providers/CollectionProvider';
import DictionaryProvider from '../../providers/DictionaryProvider';
import CashierPlaceDevices from '../cinemas/CashierPlaceDevices.vue';
import ToggleList from '../../components/fields/ToggleList.vue';

export default {
  components: {
    CashierPlaceDevices,
    DictionaryProvider,
    CollectionProvider,
    ToggleList,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    convertDevicesFromDbFormat(items) {
      return items ? items.map((item) => item.pivot) : [];
    },
    convertDevicesToDbFormat(items) {
      return items ? items.map((item) => ({ id: item.cashierDeviceId, pivot: item })) : [];
    },
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },
    clearEmptyAppDevices() {
      let appDevices = this.convertDevicesFromDbFormat(this.item.appDevices);

      appDevices = appDevices.filter((ad) => !ad.cashierDeviceId);

      this.updateValue('appDevices', this.convertDevicesToDbFormat(appDevices));
    },
    getAppDevices(cinemaDevices) {
      const appDevices = this.convertDevicesFromDbFormat(this.item.appDevices);

      return appDevices.filter((ad) => !ad.cashierDeviceId || cinemaDevices.find((cd) => cd.id === ad.cashierDeviceId));
    },
    getOtherAppDevices(cinemaDevices) {
      const appDevices = this.convertDevicesFromDbFormat(this.item.appDevices);

      return appDevices.filter((ad) => ad.cashierDeviceId && !cinemaDevices.find((cd) => cd.id === ad.cashierDeviceId));
    },
    setAppDevices(cinemaDevices, devices) {
      const mergedDevices = [
        ...devices,
        ...this.getOtherAppDevices(cinemaDevices),
      ];

      this.updateValue('appDevices', this.convertDevicesToDbFormat(mergedDevices));
    },
  },
};
</script>

<style scoped>
.cashier-devices >>> .card-header {
  padding: 0.5rem 0.5rem;
}
.cashier-devices >>> .card-body {
  padding: 0.5rem 0.5rem;
}
</style>
