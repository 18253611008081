<template>
  <multi-view>
    <template #side>
      <params></params>
    </template>

    <template #panel>
      <b-button
        size="sm"
        variant="outline-info"
        :to="{ name: 'reportIndex' }"
      >
        <span slot="text">
          <icon icon="fa-chevron-left"></icon> Назад
        </span>
      </b-button>
    </template>

    <template #content>
      <div
        v-if="report"
        class="p-3 w-100"
        v-html="report"
        id="htmlReport"
      ></div>
    </template>
  </multi-view>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MultiView from '../../../layout/MultiView.vue';
import Params from './Params.vue';

export default {
  components: {
    MultiView,
    Params,
  },
  computed: {
    ...mapState('reports/proceedsByCinemasByPc', {
      report: (state) => state.report,
      filter: (state) => state.filter,
    }),
  },
  methods: {
    ...mapActions({
      setFilter: 'reports/proceedsByCinemasByPc/setFilter',
      fetchReport: 'reports/proceedsByCinemasByPc/fetchReport',
    }),
  },
};
</script>
