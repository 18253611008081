<template>
  <div class="pt-3">
    <b-table
      class="mb-0"
      caption-top
      empty-text="Нет данных"
      :items="items"
      :fields="fields"
      :show-empty="true"
    >
      <template #cell(type)="data">
        <div v-if="data.item.type === 'pay'">Оплата</div>
        <div v-if="data.item.type === 'refund'">Возврат</div>
      </template>

      <template #cell(paid)="data">
        <div v-if="data.item.paid === true">Да</div>
        <div v-if="data.item.paid === false">Нет</div>
      </template>

      <template #cell(transactionTime)="data">
        <div v-if="!!data.item.transactionTime">
          {{ $datetime.format(data.item.transactionTime, $datetime.datetimeFormat) }}
        </div>
        <div v-else>—</div>
      </template>

      <template #cell(createdAt)="data">
        <div v-if="!!data.item.createdAt">
          {{ $datetime.format(data.item.createdAt, $datetime.datetimeFormat) }}
        </div>
        <div v-else>—</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import fields from './_cardPayments.fields';

export default {
  props: {
    items: {
      default: [],
    },
  },
  data() {
    return {
      fields,
    };
  },
};
</script>

<style scoped>
.table >>> th {
  font-weight: 400;
  font-size: 14px;
}
.table >>> td {
  font-weight: 300;
  font-size: 14px;
}
</style>
