<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Наименование:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Описание:"
          :invalid-feedback="errors.description"
          :state="states.description"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.description"
            :state="states.description"
            :disabled="loading"
            @change="updateValue('description', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Необходимые документы:"
          :invalid-feedback="errors.documents"
          :state="states.documents"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.documents"
            :state="states.documents"
            :disabled="loading"
            @change="updateValue('documents', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
export default {
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
