export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    sortable: true,
    filterable: true,
  },
  {
    key: 'balance',
    type: 'text',
    label: 'Баланс',
    sortable: false,
    filterable: false,
  },
  {
    key: 'ticketPrice',
    type: 'text',
    label: 'Согласованная цена билета',
    sortable: false,
    filterable: false,
  },
];
