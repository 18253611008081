<template>
  <modal-form-dialog
    size="xl"
    :title="title"
    :save="save"
    :close="() => true"
    :busy="loading"
    @hidden="$emit('close')"
  >
    <movies-favorite-table
      v-if="cinemas.length || selectedItems.length"
      :cinemas="cinemas"
      :releases="movie.releases"
    />
    <div v-else>
      Загрузка..
    </div>
  </modal-form-dialog>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import { mapActions, mapState } from 'vuex';

import ModalFormDialog from '../../components/modal/ModalFormDialog.vue';

import MoviesFavoriteTable from './MoviesFavoriteTable.vue';

export default {
  components: {
    ModalFormDialog,
    MoviesFavoriteTable,
  },
  props: {
    movie: Object,
  },
  computed: {
    ...mapState('data/favoriteReleases', [
      'loading',
      'selectedItems',
    ]),
    ...mapState('dictionaries/cinemas', {
      cinemas: (state) => state.items,
    }),

    title() {
      return `${this.movie.name}, ${this.movie.ageLimit}+`;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('data/favoriteReleases', [
      'setFilter',
      'fetchItems',
      'updateItem',
    ]),

    async init() {
      this.setFilter({
        field: 'movieId',
        value: this.movie.id,
      });

      await this.$store.dispatch('dictionaries/cinemas/fetchItems');
      await this.fetchItems();

      this.$store.dispatch('data/favoriteReleases/initReleases');
    },

    async save() {
      const [err, data] = await this.updateItem({
        id: this.movie.id,
        values: this.selectedItems,
      });

      if (data) {
        this.$emit('saved');
      }
      if (err && err.status === 422 && err?.data?.errors) {
        this.$notify({
          type: 'error',
          text: err?.data?.errors,
        });
      }

      return !err;
    },
  },
};
</script>
