export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    filterable: false,
    sortable: false,
  },
  {
    key: 'duration',
    type: 'text',
    label: 'Длительность',
    filterable: false,
    sortable: false,
  },
  {
    key: 'positionType',
    type: 'text',
    label: 'Позиция',
    filterable: false,
    sortable: false,
  },
  {
    key: 'period',
    type: 'text',
    label: 'Даты',
    sortable: false,
    filterable: false,
  },
  {
    key: 'type',
    type: 'text',
    label: 'Тип',
    sortable: false,
    filterable: false,
  },
  {
    key: 'halls',
    type: 'text',
    label: 'Залы',
    sortable: false,
    filterable: false,
  },
  {
    key: 'targeting',
    type: 'text',
    label: 'Таргетинг',
    sortable: false,
    filterable: false,
  },
];
