<template>
  <div class="table-container roles">
    <collection-provider
      collection="roles"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          :permissions="$user.permissions"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #cell(name)="data">
            <div>{{data.item.name}}</div>
            <div
              v-if="data.item.note"
              class="text-muted mt-2"
            >{{data.item.note}}</div>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.role.show"
      create-title="Создание роли"
      edit-title="Редактирование роли"
      collection="roles"
      :id="modals.role.id"
      @close="modals.role.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <role-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></role-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import RoleForm from './RoleForm.vue';

export default {
  components: {
    CollectionProvider,
    ModalModelForm,
    Datatable,
    RoleForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        role: {
          show: false,
          id: 0,
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateRoleForm() {
      this.modals.role.id = '';
      this.modals.role.show = true;
    },

    async removeCheckedRoles() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    openEditRoleForm(id) {
      this.modals.role.id = id;
      this.modals.role.show = true;
    },

    async removeRole(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>
