<template>
  <b-modal
    centered
    size="lg"
    hide-footer
    title="Рекламные ролики"
    :visible="true"
    @ok="close"
    @hidden="close"
  >
    <b-table
      class="datatable border"
      :fields="fields"
      :items="advertisements"
      :striped="true"
      :hover="false"
      :foot-clone="true"
    >
      <template #cell(duration)="data">
        {{ $datetime.convertTime(data.item.duration) }}
      </template>

      <template #foot(name)>
        <span>Общая длительность</span>
      </template>

      <template #foot(duration)>
        <span>{{ $datetime.convertTime(totalDuration) }}</span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
/* eslint-disable no-restricted-syntax */
export default {
  props: {
    advertisements: {
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          type: 'text',
          label: 'Название',
        },
        {
          key: 'duration',
          type: 'text',
          label: 'Длительность',
        },
      ],
    };
  },
  computed: {
    totalDuration() {
      return this.advertisements.reduce((total, advertisement) => total + advertisement.duration, 0);
    },
  },
  methods: {
    close() {
      this.$emit('hide');
    },
  },
};
</script>

<style scoped>

</style>
