<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Наименование:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Город:"
          :invalid-feedback="errors.cityId"
          :state="states.cityId"
        >
          <collection-lookup
            collection="cities"
            :state="states.cityId"
            :value="item.cityId"
            :text="getCityFullName(item)"
            :disabled="loading"
            @select="updateValue('cityId', $event)"
          ></collection-lookup>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Юридическое лицо:"
          :invalid-feedback="errors.legalEntityId"
          :state="states.legalEntityId"
        >
          <dictionary-provider name="legalEntities">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.legalEntityId"
                :options="$array.convertToOptions(items)"
                :state="states.legalEntityId"
                :disabled="loading"
                @change="updateValue('legalEntityId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Смещение UTC:"
          :invalid-feedback="errors.utcOffset"
          :state="states.utcOffset"
        >
          <dictionary-provider name="utcOffsets">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.utcOffset"
                :options="convertUtcOffsets(items)"
                :state="states.utcOffset"
                :disabled="loading"
                @change="updateValue('utcOffset', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Время работы в будние дни:"
          :invalid-feedback="errors.worktimeWeekday"
          :state="states.worktimeWeekday"
        >
          <masked-input
            type="text"
            size="sm"
            class="form-control form-control-sm"
            :placeholder="placeholder.TIME_RANGE"
            :value="item.worktimeWeekday"
            :mask="mask.getTimeRange"
            :guide="false"
            :disabled="loading"
            :state="states.worktimeWeekday"
            @change="updateValue('worktimeWeekday', $event.target.value)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Время работы в выходные дни:"
          :invalid-feedback="errors.worktimeHoliday"
          :state="states.worktimeHoliday"
        >
          <masked-input
            type="text"
            size="sm"
            class="form-control form-control-sm"
            :placeholder="placeholder.TIME_RANGE"
            :value="item.worktimeHoliday"
            :mask="mask.getTimeRange"
            :guide="false"
            :disabled="loading"
            :state="states.worktimeHoliday"
            @change="updateValue('worktimeHoliday', $event.target.value)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Адрес:"
          :invalid-feedback="errors.address"
          :state="states.address"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.address"
            :state="states.address"
            :disabled="loading"
            @change="updateValue('address', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Широта:"
          :invalid-feedback="errors.latitude"
          :state="states.latitude"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.latitude"
            :state="states.latitude"
            :disabled="loading"
            @change="updateValue('latitude', parseFloat($event))"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Долгота:"
          :invalid-feedback="errors.longitude"
          :state="states.longitude"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.longitude"
            :state="states.longitude"
            :disabled="loading"
            @change="updateValue('longitude', parseFloat($event))"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <cinema-contacts
      class="mt-3"
      :contacts="item.contacts"
      :disabled="loading"
      @change="updateValue('contacts', $event)"
    ></cinema-contacts>
    <cinema-support-contacts
      class="mt-3"
      :supportContacts="item.supportContacts"
      :disabled="loading"
      @change="updateValue('supportContacts', $event)"
    ></cinema-support-contacts>
  </b-container>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import CollectionLookup from '../../components/CollectionLookup.vue';
import DictionaryProvider from '../../providers/DictionaryProvider';
// eslint-disable-next-line import/named
import { MaskHelper } from '../../utils/mask';
import { placeholder } from '../../utils/placeholder';
import CinemaContacts from '../../components/special/CinemaContacts.vue';
import CinemaSupportContacts from '../../components/special/CinemaSupportContacts.vue';

export default {
  components: {
    DictionaryProvider,
    MaskedInput,
    CollectionLookup,
    CinemaContacts,
    CinemaSupportContacts,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      placeholder,
      mask: MaskHelper,
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    convertUtcOffsets(items) {
      return items.map((item) => ({ value: item.offset, text: item.offset }));
    },
    getCityFullName(item) {
      const city = item?.city?.name;
      const region = item?.city?.region?.name;

      return region ? `${city} / ${region}` : city;
    },
  },
};
</script>
