<template>
  <div class="modal-orders">
    <div class="d-flex">
      <div class="flex-1 header-block">
        <div>
          <div class="header-item" v-if="item.tickets.length">
            <span class="text-secondary">Фильм:</span> {{ show.release.movie.name }}, {{ show.release.format.name }}
          </div>
          <div class="header-item" v-if="item.tickets.length">
            <span class="text-secondary">Сеанс:</span> {{ formatDate(show.date) }}, {{ formatTime(show.time) }}, Зал {{ show.hall.name }}
          </div>
          <div class="header-item" v-if="item.customer && (item.customer.name || item.customer.surname)">
            <span class="text-secondary">Клиент:</span> {{ formatCustomerName(item.customer) }}
          </div>
          <div class="header-item" v-if="item.customer && item.customer.phone">
            <span class="text-secondary">Телефон:</span> {{ formatPhone(item.customer.phone) }}
          </div>
          <div class="header-item" v-if="item.customer && item.customer.email">
            <span class="text-secondary">Email:</span> {{ item.customer.email }}
          </div>
        </div>
      </div>

      <div class="flex-1 header-block">
        <div class="header-item" :class="[ !!item.approvedAt ? 'text-success' : 'text-danger' ]">
          <span class="text-secondary">Дата создания:</span> {{ formatDatetime(item.createdAt) }} ({{ !!item.approvedAt ? `подтвержден ${formatDatetime(item.approvedAt)}` : 'не подтвержден' }})
        </div>
        <div class="header-item">
          <span class="text-secondary">Источник продажи:</span> {{ item.sourceName }}
        </div>
        <div class="header-item" v-if="item.paymentTypeId">
          <span class="text-secondary">Тип оплаты:</span> {{ item.paymentType.name }}
        </div>
        <div class="header-item" v-if="item.merchantPayment && item.merchantPayment.buyer && (item.merchantPayment.buyer.firstName || item.merchantPayment.buyer.middleName || item.merchantPayment.buyer.lastName)">
          <span class="text-secondary">ФИО покупателя:</span> {{ formatBuyerName(item.merchantPayment.buyer) }}
        </div>
        <div class="header-item" v-if="item.promocode">
          <span class="text-secondary">Использован промокод:</span> {{ item.promocode.number }}
        </div>
        <div class="header-item" v-if="item.certificate">
          <span class="text-secondary">Использован сертификат:</span> {{ item.certificate.number }}
        </div>
      </div>
    </div>

    <b-tabs
      v-model="currentTab"
      class="mt-3"
    >
      <b-tab
        title="Детали заказа"
        active
      >
        <order-details :item="item" />
      </b-tab>

      <b-tab
        v-if="item.kkmFiscalItems.length || item.kkmFullFiscalItems.length"
        title="Фискализация"
      >
        <kkm-fiscal-items
          :kkm-fiscal-items="item.kkmFiscalItems"
          :kkm-full-fiscal-items="item.kkmFullFiscalItems"
        />
      </b-tab>

      <b-tab
        v-if="item.cardPayments && item.cardPayments.length"
        title="Детали оплаты"
      >
        <card-payments
          :items="item.cardPayments"
        />
      </b-tab>

      <b-tab
        v-if="item.merchantPayment"
        title="Детали оплаты"
      >
        <merchant-payment
          :item="item.merchantPayment"
        />
      </b-tab>
    </b-tabs>

    <div class="d-flex justify-content-end border-top pt-3 mt-3">
      <b-button
        v-if="canRefund(item, false)"
        variant="outline-primary"
        class="mr-3"
        :disabled="loading"
        @click="$emit('refund-order', {includeServiceFee: false})"
      >
        Вернуть заказ
      </b-button>

      <b-button
        v-if="canRefund(item, true)"
        variant="outline-danger"
        :disabled="loading"
        @click="$emit('refund-order', {includeServiceFee: true})"
      >
        Полный возврат
      </b-button>
    </div>
  </div>
</template>

<script>
import { Formatter } from '../../utils/formatter';

import OrderDetails from './OrderDetails.vue';
import KkmFiscalItems from './KkmFiscalItems.vue';
import CardPayments from './CardPayments.vue';
import MerchantPayment from './MerchantPayment.vue';

export default {
  components: {
    OrderDetails,
    KkmFiscalItems,
    CardPayments,
    MerchantPayment,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: null,
    },
  },
  data() {
    return {
      currentTab: 0,
    };
  },
  computed: {
    show() {
      return this.item?.tickets[0]?.show;
    },
  },
  methods: {
    formatDatetime(datetime) {
      return this.$datetime.format(datetime, this.$datetime.datetimeFormat);
    },

    formatDate(date) {
      return this.$datetime.format(date, 'D MMMM YYYY');
    },

    formatTime(time) {
      const momentTime = this.$datetime.convertTimeToMoment(time);
      return this.$datetime.format(momentTime, this.$datetime.timeFormat);
    },

    formatCustomerName(customer) {
      let elements = [
        customer.name,
        customer.surname,
      ];

      return elements.filter((item) => !!item).join(' ');
    },

    formatBuyerName(buyer) {
      let elements = [
        buyer.lastName,
        buyer.firstName,
        buyer.middleName,
      ];

      return elements.filter((item) => !!item).join(' ');
    },

    formatPhone(phone) {
      return Formatter.phoneFormat(phone);
    },

    canRefund(item, includeServiceFee) {
      // возврат доступен только для онлайн-заказов
      if (!item.merchantPayment) {
        return false;
      }

      // полный возврат доступен только если был сервисный сбор
      if (includeServiceFee && !item.merchantPayment.serviceFee) {
        return false;
      }

      if (item.approvedAt) {
        // если есть невозвращенные элементы, разрешаем возврат
        if (item.tickets?.some((item) => !item.refundedAt) ||
            item.products?.some((item) => !item.refundedAt) ||
            item.certficates?.some((item) => !item.refundedAt)) {
            return true;
        }

        // если есть невозвращенные сервисные сборы, разрешаем полный возврат
        if (includeServiceFee && item.serviceFees?.some((item) => !item.refundedAt)) {
          return true;
        }
      }

      if (item.merchantPayment.authorization) {
        // считаем сумму возвратов
        let refundAmount = 0;

        if (item.merchantPayment.refunds?.length) {
          refundAmount = item.merchantPayment.refunds.reduce((amount, operation) => {
            return amount + operation.amount;
          }, 0);
        }

        if (refundAmount < item.merchantPayment.amount) {
          return includeServiceFee || (refundAmount < item.merchantPayment.amount - item.merchantPayment.serviceFee);
        }
      }

      return false;
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-orders {
  font-weight: 400;
}
.header-item {
  line-height: 1.8;
}
</style>
