<template>
  <div class="discount-period">
    <div v-if="!item.dateStart && !item.dateEnd">Без срока действия</div>
    <div v-else>
      <span v-if="item.dateStart" class="mr-1">c {{$datetime.formatDateTime(item.dateStart)}}</span>
      <span v-if="item.dateEnd">по {{$datetime.formatDateTime(item.dateEnd)}}</span>
    </div>

    <div v-if="!item.days || item.days.length === 0 || item.days.length === 7">Все дни недели</div>
    <div v-else>
      <span class="mr-1">Дни недели:</span>
      <span>{{$array.convertToText(item.days, '', 'short')}}</span>
    </div>

    <div v-if="!item.timeStart && !item.timeEnd">Без ограничения по времени</div>
    <div v-else>
      <span v-if="item.timeStart" class="mr-1">с {{item.timeStart}}</span>
      <span v-if="item.timeEnd">до {{item.timeEnd}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.discount-period > div {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
