var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('collection-provider',{ref:"collection",attrs:{"collection":_vm.collection,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"loaded":function($event){return _vm.onCollectionLoaded()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('model-provider',{ref:"model",attrs:{"collection":_vm.collection,"id":_vm.itemId},on:{"notfound":function($event){return _vm.openFirstItem()},"loaded":function($event){return _vm.$emit('item-loaded', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_data = ref.data;
var loading = ref_data.loading;
var item = ref_data.item;
return [_vm._t("default",null,{"data":Object.assign({}, data, {item: item, loading: loading}),"actions":Object.assign({}, actions, {open: _vm.open, remove: _vm.remove, load: _vm.load})})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }