import moment from 'moment';

import { BUSINESS_DATE_BORDER } from '../constants';

moment.locale('ru');

export default {
  dateFormat: 'DD.MM.YYYY',
  timeFormat: 'HH:mm',
  datetimeFormat: 'DD.MM.YYYY HH:mm',
  timezone: 'Europe/Moscow',

  format(date, format) {
    const momentDate = moment(date);
    return momentDate.isValid() ? momentDate.format(format) : '-';
  },

  now() {
    return moment();
  },

  convertToDate(date) {
    return moment(date).toDate();
  },

  convertTimeToMoment(time, businessDateBorder = BUSINESS_DATE_BORDER) {
    const momentTime = moment(time, 'HH:mm:ss');
    const momentBusinessDateBorder = moment(businessDateBorder, 'HH:mm');

    if (momentTime.isBefore(momentBusinessDateBorder)) {
      momentTime.add(1, 'days');
    }

    return momentTime;
  },

  compareTimes(time1, time2) {
    const momentTime1 = this.convertTimeToMoment(time1);
    const momentTime2 = this.convertTimeToMoment(time2);

    if (momentTime1.isBefore(momentTime2)) {
      return -1;
    }

    if (momentTime2.isBefore(momentTime1)) {
      return 1;
    }

    return 0;
  },

  getDates(dateStart, dateEnd) {
    const dates = [];

    const date = moment(dateStart);
    const last = moment(dateEnd);

    while (date.isSameOrBefore(last, 'days')) {
      dates.push(date.toDate());

      date.add(1, 'day');
    }

    return dates;
  },

  convertDateToDbFormat(date, format = '') {
    return moment(date, format).format('YYYY-MM-DD');
  },

  isSameDay(date1, date2) {
    return moment(date1).isSame(date2, 'days');
  },

  isSameMonth(date1, date2) {
    return moment(date1).isSame(date2, 'month');
  },

  getDay(date) {
    return moment(date).day();
  },

  getStartOfCurrentMonth() {
    return moment().date(1).startOf('day').toDate();
  },

  getCurrentDate() {
    return moment().startOf('day').toDate();
  },

  findWeekStart(date) {
    let momentDate = moment(date);

    if (momentDate.day() !== 4) {
      momentDate = momentDate.subtract(1, 'days');

      while (momentDate.day() !== 4) {
        momentDate = momentDate.subtract(1, 'days');
      }
    }

    return momentDate.toDate();
  },

  findMonthStart(date) {
    return moment(date).date(1).toDate();
  },

  findMonthEnd(date) {
    return moment(date).endOf('month').toDate();
  },

  findNextMonthStart(date) {
    return moment(date).add(1, 'month').date(1).toDate();
  },

  findPrevMonthStart(date) {
    return moment(date).subtract(1, 'month').date(1).toDate();
  },

  findNextWeekStart(date) {
    return moment(date).add(1, 'weeks').day(4).toDate();
  },

  findPrevWeekStart(date) {
    return moment(date).subtract(1, 'weeks').day(4).toDate();
  },

  isFirstHalfOfMonth(date) {
    return moment(date).date() < 15;
  },

  getWeekByDate(date) {
    const dateStart = moment(this.findWeekStart(date)).toDate();
    const dateEnd = moment(dateStart).add(6, 'days').toDate();

    return {
      dateStart,
      dateEnd,
    };
  },

  getMonthByDate(date, count, type) {
    let dateStart;
    let dateEnd;

    if (type === 'prev') {
      dateStart = moment(date).toDate();
      dateEnd = moment(dateStart).subtract(count, 'month').toDate();

      return {
        dateStart: this.convertDateToDbFormat(dateEnd),
        dateEnd: this.convertDateToDbFormat(dateStart),
      };
    }
    dateStart = moment(date).toDate();
    dateEnd = moment(dateStart).add(count, 'month').toDate();

    return {
      dateStart: this.convertDateToDbFormat(dateStart),
      dateEnd: this.convertDateToDbFormat(dateEnd),
    };
  },

  isDateBetween(date, dateStart, dateEnd) {
    return moment(date).isSameOrAfter(dateStart, 'days') && moment(date).isSameOrBefore(dateEnd, 'days');
  },

  formatDate(date) {
    return date && moment(date).format(this.dateFormat);
  },

  formatDateInCurrentTimezone(date) {
    return date && moment(date).format(this.dateFormat);
  },

  formatDateTime(date) {
    return moment(date).format(this.datetimeFormat);
  },

  formatTime(time) {
    return moment(time, 'HH:mm:ss').format('HH:mm');
  },

  getHours(timeStart) {
    const hours = [];

    const time = moment(timeStart, 'HH:mm');

    for (let i = 0; i < 24; i += 1) {
      hours.push(time.format('HH:mm'));
      time.add(1, 'hours');
    }

    return hours;
  },

  getIntervalEndTime(time, interval, roundStep = 0) {
    const momentTime = this.convertTimeToMoment(time);
    const endTime = momentTime.add(interval, 'minutes').toDate();

    if (roundStep) {
      return this.roundTo(endTime, roundStep);
    }

    return endTime;
  },

  roundTo(time, interval) {
    const momentTime = this.convertTimeToMoment(time);
    const roundedMinutes = Math.round(momentTime.minute() / interval) * interval;
    return momentTime.clone().minute(roundedMinutes).second(0).toDate();
  },

  isTimeAfter(time1, time2) {
    return moment(time1).isAfter(moment(time2));
  },

  isTimeBefore(time1, time2) {
    return moment(time1).isBefore(moment(time2));
  },

  isDateBefore(date1, date2) {
    return moment(date1).isBefore(moment(date2), 'days');
  },

  isDateAfter(date1, date2) {
    return moment(date1).isAfter(moment(date2), 'days');
  },

  isDateSameOrBefore(date1, date2) {
    return moment(date1).isSameOrBefore(moment(date2), 'days');
  },

  isDateSameOrAfter(date1, date2) {
    return moment(date1).isSameOrAfter(moment(date2), 'days');
  },

  convertTimeToDbFormat(time) {
    return moment(time).format('HH:mm');
  },

  isPeriodsIntersects(time1, interval1, time2, interval2) {
    const period1Start = this.convertTimeToMoment(time1);
    const period1End = moment(this.getIntervalEndTime(time1, interval1));

    const period2Start = this.convertTimeToMoment(time2);
    const period2End = moment(this.getIntervalEndTime(time2, interval2));

    return (
      period1Start.isSameOrAfter(period2Start)
      && period1Start.isSameOrBefore(period2End)
    ) || (
      period1End.isSameOrAfter(period2Start)
      && period1End.isSameOrBefore(period2End)
    ) || (
      period2Start.isSameOrAfter(period1Start)
      && period2Start.isSameOrBefore(period1End)
    ) || (
      period2End.isSameOrAfter(period1Start)
      && period2End.isSameOrBefore(period1End)
    );
  },

  getDiffInMinutes(time2, time1) {
    const momentTime1 = this.convertTimeToMoment(time1);
    const momentTime2 = this.convertTimeToMoment(time2);

    return momentTime2.diff(momentTime1, 'minutes');
  },

  getDiffInSeconds(time2, time1) {
    const momentTime1 = this.convertTimeToMoment(time1);
    const momentTime2 = this.convertTimeToMoment(time2);

    return momentTime2.diff(momentTime1, 'seconds');
  },

  addDays(date, days) {
    return moment(date).add(days, 'days').toDate();
  },

  subtractDays(date, days) {
    return moment(date).subtract(days, 'days').toDate();
  },

  addMonthes(date, month) {
    return moment(date).add(month, 'month').toDate();
  },

  subtractMonthes(date, month) {
    return moment(date).subtract(month, 'month').toDate();
  },

  addOneMonth(date) {
    return moment(date).add(1, 'month').subtract(1, 'day').toDate();
  },

  addWeeks(date, weeksCount = 1) {
    return moment(date).add(weeksCount * 7 - 1, 'day').toDate();
  },

  getDiffInDays(date2, date1, float = false) {
    return moment(date2).diff(moment(date1), 'days', float);
  },

  getCurrentTimeInTimezone(offset) {
    const d = new Date();
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    return new Date(utc + (3600000 * Number.parseInt(offset, 10)));
  },

  getShowTime(date, time) {
    const momentDate = moment(date);
    const momentTime = moment(time, 'HH:mm:ss');
    const momentBusinessDateBorder = moment(BUSINESS_DATE_BORDER, 'HH:mm');

    if (momentTime.isBefore(momentBusinessDateBorder)) {
      momentDate.add(1, 'days');
    }

    momentDate.set({
      hour: momentTime.get('hour'),
      minute: momentTime.get('minute'),
      second: momentTime.get('second'),
    });

    return momentDate.toDate();
  },

  getRepertoryWeeks(dateStart, dateEnd) {
    let start = dateStart;
    let end = dateStart;

    const weeks = [];

    while (this.isDateBefore(end, dateEnd)) {
      if (this.getDay(end) === 3) {
        weeks.push({
          dateStart: start,
          dateEnd: end,
        });

        start = this.addDays(end, 1);
      }

      end = this.addDays(end, 1);
    }

    if (this.isDateSameOrBefore(start, end)) {
      weeks.push({
        dateStart: start,
        dateEnd: end,
      });
    }

    return weeks;
  },

  subtractYears(date, years) {
    return moment(date).subtract(years, 'years').toDate();
  },

  getWeekDay(date, day) {
    return moment(date)
      .day(day)
      .toDate();
  },

  getStartOf(date, what) {
    return moment(date).startOf(what).toDate();
  },

  getEndOf(date, what) {
    return moment(date).endOf(what).toDate();
  },

  getRentalWeek(date, firstDate, lastDate) {
    if (!this.isDateBetween(date, firstDate, lastDate)) {
      return null;
    }

    let week = 1;

    for (const d of this.getDates(firstDate, date)) {
      if (this.getDay(d) === 4) {
        week += 1;
      }
    }

    // если первый день - среда, то был предпросмотр и считаем, что это начало первой недели проката
    if (this.getDay(firstDate) === 3) {
      week -= 1;
    }

    return week;
  },

  convertTime(minutes) {
    const hours = Math.floor(minutes / 60);

    return `${String(hours).padStart(2, '0')}:${String(minutes - hours * 60).padStart(2, '0')}`;
  },

  convertDuration(seconds) {
    return moment.utc(moment.duration(seconds, 'seconds').asMilliseconds()).format('HH:mm:ss');
  },
};
