import FileSaver from 'file-saver';
import _factory from './_factory';
import collectionServiceFactory from '../../services/collectionServiceFactory';
import datetime from '../../utils/datetime';
import advertisementsService from '../../services/Advertisements';

const advertisementPositionsService = collectionServiceFactory('advertisementPositions');

export default {
  ..._factory('advertisements', {
    state: {
      page: null,
      limit: null,
    },

    actions: {
      async changePositions({ commit, dispatch }, { values }) {
        commit('setLoading', true);

        const [err, item] = await advertisementPositionsService.createItem(values);

        if (!err) {
          await dispatch('fetchItems');
        }

        commit('setLoading', false);

        return [err, item];
      },

      async exportAdvertisements({ state }, {
        cinemaId,
        advertisementTypeId,
        dateStart,
        dateEnd,
        advertisementIds,
        withTickets,
        oneFile,
        filename,
        format,
        sameAdvertisementForAllCinemas,
      }) {
        const [err, data] = await advertisementsService.exportAdvertisements({
          cinemaId,
          advertisementTypeId,
          dateStart: dateStart ? datetime.convertDateToDbFormat(dateStart) : '',
          dateEnd: dateEnd ? datetime.convertDateToDbFormat(dateEnd) : '',
          advertisementIds,
          withTickets,
          oneFile,
          sameAdvertisementForAllCinemas,
        });

        if (err) {
          return [err, null];
        }

        if (!err && data) {
          if (format === 'xlsx' || format === 'zip') {
            FileSaver.saveAs(data, filename);
          }
        }

        return [null, true];
      },
    },

    getters: {
      getAdvertisements: (state) => (cinemaId, hallId, date, day, time, releaseId, formatId, ageLimit) => state.items
        .filter((adv) => {
          if (adv.cinemaId !== cinemaId) {
            return false;
          }

          const filters = {
            halls: hallId,
            days: day,
            releases: releaseId,
            formats: formatId,
            ageLimits: ageLimit,
          };

          // eslint-disable-next-line no-restricted-syntax
          for (const [collection, value] of Object.entries(filters)) {
            if (adv[collection] && adv[collection].length && !adv[collection].find((item) => item.id === value)) {
              return false;
            }
          }

          if (adv.dateStart && datetime.isDateBefore(date, adv.dateStart)) {
            return false;
          }

          if (adv.dateEnd && datetime.isDateAfter(date, adv.dateEnd)) {
            return false;
          }

          if (adv.timeStart && datetime.compareTimes(time, adv.timeStart) < 0) {
            return false;
          }

          if (adv.timeEnd && datetime.compareTimes(time, adv.timeEnd) > 0) {
            return false;
          }

          return true;
        }),

      getAdvertisementsDuration: (state, getters) => (cinemaId, hallId, date, day, time, releaseId, formatId, ageLimit) => {
        const total = getters
          .getAdvertisements(cinemaId, hallId, date, day, time, releaseId, formatId, ageLimit)
          .reduce((_total, adv) => _total + adv.duration, 0);

        return total / 60;
      },
    },

  }, advertisementsService),
};
