<template>
  <b-modal
    ref="modal"
    centered
    size="lg"
    ok-variant="success"
    ok-title="Сохранить"
    cancel-title="Закрыть"
    title-class="mw-100 text-truncate text-nowrap"
    header-class="mw-100 text-truncate text-nowrap"
    :visible="true"
    :title="title"
    :busy="loading"
    @ok="ok"
    @hidden="$emit('hide')"
  >
    <b-container fluid class="p-0">
      <b-form-group
        :invalid-feedback="errors.depth"
        :state="states.depth"
      >
        <b-form-group
          label="Название:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            autofocus
            type="text"
            size="sm"
            v-model="item.name"
            :state="states.name"
            :disabled="loading"
          ></b-form-input>
        </b-form-group>
      </b-form-group>
    </b-container>
  </b-modal>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export default {
  props: {
    id: null,
    name: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      errors: {},
      states: {},
      item: {
        name: this.name,
      },
    };
  },
  computed: {
    title() {
      return this.id ? 'Редактирование' : 'Создание';
    },
  },
  methods: {
    setError(field, value) {
      this.$set(this.errors, field, value);
    },

    resetErrors() {
      this.errors = {};
    },

    setState(field, value) {
      this.$set(this.states, field, value);
    },

    resetStates() {
      this.states = {};
    },

    async ok(event) {
      event.preventDefault();

      this.loading = true;

      let err;
      this.resetErrors();
      this.resetStates();

      if (!this.id) {
        [err] = await this.$store.dispatch('media/library/createDirectory', this.item);
      } else {
        [err] = await this.$store.dispatch('media/library/updateFileOrDirectory', { id: this.id, name: this.item.name });
      }

      if (err && err.status === 422 && err?.data?.errors) {
        const errors = err?.data?.errors;

        for (const field in errors) {
          this.setError(field, errors[field].join(', '));
          this.setState(field, false);
        }
      }

      if (!err) {
        this.$emit('saved');
        this.$refs.modal.hide();
      }

      this.loading = false;
    },
  },
};
</script>
