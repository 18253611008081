export default () => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Создать копию',
        action: 'copyAgreement',
        permission: 'distributor_agreements.edit',
      },
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditAgreementForm',
        permission: 'distributor_agreements.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeAgreement',
        permission: 'distributor_agreements.edit',
      },
    ],
  },
];
