<template>
  <td :colspan="rowLength">
    <div
      class="cursor-pointer d-flex align-items-center"
      @click="$emit('click-directory')"
    >
      <div class="table-library__image-wrap mr-2">
        <img
          class="rounded"
          alt="Назад"
          :src="require(`@/assets/media-panels/library/dots.png`)"
        />
      </div>
      <div>
        Назад
      </div>
    </div>
  </td>
</template>
<script>
export default {
  props: {
    rowLength: {
      default: 1,
    },
  },
};
</script>
