import Full from '../layout/Full.vue';
import Targets from '../pages/targets/Index.vue';
import Marketing from '../pages/analytics/marketing/Index.vue';
import Distribution from '../pages/analytics/distribution/Index.vue';
import Market from '../pages/analytics/market/Index.vue';
import Customers from '../pages/analytics/customers/Index.vue';
import Rfm from '../pages/analytics/rfm/Index.vue';
import RfmDynamic from '../pages/analytics/rfm-dynamic/Index.vue';

export default {
  path: '/analytics',
  component: Full,
  children: [
    {
      name: 'targets',
      path: '/targets',
      component: Targets,
      meta: {
        auth: true,
        title: 'Плановые показатели',
        breadcrumbs: [
          { name: 'Аналитика' },
          { name: 'Плановые показатели' },
        ],
        permissions: ['targets.view'],
      },
    },
    {
      name: 'analyticsMarketing',
      path: '/analytics/marketing',
      component: Marketing,
      meta: {
        auth: true,
        title: 'Аналитика маркетинга',
        breadcrumbs: [
          { name: 'Аналитика' },
          { name: 'Маркетинг' },
        ],
        permissions: ['analytics.marketing.view'],
      },
    },
    {
      name: 'analyticsDistribution',
      path: '/analytics/distribution',
      component: Distribution,
      meta: {
        auth: true,
        title: 'Аналитика проката',
        breadcrumbs: [
          { name: 'Аналитика' },
          { name: 'Прокат' },
        ],
        permissions: ['analytics.distribution.view'],
      },
    },
    {
      name: 'analyticsMarket',
      path: '/analytics/market',
      component: Market,
      meta: {
        auth: true,
        title: 'Аналитика рынка',
        breadcrumbs: [
          { name: 'Аналитика' },
          { name: 'Рынок' },
        ],
        permissions: ['analytics.market.view'],
      },
    },
    {
      name: 'analyticsCustomers',
      path: '/analytics/customers',
      component: Customers,
      meta: {
        auth: true,
        title: 'Регистрация клиентов',
        breadcrumbs: [
          { name: 'Аналитика' },
          { name: 'Регистрация клиентов' },
        ],
        scrollable: true,
        permissions: ['analytics.customers.view'],
      },
    },
    {
      name: 'analyticsRfm',
      path: '/analytics/rfm',
      component: Rfm,
      meta: {
        auth: true,
        title: 'RFM анализ',
        breadcrumbs: [
          { name: 'Аналитика' },
          { name: 'RFM анализ' },
        ],
        permissions: ['analytics.rfm.view'],
      },
    },
    {
      name: 'analyticsRfmDynamic',
      path: '/analytics/rfm-dynamic',
      component: RfmDynamic,
      meta: {
        auth: true,
        title: 'Динамика RFM',
        breadcrumbs: [
          { name: 'Аналитика' },
          { name: 'Динамика RFM' },
        ],
        permissions: ['analytics.rfm.view'],
      },
    },
  ],
};
