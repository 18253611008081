<template>
  <div>
    <div class="d-flex">
      <span
        v-if="calendarType === 'day'"
        class="mr-1 cursor-pointer"
        @click="backwardWeek()"
      >
        <icon icon="fa-angle-double-left"></icon>
      </span>
      <span
        class="mr-2 cursor-pointer"
        @click="selectPrevPeriod()"
      >
        <icon icon="fa-angle-left"></icon>
      </span>
      <div
        id="select_date"
        class="text-center select-date"
        @click="showDatepicker = !showDatepicker"
      >{{period}}</div>
      <span
        class="ml-2 cursor-pointer"
        @click="selectNextPeriod()"
      >
        <icon icon="fa-angle-right"></icon>
      </span>
      <span
        v-if="calendarType === 'day'"
        class="ml-1 cursor-pointer"
        @click="forwardWeek()"
      >
        <icon icon="fa-angle-double-right"></icon>
      </span>
    </div>
    <b-popover
      target="select_date"
      placement="bottom"
      :show.sync="showDatepicker"
    >
      <datepicker
        ref="datepicker"
        :value="date"
        :inline="true"
        :bootstrap-styling="true"
        :monday-first="true"
        :disabled-dates="disabledDates"
        @selected="selectDate"
        v-click-outside="hideDatepicker"
      ></datepicker>
    </b-popover>
  </div>
</template>

<script>
import datetime from '../utils/datetime';

import Datepicker from './datepicker/Datepicker.vue';

export default {
  components: {
    Datepicker,
  },
  props: {
    date: {
      default: new Date(),
    },
    calendarType: {
      default: 'week',
    },
  },
  data() {
    return {
      showDatepicker: false,
    };
  },
  computed: {
    dateAsText() {
      return this.formatDate(this.date, { day: 'numeric', month: 'long', year: 'numeric' });
    },

    dateStart() {
      return this.date;
    },

    dateStartAsText() {
      const format = { day: 'numeric', month: 'long' };

      if (this.dateStart.getYear() !== this.dateEnd.getYear()) {
        format.year = 'numeric';
      }

      return this.formatDate(this.dateStart, format);
    },

    dateEnd() {
      return datetime.addDays(this.date, 6);
    },

    dateEndAsText() {
      return this.formatDate(this.dateEnd, { day: 'numeric', month: 'long', year: 'numeric' });
    },

    period() {
      if (this.calendarType === 'day') {
        return this.dateAsText;
      }

      return `${this.dateStartAsText} - ${this.dateEndAsText}`;
    },

    weekStartDay() {
      return datetime.getDay(this.dateStart);
    },

    disabledDates() {
      if (this.calendarType === 'week') {
        const days = [];

        for (let day = 0; day < 7; day += 1) {
          if (day !== this.weekStartDay) {
            days.push(day);
          }
        }

        return {
          days,
        };
      }

      return {};
    },

    trackedProps() {
      return [this.date.toString(), this.calendarType].join(',');
    },
  },
  watch: {
    trackedProps() {
      this.showDatepicker = false;
    },
  },
  methods: {
    hideDatepicker() {
      this.showDatepicker = false;
    },

    formatDate(date, format) {
      const text = date.toLocaleString('ru', format);

      return text[0].toUpperCase() + text.slice(1);
    },

    selectDate(date) {
      this.$emit('select-date', date);
      this.showDatepicker = false;
    },

    selectPrevPeriod() {
      if (this.calendarType === 'day') {
        this.$emit('select-date', datetime.subtractDays(this.date, 1));
      }

      if (this.calendarType === 'week') {
        this.$emit('select-date', datetime.subtractDays(this.dateStart, 7));
      }
    },

    selectNextPeriod() {
      if (this.calendarType === 'day') {
        this.$emit('select-date', datetime.addDays(this.date, 1));
      }

      if (this.calendarType === 'week') {
        this.$emit('select-date', datetime.addDays(this.dateStart, 7));
      }
    },

    backwardWeek() {
      this.$emit('select-date', datetime.findPrevWeekStart(this.date));
    },

    forwardWeek() {
      this.$emit('select-date', datetime.findNextWeekStart(this.date));
    },
  },
};
</script>

<style scoped>
.select-date {
  color: blue !important;
  cursor: pointer;
}
.popover {
  max-width: 400px;
}
.popover  >>> .vdp-datepicker__calendar {
  border-width: 0 !important;
}
</style>
