<template>
  <div
    v-if="weeks.length"
    class="timeline d-flex flex-column w-100"
    :class="{'loading': loading}"
    ref="timeline"
    @touchmove.stop.prevent="resize($event)"
    @mousemove.stop.prevent="resize($event)"
    @touchend.stop.prevent="stopResizing()"
    @mouseup.stop.prevent="stopResizing()"
    @mouseleave.stop.prevent="stopResizing()"
  >
    <div
      v-for="row in rowsCount"
      class="d-flex timeline-line"
      :class="{'dragover': dragoverRow === row}"
      :key="row"
    >
      <timeline-week
        v-for="(week, column) in weeks"
        :week="week"
        :key="column"
        :allow-drop="!getWeekRowItem(week, row)"
        :show-add-button="getWeekRowItem(week, row) && isWeekAfterItem(week, getWeekRowItem(week, row))"
        :free-days="getWeekFreeDays(week, getWeekRowItem(week, row))"
        @drop="addRepertoryItem(week, $event)"
        @dragover="dragoverRow = row"
        @click="getWeekRowItem(week, row) && changeItemDateEnd(getWeekRowItem(week, row).id, getLastDateOfWeek(week))"
      ></timeline-week>

      <div
        v-for="item in getRowItems(row)"
        :key="`${row}_${item.id}`"
        class="timeline-item"
        :style="getItemStyles(item)"
        :ref="`item_${item.id}`"
        :data-id="item.id"
        @click="clickItem(item.id)"
      >
        <div class="timeline-item__info d-flex">
          <div class="timeline-item__release flex-grow-1">
            <slot name="release" :repertory-item="item"></slot>
          </div>
          <div>
            <slot name="agreement" :repertory-item="item"></slot>
          </div>
        </div>
        <div
          class="timeline-item__stretch-start"
          v-if="isSameOrBefore(timelineDateStart, item.dateStart)"
          @touchstart.stop.prevent="startResizing(item, 'left')"
          @mousedown.stop.prevent="startResizing(item, 'left')"
          @click.stop.prevent
        ></div>
        <div
          class="timeline-item__stretch-end"
          v-if="isSameOrAfter(timelineDateEnd, item.dateEnd)"
          @touchstart.stop.prevent="startResizing(item, 'right')"
          @mousedown.stop.prevent="startResizing(item, 'right')"
          @click.stop.prevent
        ></div>
        <div
          class="timeline-item__counter"
          v-for="week in item.weeks"
          :ref="`item_${item.id}_counter`"
          :key="week.dateStart"
          :style="getCounterStyles(item, week)"
          :data-date="week.dateStart"
        >
          <slot
            name="counter"
            :week="week"
            :repertory-item="item"
          ></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-continue */

import datetime from '../../utils/datetime';

import TimelineWeek from './TimelineWeek.vue';

export default {
  components: {
    TimelineWeek,
  },
  props: {
    items: {
      default: () => [],
    },
    statuses: {
      default: () => [],
    },
    weeks: {
      default: () => [],
    },
    loading: {
      default: false,
    },
    viewMode: {
      default: 'default',
    },
  },
  data() {
    return {
      dragoverRow: null,
      resizeInfo: {
        el: null,
        x: null,
        item: null,
        side: null,
      },
    };
  },
  computed: {
    timelineDaysCount() {
      return this.weeks.reduce((acc, cur) => acc + cur.length, 0);
    },

    timelineDateStart() {
      return this.weeks?.[0]?.[0].date;
    },

    timelineDateEnd() {
      const lastWeek = this.weeks?.[this.weeks.length - 1];
      return lastWeek && lastWeek?.[lastWeek.length - 1].date;
    },

    timelineDayWidth() {
      return this.$refs.timeline ? (this.$refs.timeline.offsetWidth / this.timelineDaysCount) : 0;
    },

    rowsCount() {
      const count = this.viewMode === 'default' ? this.items.length : this.packedItems.length;

      return count >= 24 ? count + 1 : 24;
    },

    sortedItems() {
      const items = [...this.items];

      return items.sort((a, b) => {
        if (datetime.isDateBefore(a.dateStart, b.dateStart)) {
          return -1;
        }

        if (datetime.isSameDay(a.dateStart, b.dateStart)) {
          if (datetime.isDateBefore(a.dateEnd, b.dateEnd)) {
            return -1;
          }

          return 1;
        }

        if (datetime.isDateAfter(a.dateStart, b.dateStart)) {
          return 1;
        }

        return 0;
      });
    },

    packedItems() {
      const packedItems = [];
      let remainItems = [...this.sortedItems];

      while (true) {
        if (!remainItems.length) {
          break;
        }

        const rowItems = [remainItems[0]];
        remainItems.splice(0, 1);

        const skipIndexes = [];

        for (let i = 0; i < remainItems.length; i += 1) {
          const item = remainItems[i];
          const prevItem = rowItems[rowItems.length - 1];

          if (datetime.isDateAfter(item.dateStart, prevItem.dateEnd)) {
            rowItems.push(item);
            skipIndexes.push(i);
          }
        }

        remainItems = remainItems.filter((value, index) => !skipIndexes.includes(index));
        packedItems.push(rowItems);
      }

      return packedItems;
    },
  },
  methods: {
    getRowItems(row) {
      if (this.viewMode === 'default') {
        return this.sortedItems[row - 1] ? [this.sortedItems[row - 1]] : [];
      }

      return this.packedItems[row - 1] ? this.packedItems[row - 1] : [];
    },

    getWeekRowItem(week, row) {
      const weekDateStart = week.length ? week[0].date : null;
      const weekDateEnd = week.length ? week[week.length - 1].date : null;

      if (!weekDateStart || !weekDateEnd) {
        return null;
      }

      for (const item of this.getRowItems(row)) {
        if (
          datetime.isDateSameOrAfter(item.dateEnd, weekDateStart)
          && datetime.isDateSameOrBefore(item.dateStart, weekDateEnd)
        ) {
          return item;
        }
      }

      return null;
    },

    isSameOrBefore(date1, date2) {
      return datetime.isDateSameOrBefore(date1, date2);
    },

    isSameOrAfter(date1, date2) {
      return datetime.isDateSameOrAfter(date1, date2);
    },

    isBefore(date1, date2) {
      return datetime.isDateBefore(date1, date2);
    },

    isAfter(date1, date2) {
      return datetime.isDateAfter(date1, date2);
    },

    getLastDateOfWeek(week) {
      return week?.[week.length - 1].date;
    },

    isWeekAfterItem(week, item) {
      if (!item) {
        return false;
      }

      const itemDateEnd = item.dateEnd;
      const weekDateEnd = this.getLastDateOfWeek(week);

      return datetime.isDateBefore(itemDateEnd, weekDateEnd) && datetime.getDiffInDays(weekDateEnd, itemDateEnd) <= 7;
    },

    getWeekFreeDays(week, item) {
      if (!item) {
        return 7;
      }

      const itemDateEnd = item.dateEnd;
      const weekDateEnd = this.getLastDateOfWeek(week);

      const diff = datetime.getDiffInDays(weekDateEnd, itemDateEnd);

      return (diff >= 0 && diff < 7) ? diff : 7;
    },

    addRepertoryItem(week, release) {
      const dateStart = week[0].date;
      const dateEnd = week[week.length - 1].date;
      this.$emit('add-item', { dateStart, dateEnd, release });
    },

    isItemInPeriod(item) {
      if (
        datetime.isDateSameOrAfter(item.dateEnd, this.timelineDateStart)
        && datetime.isDateSameOrBefore(item.dateEnd, this.timelineDateEnd)
      ) {
        return true;
      }

      if (
        datetime.isDateSameOrAfter(item.dateStart, this.timelineDateStart)
        && datetime.isDateSameOrBefore(item.dateStart, this.timelineDateEnd)
      ) {
        return true;
      }

      if (
        datetime.isDateSameOrAfter(this.timelineDateStart, item.dateStart)
        && datetime.isDateSameOrBefore(this.timelineDateEnd, item.dateEnd)
      ) {
        return true;
      }

      return false;
    },

    getItemStyles(item) {
      return {
        left: `${this.getItemPosition(item)}px`,
        width: `${this.getItemWidth(item)}px`,
        backgroundColor: this.getItemColor(item),
      };
    },

    getCounterStyles(item, week) {
      return {
        left: `${this.getCounterPosition(item, week)}px`,
        display: this.displayCounter(week) ? 'inline-block' : 'none',
      };
    },

    getItemPosition(item) {
      if (!this.$refs.timeline) {
        return 0;
      }

      let daysFromTimelineDateStart = Math.round(datetime.getDiffInDays(item.dateStart, this.timelineDateStart, true));

      if (daysFromTimelineDateStart < 0) {
        daysFromTimelineDateStart = 0;
      }

      return this.timelineDayWidth * daysFromTimelineDateStart;
    },

    getItemWidth(item) {
      if (!this.$refs.timeline) {
        return 0;
      }

      const daysFromTimelineDateStart = Math.round(datetime.getDiffInDays(item.dateStart, this.timelineDateStart, true));
      let duration = Math.round(datetime.getDiffInDays(item.dateEnd, item.dateStart, true)) + 1;

      if (daysFromTimelineDateStart < 0) {
        duration += daysFromTimelineDateStart;
      }

      if (duration < 0) {
        duration = 0;
      }

      if ((duration + daysFromTimelineDateStart) > this.timelineDaysCount) {
        duration = this.timelineDaysCount - daysFromTimelineDateStart;
      }

      return this.timelineDayWidth * duration;
    },

    getItemColor(item) {
      return this.statuses.find((status) => status.id === item.repertoryItemStatusId)?.color || '#FFF';
    },

    displayCounter(week) {
      if (
        datetime.isDateSameOrAfter(week.dateStart, this.timelineDateStart)
        && datetime.isDateSameOrBefore(week.dateStart, this.timelineDateEnd)
      ) {
        return true;
      }

      return false;
    },

    getCounterPosition(item, week) {
      if (!this.$refs.timeline) {
        return 0;
      }

      let itemDateStart = item.dateStart;
      const weekDateStart = week.dateStart;

      if (datetime.isDateBefore(itemDateStart, this.timelineDateStart)) {
        itemDateStart = this.timelineDateStart;
      }

      const days = Math.round(datetime.getDiffInDays(weekDateStart, itemDateStart, true));

      return this.timelineDayWidth * days + 3;
    },

    clickItem(id) {
      if (!this.$user.can('repertory.edit')) {
        return;
      }

      if (!this.resizeInfo.el) {
        this.$emit('click-item', id);
      }
    },

    saveResizeInfo(el, item, side) {
      this.resizeInfo.el = el;
      this.resizeInfo.item = item;
      this.resizeInfo.x = null;
      this.resizeInfo.side = side;
    },

    resetResizeInfo() {
      this.resizeInfo.el = null;
      this.resizeInfo.item = null;
      this.resizeInfo.x = null;
      this.resizeInfo.side = null;
    },

    changeItemDateStart(id, date) {
      this.$emit('change-date-start', {
        id,
        date,
      });
    },

    changeItemDateEnd(id, date) {
      this.$emit('change-date-end', {
        id,
        date,
      });
    },

    startResizing(item, side) {
      if (this.loading) {
        return;
      }

      const { id } = item;
      const el = this.$refs[`item_${id}`][0];

      el.style.zIndex = 1000;

      this.saveResizeInfo(el, item, side);
    },

    stopResizing() {
      if (!this.resizeInfo.item) {
        return;
      }

      if (!this.resizeInfo.el) {
        return;
      }

      const { el } = this.resizeInfo;

      if (el) {
        el.style.zIndex = 1;
      }

      const daysToStart = Math.round(el.offsetLeft / this.timelineDayWidth);

      if (this.resizeInfo.side === 'left') {
        const left = daysToStart * this.timelineDayWidth;
        const width = el.offsetWidth + el.offsetLeft - left;

        el.style.left = `${left}px`;
        el.style.width = `${width}px`;

        const newDateStart = datetime.addDays(this.timelineDateStart, daysToStart);

        this.redrawWeeksCounters();

        this.changeItemDateStart(this.resizeInfo.item.id, newDateStart);
      }

      if (this.resizeInfo.side === 'right') {
        const daysToEnd = Math.round((el.offsetLeft + el.offsetWidth) / this.timelineDayWidth);
        const width = daysToEnd * this.timelineDayWidth - el.offsetLeft;
        const period = Math.round(width / this.timelineDayWidth);

        el.style.width = `${width}px`;

        const newDateEnd = datetime.addDays(this.timelineDateStart, daysToStart + period - 1);

        this.redrawWeeksCounters();

        this.changeItemDateEnd(this.resizeInfo.item.id, newDateEnd);
      }

      this.resetResizeInfo();
    },

    getResizeOffset(e) {
      const x = e.pageX || e.changedTouches[0].pageX;

      if (this.resizeInfo.x === null) {
        this.resizeInfo.x = x;
      }

      const offset = x - this.resizeInfo.x;

      this.resizeInfo.x = x;

      return offset;
    },

    resize(e) {
      e.preventDefault();

      if (this.loading) {
        return;
      }

      if (!this.resizeInfo.el) {
        return;
      }

      if (!e.pageX) {
        document.body.style.overflow = 'hidden';
      }

      const { el } = this.resizeInfo;

      const offset = this.getResizeOffset(e);

      if (this.resizeInfo.side === 'left') {
        const left = el.offsetLeft + offset;
        const width = el.offsetWidth - offset;

        if (left >= 0) {
          el.style.left = `${left}px`;
          el.style.width = `${width}px`;
        }
      }

      if (this.resizeInfo.side === 'right') {
        const width = el.offsetWidth + offset;

        if (el.offsetLeft + width < this.$refs.timeline.offsetWidth) {
          el.style.width = `${width}px`;
        }
      }

      this.redrawWeeksCounters();
    },

    redrawWeeksCounters() {
      if (!this.resizeInfo.item) {
        return;
      }

      if (!this.resizeInfo.el) {
        return;
      }

      const { id } = this.resizeInfo.item;
      const { el } = this.resizeInfo;

      const counters = this.$refs[`item_${id}_counter`];

      if (!Array.isArray(counters)) {
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const counter of counters) {
        const week = this.weeks.find((days) => days.find((day) => datetime.isSameDay(day, counter.dataset.date)));

        if (!week) {
          // eslint-disable-next-line no-continue
          continue;
        }

        let weekStartPosition = Math.round(datetime.getDiffInDays(week[0], this.timelineDateStart, true)) * this.timelineDayWidth;
        let weekEndPosition = Math.round(datetime.getDiffInDays(week[week.length - 1], this.timelineDateStart, true)) * this.timelineDayWidth + (this.timelineDayWidth / 2);

        const elStartPosition = el.offsetLeft;
        const elEndPosition = el.offsetLeft + el.offsetWidth;

        if (elStartPosition >= weekStartPosition) {
          weekStartPosition = elStartPosition;
        }

        if (elEndPosition <= weekEndPosition) {
          weekEndPosition = elEndPosition;
        }

        if (weekEndPosition > weekStartPosition) {
          counter.style.display = 'inline-block';
          counter.style.left = `${weekStartPosition - elStartPosition + 3}px`;
        } else {
          counter.style.display = 'none';
        }
      }
    },
  },

};
</script>

<style scoped>
.timeline {
  margin: -6px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.timeline.loading {
  opacity: 0.5;
}
.timeline-line {
  position: relative;
}
.timeline-line:hover, .timeline-line.dragover {
  background-color: #efefef !important;
}
.timeline-item {
  position: absolute;
  background-color: #fff;
  top: 0;
  bottom: 0;
  font-size: 12px;
  line-height: 13px;
  padding: 3px;
  border: 1px solid #000;
  border-top: none;
  cursor: pointer;
  color: #fff;
}
.timeline-item__stretch-start, .timeline-item__stretch-end {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  z-index: 1;
}
.timeline-item__stretch-start {
  cursor: w-resize;
}
.timeline-item__stretch-end {
  cursor: e-resize;
}
.timeline-item__stretch-start {
  left: -5px;
}
.timeline-item__stretch-end {
  right: -5px;
}
.timeline-item__info {
  z-index: 2;
}
.timeline-item__release {
  width: 0;
  overflow: hidden;
}
.timeline-item__name {
  font-weight: 500;
}
.timeline-item__counter {
  position: absolute;
  bottom: 0;
}
</style>
