<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Наименование:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            autofocus
            type="text"
            size="sm"
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          label="Код:"
          :invalid-feedback="errors.code"
          :state="states.code"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.code"
            :state="states.code"
            :disabled="loading"
            @change="updateValue('code', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Адрес:"
          :invalid-feedback="errors.address"
          :state="states.address"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.address"
            :state="states.address"
            :disabled="loading"
            @change="updateValue('address', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Телефон:"
          :invalid-feedback="errors.phone"
          :state="states.phone"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.phone"
            :state="states.phone"
            :disabled="loading"
            @change="updateValue('phone', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Почта:"
          :invalid-feedback="errors.email"
          :state="states.email"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.email"
            :state="states.email"
            :disabled="loading"
            @change="updateValue('email', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Сайт:"
          :invalid-feedback="errors.website"
          :state="states.website"
        >
          <b-form-input
            type="text"
            size="sm"
            :placeholder="placeholder.URL"
            :value="item.website"
            :state="states.website"
            :disabled="loading"
            @change="updateValue('website', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      :disabled="loading"
      :invalid-feedback="errors.includeEkb"
      :state="states.includeEkb"
    >
      <b-form-checkbox
        :checked="!!item.includeEkb"
        :state="states.includeEkb"
        :disabled="loading"
        @change="updateValue('includeEkb', $event)"
      >
        Выгружать данные о продажах в ЕАИС
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      :disabled="loading"
      :invalid-feedback="errors.includeRentrak"
      :state="states.includeRentrak"
    >
      <b-form-checkbox
        :checked="!!item.includeRentrak"
        :state="states.includeRentrak"
        :disabled="loading"
        @change="updateValue('includeRentrak', $event)"
      >
        Выгружать данные о продажах в Рентрак
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      :disabled="loading"
      :invalid-feedback="errors.includeRao"
      :state="states.includeRao"
    >
      <b-form-checkbox
        :checked="!!item.includeRao"
        :state="states.includeRao"
        :disabled="loading"
        @change="updateValue('includeRao', $event)"
      >
        Включать данные о продажах в отчеты РАО
      </b-form-checkbox>
    </b-form-group>

    <contacts
      class="mt-3"
      :contacts="item.contacts"
      :disabled="loading"
      @change="updateValue('contacts', $event)"
    ></contacts>
  </b-container>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import Contacts from '../../components/special/Contacts.vue';
import { placeholder } from '../../utils/placeholder';
import { MaskHelper } from '../../utils/mask';

export default {
  components: {
    Contacts,
    MaskedInput,
  },
  data() {
    return {
      placeholder,
      mask: MaskHelper,
    };
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    async handleFile(files) {
      const image = await this.$fileManager.getBase64(files[0], { maxWidth: 200 });
      this.updateValue('image', image);
    },
  },
};
</script>
