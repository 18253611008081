<template>
  <collection-provider
    ref="collection"
    collection="playlists"
    :filter="filter"
    :disableFetching="settingsId === null"
  >
    <template #default>
      <div class="container p-0 mediastream">
        <div class="h6 mb-0 pb-2 border-bottom">Содержимое медиапотока</div>
        <div class="pb-2 mw-100 mediastream__content">
          <scroll>
            <template v-if="isDropPlaylists">
              <Container
                v-if="localFiles.length > 0"
                :tag="{ value: 'div' }"
                @drop="onDropItem">
                <Draggable
                  v-for="item in localFiles"
                  :key="item.positionIndex"
                  :tag="{ value: 'div', props: { class: 'mr-2 mediastream__item'} }"
                >
                  <setting-mediastream-item
                    :drop="isDropPlaylists"
                    :item="item"
                    @change-duration="changeValue({ index: item.positionIndex, field: 'duration', value: $event })"
                    @delete="deleteFile(item.positionIndex)"
                  ></setting-mediastream-item>
                </Draggable>
              </Container>
              <div v-else class="mt-2 w-100 text-center">
                Нет файлов
              </div>
            </template>
            <template v-else>
              <div v-if="localFiles.length > 0">
                <div
                  v-for="item in localFiles"
                  :key="item.positionIndex"
                  class="mr-2 mediastream__item"
                >
                  <setting-mediastream-item
                    :drop="isDropPlaylists"
                    :item="item"
                    @change-duration="changeValue({ index: item.positionIndex, field: 'duration', value: $event })"
                    @delete="deleteFile(item.positionIndex)"
                  ></setting-mediastream-item>
                </div>
              </div>
              <div v-else class="mt-2 w-100 text-center">
                Нет файлов
              </div>
            </template>
          </scroll>
        </div>
        <div v-if="!isDropPlaylists" class="text-danger">* Сортировка недоступна</div>
      </div>
    </template>
  </collection-provider>
</template>
<script>
import { mapState } from 'vuex';
import moment from 'moment';
import { Container, Draggable } from 'vue-smooth-dnd';

import CollectionProvider from '../../providers/CollectionProvider';

import SettingMediastreamItem from './SettingMediastreamItem.vue';

export default {
  props: {
    libraryFiles: {
      type: Array,
      default: () => [],
    },
    isDropPlaylists: {
      type: Boolean,
      default: false,
    },
    settingsId: null,
    cinemaId: null,
  },
  data: () => ({
    DEFAULT_DURATION: 10,
    setting: {
      playlist: [],
    },
  }),
  computed: {
    ...mapState('data/playlists', {
      playlist: (state) => state.items,
    }),

    filter() {
      return {
        settingsId: this.settingsId,
        cinemaId: this.cinemaId,
      };
    },

    localFiles: {
      get() {
        return this.setting.playlist;
      },

      set(values) {
        this.setting.playlist = values.map((item, index) => ({
          ...item,
          positionIndex: index,
          duration: (item.duration && moment.duration(item.duration, 'seconds').asSeconds()) || this.DEFAULT_DURATION,
          ...(!item.libraryFileId && { libraryFileId: item.id }),
        }));

        this.$emit('change', this.setting);
      },
    },
  },
  watch: {
    libraryFiles(values) {
      this.localFiles = [...this.localFiles, ...values];
    },

    playlist: {
      handler(values) {
        this.localFiles = [...values];
      },
      immediate: true,
    },
  },
  components: {
    SettingMediastreamItem,
    CollectionProvider,
    Container,
    Draggable,
  },
  methods: {
    async onDropItem({ removedIndex, addedIndex }) {
      if (removedIndex === addedIndex) {
        return;
      }

      const files = [...this.localFiles];

      const item = files.splice(removedIndex, 1);
      files.splice(addedIndex, 0, item[0]);

      this.localFiles = files;
    },

    changeValue({ index, field, value }) {
      this.$set(this.localFiles, index, {
        ...this.localFiles[index],
        [field]: value,
      });
    },

    deleteFile(index) {
      const files = [...this.localFiles];

      files.splice(index, 1);

      this.localFiles = files;
    },
  },
};
</script>

<style lang="scss">
img {
  max-width: 100%;
  max-height: 100%;
}

.mediastream {
  &__item {
    height: 90px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #DEE2E6;
    }
  }

  &__content {
    height: 270px;
  }

  &__item {
    &:not(:last-of-type) {
      border-bottom: 1px solid #DEE2E6;
    }
  }

  &__wrap {
    height: 90px;
  }

  &__img-wrap {
    width: 75px;
    height: 60px;
  }

  &__name {
    max-width: 285px;
    flex: 0 0 285px;
  }

  &__duration {
    max-width: 145px;
    flex: 0 0 145px;
  }
}
</style>
