import _factory from './_factory';
import datetime2 from '../../utils/datetime2';

export default {
  ..._factory('marketIndicators', {
    getters: {
      indicators: (state) => {
        const result = {};

        for (const item of state.items) {
          result[item.date] = item;
        }

        return result;
      },

      groupByPeriods: (state, getters) => (periods) => {
        const result = [];

        periods.forEach((period) => {
          const dates = datetime2.getDates(period.dateStart, period.dateEnd);

          let ticketsPrice = 0;
          let ticketsCount = 0;

          for (const date of dates) {
            const indicator = getters.indicators[datetime2.convertToDbFormat(date)];

            if (indicator) {
              ticketsPrice += indicator.ticketsPrice;
              ticketsCount += indicator.ticketsCount;
            }
          }

          result.push({
            ...period,
            ticketsPrice,
            ticketsCount,
          });
        });

        return result;
      },
    },
  }),
};
