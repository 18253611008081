var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dictionary-map',{attrs:{"keys":_vm.colorSchemes,"items":_vm.createMapItems(_vm.colorSchemeFormulas),"disabled":_vm.disabled,"texts":{
      add: 'Добавить ценовой пояс',
      empty: 'Ценовые пояса не указаны',
      key: 'Ценовой пояс',
      value: 'Изменение цены',
    }},on:{"change":_vm.onChangeMap},scopedSlots:_vm._u([{key:"value",fn:function(ref){
    var disabled = ref.disabled;
    var item = ref.item;
    var setValue = ref.setValue;
return [_c('price-formula',{attrs:{"value":item.value,"operations":_vm.operations,"disabled":disabled},on:{"change":function($event){return setValue($event)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }