<template>
  <b-row>
    <b-col cols="12">
      <b-form-group
        class="mb-0"
        label="Состав карточки времени:"
      >
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <b-form-checkbox
                v-model="settingLocal.custom.showScheduleFormat"
                :value="1"
                :unchecked-value="0"
                :disabled="disabledFormatFilm"
              >
                Формат фильма
              </b-form-checkbox>
              <b-form-checkbox
                v-model="settingLocal.custom.showSchedulePrice"
                :value="1"
                :unchecked-value="0"
                :disabled="disabledPrice"
              >
                Цена
              </b-form-checkbox>
              <b-form-checkbox
                v-model="settingLocal.custom.showScheduleHall"
                :value="1"
                :unchecked-value="0"
                :disabled="disabledFormatHall"
              >
                Категория зала
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="false">
            <b-form-group label="Размер:">
              <b-form-radio
                v-model="settingLocal.custom.bigSessionCards"
                :value="0"
              >
                Обычный
              </b-form-radio>
              <b-form-radio
                v-model="settingLocal.custom.bigSessionCards"
                :value="1"
              >
                Увеличенный
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Количество карточек фильма на одном экране:">
        <b-form-radio-group
          v-model="settingLocal.custom.maxCardsPerScreen"
          :options="optionsMaxCardsScreen"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group label="Ротация карточек фильма (сек.):">
        <b-form-radio-group
          v-model="settingLocal.custom.duration"
          :options="durations"
        ></b-form-radio-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    setting: {
      default: () => ({}),
    },
    durations: {
      default: () => [],
    },
  },
  data: () => ({
    settingLocal: {
      custom: {
        disabledFormatHall: false,
        disabledFormatFilm: false,
        disabledPrice: false,
        bigSessionCards: 0,
        showScheduleHall: 1,
        showScheduleFormat: 1,
        showSchedulePrice: 1,
        duration: 5,
        maxCardsPerScreen: 5,
      },
    },
  }),
  computed: {
    maxCardsPerScreen() {
      return this.settingLocal.custom.maxCardsPerScreen;
    },
    optionsMaxCardsScreen() {
      return [5, 10, 20].map((item) => ({
        value: item,
        text: `до ${item}`,
      }));
    },
  },
  watch: {
    maxCardsPerScreen: {
      handler(value) {
        if (value === 20) {
          this.disabledFormatHall = true;
          this.disabledFormatFilm = true;
          this.disabledPrice = true;

          this.settingLocal.custom.bigSessionCards = 0;
        } else {
          this.disabledFormatHall = false;
          this.disabledFormatFilm = false;
          this.disabledPrice = false;
        }
        this.settingLocal.custom.showScheduleHall = 1;
        this.settingLocal.custom.showScheduleFormat = 1;
        this.settingLocal.custom.showSchedulePrice = 1;
      },
      immediate: true,
    },

    settingLocal: {
      handler(setting) {
        this.$emit('change', setting);
      },
      immediate: true,
      deep: true,
    },

    setting: {
      handler(setting) {
        if (!setting) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(this.settingLocal.custom)) {
          if (typeof setting.custom[key] !== 'undefined') {
            if (key === 'maxCardsPerScreen') {
              if (setting.custom[key] === 20) {
                this.disabledFormatHall = true;
                this.disabledFormatFilm = true;
                this.disabledPrice = true;
              } else {
                this.disabledFormatHall = false;
                this.disabledFormatFilm = false;
                this.disabledPrice = false;
              }
            }

            this.settingLocal.custom[key] = setting.custom[key];
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
