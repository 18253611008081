<template>
  <div class="files">
    <b-table
      class="datatable"
      empty-text="Нет файлов"
      :fields="columns"
      :items="files"
      :striped="true"
      :hover="true"
      :no-local-sorting="false"
      :show-empty="true"
    >
      <template #cell(name)="data">
        <a
          target="_blank"
          :href="data.item.url"
        >
          {{ data.item.name }}
        </a>
      </template>

      <template #cell(format)="data">
        {{ getFileFormat(data.item.url) }}
      </template>

      <template #cell(createdAt)="data">
        {{ $datetime.formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(size)="data">
        {{ formatSize(data.item.size) }}
      </template>
    </b-table>
  </div>
</template>

<script>

export default {
  props: {
    files: {
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          key: 'name',
          label: 'Файл',
        },
        {
          key: 'format',
          label: 'Формат',
        },
        {
          key: 'createdAt',
          label: 'Загружен',
        },
        {
          key: 'size',
          label: 'Размер',
        },
      ],
    };
  },
  methods: {
    getFileFormat(url) {
      const parts = url.split('.');

      return parts.length > 1 ? parts[parts.length - 1] : 'Неизвестный';
    },

    formatSize(size) {
      const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return `${(size / 1024 ** i).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    },
  },
};
</script>

<style scoped>
</style>
