var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('collection-provider',{ref:"collection",attrs:{"collection":"halls","filter":{ cinemaId: _vm.cinemaId },"limit":null,"page":null,"disable-fetching":!_vm.cinemaId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"item-actions":_vm.datatable.itemActions},on:{"item-action":function($event){return _vm.performItemAction($event)}},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var hall = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'hall', params: { cinema: _vm.cinemaId, id: hall.id } }}},[_vm._v(" "+_vm._s(hall.name)+" ")])]}},{key:"cell(category)",fn:function(ref){
var hall = ref.item;
return [_vm._v(" "+_vm._s(hall.hallCategory ? hall.hallCategory.name : '')+" ")]}},{key:"cell(rowsCount)",fn:function(ref){
var hall = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRowsCount(hall))+" ")]}},{key:"cell(placesCount)",fn:function(ref){
var hall = ref.item;
return [_vm._v(" "+_vm._s(hall.placesCount)+" ")]}},{key:"cell(interval)",fn:function(ref){
var hall = ref.item;
return [_vm._v(" "+_vm._s(hall.interval)+" мин. ")]}},{key:"cell(formats)",fn:function(ref){
var hall = ref.item;
return [_vm._v(" "+_vm._s(_vm.$array.convertToText(hall.formats))+" ")]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }