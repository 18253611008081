<template>
  <dictionary-map
    :keys="apps"
    :items="createMapItems(appPrices)"
    :disabled="disabled"
    :texts="{
      add: 'Добавить цену для группы приложений',
      empty: 'Цены для групп приложений не указаны',
      key: 'Группа приложений',
      value: 'Цена',
    }"
    @change="emitChange"
  >
    <template #value="{disabled, item, setValue}">
      <b-form-input
        size="sm"
        type="text"
        ref="el"
        :value="item.value"
        :disabled="disabled"
        @change="setValue($event)"
      />
    </template>
  </dictionary-map>
</template>

<script>
import DictionaryMap from '../../components/special/DictionaryMap.vue';

export default {
  components: {
    DictionaryMap,
  },
  props: {
    apps: {
      type: Array,
      required: true,
    },
    disabled: {
      default: false,
    },
    appPrices: {
      default: () => [],
    },
  },
  methods: {
    createMapItems(appPrices) {
      return appPrices
        ? appPrices.map((appPrice) => ({ keyId: appPrice.id, value: appPrice.pivot.price }))
        : [];
    },
    createAppPrices(mapItems) {
      return mapItems
        ? mapItems.map((mapItem) => ({ id: mapItem.keyId, pivot: { price: mapItem.value } }))
        : [];
    },
    emitChange(items) {
      this.$emit('change', this.createAppPrices(items));
    },
  },
};
</script>
