<template>
  <div class="p-3 border rounded">
    <div class="font-weight-bold mb-2">{{ title }}</div>
    <div
      class="d-flex"
      v-for="(label, index) in labels"
      :key="index"
    >
      <div class="w-50">{{ label }}</div>
      <div class="mr-2 w-25 text-right">{{ getValue(index) }}</div>
      <div class="w-25 text-right">{{ getPercent(index) }}%</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
    },
    labels: {
      default: () => [],
    },
    data: {
      default: () => [],
    },
  },
  methods: {
    getValue(index) {
      if (index >= this.data.length) {
        return 0;
      }

      return this.data[index].toLocaleString();
    },

    getPercent(index) {
      if (index >= this.data.length) {
        return 0;
      }

      const total = this.data.reduce((a, b) => a + b, 0);
      const ratio = total ? this.data[index] / total : 0;

      return (ratio * 100).toFixed(2);
    },
  },
};
</script>

<style scoped>
.font-weight-bold {
  font-weight: 500 !important;
}
</style>
