<template>
  <info-card class="w-80 ml-3">
    <info-card-row>
      <template #label>Номер:</template>
      <template #value>{{item.number}}</template>
    </info-card-row>

    <info-card-row>
      <template #label>Изменение цены:</template>
      <template #value>{{item.promocodeGroup.formula}}</template>
    </info-card-row>

    <info-card-row>
      <template #label>Количество активаций:</template>
      <template #value>
        {{ item.activationsCount }}
      </template>
    </info-card-row>

    <info-card-row>
      <template #label>Предел активаций:</template>
      <template #value>
        {{ item.activationsLimit }}
      </template>
    </info-card-row>

    <info-card-row>
      <template #label>Дата создания:</template>
      <template #value>
        {{ $datetime.formatDateTime(item.createdAt) }}
      </template>
    </info-card-row>

    <info-card-row v-if="item.promocodeGroup.datetimeStart">
      <template #label>Дата начала действия:</template>
      <template #value>{{ $datetime.formatDateTime(item.promocodeGroup.datetimeStart) }}</template>
    </info-card-row>

    <info-card-row>
      <template #label>Группа сертификатов:</template>
      <template #value>{{item.promocodeGroup.name}}</template>
    </info-card-row>

    <info-card-row v-if="item.promocodeGroup.datetimeEnd">
      <template #label>Дата окончания действия:</template>
      <template #value>{{ $datetime.formatDateTime(item.promocodeGroup.datetimeEnd) }}</template>
    </info-card-row>
  </info-card>
</template>

<script>
import InfoCard from '../../components/card/InfoCard.vue';
import InfoCardRow from '../../components/card/InfoCardRow.vue';

export default {
  components: {
    InfoCard,
    InfoCardRow,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {

  },
};
</script>
