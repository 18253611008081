<template>
  <tr>
    <td
      v-if="frequency === 'high' && monetary === 'high'"
      class="bg p-0"
      :class="{
        'bg-success': recency === 'high',
        'bg-warning': recency === 'normal',
        'bg-danger': recency === 'low',
      }"
      rowspan="9"
    ></td>

    <td
      v-if="monetary === 'high'"
      rowspan="3"
      class="align-middle border-bottom"
    >{{ title }}</td>

    <td
      class="p-0 segment-icons text-nowrap align-middle"
      :class="{'border-bottom': monetary === 'low'}"
    >
      <img :src="require(`@/assets/analytics/rfm_recency_${recency}.svg`)" class="rfm-icon">
      <img :src="require(`@/assets/analytics/rfm_frequency_${frequency}.svg`)" class="rfm-icon">
      <img :src="require(`@/assets/analytics/rfm_monetary_${monetary}.svg`)" class="rfm-icon">
    </td>

    <td
      class="p-1 text-right align-middle border-left border-right bg-col"
      :class="{'border-bottom': monetary === 'low'}"
    >{{ getMainSegmentCount(monetary).toLocaleString() }}</td>

    <td
      v-if="monetary === 'high'"
      class="p-1 align-middle border-bottom border-right bg-col"
      rowspan="3"
    >
      <div
        class="h-100 w-100 d-flex align-items-center justify-content-center"
        :style="`background: linear-gradient(to right, #b6e1fc ${mainSegmentCountPercent}%, transparent 0);`"
      >{{ mainSegmentTotalCount.toLocaleString() }}</div>
    </td>

    <td
      v-if="monetary === 'high'"
      class="p-1 align-middle border-bottom border-right bg-col"
      rowspan="3"
    >
      <div
        class="h-100 w-100 d-flex align-items-center justify-content-center"
      >
        <div
          class="p-1"
          :class="{
            'bg-success': mainSegmentTotalCount > offsetSegmentTotalCount,
            'bg-danger': mainSegmentTotalCount < offsetSegmentTotalCount,
          }"
        >{{ mainSegmentCountPercent }}%</div>
      </div>
    </td>

    <td
      v-if="monetary === 'high'"
      class="p-1 align-middle border-bottom border-right bg-col"
      rowspan="3"
    >
      <div
        class="h-100 w-100 d-flex align-items-center justify-content-center"
        :class="{
          'text-success': mainSegmentTotalCount > offsetSegmentTotalCount,
          'text-danger': mainSegmentTotalCount < offsetSegmentTotalCount,
        }"
      >{{ formatDiff(mainSegmentTotalCount, offsetSegmentTotalCount) }}</div>
    </td>

    <td
      class="p-1 text-right align-middle border-left border-right"
      :class="{'border-bottom': monetary === 'low'}"
    >{{ getMainSegmentPrice(monetary).toLocaleString() }}</td>

    <td
      v-if="monetary === 'high'"
      class="p-1 align-middle border-bottom border-right"
      rowspan="3"
    >
      <div
        class="h-100 w-100 d-flex align-items-center justify-content-center"
        :style="`background: linear-gradient(to right, #b6e1fc ${mainSegmentPricePercent}%, transparent 0);`"
      >{{ mainSegmentTotalPrice.toLocaleString() }}</div>
    </td>

    <td
      v-if="monetary === 'high'"
      class="p-1 align-middle border-bottom border-right"
      rowspan="3"
    >
      <div
        class="h-100 w-100 d-flex align-items-center justify-content-center"
      >
        <div
          class="p-1"
          :class="{
            'bg-success': mainSegmentTotalPrice > offsetSegmentTotalPrice,
            'bg-danger': mainSegmentTotalPrice < offsetSegmentTotalPrice,
          }"
        >{{ mainSegmentPricePercent }}%</div>
      </div>
    </td>

    <td
      v-if="monetary === 'high'"
      class="p-1 align-middle border-bottom border-right"
      rowspan="3"
    >
      <div
        class="h-100 w-100 d-flex align-items-center justify-content-center"
        :class="{
          'text-success': mainSegmentTotalPrice > offsetSegmentTotalPrice,
          'text-danger': mainSegmentTotalPrice < offsetSegmentTotalPrice,
        }"
      >{{ formatDiff(mainSegmentTotalPrice, offsetSegmentTotalPrice) }}</div>
    </td>

    <td
      v-if="monetary === 'high'"
      class="p-1 align-middle border-bottom bg-col"
      rowspan="3"
    >
      <div
        class="h-100 w-100 d-flex align-items-center justify-content-center"
      >{{ mainSegmentPricePerCustomer.toLocaleString() }}</div>
    </td>

    <td
      class="p-1 text-right align-middle border-right bg-col"
      :class="{'border-bottom': monetary === 'low'}"
    >{{ getMainSegmentPricePerCustomer(monetary).toLocaleString() }}</td>

    <td
      v-if="monetary === 'high'"
      class="p-1 align-middle border-bottom border-right bg-col"
      rowspan="3"
    >
      <div
        class="h-100 w-100 d-flex align-items-center justify-content-center"
        :class="{
          'text-success': mainSegmentPricePerCustomer > offsetSegmentPricePerCustomer,
          'text-danger': mainSegmentPricePerCustomer < offsetSegmentPricePerCustomer,
        }"
      >{{ formatDiff(mainSegmentPricePerCustomer, offsetSegmentPricePerCustomer) }}</div>
    </td>
  </tr>
</template>

<script>
const COLUMN_COUNT = 'count(customer_id)';
const COLUMN_PRICE = 'sum(orders_price)';

export default {
  props: {
    title: {
      default: '',
    },
    recency: {
      default: 'low',
    },
    frequency: {
      default: 'low',
    },
    monetary: {
      default: 'low',
    },
    mainItems: {
      default: () => [],
    },
    offsetItems: {
      default: () => [],
    },
  },
  computed: {
    mainTotalCount() {
      return this.mainItems.reduce((total, current) => total + +current[COLUMN_COUNT], 0);
    },

    offsetTotalCount() {
      return this.offsetItems.reduce((total, current) => total + +current[COLUMN_COUNT], 0);
    },

    mainTotalPrice() {
      return this.mainItems.reduce((total, current) => total + +current[COLUMN_PRICE], 0);
    },

    mainSegmentItems() {
      return this.mainItems.filter((item) => item.recency === this.recency && item.frequency === this.frequency);
    },

    offsetSegmentItems() {
      return this.offsetItems.filter((item) => item.recency === this.recency && item.frequency === this.frequency);
    },

    mainSegmentTotalCount() {
      return this.mainSegmentItems.reduce((total, current) => total + +current[COLUMN_COUNT], 0);
    },

    offsetSegmentTotalCount() {
      return this.offsetSegmentItems.reduce((total, current) => total + +current[COLUMN_COUNT], 0);
    },

    mainSegmentCountPercent() {
      return this.mainTotalCount ? Math.round(100 * (this.mainSegmentTotalCount / this.mainTotalCount)) : 0;
    },

    mainSegmentTotalTPrice() {
      return this.mainSegmentItems.reduce((total, current) => total + +current[COLUMN_PRICE], 0);
    },

    mainSegmentTotalPrice() {
      return this.mainSegmentItems.reduce((total, current) => total + +current[COLUMN_PRICE], 0);
    },

    offsetSegmentTotalPrice() {
      return this.offsetSegmentItems.reduce((total, current) => total + +current[COLUMN_PRICE], 0);
    },

    mainSegmentPricePercent() {
      return this.mainTotalPrice ? Math.round(100 * (this.mainSegmentTotalPrice / this.mainTotalPrice)) : 0;
    },

    mainSegmentPricePerCustomer() {
      return this.mainSegmentTotalCount ? this.roundValue(this.mainSegmentTotalPrice / this.mainSegmentTotalCount, 0) : 0;
    },

    offsetSegmentPricePerCustomer() {
      return this.offsetSegmentTotalCount ? this.roundValue(this.offsetSegmentTotalPrice / this.offsetSegmentTotalCount, 0) : 0;
    },
  },
  methods: {
    roundValue(value, precision = 2) {
      const multiplier = 10 ** (precision || 0);

      return Math.round(value * multiplier) / multiplier;
    },

    getMainSegmentCount(monetary) {
      return +this.mainSegmentItems.find((item) => item.monetary === monetary)?.[COLUMN_COUNT] || 0;
    },

    getMainSegmentPrice(monetary) {
      return +this.mainSegmentItems.find((item) => item.monetary === monetary)?.[COLUMN_PRICE] || 0;
    },

    getMainSegmentPricePerCustomer(monetary) {
      const count = this.getMainSegmentCount(monetary);

      return count ? this.roundValue(this.getMainSegmentPrice(monetary) / count, 0) : 0;
    },

    formatDiff(value1, value2, postfix = '') {
      const diff = Math.abs(value1 - value2);

      if (value1 > value2) {
        return `+${diff.toLocaleString()}${postfix}`;
      } if (value1 < value2) {
        return `-${diff.toLocaleString()}${postfix}`;
      }

      return '0';
    },
  },
};
</script>

<style scoped>
.bg {
  width: 5px !important;
}
.segment-icons {
  width: 35px;
}
.segment-icons img {
  width: 10px;
}
td {
  border: 0;
}
.bg-col {
  background-color:ghostwhite;
}
.rfm-icon {
  min-width: 30px;
  opacity: 0.8;
}
.bg-success {
  background-color: #b6d7a8 !important;
}
.bg-danger {
  background-color: #ea9999 !important;
}
</style>
