var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('collection-provider',{ref:"collection",attrs:{"collection":"qaItems","sort-by":"position","sort-desc":false,"page":null,"limit":1000},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable-drag',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"panel-actions":_vm.datatable.panelActions,"item-actions":_vm.datatable.itemActions,"checked":_vm.datatable.checked},on:{"change-checked":function($event){_vm.datatable.checked = $event},"panel-action":function($event){return _vm.performPanelAction($event)},"item-action":function($event){return _vm.performItemAction($event)},"change-positions":function($event){return _vm.changePositions($event)}},scopedSlots:_vm._u([{key:"filter-category",fn:function(){return [_c('dictionary-provider',{attrs:{"name":"qaCategories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('toggle-list',{attrs:{"placeholder":"Все категории","options":items,"searchable":true,"hideSelected":false,"disabled":data.loading,"value":_vm.convertToValue(_vm.filter.qaCategoryIds, items)},on:{"change":function($event){actions.changeFilter('qaCategoryIds', _vm.$array.getValues($event, 'id'))}}})]}}],null,true)})]},proxy:true},{key:"filter-apps",fn:function(){return [_c('dictionary-provider',{attrs:{"name":"apps"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('toggle-list',{attrs:{"placeholder":"Все источники","options":items,"searchable":true,"hideSelected":false,"disabled":data.loading,"value":_vm.convertToValue(_vm.filter.appIds, items)},on:{"change":function($event){actions.changeFilter('appIds', _vm.$array.getValues($event, 'id'))}}})]}}],null,true)})]},proxy:true},{key:"cell-apps",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm.$array.convertToText(data.item.apps, 'Все')))])]}},{key:"cell-category",fn:function(data){return [(data.item.category)?_c('div',[_vm._v(_vm._s(data.item.category.name))]):_c('div',[_vm._v("-")])]}}],null,true)},'datatable-drag',Object.assign({}, data, actions),false))]}}])}),(_vm.modals.qa.show)?_c('modal-model-form',{attrs:{"create-title":"Создание вопроса","edit-title":"Редактирование вопроса","collection":"qaItems","id":_vm.modals.qa.id},on:{"close":function($event){_vm.modals.qa.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('qa-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,false,3208513197)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }