var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"hover":"","no-local-sorting":"","table-class":['table-library', { 'table-library-tree' : _vm.isTreeMode }],"thead-class":['table-library__thead', { 'table-library__thead-hide' : _vm.isTreeMode }],"tbody-tr-class":_vm.rowClass,"items":_vm.prepareItems,"fields":_vm.datatable.fields,"filter-included-fields":['name'],"filter":_vm.filter,"sort-by":_vm.sort.by,"sort-desc":_vm.sort.desc},on:{"sort-changed":function($event){return _vm.changeSort({ by: $event.sortBy, desc: !!$event.sortDesc })}},scopedSlots:_vm._u([(_vm.showBackButton)?{key:"top-row",fn:function(){return [_c('files-row-top',{attrs:{"row-length":_vm.datatable.fields.length},on:{"click-directory":function($event){return _vm.fetchItemsByDirectortId(_vm.backBtnDirectory)}}})]},proxy:true}:null,{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(_vm.helperFile.isDirectory(item.type))?_c('files-item-directory',{attrs:{"item":item,"is-list-mode":_vm.isListMode,"is-tree-mode":_vm.isTreeMode},on:{"open-directory":_vm.openDirectory,"close-directory":_vm.closeDirectory}}):_vm._e(),(_vm.helperFile.isFile(item.type))?_c('files-item-file',{attrs:{"item":item,"clickable":item.status !== _vm.helperFileStatus.statusLoading},on:{"click-file":function($event){return _vm.$emit('click-file', $event)}}}):_vm._e()]}},{key:"cell(mediaInfo)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemMediaInfo(item))+" ")]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('actions',{attrs:{"actions":_vm.getItemActions(item)},on:{"click":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onActionItem.apply(void 0, [ item.id ].concat( argsArray ))}}})]}}],null,true)}),(_vm.modal.file.show)?_c('modal-file',{attrs:{"id":_vm.modal.file.id,"name":_vm.modal.file.name},on:{"hide":function($event){_vm.modal.file.show = false,
      _vm.modal.file.name = null,
      _vm.modal.file.id = null}}}):_vm._e(),(_vm.modal.directory.show)?_c('modal-directory',{attrs:{"id":_vm.modal.directory.id,"name":_vm.modal.directory.name},on:{"hide":function($event){_vm.modal.directory.show = false,
      _vm.modal.directory.name = null,
      _vm.modal.directory.id = null}}}):_vm._e(),(_vm.modal.warning.show)?_c('modal-content',{attrs:{"title":"Ошибка","size":"md"},on:{"hide":function($event){_vm.modal.warning.show = false}}},[_c('div',{staticClass:"text"},[_vm._v("Вы не можете удалить данный файл или папку, так как он используется в одном из медиапотоков.")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }