export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'phone',
    type: 'text',
    label: 'Телефон',
    sortable: true,
    filterable: true,
  },
  {
    key: 'email',
    type: 'text',
    label: 'E-mail',
    sortable: true,
    filterable: true,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Имя',
    filterable: true,
    sortable: true,
  },
  {
    key: 'surname',
    type: 'text',
    label: 'Фамилия',
    filterable: true,
    sortable: true,
  },
  {
    key: 'balance',
    type: 'text',
    label: 'Баланс',
    sortable: true,
    filterable: false,
  },
  {
    key: 'approvedAt',
    type: 'text',
    label: 'Дата подтверждения',
    sortable: false,
    filterable: false,
  },
  {
    key: 'blockedAt',
    type: 'text',
    label: 'Дата блокировки',
    sortable: false,
    filterable: false,
  },
];
