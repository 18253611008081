import AnalyticsService from '../services/Analytics';

export default {
  props: {
    table: {
      default: '',
    },
    select: {
      default: () => ['*'],
    },
    params: {
      default: () => ({}),
    },
    where: {
      default: () => ({}),
    },
    groupBy: {
      default: () => ({}),
    },
    sortBy: {
      default: '',
    },
    sortDesc: {
      default: false,
    },
    page: {
      default: 1,
    },
    limit: {
      default: 20,
    },
    disabled: {
      default: false,
    },
  },
  render() {
    return this.$scopedSlots.default({
      items: this.items,
      total: this.total,
      loading: this.loading,
    });
  },
  data() {
    return {
      items: [],
      total: 0,
      loading: false,
    };
  },
  computed: {
    watchedValues() {
      return [
        JSON.stringify(this.params),
        JSON.stringify(this.where),
        JSON.stringify(this.groupBy),
        this.sortBy,
        this.sortDesc,
        this.page,
        this.limit,
      ].join();
    },
  },
  watch: {
    watchedValues: {
      handler() {
        this.fetchAnalyticsData();
      },
    },
  },
  created() {
    this.fetchAnalyticsData();
  },
  methods: {
    async fetchAnalyticsData() {
      if (this.disabled) {
        return;
      }

      this.loading = true;
      this.items = [];
      this.total = 0;

      const queries = [];

      queries.push({
        id: 'total',
        table: this.table,
        params: this.params,
        select: ['count(*)'],
        where: this.where,
        group_by: this.groupBy,
      });

      queries.push({
        id: 'items',
        table: this.table,
        params: this.params,
        select: this.select,
        where: this.where,
        group_by: this.groupBy,
        order_by: this.sortBy ? {
          [this.sortBy]: this.sortDesc ? 'desc' : 'asc',
        } : {},
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
      });

      const [err, result] = await AnalyticsService.fetch(queries);

      if (!err && result) {
        this.items = result.items;
        this.total = result.total[0]['count(*)'];
      }

      this.loading = false;
    },
  },
};
