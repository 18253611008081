<template>
  <div class="table-container">
    <collection-provider
      collection="advPromotions"
      ref="collection"
      :page="1"
      :limit="20"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(cinemas)>
            <dictionary-provider name="cinemas">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="data.filter.cinemas"
                  :options="convertCinemas(items)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('cinemaId', $event)"
                >
                  <template #first>
                    <b-form-select-option :value="null">Все кинотеатры</b-form-select-option>
                  </template>
                </b-form-select>
              </template>
            </dictionary-provider>
          </template>

          <template #filter(appGroups)>
            <dictionary-provider name="appGroups">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="data.filter.appGroupId"
                  :options="$array.convertToOptions(items, null)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('appGroupId', $event)"
                >
                  <template #first>
                    <b-form-select-option :value="null">Все приложения</b-form-select-option>
                  </template>
                </b-form-select>
              </template>
            </dictionary-provider>
          </template>

          <template #filter(active)>
            <b-form-select
              size="sm"
              :value="data.filter.active"
              :options="advPromotionActiveStatuses"
              :disabled="data.loading"
              @change="actions.changeFilter('active', $event)"
            ></b-form-select>
          </template>

          <template #cell(name)="data">
            <div class="w-300">{{data.item.name}}</div>
          </template>

          <template #cell(period)="data">
            <div class="w-300">
              <template v-if="data.item.dateStart">
                c {{formatDate(data.item.dateStart)}}
              </template>
              <template v-if="data.item.dateEnd">
                по {{formatDate(data.item.dateEnd)}}
              </template>
            </div>
          </template>

          <template #cell(appGroups)="data">
            <div class="w-300">{{$array.convertToText(data.item.appGroups, 'Все приложения')}}</div>
          </template>

          <template #cell(cinemas)="data">
            <div class="info">
              <div :id="`cinemas_${data.item.id}`">{{ convertCinemasToText(data.item.cinemas) }}</div>
              <b-tooltip
                v-if="data.item.cinemas && data.item.cinemas.length"
                :target="`cinemas_${data.item.id}`"
              >
                <div
                  class="mb-1"
                  v-for="cinema in data.item.cinemas"
                  :key="cinema.id"
                >
                  <div>{{ cinema.name }}, {{ cinema.city.name }}</div>
                </div>
              </b-tooltip>
            </div>
          </template>

          <template #cell(active)="data">
            {{getAdvPromotionActiveName(data.item.active)}}
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.advPromotions.show"
      create-title="Создание рекламной акции"
      edit-title="Редактирование рекламной акции"
      collection="advPromotions"
      :id="modals.advPromotions.id"
      @close="modals.advPromotions.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <adv-promotion-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></adv-promotion-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import DictionaryProvider from '../../providers/DictionaryProvider';
import CollectionProvider from '../../providers/CollectionProvider';

import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import Datatable from '../../components/datatable/Datatable.vue';

import AdvPromotionForm from './AdvPromotionForm.vue';

import datetime from '../../utils/datetime';

export default {
  components: {
    CollectionProvider,
    DictionaryProvider,
    Datatable,
    ModalModelForm,
    AdvPromotionForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        advPromotions: {
          show: false,
          id: 0,
        },
      },
      advPromotionActiveStatuses: [
        {
          value: null,
          text: '',
        },
        {
          value: false,
          text: 'Нет',
        },
        {
          value: true,
          text: 'Да',
        },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return datetime.formatDate(date);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateAdvPromotionForm() {
      this.modals.advPromotions.id = '';
      this.modals.advPromotions.show = true;
    },

    async removeCheckedAdvPromotions() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    openEditAdvPromotionForm(id) {
      this.modals.advPromotions.id = id;
      this.modals.advPromotions.show = true;
    },

    async removeAdvPromotion(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    pauseCheckedPromotions() {
      this.$refs.collection.changeItems(this.datatable.checked, { active: 0 });
      this.datatable.checked = [];
    },

    resumeCheckedPromotions() {
      this.$refs.collection.changeItems(this.datatable.checked, { active: 1 });
      this.datatable.checked = [];
    },

    pausePromotion(id) {
      this.$refs.collection.changeItem(id, { active: 0 });
    },

    resumePromotion(id) {
      this.$refs.collection.changeItem(id, { active: 1 });
    },

    getAdvPromotionActiveName(value) {
      return this.advPromotionActiveStatuses.find((status) => status.value === value)?.text;
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        value: cinema.id,
        text: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    convertCinemasToText(cinemas) {
      if (!cinemas.length) {
        return 'Все кинотеатры';
      }

      return cinemas.map((cinema) => `${cinema?.name} ${cinema?.city?.name}`).join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.w-300 {
  max-width: 300px;
  width: 100%;
}
.info {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}
</style>
