<template>
  <b-modal
    centered
    ref="modal"
    size="lg"
    :title="title"
    :visible="true"
    :busy="busy"
    @hidden="$emit('hide')"
  >
    <model-provider
      ref="model"
      collection="mediaPanels/settings"
      :id="id"
      :init="{ cinemaId }"
      @error="notifyErrors"
      @saved="updated"
      @removed="updated"
      @loaded="type = $event.type"
    >
      <template #default>
        <div>
          <setting-screens
            v-show="type"
            ref="screens"
            :type="type"
            :find-screens-by-field="findScreensByField"
            :disabled-button-preview="disabledButtonPreview"
            :hide-change-top="hideChangeTopForScreens"
            @screenshot="$emit('screenshot', $event)"
            @preview-screen-for-settings="$emit('preview-screen-for-settings', { screenId: $event.screenId, settings: settingsComponent })"
          ></setting-screens>

          <setting-type
            class="mt-4"
            :value="type"
            @change="type = $event"
          ></setting-type>

          <setting-sessions
            v-if="type === 'SESSIONS'"
            :setting="setting('SESSIONS')"
            :durations="durations"
            @change="changeSetting($event)"
          ></setting-sessions>

          <setting-cards
            v-if="type === 'CARDS'"
            :setting="setting('CARDS')"
            :durations="durations"
            @change="changeSetting($event)"
          ></setting-cards>

          <setting-posters
            v-if="type === 'POSTERS'"
            :setting="setting('POSTERS')"
            :durations="durations"
            @change="changeSetting($event)"
          ></setting-posters>

          <setting-vposters
            v-if="type === 'VPOSTERS'"
            :setting="setting('VPOSTERS')"
            :durations="durations"
            @change="changeSetting($event)"
          ></setting-vposters>

          <setting-trailers
            v-if="type === 'TRAILERS'"
            :setting="setting('TRAILERS')"
            @change="changeSetting($event)"
          ></setting-trailers>

          <setting-mediastream
            v-if="type === 'MEDIASTREAM'"
            :cinema-id="cinemaId"
            :settings-id="id"
            :is-drop-playlists="isDropPlaylists"
            :library-files="libraryFiles"
            @change="changeSetting($event)"
          ></setting-mediastream>
        </div>
      </template>
    </model-provider>

    <template #modal-footer="{ hide }">
      <div class="w-100 d-flex">
        <div class="flex-grow-1">
          <b-button
            v-if="isSettingTypeMediastream"
            variant="info"
            @click="$emit('show-modal-files')"
          >Добавить файл</b-button>
        </div>
        <div>
          <b-button variant="secondary" @click="hide()">Закрыть</b-button>
          <b-button
            class="ml-2"
            variant="success"
            :disabled="disabledButtonPreview"
            @click="save()"
          >Сохранить</b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ModelProvider from '../../providers/ModelProvider';

import SettingType from './SettingType.vue';
import SettingCards from './SettingCards.vue';
import SettingScreens from './SettingScreens.vue';
import SettingSessions from './SettingSessions.vue';
import SettingPosters from './SettingPosters.vue';
import SettingVposters from './SettingVposters.vue';
import SettingTrailers from './SettingTrailers.vue';
import SettingMediastream from './SettingMediastream.vue';

export default {
  components: {
    ModelProvider,
    SettingType,
    SettingScreens,
    SettingSessions,
    SettingCards,
    SettingPosters,
    SettingVposters,
    SettingTrailers,
    SettingMediastream,
  },
  props: {
    id: null,
    cinemaId: null,
    createTitle: {
      default: 'Создание',
    },
    editTitle: {
      default: 'Редактирование',
    },
    libraryFiles: {
      type: Array,
      default: () => [],
    },
    findScreensByField: {
      type: Object,
    },
    screens: {
      type: Array,
      default: () => [],
    },
    isDropPlaylists: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      type: null,
      settingsComponent: null,
      durations: [...new Array(5)].map((item, index) => ({
        value: (index + 1) * 5,
        text: (index + 1) * 5,
      })),
    };
  },
  computed: {
    title() {
      return this.id ? this.editTitle : this.createTitle;
    },

    busy() {
      return this.$refs.model?.loading || this.$refs.screens?.loading || this.loading;
    },

    activeSetting() {
      return this.$refs.model?.item;
    },

    typeActiveSetting() {
      return this.activeSetting?.type;
    },

    isSettingTypeMediastream() {
      return this.type === 'MEDIASTREAM';
    },

    setting() {
      return (type) => {
        if (this.typeActiveSetting === type) {
          return this.activeSetting;
        }

        return null;
      };
    },

    disabledButtonPreview() {
      if (this.isSettingTypeMediastream) {
        return Array.isArray(this.settingsComponent?.playlist) && this.settingsComponent.playlist.length === 0;
      }

      return false;
    },

    hideChangeTopForScreens() {
      return ['MEDIASTREAM', 'TRAILERS'].includes(this.type);
    },
  },
  methods: {
    changeSetting(setting) {
      this.settingsComponent = {
        id: this.id || 0,
        type: this.type,
        cinemaId: this.cinemaId,
        ...setting,
      };
    },

    async updated(id) {
      const settingId = id || null;

      await this.$refs.screens.save(settingId);

      this.$emit('updated', settingId);

      this.$refs.modal.hide();
    },

    async save() {
      if (this.type) {
        /* eslint-disable guard-for-in */
        /* eslint-disable no-restricted-syntax */
        for (const [field, value] of Object.entries(this.settingsComponent)) {
          this.$refs.model.setValue(field, value);
        }

        await this.$refs.model.save();

        return;
      }

      if (this.id && !this.type) {
        await this.$refs.model.remove();

        return;
      }

      this.$refs.modal.hide();
    },

    notifyErrors(errors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const field of Object.keys(errors)) {
        this.$notify({
          type: 'error',
          text: errors[field],
        });
      }
    },
  },
};
</script>
