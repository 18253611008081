export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditOrganizationForm',
        permission: 'organizations.edit',
      },
      {
        type: 'button',
        text: 'Изменить баланс',
        action: 'openOrganizationBalanceForm',
        args: item,
        permission: 'organizations.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeOrganization',
        permission: 'organizations.edit',
      },
    ],
  },
];
