import humps from 'humps';

import {
  fetchShows,
  getShow,
  updateShow,
  toggleSales,
  setMinPrice,
} from '../api/sales';

export default {
  async fetchShows({
    filter, params,
  }) {
    const [err, data] = await fetchShows({
      filter: humps.decamelizeKeys(filter),
      params: humps.decamelizeKeys(params),
    });

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async updateShow(id, values) {
    const [err, data] = await updateShow(id, humps.decamelizeKeys(values));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async getShow(id, params) {
    const [err, data] = await getShow(id, humps.decamelizeKeys(params));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async toggleSales({
    cinemaId, hallId, dates, showId,
  }, values) {
    const [err, data] = await toggleSales({
      cinemaId, hallId, dates, showId,
    }, humps.decamelizeKeys(values));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async setMinPrice({
    cinemaId, releaseId, dateFrom, dateTo, timeFrom, timeTo,
  }, { minPrice }) {
    const [err, data] = await setMinPrice({
      cinemaId, releaseId, dateFrom, dateTo, timeFrom, timeTo,
    }, { minPrice });

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },
};
