<template>
  <div
    class="schedule"
    :class="{'disabled': disabled}"
  >
    <schedule-hall
      v-for="(hall, hallIndex) in halls"
      class="schedule__hall mb-3"
      :key="hall.id"
      :hall="hall"
      @clear-hall="$emit('clear-hall', hall.id)"
      @toggle-hall="toggleHallVisibility(hallIndex)"
    >
      <template #default>
        <div
          :class="[hallsVisibility[hallIndex] === false ? 'd-none' : 'd-flex']"
          :style="getScheduleContainerStyles(hallIndex)"
        >
          <draggable
            v-for="(date, dateIndex) in dates"
            class="schedule__draggable flex-grow-1"
            :key="date.getDay()"
            :sort="false"
            :force-fallback="true"
            :disabled="disabled"
            :group="dragOptions"
            :data-date-index="dateIndex"
            :data-hall-index="hallIndex"
            :list="getSchedule(hallIndex, dateIndex)"
            :move="onMoveDay"
            @end="copyShows($event)"
          >
            <drop
              class="schedule-day d-flex flex-column"
              @drop="onDrop(hallIndex, dateIndex, $event)"
              @dragenter="onDragEnter(hallIndex, dateIndex, $event)"
              @dragleave="onDragLeave(hallIndex, dateIndex, $event)"
            >
              <schedule-day
                class="schedule__draggable-item"
                :is-first-day="dateIndex === 0"
                :is-last-day="isLastDay(hallIndex, dateIndex)"
                :is-same-as-left="isSameAsLeft(hallIndex, dateIndex)"
                :is-same-as-right="isSameAsRight(hallIndex, dateIndex)"
                :lines-count="getHallLinesCount(hallIndex)"
                :line-height="lineHeight"
                :holiday="isHoliday(date)"
              >
                <template
                  v-for="(show, index) in getSchedule(hallIndex, dateIndex)"
                  #[`item(${index})`]
                >
                  <slot
                    name="item"
                    :show="show"
                    :is-same="isSameAsLeft(hallIndex, dateIndex)(index)"
                    :hall="hall"
                  ></slot>
                </template>
              </schedule-day>
            </drop>
          </draggable>
        </div>
      </template>
    </schedule-hall>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import Drop from '../../components/Drop.vue';
import datetime from '../../utils/datetime';
import ScheduleHall from './ScheduleHall.vue';
import ScheduleDay from './ScheduleDay.vue';

import { BUSINESS_DATE_BORDER } from '../../constants';

export default {
  components: {
    Drop,
    Draggable,
    ScheduleHall,
    ScheduleDay,
  },
  props: {
    disabled: {
      default: false,
    },
    shows: {
      default: () => [],
    },
    halls: {
      default: () => [],
    },
    dates: {
      default: () => [],
    },
    lineHeight: {
      default: 30,
    },
    isHoliday: {
      default: () => false,
    },
    worktimeHoliday: {
      default: `${BUSINESS_DATE_BORDER}-${BUSINESS_DATE_BORDER}`,
    },
    worktimeWeekday: {
      default: `${BUSINESS_DATE_BORDER}-${BUSINESS_DATE_BORDER}`,
    },
    getAdvertisementsDuration: {
      type: Function,
      default: () => 0,
    },
  },
  data() {
    return {
      schedule: [],
      draggableHallIndex: null,
      draggableDateIndex: null,
      draggableShows: [],
      hallsVisibility: {},
    };
  },
  computed: {
    dragOptions() {
      return {
        pull: this.pullDay,
        put: true,
        revertClone: true,
      };
    },
  },
  watch: {
    shows: {
      handler() {
        this.initSchedule();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initSchedule() {
      for (let i = 0; i < this.halls.length; i += 1) {
        this.schedule[i] = [];
        for (let j = 0; j < this.dates.length; j += 1) {
          this.schedule[i][j] = this.getShows(this.halls[i].id, this.dates[j]);
        }
      }
    },

    getShows(hallId, date) {
      return this.shows.filter((_show) => _show.hallId === hallId && datetime.isSameDay(_show.date, date));
    },

    getHallLinesCount(hallIndex) {
      let linesCount = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const dateIndex in this.schedule[hallIndex]) {
        if (this.schedule[hallIndex][dateIndex].length > linesCount) {
          linesCount = this.schedule[hallIndex][dateIndex].length;
        }
      }

      return linesCount + this.draggableShows.length + 1;
    },

    getSchedule(hallIndex, dateIndex) {
      const shows = (this.schedule[hallIndex] && this.schedule[hallIndex][dateIndex]) || [];

      if (this.draggableHallIndex === hallIndex && this.draggableDateIndex === dateIndex && this.draggableShows.length) {
        return [...shows, ...this.draggableShows];
      }

      return shows;
    },

    isLastDay(hallIndex, dateIndex) {
      return dateIndex === (this.dates.length - 1);
    },

    isSameAsLeft(hallIndex, dateIndex) {
      if (dateIndex === 0) {
        return () => false;
      }

      const currentDateShows = this.schedule[hallIndex] ? this.schedule[hallIndex][dateIndex] : [];
      const prevDateShows = this.schedule[hallIndex] ? this.schedule[hallIndex][dateIndex - 1] : [];

      return (showIndex) => {
        const prevShow = prevDateShows[showIndex];
        const currentShow = currentDateShows[showIndex];

        if (!prevShow || !currentShow) {
          return false;
        }

        if (prevShow.releaseId !== currentShow.releaseId) {
          return false;
        }

        if (prevShow.time !== currentShow.time) {
          return false;
        }

        return true;
      };
    },

    isSameAsRight(hallIndex, dateIndex) {
      if (dateIndex === 6) {
        return () => false;
      }

      return this.isSameAsLeft(hallIndex, dateIndex + 1);
    },

    onMoveDay() {
      return -1;
    },

    getDateIndex(el) {
      return +el.dataset.dateIndex;
    },

    getHallIndex(el) {
      return +el.dataset.hallIndex;
    },

    pullDay(to, from) {
      const sourceHallIndex = +this.getHallIndex(from.el);
      const sourceDateIndex = +this.getDateIndex(from.el);
      const destinationHallIndex = +this.getHallIndex(to.el);
      const destinationDateIndex = +this.getDateIndex(to.el);

      if (!this.isCopyAllowed(sourceHallIndex, sourceDateIndex, destinationHallIndex, destinationDateIndex)) {
        return false;
      }

      return 'clone';
    },

    copyShows(event) {
      const sourceHallIndex = +this.getHallIndex(event.from);
      const sourceDateIndex = +this.getDateIndex(event.from);
      const destinationHallIndex = +this.getHallIndex(event.to);
      const destinationDateIndex = +this.getDateIndex(event.to);

      if (!this.isCopyAllowed(sourceHallIndex, sourceDateIndex, destinationHallIndex, destinationDateIndex)) {
        return;
      }

      this.$emit('copy-shows', {
        hallIdFrom: this.halls[sourceHallIndex].id,
        hallIdTo: this.halls[destinationHallIndex].id,
        dateFrom: this.dates[sourceDateIndex],
        dateTo: this.dates[destinationDateIndex],
      });
    },

    isCopyAllowed(sourceHallIndex, sourceDateIndex, destinationHallIndex, destinationDateIndex) {
      if (sourceDateIndex === destinationDateIndex && sourceHallIndex === destinationHallIndex) {
        return false;
      }

      const sourceHall = this.halls[sourceHallIndex];
      const sourceDate = this.dates[sourceDateIndex];
      const destinationHall = this.halls[destinationHallIndex];

      const shows = this.getShows(sourceHall.id, sourceDate);

      // eslint-disable-next-line no-restricted-syntax
      for (const show of shows) {
        if (!destinationHall.formats.find((format) => format.id === show.release.formatId)) {
          return false;
        }
      }

      return true;
    },

    onDragEnter(hallIndex, dateIndex, { release, count }) {
      if (this.disabled) {
        return;
      }

      if (this.draggableHallIndex === hallIndex && this.draggableDateIndex === dateIndex) {
        return;
      }

      const hall = this.halls[hallIndex];
      const date = this.dates[dateIndex];

      if (hall.formats.find((format) => format.id === release.formatId)) {
        this.draggableHallIndex = hallIndex;
        this.draggableDateIndex = dateIndex;

        this.createDraggableShows(hall, date, release, count);
      }
    },

    onDragLeave(hallIndex, dateIndex) {
      if (this.disabled) {
        return;
      }

      if (this.draggableHallIndex === hallIndex && this.draggableDateIndex === dateIndex) {
        this.draggableHallIndex = null;
        this.draggableDateIndex = null;
      }
    },

    onDrop() {
      if (this.disabled) {
        return;
      }

      this.$emit('create-shows', this.draggableShows);

      this.draggableHallIndex = null;
      this.draggableDateIndex = null;
      this.draggableShows = [];
    },

    createDraggableShows(hall, date, release, count = 1) {
      const shows = this.getShows(hall.id, date);
      const lastShow = shows[shows.length - 1];

      let lastShowTime = this.isHoliday(date) ? this.worktimeHoliday.split('-')[0] : this.worktimeWeekday.split('-')[0];
      let lastShowDuration = 0;
      let lastShowAdvertisementsDuration = 0;

      if (lastShow) {
        lastShowTime = lastShow.time;
        lastShowDuration = lastShow.release.duration;
        lastShowAdvertisementsDuration = lastShow.advertisementsDuration;
      }

      this.draggableShows = [];

      for (let i = 0; i < count; i += 1) {
        lastShowTime = this.$datetime.getIntervalEndTime(lastShowTime, lastShowDuration ? lastShowDuration + lastShowAdvertisementsDuration + hall.interval : 0, 5);
        lastShowDuration = release.duration;

        lastShowAdvertisementsDuration = this.getAdvertisementsDuration(hall.id, date, lastShowTime, release);

        this.draggableShows.push({
          releaseId: release.id,
          hallId: hall.id,
          release,
          date: this.$datetime.convertDateToDbFormat(date),
          time: this.$datetime.convertTimeToDbFormat(lastShowTime),
          advertisementsDuration: lastShowAdvertisementsDuration,
        });
      }
    },

    toggleHallVisibility(hallIndex) {
      if (typeof this.hallsVisibility[hallIndex] === 'undefined') {
        this.$set(this.hallsVisibility, hallIndex, false);
      } else {
        this.hallsVisibility[hallIndex] = !this.hallsVisibility[hallIndex];
      }
    },

    getScheduleContainerStyles(hallIndex) {
      return {
        height: `${this.getHallLinesCount(hallIndex) * this.lineHeight}px`,
      };
    },
  },
};
</script>

<style scoped>
.schedule {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
.schedule.disabled {
  opacity: 0.25;
}
.schedule__draggable {
  width: 0;
}
.schedule__hall {
  overflow: hidden;
}
</style>
