<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Дистрибьютор:"
      :invalid-feedback="errors.distributorId"
      :state="states.distributorId"
    >
      <dictionary-provider name="distributors2">
        <template #default="{ items: distributors, loading: distributorsLoading }">
          <toggle-list
            :options="distributors"
            :value="item.distributor ? [item.distributor] : []"
            :searchable="true"
            :disabled="loading || distributorsLoading"
            :multiple="false"
            :hide-selected="false"
            :close-on-select="true"
            @change="updateDistributor($event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Наименование юридического лица:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="ФИО Директора:"
      :invalid-feedback="errors.director"
      :state="states.director"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.director"
        :state="states.director"
        :disabled="loading"
        @change="updateValue('director', $event)"
      ></b-form-input>
    </b-form-group>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    updateDistributor($event) {
      if (!$event || !$event.length) {
        return;
      }

      const distributor = $event[$event.length - 1];

      this.updateValue('distributor', distributor);
      this.updateValue('distributorId', distributor.id);
    },
  },
};
</script>
