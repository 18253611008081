export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: true,
  },
  {
    key: 'host',
    type: 'text',
    label: 'Адрес сервера',
    filterable: false,
    sortable: false,
  },
  {
    key: 'port',
    type: 'text',
    label: 'Порт',
    filterable: false,
    sortable: false,
  },
  {
    key: 'username',
    type: 'text',
    label: 'Пользователь',
    filterable: false,
    sortable: false,
  },
  {
    key: 'password',
    type: 'text',
    label: 'Пароль',
    filterable: false,
    sortable: false,
  },
  {
    key: 'encryption',
    type: 'text',
    label: 'Тип шифрования',
    filterable: false,
    sortable: false,
  },
];
