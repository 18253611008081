<template>
  <div class="table-container mail-accounts">
    <collection-provider
      collection="mailAccounts"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <dictionaries-provider :names="['legalEntities']">
          <template #default="{ legalEntities }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :panel-actions="datatable.panelActions"
              :item-actions="datatable.itemActions"
              :checked="datatable.checked"
              :permissions="$user.permissions"
              @change-checked="datatable.checked = $event"
              @panel-action="performPanelAction($event)"
              @item-action="performItemAction($event)"
            >
              <template #cell(legalEntityId)="data">
                {{data.item.legalEntity.name}}
              </template>
            </datatable>
          </template>
        </dictionaries-provider>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.mailAccount.show"
      create-title="Создание мерчант аккаунта"
      edit-title="Редактирование мерчант аккаунта"
      collection="mailAccounts"
      :id="modals.mailAccount.id"
      @close="modals.mailAccount.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <mail-account-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></mail-account-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';
import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import MailAccountForm from './MailAccountForm.vue';
import DictionariesProvider from '../../providers/DictionariesProvider';

export default {
  components: {
    DictionariesProvider,
    CollectionProvider,
    ModalModelForm,
    MailAccountForm,
    Datatable,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        mailAccount: {
          show: false,
          id: null,
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateMailAccountForm() {
      this.modals.mailAccount.id = null;
      this.modals.mailAccount.show = true;
    },

    openEditMailAccountForm(id) {
      this.modals.mailAccount.id = id;
      this.modals.mailAccount.show = true;
    },

    async removeCheckedMailAccounts() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    async removeMailAccount(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>
