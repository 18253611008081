/* eslint-disable no-return-await */
/* eslint-disable no-restricted-syntax */
import http from './http';
import { API_VERSION } from '../config';
import { convertError } from './utilities';

export default async function fetchAnalytics(params) {
  try {
    const { data } = await http.post(`/${API_VERSION}/analytics`, params);
    return [null, data];
  } catch (error) {
    return convertError(error);
  }
}

export async function exportCustomers(params) {
  try {
    const response = await http.post(`/${API_VERSION}/analytics/export_customers`, params, {
      responseType: 'blob',
    });

    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}
