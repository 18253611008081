<template>
  <div class="discount-formula">
    <div>Изменение цены: {{ item.formula }}</div>
    <div
      class="mt-1"
      v-for="(colorScheme, index) in item.colorSchemes"
      :key="index"
    >{{ colorScheme.name }}: {{ colorScheme.pivot.formula }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.discount-formula {
  font-size: 12px;
}
</style>
