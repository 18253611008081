import { render, staticRenderFns } from "./RfmMoney.vue?vue&type=template&id=7a33d944&scoped=true&"
import script from "./RfmMoney.vue?vue&type=script&lang=js&"
export * from "./RfmMoney.vue?vue&type=script&lang=js&"
import style0 from "./RfmMoney.vue?vue&type=style&index=0&id=7a33d944&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a33d944",
  null
  
)

export default component.exports