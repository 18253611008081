<template>
  <list-box
    :items="displayedReleases"
    :loading="loading"
    :search="releaseName"
    :draggable="true"
    :empty-text="emptyText"
    search-text="Поиск по всей базе"
    @search="searchRelease($event)"
  >
    <template #title="{item}">
      <span
        v-if="item.movie"
        class="release__name text-truncate"
        :title="item.movie.name"
      >{{item.movie.name}}</span>
    </template>

    <template #subtitle="{item}">
      <span>c {{ $datetime.formatDate(item.movie.dateStart) }}</span>
    </template>

    <template #description="{item}">
      <span class="mr-1">{{item.movie.ageLimit}}+,</span>
      <span class="mr-1">{{item.duration}}</span>
      <span class="mr-1">мин,</span>
      <span
        v-if="item.format"
        :class="{'text-danger': format3D(item.format.name)}"
      >{{item.format.name}}</span>
    </template>
  </list-box>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState } from 'vuex';

import ListBox from '../../components/ListBox.vue';

import { DEBOUNCE_DELAY } from '../../constants';

export default {
  components: {
    ListBox,
  },
  props: {
    cinemaId: {
      default: null,
    },
    formats: {
      default: [],
    },
    dateStart: {
      default: '',
    },
    dateEnd: {
      default: '',
    },
  },
  data() {
    return {
      releaseName: '',
    };
  },
  computed: {
    ...mapState('dictionaries/favoriteReleases', {
      favoriteReleases: (state) => state.items,
      favoriteReleasesLoading: (state) => state.loading,
    }),

    ...mapState('dictionaries/releases2', {
      releases: (state) => state.items,
      releasesLoading: (state) => state.loading,
    }),

    displayedReleases() {
      if (this.releaseName) {
        return this.releases.filter((release) => this.formats.includes(release.format.id));
      }

      return this.favoriteReleases;
    },

    loading() {
      return this.favoriteReleasesLoading || this.releasesLoading;
    },

    emptyText() {
      if (this.releaseName && !this.releases.length) {
        return 'Релизы не найдены';
      }

      return 'Нет избранных релизов';
    },

    propsComposition() {
      return [this.cinemaId, this.dateStart, this.dateEnd].join('');
    },
  },
  watch: {
    propsComposition() {
      this.fetchFavoriteReleases();
    },
  },
  created() {
    this.searchRelease = debounce(this.searchRelease, DEBOUNCE_DELAY, {
      trailing: true,
      leading: false,
    });

    this.fetchFavoriteReleases();
  },
  methods: {
    fetchFavoriteReleases() {
      if (!this.cinemaId || !this.dateStart || !this.dateEnd) {
        return;
      }

      this.$store.dispatch('dictionaries/favoriteReleases/fetchItems', {
        filter: {
          cinemaId: this.cinemaId,
          dateStartGte: this.$datetime.convertDateToDbFormat(this.$datetime.subtractDays(this.dateStart, 14)),
          dateStartLte: this.$datetime.convertDateToDbFormat(this.dateEnd),
        },
      });
    },

    format3D(value) {
      return value.indexOf('3D') !== -1;
    },

    searchRelease(name) {
      this.releaseName = name;

      if (!name) {
        return;
      }

      this.$store.dispatch('dictionaries/releases2/fetchItems', {
        filter: {
          name,
        },
      });
    },
  },
};
</script>

<style scoped>
.release__name {
  max-width: 100%;
  display: block;
}
</style>
