<template>
  <div class="table-container">
    <collection-provider
      collection="halls"
      ref="collection"
      :filter="{ cinemaId }"
      :limit="null"
      :page="null"
      :disable-fetching="!cinemaId"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :item-actions="datatable.itemActions"
          @item-action="performItemAction($event)"
        >
          <template #cell(name)="{ item: hall }">
            <router-link :to="{ name: 'hall', params: { cinema: cinemaId, id: hall.id } }">
              {{hall.name}}
            </router-link>
          </template>
          <template #cell(category)="{ item: hall }">
            {{ hall.hallCategory ? hall.hallCategory.name : '' }}
          </template>
          <template #cell(rowsCount)="{ item: hall }">
            {{ getRowsCount(hall) }}
          </template>
          <template #cell(placesCount)="{ item: hall }">
            {{ hall.placesCount }}
          </template>
          <template #cell(interval)="{ item: hall }">
            {{ hall.interval }} мин.
          </template>
          <template #cell(formats)="{ item: hall }">
            {{ $array.convertToText(hall.formats) }}
          </template>
        </datatable>
      </template>
    </collection-provider>
  </div>
</template>

<script>
import CollectionProvider from '../../providers/CollectionProvider';
import { confirmDialog } from '../../utils/dialogs';
import Datatable from '../../components/datatable/Datatable.vue';

import fields from './_halls.fields';
import itemActions from './_halls.itemActions';

export default {
  components: {
    CollectionProvider,
    Datatable,
  },
  props: {
    cinemaId: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },
    getRowsCount(hall) {
      return hall.scheme.filter((row) => row.length).length;
    },
    getFormats(hall) {
      return hall.formats.map((f) => ({ id: f.id, name: f.name }));
    },
    openHall(id) {
      this.$router.push({ name: 'hall', params: { cinemaId: this.cinemaId, id } });
    },
    async removeHall(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>
.hall {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  position: relative;
}
.hall-image {
  max-width: 33%;
}
.hall-image img {
  max-width: 100%;
}
.hall h3 a, .hall h3 a:hover, .hall h3 a:active, .hall h3 a:focus {
  color: inherit;
  text-decoration: none;
}
.hall-format:before {
  content: " ";
}
.hall-format + .hall-format:before {
  content: ", ";
}
.hall__menu {
  position: absolute;
  right: 0;
  top: 0;
  color: #AA0000;
  cursor: pointer;
}
.hall__menu .btn-link {
  color: #000;
}
.notes {
  font-size: 0.83rem;
  width: 100%;
}
</style>
