<template>
  <autocomplete
    input-class="form-control form-control-sm"
    :source="getItems"
    :disabled="disabled || loading"
    :value="text"
    :state="state"
    :size="size"
    @select="select($event)"
  ></autocomplete>
</template>

<script>
import Autocomplete from './Autocomplete.vue';
import collectionServiceFactory from '../services/collectionServiceFactory';

export default {
  components: {
    Autocomplete,
  },
  props: {
    collection: {
      default: '',
    },
    value: {
      default: '',
    },
    text: {
      default: '',
    },
    state: {
      default: null,
    },
    disabled: {
      default: false,
    },
    size: {
      default: 'sm',
    },
    format: {
      type: Function,
      default: ({ name }) => name,
    },
  },
  data() {
    return {
      loading: false,
      service: null,
    };
  },
  created() {
    this.service = collectionServiceFactory(this.collection);
  },
  methods: {
    async getItems(term, process) {
      const [, data] = await this.service.fetchItems({
        filter: {
          name: term,
        },
      });

      if (data && data.items) {
        const items = data.items.map((item) => ({
          text: this.format(item),
          data: item.id,
        }));

        process(items);
      }
    },

    select(item) {
      this.$emit('select', item.data);
    },
  },
};
</script>
