<template>
  <div class="cinema-info">
    <div
      class="items-index__cinemas"
      :id="`cinemas_${item.id}`"
    >{{convertCinemasToText(item.cinemas)}}</div>
    <b-tooltip
      v-if="item.cinemas && item.cinemas.length"
      :target="`cinemas_${item.id}`"
    >
      <div
        class="mb-1"
        v-for="cinema in item.cinemas"
        :key="cinema.id"
      >
        <div>{{ cinema.name }}, {{ cinema.city.name }}</div>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: () => ({}),
    },
  },
  methods: {
    convertCinemasToText(cinemas) {
      if (!cinemas.length) {
        return '';
      }

      return cinemas.map((cinema) => `${cinema.name} ${cinema.city.name}`).join(', ');
    },
  },
};
</script>

<style scoped>
.cinema-info > div {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
