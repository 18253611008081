import humps from 'humps';
import collectionServiceFactory from './collectionServiceFactory';

import {
  createScreen,
  updateScreen,
  updateScreens,
  rebootScreen,
  updateGroupAndPosition,
} from '../api/mediaPanels';

const screensService = collectionServiceFactory('mediaPanels/screens');

export default {
  ...screensService,

  async createItem(value) {
    const [err, data] = await createScreen(humps.decamelizeKeys(value));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async updateItem(id, value) {
    const [err, data] = await updateScreen(id, humps.decamelizeKeys(value));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async updateItems(ids, values) {
    const results = await updateScreens(ids, humps.decamelizeKeys(values));

    return results.map(([err, data]) => [humps.camelizeKeys(err), humps.camelizeKeys(data)]);
  },

  async rebootItemById(id) {
    const [err, data] = await rebootScreen(id);

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },

  async updateGroupAndPosition(value) {
    const [err, data] = await updateGroupAndPosition(humps.decamelizeKeys(value));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },
};
