<template>
  <info-card class="w-80 ml-3">
    <info-card-row>
      <template #label>Номер:</template>
      <template #value>{{item.number}}</template>
    </info-card-row>
    <info-card-row>
      <template #label>Номинал:</template>
      <template #value>{{item.certificateGroup.nominal}}</template>
    </info-card-row>
    <info-card-row>
      <template #label>Стоимость:</template>
      <template #value>{{item.certificateGroup.price}}</template>
    </info-card-row>
    <info-card-row>
      <template #label>Текущий баланс:</template>
      <template #value>{{item.balance}}</template>
    </info-card-row>
    <info-card-row>
      <template #label>Дата создания:</template>
      <template #value>
        {{ $datetime.formatDateTime(item.createdAt) }},
        <span v-if="item.certificateGroup.activated">активирован при создании</span>
        <span v-else>{{ !!item.activatedAt ? 'активирован ' + $datetime.formatDateTime(item.activatedAt) : 'не активирован' }}</span>
      </template>
    </info-card-row>
    <info-card-row v-if="item.invalidateDate">
      <template #label>Дата деактивации:</template>
      <template #value>{{ $datetime.formatDate(item.invalidateDate) }}</template>
    </info-card-row>
    <info-card-row>
      <template #label>Группа сертификатов:</template>
      <template #value>{{item.certificateGroup.name}}</template>
    </info-card-row>
  </info-card>
</template>

<script>
import InfoCard from '../../components/card/InfoCard.vue';
import InfoCardRow from '../../components/card/InfoCardRow.vue';

export default {
  components: {
    InfoCard,
    InfoCardRow,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {

  },
};
</script>
