<template>
  <b-container
    fluid
    class="p-0"
    :class="{'disabled': loading}"
  >
    <template>
      <div class="d-flex flex-wrap flex-row justify-content-center">
        <div
          v-for="month in 12"
          class="border m-2 mb-4"
          :key="month"
        >
          <slot
            name="month"
            :year="year"
            :month="month"
          >
          </slot>
        </div>
      </div>
    </template>
  </b-container>
</template>

<script>
export default {
  props: {
    year: {
      default: '',
    },
    loading: {
      default: false,
    },
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.7;
}
</style>
