export const TYPES_SETTINGS = [
  {
    value: 'CARDS',
    text: 'Сетка',
    component: 'Cards',
    icon: 'cards.svg',
  },
  {
    value: 'SESSIONS',
    text: 'Список',
    component: 'Sessions',
    icon: 'sessions.svg',
  },
  {
    value: 'POSTERS',
    text: 'Горизонтальный постер',
    component: 'Posters',
    icon: 'posters.svg',
  },
  {
    value: 'VPOSTERS',
    text: 'Вертикальный постер',
    component: 'Vposters',
    icon: 'vposters.svg',
  },
  {
    value: 'MEDIASTREAM',
    text: 'Медиапоток',
    component: 'Mediastream',
    icon: 'mediastream.svg',
  },
  {
    value: 'TRAILERS',
    text: 'Трейлер',
    component: 'Trailers',
    icon: 'trailers.svg',
  },
];

export const CARD_MODE_OPTIONS = [
  {
    value: 'AUTO',
    text: 'Без акцента',
  },
  {
    value: 'FOCUS_ON_SESSIONS',
    text: 'Акцент на сеансы',
  },
  {
    value: 'FOCUS_ON_FILM_FEES',
    text: 'Акцент на сборы',
  },
];

export const HORIZONTAL_POSTER_MODE_OPTIONS = [
  {
    value: 'SCHEDULE',
    text: 'Открытые продажи',
  },
  // {
  //   value: 'SCHEDULED_PLUS_FAVORITE_RELEASES',
  //   text: 'Открытые продажи + Избранные релизы',
  // },
  // {
  //   value: 'SCHEDULED_FAVORITE_MOVIES',
  //   text: 'Избранные релизы',
  // },
];

export const VERTICAL_POSTER_MODE_OPTIONS = [
  {
    value: 'OPEN_SALES',
    text: 'Открытые продажи',
  },
  {
    value: 'OPEN_SALES_PLUS_FAVORITE_RELEASES',
    text: 'Открытые продажи + Избранные релизы',
  },
  {
    value: 'FAVORITE_RELEASES',
    text: 'Избранные релизы',
  },
];

export const TRAILER_MODE_OPTIONS = [
  {
    value: 'OPEN_SALES',
    text: 'Открытые продажи',
  },
  {
    value: 'OPEN_SALES_PLUS_FAVORITE_RELEASES',
    text: 'Открытые продажи + Избранные релизы',
  },
  {
    value: 'FAVORITE_RELEASES',
    text: 'Избранные релизы',
  },
];
