<template>
  <div class="h-100 d-flex flex-column movies">
    <b-row class="p-2 border-bottom shadow-sm">
      <b-col>
        <movies-filters />
      </b-col>
      <b-col cols="auto">
        <b-button
          v-if="$user.can('movies.edit')"
          size="sm"
          variant="info"
          @click="addMovie"
        >Добавить фильм</b-button>
      </b-col>
    </b-row>

    <div class="movies__wrap">
      <div
        v-if="moviesLoading && !movies.length"
        class="movies__loader"
      >
        <b-spinner variant="primary"></b-spinner>
      </div>

      <scroll
        v-if="!!movies.length"
        @end-reached="onNextMonth"
      >
        <div
          v-if="filter.dateStartGte"
          class="d-flex justify-content-center pt-3"
        >
          <div class="d-flex">
            <b-button
              v-if="!!movies.length && loadingPrevItems"
              class="mr-1"
              size="sm"
              variant="light"
              :disabled="moviesLoading"
              @click="onPrevMonth"
            >к предыдущему месяцу</b-button>
          </div>
        </div>

        <movies-group
          class="p-3"
          v-for="(date, index) of dates"
          :key="`movies_group_${index}`"
          :date="date"
        >
          <b-table
            class="mb-0"
            striped
            hover
            thead-class="movies__thead"
            :items="getMoviesForDate(date)"
            :fields="fields"
          >
            <template #cell(id)="data">
              <icon
                v-if="!!data.item.releases.length"
                icon="fa-star"
                class="movies__favorite"
                :class="{ 'selected': favoriteEnabled(data.item.releases) }"
                @click.native="openFavoriteMovieModal(data.item)"
              ></icon>
            </template>

            <template #cell(name)="data">
              <div class="d-flex">
                <div
                  class="movies__poster"
                  :style="`background-image: url(${data.item.posterUrl || noPhoto});`"
                />
                <div>
                  <div>
                    <router-link
                      :id="`movie_${data.item.id}`"
                      :to="{
                        name: 'movie2',
                        params: {
                          movieId: data.item.id,
                          tab: 'info',
                        },
                        query: filter,
                      }"
                    >
                      {{ data.item.name }}
                    </router-link>
                  </div>
                  <div v-if="data.item.nameEn" class="text-small">{{ data.item.nameEn }}</div>
                </div>
            </div>
            </template>

            <template #cell(releases)="data">
              <div
                v-for="(item, index) of data.item.releases"
                :key="index"
              >
                {{ item.format.name }} - {{ item.duration }} мин.
              </div>
            </template>

            <template #cell(ageLimit)="data">
              <div>{{ data.item.ageLimit }}+</div>
            </template>

            <template #cell(distributor)="data">
              {{ data.item.distributor && data.item.distributor.name }}
            </template>

            <template #cell(laboratories)="data">
              <div
                v-for="(laboratory, index) of data.item.laboratories"
                :key="index"
              >
                {{ laboratory.name }}
              </div>
            </template>

            <template #cell(memorandumPeriodEnd)="{ item: { memorandumPeriodEnd } }">
              {{ memorandumPeriodEnd && datetime.formatDate(memorandumPeriodEnd) }}
            </template>

            <template #cell(datePreview)="{ item: { datePreview } }">
              {{ datePreview && datetime.formatDate(datePreview) }}
            </template>

            <template #cell(dateStart)="{ item: { dateStart, dateEnd } }">
              {{ dateStart && datetime.formatDate(dateStart) }}
              <template v-if="dateEnd">
                - {{ datetime.formatDate(dateEnd) }}
              </template>
            </template>
          </b-table>
        </movies-group>

        <div
          v-if="moviesLoading"
          class="text-center pt-2 pb-2"
        >
          <b-spinner variant="primary"></b-spinner>
        </div>
      </scroll>

      <div
        v-if="!movies.length"
        class="text-center pt-2 pb-2"
      >
        Нет фильмов для выбранных условий
      </div>
    </div>

    <movies-favorite-modal
      v-if="modals.favorite.show"
      :movie="modals.favorite.movie"
      @saved="fetchItemsByParams(filter, modals.favorite.movie.id)"
      @close="closeFavoriteMovieModal"
    />

    <modal-model-form
      v-if="modals.movie.show"
      create-title="Новый фильм"
      edit-title="Редактирование фильма"
      collection="movies2"
      :init="{ useBonuses: true }"
      @close="modals.movie.show = false"
      @save="init"
    >
      <template #default="{ data, actions }">
        <movie-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></movie-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import noPhoto from '../../assets/no_photo.png';

import ModalModelForm from '../../components/modal/ModalModelForm.vue';

import MovieForm from '../movie2/MovieForm.vue';

import MoviesFilters from './MoviesFilters.vue';
import MoviesGroup from './MoviesGroup.vue';
import MoviesFavoriteModal from './MoviesFavoriteModal.vue';

import fields from './_index.fields';
import datetime from '../../utils/datetime';

export default {
  components: {
    MoviesFilters,
    MoviesGroup,
    MovieForm,
    ModalModelForm,
    MoviesFavoriteModal,
  },
  data() {
    return {
      datetime,
      modals: {
        favorite: {
          show: false,
          movie: null,
        },
        movie: {
          show: false,
        },
      },
      disableFetchingItems: false,
      noPhoto,
    };
  },
  computed: {
    ...mapState('data/movies2', {
      filter: (state) => state.filter,
      movies: (state) => state.items,
      moviesLoading: (state) => state.loading,
      selectedDate: (state) => state.selectedDate,
      loadingPrevItems: (state) => state.loadingMoviesDirection.prev,
      loadingNextItems: (state) => state.loadingMoviesDirection.next,
    }),

    dates() {
      return this.movies
        .reduce((dates, movie) => {
          if (!dates.find((interval) => interval.start === movie.dateStart && interval.end === movie.dateEnd)) {
            dates.push({ start: movie.dateStart, end: movie.dateEnd });
          }

          return dates;
        }, [])
        .sort((a, b) => new Date(a.start) - new Date(b.start) || new Date(b.end) - new Date(a.end));
    },

    fields() {
      return fields;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'movie') {
        vm.disableFetchingItems = true;
        vm.fetchItemsByParams(from.query, from.params.movieId);
      }
    });
  },
  mounted() {
    if (!this.movies.length) {
      this.init();
    }
  },
  methods: {
    ...mapActions({
      setFilter: 'data/movies2/setFilter',
      changeFilter: 'data/movies2/changeFilter',
      fetchItems: 'data/movies2/fetchItems',
      loadMoviesOfPrevMonthForDate: 'data/movies2/loadMoviesOfPrevMonthForDate',
      loadMoviesOfNextMonthForDate: 'data/movies2/loadMoviesOfNextMonthForDate',
      initLoadMoviesOfMonthForDate: 'data/movies2/initLoadMoviesOfMonthForDate',
    }),

    async init() {
      if (this.disableFetchingItems) {
        return;
      }

      this.initLoadMoviesOfMonthForDate(new Date());
    },

    async fetchItemsByParams(filter, movieId) {
      for (const [field, value] of Object.entries(filter)) {
        this.setFilter({ field, value });
      }

      await this.fetchItems();

      this.$nextTick(() => {
        const element = document.getElementById(`movie_${+movieId}`);

        if (element) {
          element.scrollIntoView(true);
        }
      });
    },

    onPrevMonth() {
      if (!this.moviesLoading) {
        this.loadMoviesOfPrevMonthForDate(
          datetime.findPrevMonthStart(this.filter.dateStartGte),
        );
      }
    },

    onNextMonth() {
      if (!this.moviesLoading && this.filter.dateStartLt) {
        this.loadMoviesOfNextMonthForDate(
          datetime.findNextMonthStart(this.filter.dateStartLt),
        );
      }
    },

    getMoviesForDate(date) {
      return this.movies
        .filter((movie) => (
          this.$datetime.isSameDay(movie.dateStart, date.start)
            && !date.end
            && !movie.dateEnd
        )
          || (
            date.end
            && this.$datetime.isSameDay(movie.dateStart, date.start)
            && this.$datetime.isSameDay(movie.dateEnd, date.end)
          ));
    },

    addMovie() {
      this.modals.movie.show = true;
    },

    openFavoriteMovieModal(movie) {
      this.modals.favorite.movie = {
        id: movie.id,
        name: movie.name,
        ageLimit: movie.ageLimit,
        releases: movie.releases,
      };
      this.modals.favorite.show = true;
    },

    closeFavoriteMovieModal() {
      this.modals.favorite.movie = null;
      this.modals.favorite.show = false;
    },

    favoriteEnabled(releases) {
      return releases.some((release) => release.favoriteCinemas.length);
    },

    reset() {
      this.$store.dispatch('data/movies2/resetFilter');
      this.$store.commit('data/movies2/setLoadingMoviesDirection', {
        type: 'prev',
        value: true,
      });
      this.$store.commit('data/movies2/setLoadingMoviesDirection', {
        type: 'next',
        value: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-small {
  font-size: 12px;
}
.movies {
  &__wrap {
    overflow: hidden;
    position: relative;
    flex: 1;
  }
  &__loader {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  &__favorite {
    color: var(--secondary);
    cursor: pointer;

    &.selected {
      color: var(--yellow);
    }
  }
  &__poster {
    overflow: hidden;
    margin-right: 0.5rem;
    flex-shrink: 0;
    border-radius: 0.25rem;
    width: 4rem;
    height: 6rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /deep/ .movies__thead {
    th {
      font-weight: 500;
      font-size: 15px;
    }
  }
}
</style>
