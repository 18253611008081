export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Информация',
        action: 'openPromocodeForm',
        permission: 'promocodes.view',
      },
      // {
      //   type: 'button',
      //   text: 'Операции',
      //   action: 'openPromocodeOperations',
      //   permission: 'promocodes.view',
      // },
    ],
  },
];
