<template>
  <div v-if="item.id">
    <item-header
      :image="item.imagePath"
      :title="item.name"
      :sub-title="item.code"
    ></item-header>

    <div>
      <info-card class="mb-4" title="Информация о прокатчике">
        <info-card-row v-if="item.website">
          <template #label>сайт</template>
          <template #value>
            <a :href="item.website" target="_blank">{{item.website}}</a>
          </template>
        </info-card-row>

        <info-card-row v-if="item.address">
          <template #label>адрес</template>
          <template #value>{{item.address}}</template>
        </info-card-row>

        <info-card-row v-if="item.phone">
          <template #label>телефон</template>
          <template #value>{{item.phone}}</template>
        </info-card-row>

        <info-card-row v-if="item.email">
          <template #label>email</template>
          <template #value>{{item.email}}</template>
        </info-card-row>

        <info-card-row v-if="!item.includeEkb">
          <template #label>Отчёты в ЕАИС</template>
          <template #value><strong>Данные о продажах не передаются!</strong></template>
        </info-card-row>

        <info-card-row v-if="!item.includeRentrak">
          <template #label>Отчёты в Рентрак</template>
          <template #value><strong>Данные о продажах не передаются!</strong></template>
        </info-card-row>

        <info-card-row v-if="!item.includeRao">
          <template #label>Отчёты в РАО</template>
          <template #value><strong>Данные о продажах не передаются!</strong></template>
        </info-card-row>
      </info-card>

      <info-card
        v-for="contact in item.contacts"
        class="mb-4"
        :title="contact.position"
        :key="contact.id"
      >
        <info-card-row v-if="contact.name">
          <template #label></template>
          <template #value><h4>{{contact.name}}</h4></template>
        </info-card-row>

        <info-card-row v-if="contact.phone">
          <template #label>телефон</template>
          <template #value>{{contact.phone}}</template>
        </info-card-row>

        <info-card-row v-if="contact.email">
          <template #label>email</template>
          <template #value>{{contact.email}}</template>
        </info-card-row>
      </info-card>
    </div>
  </div>
</template>

<script>
import ItemHeader from '../../components/card/ItemHeader.vue';
import InfoCard from '../../components/card/InfoCard.vue';
import InfoCardRow from '../../components/card/InfoCardRow.vue';

export default {
  components: {
    ItemHeader,
    InfoCard,
    InfoCardRow,
  },
  props: {
    item: {
      default: () => ({}),
    },
  },
};
</script>
