<template>
  <list-box
    empty-text="Нет фильмов"
    :current="movieId"
    :items="movies"
    :loading="loading"
    :search="search"
    @search="$emit('search', $event)"
    @click="$emit('select', $event)"
  >
    <template #top-content>
      <div
        class="p-2 border-bottom"
      >
        <b-form-checkbox
          size="sm"
          :checked="noKeys"
          :value="true"
          :unchecked-value="false"
          :disabled="loading"
          @change="$emit('change-no-keys', $event)"
        >
          Фильмы без ключей
        </b-form-checkbox>
      </div>
    </template>

    <template #title="{ item }">
      <div
        class="d-flex"
        :class="{ 'active': movieId === item.id }"
      >
        <span
          class="movie__name text-truncate"
          :title="item.name"
        >{{ item.name }}</span>
      </div>
    </template>

    <template #description="{ item }">
      <div>Ключей: {{ item.dcpKeys.length }}</div>
      <div :class="{ 'text-danger': item.showsWithoutDcpKeys.length }">Сеансов без ключей: {{ item.showsWithoutDcpKeys.length }}</div>
    </template>
  </list-box>
</template>

<script>
import ListBox from '../../components/ListBox.vue';

export default {
  components: {
    ListBox,
  },
  props: {
    movieId: {
      default: null,
    },
    movies: {
      default: () => [],
    },
    loading: {
      default: false,
    },
    search: {
      default: '',
    },
    noKeys: {
      default: false,
    },
  },
};
</script>

<style scoped>
.movie__name {
  max-width: 100%;
  font-weight: 400;
  display: block;
}
</style>
