<template>
  <b-dropdown
    v-if="items.length"
    size="sm"
    class="dropdown dropdown_movies-filter"
    menu-class="dropdown__menu"
    variant="outline-secondary"
    no-caret
    @show="onShown"
  >
    <span class="dropdown__btn" slot="button-content">
      {{ getDropdownTitle() }}
      <icon class="dropdown__icon" icon="fa-angle-down"></icon>
    </span>
    <b-dropdown-item-button
      active-class="active-item"
      :active="value == null"
      :disabled="value == null"
      @click="clickDropdownBtn(null)"
      >{{ allText }}</b-dropdown-item-button
    >
    <b-dropdown-item-button
      v-for="item of items"
      active-class="active-item"
      class="dropdown__item"
      :active="item.id == value"
      :disabled="item.id == value"
      :key="item.id"
      @click="clickDropdownBtn(item.id)"
      >{{ item.name }}</b-dropdown-item-button
    >
  </b-dropdown>
</template>

<script>
export default {
  props: {
    allText: {
      default: 'Все',
    },
    items: {
      default: () => [],
    },
    value: null,
  },
  methods: {
    onShown() {
      document.body.click();
    },

    getDropdownTitle() {
      if (!this.value) {
        return this.allText;
      }
      return this.items.find((item) => item.id === this.value)?.name;
    },

    clickDropdownBtn(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;

  &_movies-filter {
    .dropdown__icon {
      position: absolute;
      width: 25px;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /deep/ .dropdown-item {
      padding: 0.15rem 1rem;
    }

    /deep/ .dropdown-item.active-item {
      background: var(--secondary);
      color: var(--white);
    }

    /deep/ .dropdown__menu {
      padding-top: 0;
      padding-bottom: 0;
      min-width: 15em;
      max-height: 18em;
      overflow: auto;
      outline: none;
    }

    /deep/ .btn.dropdown-toggle {
      padding-right: 25px;

      .dropdown__icon::before {
        transition: 0.3s ease;
        position: relative;
      }

      &[aria-expanded='true'] {
        .dropdown__icon::before {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>
