<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Кинотеатры:"
      :invalid-feedback="errors.cinemas"
      :state="states.cinemas"
    >
      <dictionary-provider name="cinemas">
        <template #default="{ items: cinemas, loading: cinemasLoading }">
          <toggle-list
            :options="convertCinemas($array.union(cinemas, item.cinemas))"
            :value="item.cinemas"
            :disabled="loading || cinemasLoading"
            @change="updateValue('cinemas', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Фильм:"
      :invalid-feedback="errors.movieId"
      :state="states.movieId"
    >
      <dictionary-provider name="movies2">
        <template #default="{ items: movies, loading: moviesLoading }">
          <toggle-list
            :options="movies"
            :value="item.movie ? [item.movie] : []"
            :searchable="true"
            :disabled="loading || moviesLoading"
            :multiple="false"
            :hide-selected="false"
            :close-on-select="true"
            @change="updateMovie($event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Форматы фильмов:"
      :invalid-feedback="errors.formats"
      :state="states.formats"
    >
      <toggle-list
        :options="formatsOptions"
        :value="item.formats"
        :disabled="loading"
        @change="updateValue('formats', $event)"
      ></toggle-list>
    </b-form-group>

    <b-form-group
      label="Номер договора:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        autofocus
        type="text"
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Период проката:"
      :invalid-feedback="errors.dateStart || errors.dateEnd"
      :state="states.dateStart || states.dateEnd"
    >
      <date-interval
        :value="{from: item.dateStart, to: item.dateEnd}"
        :disabled="loading"
        @selected="updateValue('dateStart', $event.from), updateValue('dateEnd', $event.to)"
      ></date-interval>
    </b-form-group>

    <b-form-group
      label="Прокатная плата:"
      :invalid-feedback="errors.rate"
      :state="states.rate"
    >
      <b-input-group append="%" size="sm">
        <b-form-input
          type="number"
          size="sm"
          min="0"
          max="100"
          :value="item.rate"
          :state="states.rate"
          :disabled="loading"
          @change="updateValue('rate', $event)"
        ></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group>
      <b-form-checkbox
        :checked="!!item.includingVat"
        :state="states.includingVat"
        :disabled="loading"
        @change="updateValue('includingVat', $event)"
      >НДС 20%</b-form-checkbox>
    </b-form-group>

    <b-form-group
      label="Сроки оплаты:"
      :invalid-feedback="errors.paymentTerms"
      :state="states.paymentTerms"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.paymentTerms"
        :state="states.paymentTerms"
        :disabled="loading"
        @change="updateValue('paymentTerms', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-checkbox
        :checked="item.original"
        :state="states.original"
        :disabled="loading"
        @change="updateValue('original', $event)"
      >Наличие оригинала договора</b-form-checkbox>
    </b-form-group>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import DateInterval from '../../components/fields/DateInterval.vue';
import ToggleList from '../../components/fields/ToggleList.vue';

export default {
  components: {
    DictionaryProvider,
    DateInterval,
    ToggleList,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  watch: {
    'item.movieId': {
      async handler() {
        if (this.item?.movieId) {
          await this.$store.dispatch('dictionaries/releases2/fetchItems',
            {
              filter: {
                movieId: this.item.movieId,
              },
            });
          const storeReleases = this.$store.state.dictionaries.releases2.items;
          this.formatsOptions = this.getReleasesFormats(storeReleases);
          const formatsDictionary = storeReleases.map((release) => release.formatId);
          this.checkFormatsInOptions(this.item.formats, formatsDictionary);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      formatsOptions: [],
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },
    updateMovie($event) {
      if (!$event || !$event.length) {
        return;
      }

      const movie = $event[$event.length - 1];

      this.updateValue('movie', movie);
      this.updateValue('movieId', movie.id);
    },
    getReleasesFormats(releases) {
      return releases.map((release) => ({
        id: release.format.id,
        name: release.format.name,
      }));
    },
    checkFormatsInOptions(chosen = [], options) {
      const filtered = chosen.filter((item) => options.indexOf(item.id) !== -1);
      this.updateValue('formats', filtered);
    },
  },
};
</script>
