<template>
  <analytics-sales-provider
    :cinema-id="cinemaId"
    :region-id="regionId"
    :periods="periods"
  >
    <template #default="{
      data: { appGroups },
      actions: { getAnalyticsData, getTargetData, getLastAnalyticsDataForAppGroups },
    }">
      <div>
        <h2 class="my-2">Общий</h2>

        <b-row>
          <b-col md="2">
            <small-fill-charts
              class="h-100"
              title1="Вал"
              title2="Покупки"
              :data1="$array.sumArrays(
                getAnalyticsData(ANALYTICS_TICKETS_PRICE),
                getAnalyticsData(ANALYTICS_PRODUCTS_PRICE)
              )"
              :data2="$array.sumArrays(
                getAnalyticsData(ANALYTICS_TICKETS_COUNT),
                getAnalyticsData(ANALYTICS_PRODUCTS_COUNT)
              )"
            ></small-fill-charts>
          </b-col>

          <b-col md="6">
            <wide-bar-chart
              class="h-100"
              :periods="formattedPeriods"
              :actual-data="$array.sumArrays(
                getAnalyticsData(analyticsType === ANALYTICS_TYPE_PRICE ? ANALYTICS_TICKETS_PRICE : ANALYTICS_TICKETS_COUNT),
                getAnalyticsData(analyticsType === ANALYTICS_TYPE_PRICE ? ANALYTICS_PRODUCTS_PRICE : ANALYTICS_PRODUCTS_COUNT)
              )"
              :target-data="$array.sumArrays(
                getTargetData(analyticsType === ANALYTICS_TYPE_PRICE ? TARGET_TICKETS_PRICE : TARGET_TICKETS_COUNT),
                getTargetData(analyticsType === ANALYTICS_TYPE_PRICE ? TARGET_PRODUCTS_PRICE : TARGET_PRODUCTS_COUNT)
              )"
              :tooltip-title="analyticsType === ANALYTICS_TYPE_PRICE ? 'Вал' : 'Покупки'"
            >
              <template #center>
                <b-button-group size="sm">
                  <b-button
                    variant="outline-info"
                    @click="$emit('remove-period')"
                  >-</b-button>
                  <b-button
                    variant="outline-info"
                    @click="$emit('add-period')"
                  >+</b-button>
                </b-button-group>
              </template>
              <template #right>
                <b-form-radio-group
                  name="column-1"
                  size="sm"
                  v-model="analyticsType"
                >
                  <b-form-radio :value="ANALYTICS_TYPE_PRICE">Вал</b-form-radio>
                  <b-form-radio :value="ANALYTICS_TYPE_COUNT">Покупки</b-form-radio>
                </b-form-radio-group>
              </template>
            </wide-bar-chart>
          </b-col>

          <b-col md="2">
            <percents-table
              class="h-100"
              title="Каналы продаж"
              :labels="$array.getValues(appGroups)"
              :data="$array.sumArrays(
                getLastAnalyticsDataForAppGroups(analyticsType === ANALYTICS_TYPE_PRICE ? ANALYTICS_TICKETS_PRICE : ANALYTICS_TICKETS_COUNT),
                getLastAnalyticsDataForAppGroups(analyticsType === ANALYTICS_TYPE_PRICE ? ANALYTICS_PRODUCTS_PRICE : ANALYTICS_PRODUCTS_COUNT),
              )"
            >
            </percents-table>
          </b-col>

          <b-col md="2">
            <small-line-chart
              class="h-100"
              title="Бар/Кино"
              y-min="0"
              y-max="100"
              y-step="50"
              y-label="%"
              :data="$array.convertToPercents(
                $array.divideArrays(
                  getAnalyticsData(analyticsType === ANALYTICS_TYPE_PRICE ? ANALYTICS_PRODUCTS_PRICE : ANALYTICS_PRODUCTS_COUNT),
                  getAnalyticsData(analyticsType === ANALYTICS_TYPE_PRICE ? ANALYTICS_TICKETS_PRICE : ANALYTICS_TICKETS_COUNT),
                  '',
                  false
                )
              )"
              :periods="formattedPeriods"
            ></small-line-chart>
          </b-col>
        </b-row>

        <h2 class="mt-4 mb-2">Кино</h2>

        <b-row>
          <b-col md="2">
            <small-fill-charts
              class="h-100"
              title1="Вал"
              title2="Покупки"
              :data1="getAnalyticsData(ANALYTICS_TICKETS_PRICE)"
              :data2="getAnalyticsData(ANALYTICS_TICKETS_COUNT)"
              :line-color="'#14CC70'"
              :fill-color="'rgba(20, 204, 112, 0.2)'"
            ></small-fill-charts>
          </b-col>

          <b-col md="6">
            <wide-bar-chart
              class="h-100"
              :periods="formattedPeriods"
              :actual-data="getAnalyticsData(analyticsType === ANALYTICS_TYPE_PRICE ? ANALYTICS_TICKETS_PRICE : ANALYTICS_TICKETS_COUNT)"
              :target-data="getTargetData(analyticsType === ANALYTICS_TYPE_PRICE ? TARGET_TICKETS_PRICE : TARGET_TICKETS_COUNT)"
              :tooltip-title="analyticsType === ANALYTICS_TYPE_PRICE ? 'Вал' : 'Покупки'"
              :backgroundColor="'rgba(20, 204, 112, 0.2)'"
              :label-color="'#14CC70'"
            >
              <template #center>
                <b-button-group size="sm">
                  <b-button
                    variant="outline-info"
                    @click="$emit('remove-period')"
                  >-</b-button>
                  <b-button
                    variant="outline-info"
                    @click="$emit('add-period')"
                  >+</b-button>
                </b-button-group>
              </template>
              <template #right>
                <b-form-radio-group
                  name="column-2"
                  size="sm"
                  v-model="analyticsType"
                >
                  <b-form-radio :value="ANALYTICS_TYPE_PRICE">Вал</b-form-radio>
                  <b-form-radio :value="ANALYTICS_TYPE_COUNT">Покупки</b-form-radio>
                </b-form-radio-group>
              </template>
            </wide-bar-chart>
          </b-col>

          <b-col md="2">
            <percents-table
              class="h-100"
              title="Каналы продаж"
              :labels="$array.getValues(appGroups)"
              :data="getLastAnalyticsDataForAppGroups(analyticsType === ANALYTICS_TYPE_PRICE ? ANALYTICS_TICKETS_PRICE : ANALYTICS_TICKETS_COUNT)"
            >
            </percents-table>
          </b-col>

          <b-col md="2">
            <small-line-chart
              class="h-100"
              title="Ср. цена"
              y-label=""
              :y-auto-scale="true"
              :data="$array.divideArrays(
                getAnalyticsData(ANALYTICS_TICKETS_PRICE),
                getAnalyticsData(ANALYTICS_TICKETS_COUNT),
              )"
              :periods="formattedPeriods"
            ></small-line-chart>
          </b-col>
        </b-row>

        <h2 class="mt-4 mb-2">Бар</h2>

        <b-row>
          <b-col md="2">
            <small-fill-charts
              class="h-100"
              title1="Вал"
              title2="Покупки"
              :data1="getAnalyticsData(ANALYTICS_PRODUCTS_PRICE)"
              :data2="getAnalyticsData(ANALYTICS_PRODUCTS_COUNT)"
              :fill-color="'rgba(159, 26, 255, 0.2)'"
              :line-color="'#9F1AFF'"
            ></small-fill-charts>
          </b-col>

          <b-col md="6">
            <wide-bar-chart
              class="h-100"
              :periods="formattedPeriods"
              :actual-data="getAnalyticsData(analyticsType === ANALYTICS_TYPE_PRICE ? ANALYTICS_PRODUCTS_PRICE : ANALYTICS_PRODUCTS_COUNT)"
              :target-data="getTargetData(analyticsType === ANALYTICS_TYPE_PRICE ? TARGET_PRODUCTS_PRICE : TARGET_PRODUCTS_COUNT)"
              :tooltip-title="analyticsType === ANALYTICS_TYPE_PRICE ? 'Вал' : 'Покупки'"
              :backgroundColor="'rgba(159, 26, 255, 0.2)'"
              :label-color="'#9F1AFF'"
            >
              <template #center>
                <b-button-group size="sm">
                  <b-button
                    variant="outline-info"
                    @click="$emit('remove-period')"
                  >-</b-button>
                  <b-button
                    variant="outline-info"
                    @click="$emit('add-period')"
                  >+</b-button>
                </b-button-group>
              </template>
              <template #right>
                <b-form-radio-group
                  name="column-3"
                  size="sm"
                  v-model="analyticsType"
                >
                  <b-form-radio :value="ANALYTICS_TYPE_PRICE">Вал</b-form-radio>
                  <b-form-radio :value="ANALYTICS_TYPE_COUNT">Покупки</b-form-radio>
                </b-form-radio-group>
              </template>
            </wide-bar-chart>
          </b-col>

          <b-col md="2">
            <percents-table
              class="h-100"
              title="Каналы продаж"
              :labels="$array.getValues(appGroups)"
              :data="getLastAnalyticsDataForAppGroups(analyticsType === ANALYTICS_TYPE_PRICE ? ANALYTICS_PRODUCTS_PRICE : ANALYTICS_PRODUCTS_COUNT)"
            >
            </percents-table>
          </b-col>

          <b-col md="2">
            <small-line-chart
              class="h-100"
              title="Per cap"
              y-label=""
              :y-auto-scale="true"
              :data="$array.divideArrays(
                getAnalyticsData(ANALYTICS_PRODUCTS_PRICE),
                getAnalyticsData(ANALYTICS_TICKETS_COUNT),
              )"
              :periods="formattedPeriods"
            ></small-line-chart>
          </b-col>
        </b-row>
      </div>
    </template>
  </analytics-sales-provider>
</template>

<script>

import {
  AnalyticsSalesProvider,
  ANALYTICS_TICKETS_PRICE,
  ANALYTICS_TICKETS_COUNT,
  ANALYTICS_PRODUCTS_PRICE,
  ANALYTICS_PRODUCTS_COUNT,
  TARGET_TICKETS_COUNT_PERCENT,
  TARGET_TICKETS_PRICE_PERCENT,
  TARGET_TICKETS_PRICE,
  TARGET_TICKETS_COUNT,
  TARGET_PRODUCTS_PRICE,
  TARGET_PRODUCTS_COUNT,

} from '../../../providers/AnalyticsSalesProvider';

import SmallFillCharts from '../components/SmallFillCharts.vue';
import WideBarChart from '../components/WideBarChart.vue';
import PercentsTable from '../components/PercentsTable.vue';
import SmallLineChart from '../components/SmallLineChart.vue';

const ANALYTICS_TYPE_PRICE = 1;
const ANALYTICS_TYPE_COUNT = 2;

export default {
  components: {
    AnalyticsSalesProvider,
    SmallFillCharts,
    WideBarChart,
    PercentsTable,
    SmallLineChart,
  },
  props: {
    cinemaId: {
      default: '',
    },
    regionId: {
      default: '',
    },
    periods: {
      default: () => [],
    },
    formattedPeriods: {
      default: () => [],
    },
  },
  data() {
    return {
      analyticsType: ANALYTICS_TYPE_PRICE,
      ANALYTICS_TYPE_PRICE,
      ANALYTICS_TYPE_COUNT,
      ANALYTICS_TICKETS_PRICE,
      ANALYTICS_TICKETS_COUNT,
      ANALYTICS_PRODUCTS_PRICE,
      ANALYTICS_PRODUCTS_COUNT,
      TARGET_TICKETS_COUNT_PERCENT,
      TARGET_TICKETS_PRICE_PERCENT,
      TARGET_TICKETS_PRICE,
      TARGET_TICKETS_COUNT,
      TARGET_PRODUCTS_PRICE,
      TARGET_PRODUCTS_COUNT,
    };
  },
};
</script>

<style scoped>
</style>
