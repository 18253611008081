<template>
  <date-range-picker
    ref="picker"
    :opens="opens"
    :control-container-class="classes"
    :date-range="value"
    :locale-data="locale"
    :show-dropdowns="true"
    :ranges="false"
    :disabled="disabled"
    :append-to-body="false"
    @update="onChange($event)"
  >
    <template #input>
      <span
        v-if="value.startDate || value.endDate"
        class="text-primary"
      >{{ $datetime.formatDate(value.startDate) }} - {{ $datetime.formatDate(value.endDate) }}</span>
      <span
        v-else
        class="text-primary"
      >Выбрать период</span>
      <slot></slot>
    </template>
  </date-range-picker>
</template>

<script>
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DateRangePicker from 'vue2-daterange-picker';

export default {
  components: {
    DateRangePicker,
  },
  props: {
    asInput: {
      default: true,
    },
    dateStart: {
      default: null,
    },
    dateEnd: {
      default: null,
    },
    disabled: {
      default: false,
    },
    size: {
      default: 'sm',
    },
    opens: {
      default: 'center',
    },
  },
  data() {
    return {
      locale: {
        direction: 'ltr',
        format: 'dd.mm.yyyy',
        separator: ' - ',
        applyLabel: 'Выбрать',
        cancelLabel: 'Отмена',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        firstDay: 1,
      },
    };
  },
  computed: {
    classes() {
      const classes = ['cursor-pointer'];

      if (this.asInput) {
        classes.push('form-control');

        if (this.size) {
          classes.push(`form-control-${this.size}`);
        }
      }

      return classes.join(' ');
    },

    value() {
      return {
        startDate: this.dateStart,
        endDate: this.dateEnd,
      };
    },
  },
  methods: {
    onChange({ startDate, endDate }) {
      this.$emit('change', {
        dateStart: this.$datetime.convertDateToDbFormat(startDate),
        dateEnd: this.$datetime.convertDateToDbFormat(endDate),
      });
    },
  },
};
</script>
