<template>
  <datepicker
    calendar-button-icon="fa fa-calendar"
    clear-button-icon="fa fa-times"
    :value="value"
    :input-class="inputClass"
    :format="customFormatter"
    :placeholder="placeholder"
    :clear-button="clearButton ? !inline : false"
    :monday-first="true"
    :bootstrap-styling="!inline"
    :calendar-button="!inline"
    :inline="inline"
    :disabled-dates="disabledDates"
    @selected="selected"
  ></datepicker>
</template>

<script>
import moment from 'moment-timezone';

import Datepicker from '../datepicker/Datepicker.vue';

export default {
  components: {
    Datepicker,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    formatOutput: {
      type: String,
      default: 'YYYY-MM-DD 00:00:00',
    },
    disabled: {
      default: false,
    },
    inline: {
      default: false,
    },
    clearButton: {
      default: true,
    },
    disabledDates: {
      default: () => ({}),
    },
    size: {
      default: 'sm',
    },
  },
  computed: {
    inputClass() {
      return this.size === 'sm' ? 'form-control-sm' : '';
    },
  },
  methods: {
    selected(value) {
      this.$emit('selected', value ? moment(value).format(this.formatOutput) : '');
    },

    customFormatter(date) {
      return moment(date).format(this.format);
    },
  },
};
</script>

<style scoped>
.inline-datepicker .input-group {
  display: none
}
</style>
