// eslint-disable-next-line import/prefer-default-export
export const placeholder = {
  PHONE: '+7 (XXX) XXXX XX XX',
  SIMPLE_PHONE: '7XXXXXXXXXXX',
  URL: 'https://example.com',
  EMAIL: 'example@mail.com',
  TIME_RANGE: '01:00-23:59',
  TIME: 'ЧЧ:ММ',
  FTP_URL: 'ftp://login:password@host/path',
};
