<template>
  <cinema-page @loaded="cinemaId = $event.id">
    <template #scroll>
      <div class="px-2">
        <periods-selector
          class="mb-4"
          :count="periodsCount"
          :default-period="PERIOD_QUARTER"
          @change-date="date = $event"
          @change="periods = $event"
          @format="formattedPeriods = $event"
        >
          <b-button-group size="sm" class="ml-3">
            <b-button
              variant="outline-info"
              @click="periodsCount > 2 && periodsCount--"
            >-</b-button>
            <b-button
              variant="outline-info"
              @click="periodsCount++"
            >+</b-button>
          </b-button-group>
        </periods-selector>

        <analytics-market-provider
          :date="date"
          :periods="periods"
          :target-cinema-ids="targetCinemaIds"
          :market-cinemas="marketCinemas"
        >
          <template #default="{
            data: { loading, marketData, cinemasData, referencePeriod },
          }">
            <div>
              <market-share
                class="mb-4"
                :loading="loading"
                :current-cinema="rentrakCinema"
                :market-data="marketData"
                :cinemas-data="cinemasData"
                :rentrak-cinemas="rentrakCinemas"
                :periods="periods"
                :formatted-periods="formattedPeriods"
                :reference-period="referencePeriod"
              ></market-share>

              <market-values
                :loading="loading"
                :current-cinema="rentrakCinema"
                :market-data="marketData"
                :cinemas-data="cinemasData"
                :rentrak-cinemas="rentrakCinemas"
                :periods="periods"
                :formatted-periods="formattedPeriods"
              ></market-values>

              <own-values
                :loading="loading"
                :current-cinema-id="rentrakCinema ? rentrakCinema.id : null"
                :market-data="marketData"
                :cinemas-data="cinemasData"
                :rentrak-cinemas="rentrakCinemas"
                :periods="periods"
                :formatted-periods="formattedPeriods"
              ></own-values>
            </div>
          </template>
        </analytics-market-provider>
      </div>
    </template>
  </cinema-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CinemaPage from '../../../layout/CinemaPage.vue';
import AnalyticsMarketProvider from '../../../providers/AnalyticsMarketProvider';
import PeriodsSelector from '../components/PeriodsSelector.vue';
import MarketShare from './MarketShare.vue';
import MarketValues from './MarketValues.vue';
import OwnValues from './OwnValues.vue';
import { PERIOD_QUARTER } from '../../../utils/periods';

export default {
  components: {
    CinemaPage,
    PeriodsSelector,
    AnalyticsMarketProvider,
    MarketShare,
    MarketValues,
    OwnValues,
  },
  data() {
    return {
      cinemaId: null,
      date: null,
      periodsCount: 6,
      periods: [],
      formattedPeriods: [],
      PERIOD_QUARTER,
    };
  },
  computed: {
    ...mapState('dictionaries/cinemas', {
      cinemas: (state) => state.items,
    }),

    ...mapState('dictionaries/rentrakCinemas', {
      rentrakCinemas: (state) => state.items,
    }),

    rentrakCinema() {
      return this.rentrakCinemas.find((rentrakCinema) => rentrakCinema.cinemaId === this.cinemaId);
    },

    ownCinemas() {
      return this.rentrakCinemas.filter((rentrakCinema) => rentrakCinema.cinemaId);
    },

    marketCinemas() {
      const result = {};

      for (const rentrakCinema of this.ownCinemas) {
        result[rentrakCinema.id] = this.rentrakCinemas.flatMap((_rentrakCinema) => (_rentrakCinema.cityId === rentrakCinema.cityId ? [_rentrakCinema.id] : []));
      }

      return result;
    },

    relatedCinemas() {
      if (!this.rentrakCinema || !this.rentrakCinema.relatedCinemas) {
        return [];
      }

      return this.rentrakCinema.relatedCinemas.map((cinemaId) => this.rentrakCinemas.find((rentrakCinema) => rentrakCinema.id === cinemaId));
    },

    targetCinemaIds() {
      return [...this.relatedCinemas.map((cinema) => cinema.id), ...this.ownCinemas.map((cinema) => cinema.id)];
    },
  },
  created() {
    this.fetchDictionaries();
  },
  methods: {
    ...mapActions('dictionaries/cinemas', {
      fetchCinemas: 'fetchItems',
    }),

    ...mapActions('dictionaries/rentrakCinemas', {
      fetchRentrakCinemas: 'fetchItems',
    }),

    fetchDictionaries() {
      this.fetchCinemas();
      this.fetchRentrakCinemas();
    },
  },
};
</script>

<style scoped>
</style>
