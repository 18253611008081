export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: true,
  },
  {
    key: 'numberPart', // для удобства отображения поиска (с кнопкой стереть)
    type: 'text',
    label: 'Номер',
    filterable: true,
    sortable: false,
  },
  {
    key: 'promocodeGroupId',
    type: 'text',
    label: 'Группа',
    filterable: true,
    sortable: false,
  },
  {
    key: 'useMode',
    type: 'text',
    label: 'Вид промокода',
    filterable: true,
    sortable: false,
  },
  {
    key: 'formula',
    type: 'text',
    label: 'Изменение цены',
    filterable: false,
    sortable: false,
  },
  {
    key: 'activationsCount',
    type: 'text',
    label: 'Количество активаций',
    filterable: false,
    sortable: false,
  },
  {
    key: 'activationsLimit',
    type: 'text',
    label: 'Предел активаций',
    filterable: false,
    sortable: false,
  },
];
