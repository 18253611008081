<template>
  <modal-form-dialog
    ok-title="Скачать"
    title="Эфирная справка"
    size="sm"
    :save="exportAdvertisements"
    :close="() => true"
    :busy="loading"
    @hidden="$emit('hide')"
  >
    <div class="font-weight-bolder mb-2" v-if="sameAdvertisementForAllCinemas">Экспорт кампании по всем городам</div>
    <div v-else-if="advertisementIds.length" class="font-weight-bolder mb-2">Выбрано кампаний: {{advertisementIds.length}}</div>
    <div v-else class="font-weight-bolder mb-2">Экспорт всех кампаний</div>
    <b-form-group
      label="Период:"
      :invalid-feedback="`${errors.dateStart} ${errors.dateEnd}`"
      :state="states.dateStart && states.dateEnd"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="dateStart"
        :disabled="loading"
        :state="states.dateStart"
        @change="changeField('dateStart', $event)"
      ></date-popover>
      <date-popover
        size="sm"
        :value="dateEnd"
        :disabled="loading"
        :state="states.dateEnd"
        @change="changeField('dateEnd', $event)"
      ></date-popover>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox
        :checked="!!withTickets"
        :state="states.withTickets"
        :disabled="loading"
        @change="changeField('withTickets', withTickets === 1 ? 0 : 1)"
      >
        Учитывать количество проданных
      </b-form-checkbox>
      <b-form-checkbox
        v-if="!sameAdvertisementForAllCinemas"
        :checked="!!!oneFile"
        :state="states.oneFile"
        :disabled="loading"
        @change="changeField('oneFile', oneFile === 1 ? 0 : 1)"
      >
        Экспортировать каждую кампанию в отдельный файл
      </b-form-checkbox>
    </b-form-group>
  </modal-form-dialog>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import ModalFormDialog from '../../components/modal/ModalFormDialog.vue';
import DatePopover from '../../components/fields/DatePopover.vue';

export default {
  components: {
    ModalFormDialog,
    DatePopover,
  },
  props: {
    cinema: {
      default: () => ({}),
    },
    initDateStart: {
      default: null,
    },
    initDateEnd: {
      default: null,
    },
    advertisementTypeId: {
      default: null,
    },
    advertisementIds: {
      default: () => [],
    },
    sameAdvertisementForAllCinemas: {
      default: 0,
    },
  },
  data() {
    return {
      visible: true,
      errors: {},
      states: {},
      loading: false,
      dateStart: null,
      dateEnd: null,
      withTickets: 0,
      oneFile: 1,
    };
  },
  watch: {
    initDateStart: {
      handler() {
        this.dateStart = this.initDateStart ? this.$datetime.convertDateToDbFormat(this.initDateStart) : '';
      },
      immediate: true,
    },
    initDateEnd: {
      handler() {
        this.dateEnd = this.initDateEnd ? this.$datetime.convertDateToDbFormat(this.initDateEnd) : '';
      },
      immediate: true,
    },
  },
  methods: {
    changeField(name, value) {
      this.$set(this, name, value);
      this.setError(name, '');
      this.setState(name, null);
    },

    async exportAdvertisements() {
      const dates = `${this.$datetime.formatDate(this.dateStart)}-${this.$datetime.formatDate(this.dateEnd)}`;
      const cinema = `${this.cinema.name}, ${this.cinema.city.name}`;
      const format = this.oneFile ? 'xlsx' : 'zip';
      let filename = 'Эфирная справка';
      if (!this.sameAdvertisementForAllCinemas) {
        filename = `${filename} ${cinema}`;
      }
      filename = `${filename} ${dates}.${format}`;

      this.loading = true;
      this.resetErrors();
      this.resetStates();

      const [err, result] = await this.$store.dispatch('data/advertisements/exportAdvertisements', {
        cinemaId: this.cinema.id,
        advertisementTypeId: this.advertisementTypeId,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        advertisementIds: this.advertisementIds,
        withTickets: this.withTickets,
        oneFile: this.oneFile,
        filename,
        format,
        sameAdvertisementForAllCinemas: this.sameAdvertisementForAllCinemas,
      });

      if (err && err.status === 422 && err?.data?.errors) {
        const errors = err?.data?.errors;
        for (const field in errors) {
          this.setError(field, errors[field].join(', '));
          this.setState(field, false);
        }
      }

      this.loading = false;

      return !err;
    },

    setError(field, value) {
      this.$set(this.errors, field, value);
    },

    resetErrors() {
      this.errors = {};
    },

    setState(field, value) {
      this.$set(this.states, field, value);
    },

    resetStates() {
      this.states = {};
    },
  },
};
</script>
