/* eslint-disable no-return-await */
/* eslint-disable no-restricted-syntax */
import http from './http';
import { API_VERSION } from '../config';
import { createQuery, convertResponse, convertError } from './utilities';

export default async function fetchDictionary(name, { filter }) {
  const query = createQuery({ filter });

  try {
    const response = await http.get(`/${API_VERSION}/dictionaries/${name}?${query}`);
    return [null, convertResponse(response)];
  } catch (error) {
    return convertError(error);
  }
}
