<template>
  <div class="table-container">
    <collection-provider
      collection="documents"
      ref="collection"
      :filter="{ cinemaId }"
      :disable-fetching="!cinemaId"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @item-action="performItemAction($event)"
        >
          <template #cell(name)="data">
            <div>{{data.item.name}}</div>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.document.show"
      create-title="Создание сотрудника"
      edit-title="Редактирование данных сотрудника"
      collection="documents"
      :id="modals.document.id"
      :init="{ cinemaId }"
      @close="modals.document.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <document-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></document-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import CollectionProvider from '../../providers/CollectionProvider';
import DictionariesProvider from '../../providers/DictionariesProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import DocumentForm from './DocumentForm.vue';

import fields from './_documents.fields';
import itemActions from './_documents.itemActions';

export default {
  components: {
    CollectionProvider,
    DictionariesProvider,
    Datatable,
    DocumentForm,
    ModalModelForm,
  },
  props: {
    cinemaId: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
      },
      modals: {
        document: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateDocumentForm() {
      this.modals.document.id = null;
      this.modals.document.show = true;
    },

    openEditDocumentForm(id) {
      this.modals.document.id = id;
      this.modals.document.show = true;
    },

    async removeDocument(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>
.documents {
  padding: 0;
  overflow-y: scroll;
  overflow-y: auto;
}
</style>
