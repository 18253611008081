<template>
  <table
    class="table m-0 border w-100"
    :style="{'opacity': disabled ? 0.5 : 1}"
  >
    <thead>
      <tr>
        <th></th>
        <th class="bg-danger mh-15">&nbsp;</th>
        <th class="bg-warning mh-15">&nbsp;</th>
        <th class="bg-success mh-15">&nbsp;</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="field in ['recency', 'frequency', 'monetary']"
        :key="field"
      >
        <td class="text-center font-weight-semibold cell-info px-2 py-1">{{ getName(field) }}</td>
        <td
          class="text-right px-2 py-1"
          :class="{'cell-value': getMaxValue(field) === getValue(field, 'low')}"
        >{{ getValue(field, 'low').toLocaleString() }}{{ percentage ? '%' : '' }}</td>
        <td
          class="text-right px-2 py-1"
          :class="{'cell-value': getMaxValue(field) === getValue(field, 'normal')}"
        >{{ getValue(field, 'normal').toLocaleString() }}{{ percentage ? '%' : '' }}</td>
        <td
          class="text-right px-2 py-1"
          :class="{'cell-value': getMaxValue(field) === getValue(field, 'high')}"
        >{{ getValue(field, 'high').toLocaleString() }}{{ percentage ? '%' : '' }}</td>
        <td class="text-right px-2 py-1 font-weight-semibold cell-info">{{ percentage ? 100 : total.toLocaleString() }}{{ percentage ? '%' : '' }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const COLUMN_COUNT = 'count(customer_id)';

export default {
  props: {
    items: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
    percentage: {
      default: false,
    },
  },
  computed: {
    total() {
      return this.items
        .reduce((total, _item) => total + +_item[COLUMN_COUNT], 0);
    },
  },
  methods: {
    getName(field) {
      return field.charAt(0).toUpperCase();
    },

    getValue(field, value) {
      const val = this.items
        .filter((_item) => _item[field] === value)
        .reduce((total, _item) => total + +_item[COLUMN_COUNT], 0);

      return this.percentage ? this.calculatePercent(val, this.total) : val;
    },

    getMaxValue(field) {
      return Math.max(this.getValue(field, 'low'), this.getValue(field, 'normal'), this.getValue(field, 'high'));
    },

    roundValue(value, precision = 2) {
      const multiplier = 10 ** (precision || 0);

      return Math.round(value * multiplier) / multiplier;
    },

    calculatePercent(value1, value2) {
      return value2 ? this.roundValue((value1 / value2) * 100) : 0;
    },
  },
};
</script>

<style scoped>
.h-1 {
  height: 1px;
}
.mh-15 {
  min-height: 15px;
}
.cell-info {
  background-color: aliceblue;
}
.cell-value {
  background-color: bisque;
}
.font-weight-semibold {
  font-weight: 500;
}
</style>
