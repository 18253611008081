export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'appId',
    type: 'text',
    label: 'Название',
    filterable: false,
    sortable: false,
    thStyle: {
      width: '160px',
    },
  },
  {
    key: 'token',
    type: 'text',
    label: 'Токен',
    filterable: false,
    sortable: false,
  },
  {
    key: 'updatedAt',
    type: 'text',
    label: 'Дата обновления',
    filterable: false,
    sortable: false,
    thStyle: {
      width: '180px',
    },
  },
];
