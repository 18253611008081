export default () => [
  {
    type: 'button',
    text: 'Добавить ценовую политику',
    variant: 'info',
    icon: 'fa-plus',
    class: 'mr-1',
    action: 'openCreatePriceForm',
    permission: 'prices.edit',
  },
];
