<template>
  <div class="table-container notifications-items">
    <collection-provider
      collection="notifications"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(title)>
            <div class="text-search">
              <b-form-input
                type="text"
                size="sm"
                :disabled="data.loading"
                :value="data.filter.titleLike"
                @change="actions.changeFilter('titleLike', $event)"
              ></b-form-input>
              <span class="trash" @click="actions.changeFilter('titleLike', '')">
                <icon icon="fa-times"></icon>
              </span>
            </div>
          </template>
          <template #filter(settings)>
            <dictionary-provider name="cinemas">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="data.filter.cinemaId"
                  :options="convertCinemas(items)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('cinemaId', $event)"
                >
                  <template #first>
                    <b-form-select-option :value="null"></b-form-select-option>
                  </template>
                </b-form-select>
              </template>
            </dictionary-provider>
          </template>
          <template #filter(enabled)>
            <b-form-select
              size="sm"
              :value="data.filter.isEnabled"
              :options="isEnabled"
              :disabled="data.loading"
              @change="actions.changeFilter('isEnabled', $event)"
            ></b-form-select>
          </template>
          <template #cell(date)="data">
            <notification-period :item="data.item"></notification-period>
          </template>
          <template #cell(enabled)="data">
            {{getNotificationStatusName(data.item.isEnabled)}}
          </template>
          <template #cell(settings)="data">
            <notification-settings :item="data.item"></notification-settings>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.notifications.show"
      create-title="Добавить уведомление"
      edit-title="Редактировать уведомление"
      collection="notifications"
      :id="modals.notifications.id"
      @close="modals.notifications.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <notification-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></notification-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import DictionaryProvider from '../../providers/DictionaryProvider';
import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import NotificationForm from './NotificationForm.vue';
import NotificationPeriod from './NotificationPeriod.vue';
import NotificationSettings from './NotificationSettings.vue';

export default {
  components: {
    DictionaryProvider,
    CollectionProvider,
    ModalModelForm,
    Datatable,
    NotificationForm,
    NotificationSettings,
    NotificationPeriod,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        notifications: {
          show: false,
          id: 0,
        },
      },
      notificationStatuses: [
        {
          value: false,
          text: 'Остановлена',
        },
        {
          value: true,
          text: 'Активна',
        },
      ],
      isEnabled: [
        {
          value: '',
          text: 'Все',
        },
        {
          value: 'true',
          text: 'Активные',
        },
        {
          value: 'false',
          text: 'Остановленные',
        },
      ],
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    getNotificationStatusName(value) {
      return this.notificationStatuses.find((status) => status.value === value)?.text;
    },

    openCreateNotificationForm() {
      this.modals.notifications.id = '';
      this.modals.notifications.show = true;
    },

    async removeCheckedNotifications() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    pauseCheckedNotifications() {
      this.$refs.collection.changeItems(this.datatable.checked, { isEnabled: 0 });
      this.datatable.checked = [];
    },

    resumeCheckedNotifications() {
      this.$refs.collection.changeItems(this.datatable.checked, { isEnabled: 1 });
      this.datatable.checked = [];
    },

    openEditNotificationForm(id) {
      this.modals.notifications.id = id;
      this.modals.notifications.show = true;
    },

    async removeNotification(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    pauseNotification(id) {
      this.$refs.collection.changeItem(id, { isEnabled: 0 });
    },

    resumeNotification(id) {
      this.$refs.collection.changeItem(id, { isEnabled: 1 });
    },

    formatDateTime(date) {
      return datetime.formatDateTime(date);
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        value: cinema.id,
        text: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },
  },
};
</script>
