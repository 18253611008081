<template>
  <div class="p-3 border rounded">
    <div class="d-flex flex-column h-100">
      <div class="font-weight-bold mb-2">{{ title }}</div>
      <div>{{ value }}{{ unit }}</div>
      <div
        :class="['percent', 'mt-', percent > 0 ? 'text-success' : 'text-danger']"
      >{{ sign }}{{ percent }}%</div>
      <div class="flex-grow-1 d-flex">
        <chart-fill
          aspect-ratio="2"
          class="mt-auto"
          :data="data"
          :line-color="lineColor"
          :fill-color="fillColor"
        ></chart-fill>
      </div>
    </div>
  </div>
</template>

<script>
import ChartFill from '../../../components/charts/ChartFill.vue';

export default {
  components: {
    ChartFill,
  },
  props: {
    title: {
      default: '',
    },
    data: {
      default: () => [],
    },
    lineColor: {
      default: '#1A8CFF',
    },
    fillColor: {
      default: 'rgba(26, 141, 255, 0.2)',
    },
    unit: {
      default: '',
    },
  },
  computed: {
    value() {
      if (!this.data.length) {
        return 0;
      }

      return this.data[this.data.length - 1].toLocaleString();
    },

    percent() {
      if (this.data.length < 2) {
        return 0;
      }

      const lastValue = this.data[this.data.length - 1];
      const prevValue = this.data[this.data.length - 2];

      const ratio = prevValue ? (lastValue - prevValue) / prevValue : 0;

      return (ratio * 100).toFixed(2);
    },

    sign() {
      return this.percent > 0 ? '+' : '';
    },
  },
};
</script>

<style scoped>
.percent {
  font-size: 0.9em;
}
.font-weight-bold {
  font-weight: 500 !important;
}
</style>
