var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100 media-panel-groups",class:{ 'media-panel-groups__loading': _vm.loading }},_vm._l((_vm.groupAndScreens),function(group){return _c('group',{key:group.id,attrs:{"group":group},scopedSlots:_vm._u([{key:"group-action",fn:function(){return [_c('b-dropdown',{attrs:{"right":"","size":"sm","variant":"link","toggle-class":"text-decoration-none p-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('icon',{staticClass:"rounded cursor-pointer group__menu-icon",attrs:{"icon":"mdi-dots-horizontal"}})]},proxy:true}],null,true)},[(group.countScreens && group.id !== null)?_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.$emit('update-settings-group', {
              groupName: group.name,
              groupId: group.id,
              settingId: group.mediablockSettingsId
            })}}},[_vm._v("Настроить")]):_vm._e(),_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.$emit('update-group-name', { id: group.id })}}},[_vm._v("Изменить название")]),_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.removeGroup(group.id)}}},[_vm._v("Удалить")])],1)]},proxy:true},{key:"screens",fn:function(){return [_c('container',{attrs:{"group-name":"group","tag":{ value: 'div', props: { class: 'flex-fill pt-2' } },"drop-placeholder":_vm.dropPlaceholderOptions,"get-child-payload":_vm.getScreenByGroupId(group.id)},on:{"drop":function (e) { return _vm.onScreenDrop(group.id, e); }}},_vm._l((group.screens),function(screen){return _c('draggable',{key:screen.id,attrs:{"tag":{ value: 'div', props: { class: 'py-1' } }}},[_c('screen',{attrs:{"screen":screen,"settings":_vm.settings},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('div',{staticClass:"d-flex flex-column justify-content-between align-items-center screen__settings"},[_c('div',{staticClass:"screen__settings-edit cursor-pointer"},[_c('b-dropdown',{attrs:{"right":"","size":"sm","variant":"link","toggle-class":"text-decoration-none p-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('icon',{staticClass:"rounded cursor-pointer screen__menu-icon",attrs:{"icon":"mdi-square-edit-outline"}})]},proxy:true}],null,true)},[(group.id === null)?_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.$emit('update-settings-screen', {
                          screenName: screen.name,
                          screenId: screen.id,
                          settingId: screen.mediablockSettingsId
                        })}}},[_vm._v("Настроить")]):_vm._e(),_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.$emit('update-screen', { id: screen.id })}}},[_vm._v("Изменить название")]),_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.$emit('reboot-screen', { id: screen.id })}}},[_vm._v("Перезагрузить")]),_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.$emit('remove-screen', { id: screen.id })}}},[_vm._v("Удалить")])],1)],1),_c('div',{staticClass:"cursor-pointer screen__settings-screen",on:{"click":function($event){return _vm.$emit('screenshot', { screenId: screen.id })}}},[_c('icon',{staticClass:"rounded cursor-pointer screen__menu-icon",attrs:{"icon":"mdi-television"}})],1)])]},proxy:true}],null,true)})],1)}),1)]},proxy:true}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }