<template>
  <vuescroll
    ref="scroll"
    :ops="options"
    @handle-scroll="handleScroll"
  >
    <slot></slot>
  </vuescroll>
</template>

<script>
import Vuescroll from 'vuescroll';

export default {
  components: {
    Vuescroll,
  },
  data() {
    return {
      endReachedSent: false,
      options: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          minSize: 0.05,
          background: '#c1d7fc',
          onlyShowBarOnScroll: false,
        },
      },
    };
  },
  mounted() {
    this.mountObserver();
  },
  methods: {
    mountObserver() {
      if (!this.$refs?.scroll?.$refs?.scrollContent) {
        return;
      }

      const observer = new MutationObserver(() => {
        this.endReachedSent = false;
      });

      observer.observe(this.$refs?.scroll?.$refs?.scrollContent, {
        childList: true,
        subtree: true,
      });
    },

    handleScroll(vertical, horizontal, nativeEvent) {
      this.$emit('handle-scroll', { vertical, horizontal, nativeEvent });

      if (vertical.process > 0.95 && !this.endReachedSent) {
        this.$emit('end-reached');
        this.endReachedSent = true;
      }
    },

    scrollTo(position, speed, easing) {
      this.$refs.scroll.scrollTo(position, speed, easing);
    },

    scrollBy(position, speed, easing) {
      this.$refs.scroll.scrollBy(position, speed, easing);
    },
  },
};
</script>
