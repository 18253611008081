<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Залы:"
      :invalid-feedback="errors.halls"
      :state="states.halls"
    >
      <toggle-list
        placeholder="Все"
        :options="halls"
        :value="item.halls"
        :disabled="loading"
        @change="updateValue('halls', $event)"
      ></toggle-list>
    </b-form-group>

    <b-form-group
      label="Форматы фильмов:"
      :invalid-feedback="errors.formats"
      :state="states.formats"
    >
      <dictionary-provider name="formats2">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.formats"
            :disabled="loading"
            @change="updateValue('formats', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Ценовые пояса:"
      :invalid-feedback="errors.colorSchemes"
      :state="states.colorSchemes"
    >
      <dictionary-provider name="colorSchemes">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.colorSchemes"
            :disabled="loading"
            @change="updateValue('colorSchemes', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Дни недели:"
      :invalid-feedback="errors.days"
      :state="states.days"
    >
      <toggle-list
        placeholder="Все"
        :options="days"
        :value="item.days"
        :disabled="loading"
        @change="updateValue('days', $event)"
      ></toggle-list>
    </b-form-group>

    <b-form-group
      label="Время:"
      :invalid-feedback="errors.timeStart || errors.timeEnd"
      :state="states.timeStart || states.timeEnd"
    >
      <time-interval
        :value="{from: item.timeStart, to: item.timeEnd}"
        :disabled="loading"
        @change="updateValue('timeStart', $event.from), updateValue('timeEnd', $event.to)"
      ></time-interval>
    </b-form-group>

    <b-form-group
      label="Цена:"
      :invalid-feedback="errors.price"
      :state="states.price"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.price"
        :state="states.price"
        :disabled="loading"
        @change="updateValue('price', $event)"
      ></b-form-input>
    </b-form-group>

    <dictionary-provider name="appGroups">
      <template #default="{ items: appGroups }">
        <app-groups-prices
          class="mt-3"
          :disabled="loading"
          :apps="appGroups"
          :app-prices="item.appGroups"
          @change="updateValue('appGroups', $event)"
        ></app-groups-prices>
      </template>
    </dictionary-provider>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import TimeInterval from '../../components/fields/TimeInterval.vue';
import ToggleList from '../../components/fields/ToggleList.vue';
import AppGroupsPrices from './AppGroupsPrices.vue';

export default {
  components: {
    AppGroupsPrices,
    DictionaryProvider,
    TimeInterval,
    ToggleList,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
    halls: {
      default: () => [],
    },
  },
  data() {
    return {
      days: [
        { id: 1, name: 'ПН' },
        { id: 2, name: 'ВТ' },
        { id: 3, name: 'СР' },
        { id: 4, name: 'ЧТ' },
        { id: 5, name: 'ПТ' },
        { id: 6, name: 'СБ' },
        { id: 7, name: 'ВС' },
      ],
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
