<template>
  <div class="table-container promocodes">
    <collection-provider
      collection="promocodes"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <dictionaries-provider :names="['promocodeGroups', 'appGroups', 'promocodeUseModes']">
          <template #default="{ promocodeGroups, appGroups, promocodeUseModes }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :panel-actions="datatable.panelActions"
              :item-actions="datatable.itemActions"
              :checked="datatable.checked"
              :permissions="$user.permissions"
              @change-checked="datatable.checked = $event"
              @panel-action="performPanelAction($event)"
              @item-action="performItemAction($event)"
            >
              <template #filter(promocodeGroupId)>
                <b-form-select
                  size="sm"
                  :value="data.filter.promocodeGroupId"
                  :options="$array.convertToOptions(promocodeGroups)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('promocodeGroupId', $event)"
                ></b-form-select>
              </template>

              <template #filter(useMode)>
                <b-form-select
                  size="sm"
                  :value="data.filter.promocodeGroupId"
                  :options="$array.convertToOptions(promocodeUseModes, { value: null, text: '' }, 'id')"
                  :disabled="data.loading"
                  @change="actions.changeFilter('useMode', $event)"
                ></b-form-select>
              </template>

              <template #cell(promocodeGroupId)="data">
                {{$array.getItemText(promocodeGroups, data.item.promocodeGroupId)}}
              </template>

              <template #cell(useMode)="data">
                {{$array.getItemText(promocodeUseModes, data.item.promocodeGroup.useMode)}}
              </template>

              <template #cell(formula)="data">
                {{ data.item.promocodeGroup ? data.item.promocodeGroup.formula : '' }}
              </template>

              <template #cell(numberPart)="data">
                {{ data.item.number }}
              </template>

              <template #cell(orderAppGroupId)="data">
                {{$array.getItemText(appGroups, data.item.orderAppGroupId)}}
              </template>
            </datatable>
          </template>
        </dictionaries-provider>
      </template>
    </collection-provider>

    <b-modal
      v-if="modals.promocodeOperations.show"
      centered
      cancel-title="Закрыть"
      size="xl"
      :visible="true"
      :title="modals.promocodeOperations.title"
      :ok-only="true"
      ok-title="Закрыть"
      ok-variant="secondary"
      @hidden="closePromocodeOperations()"
    >
      <promocode-operations
        :id="modals.promocodeOperations.id"
      ></promocode-operations>
    </b-modal>

    <modal-model-form
      v-if="modals.promocode.show"
      collection="promocodes"
      edit-title="Информация по сертификату"
      :id="modals.promocode.id"
      :hide-footer="true"
      @close="modals.promocode.show = false"
    >
      <template #default="{ data }">
        <promocode-info
          v-if="data.item && data.item.id"
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
        ></promocode-info>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import PromocodeInfo from './PromocodeInfo.vue';
import CollectionProvider from '../../providers/CollectionProvider';
import DictionariesProvider from '../../providers/DictionariesProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import PromocodeOperations from './PromocodeOperations.vue';

import { Formatter } from '../../utils/formatter';
import { placeholder } from '../../utils/placeholder';

import fields from './_index.fields';
import itemActions from './_index.itemActions';
import panelActions from './_index.panelActions';

export default {
  components: {
    PromocodeInfo,
    CollectionProvider,
    Datatable,
    ModalModelForm,
    DictionariesProvider,
    PromocodeOperations,
  },
  data() {
    return {
      formatter: Formatter,
      placeholder,
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        promocode: {
          show: false,
          id: '',
        },
        promocodeOperations: {
          show: false,
          id: null,
          title: '',
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openPromocodeForm(id) {
      this.modals.promocode.id = id;
      this.modals.promocode.show = true;
    },

    openPromocodeOperations(id) {
      this.modals.promocodeOperations.show = true;
      this.modals.promocodeOperations.id = id;
      this.modals.promocodeOperations.title = 'Операции по промокоду';
    },

    closePromocodeOperations() {
      this.modals.promocodeOperations.show = false;
      this.modals.promocodeOperations.id = null;
      this.modals.promocodeOperations.title = '';
    },
  },
};
</script>
