<template>
  <div class="p-2">
    <b-form-group
      label="Период:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.dateStart']} ${errors['filter.dateEnd']}`"
      :state="states['filter.dateStart'] && states['filter.dateEnd']"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="filter.dateStart"
        :state="states['filter.dateStart']"
        :disabled="loading"
        @change="setFilter({ field: 'dateStart', value: $event })"
      ></date-popover>
      <date-popover
        size="sm"
        :value="filter.dateEnd"
        :state="states['filter.dateEnd']"
        :disabled="loading"
        @change="setFilter({ field: 'dateEnd', value: $event })"
      ></date-popover>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Выборка:"
      :disabled="loading"
      :invalid-feedback="errors['params.compareDatesBy']"
    >
      <b-form-radio-group
        name="compare-dates-by"
        :state="states['params.compareDatesBy']"
        :checked="params.compareDatesBy"
        :disabled="loading"
        @change="setParam({ field: 'compareDatesBy', value: $event })"
      >
        <b-form-radio value="created_at">По дате продажи</b-form-radio>
        <b-form-radio value="show_date">По дате сеанса</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <div class="mt-4">
      <b-button
        variant="info"
        size="sm"
        :disabled="loading"
        @click="fetchReport({ format: 'html' })"
      >Просмотр</b-button>
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
        @click="printReport()"
      ><icon icon="fa-print"></icon> Печать</b-button>
      <b-dropdown
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
      >
        <template #button-content>
          <icon icon="fa-save"></icon>
        </template>
        <b-dropdown-item
          v-for="format in formats"
          :key="format.value"
          @click="fetchReport({ format: format.value })"
        >{{format.text}}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DatePopover from '../../../components/fields/DatePopover.vue';

import datetime from '../../../utils/datetime';

export default {
  components: {
    DatePopover,
  },
  data() {
    return {
      format: 'xlsx',
      formats: [
        { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'XLSX' },
      ],
    };
  },
  computed: {
    ...mapState(['cinemaId']),

    ...mapState('reports/promocodeGroupsByPeriod', {
      params: (state) => state.params,
      filter: (state) => state.filter,
      loading: (state) => state.loading,
      errors: (state) => state.errors,
      states: (state) => state.states,
    }),
  },
  created() {
    const date = datetime.convertDateToDbFormat(new Date());

    this.setParam({ field: 'cinemaId', value: this.cinemaId });
    this.setParam({ field: 'compareDatesBy', value: 'created_at' });
    this.setFilter({ field: 'dateStart', value: date });
    this.setFilter({ field: 'dateEnd', value: date });
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      setParam: 'reports/promocodeGroupsByPeriod/setParam',
      setFilter: 'reports/promocodeGroupsByPeriod/setFilter',
      fetchReport: 'reports/promocodeGroupsByPeriod/fetchReport',
      reset: 'reports/promocodeGroupsByPeriod/reset',
    }),

    async printReport() {
      const [err, data] = await this.fetchReport({ format: 'html' });

      if (err || !data) {
        return;
      }

      this.$nextTick(() => {
        this.$htmlToPaper('htmlReport');
      });
    },
  },
};
</script>
