<template>
  <cinema-page
    route-name="pushkinsCardEvents2"
    route-param-name="cinemaId"
  >
    <template #scroll="{ cinemaId }">
      <div>
        <collection-provider
          collection="pushkinsCardEvents2"
          ref="collection"
          :filter="{ cinemaId }"
          :disable-fetching="!cinemaId"
        >
          <template #default="{ data, actions }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :permissions="$user.permissions"
              :item-actions="datatable.itemActions"
              :panel-actions="datatable.panelActions"
              @item-action="performItemAction($event)"
              @panel-action="performPanelAction($event)"
            >
              <template #cell(movie)="data">
                <router-link
                  v-if="data.item.movie"
                  :id="`movie_${data.item.movie.id}`"
                  :to="{
                    name: 'movie2',
                    params: {
                      movieId: data.item.movie.id,
                      tab: 'info',
                    },
                  }"
                  target="_blank"
                >
                  {{ data.item.movie.name }}
                </router-link>
              </template>

              <template #cell(name)="data">
                <b-link @click="openPushkinsCardEvent(data.item.id)">{{ data.item.name }}</b-link>
              </template>

              <template #cell(accepted)="data">
                {{ data.item.accepted ? 'Да' : 'Нет' }}
              </template>

              <template #cell(periodStart)="{ item: { periodStart } }">
                {{ periodStart && $datetime.formatDateTime(periodStart) }}
              </template>

              <template #cell(periodEnd)="{ item: { periodEnd } }">
                {{ periodEnd && $datetime.formatDateTime(periodEnd) }}
              </template>
            </datatable>
          </template>
        </collection-provider>
      </div>

      <model-provider
        v-if="modals.pushkinsCardEvent.show"
        collection="pushkinsCardEvents2"
        :id="modals.pushkinsCardEvent.id"
      >
        <template #default="{ data }">
          <b-modal
            centered
            title="Периоды"
            :visible="true"
            :ok-only="true"
            @hidden="modals.pushkinsCardEvent.show = false"
          >
            <b-table
              class="datatable"
              empty-text="Нет данных"
              :items="data.item.periodItems"
              :fields="periodItemsColumns"
              :striped="true"
              :hover="true"
              :no-local-sorting="false"
              :show-empty="true"
            >
              <template #cell(start)="{ item: { start } }">
                {{ start && $datetime.formatDateTime(start) }}
              </template>

              <template #cell(end)="{ item: { end } }">
                {{ end && $datetime.formatDateTime(end) }}
              </template>
            </b-table>
          </b-modal>
        </template>
      </model-provider>
    </template>
  </cinema-page>
</template>

<script>
import CinemaPage from '../../layout/CinemaPage.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModelProvider from '../../providers/ModelProvider';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    Datatable,
    ModelProvider,
  },
  props: {
    cinemaId: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
        panelActions,
      },
      modals: {
        pushkinsCardEvent: {
          show: false,
          id: 0,
        },
      },
      periodItemsColumns: [
        {
          key: 'start',
          label: 'Начало периода',
        },
        {
          key: 'end',
          label: 'Конец периода',
        },
      ],
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    openPushkinsCardEvent(id) {
      this.modals.pushkinsCardEvent.id = id;
      this.modals.pushkinsCardEvent.show = true;
    },
  },
};
</script>

<style scoped>
</style>
