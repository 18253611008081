export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: true,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Наименование',
    filterable: true,
    sortable: true,
  },
  {
    key: 'prefix',
    type: 'text',
    label: 'Префикс',
    filterable: true,
    sortable: true,
  },
  {
    key: 'date',
    type: 'date',
    label: 'Период действия',
    filterable: true,
    sortable: true,
  },
  {
    key: 'settings',
    type: 'text',
    label: 'Настройки',
    filterable: false,
    sortable: false,
  },
  {
    key: 'promocodesCount',
    type: 'text',
    label: 'Количество',
    filterable: false,
    sortable: false,
  },
  {
    key: 'createdAt',
    type: 'date',
    label: 'Дата создания',
    filterable: false,
    sortable: true,
  },
];
