export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    filterable: true,
    sortable: false,
  },
  {
    key: 'releases',
    type: 'text',
    label: 'Релизы',
    filterable: false,
    sortable: false,
  },
  {
    key: 'period',
    type: 'text',
    label: 'Период действия',
    sortable: false,
    filterable: false,
  },
  {
    key: 'color',
    type: 'text',
    label: 'Цвет',
    filterable: false,
    sortable: false,
  },
  {
    key: 'cinemas',
    type: 'text',
    label: 'Кинотеатры',
    filterable: false,
    sortable: false,
  },
  {
    key: 'active',
    type: 'select',
    label: 'Активна',
    sortable: false,
    filterable: true,
  },
];
