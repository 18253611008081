<template>
  <div>
    <b-table
      class="datatable"
      :fields="columns"
      :items="items"
      :busy="loading"
      :show-empty="true"
      :empty-text="loading ? 'Загрузка...' : 'Нет данных'"
    >
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      default: false,
    },
    items: {
      default: () => [],
    },
    fields: {
      default: () => [],
    },
  },
  computed: {
    columns() {
      return [
        {
          key: 'itemChecked',
          label: '',
          sortable: false,
          filterable: false,
          type: 'checkbox',
          tdClass: 'column-checkbox',
        },
        ...this.fields.map((field) => ({ ...field, tdClass: `column-${field.key}` })),
      ];
    },
  },
};
</script>

<style scoped>
.datatable >>> .column-checkbox {
  width: 1px;
  text-align: center;
}
.datatable >>> .column-actions {
  width: 1px;
  text-align: center;
}
.datatable >>> .column-id {
  width: 1px;
  text-align: center;
}
</style>
