<template>
  <div class="p-3">
    <info-card
      class="mb-4"
      title="Общие настройки"
    >
      <info-card-row v-if="item.prices__minPrice">
        <template #label>Минимальная стоимость билета для создаваемых сеансов</template>
        <template #value>{{item.prices__minPrice}}</template>
      </info-card-row>
      <info-card-row v-if="item.mediapanels__loggingEnabled">
        <template #label>Логи медиапанелей</template>
        <template #value>{{item.mediapanels__loggingEnabled == '1' ? 'Да' : 'Нет'}}</template>
      </info-card-row>
      <info-card-row v-if="item.fiscalization__ticketHeader">
        <template #label>Дополнительный заголовок в билете</template>
        <template #value>{{item.fiscalization__ticketHeader}}</template>
      </info-card-row>
      <info-card-row v-if="item.message__noShows">
        <template #label>Сообщение при отсутствии сеансов</template>
        <template #value>{{item.message__noShows}}</template>
      </info-card-row>
      <info-card-row v-if="item.support__workTimeStart">
        <template #label>Время начала работы сл. поддержки</template>
        <template #value>{{item.support__workTimeStart}}</template>
      </info-card-row>
      <info-card-row v-if="item.support__workTimeEnd">
        <template #label>Время окончания работы сл. поддержки</template>
        <template #value>{{item.support__workTimeEnd}}</template>
      </info-card-row>
      <info-card-row v-if="item.sberbank__pushkinsCardTerminalOwner">
        <template #label>Владелец терминалов для приема платежей по Пушкинской карте (ИНН)</template>
        <template #value>{{item.pushkinsCard__terminalOwner}}</template>
      </info-card-row>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings')"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card class="mb-4" title="Настройки QR-ticket">
      <info-card-row v-if="item.qrTicket__beforeShowTimeout">
        <template #label>QR-ticket: видимость сеанса до начала, мин.</template>
        <template #value>{{item.qrTicket__beforeShowTimeout}}</template>
      </info-card-row>

      <info-card-row v-if="item.qrTicket__afterShowTimeout">
        <template #label>QR-ticket: видимость сеанса после окончания, мин.</template>
        <template #value>{{item.qrTicket__afterShowTimeout}}</template>
      </info-card-row>

      <info-card-row v-if="item.qrTicket__openedShowBeforeTimeout">
        <template #label>QR-ticket: период прохода до начала сеанса, мин.</template>
        <template #value>{{item.qrTicket__openedShowBeforeTimeout}}</template>
      </info-card-row>

      <info-card-row v-if="item.qrTicket__openedShowAfterTimeout">
        <template #label>QR-ticket: период прохода после начала сеанса, мин.</template>
        <template #value>{{item.qrTicket__openedShowAfterTimeout}}</template>
      </info-card-row>

      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'qrticket'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card class="mb-4" title="Настройки SMS">
      <info-card-row v-if="item.sms__disabled">
        <template #label>Отключить SMS для внешних источников</template>
        <template #value>{{item.sms__disabled == '1' ? 'да' : 'нет'}}</template>
      </info-card-row>
      <info-card-row v-if="item.bonusSystem__smsUrl">
        <template #label>Используемый SMS-центр</template>
        <template #value>{{item.bonusSystem__smsUrl}}</template>
      </info-card-row>
      <info-card-row v-if="item.bonusSystem__smsLogin">
        <template #label>Логин</template>
        <template #value>{{item.bonusSystem__smsLogin}}</template>
      </info-card-row>
      <info-card-row v-if="item.bonusSystem__smsPassword">
        <template #label>Пароль</template>
        <template #value>{{item.bonusSystem__smsPassword}}</template>
      </info-card-row>
      <info-card-row v-if="item.bonusSystem__enabledCustomerApproval">
        <template #label>Подтверждать создание клиента</template>
        <template #value>{{item.bonusSystem__enabledCustomerApproval == '1' ? 'да' : 'нет'}}</template>
      </info-card-row>
      <info-card-row v-if="item.bonusSystem__enabledOrderApproval">
        <template #label>Подтверждать списание бонусов</template>
        <template #value>{{item.bonusSystem__enabledOrderApproval == '1' ? 'да' : 'нет'}}</template>
      </info-card-row>
      <info-card-row v-if="item.bonusSystem__smsTimeout">
        <template #label>Время ожидания повторной отправки смс (сек)</template>
        <template #value>{{item.bonusSystem__smsTimeout}}</template>
      </info-card-row>
      <info-card-row v-if="item.bonusSystem__smsCodeTemplate">
        <template #label>Шаблон для отправки кода</template>
        <template #value>{{item.bonusSystem__smsCodeTemplate}}</template>
      </info-card-row>
      <info-card-row v-if="item.bonusSystem__smsOrderTemplate">
        <template #label>Шаблон для отправки заказа</template>
        <template #value>{{item.bonusSystem__smsOrderTemplate}}</template>
      </info-card-row>
      <dictionary-provider
        v-if="item.sms__orderAppGroups !== '[]'"
        name="appGroups"
      >
        <template #default="{ items: appGroups, loading: appGroupsLoading }">
          <info-card-row v-if="!appGroupsLoading && appGroups.length">
            <template #label>Выбранные группы</template>
            <template #value>{{ convertToText(item.sms__orderAppGroups, appGroups) }}</template>
          </info-card-row>
        </template>
      </dictionary-provider>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'sms'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card class="mb-4" title="Идентификация в системе PRO.Культура">
      <info-card-row v-if="item.proculture__organizationId">
        <template #label>Идентификатор организации</template>
        <template #value>{{item.proculture__organizationId}}</template>
      </info-card-row>
      <info-card-row v-if="item.proculture__placeId">
        <template #label>Идентификатор кинотеатра</template>
        <template #value>{{item.proculture__placeId}}</template>
      </info-card-row>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'proculture'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card class="mb-4" title="Настройки сервиса фискализации">
      <info-card-row v-if="item.fiscalization__qkkmApiKey">
        <template #label>Ключ сервиса фискализации</template>
        <template #value>{{item.fiscalization__qkkmApiKey}}</template>
      </info-card-row>
      <info-card-row v-if="item.fiscalization__ofdClientContact">
        <template #label>Клиент для фискализации полной оплаты</template>
        <template #value>{{item.fiscalization__ofdClientContact}}</template>
      </info-card-row>
      <info-card-row v-if="item.fiscalization__ticketsUseAgent">
        <template #label>Продажа билетов по агентскому договору</template>
        <template #value>{{item.fiscalization__ticketsUseAgent == '1' ? 'да' : 'нет'}}</template>
      </info-card-row>
      <info-card-row v-if="item.fiscalization__barItemsUseAgent">
        <template #label>Продажа товаров бара по агентскому договору</template>
        <template #value>{{item.fiscalization__barItemsUseAgent == '1' ? 'да' : 'нет'}}</template>
      </info-card-row>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'fiscal'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card class="mb-4" title="Настройки честного знака">
      <info-card-row>
        <template #label>Токен доступа</template>
        <template #value>{{item.trueApi__token}}</template>
      </info-card-row>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'trueApi'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card class="mb-4" title="Настройки ЕГАИС">
      <info-card-row>
        <template #label>Адрес УТМ</template>
        <template #value>{{item.egais__utmAddress}}</template>
      </info-card-row>
      <info-card-row>
        <template #label>ФСРАР ИД</template>
        <template #value>{{item.egais__fsrarId}}</template>
      </info-card-row>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'egais'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card
      class="mb-4"
      title="Настройки для отчетов"
    >
      <info-card-row>
        <template #label>Отчетное лицо в ежедневном посеансовом отчете</template>
        <template #value>
          <model-provider
            collection="users"
            :id="item.reports__dailyShowsPersonId"
          >
            <template #default="{ data: { item: user } }">
              {{user.name}}
            </template>
          </model-provider>
        </template>
      </info-card-row>
      <info-card-row v-if="item.reports__raoRate">
        <template #label>Процент отчислений в РАО</template>
        <template #value>{{item.reports__raoRate}}%</template>
      </info-card-row>
      <info-card-row v-if="item.reports__raoContractId">
        <template #label>Номер договора РАО</template>
        <template #value>{{item.reports__raoContractId}}</template>
      </info-card-row>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'report'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card class="mb-4" title="Настройки для системы ЕКБ">
      <info-card-row v-if="item.reports__ekbId">
        <template #label>Идентификатор кинотеатра</template>
        <template #value>{{item.reports__ekbId}}</template>
      </info-card-row>
      <info-card-row v-if="item.reports__ekbLogin">
        <template #label>Логин</template>
        <template #value>{{item.reports__ekbLogin}}</template>
      </info-card-row>
      <info-card-row v-if="item.reports__ekbPassword">
        <template #label>Пароль</template>
        <template #value>{{item.reports__ekbPassword}}</template>
      </info-card-row>
      <info-card-row v-if="item.reports__ekbRegionId">
        <template #label>Регион</template>
        <template #value>
          <dictionary-provider name="ekbRegions">
            <template #default="{ items }">
              {{ $array.getItemText(items, +item.reports__ekbRegionId) }}
            </template>
          </dictionary-provider>
        </template>
      </info-card-row>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'ekb'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card class="mb-4" title="Настройки для системы Rentrak">
      <info-card-row v-if="item.reports__rentrakFtpCredentials">
        <template #label>Параметры соединения</template>
        <template #value>{{item.reports__rentrakFtpCredentials}}</template>
      </info-card-row>
      <info-card-row v-if="item.reports__rentrakCircuitId">
        <template #label>Идентификатор сети</template>
        <template #value>{{item.reports__rentrakCircuitId}}</template>
      </info-card-row>
      <info-card-row v-if="item.reports__rentrakCircuitName">
        <template #label>Название сети в системе Rentrak</template>
        <template #value>{{item.reports__rentrakCircuitName}}</template>
      </info-card-row>
      <info-card-row v-if="item.reports__rentrakTheatreNo">
        <template #label>Идентификатор кинотеатра</template>
        <template #value>{{item.reports__rentrakTheatreNo}}</template>
      </info-card-row>
      <info-card-row v-if="item.reports__rentrakTheatreName">
        <template #label>Название кинотеатра в системе Rentrak</template>
        <template #value>{{item.reports__rentrakTheatreName}}</template>
      </info-card-row>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'rentrak'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card class="mb-4" title="Бонусная система">
      <info-card-row v-if="item.bonusSystem__enabled">
        <template #label>Использовать бонусную систему</template>
        <template #value>{{item.bonusSystem__enabled == '1' ? 'да' : 'нет'}}</template>
      </info-card-row>
      <info-card-row v-if="item.bonusSystem__enabledMixedMode">
        <template #label>Использовать одновременное списание и начисление бонусов на остаток</template>
        <template #value>{{item.bonusSystem__enabledMixedMode == '1' ? 'да' : 'нет'}}</template>
      </info-card-row>
      <info-card-row v-if="item.bonusSystem__minTicketPrice">
        <template #label>Минимальная стоимость билета в заказе при оплате бонусами</template>
        <template #value>{{item.bonusSystem__minTicketPrice}}</template>
      </info-card-row>
      <info-card-row v-if="item.bonusSystem__accumulationPercent">
        <template #label>Процент начисления бонусов</template>
        <template #value>{{item.bonusSystem__accumulationPercent}}</template>
      </info-card-row>
      <info-card-row v-if="item.cinemaRequestSecret">
        <template #label>Ключ для доступа к API</template>
        <template #value>{{item.cinemaRequestSecret}}</template>
      </info-card-row>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'bonuses'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card class="mb-4" title="Настройки для мобильного приложения">
      <info-card-row v-if="item.contacts__phone">
        <template #label>Телефон</template>
        <template #value>{{item.contacts__phone}}</template>
      </info-card-row>
      <info-card-row v-if="item.contacts__email">
        <template #label>Email</template>
        <template #value>{{item.contacts__email}}</template>
      </info-card-row>
      <info-card-row v-if="item.documents__termOfUseUrl">
        <template #label>Ссылка на пользовательское соглашение</template>
        <template #value>
          <a :href="item.documents__termOfUseUrl" target="_blank">Открыть ссылку</a>
        </template>
      </info-card-row>
      <info-card-row v-if="item.documents__privacyPolicyUrl">
        <template #label>Ссылка на политику конфиденциальности</template>
        <template #value>
          <a :href="item.documents__privacyPolicyUrl" target="_blank">Открыть ссылку</a>
        </template>
      </info-card-row>
      <info-card-row v-if="item.documents__onlineSalesRulesUrl">
        <template #label>Ссылка на условия online продаж билета</template>
        <template #value>
          <a :href="item.documents__onlineSalesRulesUrl" target="_blank">Открыть ссылку</a>
        </template>
      </info-card-row>
      <info-card-row v-if="item.documents__cinemaUsingRulesUrl">
        <template #label>Ссылка на правила посещения кинотеатра</template>
        <template #value>
          <a :href="item.documents__cinemaUsingRulesUrl" target="_blank">Открыть ссылку</a>
        </template>
      </info-card-row>
      <info-card-row v-if="item.documents__bonusesSystemRulesUrl">
        <template #label>Ссылка на правила системы лояльности</template>
        <template #value>
          <a :href="item.documents__bonusesSystemRulesUrl" target="_blank">Открыть ссылку</a>
        </template>
      </info-card-row>
      <info-card-row v-if="item.mobileApp__androidLink">
        <template #label>Ссылка на android приложение</template>
        <template #value>
          <a :href="item.mobileApp__androidLink" target="_blank">Открыть ссылку</a>
        </template>
      </info-card-row>
      <info-card-row v-if="item.mobileApp__iosLink">
        <template #label>Ссылка на ios приложение</template>
        <template #value>
          <a :href="item.mobileApp__iosLink" target="_blank">Открыть ссылку</a>
        </template>
      </info-card-row>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'mobile-app'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <info-card class="mb-4" title="Настройки VPN-API">

      <info-card-row v-if="item.vpnApi__cinemaUrl">
        <template #label>Адрес API кинотеатра</template>
        <template #value>{{ item.vpnApi__cinemaUrl }}</template>
      </info-card-row>

      <info-card-row v-if="item.vpnApi__networkUrl">
        <template #label>Адрес API сети</template>
        <template #value>{{ item.vpnApi__networkUrl }}</template>
      </info-card-row>

      <info-card-row v-if="item.vpnApi__connectTimeout">
        <template #label>Таймаут соединения (сек)</template>
        <template #value>{{ item.vpnApi__connectTimeout }}</template>
      </info-card-row>

      <info-card-row v-if="item.vpnApi__secret">
        <template #label>Секретный ключ</template>
        <template #value>{{ item.vpnApi__secret }}</template>
      </info-card-row>

      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'vpn'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>

    </info-card>

    <info-card class="mb-4" title="Настройки внешних продаж">

      <info-card-row>
        <template #label>Максимальное число билетов в одном заказе</template>
        <template #value>{{ item.extApi__maxOrderTickets }}</template>
      </info-card-row>

      <info-card-row>
        <template #label>Количество минут после начала сеанса, в течение которых разрешена покупка билета</template>
        <template #value>{{ item.extApi__createOrderTimeout }}</template>
      </info-card-row>

      <info-card-row>
        <template #label>Количество минут после начала сеанса, в течение которых разрешен возврат билетов</template>
        <template #value>{{ item.extApi__refundOrderTimeout }}</template>
      </info-card-row>

      <info-card-row>
        <template #label>Количество минут, предоставляемых пользователю для оплаты заказа</template>
        <template #value>{{ item.extApi__paymentTimeout }}</template>
      </info-card-row>

      <info-card-row>
        <template #label>Префикс для строкового представления идентификатора заказа</template>
        <template #value>{{ item.extApi__orderIdPrefix }}</template>
      </info-card-row>

      <info-card-row>
        <template #label>Мерчант-аккаунт для приема платежей</template>
        <template #value>
          <dictionary-provider name="merchantAccounts">
            <template #default="{ items }">
              {{ $array.getItemText(items, +item.extApi__merchantAccountId) }}
            </template>
          </dictionary-provider>
        </template>
      </info-card-row>

      <info-card-row>
        <template #label>Мерчант-аккаунт для приема платежей через СБП</template>
        <template #value>
          <dictionary-provider name="merchantAccounts">
            <template #default="{ items }">
              {{ $array.getItemText(items, +item.extApi__sbpMerchantAccountId) }}
            </template>
          </dictionary-provider>
        </template>
      </info-card-row>

      <info-card-row>
        <template #label>Мерчант-аккаунт для приема платежей по пушкинской карте</template>
        <template #value>
          <dictionary-provider name="merchantAccounts">
            <template #default="{ items }">
              {{ $array.getItemText(items, +item.extApi__pushkinsCardMerchantAccountId) }}
            </template>
          </dictionary-provider>
        </template>
      </info-card-row>

      <info-card-row>
        <template #label>Мерчант-аккаунт для приема платежей по пушкинской карте для культурных мероприятий</template>
        <template #value>
          <dictionary-provider name="merchantAccounts">
            <template #default="{ items }">
              {{ $array.getItemText(items, +item.extApi__pushkinsCardCulturalEventMerchantAccountId) }}
            </template>
          </dictionary-provider>
        </template>
      </info-card-row>

      <dictionary-provider
        name="appGroups"
      >
        <template #default="{ items: appGroups, loading: appGroupsLoading }">
          <info-card-row v-if="!appGroupsLoading && appGroups.length">
            <template #label>Группы приложений, для которых будет отправляться СМС с описанием заказа</template>
            <template #value>{{ convertToText(item.extApi__smsOrderAppGroups, appGroups) }}</template>
          </info-card-row>
        </template>
      </dictionary-provider>

      <info-card-row>
        <template #label>Шаблон СМС с описанием заказа</template>
        <template #value>{{ item.extApi__smsOrderTemplate }}</template>
      </info-card-row>

      <info-card-row>
        <template #label>Секретный ключ для подписи идентификатора заказа</template>
        <template #value>{{ item.extApi__secret }}</template>
      </info-card-row>

      <info-card-row>
        <template #label>Включить отправку проверочных смс-кодов</template>
        <template #value>{{item.extApi__smsCodeEnabled == '1' ? 'да' : 'нет'}}</template>
      </info-card-row>

      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'ext-api'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>

    </info-card>

    <info-card class="mb-4" title="Настройки агентского договора">

      <info-card-row v-if="item.agencyContract__number">
        <template #label>Номер агентского договора</template>
        <template #value>{{ item.agencyContract__number }}</template>
      </info-card-row>

      <info-card-row v-if="item.agencyContract__date">
        <template #label>Дата агентского договора</template>
        <template #value>{{ item.agencyContract__date }}</template>
      </info-card-row>

      <info-card-row v-if="item.agencyContract__signer">
        <template #label>Подписант</template>
        <template #value>{{ item.agencyContract__signer }}</template>
      </info-card-row>

      <info-card-row v-if="item.agencyContract__fee">
        <template #label>Размер вознаграждения за отчетный период</template>
        <template #value>{{ item.agencyContract__fee }}</template>
      </info-card-row>

      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'agencyContract'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>

    </info-card>

    <info-card class="mb-4" title="Настройки сервисов IIKO">
      <info-card-row>
        <template #label>Использовать сервисы IIKO</template>
        <template #value>{{item.iikoServer__enabled == '1' ? 'да' : 'нет'}}</template>
      </info-card-row>
      <info-card-row v-if="item.iikoServer__url">
        <template #label>Адрес хоста</template>
        <template #value>{{item.iikoServer__url}}</template>
      </info-card-row>
      <info-card-row v-if="item.iikoServer__login">
        <template #label>Логин</template>
        <template #value>{{item.iikoServer__login}}</template>
      </info-card-row>
      <info-card-row v-if="item.iikoServer__password">
        <template #label>Пароль</template>
        <template #value>{{item.iikoServer__password}}</template>
      </info-card-row>
      <div class="d-flex col-md-4 justify-content-end">
        <b-button
          size="sm"
          variant="outline-info"
          class="mr-1"
          @click="$emit('edit-settings', {scrollTo: 'iiko'})"
        >
          <icon icon="fa-pencil"></icon> Редактировать
        </b-button>
      </div>
    </info-card>

    <dictionary-provider
        name="rentrakCinemas"
      >
        <template #default="{ items: rentrakCinemas, loading: rentrakCinemasLoading }">
          <info-card
            v-if="!rentrakCinemasLoading && rentrakCinemas.length"
           class="mb-4"
           title="Настройки аналитики"
          >
            <info-card-row v-if="item.iboe__cinemaId">
              <template #label>Данный кинотеатр в аналитике</template>
              <template #value>{{ $array.getItemText(rentrakCinemas, +item.iboe__cinemaId) }}</template>
            </info-card-row>
            <info-card-row>
              <template #label>Связанные кинотеатры</template>
              <template #value>{{ convertToText(item.iboe__relatedCinemaIds, rentrakCinemas) }}</template>
            </info-card-row>
            <div class="d-flex col-md-4 justify-content-end">
              <b-button
                size="sm"
                variant="outline-info"
                class="mr-1"
                @click="$emit('edit-settings', {scrollTo: 'analytics_rentrak'})"
              >
                <icon icon="fa-pencil"></icon> Редактировать
              </b-button>
            </div>
          </info-card>
        </template>
      </dictionary-provider>
  </div>
</template>

<script>
import InfoCard from '../../components/card/InfoCard.vue';
import InfoCardRow from '../../components/card/InfoCardRow.vue';
import ModelProvider from '../../providers/ModelProvider';
import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    InfoCard,
    InfoCardRow,
    ModelProvider,
    DictionaryProvider,
  },
  props: {
    item: {
      default: () => ({}),
    },
  },
  methods: {
    convertToText(ids, options) {
      if (!ids) {
        return [];
      }

      if (!Array.isArray(ids)) {
        ids = JSON.parse(ids);
      }

      return this.$array.convertToText(options.filter((option) => !!~ids.indexOf(option.id)));
    },
  },
};
</script>

<style scoped>
.mw-200 {
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
