/* eslint-disable no-return-await */
/* eslint-disable no-restricted-syntax */
import humps from 'humps';
import http from './http';
import { API_VERSION } from '../config';
import { createQuery, convertResponse, convertError } from './utilities';

export async function fetchShows({
  filter, params,
} = {}) {
  const query = createQuery({
    filter, params,
  });

  try {
    const response = await http.get(`/${API_VERSION}/shows?${query}`);
    return [null, convertResponse(response)];
  } catch (error) {
    return convertError(error);
  }
}

export async function getShow(id, params) {
  try {
    const query = createQuery({
      params,
    });

    const response = await http.get(`/${API_VERSION}/shows/${id}?${query}`);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function createShows(shows, dates) {
  try {
    const response = await http.post(`/${API_VERSION}/shows`, { shows, dates });
    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}

export async function updateShows(shows, dates) {
  try {
    const response = await http.put(`/${API_VERSION}/shows`, { shows, dates });
    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}

export async function deleteShows(shows, dates) {
  try {
    const response = await http.delete(`/${API_VERSION}/shows`, { data: { shows, dates } });
    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}

export async function clearShows({
  cinemaId, hallId, date, dateStart, dateEnd,
}) {
  try {
    const filter = {};

    if (cinemaId) {
      filter.cinema_id = cinemaId;
    }

    if (hallId) {
      filter.hall_id = hallId;
    }

    if (date) {
      filter.date = date;
    }

    if (dateStart && dateEnd) {
      filter.date_start = dateStart;
      filter.date_end = dateEnd;
    }

    const query = createQuery({
      filter,
    });

    const response = await http.post(`/${API_VERSION}/clear_shows?${query}`);
    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}

export async function copyShows({
  hallIdFrom, hallIdTo, dateFrom, dateTo, hallIdsFrom, cinemaIdsTo,
}) {
  try {
    const response = await http.post(`/${API_VERSION}/copy_shows`, humps.decamelizeKeys({
      hallIdFrom, hallIdTo, dateFrom, dateTo, hallIdsFrom, cinemaIdsTo,
    }));
    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}

export async function toggleLocked(showId, locked) {
  try {
    const response = await http.post(`/${API_VERSION}/toggle_locked?show_id=${showId}`, {
      locked: locked ? 1 : 0,
    });

    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}

export async function exportShows(params) {
  try {
    const response = await http.post(`/${API_VERSION}/export_shows`, params, {
      responseType: 'blob',
    });

    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}
