<template>
  <b-nav class="banners-tabs" pills>
    <b-nav-item
      :active="!bannerTypeId"
      @click="$emit('select', 0)"
    >Баннеры</b-nav-item>
    <b-nav-item
      :active="1 === bannerTypeId"
      @click="$emit('select', 1)"
    >Порядок воспроизведения</b-nav-item>
  </b-nav>
</template>

<script>
export default {
  props: {
    bannerTypeId: {
      default: null,
    },
  },
};
</script>

<style scoped>
.banners-tabs >>> .nav-link {
  padding: 0.25rem 0.5rem;
}
</style>
