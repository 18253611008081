export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    sortable: false,
    filterable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    sortable: true,
    filterable: false,
  },
  {
    key: 'file',
    type: 'text',
    label: 'Файл',
    sortable: false,
    filterable: false,
  },
];
