/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export default {
  namespaced: true,
  state: {
    title: '',
    breadcrumbs: [],
    params: {},
  },
  actions: {
  },
  mutations: {
    setTitle(state, title) {
      state.title = title;
      state.params = {};
    },
    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
      state.params = {};
    },
    setParams(state, params) {
      state.params = {
        ...state.params,
        ...params,
      };
    },
  },
  getters: {
    title: (state) => {
      const searchRegexp = /\{([_0-9a-zA-Z]+)\}/g;

      const withoutParams = state.title.replace(/<params>.+<\/params>/g, '');

      const withPlaceholders = state.title.replace(/(<params>)|(<\/params>)/g, '');

      if (withPlaceholders.match(searchRegexp)) {
        const title = withPlaceholders.replace(searchRegexp, (match, _item) => (state.params[_item] ? state.params[_item] : `{${_item}}`));

        if (!title.match(searchRegexp)) {
          return title;
        }
      }

      return withoutParams;
    },

    breadcrumbs: (state) => state.breadcrumbs.map((item) => {
      const copy = { ...item };

      for (const key in copy) {
        copy[key] = copy[key].replace(/\{([_0-9a-zA-Z]+)\}/g, (match, _item) => (state.params[_item] ? state.params[_item] : ''));
      }

      return copy;
    }).filter((item) => item),
  },
};
