var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('model-provider',{ref:"movies",attrs:{"collection":"movies2","id":_vm.movieId},on:{"loaded":function($event){return _vm.setTitle($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [(data.loading)?_c('div',{staticClass:"movie__loader",class:{ active: data.loading }},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):(data.item && data.item.id)?_c('layout',{scopedSlots:_vm._u([{key:"panel",fn:function(){return [_c('buttons',{attrs:{"item":data.item},on:{"favorite-movie":function($event){return _vm.openModalFavoriteMovie(data.item)},"edit-movie":function($event){return _vm.openEditForm(data.item.id)}}})]},proxy:true},{key:"image",fn:function(){return [_c('div',{staticClass:"movie__poster"},[_c('movie-image',{attrs:{"image":data.item.posterUrl,"alt":data.item.name}}),(data.item.trailerUrl)?_c('div',{staticClass:"movie__play",on:{"click":function($event){return _vm.openModalVideo(data.item.trailerUrl)}}},[_c('svg',{attrs:{"data-v-aa60413e":"","viewBox":"0 0 14 16","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"data-v-aa60413e":"","fill-rule":"evenodd","clip-rule":"evenodd","d":"M0.666664 2.30988C0.666664 0.731703 2.40897 -0.224724 3.74042 0.622563L12.6818 6.31255C13.9168 7.09846 13.9168 8.90129 12.6818 9.6872L3.74042 15.3772C2.40897 16.2245 0.666664 15.268 0.666664 13.6899V2.30988Z"}})])]):_vm._e()],1)]},proxy:true},{key:"header",fn:function(){return [_c('movie-header',{attrs:{"name":data.item.name,"name-en":data.item.nameEn,"age-limit":data.item.ageLimit,"releases":data.item.releases}},[_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"d-inline-block"},[_c('ul',{staticClass:"custom-tabs"},_vm._l((_vm.tabs),function(_tab){return _c('li',{key:_tab.id,class:{'active': _vm.tab === _tab.id}},[_c('router-link',{attrs:{"to":{
                        name: 'movie2',
                        params: {
                          movieId: _vm.movieId,
                          tab: _tab.id,
                        },
                        query: _vm.$route.query,
                      }}},[_vm._v(" "+_vm._s(_tab.name)+" ")])],1)}),0)])])])]},proxy:true},{key:"content",fn:function(){return [(_vm.tab === 'info')?_c('info',{attrs:{"movie":data.item}}):_vm._e(),(_vm.tab === 'settings')?_c('settings',{attrs:{"movie":data.item}}):_vm._e(),(_vm.tab === 'repertory')?_c('repertory',{attrs:{"movie":data.item}}):_vm._e(),(_vm.tab === 'trailers')?_c('trailers',{attrs:{"trailers":data.item.movieTrailers}}):_vm._e(),(_vm.tab === 'files')?_c('files-movie',{attrs:{"files":data.item.movieFiles,"screenshots":data.item.movieScreenshots}}):_vm._e(),(_vm.tab === 'screenshots')?_c('screenshots',{attrs:{"screenshots":data.item.movieScreenshots}}):_vm._e(),(_vm.tab === 'pushkinsCardEvents')?_c('pushkins-card-events',{attrs:{"pushkins-card-events":data.item.pushkinsCardEvents,"cinemas":_vm.cinemas}}):_vm._e()]},proxy:true}],null,true)}):_vm._e()]}}])}),(_vm.modals.favorite.show)?_c('movies-favorite-modal',{attrs:{"movie":_vm.modals.favorite.movie},on:{"saved":function($event){return _vm.$refs.movies.load()},"close":_vm.closeModalFavoriteMovie}}):_vm._e(),(_vm.modals.movie.show)?_c('modal-model-form',{attrs:{"create-title":"Новый фильм","edit-title":"Редактирование фильма","collection":"movies2","id":_vm.modals.movie.id,"init":{ useBonuses: true }},on:{"close":function($event){_vm.modals.movie.show = false},"saved":function($event){return _vm.$refs.movies.load()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var data = ref.data;
                      var actions = ref.actions;
return [_c('movie-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,false,855252933)}):_vm._e(),(_vm.modals.video.show)?_c('b-modal',{attrs:{"centered":"","size":"xl","visible":true,"hide-header":true,"hide-footer":true},on:{"hidden":function($event){return _vm.closeModalVideo()}}},[_c('div',{staticClass:"h-100 w-100 text-center",on:{"click":function($event){return _vm.closeModalVideo()}}},[_c('video',{staticClass:"mw-100",attrs:{"src":_vm.modals.video.trailerUrl,"autoplay":"true","loop":"true","muted":"true","controls":"true"},domProps:{"muted":true}})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }