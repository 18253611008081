<template>
  <div class="p-2">
    <b-form-group
      label="Период:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.dateStart']} ${errors['filter.dateEnd']}`"
      :state="states['filter.dateStart'] && states['filter.dateEnd']"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="filter.dateStart"
        :state="states['filter.dateStart']"
        :disabled="loading"
        @change="setFilter({ field: 'dateStart', value: $event })"
      ></date-popover>
      <date-popover
        size="sm"
        :value="filter.dateEnd"
        :state="states['filter.dateEnd']"
        :disabled="loading"
        @change="setFilter({ field: 'dateEnd', value: $event })"
      ></date-popover>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Группы товаров:"
      :disabled="loading || !params.cinemaId"
      :invalid-feedback="errors['filter.productReportGroupIds']"
    >
      <dictionary-provider
        name="productReportGroups"
        :filter="{ cinemaId: params.cinemaId }"
        :disable-fetching="!params.cinemaId"
      >
        <template #default="{ items: productReportGroups, loading: productReportGroupsLoading }">
          <toggle-list
            :options="productReportGroups"
            :value="convertToDictionaryFromIds(filter.productReportGroupIds, productReportGroups)"
            :disabled="!params.cinemaId || loading || productReportGroupsLoading"
            @change="setFilter({ field: 'productReportGroupIds', value: convertFromDictionaryToIds($event) })"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Учет типа суток:"
      :disabled="loading"
      :invalid-feedback="errors['params.dayType']"
    >
      <b-form-radio-group
        name="day-type"
        :state="states['filter.dayType']"
        :checked="filter.dayType"
        :disabled="loading"
        @change="setFilter({ field: 'dayType', value: $event })"
      >
        <b-form-radio value="business">Бизнес-сутки</b-form-radio>
        <b-form-radio value="calendar">Календарные</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Детализация:"
      :disabled="loading"
      :invalid-feedback="errors['filter.detailReport']"
    >
      <b-form-checkbox
        :checked="filter.detailReport"
        :disabled="loading"
        :value="1"
        :unchecked-value="0"
        :state="states['filter.detailReport']"
        @change="setFilter({ field: 'detailReport', value: $event })"
      >Отобразить все поля</b-form-checkbox>
    </b-form-group>

    <div class="mt-4">
      <b-button
        variant="info"
        size="sm"
        :disabled="loading"
        @click="fetchReport({ format: 'html' })"
      >Просмотр</b-button>
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
        @click="printReport()"
      ><icon icon="fa-print"></icon> Печать</b-button>
      <b-dropdown
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
      >
        <template #button-content>
          <icon icon="fa-save"></icon>
        </template>
        <b-dropdown-item
          v-for="format in formats"
          :key="format.value"
          @click="fetchReport({ format: format.value })"
        >{{format.text}}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DictionaryProvider from '../../../providers/DictionaryProvider';
import DatePopover from '../../../components/fields/DatePopover.vue';
import ToggleList from '../../../components/fields/ToggleList.vue';

import datetime from '../../../utils/datetime';

export default {
  components: {
    DictionaryProvider,
    DatePopover,
    ToggleList,
  },
  data() {
    return {
      format: 'xlsx',
      formats: [
        { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'XLSX' },
      ],
    };
  },
  computed: {
    ...mapState(['cinemaId']),

    ...mapState('reports/consolidatedSalesOwner', {
      params: (state) => state.params,
      filter: (state) => state.filter,
      loading: (state) => state.loading,
      errors: (state) => state.errors,
      states: (state) => state.states,
    }),
  },
  created() {
    const date = datetime.convertDateToDbFormat(new Date());

    this.setParam({ field: 'cinemaId', value: this.cinemaId });

    this.setFilter({ field: 'detailReport', value: 0 });
    this.setFilter({ field: 'productReportGroupIds', value: [] });
    this.setFilter({ field: 'dateStart', value: date });
    this.setFilter({ field: 'dateEnd', value: date });
    this.setFilter({ field: 'dayType', value: 'business' });

    // детализация источников продаж
    this.setFilter({ field: 'onlineIds', value: [] });
    this.setFilter({ field: 'kiosksIds', value: [] });
    this.setFilter({ field: 'detailProductGroupsIds', value: [] });
    this.setFilter({ field: 'tickets', value: 0 });
    this.setFilter({ field: 'products', value: 0 });
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      setParam: 'reports/consolidatedSalesOwner/setParam',
      setFilter: 'reports/consolidatedSalesOwner/setFilter',
      fetchReport: 'reports/consolidatedSalesOwner/fetchReport',
      reset: 'reports/consolidatedSalesOwner/reset',
    }),

    convertToDictionaryFromIds(ids, items) {
      if (!ids || !items) {
        return [];
      }

      return ids.map((id) => items.find((item) => item.id === id));
    },

    convertFromDictionaryToIds(items) {
      if (!items) {
        return [];
      }

      return items.map((item) => item.id);
    },

    async printReport() {
      const [err, data] = await this.fetchReport({ format: 'html' });

      if (err || !data) {
        return;
      }

      this.$nextTick(() => {
        this.$htmlToPaper('htmlReport');
      });
    },
  },
};
</script>
