<template>
  <analytics-provider
    table="releases_sales"
    :params="{
      cinema_id: cinemaId,
      date_start: dateStart,
      date_end: dateEnd,
    }"
    :page="page"
    :limit="limit"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :disabled="!dateStart || !dateEnd"
  >
    <template #default="{ items, total, loading }">
      <div>
        <b-table
          class="datatable border"
          :fields="fields"
          :items="items"
          :striped="true"
          :hover="false"
          :no-local-sorting="true"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :busy="loading"
          :show-empty="true"
          :empty-text="loading ? 'Загрузка...' : 'Нет данных'"
          @sort-changed="sortBy = $event.sortBy, sortDesc = !!$event.sortDesc"
        >
          <template #cell(release_name)="data">
            {{ data.item.movie_name }} {{ data.item.format_name }}
          </template>

          <template #cell(occupation)="data">
            <span
              :class="[data.item.occupation < 5 || data.item.occupation > 70 ? 'text-danger' : '']"
            >{{ data.item.occupation }}</span>
          </template>

          <template #cell(first_show_date)="data">
            {{ getRentalWeek(data.item.first_show_date, data.item.last_show_date) }}
          </template>
        </b-table>

        <b-pagination
          v-if="total > limit"
          class="pagination"
          size="sm"
          align="center"
          :value="page"
          :total-rows="total"
          :per-page="limit"
          @change="page = $event"
        ></b-pagination>
      </div>
    </template>
  </analytics-provider>
</template>

<script>

import AnalyticsProvider from '../../../providers/AnalyticsProvider';

export default {
  components: {
    AnalyticsProvider,
  },
  props: {
    cinemaId: {
      default: '',
    },
    dateStart: {
      default: '',
    },
    dateEnd: {
      default: '',
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'release_name',
          label: 'Название релиза',
          sortable: false,
        },
        {
          key: 'tickets_price',
          label: 'Вал, руб',
          sortable: true,
          thStyle: {
            width: '160px',
          },
        },
        {
          key: 'average_tickets_price',
          label: 'Наработка, руб',
          sortable: true,
          thStyle: {
            width: '180px',
          },
        },
        {
          key: 'occupation',
          label: 'Заполняемость, %',
          sortable: true,
          thStyle: {
            width: '260px',
          },
        },
        {
          key: 'tickets_price_percent', // чтобы работала сортировка по доле
          label: 'Доля, %',
          sortable: true,
          thStyle: {
            width: '140px',
          },
        },
        {
          key: 'shows_count',
          label: 'Сеансы',
          sortable: true,
          thStyle: {
            width: '160px',
          },
        },
        {
          key: 'first_show_date', // имитируем сортировку по неделям
          label: 'Неделя',
          sortable: true,
          thStyle: {
            width: '140px',
          },
        },
        {
          key: 'average_tickets_count',
          label: 'Наработка, билеты',
          sortable: true,
          thStyle: {
            width: '200px',
          },
        },
      ],
      page: 1,
      limit: 20,
      sortBy: 'occupation',
      sortDesc: true,
      total_price: 0,
    };
  },
  methods: {
    getRentalWeek(firstDate, lastDate) {
      return this.$datetime.getRentalWeek(new Date(), firstDate, lastDate) || '-';
    },
  },
};
</script>

<style scoped>
.pagination >>> .page-link {
  border-width: 0;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  color: #000;
}
.pagination >>> .active .page-link {
  color: #FFF;
}
</style>
