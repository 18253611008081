export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditCertificateGroupForm',
        permission: 'certificates.edit',
      },
      {
        type: 'button',
        text: item.allowActivation ? 'Закрыть продажи' : 'Возобновить продажи',
        action: item.allowActivation ? 'stopSales' : 'resumeSales',
        permission: 'banners.edit',
      },
      {
        type: 'button',
        text: 'Экспорт',
        action: 'exportCertificateGroup',
        args: item,
        permission: 'certificates.view',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeCertificateGroup',
        permission: 'certificates.edit',
      },
    ],
  },
];
