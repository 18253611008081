<template>
  <cinema-page>
    <template #fixed>
      <multi-view class="report report-consolidatedSalesOnMovie">
        <template #side>
          <params></params>
        </template>

        <template #panel>
          <b-button
            size="sm"
            variant="outline-info"
            :to="{ name: 'reportIndex' }"
          >
            <span slot="text">
              <icon icon="fa-chevron-left"></icon> Назад
            </span>
          </b-button>
        </template>

        <template #content>
          <div
            v-if="report"
            class="p-3 w-100"
            v-html="report"
            id="htmlReport"
            @click="handleClick"
          ></div>
        </template>
      </multi-view>
    </template>
  </cinema-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CinemaPage from '../../../layout/CinemaPage.vue';
import MultiView from '../../../layout/MultiView.vue';
import Params from './Params.vue';

export default {
  components: {
    CinemaPage,
    MultiView,
    Params,
  },
  computed: {
    ...mapState('reports/consolidatedSalesOnMovie', {
      report: (state) => state.report,
    }),
  },
  methods: {
    ...mapActions({
      setSort: 'reports/consolidatedSalesOnMovie/setSort',
      fetchReport: 'reports/consolidatedSalesOnMovie/fetchReport',
    }),

    handleClick(e) {
      const isSortable = e?.target?.dataset?.sortable;
      const sortingName = e?.target?.dataset?.sortingName;
      const sortingOrder = e?.target?.dataset?.sortingOrder;

      if (!isSortable || !sortingName) {
        return;
      }

      this.setSort({
        by: sortingName,
        desc: !sortingOrder || sortingOrder === 'asc',
      });

      this.fetchReport({ format: 'html' });
    },
  },
};
</script>

<style scoped>
</style>
