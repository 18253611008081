export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'cinemas',
    type: 'text',
    label: 'Кинотеатры',
    sortable: false,
    filterable: true,
  },
  {
    key: 'movieId',
    type: 'text',
    label: 'Фильм',
    sortable: false,
    filterable: true,
  },
  {
    key: 'formats',
    type: 'text',
    label: 'Форматы',
    sortable: false,
    filterable: true,
  },
  {
    key: 'distributor',
    type: 'text',
    label: 'Дистрибьютор',
    sortable: false,
    filterable: true,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Номер договора',
    sortable: true,
    filterable: true,
  },
  {
    key: 'dates',
    type: 'date',
    label: 'Период',
    sortable: false,
    filterable: true,
  },
  {
    key: 'rate',
    type: 'text',
    label: 'Процент',
    sortable: false,
    filterable: false,
  },
  {
    key: 'includingVat',
    type: 'text',
    label: 'НДС',
    sortable: false,
    filterable: false,
  },
  {
    key: 'paymentTerms',
    type: 'text',
    label: 'Срок оплаты',
    sortable: false,
    filterable: false,
  },
  {
    key: 'original',
    type: 'text',
    label: 'Оригинал',
    sortable: false,
    filterable: false,
  },
];
