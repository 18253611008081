<template>
  <div>
    <template v-for="(action, index) in actions">
      <actions-dropdown
        :key="index"
        v-if="action.type === 'dropdown'"
        :class="[action.class]"
        :variant="action.variant"
        :disabled="disabled || !!action.disabled"
        :size="buttonSize"
        :icon="action.icon"
        :text="action.text"
        :items="action.items"
        @click="click(...arguments)"
      ></actions-dropdown>

      <actions-button
        :key="index"
        v-if="action.type === 'button'"
        :class="[action.class]"
        :variant="action.variant"
        :disabled="disabled || !!action.disabled"
        :size="buttonSize"
        :icon="action.icon"
        :text="action.text"
        @click="action.action && $emit('click', action.action, action.args)"
      ></actions-button>
    </template>
  </div>
</template>

<script>
import ActionsDropdown from './Dropdown.vue';
import ActionsButton from './Button.vue';

export default {
  name: 'actions',
  components: {
    ActionsDropdown,
    ActionsButton,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    buttonSize: {
      type: String,
      default: 'sm',
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    click(action, args) {
      this.$emit('click', action, args);
    },
  },
};
</script>
