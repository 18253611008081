export default [
  0x724CF9,
  0x564592,
  0x4D9DE0,
  0xF433AB,
  0xE952DE,
  0x75B9BE,
  0x247BA0,
  0x655A7C,
  0xFE5D26,
  0x4AAD52,
  0xD81159,
  0xFF9505,
  0x5171A5,
  0xDB5A42,
  0xBF3100,
  0x134611,
  0x080357,
  0x2C4251,
  0x11270B,
  0xD78A43,
  0xF0F66E,
  0x064789,
  0x488B49,
  0x0A2239,
  0x33658A,
  0x0D3B66,
  0x29274C,
  0x53A2BE,
  0x5C415D,
  0x3BB273,
  0xCA7DF9,
  0x5DD9C1,
  0xF2A359,
  0x7E52A0,
  0xB47EB3,
  0xFFFC31,
  0x5BC0EB,
  0xEF3054,
  0xF4AFAB,
  0x5F0F40,
  0x97CC04,
  0xE15554,
  0x669D31,
  0xB76300,
  0xC97B84,
  0xFFA0FD,
  0xE1BC29,
  0xD16666,
  0xFFC15E,
  0xEEB902,
];
