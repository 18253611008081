<template>
  <cinema-page>
    <template #fixed>
      <multi-view class="report report-consolidated-sales">
        <template #side>
          <params></params>
        </template>

        <template #panel>
          <b-button
            size="sm"
            variant="outline-info"
            :to="{ name: 'reportIndex' }"
          >
            <span slot="text">
              <icon icon="fa-chevron-left"></icon> Назад
            </span>
          </b-button>
        </template>

        <template #content>
          <div
            v-if="report"
            class="p-3 w-100"
            v-html="report"
            id="htmlReport"
            @click="handleClick"
          ></div>
        </template>
      </multi-view>
    </template>
  </cinema-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CinemaPage from '../../../layout/CinemaPage.vue';
import MultiView from '../../../layout/MultiView.vue';
import Params from './Params.vue';

export default {
  components: {
    CinemaPage,
    MultiView,
    Params,
  },
  computed: {
    ...mapState('reports/consolidatedSalesOwner', {
      report: (state) => state.report,
      params: (state) => state.params,
      filter: (state) => state.filter,
    }),
  },
  methods: {
    ...mapActions({
      setParam: 'reports/consolidatedSalesOwner/setParam',
      setFilter: 'reports/consolidatedSalesOwner/setFilter',
      fetchReport: 'reports/consolidatedSalesOwner/fetchReport',
      reset: 'reports/consolidatedSalesOwner/reset',
    }),

    handleClick(e) {
      const $target = e.target;
      const group = $target?.dataset?.group;
      const groupValue = $target?.dataset?.value;

      if (!group) {
        return;
      }

      let i;
      let filterArray = [];
      switch (group) {
        case 'tickets':
          this.setFilter({ field: 'tickets', value: +!this.filter.tickets });
          break;

        case 'products':
          this.setFilter({ field: 'products', value: +!this.filter.products });
          break;

        case 'online':
          filterArray = [...this.filter.onlineIds];
          i = filterArray.indexOf(groupValue);
          if (i !== -1) {
            filterArray.splice(i, 1);
          } else {
            filterArray.push(groupValue);
          }

          this.setFilter({ field: 'onlineIds', value: filterArray });
          break;

        case 'kiosks':
          filterArray = [...this.filter.kiosksIds];
          i = filterArray.indexOf(groupValue);
          if (i !== -1) {
            filterArray.splice(i, 1);
          } else {
            filterArray.push(groupValue);
          }

          this.setFilter({ field: 'kiosksIds', value: filterArray });
          break;
      }

      this.fetchReport({ format: 'html' });
    },
  },
};
</script>

<style scoped>
@import '../../../assets/reports/consolidated-sales.css';
</style>
