<template>
  <cinema-provider
    route-name="halls"
    route-param-name="cinemaId"
    @loaded="setCinemaId($event)"
  >
    <template #default>
      <dictionaries-provider
        :names="['seatTypes', 'colorSchemes']"
        @seat-types-loaded="scheme.setSeatTypes($event)"
      >
        <template #default="{ seatTypes, colorSchemes }">
          <model-provider
            collection="halls"
            :id="hallId"
            ref="hall"
            @loaded="setSchemeInfo($event)"
            @error="notifyErrors($event)"
          >
            <template #default="{ data }">
              <multi-view>
                <template #side>
                  <hall-form
                    class="p-2"
                    :item="data.item"
                    :dirty="data.dirty"
                    :loading="data.loading"
                    :states="data.states"
                    :errors="data.errors"
                    @change="setValue($event.field, $event.value)"
                  ></hall-form>
                </template>

                <template #panel>
                  <buttons
                    :color-schemes="colorSchemes"
                    :seat-types="seatTypes"
                    :has-seats="scheme.hasSeats()"
                    :has-selected-seats="scheme.hasSeats(selectedItems)"
                    :has-selected-gaps="scheme.hasGaps(selectedItems)"
                    :disabled="data.loading"
                    @save="saveHall()"
                    @cancel="openCinemaHalls()"
                    @add-rows="modals.addRows.show = true"
                    @add-road="scheme.addRoad()"
                    @reverse-seats="scheme.reverseSeats()"
                    @reverse-rows="scheme.reverseRows()"
                    @change-seats-type="scheme.changeSeatsType(selectedItems, $event), resetSelectedItems()"
                    @change-seats-color-scheme="scheme.changeSeatsColorSchemeId(selectedItems, $event), resetSelectedItems()"
                    @remove-seats="scheme.removeSeats(selectedItems), resetSelectedItems()"
                    @add-gap-left="scheme.addGapLeft(selectedItems), resetSelectedItems()"
                    @add-gap-right="scheme.addGapRight(selectedItems), resetSelectedItems()"
                  ></buttons>
                </template>

                <template
                  v-if="$user.can('prices.view') && hallId"
                  #subpanel
                >
                  <color-scheme-prices
                    :cinemaId="cinemaId"
                    :hallId="hallId"
                    :color-schemes="colorSchemes"
                    :scheme="scheme.scheme"
                  ></color-scheme-prices>
                </template>

                <template #content>
                  <scheme
                    :color-schemes="colorSchemes"
                    :seat-types="seatTypes"
                    :scheme="scheme.scheme"
                    :selected="selectedItems"
                    @change-scheme="scheme.replaceScheme($event)"
                    @change-selected="setSelectedItems($event)"
                    @add-seat="addSeat($event)"
                    @remove-row="scheme.removeRow($event)"
                    @remove-road="scheme.removeRow($event)"
                  ></scheme>
                </template>

                <template #portal>
                  <modal-add-rows
                    v-if="modals.addRows.show"
                    @hide="modals.addRows.show = false"
                    @add-rows="addRows($event)"
                  ></modal-add-rows>
                </template>
              </multi-view>
            </template>
          </model-provider>
        </template>
      </dictionaries-provider>
    </template>
  </cinema-provider>
</template>

<script>
import { toIntOrNull } from '../../utils/cast';
import { confirmDialog } from '../../utils/dialogs';

import MultiView from '../../layout/MultiView.vue';

import HallForm from './HallForm.vue';
import Buttons from './Buttons.vue';
import Scheme from './Scheme.vue';
import ModalAddRows from './ModalAddRows.vue';
import ColorSchemePrices from './ColorSchemePrices.vue';

import CinemaProvider from '../../providers/CinemaProvider.vue';
import ModelProvider from '../../providers/ModelProvider';
import DictionariesProvider from '../../providers/DictionariesProvider';

import SchemeFormatter from './Scheme';

import {
  MAX_ROWS_COUNT,
  MAX_SEATS_COUNT,
} from '../../constants';

export default {
  components: {
    MultiView,
    HallForm,
    Buttons,
    Scheme,
    ModalAddRows,
    CinemaProvider,
    ModelProvider,
    DictionariesProvider,
    ColorSchemePrices,
  },
  data() {
    return {
      selectedItems: [],
      cinemaId: null,
      hallId: null,
      scheme: new SchemeFormatter(),
      dirty: false,
      modals: {
        addRows: {
          show: false,
        },
      },
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'scheme.dirty': function () {
      this.dirty = true;
    },
  },
  created() {
    this.hallId = toIntOrNull(this.$route.params.id);
  },
  async beforeRouteLeave(to, from, next) {
    if (this.dirty) {
      if (await confirmDialog('Отменить изменения?')) {
        next();
      } else {
        next(false);
        return;
      }
    }

    next();
  },
  methods: {
    setSelectedItems(items) {
      this.selectedItems = items;
    },

    resetSelectedItems() {
      this.selectedItems = [];
    },

    addRows({ rowsCount, seatsCount, type }) {
      const allowedRowsCount = MAX_ROWS_COUNT - this.scheme.getRowsCount();

      if (allowedRowsCount <= 0) {
        return;
      }

      this.scheme.addRows({
        rowsCount: Math.min(allowedRowsCount, rowsCount),
        seatsCount,
        type,
      });
    },

    addSeat(rowIndex) {
      const allowedSeatsCount = MAX_SEATS_COUNT - this.scheme.getSeatsCount(rowIndex);

      if (allowedSeatsCount <= 0) {
        return;
      }

      this.scheme.addSeat(rowIndex);
    },

    setCinemaId(cinema) {
      this.cinemaId = cinema.id;
    },

    openCinemaHalls() {
      this.$router.push({ name: 'cinemas', params: { cinemaId: this.cinemaId, tab: 'halls' } });
    },

    setSchemeInfo(item) {
      this.scheme.setScheme(item.scheme);
      this.scheme.setStartCorner(item.startCorner);
    },

    setValue(field, value) {
      this.$refs.hall.setValue(field, value);
      this.dirty = true;
    },

    async saveHall() {
      if (!this.$refs.hall.item.cinemaId) {
        this.setValue('cinemaId', this.cinemaId);
      }

      this.setValue('scheme', this.scheme.scheme);
      this.setValue('startCorner', this.scheme.startCorner);
      this.setValue('placesCount', this.scheme.getPlacesCount());

      const result = await this.$refs.hall.save();

      if (result) {
        this.dirty = false;

        this.$notify({
          type: 'success',
          text: 'Данные сохранены!',
        });

        this.openCinemaHalls();
      }
    },

    notifyErrors(errors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const field of Object.keys(errors)) {
        this.$notify({
          type: 'error',
          text: errors[field],
        });
      }
    },
  },
};
</script>
