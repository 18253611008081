export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    sortable: true,
    filterable: true,
  },
  {
    key: 'legalEntityId',
    type: 'text',
    label: 'Юр. лицо',
    sortable: true,
    filterable: true,
  },
  {
    key: 'address',
    type: 'text',
    label: 'Адрес',
    sortable: true,
    filterable: true,
  },
  {
    key: 'useProxy',
    type: 'text',
    label: 'Прокси',
    sortable: true,
    filterable: true,
  },
  {
    key: 'networkDevice',
    type: 'text',
    label: 'Онлайн продажи',
    sortable: true,
    filterable: true,
  },
  {
    key: 'cashierDeviceProtocolId',
    type: 'text',
    label: 'Протокол',
    sortable: true,
    filterable: true,
  },
  {
    key: 'using',
    type: 'text',
    label: 'Использование',
    sortable: false,
    filterable: false,
  },
];
