export default () => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditCashierDeviceForm',
        permission: 'cashier_devices.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeCashierDevice',
        permission: 'cashier_devices.edit',
      },
    ],
  },
];
