var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container notifications-items"},[_c('collection-provider',{ref:"collection",attrs:{"collection":"notifications"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"panel-actions":_vm.datatable.panelActions,"item-actions":_vm.datatable.itemActions,"checked":_vm.datatable.checked},on:{"change-checked":function($event){_vm.datatable.checked = $event},"panel-action":function($event){return _vm.performPanelAction($event)},"item-action":function($event){return _vm.performItemAction($event)}},scopedSlots:_vm._u([{key:"filter(title)",fn:function(){return [_c('div',{staticClass:"text-search"},[_c('b-form-input',{attrs:{"type":"text","size":"sm","disabled":data.loading,"value":data.filter.titleLike},on:{"change":function($event){return actions.changeFilter('titleLike', $event)}}}),_c('span',{staticClass:"trash",on:{"click":function($event){return actions.changeFilter('titleLike', '')}}},[_c('icon',{attrs:{"icon":"fa-times"}})],1)],1)]},proxy:true},{key:"filter(settings)",fn:function(){return [_c('dictionary-provider',{attrs:{"name":"cinemas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":data.filter.cinemaId,"options":_vm.convertCinemas(items),"disabled":data.loading},on:{"change":function($event){return actions.changeFilter('cinemaId', $event)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}})]},proxy:true}],null,true)})]}}],null,true)})]},proxy:true},{key:"filter(enabled)",fn:function(){return [_c('b-form-select',{attrs:{"size":"sm","value":data.filter.isEnabled,"options":_vm.isEnabled,"disabled":data.loading},on:{"change":function($event){return actions.changeFilter('isEnabled', $event)}}})]},proxy:true},{key:"cell(date)",fn:function(data){return [_c('notification-period',{attrs:{"item":data.item}})]}},{key:"cell(enabled)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getNotificationStatusName(data.item.isEnabled))+" ")]}},{key:"cell(settings)",fn:function(data){return [_c('notification-settings',{attrs:{"item":data.item}})]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}])}),(_vm.modals.notifications.show)?_c('modal-model-form',{attrs:{"create-title":"Добавить уведомление","edit-title":"Редактировать уведомление","collection":"notifications","id":_vm.modals.notifications.id},on:{"close":function($event){_vm.modals.notifications.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('notification-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,false,4144521328)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }