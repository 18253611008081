<template>
  <span class="wheelchair">
    <svg
      viewBox="0 0 35 42"
      xmlns="http://www.w3.org/2000/svg"
      :style="`--color: ${color}`"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.00012207 29.0655C0.00012207 24.5214 2.39962 20.674 6.13482 18.594C7.05588 18.0808 8.22164 18.4139 8.73485 19.3349C9.24805 20.256 8.91498 21.4217 7.99392 21.9349C5.44148 23.3556 3.82369 25.9454 3.82369 29.0655C3.82369 33.6469 7.56569 37.3855 12.2015 37.3855C14.7234 37.3855 16.9937 36.2742 18.5368 34.5068L19.1214 33.7557L19.3117 33.4124L19.4408 33.1983L19.5972 32.7837C19.9302 31.781 21.011 31.2372 22.0137 31.5669C23.0163 31.9 23.5601 32.9808 23.2304 33.9834C23.0707 34.466 22.9449 34.7753 22.7648 35.0914C22.5779 35.4177 22.6016 35.3361 22.4317 35.6658L22.3128 35.8629C20.1172 39.0883 16.4024 41.2091 12.2015 41.2091C5.47547 41.2091 0.00012207 35.7779 0.00012207 29.0655Z"/>
      <path d="M14.1016 14.6276H12.1898V12.7149C13.0001 12.7149 14.1016 13.5339 14.1016 14.6276Z"/>
      <path d="M12.1898 14.6276L10.451 14.6141C10.451 13.503 11.1931 12.7183 12.1898 12.7149V14.6276Z"/>
      <path d="M28.8045 35.2442L22.0988 27.8214H12.1915C11.1345 27.8214 10.278 26.965 10.278 25.9079L10.451 14.6141L12.1898 14.6276H14.1016V17.9277V19.9998V22.9998V23.9979H22.9485C23.4889 23.9979 24.0021 24.2256 24.3657 24.6266L30.4257 31.3357L31.8837 30.153C32.7028 29.4868 33.9094 29.6126 34.5721 30.4317C35.2383 31.2507 35.1125 32.4573 34.2934 33.12L31.4283 35.4448C30.6398 36.0871 29.4876 35.9954 28.8045 35.2442Z"/>
      <path d="M21.0001 22.9998H14.1016V19.9998H21.0001V22.9998Z"/>
      <path d="M21.0001 22.9998V19.9998C22.5001 19.9998 22.5001 21.4998 22.5001 21.4998C22.5001 21.4998 22.5001 22.9998 21.0001 22.9998Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2492 0.546649C15.0633 0.509263 17.3778 2.75922 17.4186 5.57337C17.456 8.38751 15.2061 10.702 12.3919 10.7428C9.57776 10.7802 7.26323 8.53026 7.22244 5.71611C7.18506 2.90197 9.43501 0.587434 12.2492 0.546649Z"/>
    </svg>
  </span>
</template>

<script>

export default {
  props: {
    color: {
      default: '#000',
    },
  },
};
</script>

<style lang="scss" scoped>
.wheelchair {
  text-align: center;
  svg {
    fill: var(--color);
    width: 65%;
    vertical-align: text-top;
  }
}
</style>
