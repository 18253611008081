export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: true,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    filterable: true,
    sortable: true,
  },
  {
    key: 'period',
    type: 'text',
    label: 'Период действия',
    sortable: false,
    filterable: false,
  },
  {
    key: 'cinemas',
    type: 'text',
    label: 'Кинотеатры',
    filterable: true,
    sortable: false,
  },
  {
    key: 'type',
    type: 'text',
    label: 'Тип',
    filterable: true,
    sortable: false,
  },
  {
    key: 'active',
    type: 'text',
    label: 'Активен',
    thStyle: {
      width: '200px',
    },
    sortable: false,
    filterable: true,
  },
];
