<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Наименование:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        autofocus
        type="text"
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Заголовок:"
      :invalid-feedback="errors.title"
      :state="states.title"
    >
      <b-form-input
        size="sm"
        :value="item.title"
        :state="states.title"
        :disabled="loading"
        @change="updateValue('title', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Url:"
      :invalid-feedback="errors.url"
      :state="states.url"
    >
      <b-form-input
        size="sm"
        :value="item.url"
        :state="states.url"
        :disabled="loading"
        @change="updateValue('url', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Содержимое тэга title:"
      :invalid-feedback="errors.htmlTitle"
      :state="states.htmlTitle"
    >
      <b-form-textarea
        type="text"
        size="sm"
        :value="item.htmlTitle"
        :state="states.htmlTitle"
        :disabled="loading"
        @change="updateValue('htmlTitle', $event)"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      label="Содержимое тэга description:"
      :invalid-feedback="errors.htmlDescription"
      :state="states.htmlDescription"
    >
      <b-form-textarea
        type="text"
        size="sm"
        :value="item.htmlDescription"
        :state="states.htmlDescription"
        :disabled="loading"
        @change="updateValue('htmlDescription', $event)"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      label="Содержимое страницы:"
      :invalid-feedback="errors.content"
      :state="states.content"
    >
      <v-md-editor
        :value="item.content || ''"
        :state="states.content"
        :disabled="loading"
        :disabled-menus="['h/h1', 'image/upload-image']"
        left-toolbar="undo redo clear | h bold italic quote | ul ol hr | link image"
        right-toolbar="preview sync-scroll fullscreen"
        height="400px"
        @change="updateValue('content', $event)"
      ></v-md-editor>
    </b-form-group>

    <b-form-group
      :disabled="loading"
      :invalid-feedback="errors.includeShows"
      :state="states.includeShows"
    >
      <b-form-checkbox
        :checked="!!item.includeShows"
        :state="states.includeShows"
        :disabled="loading"
        @change="updateValue('includeShows', $event)"
      >
        Отображать сеансы
      </b-form-checkbox>
    </b-form-group>

    <template v-if="item.includeShows">
      <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
        <div class="align-self-end">Фильтры фильмов, для которых отображаются сеансы</div>
      </div>

      <b-form-group
        label="Категории залов:"
        :invalid-feedback="errors.showsHallCategories"
        :state="states.showsHallCategories"
      >
        <dictionary-provider name="hallCategories">
          <template #default="{ items }">
            <toggle-list
              placeholder="Все"
              :options="items"
              :value="item.showsHallCategories"
              :disabled="loading"
              @change="updateValue('showsHallCategories', $event)"
            ></toggle-list>
          </template>
        </dictionary-provider>
      </b-form-group>

      <b-form-group
        label="Возрастное ограничение фильма:"
        :invalid-feedback="errors.showsAgeLimit"
        :state="states.showsAgeLimit"
      >
        <dictionary-provider name="ageLimits">
          <template #default="{ items }">
            <toggle-list
              placeholder="Все"
              :options="items"
              :value="item.showsAgeLimits"
              :disabled="loading"
              @change="updateValue('showsAgeLimits', $event)"
            ></toggle-list>
          </template>
        </dictionary-provider>
      </b-form-group>

      <b-form-group
        label="Форматы фильмов:"
        :invalid-feedback="errors.showsFormats"
        :state="states.showsFormats"
      >
        <dictionary-provider name="formats2">
          <template #default="{ items }">
            <toggle-list
              placeholder="Все"
              :options="items"
              :value="item.showsFormats"
              :disabled="loading"
              @change="updateValue('showsFormats', $event)"
            ></toggle-list>
          </template>
        </dictionary-provider>
      </b-form-group>

      <b-form-group
        label="Количество дней до даты сеанса:"
        :invalid-feedback="errors.showsBeforeFormula"
        :state="states.showsBeforeFormula"
      >
        <price-formula
          :value="item.showsBeforeFormula || ''"
          :operations="['<', '=', '>']"
          :disabled="loading"
          @change="updateValue('showsBeforeFormula', $event)"
        ></price-formula>
      </b-form-group>
    </template>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';
import PriceFormula from '../../components/fields/PriceFormula.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
    PriceFormula,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
    };
  },
  methods: {
    updateValue(field, value) {
      // проверка из-за редактора, шлёт события сразу же
      if (this.item[field] === value) {
        return;
      }

      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
