import Agreements from '../pages/agreements/Index.vue';
import DcpKeys from '../pages/dcp-keys/Index.vue';
import DistributorLegalEntities from '../pages/distributor-legal-entities/Index.vue';
import DistributorReportEmails from '../pages/distributor-report-emails/Index.vue';
import Distributors2 from '../pages/distributors2/Index.vue';
import Full from '../layout/Full.vue';
import Laboratories2 from '../pages/laboratories2/Index.vue';
import PushkinsCardEvents from '../pages/pushkins-card-events/Index.vue';
import PushkinsCardEvents2 from '../pages/pushkins-card-events2/Index.vue';
import Repertory from '../pages/repertory/Index.vue';
import ReserveTypes from '../pages/reserve-types/Index.vue';
import Sales from '../pages/sales/Index.vue';
import Schedule from '../pages/schedule/Index.vue';
import Movie2 from '../pages/movie2/Index.vue';
import Movies2 from '../pages/movies2/Index.vue';

export default {
  path: '/distribution',
  redirect: '/distribution/distributors',
  component: Full,
  children: [
    {
      name: 'distributors2',
      path: 'distributors2/:distributorId?',
      component: Distributors2,
      meta: {
        auth: true,
        title: 'Прокатчики',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Дистрибьюторы' },
          { name: 'Прокатчики' },
        ],
        permissions: ['distributors.view'],
      },
    },
    {
      name: 'laboratories2',
      path: 'laboratories2/:labId?',
      component: Laboratories2,
      meta: {
        auth: true,
        title: 'Лаборатории',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Дистрибьюторы' },
          { name: 'Лаборатории' },
        ],
        permissions: ['labs.view'],
      },
    },
    {
      name: 'distributorReportEmails',
      path: 'distributor-report-emails/:cinemaId?',
      component: DistributorReportEmails,
      meta: {
        auth: true,
        title: 'Адреса для отчетов',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Дистрибьюторы' },
          { name: 'Адреса для отчетов' },
        ],
        scrollable: true,
        permissions: ['distributor_report_emails.view'],
      },
    },
    {
      name: 'repertory',
      path: 'repertory/:cinemaId?',
      component: Repertory,
      meta: {
        auth: true,
        title: 'Планирование',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Планирование' },
        ],
        permissions: ['repertory.view'],
      },
    },
    {
      name: 'schedule',
      path: 'schedule/:cinemaId?',
      component: Schedule,
      meta: {
        auth: true,
        title: 'Расписание сеансов',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Расписание сеансов' },
        ],
        permissions: ['schedule.view'],
      },
    },
    {
      name: 'sales',
      path: 'sales/:cinemaId?',
      component: Sales,
      meta: {
        auth: true,
        title: 'Продажа билетов',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Продажа билетов' },
        ],
        permissions: ['schedule.view'],
      },
    },
    {
      name: 'pushkinsCardEvents',
      path: 'pushkins-card-events/:cinemaId?',
      component: PushkinsCardEvents,
      meta: {
        auth: true,
        title: 'События пушкинской карты',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'События пушкинской карты' },
        ],
        permissions: ['schedule.view'],
      },
    },
    {
      name: 'pushkinsCardEvents2',
      path: 'pushkins-card-events2/:cinemaId?',
      component: PushkinsCardEvents2,
      meta: {
        auth: true,
        title: 'События пушкинской карты',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'События пушкинской карты' },
        ],
        permissions: ['schedule.view'],
      },
    },
    {
      name: 'agreements',
      path: 'agreements/:cinemaId?',
      component: Agreements,
      meta: {
        auth: true,
        title: 'Договоры с дистрибьюторами',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Договоры с дистрибьюторами' },
        ],
        scrollable: true,
        permissions: ['distributor_agreements.view'],
      },
    },
    {
      name: 'dcpKeys',
      path: 'dcp-keys/',
      component: DcpKeys,
      meta: {
        auth: true,
        title: 'Ключи',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Ключи' },
        ],
        permissions: ['dcp_keys.view'],
      },
    },
    {
      name: 'reserveTypes',
      path: 'reserve-types',
      component: ReserveTypes,
      meta: {
        auth: true,
        title: 'Типы брони',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Бронирование' },
          { name: 'Типы брони' },
        ],
        scrollable: true,
        permissions: ['reserve_types.view'],
      },
    },
    {
      name: 'distributorLegalEntities',
      path: 'distributor-legal-entities',
      component: DistributorLegalEntities,
      meta: {
        auth: true,
        title: 'Юридические лица для отчетов',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Дистрибьюторы' },
          { name: 'Юридические лица для отчетовв' },
        ],
        scrollable: true,
        permissions: ['distributor_legal_entities.view'],
      },
    },
    {
      name: 'movies2',
      path: 'movies2/',
      component: Movies2,
      meta: {
        auth: true,
        title: 'Фильмы',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Фильмы' },
        ],
        permissions: ['movies.view'],
      },
    },
    {
      name: 'movie2',
      path: '/movies2/:movieId?/:tab?',
      component: Movie2,
      meta: {
        auth: true,
        title: 'Просмотр фильма',
        breadcrumbs: [
          { name: 'Прокат' },
          { name: 'Фильмы' },
        ],
      },
      permissions: ['movies.view'],
    },
  ],
};
