<template>
  <div>
    <b-dropdown
      variant="outline-info"
      no-caret
      class="segments"
      id="segments"
      ref="dropdown"
      v-click-outside="onClickOutside"
      :size="size"
      :disabled="disabled"
      @hide="hide($event)"
      @hidden="visible = false"
      @shown="visible = true"
    >
      <template v-slot:button-content>
        <span class="segments__name">{{title}}</span>
      </template>

      <b-dropdown-form>
        <template v-for="recency in ['high', 'normal', 'low']">
          <b-button
            v-for="frequency in ['high', 'normal', 'low']"
            :key="`${recency}_${frequency}`"
            variant="outline-info"
            class="m-1"
            :size="size"
            :pressed="isSegmentSelected(recency, frequency)"
            @click="toggleSegment(recency, frequency)"
          >{{ getTitle(recency, frequency) }}</b-button>
        </template>

        <div class="mt-2 mx-1 d-flex w-100">
          <div class="w-33 mx-1">
            <div class="mb-2 text-center">Давность (R)</div>
            <b-button
              v-for="value in ['high', 'normal', 'low']"
              :key="value"
              variant="outline-info"
              class="mb-1 w-100"
              :size="size"
              :pressed="isParamSelected('recency', value)"
              @click="toggleParam('recency', value)"
            >{{ recencyTitles[value] }}</b-button>
          </div>

          <div class="w-33 mx-1">
            <div class="mb-2 text-center">Частота (F)</div>
            <b-button
              v-for="value in ['high', 'normal', 'low']"
              :key="value"
              variant="outline-info"
              class="mb-1 w-100"
              :size="size"
              :pressed="isParamSelected('frequency', value)"
              @click="toggleParam('frequency', value)"
            >{{ frequencyTitles[value] }}</b-button>
          </div>

          <div class="w-33 mx-1">
            <div class="mb-2 text-center">Ценность (M)</div>
            <b-button
              v-for="value in ['high', 'normal', 'low']"
              :key="value"
              variant="outline-info"
              class="mb-1 w-100"
              :size="size"
              :pressed="isParamSelected('monetary', value)"
              @click="toggleParam('monetary', value)"
            >{{ monetaryTitles[value] }}</b-button>
          </div>
        </div>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
import { getNoun } from '../../../utils/string';

export default {
  components: {
  },
  props: {
    titles: {
      default: () => [],
    },
    recencyTitles: {

    },
    frequencyTitles: {

    },
    monetaryTitles: {

    },
    value: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
    size: {
      default: 'sm',
    },
  },
  data() {
    return {
      clickedOutside: false,
      visible: false,
      localValue: [],
    };
  },
  computed: {
    title() {
      let count = this.localValue.filter((item) => Object.keys(item).length > 1).length;

      if (!count && this.localValue.filter((item) => Object.keys(item).length === 1).length) {
        const recencyCount = this.localValue.filter((item) => Object.keys(item).length === 1 && 'recency' in item).length || 1;
        const frequencyCount = this.localValue.filter((item) => Object.keys(item).length === 1 && 'frequency' in item).length || 1;
        const monetaryCount = this.localValue.filter((item) => Object.keys(item).length === 1 && 'monetary' in item).length || 1;

        count = recencyCount * frequencyCount * monetaryCount;
      }

      return `${count} ${getNoun(count, 'сегмент', 'сегмента', 'сегментов')}`;
    },
  },
  watch: {
    value: {
      handler() {
        this.localValue = [...this.value];
      },
      immediate: true,
    },
  },
  methods: {
    getTitle(recency, frequency) {
      return this.titles[`frequency_${frequency}_recency_${recency}`] || 'Без названия';
    },

    onClickOutside() {
      this.clickedOutside = true;
    },

    hide(event) {
      if (!this.clickedOutside) {
        event.preventDefault();
      }

      this.clickedOutside = false;
    },

    isSegmentSelected(recency, frequency) {
      return !!this.localValue.find((segment) => segment.recency === recency && segment.frequency === frequency);
    },

    toggleSegment(recency, frequency) {
      this.localValue = this.localValue.filter((segment) => Object.keys(segment).length > 1);

      const index = this.localValue.findIndex((segment) => segment.recency === recency && segment.frequency === frequency);

      if (index === -1) {
        this.localValue.push({ recency, frequency });
      } else {
        this.localValue.splice(index, 1);
      }

      this.$emit('change', this.localValue);
    },

    isParamSelected(field, value) {
      return !!this.localValue.filter((segment) => Object.keys(segment).length === 1).find((segment) => segment[field] === value);
    },

    toggleParam(field, value) {
      this.localValue = this.localValue.filter((segment) => Object.keys(segment).length === 1);

      const index = this.localValue.findIndex((segment) => segment[field] === value);

      if (index === -1) {
        this.localValue.push({ [field]: value });
      } else {
        this.localValue.splice(index, 1);
      }

      this.$emit('change', this.localValue);
    },

  },
};
</script>

<style scoped>
.w-33 {
  width: 33.333%;
}

.segments >>> .b-dropdown-form {
  padding: 2px 10px;
  width: 380px;
}
.segments__tooltip >>> .tooltip-inner {
  max-width: 350px !important;
}
</style>
