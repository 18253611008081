var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"centered":"","ok-variant":"success","ok-title":"Установить","cancel-title":"Закрыть","visible":true,"busy":_vm.loading},on:{"ok":_vm.ok,"hidden":function($event){return _vm.$emit('hide')}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',[_vm._v("Установка минимальных цен")])]},proxy:true}])},[_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('b-form-group',{attrs:{"label":"Релиз:","invalid-feedback":_vm.errors.releaseId,"state":_vm.states.releaseId}},[_c('dictionary-provider',{attrs:{"name":"showsReleases","filter":{
          cinemaId: _vm.cinemaId,
          dateStart: _vm.item.dateFrom,
          dateEnd: _vm.item.dateTo
        },"disable-fetching":!_vm.cinemaId || !_vm.item.dateFrom || !_vm.item.dateTo},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var items = ref.items;
        var releasesLoading = ref.loading;
return [_c('b-form-select',{attrs:{"size":"sm","disabled":_vm.loading || releasesLoading},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Выберите фильм --")])]},proxy:true}],null,true),model:{value:(_vm.item.releaseId),callback:function ($$v) {_vm.$set(_vm.item, "releaseId", $$v)},expression:"item.releaseId"}},_vm._l((items),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.movie.name)+" "+_vm._s(item.format.name))])}),0)]}}])})],1),_c('b-form-group',{attrs:{"label":"Даты начала и окончания периода:","invalid-feedback":_vm.errors.dateFrom || _vm.errors.dateTo,"state":_vm.states.dateFrom || _vm.states.dateTo}},[_c('date-interval',{attrs:{"value":{from: _vm.item.dateFrom, to: _vm.item.dateTo},"disabled":_vm.loading},on:{"selected":function($event){_vm.updateValue('dateFrom', $event.from), _vm.updateValue('dateTo', $event.to)}}})],1),_c('b-form-group',{attrs:{"label":"Время начала и окончания периода:","invalid-feedback":_vm.errors.timeFrom || _vm.errors.timeTo,"state":_vm.states.timeFrom || _vm.states.timeTo}},[_c('time-interval',{attrs:{"value":{from: _vm.item.timeFrom, to: _vm.item.timeTo},"disabled":_vm.loading},on:{"change":function($event){_vm.updateValue('timeFrom', $event.from), _vm.updateValue('timeTo', $event.to)}}})],1),_c('b-form-group',{attrs:{"label":"Минимальная стоимость:","invalid-feedback":_vm.errors.minPrice,"state":_vm.states.minPrice}},[_c('b-form-input',{attrs:{"type":"number","size":"sm","min":"0","disabled":_vm.loading},model:{value:(_vm.item.minPrice),callback:function ($$v) {_vm.$set(_vm.item, "minPrice", $$v)},expression:"item.minPrice"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }