var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('model-provider',{attrs:{"collection":"holidays","id":_vm.holiday && _vm.holiday.id,"init":{ date: _vm.dbDate }},on:{"error":_vm.notifyErrors,"saved":function($event){return _vm.$emit('saved', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_data = ref.data;
var item = ref_data.item;
var states = ref_data.states;
var errors = ref_data.errors;
var loading = ref_data.loading;
var ref_actions = ref.actions;
var save = ref_actions.save;
var setValue = ref_actions.setValue;
return [_c('div',[_c('b-form-group',{attrs:{"label":"Работаем по:","invalid-feedback":errors.asDay,"state":states.asDay}},[_c('b-form-select',{attrs:{"size":"sm","autofocus":"","options":_vm.days,"value":item.asDay,"state":states.asDay,"disabled":loading},on:{"change":function($event){return setValue('asDay', $event)}}})],1),_c('b-form-group',{attrs:{"label":"Комментарий:"}},[_c('b-form-input',{attrs:{"size":"sm","type":"text","value":item.note,"disabled":loading},on:{"change":function($event){return setValue('note', $event)}}})],1),_c('b-form-group',{attrs:{"label":"Регионы:"}},[_c('dictionary-provider',{attrs:{"name":"regions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('toggle-list',{attrs:{"options":items,"value":item.regions,"disabled":loading,"hideSelected":false,"limit":3},on:{"change":function($event){return setValue('regions', $event)}}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-info","size":"sm","disabled":loading},on:{"click":function($event){return save()}}},[_vm._v("Сохранить")]),(item.id)?_c('b-button',{attrs:{"variant":"outline-danger","size":"sm","disabled":loading},on:{"click":function($event){return _vm.$emit('remove', item.id)}}},[_vm._v("Удалить")]):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }