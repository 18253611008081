<template>
  <list-box
    :current="id"
    :items="items"
    :loading="loading"
    :search="name"
    @click="$emit('select', $event)"
    @search="search"
    @end-reached="$emit('fetch-next')"
  >
    <template #title="{item}">
      {{item.name}}
    </template>

    <template #description="{item}">
      {{item.code}}
    </template>
  </list-box>
</template>

<script>
import debounce from 'lodash.debounce';
import ListBox from '../../components/ListBox.vue';

import { DEBOUNCE_DELAY } from '../../constants';

export default {
  components: {
    ListBox,
  },
  props: {
    id: {
      default: null,
    },
    items: {
      default: () => [],
    },
    loading: {
      default: false,
    },
    name: {
      default: '',
    },
  },
  created() {
    this.search = debounce(this.search, DEBOUNCE_DELAY, {
      trailing: true,
      leading: false,
    });
  },
  methods: {
    search(value) {
      this.$emit('search', value);
    },
  },
};
</script>
