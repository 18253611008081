<template>
  <div class="p-2">
    <div
      v-for="cinema in cinemas"
      class="mb-4"
      :key="cinema.id"
    >
      <h3 class="mb-2">{{ cinema.name }}, {{ cinema.city.name }}</h3>

      <b-table
        class="datatable"
        :empty-text="loading ? 'Загрузка...' : 'Нет ключей'"
        :small="true"
        :fields="fields"
        :items="getCinemaDcpKeys(cinema.id)"
        :striped="true"
        :hover="false"
        :no-local-sorting="true"
        :busy="loading"
        :show-empty="true"
      >
        <template #cell(hallName)="data">
          <span :class="{'text-danger': !data.item.filename}">Зал: {{ data.item.hallName }}</span>
        </template>

        <template #cell(formatName)="data">
          <span :class="{'text-danger': !data.item.filename}">{{ data.item.formatName }}</span>
        </template>

        <template #cell(period)="data">
          <span :class="{'text-danger': !data.item.filename}">{{ data.item.period }}</span>
        </template>

        <template #cell(filename)="data">
          <a
            v-if="data.item.filename"
            class="text-primary cursor-pointer"
            @click="$emit('export', data.item)"
          >{{ data.item.filename }}</a>
          <span
            v-else
            class="text-danger"
          >Нет ключей</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    movie: {
      default: null,
    },
    cinemas: {
      default: () => [],
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'hallName',
          label: 'Зал',
        },
        {
          key: 'formatName',
          label: 'Формат',
        },
        {
          key: 'period',
          label: 'Срок действия',
        },
        {
          key: 'filename',
          label: 'Название файла',
        },
      ],
    };
  },
  methods: {
    getCinemaDcpKeys(cinemaId) {
      if (!this.movie) {
        return [];
      }

      const cinema = this.cinemas.find((_cinema) => _cinema.id === cinemaId);

      if (!cinema) {
        return [];
      }

      const dcpKeys = this.movie.dcpKeys
        .filter((dcpKey) => cinema.halls.find((hall) => hall.id === dcpKey.hallId))
        .map((dcpKey) => ({
          id: dcpKey.id,
          hallId: dcpKey.hallId,
          filename: dcpKey.filename,
          hallName: cinema.halls.find((hall) => hall.id === dcpKey.hallId)?.name,
          formatName: this.movie.releases.find((release) => release.id === dcpKey.releaseId)?.format?.name,
          period: `${this.$datetime.formatDateTime(dcpKey.dateStart)} - ${this.$datetime.formatDateTime(dcpKey.dateEnd)}`,
        }))
        .sort((a, b) => a.hallId - b.hallId);

      // eslint-disable-next-line guard-for-in
      for (const show of this.getShowsWithoutDcpKeys(cinemaId)) {
        dcpKeys.push({
          id: show.key,
          hallId: show.hallId,
          filename: '',
          hallName: show.hallName,
          formatName: show.formatName,
          period: `Дата: ${this.$datetime.formatDate(show.date)}, Сеансов ${show.count}`,
        });
      }

      return dcpKeys;
    },

    getShowsWithoutDcpKeys(cinemaId) {
      if (!this.movie) {
        return [];
      }

      const cinema = this.cinemas.find((_cinema) => _cinema.id === cinemaId);

      if (!cinema) {
        return [];
      }

      return this.movie.showsWithoutDcpKeys
        .filter((show) => cinema.halls.find((hall) => hall.id === show.hallId))
        .map((show) => ({
          key: `${show.hallId}_${show.releaseId}_${show.date}`,
          hallId: show.hallId,
          releaseId: show.releaseId,
          date: show.date,
          hallName: cinema.halls.find((hall) => hall.id === show.hallId)?.name,
          formatName: this.movie.releases.find((release) => release.id === show.releaseId)?.format?.name,
        }))
        .reduce((shows, show) => {
          const foundShow = shows.find((_show) => _show.key === show.key);

          if (!foundShow) {
            shows.push({
              ...show,
              count: 1,
            });
          } else {
            foundShow.count += 1;
          }

          return shows;
        }, [])
        .sort((a, b) => a.hallId - b.hallId);
    },
  },
};
</script>
