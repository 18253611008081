<template>
  <cinema-page
    route-name="prices"
    route-param-name="cinemaId"
  >
    <template #scroll="{ cinemaId, halls }">
      <collection-provider
        collection="prices"
        ref="collection"
        :filter="{ cinemaId }"
        :disable-fetching="!cinemaId"
      >
        <template #default="{ data, actions }">
          <dictionaries-provider :names="['colorSchemes', 'formats2']">
            <template #default="{ colorSchemes, formats2 }">
              <datatable
                class="prices"
                v-bind="{ ...data, ...actions }"
                :fields="datatable.fields"
                :permissions="$user.permissions"
                :item-actions="datatable.itemActions"
                :panel-actions="datatable.panelActions"
                @item-action="performItemAction($event)"
                @panel-action="performPanelAction($event)"
              >
                <template #filter(day)>
                  <b-form-select
                    size="sm"
                    :value="data.filter.day"
                    :options="days"
                    :disabled="data.loading"
                    @change="actions.changeFilter('day', $event)"
                  ></b-form-select>
                </template>
                <template #filter(formatId)>
                  <b-form-select
                    size="sm"
                    :value="data.filter.formatId"
                    :options="$array.convertToOptions(formats2)"
                    :disabled="data.loading"
                    @change="actions.changeFilter('formatId', $event)"
                  ></b-form-select>
                </template>
                <template #filter(hallId)>
                  <b-form-select
                    size="sm"
                    :value="data.filter.hallId"
                    :options="$array.convertToOptions(halls)"
                    :disabled="data.loading"
                    @change="actions.changeFilter('hallId', $event)"
                  ></b-form-select>
                </template>
                <template #filter(colorSchemeId)>
                  <b-form-select
                    size="sm"
                    :value="data.filter.colorSchemeId"
                    :options="$array.convertToOptions(colorSchemes)"
                    :disabled="data.loading"
                    @change="actions.changeFilter('colorSchemeId', $event)"
                  ></b-form-select>
                </template>
                <template #cell(day)="data">
                  {{$array.convertToText(data.item.days, 'Все', 'short')}}
                </template>
                <template #cell(time)="data">
                  <span v-if="data.item.timeStart" class="mr-1">с  {{data.item.timeStart}}</span>
                  <span v-if="data.item.timeEnd">по {{data.item.timeEnd}}</span>
                </template>
                <template #cell(hallId)="data">
                  <div
                    class="mw-200vw"
                    :title="$array.convertToText(data.item.halls, 'Все')"
                  >{{$array.convertToText(data.item.halls, 'Все')}}</div>
                </template>
                <template #cell(formatId)="data">
                  <div
                    class="mw-200vw"
                    :title="$array.convertToText(data.item.formats, 'Все')"
                  >{{$array.convertToText(data.item.formats, 'Все')}}</div>
                </template>
                <template #cell(colorSchemeId)="data">
                  <div
                    class="mw-200vw"
                    :title="$array.convertToText(data.item.colorSchemes, 'Все')"
                  >{{$array.convertToText(data.item.colorSchemes, 'Все')}}</div>
                </template>
              </datatable>
            </template>
          </dictionaries-provider>
        </template>
      </collection-provider>

      <modal-model-form
        v-if="modals.price.show"
        create-title="Создание ценовой политики"
        edit-title="Редактирование ценовой политики"
        collection="prices"
        :id="modals.price.id"
        :init="{ cinemaId }"
        @close="modals.price.show = false"
        @saved="$refs.collection.fetchItems()"
      >
        <template #default="{ data, actions }">
          <price-form
            :loading="data.loading"
            :errors="data.errors"
            :states="data.states"
            :item="data.item"
            :halls="halls"
            @change="actions.setValue($event.field, $event.value)"
          ></price-form>
        </template>
      </modal-model-form>
    </template>
  </cinema-page>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import CinemaPage from '../../layout/CinemaPage.vue';
import CollectionProvider from '../../providers/CollectionProvider';
import DictionariesProvider from '../../providers/DictionariesProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import PriceForm from './PriceForm.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    DictionariesProvider,
    Datatable,
    PriceForm,
    ModalModelForm,
  },
  props: {
    cinemaId: {
      default: null,
    },
    halls: {
      default: () => [],
    },
  },
  data() {
    return {
      datatable: {
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        price: {
          show: false,
          id: '',
        },
      },
      days: [
        { value: null, text: '' },
        { value: 1, text: 'ПН' },
        { value: 2, text: 'ВТ' },
        { value: 3, text: 'СР' },
        { value: 4, text: 'ЧТ' },
        { value: 5, text: 'ПТ' },
        { value: 6, text: 'СБ' },
        { value: 7, text: 'ВС' },
      ],
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    openCreatePriceForm() {
      this.modals.price.id = null;
      this.modals.price.show = true;
    },

    openEditPriceForm(id) {
      this.modals.price.id = id;
      this.modals.price.show = true;
    },

    async removePrice(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>
.prices >>> .mw-200vw {
  max-width: 22vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.prices >>> .column-price {
  width: 10vw;
}
</style>
