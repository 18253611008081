export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    sortable: false,
    filterable: true,
  },
  {
    key: 'legalEntityId',
    type: 'text',
    label: 'Юр. лицо',
    sortable: false,
    filterable: true,
  },
  {
    key: 'productGroupId',
    type: 'text',
    label: 'Группа для отображения',
    sortable: false,
    filterable: true,
  },
  {
    key: 'productReportGroupId',
    type: 'text',
    label: 'Группа для отчетов',
    sortable: false,
    filterable: true,
  },
  {
    key: 'isEnabled',
    type: 'text',
    label: 'Статус',
    sortable: false,
    filterable: true,
  },
  {
    key: 'price',
    type: 'text',
    label: 'Цена',
    sortable: true,
    filterable: false,
  },
];
