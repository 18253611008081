var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cinema-page',{attrs:{"route-name":"mediaPanels","route-param-name":"cinemaId"},on:{"loaded":function($event){return _vm.setCinemaId($event)}},scopedSlots:_vm._u([{key:"buttons",fn:function(ref){
var cinemas = ref.cinemas;
var loading = ref.loading;
return [_c('buttons',{attrs:{"cinemas":cinemas,"cinema-id":_vm.cinemaId,"disabled":loading || _vm.loadingResources},on:{"add-screen":function($event){return _vm.openForm('screen', { id: null })},"add-group":function($event){return _vm.openForm('group', { id: null })}}})]}},{key:"fixed",fn:function(ref){
var cinemaId = ref.cinemaId;
var loading = ref.loading;
return [_c('div',{staticClass:"w-100 h-100 p-2 overflow-auto"},[_c('groups',{attrs:{"screens":_vm.screens,"groups":_vm.groups,"settings":_vm.settings,"loading":loading || _vm.loadingResources},on:{"update-group-name":function($event){return _vm.openForm('group', { id: $event.id })},"update-settings-group":function($event){_vm.modals.groupSettings.groupName = $event.groupName,
          _vm.modals.groupSettings.groupId = $event.groupId,
          _vm.openForm('groupSettings', { id: $event.settingId })},"update-settings-screen":function($event){_vm.modals.screenSettings.screenName = $event.screenName,
          _vm.modals.screenSettings.screenId = $event.screenId,
          _vm.openForm('screenSettings', { id: $event.settingId })},"remove-group":function($event){_vm.removeGroups({ id: $event.id }), _vm.fetchScreens()},"update-screen":function($event){return _vm.openForm('screen', { id: $event.id })},"update-position-screens":function($event){return _vm.updatePositionScreens($event)},"remove-screen":function($event){return _vm.removeScreen({ id: $event.id })},"reboot-screen":function($event){return _vm.rebootScreen({ id: $event.id })},"screenshot":function($event){_vm.modals.screenshotModal.show = true,
          _vm.getScreenshot({ id: $event.screenId })}}})],1),(_vm.modals.screen.show)?_c('modal-screen',{attrs:{"id":_vm.modals.screen.id,"loading":_vm.screensLoading,"cinema-id":cinemaId},on:{"hide":function($event){return _vm.closeForm('screen')},"saved":function($event){return _vm.fetchScreens()}}}):_vm._e(),(_vm.modals.group.show)?_c('modal-model-form',{attrs:{"create-title":"Создание группы","edit-title":"Редактирование группы","collection":"mediaPanels/groups","id":_vm.modals.group.id,"cinema-id":cinemaId,"init":{ cinemaId: cinemaId }},on:{"close":function($event){_vm.modals.group.show = false},"saved":function($event){_vm.fetchGroups(), _vm.fetchScreens()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var dataModalGroup = ref.data;
          var modalActionGroup = ref.actions;
return [_c('modal-group',{attrs:{"loading":dataModalGroup.loading,"errors":dataModalGroup.errors,"states":dataModalGroup.states,"item":dataModalGroup.item},on:{"change":function($event){return modalActionGroup.setValue($event.field, $event.value)}}})]}}],null,true)}):_vm._e(),(_vm.modals.groupSettings.show)?_c('modal-settings',{attrs:{"createTitle":"Создание группы","editTitle":("Редактирование группы \"" + (_vm.modals.groupSettings.groupName) + "\""),"find-screens-by-field":{ field: 'groupId', id: _vm.modals.groupSettings.groupId },"id":_vm.modals.groupSettings.id,"cinema-id":cinemaId,"library-files":_vm.modals.libraryFiles.files},on:{"hide":function($event){_vm.modals.groupSettings.show = false},"updated":function($event){return _vm.updateGroupSetting($event)},"show-modal-files":function($event){_vm.modals.libraryFiles.show = true},"screenshot":function($event){_vm.modals.screenshotModal.show = true,
        _vm.getScreenshot({ id: $event.screenId })},"preview-screen-for-settings":function($event){_vm.modals.previewModal.show = true,
        _vm.getPreviewForSettings({ screenId: $event.screenId, settings: $event.settings })}}}):_vm._e(),(_vm.modals.screenSettings.show)?_c('modal-settings',{attrs:{"createTitle":"Создание экрана","editTitle":("Редактирование экрана \"" + (_vm.modals.screenSettings.screenName) + "\""),"is-drop-playlists":"","find-screens-by-field":{ field: 'id', id: _vm.modals.screenSettings.screenId },"id":_vm.modals.screenSettings.id,"cinema-id":cinemaId,"library-files":_vm.modals.libraryFiles.files},on:{"hide":function($event){_vm.modals.screenSettings.show = false},"show-modal-files":function($event){_vm.modals.libraryFiles.show = true},"updated":function($event){_vm.modals.screenSettings.show = false,
        _vm.generateSettingAndFetchData($event)},"screenshot":function($event){_vm.modals.screenshotModal.show = true,
        _vm.getScreenshot({ id: $event.screenId })},"preview-screen-for-settings":function($event){_vm.modals.previewModal.show = true,
        _vm.getPreviewForSettings($event)}}}):_vm._e(),(_vm.modals.previewModal.show)?_c('modal-content',{attrs:{"size":"xl","title":"Предпросмотр"},on:{"hide":function($event){_vm.modals.previewModal.show = false,
        _vm.removePreviewForSetting()}}},[_c('div',[_c('b-embed',{attrs:{"type":"iframe","srcdoc":_vm.previewForSettings}})],1)]):_vm._e(),(_vm.modals.screenshotModal.show)?_c('modal-content',{attrs:{"size":"xl","title":"Снимок экрана"},on:{"hide":function($event){_vm.modals.screenshotModal.show = false,
        _vm.removeScreenshot()}}},[_c('div',[(_vm.screenshot)?_c('b-img',{attrs:{"src":_vm.screenshot}}):_c('div',{staticClass:"d-flex flex-column justify-content-center w-100"},[_c('div',{staticClass:"p-3 mb-2 w-100 d-flex justify-content-center"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1),_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v("Загружается, пожалуйста подождите...")])])],1)]):_vm._e(),(_vm.modals.libraryFiles.show)?_c('modal-files',{on:{"files":function($event){_vm.modals.libraryFiles.files = $event},"hide":function($event){_vm.modals.libraryFiles.show = false,
        _vm.modals.libraryFiles.files = []}}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }