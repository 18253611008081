<template>
  <b-container fluid class="p-0">
    <b-row v-if="title">
      <b-col>
        <h4 class="box-title">{{title}}</h4>
        <hr class="m-t-0 m-b-30">
      </b-col>
    </b-row>
    <slot></slot>
  </b-container>
</template>

<script>

export default {
  props: {
    title: {
      default: '',
    },
  },
};
</script>
