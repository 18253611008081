<template>
  <cinema-page
    route-name="mediaPanels"
    route-param-name="cinemaId"
    @loaded="setCinemaId($event)"
  >
    <template #buttons="{ cinemas, loading }">
      <buttons
        :cinemas="cinemas"
        :cinema-id="cinemaId"
        :disabled="loading || loadingResources"
        @add-screen="openForm('screen', { id: null })"
        @add-group="openForm('group', { id: null })"
      ></buttons>
    </template>
    <template #fixed="{ cinemaId, loading }">
      <div class="w-100 h-100 p-2 overflow-auto">
        <groups
          :screens="screens"
          :groups="groups"
          :settings="settings"
          :loading="loading || loadingResources"
          @update-group-name="openForm('group', { id: $event.id })"
          @update-settings-group="
            modals.groupSettings.groupName = $event.groupName,
            modals.groupSettings.groupId = $event.groupId,
            openForm('groupSettings', { id: $event.settingId })
          "
          @update-settings-screen="
            modals.screenSettings.screenName = $event.screenName,
            modals.screenSettings.screenId = $event.screenId,
            openForm('screenSettings', { id: $event.settingId })
          "
          @remove-group="removeGroups({ id: $event.id }), fetchScreens()"
          @update-screen="openForm('screen', { id: $event.id })"
          @update-position-screens="updatePositionScreens($event)"
          @remove-screen="removeScreen({ id: $event.id })"
          @reboot-screen="rebootScreen({ id: $event.id })"
          @screenshot="
            modals.screenshotModal.show = true,
            getScreenshot({ id: $event.screenId })
          "
        ></groups>
      </div>

      <modal-screen
        v-if="modals.screen.show"
        :id="modals.screen.id"
        :loading="screensLoading"
        :cinema-id="cinemaId"
        @hide="closeForm('screen')"
        @saved="fetchScreens()"
      ></modal-screen>

      <modal-model-form
        v-if="modals.group.show"
        create-title="Создание группы"
        edit-title="Редактирование группы"
        collection="mediaPanels/groups"
        :id="modals.group.id"
        :cinema-id="cinemaId"
        :init="{ cinemaId }"
        @close="modals.group.show = false"
        @saved="fetchGroups(), fetchScreens()"
      >
        <template #default="{ data: dataModalGroup, actions: modalActionGroup }">
          <modal-group
            :loading="dataModalGroup.loading"
            :errors="dataModalGroup.errors"
            :states="dataModalGroup.states"
            :item="dataModalGroup.item"
            @change="modalActionGroup.setValue($event.field, $event.value)"
          ></modal-group>
        </template>
      </modal-model-form>

      <modal-settings
        v-if="modals.groupSettings.show"
        createTitle="Создание группы"
        :editTitle='`Редактирование группы "${modals.groupSettings.groupName}"`'
        :find-screens-by-field="{ field: 'groupId', id: modals.groupSettings.groupId }"
        :id="modals.groupSettings.id"
        :cinema-id="cinemaId"
        :library-files="modals.libraryFiles.files"
        @hide="modals.groupSettings.show = false"
        @updated="updateGroupSetting($event)"
        @show-modal-files="modals.libraryFiles.show = true"
        @screenshot="
          modals.screenshotModal.show = true,
          getScreenshot({ id: $event.screenId })
        "
        @preview-screen-for-settings="
          modals.previewModal.show = true,
          getPreviewForSettings({ screenId: $event.screenId, settings: $event.settings })
        "
      ></modal-settings>

      <modal-settings
        v-if="modals.screenSettings.show"
        createTitle="Создание экрана"
        :editTitle='`Редактирование экрана "${modals.screenSettings.screenName}"`'
        is-drop-playlists
        :find-screens-by-field="{ field: 'id', id: modals.screenSettings.screenId }"
        :id="modals.screenSettings.id"
        :cinema-id="cinemaId"
        :library-files="modals.libraryFiles.files"
        @hide="modals.screenSettings.show = false"
        @show-modal-files="modals.libraryFiles.show = true"
        @updated="
          modals.screenSettings.show = false,
          generateSettingAndFetchData($event)
        "
        @screenshot="
          modals.screenshotModal.show = true,
          getScreenshot({ id: $event.screenId })
        "
        @preview-screen-for-settings="
          modals.previewModal.show = true,
          getPreviewForSettings($event)
        "
      >
      </modal-settings>

      <modal-content
        v-if="modals.previewModal.show"
        size="xl"
        title="Предпросмотр"
        @hide="
          modals.previewModal.show = false,
          removePreviewForSetting()
        "
      >
        <div>
          <b-embed type="iframe" :srcdoc="previewForSettings"></b-embed>
        </div>
      </modal-content>

      <modal-content
        v-if="modals.screenshotModal.show"
        size="xl"
        title="Снимок экрана"
        @hide="
          modals.screenshotModal.show = false,
          removeScreenshot()
        "
      >
        <div>
          <b-img v-if="screenshot" :src="screenshot"></b-img>
          <div v-else class="d-flex flex-column justify-content-center w-100">
            <div class="p-3 mb-2 w-100 d-flex justify-content-center">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div class="d-flex justify-content-center">Загружается, пожалуйста подождите...</div>
          </div>
        </div>
      </modal-content>

      <modal-files
        v-if="modals.libraryFiles.show"
        @files="modals.libraryFiles.files = $event"
        @hide="
          modals.libraryFiles.show = false,
          modals.libraryFiles.files = []
        "
      >
      </modal-files>
    </template>
  </cinema-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import CinemaPage from '../../layout/CinemaPage.vue';

import ModalScreen from './ModalScreen.vue';
import ModalGroup from './ModalGroup.vue';
import ModalSettings from './ModalSettings.vue';
import ModalContent from './ModalContent.vue';
import ModalFiles from './ModalFiles.vue';
import Groups from './Groups.vue';
import Buttons from './Buttons.vue';

export default {
  name: 'media-panels',
  data: () => ({
    cinemaId: null,
    modals: {
      group: {
        show: false,
        id: null,
      },
      groupSettings: {
        show: false,
        id: null,
        groupId: null,
        groupName: null,
      },
      screen: {
        show: false,
        id: null,
      },
      screenSettings: {
        show: false,
        id: null,
        screenId: null,
        screenName: null,
      },
      previewModal: {
        show: false,
      },
      screenshotModal: {
        show: false,
      },
      libraryFiles: {
        show: false,
        files: [],
      },
    },
  }),
  computed: {
    ...mapState('media/screens', {
      screens: (state) => state.items,
      screensLoading: (state) => state.loading,
    }),

    ...mapState('data/screenGroups', {
      groups: (state) => [
        { id: null, name: 'Одиночные экраны' },
        ...([...state.items].sort(
          (a, b) => a.id - b.id,
        )),
      ],
      groupsLoading: (state) => state.loading,
    }),

    ...mapState('data/settingsMediaPanels', {
      previewForSettings: (state) => state.previewForSettings,
      settings: (state) => state.items,
      settingsLoading: (state) => state.loading,
    }),

    ...mapState('media/screenshot', {
      screenshotLoading: (state) => state.loading,
      screenshot: (state) => state.imagePath,
    }),

    loadingResources() {
      return this.screenshotLoading || this.groupsLoading || this.settingsLoading || this.screensLoading;
    },
  },
  components: {
    Groups,
    Buttons,
    ModalScreen,
    ModalModelForm,
    ModalGroup,
    ModalSettings,
    ModalContent,
    ModalFiles,
    CinemaPage,
  },
  methods: {
    ...mapActions({
      fetchScreens: 'media/screens/fetchItems',
      removeScreen: 'media/screens/removeItem',
      rebootScreen: 'media/screens/rebootItem',
      changeFilterScreens: 'media/screens/changeFilter',
      updatePositionScreens: 'media/screens/updateGroupAndPosition',

      fetchGroups: 'data/screenGroups/fetchItems',
      removeGroups: 'data/screenGroups/removeItem',
      updateGroup: 'data/screenGroups/updateItem',
      changeFilterGroups: 'data/screenGroups/changeFilter',

      fetchSettings: 'data/settingsMediaPanels/fetchItems',
      getPreviewHtml: 'data/settingsMediaPanels/getPreviewHtml',
      getPreviewForSettings: 'data/settingsMediaPanels/getPreviewForSettings',
      removePreviewForSetting: 'data/settingsMediaPanels/removePreviewForSetting',
      changeFilterSettings: 'data/settingsMediaPanels/changeFilter',
      generateSchedulesForSettings: 'data/settingsMediaPanels/generateSchedulesForSettings',

      getScreenshot: 'media/screenshot/getScreenshot',
      removeScreenshot: 'media/screenshot/removeScreenshot',
    }),

    setCinemaId({ id }) {
      this.cinemaId = id;
      this.changeFilterGroups({ field: 'cinemaId', value: id });
      this.changeFilterScreens({ field: 'cinemaId', value: id });
      this.changeFilterSettings({ field: 'cinemaId', value: id });
    },

    openForm(type, { id }) {
      this.modals[type].show = true;
      this.modals[type].id = id;
    },

    closeForm(type) {
      this.modals[type].show = false;
      this.modals[type].id = null;
    },

    async updateGroupSetting(id) {
      await this.updateGroup({ id: this.modals.groupSettings.groupId, values: { mediablockSettingsId: id } });

      this.modals.groupSettings.show = false;

      await this.generateSettingAndFetchData(id);
    },

    async generateSettingAndFetchData(id) {
      if (id) {
        await this.generateSchedulesForSettings(id);
      }

      await this.fetchSettings();
      await this.fetchGroups();
      await this.fetchScreens();
    },
  },
};
</script>

<style lang="scss">
img {
  max-width: 100%;
}
</style>
