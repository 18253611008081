<template>
  <b-modal
    centered
    ok-only
    title="Выберите файлы"
    size="lg"
    :busy="busy"
    :visible="visible"
    @hidden="$emit('hide')"
  >
    <div class="mw-100 files">
      <scroll>
        <files
          mode="tree"
          color-row-selected="info"
          :files-selected="files"
          :hide-fields="['createdAt', 'updatedAt', 'action', 'status']"
          :parent-directory-id="null"
          @click-file="clickFile($event)"
        ></files>
      </scroll>
    </div>
    <template #modal-footer>
        <div class="mw-100 d-flex align-items-center justify-content-end">
          <div
            v-if="countSelectedFiles"
            class="text-secondary mr-2"
          >
            {{ getDeclOfNum(countSelectedFiles, ['Выбран', 'Выбрано', 'Выбрано']) }}
            {{ countSelectedFiles }} {{ getDeclOfNum(countSelectedFiles, ['файл', 'файлa', 'файлов']) }}</div>
          <b-button
            variant="info"
            @click="
              $emit('files', files)
              visible = false
            "
          >
            Добавить
          </b-button>
        </div>
      </template>
  </b-modal>
</template>
<script>
/* eslint-disable no-restricted-syntax */
import declOfNum from '../../utils/declOfNum';

import Files from '../media-library/Files.vue';

export default {
  props: {
    cinemaId: null,
    mediablockSettingsId: null,
  },
  data: () => ({
    files: [],
    visible: true,
    busy: false,
  }),
  computed: {
    countSelectedFiles() {
      return this.files.length;
    },

    count() {
      return this.playlists.length;
    },
  },
  components: {
    Files,
  },
  methods: {
    clickFile(item) {
      if (this.files.map((_item) => _item.id).includes(item.id)) {
        this.files = this.files.filter((_item) => _item.id !== item.id);

        return;
      }

      this.files.push(item);
    },

    getDeclOfNum(n, titles = []) {
      return declOfNum(n, titles);
    },
  },
};
</script>

<style lang="scss" scoped>
  img {
    max-width: 100%;
  }

  .files {
    height: 450px;
  }
</style>
