<template>
  <div class="trailers">
    <b-table
      class="datatable"
      empty-text="Нет данных"
      :fields="columns"
      :items="trailers"
      :striped="true"
      :hover="true"
      :no-local-sorting="false"
      :show-empty="true"
    >
      <template #cell(preview)="data">
        <a
          @click="openModalVideo(data.item.mp4Url)"
        >
          <img class="trailers__thumb" :src="data.item.mp4ThumbUrl" />
        </a>
      </template>

      <template #cell(createdAt)="data">
          {{ $datetime.formatDateTime(data.item.createdAt) }}
        </template>

      <template #cell(duration)="data">
        {{ $datetime.convertDuration(data.item.duration) }}
      </template>

      <template #cell(ageLimit)="data">
        {{ data.item.ageLimit }}+
      </template>

      <template #cell(download)="data">
        <a
          :href="data.item.hd720Url"
          target="_blank"
          class="trailers__link"
        >HD720</a>
        <a
          :href="data.item.hd1080Url"
          target="_blank"
          class="trailers__link"
        >HD1080</a>
        <a
          :href="data.item.dcpUrl"
          target="_blank"
          class="trailers__link"
        >DCP</a>
      </template>
    </b-table>

    <b-modal
      v-if="modalVideo.show"
      centered
      size="xl"
      :visible="true"
      :hide-header="true"
      :hide-footer="true"
      @hidden="closeModalVideo()"
    >
      <div class="h-100 w-100 text-center" @click="closeModalVideo()">
        <video
          :src="modalVideo.trailerUrl"
          autoplay="true"
          loop="true"
          muted="true"
          controls="true"
        ></video>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  props: {
    trailers: {
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          key: 'preview',
          label: 'Превью',
        },
        {
          key: 'createdAt',
          label: 'Загружен',
        },
        {
          key: 'duration',
          label: 'Время',
        },
        {
          key: 'ageLimit',
          label: 'ВО',
        },
        {
          key: 'download',
          label: 'Скачать',
        },
      ],
      modalVideo: {
        show: false,
        trailerUrl: null,
      },
    };
  },
  methods: {
    openModalVideo(trailerUrl) {
      this.modalVideo.show = true;
      this.modalVideo.trailerUrl = trailerUrl;
    },

    closeModalVideo() {
      this.modalVideo.show = false;
      this.modalVideo.trailerUrl = null;
    },
  },
};
</script>

<style scoped>
.trailers__thumb {
  max-width: 100px;
}

.trailers__link:not(:first-child)::before {
    color: rgb(0, 0, 0);
    content: "•";
    padding: 0px 3px;
}
/deep/ .modal-content {
  background-color: inherit;
  border: 0;
}
</style>
