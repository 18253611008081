<template>
  <div
    v-if="hasActions || hasPagination"
    class="table-actions d-flex justify-content-between align-items-center"
  >
    <div
      v-if="hasActions"
      class="d-flex align-items-center flex-1"
    >
      <actions
        v-if="actions.length"
        class="btn-toolbar"
        :actions="actions"
        :disabled="disabled"
        @click="click(...arguments)"
      ></actions>
      <slot></slot>
    </div>
    <div v-if="hasPagination" class="ml-auto d-flex flex-1 align-items-center">
      <pagination
        label="Страница"
        :page="pagination.page"
        :limit="pagination.limit"
        :total="pagination.total"
        @update="$emit('change-page', $event)"
        :disabled="disabled"
      ></pagination>
      <per-page
        :value="pagination.limit"
        @update="$emit('change-limit', $event)"
        :disabled="disabled"
      ></per-page>
    </div>
  </div>
</template>

<script>
import Actions from './Actions.vue';
import Pagination from './Pagination.vue';
import PerPage from './PerPage.vue';

export default {
  components: {
    Actions,
    Pagination,
    PerPage,
  },
  props: {
    buttonSize: {
      type: String,
      default: 'sm',
    },
    actions: {
      type: Array,
    },
    pagination: {
      type: Object,
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    countOfPages() {
      if (!this.pagination?.limit || !this.pagination?.page) {
        return 1;
      }

      return Math.ceil(this.pagination?.total / this.pagination?.limit);
    },
    hasActions() {
      return (this.actions && this.actions.length) || this.$slots.default;
    },
    hasPagination() {
      return this.pagination?.limit && this.pagination?.page && this.countOfPages > 1;
    },
  },
  methods: {
    click(action, args) {
      this.$emit('click', action, args);
    },
  },
};
</script>
