/* eslint-disable no-restricted-syntax */
import {
  START_CORNER_TOP_LEFT,
  START_CORNER_TOP_RIGHT,
  START_CORNER_BOTTOM_RIGHT,
  START_CORNER_BOTTOM_LEFT,
  DEFAULT_SEAT_TYPE,
  DEFAULT_COLOR_SCHEME_ID,
  PLACE_ID_BASE,
} from '../../constants';

export default class Scheme {
  constructor(scheme = [], startCorner = START_CORNER_TOP_LEFT, seatTypes = []) {
    this.scheme = scheme;
    this.startCorner = startCorner;
    this.seatTypes = seatTypes;
    this.dirty = false;
  }

  setScheme(scheme) {
    this.scheme = scheme;
    this.dirty = false;
  }

  setStartCorner(startCorner) {
    this.startCorner = startCorner;
  }

  setSeatTypes(seatTypes) {
    this.seatTypes = seatTypes;
  }

  hasSeats(selectedItems) {
    if (!selectedItems) {
      return this.scheme.some((row) => row.some((cell) => cell.length));
    }

    return selectedItems.some((item) => this.scheme[item.i] && this.scheme[item.i][item.j] && this.scheme[item.i][item.j].length);
  }

  hasGaps(selectedItems) {
    if (!selectedItems) {
      return this.scheme.some((row) => row.some((cell) => !cell.length));
    }

    return selectedItems.some((item) => this.scheme[item.i] && this.scheme[item.i][item.j] && !this.scheme[item.i][item.j].length);
  }

  getRowsCount() {
    return this.scheme
      .filter((row) => row.length)
      .length;
  }

  getSeatsCount(rowIndex) {
    return this.scheme[rowIndex]
      .filter((cell) => cell.length)
      .reduce((acc, cell) => acc + cell.length, 0);
  }

  getPlacesCount() {
    return this.scheme
      .filter((row) => row.length)
      .reduce((acc, row) => acc + this.getPlacesCountInRow(row), 0);
  }

  getPlacesCountInRow(row) {
    return row
      .filter((cell) => cell.length)
      .reduce((acc, cell) => acc + this.getPlacesCountInCell(cell), 0);
  }

  getPlacesCountInCell(cell) {
    return cell.reduce((acc, seat) => acc + seat.places.length, 0);
  }

  sortSelectedItems(selectedItems) {
    const selected = JSON.parse(JSON.stringify(selectedItems));

    selected.sort((a, b) => {
      if (a.i < b.i) {
        return 1;
      }

      if (a.i > b.i) {
        return -1;
      }

      if (a.j < b.j) {
        return 1;
      }

      if (a.j > b.j) {
        return -1;
      }

      return 0;
    });

    return selected;
  }

  createSeats(rowsCount, seatsCount, type) {
    const rows = [];
    const row = [];

    for (let j = 0; j < seatsCount; j += 1) {
      const cell = [];

      for (let k = 0; k < this.getSeatMultiplex(type); k += 1) {
        cell.push({ type, colorSchemeId: DEFAULT_COLOR_SCHEME_ID });
      }

      row.push(cell);
    }

    for (let i = 0; i < rowsCount; i += 1) {
      rows.push(row);
    }

    return rows;
  }

  addRoad() {
    this.scheme = [...this.scheme, []];
    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  addRows({ rowsCount, seatsCount, type }) {
    this.scheme = [...this.scheme, ...this.createSeats(rowsCount, seatsCount, type)];
    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  replaceScheme(scheme) {
    this.scheme = scheme;
    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  reverseRows() {
    const opposites = {
      [START_CORNER_TOP_LEFT]: START_CORNER_BOTTOM_LEFT,
      [START_CORNER_TOP_RIGHT]: START_CORNER_BOTTOM_RIGHT,
      [START_CORNER_BOTTOM_LEFT]: START_CORNER_TOP_LEFT,
      [START_CORNER_BOTTOM_RIGHT]: START_CORNER_TOP_RIGHT,
    };

    this.startCorner = opposites[this.startCorner];

    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  reverseSeats() {
    const opposites = {
      [START_CORNER_TOP_LEFT]: START_CORNER_TOP_RIGHT,
      [START_CORNER_TOP_RIGHT]: START_CORNER_TOP_LEFT,
      [START_CORNER_BOTTOM_LEFT]: START_CORNER_BOTTOM_RIGHT,
      [START_CORNER_BOTTOM_RIGHT]: START_CORNER_BOTTOM_LEFT,
    };

    this.startCorner = opposites[this.startCorner];

    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  changeSeatsType(selectedItems, type) {
    const scheme = JSON.parse(JSON.stringify(this.scheme));
    const multiplex = this.getSeatMultiplex(type);

    // eslint-disable-next-line no-restricted-syntax
    for (const item of selectedItems) {
      const cell = scheme[item.i][item.j];

      if (cell.length) {
        const seat = { ...cell[0] };

        seat.type = type;

        scheme[item.i][item.j] = [];

        for (let i = 0; i < multiplex; i += 1) {
          scheme[item.i][item.j].push(seat);
        }
      }
    }

    this.scheme = scheme;

    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  changeSeatsColorSchemeId(selectedItems, colorSchemeId) {
    const scheme = JSON.parse(JSON.stringify(this.scheme));

    // eslint-disable-next-line no-restricted-syntax
    for (const item of selectedItems) {
      const cell = scheme[item.i][item.j];

      if (cell.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const seat of cell) {
          seat.colorSchemeId = colorSchemeId;
        }
      }
    }

    this.scheme = scheme;

    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  changeSeats(selectedItems, field, value) {
    const scheme = JSON.parse(JSON.stringify(this.scheme));
    const multiplex = this.getSeatMultiplex(type);

    // eslint-disable-next-line no-restricted-syntax
    for (const item of selectedItems) {
      const cell = scheme[item.i][item.j];

      if (cell.length) {
        const seat = { ...cell[0] };

        seat[field] = value;

        scheme[item.i][item.j] = [];

        for (let i = 0; i < multiplex; i += 1) {
          scheme[item.i][item.j].push(seat);
        }
      }
    }

    this.scheme = scheme;

    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  removeSeats(selectedItems) {
    const scheme = JSON.parse(JSON.stringify(this.scheme));

    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.sortSelectedItems(selectedItems)) {
      scheme[item.i].splice(item.j, 1);

      if (!scheme[item.i].length) {
        scheme.splice(item.i, 1);
      }
    }

    this.scheme = scheme;

    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  addGapLeft(selectedItems) {
    const scheme = JSON.parse(JSON.stringify(this.scheme));

    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.sortSelectedItems(selectedItems)) {
      scheme[item.i].splice(item.j, 0, []);
    }

    this.scheme = scheme;

    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  addGapRight(selectedItems) {
    const scheme = JSON.parse(JSON.stringify(this.scheme));

    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.sortSelectedItems(selectedItems)) {
      scheme[item.i].splice(item.j + 1, 0, []);
    }

    this.scheme = scheme;

    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  addSeat(rowIndex) {
    const scheme = JSON.parse(JSON.stringify(this.scheme));

    let type = DEFAULT_SEAT_TYPE;

    if (scheme[rowIndex].length) {
      let lastCell = null;

      for (const cell of scheme[rowIndex]) {
        if (cell.length) {
          lastCell = cell;
        }
      }

      if (lastCell) {
        const lastSeat = lastCell[lastCell.length - 1];

        if (lastSeat) {
          type = lastSeat.type;
        }
      }
    }

    scheme[rowIndex].push([{ type, colorSchemeId: DEFAULT_COLOR_SCHEME_ID }]);

    this.scheme = scheme;

    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  removeRow(rowIndex) {
    const scheme = JSON.parse(JSON.stringify(this.scheme));

    scheme.splice(rowIndex, 1);

    this.scheme = scheme;

    this.assignSeatsIdsAndPlaces();
    this.dirty = true;
  }

  getSeatCapacity(type) {
    const seatInfo = this.seatTypes.find((seatType) => seatType.code === type);

    return +seatInfo?.capacity || 1;
  }

  getSeatMultiplex(type) {
    const seatInfo = this.seatTypes.find((seatType) => seatType.code === type);

    return +seatInfo?.multiplex || 1;
  }

  // assignSeatsIdsAndPlaces() {
  //   let scheme = JSON.parse(JSON.stringify(this.scheme));

  //   scheme = this.assignPlaces(scheme);
  //   scheme = this.assignSeatsIds(scheme);

  //   this.scheme = scheme;
  // }

  assignSeatsIdsAndPlaces() {
    const scheme = JSON.parse(JSON.stringify(this.scheme));

    let mobileScheme = [];
    let clearScheme = [];

    for (const row of scheme) {
      let isMobileRow = false;

      for (const cell of row) {
        if (cell.length && cell[0].type === 'mobile') {
          isMobileRow = true;
          mobileScheme = [...mobileScheme, row];
          break;
        }
      }

      if (!isMobileRow) {
        clearScheme = [...clearScheme, row];
      }
    }

    for (const row of mobileScheme) {
      for (const cell of row) {
        for (const seat of cell) {
          seat.type = 'mobile';
        }
      }
    }

    for (const row of clearScheme) {
      for (const cell of row) {
        for (const seat of cell) {
          if (seat.type === 'mobile') {
            seat.type = 'standart';
          }
        }
      }
    }

    if (clearScheme.length) {
      clearScheme = this.assignPlaces(clearScheme);
      clearScheme = this.assignSeatsIds(clearScheme);
    }

    if (mobileScheme.length) {
      const firstRowNumber = clearScheme.filter((row) => row.length).length + 1;

      mobileScheme = this.assignPlaces(mobileScheme, firstRowNumber);
      mobileScheme = this.assignSeatsIds(mobileScheme, firstRowNumber);
    }

    this.scheme = [...mobileScheme, ...clearScheme];
  }

  // eslint-disable-next-line class-methods-use-this
  assignSeatsIds(scheme, firstRowNumber = 1) {
    const localScheme = JSON.parse(JSON.stringify(scheme));

    let rowNumber = firstRowNumber;

    localScheme.forEach((row, i) => {
      let columnNumber = 1;

      row.forEach((cell, j) => {
        if (cell.length) {
          // eslint-disable-next-line no-restricted-syntax
          for (const seat of localScheme[i][j]) {
            seat.seatId = rowNumber * PLACE_ID_BASE + columnNumber;
            columnNumber += 1;
          }
        }
      });

      if (row.length) {
        rowNumber += 1;
      }
    });

    return localScheme;
  }

  assignPlaces(scheme, firstRowNumber = 1) {
    const localScheme = JSON.parse(JSON.stringify(scheme));

    let rowNumber = firstRowNumber;

    let rowStart = 0;
    let rowStop = localScheme.length;
    let rowStep = 1;

    if (this.startCorner === START_CORNER_BOTTOM_LEFT || this.startCorner === START_CORNER_BOTTOM_RIGHT) {
      rowStart = localScheme.length - 1;
      rowStop = -1;
      rowStep = -1;
    }

    for (let i = rowStart; i !== rowStop; i += rowStep) {
      const row = localScheme[i];

      if (!row.length) {
        continue;
      }

      let columnStart = 0;
      let columnStop = row.length;
      let columnStep = 1;

      if (this.startCorner === START_CORNER_BOTTOM_RIGHT || this.startCorner === START_CORNER_TOP_RIGHT) {
        columnStart = row.length - 1;
        columnStop = -1;
        columnStep = -1;
      }

      let columnNumber = 1;

      for (let j = columnStart; j !== columnStop; j += columnStep) {
        const cell = localScheme[i][j];

        if (!cell.length) {
          continue;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const seat of cell) {
          seat.places = [];

          for (let k = 0; k < this.getSeatCapacity(seat.type); k += 1) {
            seat.places.push(rowNumber * PLACE_ID_BASE + columnNumber);
            columnNumber += 1;
          }

          if (columnStep === -1) {
            seat.places.reverse();
          }
        }

        if (columnStep === -1) {
          cell.reverse();
        }
      }

      rowNumber += 1;
    }

    return localScheme;
  }
}
