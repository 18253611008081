export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: true,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    filterable: true,
    sortable: true,
  },
  {
    key: 'kpp',
    type: 'text',
    label: 'КПП',
    filterable: true,
    sortable: true,
  },
  {
    key: 'taxSystemId',
    type: 'select',
    label: 'Система налогообложения',
    filterable: true,
    sortable: false,
  },
];
