<template>
  <div class="tag-info">
    <div
      class="items-index__cinemas"
      :id="`cinemas_${item.id}`"
    >{{convertCinemasToText(item.cinemas)}}</div>
    <b-tooltip
      v-if="item.cinemas && item.cinemas.length"
      :target="`cinemas_${item.id}`"
    >
      <div
        class="mb-1"
        v-for="cinema in item.cinemas"
        :key="cinema.id"
      >
        <div>{{ cinema.name }}, {{ cinema.city.name }}</div>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: () => ({}),
    },
  },
  methods: {
    convertCinemasToText(cinemas) {
      if (!cinemas.length) {
        return 'Все кинотеатры';
      }

      return cinemas.map((cinema) => `${cinema?.name} ${cinema?.city?.name}`).join(', ');
    },
  },
};
</script>

<style scoped>
.tag-info {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}
</style>
