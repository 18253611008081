<template>
  <div>
    <template v-if="type === 'date' || type === 'datetime'">
      <date-interval
        :value="localValue"
        :disabled="disabled"
        @selected="$emit('update', $event)"
      ></date-interval>
    </template>

    <template v-else-if="type === 'time'">
      <time-interval
        :value="localValue"
        :disabled="disabled"
        @change="$emit('update', $event)"
      ></time-interval>
    </template>

    <template v-else-if="type === 'interval'">
      <value-interval
        :value="localValue"
        :disabled="disabled"
        @change="$emit('update', $event)"
      ></value-interval>
    </template>

    <template v-else-if="type === 'select'">
      <div class="search-select-container">
        <b-form-select
          size="sm"
          :value="localValue"
          :options="localOptions"
          :disabled="disabled"
          @change="$emit('update', $event)"
        ></b-form-select>
      </div>
    </template>

    <template v-else-if="type === 'switcher'">
      <b-form-select
        size="sm"
        :disabled="disabled"
        :value="localValue"
        :options="[{value: null, text: ''}, {value: true, text: 'Да'}, {value: false, text: 'Нет'}]"
        @change="$emit('update', $event)"
      ></b-form-select>
    </template>

    <template v-else>
      <div class="text-search">
        <b-form-input
          type="text"
          size="sm"
          :disabled="disabled"
          :value="localValue"
          @change="$emit('update', $event)"
        ></b-form-input>
        <span class="trash" @click="$emit('update', '')"><icon icon="fa-times"></icon></span>
      </div>
    </template>
  </div>
</template>

<script>
import DateInterval from '../fields/DateInterval.vue';
import TimeInterval from '../fields/TimeInterval.vue';
import ValueInterval from '../fields/ValueInterval.vue';

export default {
  components: {
    DateInterval,
    ValueInterval,
    TimeInterval,
  },
  props: {
    value: {},
    options: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    localOptions() {
      return [
        {
          value: '',
          text: '-',
        },
        ...this.options,
      ];
    },

    localValue() {
      return typeof this.value === 'undefined' ? '' : this.value;
    },
  },
};
</script>

<style scoped>

.search-select-container {
  display:inline-block;
  width:100%;
  overflow:hidden;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  height: calc(1.8125rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0;
  margin: 0;
}
.search-select-container select {
  border: 0;
  width:calc(100% + 20px);
  padding-left: 5px;
  padding-right:20px;
}
</style>
