<template>
  <div class="orders p-2">
    <collection-provider
      collection="orders"
      ref="collection"
      :filter="{ cinemaId, showDatetimeStart, showDatetimeEnd }"
      :disable-fetching="!cinemaId"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :show-top-toolbar="false"
        >
          <template #cell(id)="data">
            <a
              class="text-primary cursor-pointer"
              @click="openOrderForm(data.item)"
            >{{ data.item.id }}</a>
          </template>

          <template #cell(status)="data">
            <icon
              v-if="data.item.refunded"
              v-b-tooltip="'Есть возвраты'"
              class="text-primary mr-1"
              icon="fa-undo"
            ></icon>
            <icon
              v-else-if="data.item.approvedAt"
              v-b-tooltip="'Подтвержден'"
              class="text-success mr-1"
              icon="fa-check"
            ></icon>
            <icon
              v-else
              v-b-tooltip="'Не подтвержден'"
              class="text-danger mr-1"
              icon="fa-times"
            ></icon>
          </template>

          <template #cell(show)="data">
            <div
              :id="`order_show__${data.item.id}`"
              class="show_column"
              v-if="data.item.tickets.length"
            >
              <span class="show_date text-primary">
                {{ data.item.tickets[0].show.calendarDate }}
              </span>
              <span class="show_time text-primary">
                {{ data.item.tickets[0].show.time }}
              </span>
              <span class="show_movie">
                {{ data.item.tickets[0].show.release.movie.name }}
              </span>
            </div>
            <b-tooltip
              :target="`order_show__${data.item.id}`"
              v-if="data.item.tickets.length && data.item.tickets[0].show.release.movie.name.length > 36"
            >
              <div class="mb-1">
                {{ data.item.tickets[0].show.release.movie.name }}
              </div>
            </b-tooltip>
          </template>

          <template #cell(price)="data">
            <span
              :id="`order_content__${data.item.id}`"
              class="order_price text-primary"
            >
              {{ formatPrice(data.item.price) }}
            </span>
            <span class="order_contents">
              ({{ formatOrderContents(data.item) }})
            </span>
            <b-tooltip
              :target="`order_content__${data.item.id}`"
              v-if="data.item.tickets.length"
            >
              <div class="mb-1">
                <div
                  v-for="ticket in data.item.tickets"
                >
                  {{ Math.floor(ticket.placeId / 1000) }} ряд {{ ticket.placeId % 1000 }} место
                </div>
              </div>
            </b-tooltip>
          </template>

          <template #cell(paymentTypeId)="data">
            {{ data.item.paymentType ? data.item.paymentType.name : '' }}
          </template>

          <template #cell(appGroupId)="data">
            {{ data.item.appGroup.name }}
          </template>

          <template #cell(customer)="data">
            <div
              :id="`order_customer__${data.item.id}`"
              v-if="data.item.customer"
            >
              {{ formatter.phoneFormat(data.item.customer.phone) }}
            </div>
            <b-tooltip
              :target="`order_customer__${data.item.id}`"
              v-if="data.item.customer"
            >
              <div class="mb-1">
                {{ formatCustomerData(data.item.customer) }}
              </div>
            </b-tooltip>
          </template>

          <template #cell(createdAt)="data">
            {{ timeHelper.format(data.item.createdAt, timeHelper.datetimeFormat) }}
          </template>

        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.order.show"
      size="xl"
      collection="orders"
      :edit-title="`Информация по заказу №${modals.order.id}`"
      :id="modals.order.compositeId"
      :hide-footer="true"
      @close="modals.order.show = false"
    >
      <template #default="{ data }">
        <order-modal
          v-if="data.item.id"
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @refund-order="refundOrder(data.item.compositeId, $event)"
        ></order-modal>
        <div v-else>Загрузка..</div>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { Formatter } from '../../utils/formatter';
import { confirmDialog } from '../../utils/dialogs';
import TimeHelper from '../../utils/datetime';

import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';

import OrderModal from './OrderModal.vue';
import fields from './_index.fields';

export default {
  components: {
    CollectionProvider,
    Datatable,
    OrderModal,
    ModalModelForm,
  },
  props: {
    cinemaId: {
      default: null,
    },
  },
  data() {
    return {
      formatter: Formatter,
      timeHelper: TimeHelper,
      datatable: {
        fields,
      },
      modals: {
        order: {
          show: false,
          id: null,
          compositeId: null,
        },
      },
    };
  },
  computed: {
    showDatetimeStart() {
      return typeof this.$route.query.showDatetimeStart === "undefined" ? null : this.$route.query.showDatetimeStart;
    },
    showDatetimeEnd() {
      return typeof this.$route.query.showDatetimeEnd === "undefined" ? null : this.$route.query.showDatetimeEnd;
    },
  },
  methods: {
    formatCustomerData(customer) {
      let elements = [
        customer.name || customer.surname ? customer.name + ' ' + customer.surname : null,
        customer.email,
      ];

      return elements.filter((item) => item).join(', ');
    },

    formatPrice(price) {
      return new Intl.NumberFormat("ru-RU").format(price);
    },

    formatOrderContents(order) {
      let elements = [];

      if (order.tickets.length > 0) {
        elements.push(order.tickets?.length + ' ' + this.getNoun(order.tickets.length, 'билет', 'билета', 'билетов'));
      }

      if (order.products.length > 0) {
        elements.push(order.products?.length + ' ' + this.getNoun(order.products.length, 'товар', 'товара', 'товаров'));
      }

      if (order.certificates.length > 0) {
        elements.push(order.certificates?.length + ' ' + this.getNoun(order.certificates.length, 'сертификат', 'сертификата', 'сертификатов'));
      }

      return elements.join(', ');
    },

    getNoun(number, one, two, five) {
      let n = number % 100;

      if (n >= 5 && n <= 20) {
        return five;
      }

      n = number % 10;

      if (n == 1) {
        return one;
      }

      if (n >= 2 && n <= 4) {
        return two;
      }

      return five;
    },

    openOrderForm(item) {
      this.modals.order.id = item.id;
      this.modals.order.compositeId = item.compositeId;
      this.modals.order.show = true;
    },

    async refundOrder(compositeId, params) {
      let question = 'Вернуть заказ и денежные средства?';

      if (params.includeServiceFee) {
        question = 'Вернуть заказ, денежные средства и сервисный сбор?';
      }

      if (await confirmDialog(question)) {
        const [err, result] = await this.$store.dispatch('data/orders/refundOrder', {
          compositeId,
          params
        });

        if (result) {
          this.$notify({
            type: 'success',
            text: 'Возврат активирован',
          });
        }
        if (err) {
          if (err.status && err.status === 422) {
            this.$notify({
              type: 'error',
              text: err.data.error,
            });
          }
        }

        this.modals.order.id = null;
        this.modals.order.compositeId = null;
        this.modals.order.show = false;

        this.$refs.collection.fetchItems();
      }
    },
  },
};
</script>

<style scoped>
.orders >>> .column-id {
  min-width: 100px;
  text-align: left;
}
.show_date {
  display: inline-block;
  min-width: 80px;
  white-space: nowrap;
  vertical-align: bottom;
}
.show_time {
  display: inline-block;
  min-width: 56px;
  white-space: nowrap;
  vertical-align: bottom;
}
.show_movie {
  display: inline-block;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: bottom;
}
.order_price {
  display: inline-block;
  min-width: 50px;
  white-space: nowrap;
}
</style>
