<template>
  <div>
    <navbar :items="navItems"></navbar>
    <topbar></topbar>
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import nav from '../_nav';
import Navbar from './Navbar.vue';
import Topbar from './Topbar.vue';

export default {
  components: {
    Navbar,
    Topbar,
  },
  computed: {
    navItems() {
      return this.filterItemsByPermissions(nav.items);
    },
  },
  methods: {
    filterItemsByPermissions(arr) {
      return arr.filter((item) => {
        if (Array.isArray(item.children)) {
          // eslint-disable-next-line no-param-reassign
          item.children = this.filterItemsByPermissions(item.children);

          if (!item.children.length) {
            return false;
          }
        }

        if (item.routeName) {
          const permissions = this.findRoutePermissions(this.$router.options.routes, item.routeName);

          if (Array.isArray(permissions) && permissions.length && !this.$user.canSome(permissions)) {
            return false;
          }
        }

        return true;
      });
    },

    findRoutePermissions(routes, name) {
      for (const route of routes) {
        if (route.name && route.name === name) {
          return route.meta && route.meta.permissions ? route.meta.permissions : [];
        }

        if (Array.isArray(route.children)) {
          const permissions = this.findRoutePermissions(route.children, name);

          if (permissions) {
            return permissions;
          }
        }
      }

      return false;
    },
  },
};
</script>

<style scoped></style>
