<template>
  <b-row align-v="center" class="mb-2">
    <b-col style="width: 220px; flex: none;">
      <div class="selection-form__title">{{ name }}</div>
    </b-col>
    <b-col>
      <b-select
        size="sm"
        :value="include"
        :options="options"
        :disabled="disabled"
        @change="$emit('include', $event)"
      ></b-select>
    </b-col>
    <b-col>
      <b-select
        size="sm"
        :value="exclude"
        :options="options"
        :disabled="disabled"
        @change="$emit('exclude', $event)"
      ></b-select>
    </b-col>
  </b-row>
</template>

<script>

export default {
  props: {
    name: {
      default: '',
    },
    options: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
    include: {
      default: () => [],
    },
    exclude: {
      default: () => [],
    },
  },
};
</script>

<style scoped>
.selection-form__title {
  line-height: 30px;
  font-weight: 500;
}
</style>
