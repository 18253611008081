import _factory from './_factory';
import datetime2 from '../../utils/datetime2';

const ratios = [
  'ratioMonday',
  'ratioTuesday',
  'ratioWednesday',
  'ratioThursday',
  'ratioFriday',
  'ratioSaturday',
  'ratioSunday',
];

export default {
  ..._factory('targets', {
    getters: {
      sortedItems: (state) => {
        const items = JSON.parse(JSON.stringify(state.items));

        items.sort((a, b) => {
          if (datetime2.isDateBefore(a.dateStart, b.dateStart)) {
            return -1;
          }

          if (datetime2.isSameDay(a.dateStart, b.dateStart)) {
            if (datetime2.isDateBefore(a.dateEnd, b.dateEnd)) {
              return -1;
            }

            return 1;
          }

          if (datetime2.isDateAfter(a.dateStart, b.dateStart)) {
            return 1;
          }

          return 0;
        });

        return items;
      },

      getTotalRatio: () => (target, getDay) => {
        const dates = datetime2.getDates(target.dateStart, target.dateEnd);

        let totalRatio = 0;

        for (const date of dates) {
          totalRatio += +target[ratios[getDay(date) - 1]];
        }

        return totalRatio;
      },

      groupByPeriods: (state, getters) => (periods, getDay) => {
        const result = [];

        periods.forEach((period) => {
          const dates = datetime2.getDates(period.dateStart, period.dateEnd);

          let ticketsPrice = 0;
          let ticketsCount = 0;
          let productsPrice = 0;
          let productsCount = 0;
          let ticketsPricePercent = 0;
          let ticketsCountPercent = 0;

          let counter = 0;

          for (const date of dates) {
            const targets = getters.sortedItems.filter((_item) => datetime2.isDateSameOrAfter(date, _item.dateStart) && datetime2.isDateSameOrBefore(date, _item.dateEnd));
            const target = targets[targets.length - 1];

            if (target) {
              const totalRatio = getters.getTotalRatio(target, getDay);

              const ratioKey = ratios[getDay(date) - 1];

              ticketsPrice += target[ratioKey] * (target.ticketsPrice / totalRatio);
              ticketsCount += target[ratioKey] * (target.ticketsCount / totalRatio);
              productsPrice += target[ratioKey] * (target.productsPrice / totalRatio);
              productsCount += target[ratioKey] * (target.productsCount / totalRatio);
              ticketsPricePercent += target.ticketsPricePercent;
              ticketsCountPercent += target.ticketsCountPercent;

              counter += 1;
            }
          }

          result.push({
            ...period,
            ticketsPrice,
            ticketsCount,
            productsPrice,
            productsCount,
            ticketsPricePercent: counter ? (ticketsPricePercent / counter) : 0,
            ticketsCountPercent: counter ? (ticketsCountPercent / counter) : 0,
          });
        });

        return result;
      },
    },
  }),
};
