var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('analytics-provider',{attrs:{"table":"shows_occupation","page":"1","limit":"5","sort-by":"occupation","sort-desc":true,"params":{
    cinema_id: _vm.cinemaId,
    date_start: _vm.dateStart,
    date_end: _vm.dateEnd,
  },"disabled":!_vm.dateStart || !_vm.dateEnd},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var topItems = ref.items;
  var topItemsLoading = ref.loading;
return [_c('analytics-provider',{attrs:{"table":"shows_occupation","page":"1","limit":"5","sort-by":"occupation","sort-desc":false,"params":{
        cinema_id: _vm.cinemaId,
        date_start: _vm.dateStart,
        date_end: _vm.dateEnd,
      },"disabled":!_vm.dateStart || !_vm.dateEnd},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var bottomItems = ref.items;
      var bottomItemsLoading = ref.loading;
return [_c('b-table',{staticClass:"datatable border",attrs:{"fields":_vm.fields,"items":_vm.$array.union(topItems, _vm.$array.reverse(bottomItems), 'movie_id'),"striped":true,"hover":false,"no-local-sorting":true,"busy":topItemsLoading || bottomItemsLoading,"show-empty":true,"empty-text":(topItemsLoading || bottomItemsLoading) ? 'Загрузка...' : 'Нет данных'},scopedSlots:_vm._u([{key:"cell(release_name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.movie_name)+" "+_vm._s(data.item.format_name)+" ")]}},{key:"cell(occupation)",fn:function(data){return [_c('span',{class:[data.item.occupation < 5 || data.item.occupation > 70 ? 'text-danger' : '']},[_vm._v(_vm._s(data.item.occupation))])]}},{key:"cell(average_occupation)",fn:function(data){return [_c('span',{class:[data.item.average_occupation < 5 || data.item.average_occupation > 70 ? 'text-danger' : '']},[_vm._v(_vm._s(data.item.average_occupation))])]}},{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$datetime.formatDate(data.item.date))+" ")]}},{key:"cell(time)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$datetime.convertTime(data.item.time))+" ")]}}],null,true)})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }