import _factory from './_factory';

export default {
  ..._factory('holidays', {
    state: {
      page: null,
      limit: null,
    },
  }),
};
