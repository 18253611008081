/* eslint-disable no-return-await */
/* eslint-disable no-restricted-syntax */
import http from './http';
import { API_VERSION } from '../config';
import { createQuery, convertResponse, convertError } from './utilities';

export async function fetchShows({
  filter, params,
} = {}) {
  const query = createQuery({
    filter, params,
  });

  try {
    const response = await http.get(`/${API_VERSION}/sales?${query}`);
    return [null, convertResponse(response)];
  } catch (error) {
    return convertError(error);
  }
}

export async function getShow(id) {
  try {
    const response = await http.get(`/${API_VERSION}/sales/${id}`);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function updateShow(id, values) {
  try {
    const response = await http.put(`/${API_VERSION}/sales/${id}`, values);
    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}

export async function toggleSales({
  cinemaId, hallId, dates, showId,
}, values) {
  try {
    const params = [];

    if (cinemaId) {
      params.push(`cinema_id=${cinemaId}`);
    }

    if (hallId) {
      params.push(`hall_id=${hallId}`);
    }

    if (dates) {
      params.push(dates.map((date) => `dates[]=${date}`).join('&'));
    }

    if (showId) {
      params.push(`show_id=${showId}`);
    }

    const response = await http.post(`/${API_VERSION}/toggle_sales?${params.join('&')}`, values);

    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}

export async function setMinPrice({
  cinemaId, releaseId, dateFrom, dateTo, timeFrom, timeTo,
}, { minPrice }) {
  try {
    const params = [];

    if (cinemaId) {
      params.push(`cinema_id=${cinemaId}`);
    }

    if (releaseId) {
      params.push(`release_id=${releaseId}`);
    }

    if (dateFrom) {
      params.push(`date_from=${dateFrom}`);
    }

    if (dateTo) {
      params.push(`date_to=${dateTo}`);
    }

    if (timeFrom) {
      params.push(`time_from=${timeFrom}`);
    }

    if (timeTo) {
      params.push(`time_to=${timeTo}`);
    }

    const response = await http.post(`/${API_VERSION}/patch_shows_min_price?${params.join('&')}`, {
      min_price: minPrice,
    });

    return [null, response.data || []];
  } catch (error) {
    console.log(error);
    return convertError(error);
  }
}
