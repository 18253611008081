var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('collection-provider',{ref:"collection",attrs:{"collection":"holidays"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('div',{staticClass:"calendar p-3"},[_c('year',{attrs:{"year":_vm.year},on:{"select-year":function($event){_vm.year = $event}}}),_c('calendar',{attrs:{"year":_vm.year,"loading":data.loading},scopedSlots:_vm._u([{key:"month",fn:function(ref){
var year = ref.year;
var month = ref.month;
return [_c('month',{attrs:{"year":year,"month":month},scopedSlots:_vm._u([{key:"day",fn:function(ref){
var year = ref.year;
var month = ref.month;
var date = ref.date;
return [_c('day',{attrs:{"id":("day_" + month + "_" + date),"year":year,"month":month,"date":date,"holiday":_vm.findHoliday(data.items, year, month, date)},nativeOn:{"click":function($event){return _vm.togglePopover(month, date)}}})]}}],null,true)})]}}],null,true)}),(_vm.popover.show)?_c('b-popover',{attrs:{"placement":"bottom","target":_vm.popover.target,"show":_vm.popover.show},on:{"update:show":function($event){return _vm.$set(_vm.popover, "show", $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.popover.title))]),_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.popover.show = false}}},[_c('icon',{attrs:{"icon":"fa-times"}})],1)])]},proxy:true}],null,true)},[_c('day-form',{attrs:{"year":_vm.year,"month":_vm.popover.month,"date":_vm.popover.date,"holiday":_vm.findHoliday(data.items, _vm.year, _vm.popover.month, _vm.popover.date)},on:{"remove":function($event){return _vm.removeHoliday($event)},"saved":function($event){_vm.popover.show = false, _vm.$refs.collection.fetchItems()}}})],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }