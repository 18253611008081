// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import * as svgicon from 'vue-svgicon';
import Notifications from 'vue-notification';
import AsyncComputed from 'vue-async-computed';
import PortalVue from 'portal-vue';
import vClickOutside from 'v-click-outside';
import VueHtmlToPaper from 'vue-html-to-paper';
import VMdEditor from '@kangc/v-md-editor';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import App from './App.vue';
import router from './router';
import store from './store';
import UserPlugin from './plugins/user';
import HttpPlugin from './plugins/http';
import DatetimePlugin from './plugins/datetime';
import ArrayPlugin from './plugins/array';
import FileManagerPlugin from './plugins/fileManager';
import FileManagerPlugin2 from './plugins/fileManager2';
import Icon from './components/Icon.vue';
import Scroll from './components/Scroll.vue';
import { VMdLang } from './lang/VMd.js';

import {
  registerFile,
  uploadFile,
  uploadFile2,
  getFilepath,
  downloadFile,
} from './api/file';

VMdEditor.use(githubTheme);
VMdEditor.lang.add({
  'zh-CN': VMdLang,
});

Vue.use(svgicon, {
  tagName: 'svgicon',
});

Vue.component('icon', Icon);

Vue.use(HttpPlugin);
Vue.use(UserPlugin);
Vue.use(DatetimePlugin);
Vue.use(ArrayPlugin);
Vue.use(FileManagerPlugin, {
  registerFile,
  uploadFile,
  getFilepath,
  downloadFile,
});
Vue.use(FileManagerPlugin2, {
  uploadFile: uploadFile2,
});
Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.use(AsyncComputed);
Vue.use(PortalVue);
Vue.use(vClickOutside);
Vue.use(VueHtmlToPaper);
Vue.use(VMdEditor);
Vue.component('scroll', Scroll);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
