<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
import ChartJs from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJs.register(annotationPlugin);

export default {
  props: {
    data: {
      default: () => [],
    },
    colors: {
      default: () => [],
    },
    yMin: {
      default: 0,
    },
    yMax: {
      default: 100,
    },
    yStep: {
      default: 25,
    },
    yLabel: {
      default: '%',
    },
    yAutoScale: {
      default: false,
    },
    yAutoScalePadding: {
      default: 0.1,
    },
    yAutoScaleRoundTo: {
      default: 10,
    },
    periods: {
      default: () => [],
    },
    aspectRatio: {
      default: 1,
    },
    textColor: {
      default: '#6C757D',
    },
    tension: {
      default: 0,
    },
    xTicksAuto: {
      default: false,
    },
    boldLineIndex: {
      default: -1,
    },
    lineWidth: {
      default: 2,
    },
    showTooltips: {
      default: false,
    },
    labels: {
      default: () => [],
    },
  },
  data() {
    return {
      // mChart: null, // всё правильно! иначе даёт ошибку...
    };
  },
  watch: {
    data: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    if (this.mChart) {
      this.mChart.destroy();
    }
  },
  computed: {
    datasets() {
      return this.data.map((data, index) => ({
        label: this.labels[index] || '',
        data,
        fill: false,
        borderColor: this.colors[index] || '#007BFF',
        borderWidth: this.boldLineIndex === index ? 2 * this.lineWidth : this.lineWidth,
        tension: this.tension,
        pointHoverRadius: 0,
      }));
    },

    yMinCalculated() {
      if (!this.yAutoScale) {
        return this.yMin;
      }

      const minValues = [];

      for (const data of this.data) {
        minValues.push(Math.min(...data));
      }

      const minValue = Math.min(...minValues) * (1 - this.yAutoScalePadding);

      if (minValue > 0) {
        return 0;
      }

      return Math.round(minValue / this.yAutoScaleRoundTo) * this.yAutoScaleRoundTo;
    },

    yMaxCalculated() {
      if (!this.yAutoScale) {
        return this.yMax;
      }

      const maxValues = [];

      for (const data of this.data) {
        maxValues.push(Math.max(...data));
      }

      const maxValue = Math.max(...maxValues) * (1 + this.yAutoScalePadding);

      return Math.round(maxValue / this.yAutoScaleRoundTo) * this.yAutoScaleRoundTo;
    },

    yStepCalculated() {
      if (!this.yAutoScale) {
        return this.yStep;
      }

      return (this.yMaxCalculated - this.yMinCalculated) / 2;
    },
  },
  methods: {
    renderChart() {
      if (this.mChart) {
        this.mChart.destroy();
      }

      this.mChart = new ChartJs(this.$refs.canvas, {
        type: 'line',
        data: {
          labels: this.periods,
          datasets: this.datasets,
        },
        options: {
          layout: {
            padding: {
              bottom: 5,
            },
          },
          animation: {
            duration: 0,
          },
          responsive: true,
          aspectRatio: this.aspectRatio,
          maintainAspectRatio: true,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: this.showTooltips,
              usePointStyle: true,
            },
          },
          interaction: {
            intersect: false,
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            x: {
              grid: {
                display: this.xTicksAuto,
                drawBorder: this.xTicksAuto,
                drawOnChartArea: this.xTicksAuto,
                drawTicks: true,
              },
              ticks: {
                display: this.xTicksAuto,
              },
            },
            y: {
              grid: {
                display: true,
                drawBorder: false,
                drawOnChartArea: true,
                drawTicks: true,
              },
              min: this.yMinCalculated,
              max: !this.yAutoScale ? null : this.yMaxCalculated,
              ticks: {
                display: false,
                stepSize: !this.yAutoScale ? 25 : this.yStepCalculated,
              },
            },
          },
        },
        plugins: [
          {
            afterDraw: (chart) => {
              const { ctx } = chart;
              ctx.save();

              const xAxis = chart.scales.x;
              const yAxis = chart.scales.y;

              ctx.fillStyle = this.textColor;

              yAxis.ticks.forEach((tick, index) => {
                if (index === 0) {
                  return;
                }

                ctx.fillText(`${Math.round(tick.value)}${this.yLabel}`, 0, yAxis.getPixelForTick(index) + 10);
              });

              if (!this.xTicksAuto) {
                xAxis.ticks.forEach((tick, index) => {
                  if (index === 0) {
                    ctx.textAlign = 'left';
                    ctx.fillText(tick.label, 0, yAxis.height + 10);
                  }

                  if (index === xAxis.ticks.length - 1) {
                    ctx.textAlign = 'right';
                    ctx.fillText(tick.label, xAxis.getPixelForTick(index), yAxis.height + 10);
                  }
                });
              }

              ctx.restore();
            },
          },
        ],
      });
    },
  },
};
</script>
