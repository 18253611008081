<template>
  <div class="table-container mb-0">
    <collection-provider
      collection="banners"
      ref="collection"
      :page="null"
      :limit="null"
      :disableFetching="disableFetching"
    >
      <template #default="{ data }">
        <datatable-drag
          v-bind="{ ...data }"
          :is-panel-actions="false"
          :is-item-actions="false"
          :fields="datatable.fieldsPosition"
          :permissions="$user.permissions"
          @change-positions="changePositions($event)"
        >
          <template #toolbar-content>
            <div class="d-flex justify-content-between align-items-center w-100">
              <div class="w-300">
                <dictionary-provider name="cinemas">
                  <template #default="{ items }">
                    <b-form-select
                      size="sm"
                      :value="data.filter.cinemaId"
                      :options="convertCinemas(items)"
                      :disabled="data.loading"
                      @change="changeFilterCinema($event)"
                    >
                    </b-form-select>
                  </template>
                </dictionary-provider>
              </div>
              <div class="d-flex justify-content-center">
                <calendar-picker
                  calendar-type="week"
                  :date="dateStart"
                  @select-date="changeFilterDates($event)"
                ></calendar-picker>
              </div>
              <div class="w-300"></div>
            </div>
          </template>

          <template #cell-period="data">
            <template v-if="data.item.dateStart">
              c {{ formatDate(data.item.dateStart) }}
            </template>
            <template v-if="data.item.dateEnd">
              по {{ formatDate(data.item.dateEnd) }}
            </template>
          </template>

          <template #cell-type="data">
            {{ getBannerTypeName(data.item.type) }}
          </template>

          <template #cell-rotation="data">
            {{ data.item.rotation }} {{ getDeclOfNum(data.item.rotation, ['секунда', 'секунды', 'секунд']) }}
          </template>

          <template #cell-active="data">
            {{ getBannerActiveName(data.item.active) }}
          </template>
        </datatable-drag>
      </template>
    </collection-provider>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import fieldsPosition from './_index.fields.position';

import DictionaryProvider from '../../providers/DictionaryProvider';
import CollectionProvider from '../../providers/CollectionProvider';

import CalendarPicker from '../../components/CalendarPicker.vue';
import DatatableDrag from '../../components/datatable/DatatableDrag.vue';

import datetime from '../../utils/datetime';
import declOfNum from '../../utils/declOfNum';

export default {
  components: {
    DictionaryProvider,
    CollectionProvider,
    DatatableDrag,
    CalendarPicker,
  },
  data() {
    return {
      cinemaId: null,
      disableFetching: true,
      dateStart: null,
      dateEnd: null,
      datatable: {
        fieldsPosition,
      },
      bannerActiveStatuses: [
        {
          value: null,
          text: '',
        },
        {
          value: false,
          text: 'Нет',
        },
        {
          value: true,
          text: 'Да',
        },
      ],
      bannerTypeCaptions: [
        {
          value: null,
          text: '',
        },
        {
          value: 'movies',
          text: 'Фильм',
        },
        {
          value: 'advPromos',
          text: 'Акция',
        },
        {
          value: 'others',
          text: 'Другое',
        },
      ],
    };
  },
  created() {
    this.changeFilterDates(new Date());
  },
  methods: {
    ...mapActions({
      setFilter: 'data/banners/setFilter',
      setParam: 'data/banners/setParam',
      fetchItems: 'data/banners/fetchItems',
    }),

    getDeclOfNum(n, titles = []) {
      return declOfNum(n, titles);
    },

    formatDate(date) {
      return datetime.formatDate(date);
    },

    async changePositions(event) {
      const init = {
        cinema_id: this.cinemaId ? this.cinemaId : '',
        date_start: this.convertDateToDbFormat(this.dateStart),
        date_end: this.convertDateToDbFormat(this.dateEnd),
      };
      const values = { ...event, ...init };
      await this.$store.dispatch('data/banners/changePositions', { values });
    },

    convertDateToDbFormat(date) {
      return date ? datetime.convertDateToDbFormat(date) : null;
    },

    async changeFilterDates(date) {
      const { dateStart, dateEnd } = datetime.getWeekByDate(date);

      this.dateStart = dateStart;
      this.dateEnd = dateEnd;

      if (!this.disableFetching) {
        this.setFilter({ field: 'dateStartLte', value: datetime.convertDateToDbFormat(dateEnd) });
        this.setFilter({ field: 'dateEndGte', value: datetime.convertDateToDbFormat(dateStart) });

        await this.fetchItems();
      }
    },

    async changeFilterCinema($event) {
      this.cinemaId = $event;
      this.disableFetching = false;

      this.setFilter({ field: 'cinemaId', value: $event });
      this.setFilter({ field: 'dateStartLte', value: datetime.convertDateToDbFormat(this.dateEnd) });
      this.setFilter({ field: 'dateEndGte', value: datetime.convertDateToDbFormat(this.dateStart) });
      this.setParam({ field: 'includePositions', value: 1 });

      await this.fetchItems();
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        value: cinema.id,
        text: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    getBannerActiveName(value) {
      return this.bannerActiveStatuses.find((status) => status.value === value)?.text;
    },

    getBannerTypeName(value) {
      return this.bannerTypeCaptions.find((status) => status.value === value)?.text;
    },
  },
};
</script>

<style scoped>
.w-300 {
  max-width: 300px;
  width: 100%;
}
.table-container >>> .column-name {
  min-width: 300px;
}
.table-container >>> .column-active {
  width: 300px;
}
.table-container >>> .column-type {
  width: 300px;
}
.table-container >>> .column-period {
  width: 300px;
}
</style>
