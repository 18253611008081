<template>
  <div
    class="timeline-show d-flex align-items-end flex-column h-100 w-100"
    :class="{'timeline-show_highlight': highlight, 'timeline-show_selected': selected}"
    :id="`show_${show.id}`"
  >
    <div class="timeline-show__name align-self-start" :title="movieName">
      {{movieName}}
    </div>
    <div class="timeline-show__info align-self-start d-flex mt-auto align-self-stretch justify-content-between">
      <div>{{formatName}}</div>
      <div>{{show.ticketsCount}}</div>
    </div>
    <div class="timeline-show__times d-flex align-self-stretch justify-content-center flex-wrap mt-1">
      <div class="mr-auto align-self-end">{{timeStart}}</div>
      <div
        class="cursor-pointer timeline-show_lock"
        :class="{'timeline-show_locked': show.locked}"
        @click.stop="toggleLocked()"
      ><icon icon="fa-lock"></icon></div>
      <div class="ml-auto align-self-end">{{timeEnd}}</div>
    </div>
    <span
      v-if="isChangingAllowed"
      class="timeline-show__remove"
      @click.stop="$emit('remove')"
    >
      <icon icon="fa-times"></icon>
    </span>
  </div>
</template>

<script>
import datetime from '../../utils/datetime';

export default {
  props: {
    show: {
      default: () => ({}),
    },
    highlight: {
      default: false,
    },
    selected: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    movieName() {
      return this.show?.release?.movie?.name;
    },

    formatName() {
      return this.show?.release?.format?.name;
    },

    timeStart() {
      return datetime.formatTime(this.show.time);
    },

    timeEnd() {
      return datetime.formatTime(datetime.getIntervalEndTime(this.show.time, this.show.release.duration + this.show.advertisementsDuration));
    },

    isChangingAllowed() {
      if (this.disabled || this.show.locked || this.show.ticketsCount || (this.show.appGroups && this.show.appGroups.length)) {
        return false;
      }

      return true;
    },

    isLocked() {
      return !!(this.show.locked || this.show.ticketsCount || (this.show.appGroups && this.show.appGroups.length));
    },
  },
  methods: {
    toggleLocked() {
      this.$emit('toggle-locked', !this.show.locked);
    },
  },
};
</script>

<style scoped>
.timeline-show {
  background-color: #fff;
  border-right: 1px solid #ccc;
  padding: 2px;
}
.timeline-show__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  font-size: 11px;
}
.timeline-show__info {
  font-size: 11px;
  color: #555;
}
.timeline-show__times {
  font-size: 10px;
  max-width: 100%;
}
.timeline-show__times > div {
  text-align: center;
}
.timeline-show__remove {
  position: absolute;
  right: 0;
  top: 0;
  color: #ccc;
  cursor: pointer;
  font-size: 10px;
}
.timeline-show__remove:hover {
  color: #AA0000;
  font-size: 15px;
  margin-top: -5px;
  margin-right: -3px;
}
.timeline-show_highlight {
  background-color: #dbe9f9;
}
.timeline-show_selected {
  background-color: #fff3e0;
}
.timeline-show_lock {
  font-size: 14px;
}
.timeline-show_locked {
  color: #AA0000;
}
</style>
