<template>
  <div class="datepicker-interval-wrap">
    <b-form-row>
      <b-col cols="6">
        <date-time
          :value="from"
          :disabled="disabled"
          :placeholder="placeholderFrom"
          @selected="selectedFrom"
        ></date-time>
      </b-col>
      <b-col cols="6">
        <date-time
          :value="to"
          :disabled="disabled"
          :placeholder="placeholderTo"
          @selected="selectedTo"
        ></date-time>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import DateTime from './DateTime.vue';

export default {
  components: {
    DateTime,
  },
  props: {
    value: {
      default: () => ({
        from: '',
        to: '',
      }),
    },
    placeholderFrom: {
      type: String,
      default: 'От',
    },
    placeholderTo: {
      type: String,
      default: 'До',
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    from() {
      return this.value && this.value.from;
    },
    to() {
      return this.value && this.value.to;
    },
  },
  methods: {
    selectedFrom(value) {
      this.$emit('selected', {
        from: value,
        to: this.to || '',
      });
    },
    selectedTo(value) {
      this.$emit('selected', {
        from: this.from || '',
        to: value,
      });
    },
  },
};
</script>
