<template>
  <div class="table-container">
    <collection-provider
      collection="advPromotions"
      ref="collection"
      :page="null"
      :limit="null"
      :disableFetching="disableFetching"
    >
      <template #default="{ data }">
        <datatable-drag
          v-bind="{ ...data }"
          :is-panel-actions="false"
          :is-item-actions="false"
          :smart-bottom-toolbar="false"
          :show-bottom-toolbar="false"
          :fields="datatable.fieldsPosition"
          :permissions="$user.permissions"
          @change-positions="changePositions($event)"
        >
          <template #toolbar-content>
            <div class="d-flex justify-content-between align-items-center w-100">
              <div class="w-300">
                <dictionary-provider name="cinemas">
                  <template #default="{ items }">
                    <toggle-list
                      placeholder="Выберите кинотеатр"
                      :options="convertFilterCinemas(items)"
                      :hideSelected="false"
                      :limit="1"
                      :value="convertToValue(data.filter.cinemaIds, items)"
                      @change="changeFilterCinema($event)"
                    ></toggle-list>
                  </template>
                </dictionary-provider>
              </div>
              <div class="d-flex justify-content-center">
                <calendar-picker
                  calendar-type="week"
                  :date="dateStart"
                  @select-date="changeFilterDates($event)"
                ></calendar-picker>
              </div>
              <div class="w-300"></div>
            </div>
          </template>

          <template #cell-name="data">
            <div class="w-300">{{data.item.name}}</div>
          </template>

          <template #cell-period="data">
            <div class="w-300">
              <template v-if="data.item.dateStart">
                c {{formatDate(data.item.dateStart)}}
              </template>
              <template v-if="data.item.dateEnd">
                по {{formatDate(data.item.dateEnd)}}
              </template>
            </div>
          </template>

          <template #cell-appGroups="data">
            <div class="w-300">{{$array.convertToText(data.item.appGroups, 'Все приложения')}}</div>
          </template>

          <template #cell-cinemas="data">
            <div class="info">
              <div :id="`cinemas_${data.item.id}`">{{ convertCinemasToText(data.item.cinemas) }}</div>
              <b-tooltip
                v-if="data.item.cinemas && data.item.cinemas.length"
                :target="`cinemas_${data.item.id}`"
              >
                <div
                  class="mb-1"
                  v-for="cinema in data.item.cinemas"
                  :key="cinema.id"
                >
                  <div>{{ cinema.name }}</div>
                </div>
              </b-tooltip>
            </div>
          </template>

          <template #cell-active="data">
            {{getAdvPromotionActiveName(data.item.active)}}
          </template>
        </datatable-drag>
      </template>
    </collection-provider>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import fieldsPosition from './_index.fields.position';

import DictionaryProvider from '../../providers/DictionaryProvider';
import CollectionProvider from '../../providers/CollectionProvider';

import CalendarPicker from '../../components/CalendarPicker.vue';
import DatatableDrag from '../../components/datatable/DatatableDrag.vue';
import ToggleList from '../../components/fields/ToggleList.vue';

import datetime from '../../utils/datetime';

export default {
  components: {
    CollectionProvider,
    DictionaryProvider,
    DatatableDrag,
    CalendarPicker,
    ToggleList,
  },
  data() {
    return {
      disableFetching: true,
      dateStart: null,
      dateEnd: null,
      datatable: {
        fieldsPosition,
      },
      advPromotionActiveStatuses: [
        {
          value: null,
          text: '',
        },
        {
          value: false,
          text: 'Нет',
        },
        {
          value: true,
          text: 'Да',
        },
      ],
    };
  },
  created() {
    this.changeFilterDates(new Date());
  },
  methods: {
    ...mapActions({
      setFilter: 'data/advPromotions/setFilter',
      fetchItems: 'data/advPromotions/fetchItems',
    }),

    formatDate(date) {
      return datetime.formatDate(date);
    },

    getAdvPromotionActiveName(value) {
      return this.advPromotionActiveStatuses.find((status) => status.value === value)?.text;
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        value: cinema.id,
        text: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    convertFilterCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    convertCinemasToText(cinemas) {
      if (!cinemas.length) {
        return 'Все кинотеатры';
      }

      return cinemas.map((cinema) => `${cinema?.name} ${cinema?.city?.name}`).join(', ');
    },

    async changePositions(values) {
      await this.$store.dispatch('data/advPromotions/changePositions', { values });
    },

    convertDateToDbFormat(date) {
      return date ? datetime.convertDateToDbFormat(date) : null;
    },

    async changeFilterDates(date) {
      const { dateStart, dateEnd } = datetime.getWeekByDate(date);

      this.dateStart = dateStart;
      this.dateEnd = dateEnd;

      if (!this.disableFetching) {
        this.setFilter({ field: 'dateStartLte', value: datetime.convertDateToDbFormat(dateEnd) });
        this.setFilter({ field: 'dateEndGte', value: datetime.convertDateToDbFormat(dateStart) });

        await this.fetchItems();
      }
    },

    async changeFilterCinema($event) {
      this.disableFetching = !$event.length;

      if (this.disableFetching) {
        this.$refs.collection.reset();
      } else {
        this.setFilter({ field: 'active', value: 1 });
        this.setFilter({ field: 'cinemaIds', value: $event.map((item) => item.id) });
        this.setFilter({ field: 'dateStartLte', value: datetime.convertDateToDbFormat(this.dateEnd) });
        this.setFilter({ field: 'dateEndGte', value: datetime.convertDateToDbFormat(this.dateStart) });

        await this.fetchItems();
      }
    },

    convertToValue(ids, options) {
      if (!ids) {
        return [];
      }

      // eslint-disable-next-line no-bitwise
      return options.filter((option) => !!~ids.indexOf(option.id));
    },
  },
};
</script>

<style lang="scss" scoped>
.w-300 {
  max-width: 300px;
  width: 100%;
}
.info {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}
</style>
