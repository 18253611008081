<template>
  <div>
    <h2 class="mb-2">Показатели сети</h2>

    <b-table
      class="datatable border"
      :bordered="true"
      :fields="fields"
      :items="items"
      :striped="true"
      :hover="false"
      :show-empty="true"
      :empty-text="loading ? 'Загрузка...' : 'Нет данных'"
    >
      <template #thead-top>
        <b-tr>
          <b-th aria-colindex="1"></b-th>
          <b-th colspan="3">Билеты</b-th>
          <b-th colspan="3">Вал, руб</b-th>
          <b-th colspan="3">Доля рынка, билеты</b-th>
          <b-th colspan="3">Доля рынка, вал</b-th>
        </b-tr>
      </template>

      <template #cell(name)="data">
        <div :class="{ 'font-weight-bold': data.item.cinemaId === currentCinemaId }">{{ data.value }}</div>
      </template>

      <template #cell()="data">
        <div
          :class="[
            data.item.cinemaId === currentCinemaId ? 'font-weight-bold' : '',
            data.field.align ? `text-${data.field.align}` : '',
            data.field.color ? data.field.color(data.unformatted) : '',
          ]"
        >{{ data.value }}</div>
      </template>
    </b-table>
  </div>
</template>

<script>

const PERIODS_COUNT = 2;

export default {
  props: {
    loading: {
      default: false,
    },
    currentCinemaId: {
      default: null,
    },
    marketData: {
      default: () => ({}),
    },
    cinemasData: {
      default: () => ({}),
    },
    rentrakCinemas: {
      default: () => [],
    },
    periods: {
      default: () => [],
    },
    formattedPeriods: {
      default: () => [],
    },
  },
  computed: {
    cinemaIds() {
      return Object.keys(this.marketData).map((cinemaId) => +cinemaId);
    },

    localPeriods() {
      return this.periods.filter((period, index) => index >= this.periods.length - PERIODS_COUNT);
    },

    localFormattedPeriods() {
      return this.formattedPeriods.filter((period, index) => index >= this.formattedPeriods.length - PERIODS_COUNT);
    },

    fields() {
      const fields = [
        {
          key: 'name',
          label: '',
          filterable: false,
          sortable: false,
          align: 'left',
        },
      ];

      const sections = ['count', 'price', 'countPercent', 'pricePercent'];
      const names = ['Доля', 'Доля', 'Динамика', 'Динамика'];
      const types = ['ratio', 'ratio', 'diff', 'diff'];

      for (let i = 0; i < 4; i += 1) {
        const section = sections[i];
        const name = names[i];
        const type = types[i];

        for (let j = 0; j < this.localFormattedPeriods.length; j += 1) {
          const postfix = section.endsWith('Percent') ? '%' : '';

          fields.push({
            key: `${section}_${this.localFormattedPeriods[j]}`,
            label: this.localFormattedPeriods[j],
            filterable: false,
            sortable: true,
            align: section === 'price' ? 'right' : 'center',
            formatter: (value) => `${value.toLocaleString()}${postfix}`,
          });
        }

        const threshold = type === 'ratio' ? 100 : 0;

        fields.push({
          key: `${section}_ratio`,
          label: name,
          filterable: false,
          sortable: true,
          align: 'center',
          formatter: (value) => `${value.toLocaleString()}%`,
          color: (value) => (value > threshold ? 'text-success' : 'text-danger'),
        });
      }

      return fields;
    },

    items() {
      return this.cinemaIds
        .map((cinemaId) => {
          const row = {
            cinemaId,
            name: this.getCinemaName(cinemaId),
          };

          for (const section of ['count', 'price']) {
            const getKey = (i) => `${section}_${this.localFormattedPeriods[i]}`;

            for (let i = 0; i < this.localFormattedPeriods.length; i += 1) {
              row[getKey(i)] = this.getCinemaValue(cinemaId, this.localPeriods[i], section);
            }

            row[`${section}_ratio`] = row[getKey(0)] ? ((row[getKey(1)] / row[getKey(0)]) * 100).toFixed(1) : 0;
          }

          for (const section of ['count', 'price']) {
            const getKey = (i) => `${section}Percent_${this.localFormattedPeriods[i]}`;

            for (let i = 0; i < this.localFormattedPeriods.length; i += 1) {
              row[getKey(i)] = this.calcPercent(this.getCinemaValue(cinemaId, this.localPeriods[i], section), this.getMarketValue(cinemaId, this.localPeriods[i], section));
            }

            row[`${section}Percent_ratio`] = (row[getKey(1)] - row[getKey(0)]).toFixed(1);
          }

          return row;
        });
    },
  },
  methods: {
    getCinemaName(cinemaId) {
      const rentrakCinema = this.rentrakCinemas.find((_rentrakCinema) => _rentrakCinema.id === cinemaId);

      return rentrakCinema ? rentrakCinema.name : '';
    },

    getCinemaValue(cinemaId, period, type) {
      const cinemaData = this.cinemasData[cinemaId].find((_data) => _data.dateStart === period.dateStart && _data.dateEnd === period.dateEnd);

      return cinemaData ? +cinemaData[type] : 0;
    },

    getMarketValue(cinemaId, period, type) {
      const marketData = this.marketData[cinemaId].find((_data) => _data.dateStart === period.dateStart && _data.dateEnd === period.dateEnd);

      return marketData ? +marketData[type] : 0;
    },

    calcPercent(value, total, unknownPercent = 0) {
      return total ? ((value / total) * 100).toFixed(1) : unknownPercent;
    },
  },
};
</script>

<style scoped>
.datatable >>> th {
  text-align: center !important;
}
.table-bordered >>> th, .table-bordered >>> td {
  border: 1px solid #dee2e6 !important;
}

.table-bordered >>> th[aria-colindex="1"] {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}
</style>
