import humps from 'humps';
import collectionServiceFactory from './collectionServiceFactory';
import { convertBlobToString } from '../utils/file';
import exportAdvertisements from '../api/advertisements';

const advertisementsService = collectionServiceFactory('advertisements');

export default {
  ...advertisementsService,

  async exportAdvertisements(params) {
    let [err, data] = await exportAdvertisements(humps.decamelizeKeys(params));

    if (err && err.data && err.data instanceof Blob) {
      const text = await convertBlobToString(err.data);
      err.data = JSON.parse(text);
    }

    if (params.format === 'html' && data instanceof Blob) {
      data = await convertBlobToString(data);
    }

    if (params.format === 'json' && data instanceof Blob) {
      data = await convertBlobToString(data);
      data = JSON.parse(data);
      data = humps.camelizeKeys(data);
    }

    return [humps.camelizeKeys(err), data];
  },
};
