<template>
  <b-table
    thead-class="d-none"
    :items="items"
    :fields="fields"
    :busy="loading"
    :show-empty="true"
    :empty-text="loading ? 'Загрузка...' : 'Нет данных'"
  >
    <template #top-row v-if="weeks.length">
      <b-td>
        <button
          class="btn-reset-style cursor-pointer text-primary"
          :disabled="loading"
          @click="$emit('prepend-week')"
        >+ Добавить неделю</button>
      </b-td>
      <b-td
        v-for="release in releases"
        :key="release.id"
        class="repertory-items__release"
      >
        <span>{{ release.format.name }}</span>
      </b-td>
      <b-td></b-td>
    </template>

    <template #cell(period)="data">
      <span>{{ $datetime.formatDate(data.item.dateStart, 'D MMM') }}</span>
      <span> - </span>
      <span>{{ $datetime.formatDate(data.item.dateEnd, 'D MMM') }}</span>
    </template>

    <template
      v-for="release in releases"
      v-slot:[`cell(release_${release.format.id})`]="data"
    >
      <div class="d-flex align-items-center repertory-control" :key="release.id">
        <button
          class="repertory-control-btn btn-reset-style cursor-pointer text-primary"
          :disabled="loading"
          @click="$emit('change-count', { index: data.index, releaseId: release.id, count: +data.item.count[release.id] - 1 })"
        >
          <icon icon="fa-minus-square"></icon>
        </button>
        <div class="repertory-control-input">
          <b-input
            type="number"
            size="sm"
            class="text-align-center"
            :value="data.item.count[release.id]"
            :disabled="loading"
            @focus="$event.target.select()"
            @input="changeCount({ index: data.index, releaseId: release.id, count: +$event })"
          ></b-input>
        </div>
        <button
          class="repertory-control-btn btn-reset-style cursor-pointer text-primary"
          :disabled="loading"
          @click="$emit('change-count', { index: data.index, releaseId: release.id, count: +data.item.count[release.id] + 1 })"
        >
          <icon icon="fa-plus-square"></icon>
        </button>
      </div>
    </template>

    <template #cell(actions)="data">
      <button
        v-if="weeks.length > 1 && (data.index === 0 || data.index === weeks.length - 1)"
        class="btn-reset-style cursor-pointer text-danger pl-4"
        :disabled="loading"
        @click="$emit('remove-week', data.index)"
      >
        <icon icon="mdi-minus-circle-outline"></icon>
      </button>
    </template>

    <template #bottom-row v-if="weeks.length">
      <b-td>
        <button
          class="btn-reset-style cursor-pointer text-primary"
          :disabled="loading"
          @click="$emit('append-week')"
        >+ Добавить неделю</button>
      </b-td>
      <b-td
        v-for="release in releases"
        :key="release.id"
        class="repertory-items__release"
      >
        <repertory-item-statuses-dropdown
          :value="getRepertoryItemStatus(release.id)"
          :disabled="loading"
          :statuses="repertoryItemStatuses"
          @change="$emit('change-status', { statusId: $event, releaseId: release.id })"
        ></repertory-item-statuses-dropdown>
      </b-td>
      <b-td></b-td>
    </template>
  </b-table>
</template>

<script>
/* eslint-disable no-param-reassign */
import debounce from 'lodash.debounce';
import RepertoryItemStatusesDropdown from './RepertoryItemStatusesDropdown.vue';

import { DEBOUNCE_DELAY } from '../../constants';

export default {
  components: {
    RepertoryItemStatusesDropdown,
  },
  props: {
    dateStart: {
      default: new Date(),
    },
    dateEnd: {
      default: new Date(),
    },
    repertoryItems: {
      default: () => ([]),
    },
    repertoryItemStatuses: {
      default: () => ([]),
    },
    releases: {
      default: () => ([]),
    },
    loading: {
      default: false,
    },
  },
  computed: {
    weeks() {
      return this.$datetime.getRepertoryWeeks(this.dateStart, this.dateEnd);
    },

    fields() {
      return [
        {
          key: 'period',
          label: '',
        },
        ...this.releases.map((release) => ({
          key: `release_${release.format.id}`,
          label: release.format.name,
        })),
        {
          key: 'actions',
          label: '',
        },
      ];
    },

    items() {
      return this.weeks.map((week) => {
        const count = this.releases.reduce((_count, release) => {
          const repertoryItem = this.repertoryItems.find((_item) => _item.releaseId === release.id);

          _count[release.id] = 0;

          if (repertoryItem) {
            const item = repertoryItem.weeks.find((_week) => this.$datetime.isDateSameOrAfter(_week.dateEnd, week.dateStart) && this.$datetime.isDateSameOrBefore(_week.dateStart, week.dateEnd));

            if (item) {
              _count[release.id] = item.count;
            }
          }

          return _count;
        }, {});

        return {
          dateStart: week.dateStart,
          dateEnd: week.dateEnd,
          count,
        };
      });
    },
  },
  created() {
    this.changeCount = debounce(this.changeCount, DEBOUNCE_DELAY, {
      trailing: true,
      leading: false,
    });
  },
  methods: {
    getRepertoryItemStatus(releaseId) {
      const repertoryItem = this.repertoryItems.find((_item) => _item.releaseId === releaseId);

      return repertoryItem?.repertoryItemStatusId || 0;
    },

    changeCount(value) {
      this.$emit('change-count', value);
    },
  },
};
</script>

<style scoped>
table {
  margin: 0;
}
table >>> td:first-child {
  width: 300px;
}
table >>> td {
  vertical-align: middle;
}
table >>> .repertory-items__release {
  width: 10%;
  text-align: center;
}
.repertory-control {
  width: 100px;
}
.repertory-control-btn {
  flex: 0 0 20px;
}
.repertory-control-input {
  flex: 0 0 60px;
}
.repertory-control-input .form-control {
  text-align: center;
}
.btn-reset-style {
  background: 0;
  border: 0;
  margin: 0;
  padding: 0;
}
.btn-reset-style:disabled {
    cursor: not-allowed;
  }
</style>
