<template>
  <div class="table-container certificate-groups">
    <collection-provider
      collection="certificateGroups"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(cinemas)>
            <dictionary-provider name="cinemas">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="data.filter.cinemaId"
                  :options="convertCinemas(items)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('cinemaId', $event)"
                >
                  <template #first>
                    <b-form-select-option :value="null"></b-form-select-option>
                  </template>
                </b-form-select>
              </template>
            </dictionary-provider>
          </template>

          <template #filter(appGroups)>
            <dictionary-provider name="appGroups">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="data.filter.appGroupId"
                  :options="$array.convertToOptions(items)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('appGroupId', $event)"
                ></b-form-select>
              </template>
            </dictionary-provider>
          </template>

          <template #cell(name)="data">
            <div>{{data.item.name}}</div>
            <div
              v-if="data.item.description"
              class="text-muted mt-2"
            >{{data.item.description}}</div>
          </template>

          <template #cell(cinemas)="data">
            <div
              v-for="cinema in data.item.cinemas"
              :key="cinema.id"
            >{{ cinema.name }}, {{ cinema.city.name }}</div>
          </template>

          <template #cell(appGroups)="data">
            <div
              v-for="appGroup in data.item.appGroups"
              :key="appGroup.id"
            >{{ appGroup.name }}</div>
          </template>

          <template #cell(allowActivation)="data">
            {{ getGroupAllowActivationName(data.item.allowActivation) }}
          </template>

          <template #cell(createdAt)="data">
            {{ $datetime.formatDateTime(data.item.createdAt) }}
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.group.show"
      create-title="Создание группы сертификатов"
      edit-title="Редактирование группы сертификатов"
      collection="certificateGroups"
      :id="modals.group.id"
      :init="{ useMode: 1, invalidateMode: 1, numberCharset: 1 }"
      @close="modals.group.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <certificate-group-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></certificate-group-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import DictionaryProvider from '../../providers/DictionaryProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import CertificateGroupForm from './CertificateGroupForm.vue';

export default {
  components: {
    CollectionProvider,
    DictionaryProvider,
    ModalModelForm,
    Datatable,
    CertificateGroupForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        group: {
          show: false,
          id: 0,
        },
      },
      allowActivationStatuses: [
        {
          value: null,
          text: '',
        },
        {
          value: false,
          text: 'Закрыта',
        },
        {
          value: true,
          text: 'Открыта',
        },
      ],
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateCertificateGroupForm() {
      this.modals.group.id = '';
      this.modals.group.show = true;
    },

    openEditCertificateGroupForm(id) {
      this.modals.group.id = id;
      this.modals.group.show = true;
    },

    async exportCertificateGroup(id, item) {
      const cinemas = item.cinemas.map(({ city, name }) => `${name}, ${city.name}`);
      const date = this.$datetime.format(item.createdAt, 'D MMMM');

      await this.$store.dispatch('data/certificateGroups/export', {
        id,
        filename: `Сертификаты ${date} (${cinemas.length ? cinemas : 'Все кинотеатры'}).csv`,
      });
    },

    async removeCertificateGroup(id) {
      if (await confirmDialog('Удалить?')) {
        const [err] = await this.$refs.collection.removeItem(id);

        if (err) {
          if (err.status && err.status === 422) {
            this.$notify({
              type: 'error',
              text: 'Невозможно удалить группу',
            });
          }
        }
      }
    },

    stopSales(id) {
      this.$refs.collection.changeItem(id, { allow_activation: 0 });
    },

    resumeSales(id) {
      this.$refs.collection.changeItem(id, { allow_activation: 1 });
    },

    getGroupAllowActivationName(value) {
      return this.allowActivationStatuses.find((status) => status.value === value)?.text;
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        value: cinema.id,
        text: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },
  },
};
</script>
