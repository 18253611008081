<template>
  <modal-form-dialog
    ok-title="Копировать"
    title="Копирование расписания"
    size="md"
    :save="copyShows"
    :close="() => true"
    :busy="loading"
    @hidden="$emit('hide')"
  >
    <b-form-group
      label="Период сеансов:"
      :invalid-feedback="`${errors.dateFrom} ${errors.dateTo}`"
      :state="states.dateFrom && states.dateTo"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="fields.dateFrom"
        :disabled="loading"
        :state="states.dateFrom"
        @change="changeField('dateFrom', $event)"
      ></date-popover>
      <date-popover
        size="sm"
        :value="fields.dateTo"
        :disabled="loading"
        :state="states.dateTo"
        @change="changeField('dateTo', $event)"
      ></date-popover>
    </b-form-group>

    <b-form-group label="Залы:">
      <b-checkbox
        v-for="hall in halls" :key="hall.id"
        :checked="fields.hallIdsFrom.includes(hall.id)"
        :disabled="loading"
        @change="changeField('hallIdsFrom', toggleId(fields.hallIdsFrom, hall.id))"
      >
        {{ hall.name }}
      </b-checkbox>
    </b-form-group>

    <b-form-group label="Кинотеатры:">
      <dictionary-provider name="cinemas">
        <template #default="{ items: cinemas, loading: cinemasLoading }">
          <div>
            <b-checkbox
              v-for="cinema in cinemas" :key="cinema.id"
              :checked="fields.cinemaIdsTo.includes(cinema.id)"
              :disabled="cinemasLoading || loading"
              @change="changeField('cinemaIdsTo', toggleId(fields.cinemaIdsTo, cinema.id))"
            >
              {{ cinema.name }} ({{cinema.city.name}}), {{ cinema.hallsCount }} {{ getNoun(cinema.hallsCount, 'зал', 'зала', 'залов') }}
            </b-checkbox>
          </div>
        </template>
      </dictionary-provider>
    </b-form-group>
  </modal-form-dialog>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import ModalFormDialog from '../../components/modal/ModalFormDialog.vue';
import DatePopover from '../../components/fields/DatePopover.vue';
import DictionaryProvider from '../../providers/DictionaryProvider';

import { getNoun } from '../../utils/string';

export default {
  components: {
    ModalFormDialog,
    DatePopover,
    DictionaryProvider,
  },
  props: {
    cinema: {
      default: () => ({}),
    },
    halls: {
      default: () => [],
    },
    initDateFrom: {
      default: null,
    },
    initDateTo: {
      default: null,
    },
  },
  data() {
    return {
      visible: true,
      errors: {},
      states: {},
      fields: {
        dateFrom: null,
        dateTo: null,
        hallIdsFrom: [],
        cinemaIdsTo: [],
      },
      loading: false,
    };
  },
  watch: {
    cinema: {
      handler() {
        this.fields.hallIdsFrom = [];
        this.fields.cinemaIdsTo = [];
      },
      immediate: true,
    },
    initDateFrom: {
      handler() {
        this.fields.dateFrom = this.$datetime.convertDateToDbFormat(this.initDateFrom);
      },
      immediate: true,
    },
    initDateTo: {
      handler() {
        this.fields.dateTo = this.$datetime.convertDateToDbFormat(this.initDateTo);
      },
      immediate: true,
    },
  },
  methods: {
    changeField(name, value) {
      this.fields[name] = value;
      this.setError(name, '');
      this.setState(name, null);
    },

    toggleId(ids, id) {
      const idsCopy = [...ids];

      const index = idsCopy.indexOf(id);

      if (index > -1) {
        idsCopy.splice(index, 1);
      } else {
        idsCopy.push(id);
      }

      return idsCopy;
    },

    async copyShows() {
      this.loading = true;
      this.resetErrors();
      this.resetStates();

      const [err] = await this.$store.dispatch('data/shows/copyShows', {
        dateFrom: this.fields.dateFrom,
        dateTo: this.fields.dateTo,
        hallIdsFrom: this.fields.hallIdsFrom,
        cinemaIdsTo: this.fields.cinemaIdsTo,
      });

      if (err && err.status === 422 && err?.data?.errors) {
        const errors = err?.data?.errors;
        for (const field in errors) {
          this.setError(field, errors[field].join(', '));
          this.setState(field, false);
        }
      }

      this.loading = false;

      return !err;
    },

    setError(field, value) {
      this.$set(this.errors, field, value);
    },

    resetErrors() {
      this.errors = {};
    },

    setState(field, value) {
      this.$set(this.states, field, value);
    },

    resetStates() {
      this.states = {};
    },

    getNoun(number,
      one,
      two,
      five) {
      return getNoun(number,
        one,
        two,
        five);
    },
  },
};
</script>
