<template>
  <list-box
    :items="displayedReleases"
    :loading="loading"
    :search="releaseName"
    :draggable="true"
    :empty-text="emptyText"
    search-text="Поиск по всей базе"
    @search="searchRelease($event)"
    @mouseover="onItemMouseover($event)"
    @mouseleave="onItemMouseleave($event)"
  >
    <template #top-content>
      <div class="p-2 border-bottom">
        <b-form-checkbox
          size="sm"
          v-model="displayShowsReleases"
          :value="true"
          :unchecked-value="false"
          :disabled="loading"
        >
          Показать релизы без росписи
        </b-form-checkbox>
      </div>
    </template>

    <template #title="{ item: { release, count } }">
      <div
        class="d-flex"
        :class="{ 'active': hoverItemId === release.movieId || displayedCounterPopoverId === release.id }"
      >
        <span
          v-if="release.movie"
          class="release__name text-truncate"
          :title="release.movie.name"
        >{{ release.movie.name }}</span>
        <span
          class="release__popover-handler ml-auto cursor-pointer"
          :id="`popover_target_${release.id}`"
          @click="toggleCounterPopover(release.id)"
        >&gt;</span>
        <releases-counter-popover
          v-if="displayedCounterPopoverId === release.id"
          :target="`popover_target_${release.id}`"
          :value="count"
          @change="saveCount(release.id, $event)"
          @hide="displayedCounterPopoverId = null"
        ></releases-counter-popover>
      </div>
    </template>

    <template #subtitle="{ item: { release } }">
      <span>в прокате c {{ $datetime.formatDate(release.movie.dateStart) }}</span>
    </template>

    <template #description="{ item: { release, repertoryCount, showsCount } }">
      <div class="d-flex align-items-center">
        <span
          v-if="release.repertoryItem"
          class="mark mr-1"
          :style="{ backgroundColor: getRepertoryItemColor(release.repertoryItem.repertoryItemStatusId) }"
        ></span>
        <span class="mr-1">{{ release.movie.ageLimit }}+,</span>
        <span class="mr-1">{{ release.duration }}</span>
        <span class="mr-1">мин,</span>
        <span
          v-if="release.format"
          class="font-weight-bold"
          :class="{'text-danger': format3D(release.format.name)}"
        >{{release.format.name}}</span>
        <div class="ml-auto">
          <span
            class="mr-1"
            :class="{
              'text-danger': showsCount && showsCount > repertoryCount,
              'text-success': showsCount && showsCount === repertoryCount,
            }"
          >{{ showsCount }}</span>
          <span class="mr-1">/</span>
          <span
            :class="{
              'text-success': showsCount && showsCount === repertoryCount,
            }"
          >{{ repertoryCount }}</span>
        </div>
      </div>
    </template>
  </list-box>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState } from 'vuex';

import ListBox from '../../components/ListBox.vue';
import ReleasesCounterPopover from './ReleasesCounterPopover.vue';

import { DEBOUNCE_DELAY } from '../../constants';

export default {
  components: {
    ListBox,
    ReleasesCounterPopover,
  },
  props: {
    cinemaId: {
      default: null,
    },
    date: {
      default: '',
    },
    dateStart: {
      default: '',
    },
    dateEnd: {
      default: '',
    },
    shows: {
      default: () => [],
    },
    halls: {
      default: () => [],
    },
  },
  data() {
    return {
      releaseName: '',
      displayShowsReleases: false,
      hoverItemId: null,
      displayedCounterPopoverId: null,
    };
  },
  computed: {
    ...mapState('dictionaries/repertoryReleases', {
      repertoryReleases: (state) => state.items,
      repertoryReleasesLoading: (state) => state.loading,
    }),

    ...mapState('dictionaries/showsReleases', {
      showsReleases: (state) => state.items,
      showsReleasesLoading: (state) => state.loading,
    }),

    ...mapState('dictionaries/releases2', {
      releases: (state) => state.items,
      releasesLoading: (state) => state.loading,
    }),

    ...mapState('dictionaries/repertoryItemStatuses', {
      repertoryItemStatuses: (state) => state.items,
    }),

    ...mapState('data/releasesCount', {
      releasesCount: (state) => state.releasesCount,
    }),

    allFormats() {
      return this.halls.reduce((_allFormats, hall) => [..._allFormats, ...hall.formats.map(({ id }) => id)], []);
    },

    displayedReleases() {
      let releases = this.repertoryReleases.filter((release) => (release.repertoryItem ? this.getRepertoryItemsCount(release.repertoryItem) > 0 : true));

      if (this.releaseName) {
        releases = this.releases;
      }

      if (this.displayShowsReleases) {
        releases = [
          ...this.repertoryReleases.filter((release) => (release.repertoryItem ? this.getRepertoryItemsCount(release.repertoryItem) > 0 : true)),
          ...this.showsReleases.filter((release) => !this.repertoryReleases.find((_release) => _release.id === release.id)),
        ];
      }

      return releases
        .filter((release) => this.allFormats.includes(release.formatId))
        .sort((release1, release2) => new Date(release2.movie.dateStart) - new Date(release1.movie.dateStart))
        .map((release) => {
          const repertoryCount = release.repertoryItem ? this.getRepertoryItemsCount(release.repertoryItem) : 0;
          const showsCount = this.getShowsCount(release.id);

          // попросили по умолчанию выводить 1
          const count = this.releasesCount[release.id] || 1; // this.releasesCount[release.id] || (repertoryCount > showsCount ? (repertoryCount - showsCount) : 1);

          return {
            release,
            repertoryCount,
            showsCount,
            count,
          };
        });
    },

    loading() {
      return this.repertoryReleasesLoading || this.releasesLoading || this.showsReleasesLoading;
    },

    emptyText() {
      if (this.releaseName && !this.releases.length) {
        return 'Релизы не найдены';
      }

      return 'Нет запланированных релизов';
    },

    propsComposition() {
      return [this.cinemaId, this.dateStart, this.dateEnd].join('');
    },
  },
  watch: {
    propsComposition() {
      this.fetchRepertoryReleases();

      if (this.displayShowsReleases) {
        this.fetchShowsReleases();
      }

      this.displayedCounterPopoverId = null;
      this.$store.commit('data/releasesCount/resetState');
    },

    displayShowsReleases() {
      if (this.displayShowsReleases) {
        this.fetchShowsReleases();
      } else {
        this.resetShowsReleases();
      }

      this.displayedCounterPopoverId = null;
      this.$store.commit('data/releasesCount/resetState');
    },
  },
  created() {
    this.searchRelease = debounce(this.searchRelease, DEBOUNCE_DELAY, {
      trailing: true,
      leading: false,
    });

    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetchRepertoryReleases();

      if (this.displayShowsReleases) {
        this.fetchShowsReleases();
      }

      this.fetchRepertoryItemStatuses();
    },

    format3D(value) {
      return value.indexOf('3D') !== -1;
    },

    fetchRepertoryItemStatuses() {
      this.$store.dispatch('dictionaries/repertoryItemStatuses/fetchItems');
    },

    fetchRepertoryReleases() {
      if (!this.cinemaId || !this.dateStart || !this.dateEnd) {
        return;
      }

      this.$store.dispatch('dictionaries/repertoryReleases/fetchItems', {
        filter: {
          cinemaId: this.cinemaId,
          dateStart: this.$datetime.convertDateToDbFormat(this.dateStart),
          dateEnd: this.$datetime.convertDateToDbFormat(this.dateEnd),
        },
      });
    },

    fetchShowsReleases() {
      if (!this.cinemaId || !this.dateStart || !this.dateEnd) {
        return;
      }

      this.$store.dispatch('dictionaries/showsReleases/fetchItems', {
        filter: {
          cinemaId: this.cinemaId,
          dateStart: this.$datetime.convertDateToDbFormat(this.$datetime.subtractDays(this.dateStart, 7)),
          dateEnd: this.$datetime.convertDateToDbFormat(this.$datetime.subtractDays(this.dateEnd, 7)),
        },
      });
    },

    resetShowsReleases() {
      this.$store.commit('dictionaries/showsReleases/setItems', { items: [] });
    },

    searchRelease(name) {
      this.releaseName = name;

      if (!name) {
        return;
      }

      this.$store.dispatch('dictionaries/releases2/fetchItems', {
        filter: {
          name,
        },
      });
    },

    onItemMouseover({ release }) {
      this.hoverItemId = release.movieId;
      this.$emit('mouseover', {
        movieId: 0,
        releaseId: release.id,
      });
    },

    onItemMouseleave({ release }) {
      this.hoverItemId = null;
      this.$emit('mouseleave', {
        movieId: 0,
        releaseId: release.id,
      });
    },

    toggleCounterPopover(id) {
      if (this.displayedCounterPopoverId === id) {
        this.displayedCounterPopoverId = null;
      } else {
        this.displayedCounterPopoverId = id;
      }
    },

    getRepertoryItemColor(statusId) {
      return this.repertoryItemStatuses.find((item) => item.id === statusId)?.color;
    },

    getShowsCount(releaseId) {
      let count = 0;

      for (const show of this.shows) {
        if (show.releaseId === releaseId && this.$datetime.isSameDay(this.date, show.date)) {
          count += 1;
        }
      }

      return count;
    },

    getRepertoryItemsCount({ weeks }) {
      for (const week of weeks) {
        if (this.$datetime.isDateSameOrBefore(this.dateStart, week.dateEnd) && this.$datetime.isDateSameOrAfter(this.dateEnd, week.dateStart)) {
          return week.count;
        }
      }

      return 0;
    },

    saveCount(releaseId, count) {
      this.$store.commit('data/releasesCount/setCount', { releaseId, count });
    },
  },
};
</script>

<style scoped>
.release__name {
  max-width: 100%;
  font-weight: 400;
  display: block;
}
.mark {
  width: 10px;
  height: 10px;
  border: 1px solid #eee;
  display: inline-block;
  border-radius: 50%;
}
.release__popover-handler {
  visibility: hidden;
}
.active .release__popover-handler {
  visibility: visible;
}
</style>
