<template>
  <div class="date-popover">
    <b-input-group
      class="date-popover__group"
      :size="size"
      :id="id"
    >
      <template #prepend>
        <div
          class="d-flex align-items-center h-100 p-1 border rounded-left date-popover__bg"
          @click="showCalendar = !showCalendar"
        >
          <icon icon="fa-calendar"></icon>
        </div>
      </template>
      <b-form-input
        readonly
        type="text"
        class="date-popover__bg"
        :state="state"
        :value="formattedValue"
        @click.native="showCalendar = !showCalendar"
      ></b-form-input>
      <template #append>
        <div
          v-if="value"
          class="d-flex align-items-center h-100 p-1 border border-left-0 rounded-right date-popover__trash cursor-pointer"
          @click="$emit('change', null)"
        >
          <icon icon="fa-times"></icon>
        </div>
      </template>
    </b-input-group>
    <b-popover
      placement="right"
      :target="id"
      :show.sync="showCalendar"
      triggers=""
    >
      <datepicker
        ref="datepicker-start"
        class="date-popover__calendar"
        :inline="true"
        :value="value"
        @selected="onDateSelected($event)"
        v-click-outside="closeCalendar"
      ></datepicker>
    </b-popover>
  </div>
</template>

<script>
import datetime from '../../utils/datetime';
import Datepicker from './Date.vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    state: {
      default: null,
    },
    size: {
      default: 'sm',
    },
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      idPrefix: 'date_popover_',
      showCalendar: false,
    };
  },
  computed: {
    id() {
      return this.idPrefix + Math.random() * 1000000;
    },
    formattedValue() {
      return this.value && datetime.formatDateInCurrentTimezone(this.value);
    },
  },
  methods: {
    closeCalendar() {
      this.showCalendar = false;
    },
    onDateSelected(value) {
      this.$emit('change', datetime.convertDateToDbFormat(value));
      this.closeCalendar();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  &_period {
    /deep/ .date-popover__bg {
      background: rgba(#6c757d, 0.1);
    }

    /deep/ .date-popover__group .date-popover__date-label {
      min-width: 30px;
      max-width: auto;
      width: auto;
      padding-top: 4px;
      margin-bottom: 0;

      & + div {
        outline: none;
      }
    }
  }
}
.date-popover__trash {
  background: rgba(#6c757d, 0.1);
}
.popover {
  max-width: 400px;

  /deep/ .vdp-datepicker__calendar {
    border-width: 0 !important;
  }
}
</style>
