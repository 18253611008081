<template>
  <div class="d-flex">
    <b-select
      class="w-auto mr-auto"
      size="sm"
      :value="calendarType"
      @change="$emit('change-calendar-type', $event)"
    >
      <option value="month">Полный месяц</option>
      <option value="week">Полные недели</option>
      <option value="quarter">Квартал</option>
    </b-select>

    <b-select
      class="w-auto"
      size="sm"
      :value="viewMode"
      @change="$emit('change-view-mode', $event)"
    >
      <option value="default">Стандартно</option>
      <option value="compact">Компактно</option>
    </b-select>
  </div>
</template>

<script>

export default {
  props: {
    disabled: {
      default: false,
    },
    calendarType: {
      default: 'month',
    },
    viewMode: {
      default: 'default',
    },
  },
};
</script>

<style scoped>
.calendar-tabs >>> .nav-link {
  padding: 0.25rem 0.5rem;
}
</style>
