import humps from 'humps';

import fetchReport from '../api/reports';

import { convertBlobToString } from '../utils/file';

export default function factory(reportName) {
  const decamelizedReportName = humps.decamelize(reportName);

  return {
    async fetchReport({
      format, sort, filter, params,
    } = {}) {
      const [err, data] = await fetchReport(decamelizedReportName, format, {
        sort: {
          by: sort && sort.by ? humps.decamelize(sort.by) : '',
          desc: sort ? sort.desc : false,
        },
        filter: humps.decamelizeKeys(filter),
        params: humps.decamelizeKeys(params),
      });

      if (err && err.data && err.data instanceof Blob) {
        const text = await convertBlobToString(err.data);
        err.data = JSON.parse(text);
      }

      if (format === 'html' && data?.content instanceof Blob) {
        data.content = await convertBlobToString(data.content);
      }

      if (format === 'json' && data?.content instanceof Blob) {
        data.content = await convertBlobToString(data.content);
        data.content = JSON.parse(data.content);
        data.content = humps.camelizeKeys(data.content);
      }

      return [humps.camelizeKeys(err), data];
    },
  };
}
