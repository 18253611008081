export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    sortable: false,
    filterable: false,
  },
  {
    key: 'category',
    type: 'text',
    label: 'Категория',
    sortable: false,
    filterable: false,
  },
  {
    key: 'rowsCount',
    type: 'text',
    label: 'Количество рядов',
    sortable: false,
    filterable: false,
  },
  {
    key: 'placesCount',
    type: 'text',
    label: 'Количество мест',
    sortable: false,
    filterable: false,
  },
  {
    key: 'interval',
    type: 'text',
    label: 'Время между сеансами',
    sortable: false,
    filterable: false,
  },
  {
    key: 'formats',
    type: 'text',
    label: 'Форматы',
    sortable: false,
    filterable: false,
  },
];
