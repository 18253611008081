<template>
  <ol class="breadcrumb">
    <li
      v-for="(item, index) in items"
      :key="index"
      class="breadcrumb-item"
    >
      <span
        v-if="isLast(index) || !getUrl(item)"
        :class="{'active': isLast(index) }"
      >
        {{ item.name }}
      </span>
      <router-link
        v-else
        :to="getUrl(item)"
      >
        {{ item.name }}
      </router-link>
    </li>
  </ol>
</template>

<script>
export default {
  props: {
    items: {
      default: () => ([]),
    },
  },
  methods: {
    isLast(index) {
      return index === this.items.length - 1;
    },

    getUrl(item) {
      if (item.url) {
        return item.url;
      }

      if (item.routeName) {
        const route = this.$router.resolve({ name: item.routeName, params: item.routeParams });
        return route?.resolved?.path;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.breadcrumb{
  background:0 0;
  margin:0;
  padding:0;
  text-decoration: none !important;
  font-size: 14px;
}
.breadcrumb a{
  color:rgba(0,0,0,.5);
  text-decoration: none !important;
}
.breadcrumb a:hover{
  color:#000;
  text-decoration: none !important;
}
.breadcrumb .active{
  color:#2cabe3;
  text-decoration: none !important;
}
</style>
