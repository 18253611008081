<template>
  <div class="timeline d-flex">
    <timeline-halls
      class="timeline__halls"
      :halls="halls"
      :hall-height="hallHeight"
    ></timeline-halls>
    <div class="timeline__lines flex-grow-1">
      <div
        ref="scroll"
        class="timeline__scroll"
        v-dragscroll="true"
        @mousewheel.prevent="onMouseWheel"
      >
        <timeline-ruler
          :time-start="timelineTimeStart"
          :step="step"
          :minute-width="minuteWidth"
        ></timeline-ruler>
        <timeline-line
          v-for="hall in halls"
          :key="`timeline_${hall.id}`"
          :shows="getHallShows(hall.id)"
          :gaps="getHallGaps(hall.id)"
          :time-start="timelineTimeStart"
          :step="step"
          :minute-width="minuteWidth"
          :disabled="disabled"
          :min-interval="getHallInterval(hall.id)"
          :allowed-formats="getHallFormats(hall.id)"
          :hall="hall"
          :sortable-shows="sortableShows"
          :sortable-shows-intervals="sortableShowsIntervals"
          :sortable-shows-offset="sortableShowsOffset"
          :date="date"
          :get-advertisements-duration="getAdvertisementsDuration"
          :hall-height="hallHeight"
          @drag="onDrag($event)"
          @drop="onDrop($event)"
          @sorting-start="onSortingStart($event)"
          @sorting="onSorting($event)"
          @sorting-stop="onSortingStop($event)"
        >
          <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
          >
            <slot :name="name" v-bind="slotData" />
          </template>
        </timeline-line>
      </div>
    </div>
  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll';

import TimelineRuler from './TimelineRuler.vue';
import TimelineHalls from './TimelineHalls.vue';
import TimelineLine from './TimelineLine.vue';

import { BUSINESS_DATE_BORDER } from '../../constants';

export default {
  components: {
    TimelineRuler,
    TimelineHalls,
    TimelineLine,
  },
  directives: {
    dragscroll,
  },
  props: {
    disabled: {
      default: false,
    },
    halls: {
      default: () => [],
    },
    shows: {
      default: () => [],
    },
    gaps: {
      default: () => [],
    },
    minuteWidth: {
      default: 0.8,
    },
    date: {
      default: null,
    },
    getAdvertisementsDuration: {
      type: Function,
      default: () => 0,
    },
    hallHeight: {},
  },
  data() {
    return {
      timelineTimeStart: BUSINESS_DATE_BORDER,
      step: 5,
      sortableShows: [],
      sortableShowsIntervals: {},
      sortableShowsOffset: 0,
    };
  },
  methods: {
    getHallShows(hallId) {
      return this.shows.filter((item) => item.hallId === hallId);
    },

    getHallGaps(hallId) {
      return this.gaps.filter((item) => item.hallId === hallId);
    },

    getHallInterval(hallId) {
      return this.halls.find((_hall) => _hall.id === hallId)?.interval;
    },

    getHallFormats(hallId) {
      return this.halls.find((_hall) => _hall.id === hallId)?.formats || [];
    },

    isTimeBusy(shows, show) {
      return shows
        .filter((_show) => _show.id !== show.id)
        .filter((_show) => _show.hallId === show.hallId)
        .find((_show) => this.$datetime.isPeriodsIntersects(
          show.time,
          show.release.duration + show.advertisementsDuration,
          _show.time,
          _show.release.duration + _show.advertisementsDuration,
        ));
    },

    convertTimeToPosition(time) {
      return this.$datetime.getDiffInMinutes(time, this.timelineTimeStart) * this.minuteWidth;
    },

    onSortingStart({ shows, intervals, offset }) {
      this.sortableShows = [...shows];
      this.sortableShowsOffset = offset;
      this.sortableShowsIntervals = intervals;
    },

    onSorting({ changes, direction }) {
      // прямое копирование сеансов сильно тормозит отрисовку, поэтому копируем только некоторые поля!
      // eslint-disable-next-line no-restricted-syntax
      for (const [i, { hallId, time, advertisementsDuration }] of Object.entries(changes)) {
        this.sortableShows[i].hallId = hallId;
        this.sortableShows[i].time = time;
        this.sortableShows[i].advertisementsDuration = advertisementsDuration;
      }

      this.scrollOnShow(this.sortableShows, direction);
    },

    onSortingStop() {
      if (!this.sortableShows.length) {
        return;
      }

      const shows = [...this.sortableShows];

      this.sortableShows = [];
      this.sortableShowsIntervals = {};
      this.sortableShowsOffset = 0;

      const otherShows = this.shows.filter((show) => !shows.find((_show) => _show.id === show.id));

      // eslint-disable-next-line no-restricted-syntax
      for (const show of shows) {
        if (this.isTimeBusy([...shows, ...otherShows], show)) {
          return;
        }
      }

      this.$emit('change-shows', shows);
    },

    onMouseWheel(e) {
      this.$refs.scroll.scrollLeft += e.deltaX;
    },

    scrollOnShow(shows, direction) {
      if (!shows.length) {
        return;
      }

      const { scrollLeft } = this.$refs.scroll;
      const firstShow = shows[0];
      const lastShow = shows[shows.length - 1];

      const positionLeft = this.convertTimeToPosition(firstShow.time);
      const positionRight = this.convertTimeToPosition(lastShow.time) + (lastShow.release.duration + lastShow.advertisementsDuration) * this.minuteWidth;

      if (direction < 0 && positionLeft < scrollLeft + 10) {
        this.$refs.scroll.scrollLeft -= 10;
        return;
      }

      if (direction > 0 && positionRight > scrollLeft + this.$refs.scroll.getBoundingClientRect().width - 20) {
        this.$refs.scroll.scrollLeft += 10;
      }
    },

    onDrag({ shows, direction }) {
      if (!shows.length) {
        return;
      }

      this.scrollOnShow(shows, direction);
    },

    onDrop(shows) {
      if (!shows.length) {
        return;
      }

      const createdShows = [];

      for (let i = shows.length - 1; i >= 0; i -= 1) {
        const currentShow = shows[i];
        let pushShow = true;

        if (i > 0) {
          const prevShow = shows[i - 1];

          // ищем пересечения между собой (или нужно откидывать сразу?..)
          if (this.$datetime.isPeriodsIntersects(
            currentShow.time,
            currentShow.release.duration + currentShow.advertisementsDuration,
            prevShow.time,
            prevShow.release.duration + prevShow.advertisementsDuration,
          )) {
            pushShow = false;
          }
        }

        if (this.isTimeBusy(this.shows, currentShow)) {
          pushShow = false;
        }

        if (pushShow) {
          createdShows.push(currentShow);
        }
      }

      if (createdShows.length) {
        const releaseIds = Array.from(new Set(createdShows.map((show) => show.releaseId)));
        releaseIds.forEach((releaseId) => {
          this.$store.commit('data/releasesCount/resetCount', { releaseId });
        });
        this.$emit('create-shows', createdShows);
      }
    },
  },
};
</script>

<style scoped>
.timeline {
  color: #313131;
  width: 100%;
  overflow: hidden;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
.timeline__scroll {
  overflow: hidden;
}
.timeline__lines {
  width: 0;
}
</style>
