<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Название:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Описание:"
          :invalid-feedback="errors.description"
          :state="states.description"
        >
          <b-form-textarea
            type="text"
            size="sm"
            :value="item.description"
            :state="states.description"
            :disabled="loading"
            @change="updateValue('description', $event)"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Изображение:"
          :invalid-feedback="errors.file"
          :state="states.image"
        >
          <file-uploader
            :disabled="loading"
            @change="handleFile($event, 'file')"
          ></file-uploader>
        </b-form-group>
      </b-col>
      <b-col v-if="item.file">
        <image-viewer
          :image="item.file.filepath"
          @remove="updateValue('file', '')"
        ></image-viewer>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FileUploader from '../../components/fields/FileUploader.vue';
import ImageViewer from '../../components/ImageViewer.vue';
import {
  FILE_UPLOAD_TYPE_CINEMA_PHOTO,
} from '../../constants';

export default {
  components: {
    FileUploader,
    ImageViewer,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    async handleFile(files, field) {
      const type = FILE_UPLOAD_TYPE_CINEMA_PHOTO;
      const fileId = await this.$fileManager.registerAndUpload(files[0], { type });

      this.updateValue(field, {
        fileId,
      });
    },
  },
};
</script>
