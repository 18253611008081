<template>
  <div class="calendar-days d-flex">
    <div
      v-for="(week, index) in weeks"
      class="calendar-days__week d-flex flex-column"
      :key="index"
      :style="`flex-grow:${week.length}`"
    >
      <div class="d-flex">
        <template v-if="weeks.length < 12" >
          <div
            v-for="(day, index) in week"
            class="calendar-days__day text-center border-right border-top"
            :key="index"
            :class="{'calendar-days__holiday': day.holiday}"
          >{{day.date.getDate()}}</div>
        </template>
        <div v-else class="calendar-days__day border-right border-top">
          <div class="calendar-days__period pl-1 pr-1 align-self-stretch d-flex align-items-center" >
            <div class="text-nowrap" :title="formatWeek(week)">{{ formatWeek(week) }}</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    weeks: {
      default: () => [],
    },
  },
  methods: {
    formatWeek(week) {
      const start = this.formatDay(week[0].date);
      const end = this.formatDay(week[week.length - 1].date);

      return `${start} - ${end}`;
    },

    formatDay(date) {
      return date.toLocaleString('ru', { day: 'numeric', month: 'numeric' });
    },
  },
};
</script>

<style scoped>
.calendar-days__week, .calendar-days__day {
  flex: 1 0 0;
  color: #888;
  overflow: hidden;
}
.calendar-days__period {
  font-size: 8px;
  height: 24px;
}
.calendar-days__holiday {
  color: #f00;
  background-color: #f6f6f6;
}
</style>
