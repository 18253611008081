<template>
  <b-row>
    <b-col :md="columnOne">
      <div
        class="p-2 m-0"
        :class="columnOneClass"
      >
        <slot name="label"></slot>
      </div>
    </b-col>
    <b-col :md="columnTwo">
      <div
        class="p-2"
        :class="columnTwoClass"
      >
        <slot name="value"></slot>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    columnOne: {
      default: 4,
    },
    columnOneClass: {
      default: 'text-right',
    },
    columnTwo: {
      default: 8,
    },
    columnTwoClass: {
      default: 'text-left',
    },
  },
};
</script>
