export default ({ checked }) => [
  {
    type: 'dropdown',
    text: 'Действия с выделенными',
    variant: 'outline-info',
    class: 'mr-2',
    bulk: true,
    disabled: checked && checked.length === 0,
    items: [
      {
        type: 'button',
        text: 'Приостановить',
        action: 'pauseCheckedDiscounts',
        permission: 'discounts.edit',
      },
      {
        type: 'button',
        text: 'Возобновить',
        action: 'resumeCheckedDiscounts',
        permission: 'discounts.edit',
      },
      {
        type: 'divider',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeCheckedDiscounts',
        permission: 'discounts.edit',
      },
    ],
  },
  {
    type: 'button',
    text: 'Добавить скидку',
    variant: 'info',
    icon: 'fa-plus',
    class: 'mr-1',
    action: 'openCreateDiscountForm',
    permission: 'discounts.edit',
  },

];
