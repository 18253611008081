<template>
  <dictionary-provider name="cinemas">
    <template #default="{ items: cinemas }">
      <collection-provider
        collection="repertoryItems"
        ref="collection"
        :filter="{ releaseIds }"
        :disable-fetching="!releaseIds.length"
      >
        <template #default="{ data: { items: repertoryItems, loading } }">
          <div>
            <repertory-export
              v-if="repertoryItems.length"
              :movie="movie"
              :releaseIds="releaseIds"
            ></repertory-export>
            <div v-if="hasFavoriteReleases(movie.releases, cinemas)">
              <repertory-cinema
                class="mb-4"
                v-for="cinema in cinemas"
                :key="cinema.id"
                :cinema="cinema"
                :repertory-items="getRepertoryItems(cinema.id, repertoryItems)"
                :repertory-item-statuses="repertoryItemStatuses"
                :releases="getReleasesAllowedForCinema(movie.releases, cinema)"
                :movie="movie"
                :loading="loading"
                @remove-items="removeRepertoryItems($event)"
                @change-items="changeRepertoryItems(cinema.id, $event)"
              ></repertory-cinema>
            </div>
            <div v-else>
              <div class="text-center">
                <div class="h5">Фильм не добавлен в избранное</div>
              </div>
            </div>
          </div>
        </template>
      </collection-provider>
    </template>
  </dictionary-provider>
</template>

<script>
import { mapState } from 'vuex';

import CollectionProvider from '../../providers/CollectionProvider';
import DictionaryProvider from '../../providers/DictionaryProvider';
import RepertoryCinema from './RepertoryCinema.vue';
import RepertoryExport from './RepertoryExport.vue';

export default {
  components: {
    CollectionProvider,
    DictionaryProvider,
    RepertoryCinema,
    RepertoryExport,
  },
  props: {
    movie: {
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('dictionaries/repertoryItemStatuses', {
      repertoryItemStatuses: (state) => state.items,
    }),

    releaseIds() {
      return this.movie?.releases.map((release) => release.id);
    },
  },
  created() {
    this.fetchRepertoryItemStatuses();
  },
  methods: {
    fetchRepertoryItemStatuses() {
      this.$store.dispatch('dictionaries/repertoryItemStatuses/fetchItems');
    },

    getRepertoryItems(cinemaId, repertoryItems) {
      return repertoryItems.filter((item) => item.cinemaId === cinemaId);
    },

    async removeRepertoryItems(ids) {
      this.$refs.collection.removeItems(ids);
    },

    async changeRepertoryItems(cinemaId, repertoryItems) {
      // eslint-disable-next-line no-restricted-syntax
      for (const repertoryItem of repertoryItems) {
        if (repertoryItem.id) {
          this.$refs.collection.changeItem(repertoryItem.id, {
            cinemaId,
            ...repertoryItem,
          });
        } else {
          this.$refs.collection.createItem({
            cinemaId,
            ...repertoryItem,
          });
        }
      }
    },

    getReleasesAllowedForCinema(releases, cinema) {
      const allFormats = cinema.halls.reduce((_allFormats, hall) => [..._allFormats, ...hall.formats.map(({ id }) => id)], []);
      return releases.filter((release) => {
        const favoriteCinemas = (release.favoriteCinemas || []).map(({ cinemaId }) => cinemaId);
        return allFormats.includes(release.formatId) && favoriteCinemas.includes(cinema.id);
      });
    },

    hasFavoriteReleases(releases, cinemas) {
      return cinemas.some((cinema) => this.getReleasesAllowedForCinema(releases, cinema).length);
    },
  },
};
</script>
