<template>
  <div class="discount-period">
    <div v-if="!item.datetimeStart && !item.datetimeEnd">Без срока действия</div>
    <div v-else>
      <span class="mr-1">Действуют</span>
      <span v-if="item.datetimeStart" class="mr-1">c {{$datetime.formatDateTime(item.datetimeStart)}}</span>
      <span v-if="item.datetimeEnd">по {{$datetime.formatDateTime(item.datetimeEnd)}}</span>
    </div>

    <div v-if="!item.showDatetimeStart && !item.showDatetimeEnd">Без ограничений по дате сеанса</div>
    <div v-else>
      <span class="mr-1">Сеансы</span>
      <span v-if="item.showDatetimeStart" class="mr-1">c {{$datetime.formatDateTime(item.showDatetimeStart)}}</span>
      <span v-if="item.showDatetimeEnd">по {{$datetime.formatDateTime(item.showDatetimeEnd)}}</span>
    </div>

    <div v-if="!item.days || item.days.length === 0 || item.days.length === 7">Сеансы на все дни недели</div>
    <div v-else>
      <span class="mr-1">Дни недели сеансов:</span>
      <span>{{$array.convertToText(item.days, '', 'short')}}</span>
    </div>

    <div v-if="!item.showTimeStart && !item.showTimeEnd">Без ограничения по времени сеансов</div>
    <div v-else>
      <span class="mr-1">Сеансы</span>
      <span v-if="item.showTimeStart" class="mr-1">с {{item.showTimeStart}}</span>
      <span v-if="item.showTimeEnd">до {{item.showTimeEnd}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.discount-period > div {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
