<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
import ChartJs from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJs.register(annotationPlugin);

export default {
  props: {
    data: {
      default: () => [],
    },
    aspectRatio: {
      default: 1,
    },
    lineColor: {
      default: '#1A8CFF',
    },
    fillColor: {
      default: 'rgba(26, 141, 255, 0.2)',
    },
    tension: {
      default: 0,
    },
  },
  data() {
    return {
      // mChart: null, // всё правильно! иначе даёт ошибку...
    };
  },
  watch: {
    data() {
      this.renderChart();
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    if (this.mChart) {
      this.mChart.destroy();
    }
  },
  methods: {
    renderChart() {
      if (this.mChart) {
        this.mChart.destroy();
      }

      this.mChart = new ChartJs(this.$refs.canvas, {
        type: 'line',
        data: {
          labels: new Array(this.data.length).fill(''),
          datasets: [
            {
              data: this.data,
              fill: true,
              borderColor: this.lineColor,
              backgroundColor: this.fillColor,
              borderWidth: 2,
              tension: this.tension,
              pointHoverRadius: 0,
            },
          ],
        },
        options: {
          animation: {
            duration: 0,
          },
          responsive: true,
          aspectRatio: this.aspectRatio,
          maintainAspectRatio: true,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          interaction: {
            intersect: false,
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
              min: 0,
              max: 1.1 * Math.max(...this.data),
            },
          },
        },

      });
    },
  },
};
</script>
