<template>
  <calendar-dates-layout :dates="dates">
    <template
      v-for="(date, index) in dates"
      #[`day(${index})`]
    >
      <calendar-dates-day
        :key="index"
        :date="date"
        :holiday="isHoliday(date)"
        @clear-date="$emit('clear-date', $event)"
        @select-date="$emit('select-date', $event)"
      ></calendar-dates-day>
    </template>
  </calendar-dates-layout>
</template>

<script>
import CalendarDatesLayout from './CalendarDatesLayout.vue';
import CalendarDatesDay from './CalendarDatesDay.vue';

export default {
  components: {
    CalendarDatesLayout,
    CalendarDatesDay,
  },
  props: {
    dates: {
      default: () => [],
    },
    isHoliday: {
      default: () => false,
    },
  },
};
</script>
