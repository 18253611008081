import get from 'lodash.get';

const array = {
  convertToText(items, emptyText = '', key = 'name') {
    if (!items || !Array.isArray(items) || !items.length) {
      return emptyText;
    }

    return items.map((item) => get(item, key)).join(', ');
  },

  getItemText(items, id, key = 'name') {
    if (!items || !Array.isArray(items) || !items.length || !id) {
      return '';
    }

    const item = items.find((_item) => _item.id === id);

    return get(item, key) || '';
  },

  convertToOptions(items, firstItem = { value: null, text: '' }, key = 'id', text = 'name') {
    if (!items || !Array.isArray(items) || !items.length) {
      return [];
    }

    const options = items.map((item) => ({ value: get(item, key), text: get(item, text) }));

    if (firstItem) {
      return [
        firstItem,
        ...options,
      ];
    }

    return options;
  },

  union(items1, items2, key = 'id') {
    if (!Array.isArray(items1)) {
      // eslint-disable-next-line no-param-reassign
      items1 = [];
    }

    if (!Array.isArray(items2)) {
      // eslint-disable-next-line no-param-reassign
      items2 = [];
    }

    return [...items1, ...items2.filter(
      (item2) => !items1.find((item1) => get(item1, key) === get(item2, key)),
    )];
  },

  getValues(items, key = 'name') {
    if (!items || !Array.isArray(items) || !items.length) {
      return [];
    }

    return items.map((item) => get(item, key));
  },

  sumArrays(items1, items2, key = '') {
    return items1.map((item, idx) => (key ? item?.[key] + (items2?.[idx]?.[key] || 0) : item + (items2?.[idx] || 0)));
  },

  divideArrays(items1, items2, key = '', round = true) {
    return items1.map((item, idx) => {
      const value1 = key ? (item?.[key] || 0) : item;
      const value2 = key ? (items2?.[idx]?.[key] || 0) : (items2?.[idx] || 0);

      const result = value2 ? value1 / value2 : 0;

      return round ? Math.round(result) : result;
    });
  },

  convertToPercents(items, round = true) {
    return items.map((value) => (round ? Math.round(value * 100) : value * 100));
  },

  reverse(items) {
    const copy = JSON.parse(JSON.stringify(items));

    copy.reverse();

    return copy;
  },

  convertToValue(ids, options) {
    if (!ids) {
      return [];
    }

    // eslint-disable-next-line no-bitwise
    return options.filter((option) => !!~ids.indexOf(option.id));
  },
};

export default {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        this.$array = array;
      },
    });
  },
};
