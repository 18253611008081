<template>
  <div class="mb-4">
    <b-button
      variant="outline-info"
      size="sm"
      :disabled="loading"
      @click="exportRepertory()"
    ><icon icon="fa-arrow-circle-o-down"></icon> Экспорт</b-button>
  </div>
</template>

<script>
export default {
  props: {
    movie: {
      default: () => ({}),
    },
    releaseIds: {
      default: () => ([]),
    },
  },
  data() {
    return {
      errors: {},
      states: {},
      loading: false,
    };
  },
  methods: {
    async exportRepertory() {
      const filename = `Роспись ${this.movie.name}.xlsx`;

      this.loading = true;
      this.resetErrors();
      this.resetStates();

      const [err, result] = await this.$store.dispatch('data/movies/exportRepertory', {
        releaseIds: this.releaseIds,
        filename,
      });

      if (err && err.status === 422 && err?.data?.errors) {
        const errors = err?.data?.errors;
        for (const field in errors) {
          this.setError(field, errors[field].join(', '));
          this.setState(field, false);
        }
      }

      this.loading = false;

      return !err;
    },

    setError(field, value) {
      this.$set(this.errors, field, value);
    },

    resetErrors() {
      this.errors = {};
    },

    setState(field, value) {
      this.$set(this.states, field, value);
    },

    resetStates() {
      this.states = {};
    },
  },
};
</script>

<style scoped>

</style>
