<template>
  <div class="sidebar p-2">
    <b-form-group
      label="Период дат:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.dateStart']} ${errors['filter.dateEnd']}`"
      :state="states['filter.dateStart'] && states['filter.dateEnd']"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="filter.dateStart"
        :state="states['filter.dateStart']"
        :disabled="loading"
        @change="setFilter({ field: 'dateStart', value: $event }), setFilter({field: 'releaseId', value: null})"
      ></date-popover>
      <date-popover
        size="sm"
        :value="filter.dateEnd"
        :state="states['filter.dateEnd']"
        :disabled="loading"
        @change="setFilter({ field: 'dateEnd', value: $event }), setFilter({field: 'releaseId', value: null})"
      ></date-popover>
    </b-form-group>

    <b-form-group
      label="Релизы:"
      :invalid-feedback="errors['filter.releaseId']"
      :state="states['filter.releaseId']"
    >
      <dictionary-provider
        name="showsReleases"
        :filter="showsReleasesFilter"
        :disable-fetching="!filter.dateStart || !filter.dateEnd"
      >
        <template #default="{ items: releases, loading: releasesLoading }">
          <b-form-select
            size="sm"
            :options="convertReleasesToOptions(releases)"
            :value="filter.releaseId"
            :disabled="releasesLoading || loading || (!filter.dateStart || !filter.dateEnd)"
            :state="states['filter.releaseId']"
            @change="setFilter({ field: 'releaseId', value: $event }), setFilter({field: 'showIds', value: [] })"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">-- Выберите релиз --</b-form-select-option>
            </template>
          </b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Сеансы:"
      :invalid-feedback="errors['filter.showIds']"
      :state="states['filter.showIds']"
    >
      <dictionary-provider
        name="shows"
        :filter="showsFilter"
        :disable-fetching="!filter.releaseId"
      >
        <template #default="{ items, loading: dictionaryLoading }">
          <toggle-list
            placeholder="Все"
            :options="convertShowsToOptions(items)"
            :value="convertToDictionaryFromIds(filter.showIds, items)"
            :disabled="loading || dictionaryLoading || !filter.releaseId"
            @change="setFilter({ field: 'showIds', value: convertFromDictionaryToIds($event) })"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="mt-2">
      <b-button
        variant="info"
        size="sm"
        :disabled="loading"
        @click="fetchReport({ format: 'html' })"
      >Просмотр</b-button>
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
        @click="printReport()"
      ><icon icon="fa-print"></icon> Печать</b-button>
      <b-dropdown
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
      >
        <template #button-content>
          <icon icon="fa-save"></icon>
        </template>
        <b-dropdown-item
          v-for="format in formats"
          :key="format.value"
          @click="fetchReport({ format: format.value })"
        >{{format.text}}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DictionaryProvider from '../../../providers/DictionaryProvider';
import DatePopover from '../../../components/fields/DatePopover.vue';
import ToggleList from '../../../components/fields/ToggleList.vue';
import datetime from '../../../utils/datetime';

export default {
  components: {
    DictionaryProvider,
    DatePopover,
    ToggleList,
  },
  data() {
    return {
      format: 'xlsx',
      formats: [
        { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'XLSX' },
      ],
    };
  },
  computed: {
    ...mapState(['cinemaId']),

    ...mapState('reports/showSales', {
      params: (state) => state.params,
      filter: (state) => state.filter,
      loading: (state) => state.loading,
      errors: (state) => state.errors,
      states: (state) => state.states,
    }),

    showsReleasesFilter() {
      return {
        cinemaId: this.params.cinemaId,
        dateStart: this.filter.dateStart,
        dateEnd: this.filter.dateEnd,
      };
    },

    showsFilter() {
      return { ...this.showsReleasesFilter, releaseId: this.filter.releaseId };
    },
  },
  created() {
    const date = datetime.convertDateToDbFormat(new Date());

    this.setParam({ field: 'cinemaId', value: this.cinemaId });
    this.setFilter({ field: 'dateStart', value: date });
    this.setFilter({ field: 'dateEnd', value: date });
    this.setFilter({ field: 'releaseId', value: null });
    this.setFilter({ field: 'showIds', value: [] });
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      setParam: 'reports/showSales/setParam',
      setFilter: 'reports/showSales/setFilter',
      fetchReport: 'reports/showSales/fetchReport',
      reset: 'reports/showSales/reset',
    }),

    convertReleasesToOptions(releases) {
      return releases.map((release) => ({
        value: release.id,
        text: `${release.movie.name} ${release.format.name}`,
      }));
    },

    convertShowsToOptions(shows) {
      if (!shows) {
        return [];
      }

      return shows.map((show) => ({
        id: show.id,
        name: show?.hall?.name ? `${datetime.format(show?.date, 'D MMM')} ${show?.time} Зал ${show?.hall?.name} ` : show?.time,
      }));
    },

    convertToDictionaryFromIds(ids, items) {
      if (!ids || !items) {
        return [];
      }

      return ids.map((id) => items.find((item) => item.id === id));
    },

    convertFromDictionaryToIds(items) {
      if (!items) {
        return [];
      }

      return items.map((item) => item.id);
    },

    async printReport() {
      const [err, data] = await this.fetchReport({ format: 'html' });

      if (err || !data) {
        return;
      }

      this.$nextTick(() => {
        this.$htmlToPaper('htmlReport');
      });
    },
  },
};
</script>
