<template>
  <cinema-page
    route-name="repertory"
    route-param-name="cinemaId"
    @loaded="setCinema($event)"
  >
    <template #fixed="{ cinemaId, cinema }">
      <collection-provider
        v-if="cinemaId && dateStart && dateEnd"
        collection="repertoryItems"
        ref="collection"
        :filter="{ cinemaId, dateStart: dateStartInDbFormat, dateEnd: dateEndInDbFormat }"
        :disable-fetching="!cinemaId || !dateStartInDbFormat || !dateEndInDbFormat"
        @loaded="calculateOccupancy(cinema, weeks, $event)"
      >
        <template #default="{ data: { items, loading } }">
          <multi-view :scroll-side="false">
            <template #side>
              <releases
                :cinema-id="cinemaId"
                :formats="cinemaFormats"
                :date-start="dateStart"
                :date-end="dateEnd"
              ></releases>
            </template>

            <template #panel>
              <buttons
                :calendar-type="calendarType"
                :view-mode="viewMode"
                :disabled="loading"
                @change-calendar-type="changeCalendarType($event)"
                @change-view-mode="changeViewMode($event)"
              ></buttons>
            </template>

            <template #subpanel>
              <calendar>
                <calendar-period
                  :weeks="weeks"
                  :calendar-type="calendarType"
                  @prev-month="setPrevMonth()"
                  @next-month="setNextMonth()"
                  @prev-week="setPrevWeek()"
                  @next-week="setNextWeek()"
                ></calendar-period>
                <calendar-stat
                  :weeks="weeks"
                  :weeks-capacity="weeksCapacity"
                  :weeks-charge="weeksCharge"
                ></calendar-stat>
                <calendar-days
                  :weeks="weeks"
                ></calendar-days>
              </calendar>
            </template>

            <template #content>
              <timeline
                :items="items"
                :statuses="repertoryItemStatuses"
                :weeks="weeks"
                :loading="loading"
                :view-mode="viewMode"
                @add-item="addRepertoryItem($event)"
                @click-item="openRepertoryItem($event)"
                @change-date-start="changeRepertoryItemDateStart($event)"
                @change-date-end="changeRepertoryItemDateEnd($event)"
              >
                <template #release="{ repertoryItem }">
                  <timeline-release :release="repertoryItem.release"></timeline-release>
                </template>
                <template #counter="{ repertoryItem, week }">
                  {{getItemChargePercent(repertoryItem.release.duration, week.count, findWeekIndex(weeks, week))}} <template v-if="week.count">({{ week.count }})</template>
                </template>
              </timeline>
            </template>

            <template #portal>
              <modal-repertory-item-form
                v-if="modals.item.show"
                :id="modals.item.id"
                @hide="modals.item.show = false"
                @saved="fetchRepertoryItems()"
                @removed="fetchRepertoryItems()"
              ></modal-repertory-item-form>
            </template>
          </multi-view>
        </template>
      </collection-provider>
    </template>
  </cinema-page>
</template>

<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import { mapState, mapGetters } from 'vuex';

import CollectionProvider from '../../providers/CollectionProvider';
import MultiView from '../../layout/MultiView.vue';
import CinemaPage from '../../layout/CinemaPage.vue';
import Calendar from './Calendar.vue';
import CalendarPeriod from './CalendarPeriod.vue';
import CalendarStat from './CalendarStat.vue';
import CalendarDays from './CalendarDays.vue';
import Buttons from './Buttons.vue';
import Releases from './Releases.vue';
import Timeline from './Timeline.vue';
import ModalRepertoryItemForm from './ModalRepertoryItemForm.vue';
import TimelineRelease from './TimelineRelease.vue';

import Occupancy from './Occupancy';
import RepertoryItemDates from './RepertoryItemDates';

import datetime from '../../utils/datetime';

export default {
  components: {
    MultiView,
    CinemaPage,
    CollectionProvider,
    Calendar,
    CalendarPeriod,
    CalendarStat,
    CalendarDays,
    Buttons,
    Releases,
    Timeline,
    ModalRepertoryItemForm,
    TimelineRelease,
  },
  data() {
    return {
      cinemaId: null,
      cinema: [],
      dateStart: new Date(),
      dateEnd: new Date(),
      calendarType: 'month',
      viewMode: 'default',
      weeksCount: 4,
      weeksCapacity: [],
      weeksCharge: [],
      modals: {
        item: {
          show: false,
          id: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters('dictionaries/holidays', ['isHoliday']),

    ...mapState('dictionaries/repertoryItemStatuses', {
      repertoryItemStatuses: (state) => state.items,
    }),

    dateStartInDbFormat() {
      return datetime.convertDateToDbFormat(this.dateStart);
    },

    dateEndInDbFormat() {
      return datetime.convertDateToDbFormat(this.dateEnd);
    },

    dates() {
      return datetime.getDates(this.dateStart, this.dateEnd).map((date) => ({
        date,
        holiday: this.isHoliday(this.regionId, date),
      }));
    },

    cinemaFormats() {
      return this.cinema.halls.reduce((_allFormats, hall) => [..._allFormats, ...hall.formats.map(({ id }) => id)], []);
    },

    weeks() {
      const weeks = [];
      let week = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const date of this.dates) {
        week.push(date);

        if (date.date.getDay() === 3) {
          weeks.push([...week]);
          week = [];
        }
      }

      if (week.length) {
        weeks.push([...week]);
      }

      return weeks;
    },
  },
  created() {
    this.fetchHolidays();
    this.fetchRepertoryItemStatuses();
    this.selectDate(datetime.getStartOfCurrentMonth());
  },
  methods: {
    setCinema(cinema) {
      this.cinemaId = cinema.id;
      this.cinema = cinema;
    },

    fetchRepertoryItems() {
      this.$refs.collection.fetchItems();
    },

    fetchRepertoryItemStatuses() {
      this.$store.dispatch('dictionaries/repertoryItemStatuses/fetchItems');
    },

    async addRepertoryItem({ dateStart, dateEnd, release }) {
      const dateStartInDbFormat = datetime.convertDateToDbFormat(dateStart);
      const dateEndInDbFormat = datetime.convertDateToDbFormat(dateEnd);

      const values = {
        cinemaId: this.cinemaId,
        releaseId: release.id,
        release,
        dateStart: dateStartInDbFormat,
        dateEnd: dateEndInDbFormat,
        weeks: [
          {
            dateStart,
            dateEnd,
            count: 0,
          },
        ],
        count: 0,
        repertoryItemStatusId: this.repertoryItemStatuses[0]?.id,
      };

      const [err, item] = await this.$refs.collection.createItem(values);

      if (!err && item && item.id) {
        this.openRepertoryItem(item.id);
      }

      if (err && err.status === 422 && err?.data?.errors) {
        const errors = err?.data?.errors;

        for (const field in errors) {
          this.$notify({
            type: 'error',
            text: errors[field].join(', '),
          });
        }
      }
    },

    async changeRepertoryItemDateStart({ id, date }) {
      this.changeRepertoryItemDate(id, 'dateStart', date);
    },

    async changeRepertoryItemDateEnd({ id, date }) {
      this.changeRepertoryItemDate(id, 'dateEnd', date);
    },

    async changeRepertoryItemDate(id, field, date) {
      const item = this.$refs.collection.data.items.find((_item) => _item.id === id);

      const dates = new RepertoryItemDates(item);

      if (field === 'dateStart') {
        dates.changeDateStart(date);
      }

      if (field === 'dateEnd') {
        dates.changeDateEnd(date);
      }

      this.$refs.collection.changeItem(id, {
        ...item,
        [field]: datetime.convertDateToDbFormat(date),
        weeks: dates.weeks,
        count: dates.getCount(),
      });
    },

    openRepertoryItem(id) {
      this.modals.item.id = id;
      this.modals.item.show = true;
    },

    findWeekIndex(weeks, repertoryItemWeek) {
      return weeks.findIndex((week) => {
        const weekDateStart = week[0].date;
        const weekDateEnd = week[week.length - 1].date;

        return datetime.isDateSameOrAfter(repertoryItemWeek.dateStart, weekDateStart) && datetime.isDateSameOrBefore(repertoryItemWeek.dateEnd, weekDateEnd);
      });
    },

    calculateOccupancy(cinema, weeks, repertoryItems) {
      const occupancy = new Occupancy(cinema, weeks, repertoryItems);

      this.weeksCapacity = occupancy.getWeeksCapacity();
      this.weeksCharge = occupancy.getWeeksCharge();
    },

    getItemChargePercent(duration, count, weekIndex) {
      const weekCapacity = this.weeksCapacity[weekIndex];

      if (weekCapacity) {
        const percent = Math.round(duration * count / weekCapacity * 100);

        return `${percent}%`;
      }

      return '';
    },

    fetchHolidays() {
      this.$store.dispatch('dictionaries/holidays/fetchItems');
    },

    selectDate(date) {
      if (this.calendarType === 'quarter') {
        this.dateStart = datetime.getStartOf(date, 'quarter');
        this.dateEnd = datetime.getEndOf(date, 'quarter');
      } else if (this.calendarType === 'month') {
        this.dateStart = datetime.getStartOf(date, 'month');
        this.dateEnd = datetime.getEndOf(date, 'month');
      } else {
        this.dateStart = datetime.findWeekStart(date);
        this.dateEnd = datetime.addWeeks(this.dateStart, this.weeksCount);
      }
    },

    changeCalendarType(value) {
      this.calendarType = value;

      let date = this.dateStart;

      if (!datetime.isFirstHalfOfMonth(date)) {
        date = datetime.findNextMonthStart(date);
      }

      this.selectDate(date);
    },

    changeViewMode(value) {
      this.viewMode = value;
    },

    setNextMonth() {
      if (this.calendarType === 'week') {
        this.calendarType = 'month';
      }

      this.dateStart = datetime.findNextMonthStart(this.dateStart);
      this.dateEnd = datetime.addMonthes(this.dateStart, this.calendarType === 'quarter' ? 3 : 1);
    },

    setPrevMonth() {
      if (this.calendarType === 'week') {
        this.calendarType = 'month';
      }

      this.dateStart = datetime.findPrevMonthStart(this.dateStart);
      this.dateEnd = datetime.addMonthes(this.dateStart, this.calendarType === 'quarter' ? 3 : 1);
    },

    setNextWeek() {
      if (this.calendarType === 'month') {
        this.calendarType = 'week';
      }

      this.dateStart = datetime.findNextWeekStart(this.dateStart);
      this.dateEnd = datetime.addWeeks(this.dateStart, this.calendarType === 'quarter' ? this.weeksCount * 4 : this.weeksCount);
    },

    setPrevWeek() {
      if (this.calendarType === 'month') {
        this.calendarType = 'week';
      }

      this.dateStart = datetime.findPrevWeekStart(this.dateStart);
      this.dateEnd = datetime.addWeeks(this.dateStart, this.calendarType === 'quarter' ? this.weeksCount * 4 : this.weeksCount);
    },
  },
};
</script>
