export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: true,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Наименование',
    filterable: true,
    sortable: true,
  },
  {
    key: 'prefix',
    type: 'text',
    label: 'Префикс',
    filterable: true,
    sortable: true,
  },
  {
    key: 'cinemas',
    type: 'text',
    label: 'Место действия',
    filterable: true,
    sortable: false,
  },
  {
    key: 'appGroups',
    type: 'text',
    label: 'Группа приложений',
    filterable: true,
    sortable: false,
  },
  {
    key: 'certificatesCount',
    type: 'text',
    label: 'Количество',
    filterable: false,
    sortable: false,
  },
  {
    key: 'notActivatedCount',
    type: 'text',
    label: 'Количество неактивированных',
    filterable: false,
    sortable: false,
  },
  {
    key: 'createdAt',
    type: 'date',
    label: 'Дата создания',
    filterable: false,
    sortable: true,
  },
  {
    key: 'allowActivation',
    type: 'text',
    label: 'Продажа',
    thStyle: {
      width: '200px',
    },
    sortable: false,
    filterable: true,
  },
];
