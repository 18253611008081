<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Название:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            autofocus
            size="sm"
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="ИНН:"
          :invalid-feedback="errors.inn"
          :state="states.inn"
        >
          <b-form-input
            type="text"
            size="sm"
            :maxlength="16"
            :value="item.inn"
            :state="states.inn"
            :disabled="loading"
            @change="updateValue('inn', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="КПП:"
          :invalid-feedback="errors.kpp"
          :state="states.kpp"
        >
          <b-form-input
            type="text"
            size="sm"
            :maxlength="16"
            :value="item.kpp"
            :state="states.kpp"
            :disabled="loading"
            @change="updateValue('kpp', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="ОГРН:"
          :invalid-feedback="errors.ogrn"
          :state="states.ogrn"
        >
          <b-form-input
            type="text"
            size="sm"
            :maxlength="16"
            :value="item.ogrn"
            :state="states.ogrn"
            :disabled="loading"
            @change="updateValue('ogrn', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Юридический адрес:"
          :invalid-feedback="errors.legalAddress"
          :state="states.legalAddress"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.legalAddress"
            :state="states.legalAddress"
            :disabled="loading"
            @change="updateValue('legalAddress', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Почтовый адрес:"
          :invalid-feedback="errors.postalAddress"
          :state="states.postalAddress"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.postalAddress"
            :state="states.postalAddress"
            :disabled="loading"
            @change="updateValue('postalAddress', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Телефоны:"
          :invalid-feedback="errors.phones"
          :state="states.phones"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.phones"
            :state="states.phones"
            :disabled="loading"
            @change="updateValue('phones', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="ФИО директора:"
          :invalid-feedback="errors.director"
          :state="states.director"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.director"
            :state="states.director"
            :disabled="loading"
            @change="updateValue('director', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="ФИО директора в родительном падеже:"
          :invalid-feedback="errors.directorGenitive"
          :state="states.directorGenitive"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.directorGenitive"
            :state="states.directorGenitive"
            :disabled="loading"
            @change="updateValue('directorGenitive', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Согласованная цена билета:"
          :invalid-feedback="errors.ticketPrice"
          :state="states.ticketPrice"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.ticketPrice"
            :state="states.ticketPrice"
            :disabled="loading"
            @change="updateValue('ticketPrice', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    DictionaryProvider,
    MaskedInput,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
