import FileSaver from 'file-saver';
import collectionServiceFactory from '../../services/collectionServiceFactory';

const DEFAULT_LIMIT = 20;

const emptyStoreModel = {
  state: {},
  actions: {},
  mutations: {},
  getters: {},
};

export default (collection, {
  state, actions, mutations, getters,
} = emptyStoreModel, useService = null) => {
  let collectionService = null;

  if (useService) {
    collectionService = useService;
  } else {
    collectionService = collectionServiceFactory(collection);
  }

  return {
    namespaced: true,

    state: {
      loading: false,
      items: [],
      total: 0,
      filter: {},
      params: {},
      sort: {
        by: '',
        desc: false,
      },
      page: 1,
      limit: DEFAULT_LIMIT,
      ...state,
    },

    actions: {
      async setFilter({ commit, dispatch }, { field, value }) {
        dispatch('resetPage');
        commit('setFilterValue', { field, value });
      },

      async changeFilter({ dispatch }, { field, value }) {
        dispatch('setFilter', { field, value });
        await dispatch('fetchItems');
      },

      async resetFilter({ commit }) {
        commit('setFilter', {});
      },

      async setSort({ commit }, { by, desc }) {
        commit('setSort', { by, desc });
      },

      async changeSort({ dispatch }, { by, desc }) {
        dispatch('setSort', { by, desc });
        dispatch('fetchItems');
      },

      async resetSort({ commit }) {
        commit('setSort', { by: '', desc: false });
      },

      async setParam({ commit, dispatch }, { field, value }) {
        dispatch('resetPage');
        commit('setParamValue', { field, value });
      },

      async changeParam({ dispatch }, { field, value }) {
        dispatch('setParam', { field, value });
        await dispatch('fetchItems');
      },

      async resetParams({ commit }) {
        commit('setParams', {});
      },

      async setPage({ commit }, page) {
        commit('setPage', { page });
      },

      async changePage({ dispatch }, page) {
        dispatch('setPage', page);
        dispatch('fetchItems');
      },

      async resetPage({ commit, state: { page } }) {
        if (page) {
          commit('setPage', { page: 1 });
        }
      },

      async setLimit({ commit, dispatch }, limit) {
        dispatch('resetPage');
        commit('setLimit', { limit });
      },

      async changeLimit({ dispatch }, limit) {
        dispatch('setLimit', limit);
        await dispatch('fetchItems');
      },

      async resetLimit({ commit, state: { limit } }) {
        if (limit) {
          commit('setLimit', { limit: DEFAULT_LIMIT });
        }
      },

      async resetItems({ commit, dispatch }) {
        commit('setItems', { items: [] });
        commit('setTotal', { total: 0 });
        dispatch('resetLimit');
        dispatch('resetPage');
      },

      async fetchItems({
        commit, state: {
          page, limit, sort, filter, params,
        },
      }) {
        commit('setLoading', true);

        const [, data] = await collectionService.fetchItems({
          page, limit, sort, filter, params,
        });

        if (data) {
          const { items, total } = data;

          commit('setItems', { items });
          commit('setTotal', { total });
        }

        commit('setLoading', false);

        return [, data];
      },

      async fetchNextItems({
        commit, state: {
          page, limit, sort, total, filter, params,
        },
      }) {
        commit('setLoading', true);

        if (page < Math.ceil(total / limit)) {
          const [, data] = await collectionService.fetchItems({
            page: page + 1, limit, sort, filter, params,
          });

          if (data) {
            const { items, total } = data;

            commit('setPage', { page: page + 1 });
            commit('pushItems', { items });
            commit('setTotal', { total });
          }
        }

        commit('setLoading', false);
      },

      async createItem({ commit, dispatch }, { values }) {
        commit('setLoading', true);

        const [err, item] = await collectionService.createItem(values);

        if (!err) {
          await dispatch('fetchItems');
        }

        commit('setLoading', false);

        return [err, item];
      },

      async updateItem({ commit, dispatch }, { id, values }) {
        commit('setLoading', true);

        const [err, result] = await collectionService.updateItem(id, values);

        if (!err) {
          await dispatch('fetchItems');
        }

        commit('setLoading', false);

        return [err, result];
      },

      async updateItems({ commit, dispatch }, { ids, values }) {
        commit('setLoading', true);

        await collectionService.updateItems(ids, values);

        await dispatch('fetchItems');

        commit('setLoading', false);
      },

      async removeItems({ commit, dispatch }, { ids }) {
        commit('setLoading', true);

        await collectionService.removeItems(ids);

        await dispatch('fetchItems');

        commit('setLoading', false);
      },

      async removeItem({ commit, dispatch }, { id }) {
        commit('setLoading', true);

        const [err, result] = await collectionService.removeItem(id);

        await dispatch('fetchItems');

        commit('setLoading', false);

        return [err, result];
      },

      async exportItem({ commit }, { id, filename, actionName = 'export' }) {
        commit('setLoading', true);

        const [err, data] = await collectionService.exportItem(id, actionName);

        if (err) {
          return [err, null];
        }

        if (!err && data) {
          const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
          FileSaver.saveAs(blob, filename);
        }

        commit('setLoading', false);

        return [null, true];
      },

      ...actions,
    },

    mutations: {
      setLoading(state, loading) {
        state.loading = loading;
      },

      setItems(state, { items }) {
        state.items = items;
      },

      pushItems(state, { items }) {
        state.items = [...state.items, ...items];
      },

      setTotal(state, { total }) {
        state.total = total;
      },

      setFilter(state, filter) {
        state.filter = filter;
      },

      setFilterValue(state, { field, value }) {
        state.filter = {
          ...state.filter,
          [field]: value,
        };
      },

      setParams(state, params) {
        state.params = params;
      },

      setParamValue(state, { field, value }) {
        state.params = {
          ...state.params,
          [field]: value,
        };
      },

      setSort(state, { by, desc }) {
        state.sort.by = by;
        state.sort.desc = desc;
      },

      setPage(state, { page }) {
        state.page = page;
      },

      setLimit(state, { limit }) {
        state.limit = limit;
      },

      ...mutations,
    },

    getters: {
      ...getters,
    },
  };
};
