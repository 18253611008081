<template>
  <div class="d-flex flex-column h-100 align-content-stretch">
    <app-header></app-header>
    <div class="wrapper">
      <div class="content">
        <div class="h-100">
          <scroll v-if="scrollable">
            <router-view></router-view>
          </scroll>
          <router-view v-else></router-view>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import AppHeader from './Header.vue';

export default {
  components: {
    AppHeader,
  },
  computed: {
    scrollable() {
      return this.$route.meta.scrollable || false;
    },
  },
};
</script>

<style>
.wrapper {
  flex: 1;
  position: relative;
}
.content {
  position:absolute;
  width:100%;
  height:100%;
  overflow: hidden;
}
</style>
