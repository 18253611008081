export default function stringComparer(value1, value2) {
  if (value1 === value2) {
    return 0;
  }

  return value1 > value2 ? 1 : -1;
}

export const getNoun = (
  number,
  one,
  two,
  five,
) => {
  let n = Math.abs(number);

  n %= 100;

  if (n >= 5 && n <= 20) {
    return five;
  }

  n %= 10;

  if (n === 1) {
    return one;
  }

  if (n >= 2 && n <= 4) {
    return two;
  }

  return five;
};
