<template>
  <div class="d-flex">
    <b-button
      v-if="distributorId && ($user.can('distributors.edit'))"
      size="sm"
      variant="outline-info"
      class="mr-1"
      :disabled="disabled"
      @click="$emit('edit')"
    >
      <icon icon="fa-pencil"></icon> Редактировать
    </b-button>
    <b-button
      v-if="distributorId && ($user.can('distributors.edit'))"
      size="sm"
      variant="outline-danger"
      class="mr-1"
      :disabled="disabled"
      @click="$emit('remove')"
    >
      <icon icon="fa-trash"></icon> Удалить
    </b-button>
    <b-button
      v-if="$user.can('distributors.edit')"
      size="sm"
      variant="info"
      class="ml-auto"
      :disabled="disabled"
      @click="$emit('create')"
    >
      <icon icon="fa-plus"></icon> Добавить прокатчика
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    distributorId: {},
    disabled: {
      default: false,
    },
  },
};
</script>
