export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'title',
    type: 'text',
    label: 'Название',
    filterable: true,
    sortable: false,
  },
  {
    key: 'date',
    type: 'text',
    label: 'Период действия',
    filterable: false,
    sortable: false,
  },
  {
    key: 'settings',
    type: 'select',
    label: 'Настройки',
    filterable: true,
    sortable: false,
  },
  {
    key: 'enabled',
    type: 'select',
    label: 'Статус',
    filterable: true,
    sortable: false,
  },
];
