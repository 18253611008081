var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cinema-provider',{attrs:{"route-name":_vm.routeName,"route-param-name":_vm.routeParamName},on:{"loaded":function($event){return _vm.$emit('loaded', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cinemaId = ref.cinemaId;
var cinema = ref.cinema;
var cinemas = ref.cinemas;
var halls = ref.halls;
var openCinema = ref.openCinema;
var loading = ref.loading;
return [_c('div',{staticClass:"h-100 d-flex flex-column"},[_c('portal',{attrs:{"to":"topbar"}},[_c('cinemas-select',{attrs:{"cinemas":cinemas,"cinema-id":cinemaId,"disabled":loading},on:{"change":function($event){return openCinema($event)}}})],1),(_vm.$slots.buttons || _vm.$scopedSlots.buttons)?_c('div',{staticClass:"d-flex border-bottom shadow-sm p-2 z-1"},[_vm._t("buttons",null,{"cinemaId":cinemaId,"cinema":cinema,"cinemas":cinemas,"halls":halls,"loading":loading,"openCinema":openCinema})],2):_vm._e(),(_vm.$slots.scroll || _vm.$scopedSlots.scroll)?_c('scroll',[_c('div',{staticClass:"pt-3 pb-3 pl-2 pr-2"},[(loading && _vm.displayLoadingStub)?_c('loading'):_vm._e(),(!loading)?_vm._t("scroll",null,{"cinemaId":cinemaId,"cinema":cinema,"cinemas":cinemas,"halls":halls,"loading":loading,"openCinema":openCinema}):_vm._e()],2)]):_vm._e(),(_vm.$slots.fixed || _vm.$scopedSlots.fixed)?_c('div',{staticClass:"w-100 h-100 p-0 overflow-hidden"},[(loading && _vm.displayLoadingStub)?_c('loading'):_vm._e(),(!loading)?_vm._t("fixed",null,{"cinemaId":cinemaId,"cinema":cinema,"cinemas":cinemas,"halls":halls,"loading":loading,"openCinema":openCinema}):_vm._e()],2):_vm._e()],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }