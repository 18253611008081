<template>
  <dictionary-map
    :keys="colorSchemes"
    :items="createMapItems(colorSchemeFormulas)"
    :disabled="disabled"
    @change="onChangeMap"
    :texts="{
        add: 'Добавить ценовой пояс',
        empty: 'Ценовые пояса не указаны',
        key: 'Ценовой пояс',
        value: 'Изменение цены',
      }"
  >
    <template #value="{disabled, item, setValue}">
      <price-formula
        :value="item.value"
        :operations="operations"
        :disabled="disabled"
        @change="setValue($event)"
      ></price-formula>
    </template>
  </dictionary-map>
</template>

<script>
import PriceFormula from '../fields/PriceFormula.vue';
import DictionaryMap from './DictionaryMap.vue';

export default {
  components: {
    DictionaryMap,
    PriceFormula,
  },
  props: {
    disabled: {
      default: false,
    },
    colorSchemes: {
      default: () => [],
    },
    colorSchemeFormulas: {
      default: () => [],
    },
    operations: {
      default: () => ['-', '=', '+'],
    },
  },
  methods: {
    createMapItems(colorSchemeFormulas) {
      return colorSchemeFormulas
        ? colorSchemeFormulas.map(
          (colorSchemeFormula) => ({
            keyId: colorSchemeFormula.id,
            value: colorSchemeFormula.pivot.formula,
          }),
        )
        : [];
    },
    createColorSchemeFormulas(mapItems) {
      return mapItems
        ? mapItems.map(
          (mapItem) => ({
            id: mapItem.keyId,
            pivot: { formula: mapItem.value },
          }),
        )
        : [];
    },
    onChangeMap(items) {
      this.$emit('change', this.createColorSchemeFormulas(items));
    },
  },
};
</script>
