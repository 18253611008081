<template>
  <div
    class="d-flex w-100 media-panel-groups"
    :class="{ 'media-panel-groups__loading': loading }"
  >
    <group v-for="group in groupAndScreens" :key="group.id" :group="group">
      <template #group-action>
        <b-dropdown
          right
          size="sm"
          variant="link"
          toggle-class="text-decoration-none p-0"
        >
          <template #button-content>
            <icon
              icon="mdi-dots-horizontal"
              class="rounded cursor-pointer group__menu-icon"
            >
            </icon>
          </template>

          <b-dropdown-item-button
            v-if="group.countScreens && group.id !== null"
            @click="
              $emit('update-settings-group', {
                groupName: group.name,
                groupId: group.id,
                settingId: group.mediablockSettingsId
              })
            "
            >Настроить</b-dropdown-item-button
          >
          <b-dropdown-item-button
            @click="$emit('update-group-name', { id: group.id })"
            >Изменить название</b-dropdown-item-button
          >
          <b-dropdown-item-button @click="removeGroup(group.id)"
            >Удалить</b-dropdown-item-button
          >
        </b-dropdown>
      </template>
      <template #screens>
        <container
          group-name="group"
          :tag="{ value: 'div', props: { class: 'flex-fill pt-2' } }"
          :drop-placeholder="dropPlaceholderOptions"
          :get-child-payload="getScreenByGroupId(group.id)"
          @drop="e => onScreenDrop(group.id, e)"
        >
          <draggable
            v-for="screen in group.screens"
            :key="screen.id"
            :tag="{ value: 'div', props: { class: 'py-1' } }"
          >
            <screen
              :screen="screen"
              :settings="settings"
            >
              <template #action>
                <div class="d-flex flex-column justify-content-between align-items-center screen__settings">
                  <div class="screen__settings-edit cursor-pointer">
                    <b-dropdown
                      right
                      size="sm"
                      variant="link"
                      toggle-class="text-decoration-none p-0"
                    >
                      <template #button-content>
                        <icon
                          icon="mdi-square-edit-outline"
                          class="rounded cursor-pointer screen__menu-icon"
                        >
                        </icon>
                      </template>
                      <b-dropdown-item-button
                        v-if="group.id === null"
                        @click="
                          $emit('update-settings-screen', {
                            screenName: screen.name,
                            screenId: screen.id,
                            settingId: screen.mediablockSettingsId
                          })
                        "
                        >Настроить</b-dropdown-item-button
                      >
                      <b-dropdown-item-button
                        @click="$emit('update-screen', { id: screen.id })"
                        >Изменить название</b-dropdown-item-button
                      >
                      <b-dropdown-item-button
                        @click="$emit('reboot-screen', { id: screen.id })"
                        >Перезагрузить</b-dropdown-item-button
                      >
                      <b-dropdown-item-button
                        @click="$emit('remove-screen', { id: screen.id })"
                        >Удалить</b-dropdown-item-button
                      >
                    </b-dropdown>
                  </div>
                  <div
                    class="cursor-pointer screen__settings-screen"
                    @click="$emit('screenshot', { screenId: screen.id })"
                  >
                    <icon
                      icon="mdi-television"
                      class="rounded cursor-pointer screen__menu-icon"
                    ></icon>
                  </div>
                </div>
              </template>
            </screen>
          </draggable>
        </container>
      </template>
    </group>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd';

import Group from './Group.vue';
import Screen from './Screen.vue';

export default {
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    screens: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      dropPlaceholderOptions: {
        className: 'drop-screens',
        animationDuration: '150',
      },

      groupAndScreens: [],
      completeDragAndDrop: true,
      updateScreens: [],
    };
  },
  watch: {
    groups: {
      handler(groups) {
        this.groupedGroupAndScreens({ groups });
      },
      immediate: true,
      deep: true,
    },

    screens: {
      handler(screens) {
        this.groupedGroupAndScreens({ screens });
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    Container,
    Draggable,
    Group,
    Screen,
  },
  methods: {
    groupedGroupAndScreens({
      groups = this.groups,
      screens = this.screens,
    } = {}) {
      this.groupAndScreens = groups.map((group) => ({
        ...group,
        countScreens: screens.filter((screen) => screen.groupId === group.id).length,
        screens: screens
          .filter((screen) => screen.groupId === group.id)
          .map((screen) => ({
            ...screen,
            mediablockSettingsId: group.id === null ? screen.mediablockSettingsId : group.mediablockSettingsId,
          }))
          .sort((a, b) => a.positionIndex - b.positionIndex),
      }));
    },

    getScreenByGroupId(groupId) {
      return (index) => this.getScreensByGroupId(groupId)[index];
    },

    getScreensByGroupId(id) {
      return this.groupAndScreens.find((group) => group.id === id).screens;
    },

    onScreenDrop(groupId, dropResult) {
      let updatePositionScreens = [];
      const { removedIndex, addedIndex, payload: screenDroped } = dropResult;

      if (removedIndex !== null || addedIndex !== null) {
        updatePositionScreens = this.getScreensByGroupId(groupId);

        if (removedIndex !== null) {
          updatePositionScreens.splice(removedIndex, 1);
          this.completeDragAndDrop = !this.completeDragAndDrop;
        }

        if (addedIndex !== null) {
          updatePositionScreens.splice(addedIndex, 0, screenDroped);
          this.completeDragAndDrop = !this.completeDragAndDrop;
        }

        updatePositionScreens.forEach((screen, positionIndex) => {
          updatePositionScreens[positionIndex] = {
            ...screen,
            groupId,
            positionIndex,
          };
        });

        this.updateScreens = [...this.updateScreens, ...updatePositionScreens];

        if (this.updateScreens.length > 0 && this.completeDragAndDrop) {
          this.$emit('update-position-screens', this.updateScreens);
          this.updateScreens = [];
        }
      }
    },

    removeGroup(id) {
      if (this.getScreensByGroupId(id).length > 0) {
        this.updateScreens = [
          ...this.getScreensByGroupId(null),
          ...this.getScreensByGroupId(id),
        ];

        this.updateScreens.forEach((screen, positionIndex) => {
          this.$set(this.updateScreens, positionIndex, {
            ...screen,
            groupId: null,
            isMain: false,
            positionIndex,
          });
        });

        this.$emit('update-position-screens', this.updateScreens);
        this.updateScreens = [];
      }

      this.$emit('remove-group', { id });
    },
  },
};
</script>

<style lang="scss" scoped>
.media-panel-groups {
  min-height: 100%;

  /deep/ .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    overflow: inherit;
  }

  /deep/ .dropdown-toggle::after {
    display: none;
  }
}
</style>
