<template>
  <info-card v-if="movie">
    <info-card-row>
      <template #label>Наименование для отчетов</template>
      <template #value>{{ movie.reportName || 'Не задано' }}</template>
    </info-card-row>

    <info-card-row>
      <template #label>Номер прокатного удостоверения для отчетов</template>
      <template #value>{{ movie.reportRentalLicenseNumber || 'Не задано' }}</template>
    </info-card-row>

    <info-card-row>
      <template #label>Выгружать данные о продажах в ЕАИС</template>
      <template #value>{{ movie.includeEkb ? 'Да' : 'Нет' }}</template>
    </info-card-row>

    <info-card-row>
      <template #label>Выгружать данные о продажах в Рентрак</template>
      <template #value>{{ movie.includeRentrak ? 'Да' : 'Нет' }}</template>
    </info-card-row>

    <info-card-row>
      <template #label>Включать данные о продажах в отчеты РАО</template>
      <template #value>{{ movie.includeRao ? 'Да' : 'Нет' }}</template>
    </info-card-row>

    <info-card-row>
      <template #label>Показывать продолжительность фильма</template>
      <template #value>
        <span>{{ movie.displayDuration ? 'Да' : 'Нет' }}</span>
      </template>
    </info-card-row>

    <info-card-row v-if="movie.culturalEvent">
      <template #label>Культурное мероприятие</template>
    </info-card-row>

    <info-card-row>
      <template #label>Разрешить использовать бонусы</template>
      <template #value>{{ movie.useBonuses ? 'Да' : 'Нет' }}</template>
    </info-card-row>
  </info-card>
</template>

<script>
import InfoCard from './InfoCard.vue';
import InfoCardRow from './InfoCardRow.vue';

export default {
  components: {
    InfoCard,
    InfoCardRow,
  },
  props: {
    movie: {
      default: () => ({}),
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
  /deep/ .card-release {
    &__title {
      min-width: 340px;
    }
  }

  .format-item:not(:last-child) {
    margin-right: 5px;

    &::after {
      content: ',';
    }
  }

  .ttc {
    text-transform: capitalize;
  }
</style>
