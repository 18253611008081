<template>
  <cinema-page
    route-name="orders"
    route-param-name="cinemaId"
  >
    <template #fixed="{ cinemaId }">
      <multi-view class="report report-cashier">
        <template #side>
          <filters :cinema-id="cinemaId"></filters>
        </template>

        <template #content>
          <orders
            ref="orders"
            :cinema-id="cinemaId"
          ></orders>
        </template>
      </multi-view>
    </template>
  </cinema-page>
</template>

<script>
import CinemaPage from '../../layout/CinemaPage.vue';
import MultiView from '../../layout/MultiView.vue';

import Filters from './Filters.vue';
import Orders from './Orders.vue';

export default {
  components: {
    MultiView,
    CinemaPage,
    Filters,
    Orders,
  },
};
</script>
