<template>
  <div class="selected-show border-top d-flex">
    <div
        v-if="show.advertisementsDuration"
        class="d-flex flex-column w-15 border-right p-1 bg-primary text-white cursor-pointer"
        @click="$emit('open-advertisements')"
    >
      <div class="flex-grow-1 text-center">{{advertisementsDuration}}</div>
      <div
          class="d-flex justify-content-between"
      >
        <div>{{advertisementsStart}}</div>
        <div>{{advertisementsEnd}}</div>
      </div>
    </div>
    <div class="d-flex flex-grow-1 border-right">
      <div class="d-flex flex-column flex-grow-1 justify-content-center p-3">
        <div>
          <span class="mr-1 font-weight-bold">{{show.release.movie.name}}</span>
          <span class="border p-1 mr-1">{{show.release.format.name}}</span>
          <span class="mr-1">{{show.release.movie.ageLimit}}+</span>
        </div>
        <div>
          <span class="text-secondary">Длительность: {{show.release.duration}} мин.</span>
        </div>
        <div class="d-flex show-sales">
          <template v-if="!show.prices">
            <span>Загрузка цен...</span>
          </template>
          <template v-else>
            <span
                v-if="show.appGroups && show.appGroups.length"
                class="show-sales_status mr-3 bg-success"
            >Открыты продажи</span>
            <b-link @click="openOrdersShow">Продано: {{ show.ticketsCount }}</b-link>
            <span class="ml-3">Забронировано: {{ show.reservedPlacesCount }}</span>
            <span class="ml-3">Свободно: {{ freePlacesCount }}</span>
            <span class="ml-3">Стоимость: {{ minPrice }}₽</span>
          </template>
        </div>
      </div>
      <div class="flex flex-column">
        <span
            class="selected-show__close"
            @click.stop="$emit('close')"
        >
          <icon icon="fa-times"></icon>
        </span>
      </div>
    </div>
    <div
        v-if="gap && gap.interval"
        class="d-flex flex-column w-15 p-1 bg-success text-white"
    >
      <div class="flex-grow-1 text-center">{{gap.interval}} мин.</div>
      <div class="d-flex justify-content-between">
        <div>{{gapStart}}</div>
        <div>{{gapEnd}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import datetime from '../../utils/datetime';

export default {
  props: {
    show: {
      default: () => ({}),
    },
    gap: {
      default: () => ({}),
    },
    placesCount: {
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('data/shows', {
      cinemaId: (state) => state.cinemaId,
    }),

    advertisementsStart() {
      return datetime.formatTime(this.show.time);
    },

    advertisementsEnd() {
      return datetime.formatTime(datetime.getIntervalEndTime(this.show.time, this.show.advertisementsDuration));
    },

    advertisementsDuration() {
      if (!this.show.advertisementsDuration) {
        return '0:00';
      }

      const minutes = Math.floor(this.show.advertisementsDuration);
      const seconds = Math.ceil((this.show.advertisementsDuration - minutes) * 60);

      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },

    gapStart() {
      return datetime.formatTime(this.gap.time);
    },

    gapEnd() {
      return datetime.formatTime(datetime.getIntervalEndTime(this.gap.time, this.gap.interval));
    },

    freePlacesCount() {
      return this.show && (this.placesCount - this.show.ticketsCount - this.show.reservedPlacesCount);
    },

    minPrice() {
      const minPrice = this.show.prices.reduce((prev, curr) => (prev.price < curr.price ? prev : curr));

      if (!minPrice.priceId) {
        return 0;
      }

      return minPrice.price;
    },
  },
  methods: {
    openOrdersShow() {
      this.$router.push({
        name: 'orders',
        params: {
          cinemaId: this.cinemaId,
        },
        query: {
          showDatetimeStart: this.show.datetime,
          showDatetimeEnd: this.show.datetime,
        },
      });
    },
  },
};
</script>

<style scoped>
.w-15 {
  width: 15%;
}
.selected-show__close {
  color: #ccc;
  cursor: pointer;
}
.selected-show__close:hover {
  color: #AA0000;
}
.show-sales {
  margin-top: 8px;
  font-size: 14px;
}
.show-sales_status {
  border-radius: 6px;
  padding: 0 8px;
  color: #fff;
}
</style>
