<template>
  <div class="d-flex justify-content-end align-items-center mb-0 ml-5">
    <span class="label mr-2 text-nowrap">Показать по</span>
    <b-form-select
      :options="options"
      :value="value"
      :size="buttonSize"
      :disabled="disabled"
      @change="$emit('update', $event)"
    />
    <span class="label ml-2">записей</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      default: false,
    },
    buttonSize: {
      type: String,
      default: 'sm',
    },
  },
  data() {
    return {
      defaultOptions: [5, 15, 30, 50, 100],
    };
  },
  computed: {
    options() {
      if (!this.defaultOptions.includes(this.value)) {
        const options = [...this.defaultOptions, this.value];
        options.sort((a, b) => a - b);
        return options;
      }

      return this.defaultOptions;
    },
  },
};
</script>
