<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Наименование:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        type="text"
        autofocus
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Комментарий:"
      :invalid-feedback="errors.comment"
      :state="states.comment"
    >
      <b-form-input
        size="sm"
        :value="item.comment"
        :state="states.comment"
        :disabled="loading"
        @change="updateValue('comment', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Длительность:"
      :invalid-feedback="errors.duration"
      :state="states.duration"
    >
      <masked-input
        type="text"
        class="form-control form-control-sm"
        :mask="mask.getTimeMask"
        :placeholder="durationPlaceholder"
        :guide="false"
        :value="convertToTimeFromSeconds(item.duration)"
        :disabled="loading"
        @change="updateValue('duration', convertToSecondsFromTime($event.target.value))"
      >
      </masked-input>    
    </b-form-group>

    <b-form-group
      label="Позиция в рекламном блоке:"
      :invalid-feedback="errors.positionType"
      :state="states.positionType"
    >
      <b-form-radio-group
        name="positionType"
        stacked
        :state="states.positionType"
        :checked="item.positionType"
        :disabled="loading"
        @change="updateValue('positionType', $event)"
      >
        <b-form-radio value="0">Не важно</b-form-radio>
        <b-form-radio value="1">Предпоследний</b-form-radio>
        <b-form-radio value="2">Последний</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Время действия</div>
    </div>

    <b-form-group
      label="Дата начала и окончания показа:"
      :invalid-feedback="errors.dateEnd || errors.dateStart"
      :state="states.dateEnd || states.dateStart"
    >
      <date-interval
        :value="{from: item.dateStart, to: item.dateEnd}"
        :disabled="loading"
        @selected="updateValue('dateStart', $event.from), updateValue('dateEnd', $event.to)">
      </date-interval>
    </b-form-group>

    <b-form-group
      label="Время показа:"
      :invalid-feedback="errors.timeStart || errors.timeEnd"
      :state="states.timeStart || states.timeEnd"
    >
      <time-interval
        :value="{from: item.timeStart, to: item.timeEnd}"
        :disabled="loading"
        @change="updateValue('timeStart', $event.from), updateValue('timeEnd', $event.to)"
      ></time-interval>
    </b-form-group>

    <b-form-group
      label="Дни недели:"
      :invalid-feedback="errors.days"
      :state="states.days"
    >
      <toggle-list
        placeholder="Все"
        :options="days"
        :value="item.days"
        :disabled="loading"
        @change="updateValue('days', $event)"
      ></toggle-list>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Место показа</div>
    </div>

    <b-form-group
      label="Залы:"
      :invalid-feedback="errors.halls"
      :state="states.halls"
    >
      <toggle-list
        placeholder="Все"
        :options="halls"
        :value="item.halls"
        :disabled="loading"
        @change="updateValue('halls', $event)"
      ></toggle-list>
    </b-form-group>

    <div class="text-uppercase flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Фильтры фильмов для показа</div>
    </div>

    <b-form-group
      label="Релизы:"
      :invalid-feedback="errors.releases"
      :state="states.releases"
    >
      <dictionary-provider
        name="repertoryReleases"
        :filter="clearFilter({ cinemaId: item.cinemaId, dateStart: item.dateStart, dateEnd: item.dateEnd })"
        :disable-fetching="!item.cinemaId || !(item.dateStart || item.dateEnd)"
      >
        <template #default="{ items, loading: releasesLoading }">
          <toggle-list
            placeholder="Все"
            :options="convertReleases(items)"
            :value="item.releases"
            :searchable="true"
            :disabled="loading || releasesLoading || !item.cinemaId || !(item.dateStart || item.dateEnd)"
            @change="updateValue('releases', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Форматы фильмов:"
      :invalid-feedback="errors.formats"
      :state="states.formats"
    >
      <dictionary-provider name="formats2">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.formats"
            :disabled="loading"
            @change="updateValue('formats', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Возрастное ограничение:"
      :invalid-feedback="errors.ageLimit"
      :state="states.ageLimit"
    >
      <dictionary-provider name="ageLimits">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.ageLimits"
            :disabled="loading"
            @change="updateValue('ageLimits', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>
  </b-container>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import DictionaryProvider from '../../providers/DictionaryProvider';
import DateInterval from '../../components/fields/DateInterval.vue';
import TimeInterval from '../../components/fields/TimeInterval.vue';
import ToggleList from '../../components/fields/ToggleList.vue';

import { MaskHelper } from '../../utils/mask';

export default {
  components: {
    DictionaryProvider,
    DateInterval,
    TimeInterval,
    ToggleList,
    MaskedInput,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
    halls: {
      default: () => [],
    },
    durationPlaceholder: {
      type: String,
      default: `00:00`,
    },
  },
  data() {
    return {
      days: [
        { id: 1, name: 'ПН' },
        { id: 2, name: 'ВТ' },
        { id: 3, name: 'СР' },
        { id: 4, name: 'ЧТ' },
        { id: 5, name: 'ПТ' },
        { id: 6, name: 'СБ' },
        { id: 7, name: 'ВС' },
      ],
      mask: MaskHelper,
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    clearFilter(filter) {
      const result = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(filter)) {
        if (typeof value !== 'undefined') {
          result[key] = value;
        }
      }

      return result;
    },
    convertToTimeFromSeconds(seconds) {
      return seconds ? new Date(1000 * seconds).toISOString().substr(14, 5) : '';
    },
    convertToSecondsFromTime(time) {
      console.log(time);
      const [minutes, seconds] = time ? time.split(':') : [0, 0];

      return 60 * parseInt(minutes, 10) + parseInt(seconds, 10);
    },
    convertReleases(releases) {
      if (!releases) {
        return [];
      }

      return releases.map((release) => ({
        id: release.id,
        name: release?.format?.name ? `${release?.movie?.name}, ${release?.format?.name}` : release?.movie?.name,
      }));
    },
  },
};
</script>
