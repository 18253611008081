export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditDiscountForm',
        permission: 'discounts.edit',
      },
      {
        type: 'button',
        text: item.isEnabled ? 'Остановить' : 'Активировать',
        action: item.isEnabled ? 'pauseDiscount' : 'resumeDiscount',
        permission: 'discounts.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeDiscount',
        permission: 'discounts.edit',
      },
    ],
  },
];
