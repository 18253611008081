<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Email:"
          :invalid-feedback="errors.email"
          :state="states.email"
        >
          <b-form-input
            type="text"
            autofocus
            size="sm"
            :value="item.email"
            :state="states.email"
            :disabled="loading"
            @change="updateValue('email', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          label="Пароль:"
          :invalid-feedback="errors.password"
          :state="states.password"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.password"
            :state="states.password"
            :disabled="loading"
            @change="updateValue('password', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="ФИО:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          label="Телефон:"
          :invalid-feedback="errors.phone"
          :state="states.phone"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.phone"
            :state="states.phone"
            :disabled="loading"
            @change="updateValue('phone', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Примечание:"
          :invalid-feedback="errors.note"
          :state="states.note"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.note"
            :state="states.note"
            :disabled="loading"
            @change="updateValue('note', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Роли:"
          :invalid-feedback="errors.roles"
          :state="states.roles"
        >
          <dictionary-provider name="roles">
            <template #default="{ items, loading: dictionaryLoading }">
              <toggle-list
                :options="items"
                :value="item.roles"
                :disabled="loading || dictionaryLoading"
                @change="updateValue('roles', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
          label="Кинотеатры:"
          :invalid-feedback="errors.cinemas"
          :state="states.cinemas"
        >
          <dictionary-provider name="cinemas">
            <template #default="{ items, loading: dictionaryLoading }">
              <toggle-list
                :options="convertCinemas($array.union(items, item.cinemas))"
                :value="item.cinemas"
                :disabled="loading || dictionaryLoading"
                @change="updateValue('cinemas', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },
  },
};
</script>
