/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import FileSaver from 'file-saver';

import reportServiceFactory from '../../services/reportServiceFactory';

export default (reportName, {
  state, actions, mutations, getters,
} = {
  state: {},
  actions: {},
  mutations: {},
  getters: {},
}) => {
  const reportService = reportServiceFactory(reportName);

  return {
    namespaced: true,
    state: {
      report: null,
      errors: {},
      states: {},
      params: {},
      filter: {},
      sort: {
        by: '',
        desc: false,
      },
      cinemaId: null,
      loading: false,
      ...state,
    },

    getters: {
      ...getters,
    },

    actions: {
      async setParam({ commit }, { field, value }) {
        commit('setParamsValue', { field, value });
      },

      async resetParams({ commit }) {
        commit('setParams', {});
      },

      async setFilter({ commit }, { field, value }) {
        commit('setFilterValue', { field, value });
      },

      async resetFilter({ commit }) {
        commit('setFilter', {});
      },

      async setSort({ commit }, { by, desc }) {
        commit('setSort', { by, desc });
      },

      async resetSort({ commit }) {
        commit('setSort', { by: '', desc: false });
      },

      async resetReport({ commit }) {
        commit('setReport', null);
      },

      async reset({ commit }) {
        commit('setParams', {});
        commit('setFilter', {});
        commit('setSort', { by: '', desc: false });
        commit('setLoading', false);
        commit('resetErrors');
        commit('resetState');
        commit('setReport', null);
      },

      async fetchReport({ commit, state: { params, filter, sort } }, { format, filename }) {
        commit('setLoading', true);

        commit('resetErrors');
        commit('resetState');

        const [err, data] = await reportService.fetchReport({
          format,
          filter,
          sort,
          params,
        });

        if (err && err.status === 422 && err?.data?.errors) {
          const errors = err?.data?.errors;

          for (const field in errors) {
            commit('setError', { field, value: errors[field].join(', ') });
            commit('setState', { field, value: false });
          }
        }

        if (!err && data?.content) {
          if (format === 'html' || format === 'json') {
            commit('setReport', data.content);
          }

          if (format === 'pdf' || format === 'xlsx') {
            const name = filename || data.filename || `report.${format}`;

            FileSaver.saveAs(data.content, name);
          }
        }

        commit('setLoading', false);

        return [err, data?.content];
      },

      ...actions,
    },

    mutations: {
      setReport(state, report) {
        state.report = report;
      },

      setError(state, { field, value }) {
        state.errors = {
          ...state.errors,
          [field]: value,
        };
      },

      resetErrors(state) {
        state.errors = {};
      },

      setState(state, { field, value }) {
        state.states = {
          ...state.states,
          [field]: value,
        };
      },

      resetState(state) {
        state.states = {};
      },

      setLoading(state, loading) {
        state.loading = loading;
      },

      setFilter(state, filter) {
        state.filter = filter;
      },

      setFilterValue(state, { field, value }) {
        state.filter = {
          ...state.filter,
          [field]: value,
        };
      },

      setParams(state, params) {
        state.params = params;
      },

      setParamsValue(state, { field, value }) {
        state.params = {
          ...state.params,
          [field]: value,
        };
      },

      setSort(state, { by, desc }) {
        state.sort.by = by;
        state.sort.desc = desc;
      },

      ...mutations,
    },
  };
};
