var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container tags-items"},[_c('collection-provider',{ref:"collection",attrs:{"collection":"tags","sort-by":"position","sort-desc":false,"page":null,"limit":1000},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable-drag',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"panel-actions":_vm.datatable.panelActions,"item-actions":_vm.datatable.itemActions,"checked":_vm.datatable.checked,"local-sortable":true},on:{"change-checked":function($event){_vm.datatable.checked = $event},"panel-action":function($event){return _vm.performPanelAction($event)},"item-action":function($event){return _vm.performItemAction($event)},"change-positions":function($event){return _vm.changePositions($event)}},scopedSlots:_vm._u([{key:"toolbar-content",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"w-300 mr-2"},[_c('dictionary-provider',{attrs:{"name":"cinemas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":data.filter.cinemas,"options":_vm.convertCinemas(items),"disabled":data.loading},on:{"change":function($event){return actions.changeFilter('cinemaId', $event)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Все кинотеатры")])]},proxy:true}],null,true)})]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-form-select',{attrs:{"size":"sm","value":data.filter.active,"options":_vm.tagActiveStatuses,"disabled":data.loading},on:{"change":function($event){return actions.changeFilter('active', $event)}}})],1)])]},proxy:true},{key:"cell-releases",fn:function(data){return [_c('tag-releases',{attrs:{"item":data.item}})]}},{key:"cell-period",fn:function(data){return [_c('div',{staticClass:"w-300"},[(data.item.dateStart)?[_vm._v(" c "+_vm._s(_vm.formatDate(data.item.dateStart))+" ")]:_vm._e(),(data.item.dateEnd)?[_vm._v(" по "+_vm._s(_vm.formatDate(data.item.dateEnd))+" ")]:_vm._e()],2)]}},{key:"cell-color",fn:function(data){return [_c('div',{staticClass:"tag-color",style:(("background-color: " + (data.item.color) + ";"))})]}},{key:"cell-cinemas",fn:function(data){return [_c('tag-cinemas',{attrs:{"item":data.item}})]}},{key:"cell-active",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getTagActiveName(data.item.active))+" ")]}}],null,true)},'datatable-drag',Object.assign({}, data, actions),false))]}}])}),(_vm.modals.tags.show)?_c('modal-model-form',{attrs:{"create-title":"Добавить метку","edit-title":"Редактировать метку","collection":"tags","id":_vm.modals.tags.id},on:{"close":function($event){_vm.modals.tags.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('tag-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,false,1872032495)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }