<template>
  <b-row align-v="center" class="mb-2">
    <b-col style="width: 220px; flex: none;">
      <div class="selection-form__title">{{ name }}</div>
    </b-col>
    <b-col>
      <div class="flex">
        {{ selectionsSize }}
        <b-button
          variant="info"
          class="ml-2"
          size="sm"
          :disabled="disabled"
          @click="fetchSelectionsSize()"
        >
          Подсчитать
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { getSelectionSize } from '../../api/selections';

export default {
  props: {
    name: {
      default: '',
    },
    include: {
      default: null,
    },
    exclude: {
      default: null,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      selectionsSize: 0,
    };
  },
  methods: {
    async fetchSelectionsSize() {
      const [err, data] = await getSelectionSize({
        include: this.include,
        exclude: this.exclude,
      });

      if (data) {
        this.selectionsSize = data?.amount || 0;
      }
      if (err && err.status && err.status === 422) {
        this.$notify({
          type: 'error',
          text: err.data.error,
        });
      }
    },
  },
};
</script>

<style scoped>
.selection-form__title {
  line-height: 30px;
  font-weight: 500;
}
</style>
