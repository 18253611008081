<template>
  <div class="p-2 shadow-sm d-flex justify-content-between">
    <b-button
      size="sm"
      variant="outline-info"
      @click="handleBack"
    >
      <span slot="text">
        <icon icon="fa-chevron-left"></icon> Назад
      </span>
    </b-button>

    <div class="d-flex">
      <b-button
        v-if="isFavorite"
        size="sm"
        :variant="enabledFavorite ? 'outline-warning' : 'outline-secondary'"
        @click="$emit('favorite-movie')"
      >
        <span slot="text">
          <icon icon="fa-star"></icon> Добавить в избранное
        </span>
      </b-button>

      <b-button
        v-if="$user.can('movies.edit')"
        size="sm"
        variant="outline-info"
        class="ml-2"
        @click="$emit('edit-movie')"
      >
        <span slot="text">
          <icon icon="fa-pencil"></icon> Редактировать информацию
        </span>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: null,
    },
  },
  computed: {
    isFavorite() {
      return !!this.item.releases.length;
    },

    enabledFavorite() {
      return this.item.releases.some((release) => !!release.favoriteCinemas.length);
    },
  },
  methods: {
    handleBack() {
      this.$router.push({
        name: 'movies2',
      });
    },
  },
};
</script>
