var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('analytics-provider',{attrs:{"table":"releases_sales","params":{
    cinema_id: _vm.cinemaId,
    date_start: _vm.dateStart,
    date_end: _vm.dateEnd,
  },"page":_vm.page,"limit":_vm.limit,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"disabled":!_vm.dateStart || !_vm.dateEnd},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var items = ref.items;
  var total = ref.total;
  var loading = ref.loading;
return [_c('div',[_c('b-table',{staticClass:"datatable border",attrs:{"fields":_vm.fields,"items":items,"striped":true,"hover":false,"no-local-sorting":true,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":loading,"show-empty":true,"empty-text":loading ? 'Загрузка...' : 'Нет данных'},on:{"sort-changed":function($event){_vm.sortBy = $event.sortBy, _vm.sortDesc = !!$event.sortDesc}},scopedSlots:_vm._u([{key:"cell(release_name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.movie_name)+" "+_vm._s(data.item.format_name)+" ")]}},{key:"cell(occupation)",fn:function(data){return [_c('span',{class:[data.item.occupation < 5 || data.item.occupation > 70 ? 'text-danger' : '']},[_vm._v(_vm._s(data.item.occupation))])]}},{key:"cell(first_show_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getRentalWeek(data.item.first_show_date, data.item.last_show_date))+" ")]}}],null,true)}),(total > _vm.limit)?_c('b-pagination',{staticClass:"pagination",attrs:{"size":"sm","align":"center","value":_vm.page,"total-rows":total,"per-page":_vm.limit},on:{"change":function($event){_vm.page = $event}}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }