import humps from 'humps';
import fetchAnalytics, { exportCustomers as apiExportCustomers } from '../api/analytics';
import { convertBlobToString } from '../utils/file';

export default {
  async fetch(params) {
    const [err, data] = await fetchAnalytics(params);

    return [err, data];
  },

  async exportCustomers(params) {
    let [err, data] = await apiExportCustomers(params);

    if (err && err.data && err.data instanceof Blob) {
      const text = await convertBlobToString(err.data);
      err.data = JSON.parse(text);
    }

    if (data instanceof Blob) {
      data = await convertBlobToString(data);
    }

    return [humps.camelizeKeys(err), data];
  },
};
