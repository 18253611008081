<template>
  <div
    class="timeline-gap d-flex flex-column justify-content-end h-100 w-100"
    :class="{'timeline-gap_warning': warning}"
  >
    <div class="text-center">{{interval}}</div>
  </div>
</template>

<script>
export default {
  props: {
    interval: {
      default: 0,
    },
    warning: {
      default: false,
    },
  },
};
</script>

<style scoped>
.timeline-gap {
  background-color: #00aec5;
  padding: 2px;
  display: inline-block;
  color: #fff;
  font-size: 10px;
}
.timeline-gap_warning {
  background-color: #aa0000;
}
</style>
