<template>
  <div class="p-3 border rounded d-flex flex-column">
    <div class="font-weight-bold mb-2">{{ title }}</div>
    <div class="d-flex align-items-center flex-grow-1">
      <div class="w-50 mr-2 percents">
        <div
          v-for="(label, index) in labels"
          class="d-flex"
          :key="index"
        >
          <div class="mr-auto d-flex align-items-center">
            <div
              class="mark mr-1"
              :style="{'background-color': colors[index]}"
            ></div>
            <div>{{ label }}</div>
          </div>
          <div class="w-25 text-right">{{ getValue(index) }}</div>
          <div class="w-25 text-right">{{ getPercent(index) }}%</div>
        </div>
      </div>
      <div class="w-50">
        <chart-line
          aspect-ratio="2"
          y-min="0"
          y-label="%"
          y-max="100"
          y-step="50"
          :data="percents"
          :periods="periods"
          :colors="colors"
        ></chart-line>
      </div>
    </div>
  </div>
</template>

<script>
import ChartLine from '../../../components/charts/ChartLine.vue';

export default {
  components: {
    ChartLine,
  },
  props: {
    title: {
      default: '',
    },
    labels: {
      default: () => [],
    },
    periods: {
      default: () => [],
    },
    data: {
      default: () => [],
    },
    colors: {
      default: () => [],
    },
  },
  computed: {
    sumForPeriods() {
      const sums = [];

      for (let i = 0; i < this.periods.length; i += 1) {
        let sum = 0;

        for (let j = 0; j < this.labels.length; j += 1) {
          sum += this.data?.[j]?.[i];
        }

        sums.push(sum);
      }

      return sums;
    },

    percents() {
      return this.data.map((labelData) => labelData.map((value, index) => (this.sumForPeriods[index] ? (value / this.sumForPeriods[index]) * 100 : 0)));
    },
  },
  methods: {
    getValue(index) {
      if (index >= this.data.length) {
        return 0;
      }

      if (!this.data[index].length) {
        return 0;
      }

      return this.data[index][this.data[index].length - 1].toLocaleString();
    },

    getPercent(index) {
      if (index >= this.percents.length) {
        return 0;
      }

      if (!this.percents[index].length) {
        return 0;
      }

      return this.percents[index][this.percents[index].length - 1].toFixed(2);
    },
  },
};
</script>

<style scoped>
.percents {
  font-size: 0.8em;
}
.mark {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
}
.font-weight-bold {
  font-weight: 500 !important;
}
</style>
