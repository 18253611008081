<template>
  <b-container fluid class="p-0">
    <b-form-group label="Стоимость:">
      <b-table
        class="datatable"
        :items="getAppGroupPrices"
        :fields="getPricesColumns"
        :busy="loading"
      ></b-table>
    </b-form-group>

    <b-form-group
      label="Минимальная стоимость:"
      :invalid-feedback="errors.minPrice"
      :state="states.minPrice"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.minPrice"
        :state="states.minPrice"
        :disabled="loading"
        @change="updateValue('minPrice', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Статус продаж для группы приложений:">
      <show-settings-table
        :fields="datatable.fields"
        :items="appGroups"
        :loading="loading"
      >
        <template #head(itemChecked)>
          <b-form-checkbox
            :checked="allAppGroupChecked"
            :disabled="appGroupsDisabled"
            @click.native.stop
            @change="toggleAppGroupAll()"
          ></b-form-checkbox>
        </template>

        <template #cell(itemChecked)="data">
          <b-form-checkbox
            :checked="isAppGroupChecked(data.item)"
            :disabled="isAppGroupClosingDisabled(data.item)"
            @click.native.stop
            @change="toggleAppGroup(data.item)"
          ></b-form-checkbox>
        </template>

        <template #cell(status)="data">
          <span :class="[ isAppGroupChecked(data.item) ? 'text-success' : 'text-danger' ]">
            {{ isAppGroupChecked(data.item) ? 'Открыты' : 'Закрыты' }}
          </span>
        </template>
      </show-settings-table>
    </b-form-group>

    <b-form-group label="Скидки:">
      <div v-if="!getAppGroupDiscounts.length">Нет настроенных скидок</div>
      <show-settings-table
        v-else
        :fields="datatable.fieldsDicounts"
        :items="getAppGroupDiscounts"
        :loading="loading"
      >
        <template #head(itemChecked)>
          <b-form-checkbox
            :checked="allDiscountChecked"
            @click.native.stop
            @change="toggleDiscountAll()"
          ></b-form-checkbox>
        </template>

        <template #cell(itemChecked)="data">
          <b-form-checkbox
            :checked="isDiscountChecked(data.item)"
            @click.native.stop
            @change="toggleDiscount(data.item)"
          ></b-form-checkbox>
        </template>

        <template #cell(appGroups)="data">
          {{ $array.convertToText(data.item.appGroups) }}
        </template>

        <template #cell(status)="data">
          <span :class="[ isDiscountChecked(data.item) ? 'text-success' : 'text-danger' ]">
            {{ isDiscountChecked(data.item) ? 'Да' : 'Нет' }}
          </span>
        </template>
      </show-settings-table>
    </b-form-group>
  </b-container>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import { mapState, mapActions } from 'vuex';

import fields from './_index.fields';
import fieldsDicounts from './_index.fields.discounts';

import ShowSettingsTable from './ShowSettingsTable.vue';

export default {
  components: {
    ShowSettingsTable,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      datatable: {
        fields,
        fieldsDicounts,
      },
      appGroupsDisabled: false,
      recentlyChangedAppGroupStatus: [],
    };
  },
  computed: {
    ...mapState('dictionaries/promotions', {
      promotions: (state) => state.items,
      promotionsLoading: (state) => state.loading,
    }),
    ...mapState('dictionaries/appGroups', {
      appGroups: (state) => state.items,
      appGroupsLoading: (state) => state.loading,
    }),
    ...mapState('dictionaries/colorSchemes', {
      colorSchemes: (state) => state.items,
      colorSchemesLoading: (state) => state.loading,
    }),

    getPricesColumns() {
      let colorSchemes = [];
      let columns = [];

      if (!this.item.appGroupPrices) {
        return columns;
      }

      for (const appGroupPrices of Object.values(this.item.appGroupPrices)) {
        colorSchemes = appGroupPrices.reduce((_colorSchemes, price) => {
          if (!_colorSchemes.includes(price.colorSchemeId)) {
            _colorSchemes.push(price.colorSchemeId);
          }

          return _colorSchemes;
        }, colorSchemes);
      }

      columns = [
        {
          id: 'id',
          label: '',
          class: 'app-group-id',
        },
        {
          key: 'name',
          label: 'Приложения',
          class: 'app-group-name',
        },
        ...colorSchemes.map((colorSchemeId) => ({
          key: `color_scheme_${colorSchemeId}`,
          label: this.colorSchemes.find((cs) => cs.id === colorSchemeId)?.name,
          class: 'color-scheme-price',
        })),
      ];

      return columns;
    },

    getAppGroupPrices() {
      const prices = [];

      if (!this.item.appGroupPrices) {
        return prices;
      }

      for (const [appGroupId, appGroupPrices] of Object.entries(this.item.appGroupPrices)) {
        const appGroup = this.appGroups.find((ag) => ag.id === +appGroupId);

        const price = {
          name: appGroup?.name,
        };

        for (const colorSchemePrice of appGroupPrices) {
          let promotionName = '';

          if (colorSchemePrice.promotionId) {
            promotionName = this.promotions.find((p) => p.id === colorSchemePrice.promotionId)?.name;
          }

          price[`color_scheme_${colorSchemePrice.colorSchemeId}`] = promotionName ? `${colorSchemePrice.price}₽ (${promotionName})` : `${colorSchemePrice.price}₽`;
        }

        prices.push(price);
      }

      return prices;
    },

    getAppGroupDiscounts() {
      const discounts = [];

      if (!this.item.discounts) {
        return discounts;
      }

      for (const [appGroupId, appGroupDiscounts] of Object.entries(this.item.appGroupDiscounts)) {
        const appGroup = this.appGroups.find((ag) => ag.id === +appGroupId);

        for (const discount of appGroupDiscounts) {
          const discountInList = discounts.find((d) => d.id === discount.id);

          if (!discountInList) {
            discounts.push({
              id: discount.id,
              name: discount.name,
              appGroups: [appGroup],
            });
          } else if (appGroup && !discountInList.appGroups.find((ag) => ag.id === appGroup.id)) {
            discountInList.appGroups.push(appGroup);
          }
        }
      }

      return discounts;
    },

    checkedAppGroups() {
      return this.item.appGroups.map((item) => item.id);
    },

    allAppGroupChecked() {
      if (!this.item.appGroups) {
        return false;
      }

      const appGroups = this.appGroups.map((item) => item.id);

      return appGroups.every((id) => this.checkedAppGroups.includes(id));
    },

    allDiscountChecked() {
      if (!this.item.discounts) {
        return false;
      }

      const discountsCount = this.getAppGroupDiscounts.length;
      const checkedDiscountsCount = this.item.discounts.length;

      if (discountsCount === checkedDiscountsCount) {
        return false;
      }

      return !checkedDiscountsCount;
    },
  },
  created() {
    this.fetchPromotions();
    this.fetchGroups();
    this.fetchColorSchemes();
  },
  methods: {
    ...mapActions('dictionaries/promotions', {
      fetchPromotions: 'fetchItems',
    }),
    ...mapActions('dictionaries/appGroups', {
      fetchGroups: 'fetchItems',
    }),
    ...mapActions('dictionaries/colorSchemes', {
      fetchColorSchemes: 'fetchItems',
    }),

    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    isAppGroupChecked(appGroup) {
      if (!this.item.appGroups) {
        return false;
      }

      return !!this.item.appGroups.find((ag) => ag.id === appGroup.id);
    },

    isAppGroupClosingDisabled(appGroup) {
      if (!this.item.ticketsCount) {
        return false;
      }

      if (this.recentlyChangedAppGroupStatus.includes(appGroup.id)) {
        return false;
      }

      if (this.isAppGroupChecked(appGroup)) {
        this.appGroupsDisabled = true;
        return true;
      }

      return false;
    },

    toggleAppGroupAll() {
      if (!this.allAppGroupChecked) {
        this.appGroups.filter((appGroup) => {
          if (!this.checkedAppGroups.includes(appGroup.id)) {
            this.toggleAppGroup(appGroup);
          }
        });
      } else {
        this.recentlyChangedAppGroupStatus = [];
        this.updateValue('appGroups', []);
      }
    },

    toggleAppGroup(appGroup) {
      this.recentlyChangedAppGroupStatus.push(appGroup.id);

      if (this.isAppGroupChecked(appGroup)) {
        this.updateValue('appGroups', this.item.appGroups.filter((ag) => ag.id !== appGroup.id));
      } else {
        this.updateValue('appGroups', [...this.item.appGroups, appGroup]);
      }
    },

    isDiscountChecked(discount) {
      if (!this.item.discounts) {
        return true;
      }

      return !this.item.discounts.find((d) => d.id === discount.id);
    },

    toggleDiscountAll() {
      if (this.allDiscountChecked) {
        this.getAppGroupDiscounts.filter((discount) => this.toggleDiscount(discount));
      } else {
        this.updateValue('discounts', []);
      }
    },

    toggleDiscount(discount) {
      if (this.isDiscountChecked(discount)) {
        this.updateValue('discounts', [...this.item.discounts, {
          id: discount.id,
          name: discount.name,
        }]);
      } else {
        this.updateValue('discounts', this.item.discounts.filter((d) => d.id !== discount.id));
      }
    },
  },
};
</script>

<style scoped>
.datatable >>> .app-group-name {
  padding-left: 0;
}
.datatable >>> .color-scheme-price {
  width: 20%;
  text-align: center;
}
</style>
