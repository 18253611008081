<template>
  <div class="color-schemes p-1 d-flex justify-content-center">
    <div
      v-for="price in usedColorSchemePrices"
      class="ml-3 mr-3"
      :key="price.colorSchemeId"
    >
      <span class="color-schemes__color mr-1" :style="{'background-color': price.color}"></span>
      <span>{{price.minPrice}}₽</span>
      <span v-if="price.minPrice !== price.maxPrice">-{{price.maxPrice}}₽</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    cinemaId: {
      default: null,
    },
    hallId: {
      default: null,
    },
    colorSchemes: {
      default: () => [],
    },
    scheme: {
      default: () => ({}),
    },
  },
  watch: {
    cinemaId: {
      handler() {
        this.fetchPrices();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('data/prices', {
      items: (state) => state.items,
      loading: (state) => state.loading,
    }),

    prices() {
      return this.items.filter((price) => !price.halls.length || price.halls.find((hall) => hall.id === this.hallId));
    },

    usedColorSchemeIds() {
      const colorSchemes = [];

      for (let i = 0; i < this.scheme.length; i += 1) {
        for (let j = 0; j < this.scheme[i].length; j += 1) {
          for (let k = 0; k < this.scheme[i][j].length; k += 1) {
            const { colorSchemeId } = this.scheme[i][j][k];
            if (colorSchemeId && !colorSchemes.includes(colorSchemeId)) {
              colorSchemes.push(colorSchemeId);
            }
          }
        }
      }

      return colorSchemes;
    },

    usedColorSchemePrices() {
      return this.usedColorSchemeIds.reduce((prices, colorSchemeId) => {
        const colorSchemePrices = this.prices
          .filter((price) => !price.colorSchemes.length || price.colorSchemes.find((cs) => cs.id === colorSchemeId))
          .map((price) => price.price)
          .sort();

        const colorScheme = this.colorSchemes.find((cs) => cs.id === colorSchemeId);

        prices.push({
          colorSchemeId,
          color: colorScheme?.color || '#FFF',
          minPrice: colorSchemePrices[0] || 0,
          maxPrice: colorSchemePrices[colorSchemePrices.length - 1] || 0,
        });

        return prices;
      }, []);
    },
  },
  methods: {
    fetchPrices() {
      if (!this.cinemaId) {
        return;
      }

      this.$store.dispatch('data/prices/setFilter', {
        field: 'cinemaId',
        value: this.cinemaId,
      });

      this.$store.dispatch('data/prices/fetchItems');
    },
  },
};
</script>

<style scoped>
.color-schemes {
  margin: -6px -6px -6px -6px;
  border-bottom: 1px solid #e5e5e5;
}
.color-schemes__color {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
}
</style>
