<template>
  <div class="table-container promocode-groups">
    <collection-provider
      collection="promocodeGroups"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(settings)>
            <dictionary-provider name="cinemas">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="data.filter.cinemaId"
                  :options="convertCinemas(items)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('cinemaId', $event)"
                >
                  <template #first>
                    <b-form-select-option :value="null"></b-form-select-option>
                  </template>
                </b-form-select>
              </template>
            </dictionary-provider>
          </template>

          <template #cell(name)="data">
            <div>{{data.item.name}}</div>
            <div
              v-if="data.item.description"
              class="text-muted mt-2"
            >{{data.item.description}}</div>
          </template>

          <template #cell(date)="data">
            <promocode-group-period :item="data.item"></promocode-group-period>
          </template>

          <template #cell(settings)="data">
            <promocode-group-settings :item="data.item"></promocode-group-settings>
          </template>

          <template #cell(createdAt)="data">
            {{ $datetime.formatDateTime(data.item.createdAt) }}
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.group.show"
      create-title="Создание группы промокодов"
      edit-title="Редактирование группы промокодов"
      collection="promocodeGroups"
      :id="modals.group.id"
      :init="{ numberCharset: 1, useMode: 1 }"
      @close="modals.group.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <promocode-group-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></promocode-group-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import DictionaryProvider from '../../providers/DictionaryProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import PromocodeGroupForm from './PromocodeGroupForm.vue';
import PromocodeGroupPeriod from './PromocodeGroupPeriod.vue';
import PromocodeGroupSettings from './PromocodeGroupSettings.vue';

export default {
  components: {
    CollectionProvider,
    DictionaryProvider,
    ModalModelForm,
    Datatable,
    PromocodeGroupForm,
    PromocodeGroupPeriod,
    PromocodeGroupSettings,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        group: {
          show: false,
          id: 0,
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreatePromocodeGroupForm() {
      this.modals.group.id = '';
      this.modals.group.show = true;
    },

    openEditPromocodeGroupForm(id) {
      this.modals.group.id = id;
      this.modals.group.show = true;
    },

    async exportPromocodeGroup(id, item) {
      const cinemas = item.cinemas.map(({ city, name }) => `${name}, ${city.name}`);
      const date = this.$datetime.format(item.createdAt, 'D MMMM');

      await this.$store.dispatch('data/promocodeGroups/export', {
        id,
        filename: `Промокоды ${date} (${cinemas.length ? cinemas : 'Все кинотеатры'}).csv`,
      });
    },

    async removePromocodeGroup(id) {
      if (await confirmDialog('Удалить?')) {
        const [err] = await this.$refs.collection.removeItem(id);

        if (err) {
          if (err.status && err.status === 422) {
            this.$notify({
              type: 'error',
              text: 'Невозможно удалить группу',
            });
          }
        }
      }
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        value: cinema.id,
        text: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },
  },
};
</script>
