<template>
  <b-modal
    centered
    ok-variant="success"
    ok-title="Добавить"
    cancel-title="Закрыть"
    :visible="true"
    @ok="ok"
    @hidden="$emit('hide')"
  >
    <template #modal-title><h4>Добавление новых рядов</h4></template>
    <b-container fluid class="p-0">
      <b-row>
        <b-col md="6">
          <b-form-group label="Количество рядов:">
            <b-form-select v-model="rowsCount" autofocus size="sm">
              <option v-for="i in maxRowsCount" :value="i" :key="i">{{ i }}</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Количество мест в ряду:">
            <b-form-select size="sm" v-model="seatsCount">
              <option v-for="i in maxSeatsCount" :value="i" :key="i">{{ i }}</option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Тип сидений:">
            <dictionary-provider name="seatTypes">
              <template #default="{ items }">
                <b-form-select
                  v-model="type"
                  size="sm"
                  :options="$array.convertToOptions(items, false, 'code')"
                >
                </b-form-select>
              </template>
            </dictionary-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';

import {
  DEFAULT_ROWS_COUNT,
  DEFAULT_SEATS_COUNT,
  DEFAULT_SEAT_TYPE,
  MAX_ROWS_COUNT,
  MAX_SEATS_COUNT,
} from '../../constants';

export default {
  components: {
    DictionaryProvider,
  },
  data() {
    return {
      rowsCount: DEFAULT_ROWS_COUNT,
      seatsCount: DEFAULT_SEATS_COUNT,
      type: DEFAULT_SEAT_TYPE,
      maxRowsCount: MAX_ROWS_COUNT,
      maxSeatsCount: MAX_SEATS_COUNT,
    };
  },
  methods: {
    ok() {
      this.$emit('add-rows', {
        rowsCount: Math.min(this.rowsCount, this.maxRowsCount),
        seatsCount: Math.min(this.seatsCount, this.maxSeatsCount),
        type: this.type,
      });

      this.rowsCount = DEFAULT_ROWS_COUNT;
      this.seatsCount = DEFAULT_SEATS_COUNT;
      this.type = DEFAULT_SEAT_TYPE;
    },
  },
};
</script>
