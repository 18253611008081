export default [
  {
    key: 'name',
    type: 'text',
    label: 'Наименование',
    filterable: false,
    sortable: false,
  },
  {
    key: 'period',
    type: 'text',
    label: 'Период действия',
    sortable: false,
    filterable: false,
  },
  {
    key: 'appGroups',
    type: 'select',
    label: 'Группы приложений',
    sortable: false,
    filterable: false,
  },
  {
    key: 'cinemas',
    type: 'select',
    label: 'Кинотеатры',
    sortable: false,
    filterable: false,
  },
  {
    key: 'active',
    type: 'select',
    label: 'Активна',
    sortable: false,
    filterable: false,
  },
];
