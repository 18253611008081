import dictionaryServiceFactory from '../../services/dictionaryServiceFactory';

export default (collection, {
  state, actions, mutations, getters,
} = {
  state: {}, actions: {}, mutations: {}, getters: {},
}) => {
  const dictionaryService = dictionaryServiceFactory(collection);

  return {
    namespaced: true,

    state: {
      loading: false,
      items: [],
      total: 0,
      ...state,
    },

    actions: {
      async fetchItems({ commit }, { filter } = {}) {
        commit('setLoading', true);

        const [, data] = await dictionaryService.fetchDictionary({ filter });

        if (data) {
          const { items, total } = data;

          commit('setItems', { items });
          commit('setTotal', { total });
        }

        commit('setLoading', false);
      },

      ...actions,
    },

    mutations: {
      setLoading(state, loading) {
        state.loading = loading;
      },

      setItems(state, { items }) {
        state.items = items;
      },

      setTotal(state, { total }) {
        state.total = total;
      },

      ...mutations,
    },

    getters: {
      ...getters,
    },
  };
};
