import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    releasesCount: {},
  },
  mutations: {
    resetCount(state, { releaseId }) {
      Vue.set(state.releasesCount, releaseId, 1);
    },
    setCount(state, { releaseId, count }) {
      Vue.set(state.releasesCount, releaseId, count);
    },
    resetState(state) {
      state.releasesCount = {};
    },
  },
};
