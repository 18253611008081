<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Наименование:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        autofocus
        type="text"
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Описание:"
      :invalid-feedback="errors.description"
      :state="states.description"
    >
      <b-form-input
        size="sm"
        :value="item.description"
        :state="states.description"
        :disabled="loading"
        @change="updateValue('description', $event)"
      ></b-form-input>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Режим использования сертификатов</div>
    </div>

    <b-form-group
      label=""
      :invalid-feedback="errors.useMode"
      :state="states.useMode"
    >
      <dictionary-provider name="certificateUseModes">
        <template #default="{ items }">
          <b-form-radio-group
            name="useMode"
            stacked
            :state="states.useMode"
            :checked="item.useMode"
            :disabled="loading"
            @change="updateValue('useMode', $event)"
          >
            <b-form-radio
              v-for="item in items"
              :key="item.ud"
              :value="item.id"
            >{{ item.name }}</b-form-radio>
          </b-form-radio-group>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      class="mb-0"
      :invalid-feedback="errors.allowProducts"
      :state="states.allowProducts"
    >
      <b-form-checkbox
        :checked="item.allowProducts"
        :state="states.allowProducts"
        :disabled="true || loading || item.useMode === 1"
        @change="updateValue('allowProducts', !item.allowProducts)"
      >
        Разрешено использовать для оплаты товаров
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      :invalid-feedback="errors.allowDiscounts"
      :state="states.allowDiscounts"
    >
      <b-form-checkbox
        :checked="item.allowDiscounts"
        :state="states.allowDiscounts"
        :disabled="loading"
        @change="updateValue('allowDiscounts', !item.allowDiscounts)"
      >
        Разрешено использовать скидки
      </b-form-checkbox>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Характеристики сертификатов</div>
    </div>

    <b-form-group
      label="Номинал:"
      :invalid-feedback="errors.nominal"
      :state="states.nominal"
    >
      <b-form-input
        type="number"
        size="sm"
        :value="item.nominal"
        :state="states.nominal"
        :disabled="loading"
        @change="updateValue('nominal', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Продажная стоимость:"
      :invalid-feedback="errors.price"
      :state="states.price"
    >
      <b-form-input
        type="number"
        size="sm"
        :value="item.price"
        :state="states.price"
        :disabled="loading"
        @change="updateValue('price', $event)"
      ></b-form-input>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Покупатель</div>
    </div>

    <b-form-group
      label=""
      :invalid-feedback="errors.organizationId"
      :state="states.organizationId"
    >
      <dictionary-provider name="organizations">
        <template #default="{ items }">
          <b-form-select
            size="sm"
            :value="item.organizationId"
            :options="$array.convertToOptions(items)"
            :state="states.organizationId"
            :disabled="loading"
            @change="updateValue('organizationId', $event)"
          ></b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Место действия сертификатов</div>
    </div>

    <b-form-group
      label="Кинотеатры:"
      :invalid-feedback="errors.cinemas"
      :state="states.cinemas"
    >
      <dictionary-provider name="cinemas">
        <template #default="{ items, loading: dictionaryLoading }">
          <toggle-list
            placeholder="Все"
            :options="convertCinemas($array.union(items, item.cinemas))"
            :value="item.cinemas"
            :disabled="loading || dictionaryLoading"
            @change="updateValue('cinemas', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Категории залов:"
      :invalid-feedback="errors.hallCategories"
      :state="states.hallCategories"
    >
      <dictionary-provider name="hallCategories">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.hallCategories"
            :disabled="loading"
            @change="updateValue('hallCategories', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Группы приложений:"
      :invalid-feedback="errors.appGroups"
      :state="states.appGroups"
    >
      <dictionary-provider name="appGroups">
        <template #default="{ items: appGroups }">
          <toggle-list
            placeholder="Все"
            :options="appGroups"
            :value="item.appGroups"
            :disabled="loading"
            @change="updateValue('appGroups', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Фильтры фильмов, для которых действуют сертификаты</div>
    </div>

    <b-form-group
      label="Возрастное ограничение фильма:"
      :invalid-feedback="errors.ageLimit"
      :state="states.ageLimit"
    >
      <dictionary-provider name="ageLimits">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.ageLimits"
            :disabled="loading"
            @change="updateValue('ageLimits', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Форматы фильмов:"
      :invalid-feedback="errors.formats"
      :state="states.formats"
    >
      <dictionary-provider name="formats2">
        <template #default="{ items }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="item.formats"
            :disabled="loading"
            @change="updateValue('formats', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Релизы:"
      :invalid-feedback="errors.releases"
      :state="states.releases"
    >
      <dictionary-provider
        name="showsReleases"
        :filter="{
          formatIds: $array.getValues(item.formats, 'id'),
        }"
      >
        <template #default="{ items, loading: dictionaryLoading }">
          <toggle-list
            placeholder="Все"
            :options="convertReleases(items)"
            :value="item.releases"
            :searchable="true"
            :disabled="loading || dictionaryLoading"
            @change="updateValue('releases', $event)"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Срок действия сертификатов</div>
    </div>

    <b-form-group
      label=""
      class="custom-controls"
      :invalid-feedback="errors.invalidateMode"
      :state="states.invalidateMode"
    >
      <b-form-radio-group
        name="invalidateMode"
        stacked
        :state="states.invalidateMode"
        :checked="item.invalidateMode"
        :disabled="loading"
        @change="updateValue('invalidateMode', $event)"
      >
        <div class="mb-2">
          <b-form-radio
            value="1"
          >Бессрочные</b-form-radio>
        </div>

        <div class="d-flex align-items-center mb-2">
          <b-form-radio
            value="2"
            class="mr-1"
          >Действуют до</b-form-radio>
          <date
            size="sm"
            :value="item.invalidateDate"
            :state="states.invalidateDate"
            :disabled="loading"
            @selected="updateValue('invalidateDate', $event)"
            @click.stop.prevent="pass()"
          ></date>
        </div>

        <div class="d-flex align-items-center">
          <b-form-radio
            value="3"
            class="mr-1"
          >Действуют</b-form-radio>
          <b-form-input
            type="number"
            size="sm"
            class="maw-width-80px"
            :value="item.invalidateDays"
            :state="states.invalidateDays"
            :disabled="loading"
            @change="updateValue('invalidateDays', $event)"
          ></b-form-input>
          <span class="ml-1">дней после активации</span>
        </div>
      </b-form-radio-group>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Генерация сертификатов</div>
    </div>

    <b-form-group
      label="Количество сертификатов:"
      :invalid-feedback="errors.certificatesCount"
      :state="states.certificatesCount"
    >
      <b-form-input
        type="number"
        size="sm"
        :value="item.certificatesCount"
        :state="states.certificatesCount"
        :disabled="loading || !!item.id"
        @change="updateValue('certificatesCount', $event)"
      ></b-form-input>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group
          label="Префикс:"
          :invalid-feedback="errors.prefix"
          :state="states.prefix"
        >
          <b-form-input
            type="text"
            size="sm"
            ref="prefix"
            :value="item.prefix"
            :state="states.prefix"
            :disabled="loading || !!item.id"
            @keyup.native="updatePrefix($event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Длина случайной части:"
          :invalid-feedback="errors.numberLength"
          :state="states.numberLength"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.numberLength"
            :state="states.numberLength"
            :disabled="loading || !!item.id"
            @change="updateValue('numberLength', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Набор символов:"
          :invalid-feedback="errors.numberCharset"
          :state="states.numberCharset"
        >
          <b-form-select
            size="sm"
            :value="item.numberCharset"
            :disabled="loading || !!item.id"
            @change="updateValue('numberCharset', $event)"
          >
            <b-form-select-option value="1">Только буквы</b-form-select-option>
            <b-form-select-option value="2">Только цифры</b-form-select-option>
            <b-form-select-option value="3">Буквы и цифры</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      :invalid-feedback="errors.activated"
      :state="states.activated"
    >
      <b-form-checkbox
        :checked="item.activated"
        :state="states.activated"
        :disabled="loading || !!item.id"
        @change="updateValue('activated', !item.activated)"
      >
        Активировать сертификаты после создания
      </b-form-checkbox>
    </b-form-group>

  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';
import Date from '../../components/fields/Date.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
    Date,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    convertReleases(releases) {
      if (!releases) {
        return [];
      }

      return releases.map((release) => ({
        id: release.id,
        name: release?.format?.name ? `${release?.movie?.name}, ${release?.format?.name}` : release?.movie?.name,
      }));
    },

    updatePrefix(e) {
      const value = e.target.value.toUpperCase();

      if (value && !/^[0-9A-Z]+$/.test(value)) {
        this.$refs.prefix.$refs.input.value = this.item.prefix || '';
        return;
      }

      this.updateValue('prefix', value);
    },
  },
};
</script>

<style scoped>
.maw-width-80px {
  max-width: 80px;
}
</style>
