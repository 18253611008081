<template>
  <b-table-simple
    class="datatable"
    :striped="true"
  >
    <b-tbody v-if="!items.length">
      <b-tr class="b-table-empty-row">
        <b-td :colspan="fields.length">
          <div class="text-center my-2">{{ loading ? 'Загрузка...' : 'Нет данных' }}</div>
        </b-td>
      </b-tr>
    </b-tbody>
    <b-tbody v-else>
      <b-tr
        v-for="(item, index) in items"
        :key="index"
      >
        <b-td
          v-for="field in fields"
          :key="field.id"
          :class="[
            'column',
            `column-${field.key}`
          ]"
          :style="field.thStyle"
        >
          {{ fields.thStyle }}
          <div v-if="$slots[`cell-${field.key}`] || $scopedSlots[`cell-${field.key}`]">
            <slot
              :name="`cell-${field.key}`"
              :item="item"
              :value="item[field.key]"
            ></slot>
          </div>
          <div v-else>{{ item[field.key] }}</div>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
export default {
  props: {
    items: {
      default: () => [],
    },
    loading: {
      default: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'release_name',
          label: 'Название релиза',
        },
        {
          key: 'tickets_price',
          label: 'Вал, руб',
          thStyle: {
            width: '160px',
          },
        },
        {
          key: 'average_tickets_price',
          label: 'Наработка, руб',
          thStyle: {
            width: '180px',
          },
        },
        {
          key: 'occupation',
          label: 'Заполняемость, %',
          thStyle: {
            width: '260px',
          },
        },
        {
          key: 'tickets_price_percent',
          label: 'Доля, %',
          thStyle: {
            width: '140px',
          },
        },
        {
          key: 'shows_count',
          label: 'Сеансы',
          thStyle: {
            width: '160px',
          },
        },
        {
          key: 'first_show_date', // имитируем сортировку по неделям
          label: 'Неделя',
          thStyle: {
            width: '140px',
          },
        },
        {
          key: 'average_tickets_count',
          label: 'Наработка, билеты',
          thStyle: {
            width: '200px',
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.datatable {
  margin: 0;

  .column {
    color: #6C757D;
  }
}
</style>
