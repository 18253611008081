import _factory from './_factory';

export default {
  ..._factory('favoriteReleases2', {
    state: {
      page: null,
      limit: null,
      selectedItems: [],
    },

    actions: {
      initReleases({ commit, state }) {
        commit('resetSelectedItems');

        for (const { cinemaId, releaseId } of state.items) {
          commit('addRelease', { cinemaId, releaseId });
        }
      },
    },

    mutations: {
      addRelease(state, { cinemaId, releaseId }) {
        state.selectedItems.push({
          release_id: releaseId,
          cinema_id: cinemaId,
        });
      },

      removeRelease(state, { cinemaId, releaseId }) {
        const copy = [...state.selectedItems];
        const index = copy.findIndex(({ release_id, cinema_id }) => cinema_id === cinemaId && release_id === releaseId);

        if (index === -1) {
          return;
        }
        copy.splice(index, 1);

        state.selectedItems = [...copy];
      },

      resetSelectedItems(state) {
        state.selectedItems = [];
      },
    },

    getters: {
      getSelectedItemsByReleaseIdCount: (state) => (releaseId) => state.selectedItems
        .filter(({ release_id }) => release_id === releaseId).length,

      getSelectedItemsByCinemaIdAndReleaseId: (state) => (cinemaId, releaseId) => state.selectedItems
        .find(({ cinema_id, release_id }) => cinema_id === cinemaId && release_id === releaseId),
    },
  }),
};
