<template>
  <div>
    <div class="autocomplete-input" :class="{'loading': loading}">
      <input
        type="text"
        autocomplete="off"
        :class="inputClasses"
        :name="name"
        :placeholder="placeholder"
        :value="query"
        :disabled="disabled"
        @input="search($event.target.value)"
        @change.stop.prevent=""
        @keydown.up="up"
        @keydown.down="down"
        @keydown.enter="selectItem"
        @keydown.esc.stop="close"
        @blur="close"
        @focus="clear"
        ref="input"
      >
      <i class="spinner"></i>
    </div>
    <div class="dropdown autocomplete-dropdown" v-if="showDropdown" :class="{'open':showDropdown}" ref="dropdown">
      <div class="dropdown-menu">
        <div class="dropdown-item disabled" v-if="!items || !items.length"><a>{{noDataText}}</a></div>
        <div
          class="dropdown-item"
          v-for="(item, index) in items"
          :key="index"
          :class="{'active': isActive(index)}"
          @mousedown.prevent="selectItem"
          @mousemove="setActive(index)"
        >
          <span v-html="item.text"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: 'form-control',
    },
    source: {
      type: Function,
      default() {
        return [];
      },
    },
    limit: {
      type: Number,
      default: 20,
    },
    noDataText: {
      type: String,
      default: 'Нет данных...',
    },
    disabled: {
      default: false,
    },
    state: {
      default: null,
    },
  },
  data() {
    return {
      query: '',
      loading: false,
      showDropdown: false,
      currentIndex: 0,
      items: [],
    };
  },
  computed: {
    inputClasses() {
      return [
        this.inputClass,
        this.state === false ? 'is-invalid' : '',
        this.state === true ? 'valid' : '',
      ];
    },
  },
  created() {
    this.query = this.value;
    this.search = debounce(this.search, 200, {
      trailing: true,
      leading: false,
    });
  },
  watch: {
    value(val) {
      this.query = val;
    },
    showDropdown(val) {
      if (val) {
        this.$nextTick(() => {
          this.setPosition();
        });
      }
    },
  },
  methods: {
    clear() {
      this.query = '';
    },

    search(value) {
      this.query = value;
      if (this.query) {
        this.loading = true;
        this.source(this.query, this.process);
      } else {
        this.loading = false;
        this.items = [];
        this.showDropdown = false;
      }
    },

    process(data) {
      this.loading = false;

      if (this.$refs.input === document.activeElement) {
        this.items = data.slice(0, this.limit || 20);
        this.showDropdown = true;
      }
    },

    close() {
      this.items = [];
      this.query = this.value;
      this.showDropdown = false;
      this.$refs.input.blur();
    },

    setActive(index) {
      this.currentIndex = index;
    },

    isActive(index) {
      return this.currentIndex === index;
    },

    selectItem(e) {
      e.preventDefault();

      const item = this.items[this.currentIndex];

      if (!item) {
        return;
      }

      this.$emit('select', item);

      this.close();

      this.query = item.text;
    },

    up(e) {
      e.preventDefault();
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
      }
    },

    down(e) {
      e.preventDefault();
      if (this.currentIndex < this.items.length - 1) {
        this.currentIndex += 1;
      }
    },

    setPosition() {
      const el = this.$refs.dropdown;

      const list = el.children[0];
      const inputPosition = this.$refs.input.getBoundingClientRect();

      list.style.minWidth = `${inputPosition.width}px`;
      list.style.maxWidth = `${Math.min(document.documentElement.clientWidth, window.innerWidth || 0, 600)}px`;
    },
  },
};
</script>

<style scoped>
.autocomplete-input {
  position: relative;
}
.autocomplete-input input[type=text] {
  position: relative;
}
.autocomplete-input.loading .spinner {
  opacity: 1;
}
.autocomplete-input .spinner {
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 6px;
  display: inline-block;
  height: 22px;
  width: 22px;
  animation: rotate 0.8s infinite linear;
  border: 4px solid #ccc;
  border-right-color: transparent;
  border-radius: 50%;
}
@keyframes rotate {
  0%    { transform: rotate(0deg); }
  100%  { transform: rotate(360deg); }
}
.autocomplete-dropdown {
  position: absolute;
  z-index: 9999;
}
.dropdown-item {
  cursor: pointer;
}
.open .dropdown-menu {
  display: block;
}
</style>
