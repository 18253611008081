<template>
  <div class="table-container">
    <collection-provider
      collection="distributorLegalEntities"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :item-actions="datatable.itemActions"
          :panel-actions="datatable.panelActions"
          @item-action="performItemAction($event)"
          @panel-action="performPanelAction($event)"
        >
          <template #cell(distributorName)="data">
            {{ data.item.distributor ? data.item.distributor.name : ''}}
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.legalEntity.show"
      create-title="Создание юридического лица"
      edit-title="Редактирование юридического лица"
      collection="distributorLegalEntities"
      :id="modals.legalEntity.id"
      @close="modals.legalEntity.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <distributor-legal-entities-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></distributor-legal-entities-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import DistributorLegalEntitiesForm from './DistributorLegalEntitiesForm.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CollectionProvider,
    Datatable,
    DistributorLegalEntitiesForm,
    ModalModelForm,
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
        panelActions,
      },
      modals: {
        legalEntity: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    openCreateDistributorLegalEntitiesForm() {
      this.modals.legalEntity.id = null;
      this.modals.legalEntity.show = true;
    },

    openEditDistributorLegalEntitiesForm(id) {
      this.modals.legalEntity.id = id;
      this.modals.legalEntity.show = true;
    },

    async removeDistributorLegalEntitiesForm(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>
.distributor-legal-entities-form {
  padding: 0;
  overflow-y: scroll;
  overflow-y: auto;
}
</style>
