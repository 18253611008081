<template>
  <div class="discount-period">
    <div v-if="!item.dateStart && !item.dateEnd">Без срока действия</div>
    <div v-else>
      <span v-if="item.dateStart" class="mr-1">c {{$datetime.formatDateTime(item.dateStart)}}</span>
      <span v-if="item.dateEnd">по {{$datetime.formatDateTime(item.dateEnd)}}</span>
    </div>
    <div v-if="item.showPeriod">Периодичность показа {{getDeclOfNum(item.showPeriod, ['каждый', 'каждые', 'каждые'])}} {{item.showPeriod}} {{getDeclOfNum(item.showPeriod, ['час', 'часа', 'часов'])}}</div>
    <div v-else>Периодичность показа без ограничений</div>
  </div>
</template>

<script>
import declOfNum from '../../utils/declOfNum';

export default {
  props: {
    item: {
      default: () => ({}),
    },
  },
  methods: {
    getDeclOfNum(n, titles) {
      return declOfNum(n, titles);
    },
  },
};
</script>

<style scoped>
.discount-period > div {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
