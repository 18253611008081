/* eslint-disable guard-for-in */
/* eslint-disable no-continue */
import FileSaver from 'file-saver';
import AnalyticsService from '../services/Analytics';

const TABLE_CUSTOMERS = 'customers';
const COLUMN_COUNT = 'count(id)';
const COLUMN_CINEMA_ID = 'cinema_id';
const COLUMN_CREATED_AT = 'date(created_at)';

export default {
  props: {
    cinemaIds: {
      default: () => [],
    },
    periods: {
      default: () => [],
    },
  },
  render() {
    return this.$scopedSlots.default({
      data: {
        cinemasData: this.cinemasData,
        mappedData: this.mappedData,
        loading: this.loading,
        exportData: this.exportData,
      },
    });
  },
  data() {
    return {
      analyticsData: {},
      loading: false,
    };
  },
  computed: {
    watchedValues() {
      return [...this.cinemaIds, ...this.periods.map(({ dateStart, dateEnd }) => `${dateStart}_${dateEnd}`)].join();
    },

    cinemasData() {
      const result = {};

      for (const cinemaId of this.cinemaIds) {
        result[cinemaId] = this.periods.map((period) => {
          const { dateStart, dateEnd } = period;
          const key = this.getAnalyticsKey('customers', dateStart, dateEnd);
          const data = this.analyticsData[key] || [];
          const row = data.find((_row) => +_row[COLUMN_CINEMA_ID] === cinemaId);

          return {
            ...period,
            count: row ? row[COLUMN_COUNT] : 0,
          };
        });
      }

      return result;
    },
  },
  watch: {
    watchedValues: {
      handler() {
        this.fetchAnalyticsData();
      },
      deep: true,
    },
  },
  methods: {
    getAnalyticsKey(key, dateStart, dateEnd) {
      return `${key}_${dateStart}_${dateEnd}`;
    },

    async fetchAnalyticsData() {
      this.analyticsData = {};

      if (!this.cinemaIds.length) {
        return;
      }

      const queries = [];

      for (let i = 0; i < this.periods.length; i += 1) {
        const { dateStart, dateEnd } = this.periods[i];

        queries.push({
          id: this.getAnalyticsKey('customers', dateStart, dateEnd),
          table: TABLE_CUSTOMERS,
          select: [COLUMN_CINEMA_ID, COLUMN_COUNT],
          where: {
            [COLUMN_CINEMA_ID]: this.cinemaIds,
            [COLUMN_CREATED_AT]: {
              gte: dateStart,
              lte: dateEnd,
            },
          },
          group_by: [COLUMN_CINEMA_ID],
        });
      }

      if (!queries.length) {
        return;
      }

      this.loading = true;

      const [, result] = await AnalyticsService.fetch(queries);

      this.loading = false;
      this.analyticsData = result;
    },

    async exportData(cinemaIds, period, filenamePrefix = '') {
      if (!cinemaIds.length || !period) {
        return;
      }

      const { dateStart, dateEnd } = period;

      this.loading = true;

      const queries = [{
        id: 'export',
        table: TABLE_CUSTOMERS,
        select: ['*'],
        where: {
          [COLUMN_CINEMA_ID]: this.cinemaIds,
          [COLUMN_CREATED_AT]: {
            gte: dateStart,
            lte: dateEnd,
          },
        },
      }];

      const [err, data] = await AnalyticsService.exportCustomers(queries);

      this.loading = false;

      if (err && !data) {
        return;
      }

      const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });

      FileSaver.saveAs(blob, `клиенты${filenamePrefix ? `_${filenamePrefix}` : ''}_${dateStart}_${dateEnd}.csv`);
    },
  },
};
