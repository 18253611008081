const USE_MODE_ONE_TICKET = 1;
const USE_MODE_ONE_ORDER = 2;

export default {
  namespaced: true,

  state: {
    loading: false,
    items: [
      {
        id: USE_MODE_ONE_TICKET,
        name: 'Применяется к одному билету в заказе',
      },
      {
        id: USE_MODE_ONE_ORDER,
        name: 'Применяется ко всем билетам в заказе',
      },
    ],
    total: 0,
  },

  actions: {
    async fetchItems() {
      //
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setItems(state, { items }) {
      state.items = items;
    },

    setTotal(state, { total }) {
      state.total = total;
    },
  },
};
