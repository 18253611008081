<template>
  <analytics-provider
    table="shows_occupation"
    page="1"
    limit="5"
    sort-by="occupation"
    :sort-desc="true"
    :params="{
      cinema_id: cinemaId,
      date_start: dateStart,
      date_end: dateEnd,
    }"
    :disabled="!dateStart || !dateEnd"
  >
    <template #default="{ items: topItems, loading: topItemsLoading }">
      <analytics-provider
        table="shows_occupation"
        page="1"
        limit="5"
        sort-by="occupation"
        :sort-desc="false"
        :params="{
          cinema_id: cinemaId,
          date_start: dateStart,
          date_end: dateEnd,
        }"
        :disabled="!dateStart || !dateEnd"
      >
        <template #default="{ items: bottomItems, loading: bottomItemsLoading }">
          <b-table
            class="datatable border"
            :fields="fields"
            :items="$array.union(topItems, $array.reverse(bottomItems), 'movie_id')"
            :striped="true"
            :hover="false"
            :no-local-sorting="true"
            :busy="topItemsLoading || bottomItemsLoading"
            :show-empty="true"
            :empty-text="(topItemsLoading || bottomItemsLoading) ? 'Загрузка...' : 'Нет данных'"
          >
            <template #cell(release_name)="data">
              {{ data.item.movie_name }} {{ data.item.format_name }}
            </template>

            <template #cell(occupation)="data">
              <span
                :class="[data.item.occupation < 5 || data.item.occupation > 70 ? 'text-danger' : '']"
              >{{ data.item.occupation }}</span>
            </template>

            <template #cell(average_occupation)="data">
              <span
                :class="[data.item.average_occupation < 5 || data.item.average_occupation > 70 ? 'text-danger' : '']"
              >{{ data.item.average_occupation }}</span>
            </template>

            <template #cell(date)="data">
              {{ $datetime.formatDate(data.item.date) }}
            </template>

            <template #cell(time)="data">
              {{ $datetime.convertTime(data.item.time) }}
            </template>
          </b-table>
        </template>
      </analytics-provider>
    </template>
  </analytics-provider>
</template>

<script>

import AnalyticsProvider from '../../../providers/AnalyticsProvider';

export default {
  components: {
    AnalyticsProvider,
  },
  props: {
    cinemaId: {
      default: '',
    },
    dateStart: {
      default: '',
    },
    dateEnd: {
      default: '',
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'release_name',
          label: 'Название релиза',
          sortable: false,
        },
        {
          key: 'time',
          label: 'Время сеанса',
          sortable: false,
          thStyle: {
            width: '160px',
          },
        },
        {
          key: 'occupation',
          label: 'Заполняемость, %',
          sortable: false,
          thStyle: {
            width: '180px',
          },
        },
        {
          key: 'average_occupation',
          label: 'Средняя заполняемость, %',
          sortable: false,
          thStyle: {
            width: '260px',
          },
        },
        {
          key: 'hall_name',
          label: 'Зал',
          sortable: false,
          thStyle: {
            width: '140px',
          },
        },
        {
          key: 'tickets_count',
          label: 'Кол-во билетов',
          sortable: false,
          thStyle: {
            width: '160px',
          },
        },
        {
          key: 'date',
          label: 'Дата',
          sortable: false,
          thStyle: {
            width: '140px',
          },
        },
        {
          key: 'average_tickets_count',
          label: 'Наработка билетов',
          sortable: false,
          thStyle: {
            width: '200px',
          },
        },
      ],
    };
  },
};
</script>

<style scoped>
.pagination >>> .page-link {
  border-width: 0;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  color: #000;
}
.pagination >>> .active .page-link {
  color: #FFF;
}
</style>
