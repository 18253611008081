<template>
  <div
    class="seat border rounded"
    :class="classes"
    :style="styles"
  >
    <div class="seat__places d-flex align-content-center align-items-center" v-if="isNumeric && places.length && !isBroken">
      <span v-for="place in places" :key="place" class="seat__number">{{place}}</span>
    </div>
    <div v-if="isBroken" class="seat__places d-flex align-content-center align-items-center">
      <icon icon="fa-times"></icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      default: () => ({
        hscale: 'auto',
        vscale: 'auto',
        isNumeric: 1,
        scheme: '',
      }),
    },
    places: {
      default: () => ([]),
    },
    backgroundColor: {
      default: '#ffff00',
    },
    fontColor: {
      default: '#000',
    },
  },
  computed: {
    isVertical() {
      return !this.isHorizontal;
    },

    isHorizontal() {
      return this.hscale === 'auto' && this.vscale !== 'auto';
    },

    svg() {
      return this.info?.scheme;
    },

    isNumeric() {
      return !!this.info?.isNumeric;
    },

    isBroken() {
      return !!this.info?.code.includes('broken');
    },

    classes() {
      return [
        this.isVertical ? 'seat-vertical' : '',
        this.isHorizontal ? 'seat-horizontal' : '',
      ];
    },

    hscale() {
      return this.info?.hscale || 'auto';
    },

    vscale() {
      return this.info?.vscale || 'auto';
    },

    styles() {
      const styles = {};

      styles.background = this.backgroundColor;
      styles.color = this.fontColor;

      return styles;
    },
  },
};
</script>
<style scoped>
.seat {
  position: relative;
}

.seat[data-type='sofa'], .seat[data-type='broken_sofa'] {
  width: 2em;
}

.seat__scheme {
  color: #bbb;
}

.seat__places {
  font-size: 0.5em;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.seat__number {
  line-height: 1;
}

.seat[data-type='mobile'] {
  border-radius: 50% !important;
}
</style>
