<template>
  <div>
    <b-dropdown
      variant="outline-info"
      no-caret
      class="cinemas-select"
      id="cinemas-select"
      ref="dropdown"
      v-click-outside="onClickOutside"
      :size="size"
      :disabled="disabled"
      @hide="hide($event)"
      @hidden="search = '', visible = false"
      @shown="visible = true"
    >
      <template v-slot:button-content>
        <span class="cinemas-select__name">{{title}}</span>
      </template>

      <b-dropdown-form>
        <b-input
          placeholder="Поиск"
          v-model="search"
          :disabled="disabled"
          :size="size"
        ></b-input>
        <b-checkbox
          class="ml-1 mt-2"
          :checked="isAllChecked"
          :disabled="disabled"
          @change="toggleAll()"
        >Все кинотеатры</b-checkbox>
      </b-dropdown-form>

      <b-dropdown-divider></b-dropdown-divider>

      <div class="cinemas-select__list">
        <div
          v-for="cinema in filteredCinemas"
          class="px-3 mb-2"
          :key="cinema.id"
        >
          <b-checkbox
            :checked="localValue.includes(cinema.id)"
            :disabled="disabled"
            @change="toggle(cinema.id)"
          >
            <div>{{cinema.name}}</div>
            <div class="cinemas-select__city">{{ cinema.city && cinema.city.name }}</div>
          </b-checkbox>
        </div>
        <div
          v-if="!filteredCinemas.length"
          class="px-2 py-1"
          :disabled="true"
        >Не найдено</div>
      </div>
    </b-dropdown>
    <b-tooltip
      v-if="!visible && !disabled && localValue.length !== cinemas.length"
      custom-class="cinemas-select__tooltip"
      placement="bottom"
      target="cinemas-select"
    >
      <div
        v-for="cinemaId in localValue"
        :key="cinemaId"
      >{{ getCinemaFullName(cinemaId) }}</div>
    </b-tooltip>
  </div>
</template>

<script>
import { getNoun } from '../../../utils/string';

export default {
  components: {
  },
  props: {
    cinemas: {
      default: () => [],
    },
    value: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
    size: {
      default: 'sm',
    },
  },
  data() {
    return {
      search: '',
      clickedOutside: false,
      visible: false,
      localValue: [],
    };
  },
  computed: {
    title() {
      return this.localValue.length === this.cinemas.length ? 'Все кинотеатры' : `${this.localValue.length} ${getNoun(this.localValue.length, 'кинотеатр', 'кинотеатра', 'кинотеатров')}`;
    },

    filteredCinemas() {
      return this.cinemas.filter((cinema) => {
        const search = this.search.toLowerCase();

        if (search) {
          if (cinema.name.toLowerCase().includes(search) || cinema?.city?.name.toLowerCase().includes(search)) {
            return true;
          }

          return false;
        }

        return true;
      });
    },

    isAllChecked() {
      return this.cinemas.every((cinema) => this.localValue.includes(cinema.id));
    },
  },
  watch: {
    value: {
      handler() {
        this.localValue = [...this.value];
      },
      immediate: true,
    },
  },
  methods: {
    toggle(cinemaId) {
      const index = this.localValue.indexOf(cinemaId);

      if (index > -1) {
        this.localValue.splice(index, 1);
      } else {
        this.localValue.push(cinemaId);
      }
    },

    toggleAll() {
      this.localValue = this.isAllChecked ? [] : this.cinemas.map((cinema) => cinema.id);
    },

    getCinemaFullName(cinemaId) {
      const cinema = this.cinemas.find((_cinema) => _cinema.id === cinemaId);

      if (!cinema) {
        return 'Не найдено';
      }

      return `${cinema.name} (${cinema.city.name})`;
    },

    onClickOutside() {
      this.clickedOutside = true;
    },

    hide(event) {
      if (!this.clickedOutside) {
        event.preventDefault();
      }

      this.clickedOutside = false;

      this.$emit('change', this.localValue);
    },
  },
};
</script>

<style scoped>
.cinemas-select__list {
  max-height: 300px;
  min-width: 300px;
  overflow-y: auto;
}
.cinemas-select >>> .b-dropdown-form {
  padding: 2px 10px;
}
.cinemas-select__city {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.cinemas-select__tooltip >>> .tooltip-inner {
  max-width: 350px !important;
}
</style>
