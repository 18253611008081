export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: true,
  },
  {
    key: 'numberPart', // для удобства отображения поиска (с кнопкой стереть)
    type: 'text',
    label: 'Номер',
    filterable: true,
    sortable: false,
  },
  {
    key: 'certificateGroupId',
    type: 'text',
    label: 'Группа',
    filterable: true,
    sortable: false,
  },
  {
    key: 'useMode',
    type: 'text',
    label: 'Вид сертификата',
    filterable: true,
    sortable: false,
  },
  {
    key: 'organizationId',
    type: 'text',
    label: 'Организация',
    filterable: true,
    sortable: false,
  },
  {
    key: 'orderAppGroupId',
    type: 'select',
    label: 'Место продажи',
    filterable: false,
    sortable: false,
  },
  {
    key: 'nominal',
    type: 'text',
    label: 'Номинал',
    filterable: false,
    sortable: false,
  },
  {
    key: 'balance',
    type: 'text',
    label: 'Баланс',
    filterable: false,
    sortable: false,
  },
  {
    key: 'invalidateDate',
    type: 'text',
    label: 'Дата окончания действия',
    filterable: false,
    sortable: false,
  },
  {
    key: 'activatedAt',
    type: 'text',
    label: 'Дата активации',
    filterable: false,
    sortable: false,
  },
  {
    key: 'status',
    type: 'text',
    label: 'Статус',
    filterable: false,
    sortable: false,
  },
];
