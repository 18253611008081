/* eslint-disable no-return-await */
/* eslint-disable no-restricted-syntax */
import http from './http';
import {
  API_VERSION, API_SERVER, API_PORT, API_PATH,
} from '../config';
import { convertError, convertResponse, createQuery } from './utilities';

const collection = 'media_panels';

// screens
export async function updateGroupAndPosition(screens) {
  try {
    const response = await http.post(`/${API_VERSION}/${collection}/screens/update_group_and_position`, { screens });
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function createScreen(values) {
  try {
    const response = await http.put(`/${API_VERSION}/${collection}/screens/activate`, values);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function updateScreen(id, values) {
  try {
    const response = await http.put(`/${API_VERSION}/${collection}/screens/${id}`, values);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function updateScreens(ids, values) {
  const promises = [];

  for (const id of ids) {
    promises.push(updateScreen(id, values));
  }

  return await Promise.all(promises);
}

export async function rebootScreen(id) {
  try {
    await http.put(`/${API_VERSION}/${collection}/screens/${id}/reboot`);
    return [null, true];
  } catch (error) {
    return convertError(error);
  }
}

// schedules generate
export async function generateSchedulesForSettings(id) {
  try {
    const response = await http.put(`/${API_VERSION}/${collection}/schedules/generate_for_settings/${id}`);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

// previews
export async function getPreviewForSettings(settings) {
  try {
    const response = await http.post(`/${API_VERSION}/${collection}/previews/get_for_settings`, settings);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function previewHtml(params) {
  const query = createQuery({ params });

  try {
    const response = await http.get(`/${API_VERSION}/${collection}/previews/get_html?${query}`);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

// screenshots
export async function getScreenshot(screenId) {
  try {
    const response = await http.get(`/${API_VERSION}/${collection}/screenshots/${screenId}`, {
      responseType: 'blob',
    });

    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function getScreenshotStatus(screenId) {
  try {
    const response = await http.get(`/${API_VERSION}/${collection}/screenshots/${screenId}/status`);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function requestOnDownloadScreenshot(screenId) {
  try {
    const response = await http.put(`/${API_VERSION}/${collection}/screenshots/${screenId}/send_request`, { screenId });
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

// logs
export async function getLogs({ filter, page, limit }) {
  const query = createQuery({ filter, page, limit });

  try {
    const response = await http.get(`/${API_VERSION}/${collection}/logs?${query}`);
    return [null, convertResponse(response)];
  } catch (error) {
    return convertError(error);
  }
}

export async function getLogsLevels({ filter }) {
  const query = createQuery({ filter });

  try {
    const response = await http.get(`/${API_VERSION}/${collection}/logs/levels?${query}`);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export async function getLogsTags({ filter }) {
  const query = createQuery({ filter });

  try {
    const response = await http.get(`/${API_VERSION}/${collection}/logs/tags?${query}`);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

// library
export async function getFilesAndDirectory({ sort, params } = {}) {
  const query = createQuery({
    sort, params,
  });

  try {
    const response = await http.get(`/${API_VERSION}/${collection}/library/list?${query}`);
    return [null, response.data || []];
  } catch (error) {
    return convertError(error);
  }
}

export async function registerFileOrDirectory(resourceInfo) {
  try {
    const response = await http.post(`/${API_VERSION}/${collection}/library/file/init`, resourceInfo);
    return [null, response.data.id];
  } catch (error) {
    return convertError(error);
  }
}

export async function uploadFile(fileId, formData, config) {
  try {
    const response = await http.post(`/${API_VERSION}/${collection}/library/file/${fileId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    });
    return [null, response];
  } catch (error) {
    return convertError(error);
  }
}

export async function updateFileOrDirectory(id, name) {
  try {
    const response = await http.put(`/${API_VERSION}/${collection}/library/file/${id}`, { name });
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}

export function getFileUrl(id, size) {
  return `${API_SERVER + (API_PORT ? `:${API_PORT}` : '')}/${API_PATH}/${API_VERSION}/${collection}/library/file/${id}?quality=${size}`;
}

export async function removeFileOrDirectory(id) {
  try {
    await http.delete(`/${API_VERSION}/${collection}/library/file/${id}`);
    return [null, true];
  } catch (error) {
    return convertError(error);
  }
}

export async function getStorage() {
  try {
    const response = await http.get(`/${API_VERSION}/${collection}/library/storage`);
    return [null, response.data || {}];
  } catch (error) {
    return convertError(error);
  }
}
