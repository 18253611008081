<template>
  <modal-form-dialog
    ok-title="Скачать"
    title="Экспорт расписания"
    size="sm"
    :save="exportShows"
    :close="() => true"
    :busy="loading"
    @hidden="$emit('hide')"
  >
    <b-form-group
      label="Период сеансов:"
      :invalid-feedback="`${errors.dateStart} ${errors.dateEnd}`"
      :state="states.dateStart && states.dateEnd"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="fields.dateStart"
        :disabled="loading"
        :state="states.dateStart"
        @change="changeField('dateStart', $event)"
      ></date-popover>
      <date-popover
        size="sm"
        :value="fields.dateEnd"
        :disabled="loading"
        :state="states.dateEnd"
        @change="changeField('dateEnd', $event)"
      ></date-popover>
    </b-form-group>
    <b-form-group label="Формат:">
      <b-select
        size="sm"
        v-model="fields.format"
        :options="formats"
      ></b-select>
    </b-form-group>
  </modal-form-dialog>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import ModalFormDialog from '../../components/modal/ModalFormDialog.vue';
import DatePopover from '../../components/fields/DatePopover.vue';

export default {
  components: {
    ModalFormDialog,
    DatePopover,
  },
  props: {
    cinema: {
      default: () => ({}),
    },
    initDateStart: {
      default: null,
    },
    initDateEnd: {
      default: null,
    },
  },
  data() {
    return {
      visible: true,
      errors: {},
      states: {},
      fields: {
        dateStart: null,
        dateEnd: null,
        format: 'pdf',
      },
      loading: false,
      formats: [
        { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'XLSX' },
      ],
    };
  },
  watch: {
    initDateStart: {
      handler() {
        this.fields.dateStart = this.$datetime.convertDateToDbFormat(this.initDateStart);
      },
      immediate: true,
    },
    initDateEnd: {
      handler() {
        this.fields.dateEnd = this.$datetime.convertDateToDbFormat(this.initDateEnd);
      },
      immediate: true,
    },
  },
  methods: {
    changeField(name, value) {
      this.fields[name] = value;
      this.setError(name, '');
      this.setState(name, null);
    },

    async exportShows() {
      const dates = `${this.$datetime.formatDate(this.fields.dateStart)}-${this.$datetime.formatDate(this.fields.dateEnd)}`;
      const cinema = `${this.cinema.name}, ${this.cinema.city.name}`;
      const filename = `Сеансы ${cinema} ${dates}.${this.fields.format}`;

      this.loading = true;
      this.resetErrors();
      this.resetStates();

      const [err, result] = await this.$store.dispatch('data/shows/exportShows', {
        dateStart: this.fields.dateStart,
        dateEnd: this.fields.dateEnd,
        format: this.fields.format,
        filename,
      });

      if (err && err.status === 422 && err?.data?.errors) {
        const errors = err?.data?.errors;
        for (const field in errors) {
          this.setError(field, errors[field].join(', '));
          this.setState(field, false);
        }
      }

      this.loading = false;

      return !err;
    },

    setError(field, value) {
      this.$set(this.errors, field, value);
    },

    resetErrors() {
      this.errors = {};
    },

    setState(field, value) {
      this.$set(this.states, field, value);
    },

    resetStates() {
      this.states = {};
    },
  },
};
</script>
