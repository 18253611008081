<template>
  <span class="yamaguchi">
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 360 360"
    :style="`--color: ${color}`"
  >
    <g transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)">
      <path
        d="M1620 3550 c-620 -76 -1141 -452 -1405 -1012 -124 -266 -182 -585
    -157 -871 23 -256 72 -429 182 -652 479 -965 1718 -1274 2599 -647 337 239
    586 602 685 1000 40 159 50 248 50 432 0 185 -10 273 -51 435 -49 197 -148
    411 -271 585 -86 122 -290 326 -412 412 -220 155 -471 259 -740 305 -105 17
    -379 25 -480 13z m-427 -883 c18 -8 161 -142 322 -300 182 -181 297 -287 309
    -287 13 0 127 106 310 289 318 315 320 317 427 309 105 -8 176 -80 187 -189 9
    -95 2 -104 -373 -479 l-345 -345 0 -488 c0 -429 -2 -492 -16 -520 -76 -151
    -272 -166 -369 -29 l-30 44 -3 496 -3 497 -343 345 c-375 377 -372 372 -364
    481 8 110 93 189 205 189 29 0 68 -6 86 -13z"
      />
    </g>
  </svg>
  </span>
</template>

<script>

export default {
  props: {
    color: {
      default: '#000',
    },
  },
};
</script>

<style lang="scss" scoped>
.yamaguchi {
  text-align: center;
  svg {
    fill: var(--color);
    width: 65%;
    vertical-align: text-top;
  }
}
</style>
