export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'question',
    type: 'text',
    label: 'Тема вопроса',
    filterable: true,
    sortable: false,
  },
  {
    key: 'category',
    type: 'text',
    label: 'Категория вопроса',
    filterable: true,
    sortable: false,
  },
  {
    key: 'apps',
    type: 'text',
    label: 'Источник',
    filterable: true,
    sortable: false,
  },
];
