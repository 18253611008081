<template>
  <dictionary-provider
    :name="dictionary"
  >
    <template #default="{ items, loading: dictionaryLoading }">
      <b-row class="mb-2">
        <b-col style="width: 220px; flex: none;">
          <div class="selection-form__title">{{ name }}</div>
        </b-col>
        <b-col class="selection-max-column">
          <toggle-list
            :id="`${name}-include`"
            :options="convert(items)"
            :value="getObjects(include)"
            :disabled="disabled || dictionaryLoading"
            :searchable="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preselect-first="true"
            :preserve-search="true"
            @change="$emit('include', $array.getValues($event, 'id'))"
          ></toggle-list>
        </b-col>
        <b-col class="selection-max-column">
          <toggle-list
            :id="`${name}-exclude`"
            :options="convert(items)"
            :value="getObjects(exclude)"
            :disabled="disabled || dictionaryLoading"
            :searchable="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preselect-first="true"
            :preserve-search="true"
            @change="$emit('exclude', $array.getValues($event, 'id'))"
          ></toggle-list>
        </b-col>
      </b-row>
    </template>
  </dictionary-provider>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
  },
  props: {
    name: {
      default: '',
    },
    dictionary: {
      default: '',
    },
    disabled: {
      default: false,
    },
    include: {
      default: () => [],
    },
    exclude: {
      default: () => [],
    },
    convert: {
      type: Function,
      default: (v) => v,
    },
  },
  methods: {
    getIds(value) {
      return value.map(({ id }) => id);
    },

    getObjects(value) {
      return value.map((id) => ({ id }));
    },
  },
};
</script>

<style scoped>
.selection-form__title {
  line-height: 30px;
  font-weight: 500;
}
.selection-max-column {
  max-width: calc((100% + 220px) / 2 - 220px);
}
</style>
