export default {
  props: {
    collection: {
      required: true,
    },
    filter: {
      default: () => ({}),
    },
    params: {
      default: () => ({}),
    },
    page: {},
    limit: {},
    sortBy: {},
    sortDesc: {
      default: false,
    },
    autoReset: {
      default: true,
    },
    disableFetching: {
      default: false,
    },
  },
  render() {
    return this.$scopedSlots.default({
      data: {
        items: this.data.items,
        total: this.data.total,
        filter: this.data.filter,
        params: this.data.params,
        page: this.data.page,
        limit: this.data.limit,
        loading: this.data.loading,
        sortBy: this.data.sortBy,
        sortDesc: this.data.sortDesc,
      },
      actions: {
        changeSort: this.changeSort,
        changeFilter: this.changeFilter,
        changeParam: this.changeParam,
        changePage: this.changePage,
        changeLimit: this.changeLimit,
        fetchItems: this.fetchItems,
        fetchNextItems: this.fetchNextItems,
        removeItem: this.removeItem,
        removeItems: this.removeItems,
        resetItems: this.resetItems,
        resetFilter: this.resetFilter,
        reset: this.reset,
      },
    });
  },
  computed: {
    data() {
      return this.$store.state.data[this.collection];
    },
    watchedValues() {
      return [...Object.values(this.filter), ...Object.values(this.params)].join();
    },
  },
  watch: {
    watchedValues: {
      handler() {
        this.init();
      },
    },
    page: {
      handler() {
        this.init();
      },
    },
    limit: {
      handler() {
        this.init();
      },
    },
    sortBy: {
      handler() {
        this.init();
      },
    },
    sortDesc: {
      handler() {
        this.init();
      },
    },
  },
  created() {
    this.init();
  },
  destroyed() {
    if (this.autoReset) {
      this.reset();
    }
  },
  methods: {
    init() {
      // eslint-disable-next-line no-restricted-syntax
      for (const [field, value] of Object.entries(this.filter)) {
        this.setFilter(field, value);
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [field, value] of Object.entries(this.params)) {
        this.setParam(field, value);
      }

      if (typeof this.page !== 'undefined') {
        this.setPage(this.page);
      }

      if (typeof this.limit !== 'undefined') {
        this.setLimit(this.limit);
      }

      if (typeof this.sortBy !== 'undefined') {
        this.setSort(this.sortBy, this.sortDesc);
      }

      this.fetchItems();
    },
    changeSort(by, desc) {
      this.$store.dispatch(`data/${this.collection}/changeSort`, { by, desc });
    },
    changeFilter(field, value) {
      this.$store.dispatch(`data/${this.collection}/changeFilter`, { field, value });
    },
    changeParam(field, value) {
      this.$store.dispatch(`data/${this.collection}/changeParam`, { field, value });
    },
    changePage(page) {
      this.$store.dispatch(`data/${this.collection}/changePage`, page);
    },
    changeLimit(limit) {
      this.$store.dispatch(`data/${this.collection}/changeLimit`, limit);
    },
    setSort(by, desc) {
      this.$store.dispatch(`data/${this.collection}/setSort`, { by, desc });
    },
    setFilter(field, value) {
      this.$store.dispatch(`data/${this.collection}/setFilter`, { field, value });
    },
    setParam(field, value) {
      this.$store.dispatch(`data/${this.collection}/setParam`, { field, value });
    },
    setPage(page) {
      this.$store.dispatch(`data/${this.collection}/setPage`, page);
    },
    setLimit(limit) {
      this.$store.dispatch(`data/${this.collection}/setLimit`, limit);
    },
    async fetchItems() {
      if (this.disableFetching) {
        return;
      }

      await this.$store.dispatch(`data/${this.collection}/fetchItems`);

      this.$emit('loaded', this.data.items);
    },
    fetchNextItems() {
      if (this.disableFetching) {
        return;
      }

      this.$store.dispatch(`data/${this.collection}/fetchNextItems`);
    },
    resetItems() {
      this.$store.dispatch(`data/${this.collection}/resetItems`);
    },
    resetFilter() {
      this.$store.dispatch(`data/${this.collection}/resetFilter`);
    },
    resetParams() {
      this.$store.dispatch(`data/${this.collection}/resetParams`);
    },
    resetPage() {
      this.$store.dispatch(`data/${this.collection}/resetPage`);
    },
    resetSort() {
      this.$store.dispatch(`data/${this.collection}/resetSort`);
    },
    resetLimit() {
      this.$store.dispatch(`data/${this.collection}/resetLimit`);
    },
    createItem(values) {
      return this.$store.dispatch(`data/${this.collection}/createItem`, { values });
    },
    removeItem(id) {
      return this.$store.dispatch(`data/${this.collection}/removeItem`, { id });
    },
    removeItems(ids) {
      return this.$store.dispatch(`data/${this.collection}/removeItems`, { ids });
    },
    changeItem(id, values) {
      return this.$store.dispatch(`data/${this.collection}/updateItem`, { id, values });
    },
    changeItems(ids, values) {
      return this.$store.dispatch(`data/${this.collection}/updateItems`, { ids, values });
    },
    reset() {
      this.resetItems();
      this.resetFilter();
      this.resetParams();
      this.resetSort();
      this.resetPage();
      this.resetLimit();
    },
  },
};
