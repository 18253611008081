<template>
  <multi-view class="report report-cashier">
    <template #side>
      <filters></filters>
    </template>

    <template #content>
      <merchant-payments
        ref="merchantPayments"
      ></merchant-payments>
    </template>
  </multi-view>
</template>

<script>
import MultiView from '../../layout/MultiView.vue';

import Filters from './Filters.vue';
import MerchantPayments from './MerchantPayments.vue';

export default {
  components: {
    MultiView,
    Filters,
    MerchantPayments,
  },
};
</script>
