export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'domain',
    type: 'text',
    label: 'Домен',
    filterable: true,
    sortable: true,
  },
  {
    key: 'enabled',
    type: 'select',
    label: 'Активно',
    filterable: true,
    sortable: true,
  },
];
