<template>
  <div class="d-flex">
    <div class="flex-fill d-flex align-items-center">
      <div>
        <b-input
          type="text"
          size="sm"
          placeholder="Поиск..."
          class="d-inline-block w-auto"
          @input="(value) => $emit('update-filter', value)"
        ></b-input>
      </div>
      <div class="ml-2 pr-4 d-flex align-items-center navigation">
        <div
          v-for="directory in directories"
          :key="directory.id"
          class="d-inline-flex"
        >
            <div
              :class="['py-1 text-truncate text-nowrap navigation__item', { 'cursor-pointer text-primary': (parentId !== directory.id) }]"
              @click="parentId !== directory.id && $emit('click-directory', directory.id)"
            >{{ directory.name }}</div>
            <div v-if="(parentId !== directory.id)" class="p-1">/</div>
          </div>
      </div>
    </div>
    <div class="d-flex">
      <div
        v-if="storageTotal"
        class="mr-3 status"
      >
        <div class="status__title">
          Использовано {{ storageUsedConvertSize }} из {{ storageTotalConvertSize }}
        </div>
        <div class="status__bar">
          <b-progress :max="100" height="5px" :variant="variant">
            <b-progress-bar :value="storageUsed"></b-progress-bar>
          </b-progress>
        </div>
      </div>
      <b-button
        size="sm"
        class="mr-2"
        variant="outline-info"
        @click="$emit('add-directory')"
        >Новая папка</b-button>

      <b-button
        size="sm"
        variant="info"
        @click="$refs['upload-file'].click()"
        >Загрузить</b-button>
      <input
        ref="upload-file"
        class="d-none"
        type="file"
        multiple
        :accept="accept"
        @change="(event) => $emit('upload-files', event.target.files)"
      />
    </div>
  </div>
</template>

<script>
import { convertFileSize } from '../../utils/file';

export default {
  props: {
    searchName: {
      type: String,
    },
    accept: {
      type: String,
      default: 'image/*,video/*',
    },
    directories: {
      type: Array,
      default: () => [],
    },
    storage: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    parentId: null,
  },
  computed: {
    storageTotal() {
      return this.storage.used + this.storage.free;
    },
    storageUsed() {
      return this.storage.used * 100 / this.storageTotal;
    },
    storageUsedConvertSize() {
      return convertFileSize(this.storage.used);
    },
    storageTotalConvertSize() {
      return convertFileSize(this.storageTotal);
    },
    variant() {
      if (this.storageUsed <= 50) {
        return 'primary';
      }
      if (this.storageUsed > 50 && this.storageUsed < 90) {
        return 'warning';
      }

      return 'danger';
    },
  },
};
</script>

<style lang="scss" scoped>
  .navigation {
    &__item {
      max-width: 70px;
    }
  }
</style>
