export const VMdLang = {
  undo: {
    toolbar: 'Отменить',
  },
  redo: {
    toolbar: 'Повторить',
  },
  clear: {
    toolbar: 'Очистить все',
  },
  h: {
    toolbar: 'Заголовок',
  },
  h1: {
    toolbar: 'Заголовок h1',
    placeholder: 'Заголовок h1',
  },
  h2: {
    toolbar: 'Заголовок h2',
    placeholder: 'Заголовок h2',
  },
  h3: {
    toolbar: 'Заголовок h3',
    placeholder: 'Заголовок h3',
  },
  h4: {
    toolbar: 'Заголовок h4',
    placeholder: 'Заголовок h4',
  },
  h5: {
    toolbar: 'Заголовок h5',
    placeholder: 'Заголовок h5',
  },
  h6: {
    toolbar: 'Заголовок h6',
    placeholder: 'Заголовок h6',
  },
  bold: {
    toolbar: 'Полужирный',
    placeholder: 'Полужирный',
  },
  italic: {
    toolbar: 'Курсив',
    placeholder: 'Курсив',
  },
  strikethrough: {
    toolbar: 'Зачеркнутый',
    placeholder: 'Зачеркнутый',
  },
  quote: {
    toolbar: 'Цитата',
    placeholder: 'Цитата',
  },
  ul: {
    toolbar: 'Обычный список',
    placeholder: 'Обычный список',
  },
  ol: {
    toolbar: 'Нумерованный список',
    placeholder: 'Нумерованный список',
  },
  table: {
    toolbar: 'Вставить таблицу',
  },
  hr: {
    toolbar: 'Линия',
  },
  link: {
    toolbar: 'Вставить ссылку',
    descPlaceholder: 'Название',
  },
  image: {
    toolbar: 'Изображение',
  },
  imageLink: {
    toolbar: 'Вставить изображение',
  },
  uploadImage: {
    toolbar: 'Загрузить изображение',
  },
  code: {
    toolbar: 'Вставить код',
  },
  save: {
    toolbar: 'Сохранить',
  },
  preview: {
    enabled: 'Предварительный просмотр включен',
    disabled: 'Предварительный просмотр выключен',
  },
  toc: {
    title: 'Навигация',
    enabled: 'Навигация включена',
    disabled: 'Навигация выключена',
  },
  syncScroll: {
    enabled: 'Синхронизация прокрутки включена',
    disabled: 'Синхронизация прокрутки выключена',
  },
  fullscreen: {
    enabled: 'Полный экран (нажмите ESC для выхода)',
    disabled: 'Выйти из полноэкранного режима',
  },
};
