<template>
  <div class="p-2">
    <b-form-group
      label="Период:"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="periodStart"
        @change="$emit('change-period-start', $event)"
      ></date-popover>
      <date-popover
        size="sm"
        :value="periodEnd"
        @change="$emit('change-period-end', $event)"
      ></date-popover>
    </b-form-group>
  </div>
</template>

<script>
import DatePopover from '../../../components/fields/DatePopover.vue';

export default {
  components: {
    DatePopover,
  },
  props: {
    periodStart: {
      default: null,
    },
    periodEnd: {
      default: null,
    },
  },
  methods: {

  },
};
</script>
