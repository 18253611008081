export default [
  {
    key: 'createdAt',
    label: 'Дата создания',
    thStyle: {
      width: '200px',
    },
  },
  {
    key: 'operation',
    label: 'Операция',
    thStyle: {
      width: '140px',
    },
  },
  {
    key: 'itemType',
    label: 'Тип',
    thStyle: {
      width: '140px',
    },
  },
  {
    key: 'result',
    label: 'Результат',
  },
];
