export default [
  {
    key: 'name',
    type: 'text',
    label: 'Название',
  },
  {
    key: 'status',
    type: 'text',
    label: 'Продажи',
    thStyle: {
      width: '160px',
    },
  },
];
