/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import collectionServiceFactory from '../services/collectionServiceFactory';

export default {
  props: {
    collection: {
      required: true,
    },
    id: {
      default: null,
    },
    init: {
      default: () => ({}),
    },
    copy: {
      default: false,
    },
  },
  render() {
    return this.$scopedSlots.default({
      data: {
        id: this.item?.id,
        item: this.item,
        errors: this.errors,
        states: this.states,
        loading: this.loading,
        dirty: this.dirty,
      },
      actions: {
        initValues: this.initValues,
        initValue: this.initValue,
        setValue: this.setValue,
        load: this.load,
        save: this.save,
        reset: this.reset,
      },
    });
  },
  data() {
    return {
      item: {},
      errors: {},
      states: {},
      loading: false,
      dirty: false,
      service: null,
    };
  },
  created() {
    this.service = collectionServiceFactory(this.collection);

    if (this.id) {
      this.load();
    } else {
      this.initValues();
    }
  },
  watch: {
    id() {
      if (this.id) {
        this.load();
      }
    },
  },
  methods: {
    setError(field, value) {
      this.$set(this.errors, field, value);
    },

    resetErrors() {
      this.errors = {};
    },

    setState(field, value) {
      this.$set(this.states, field, value);
    },

    resetStates() {
      this.states = {};
    },

    setValue(field, value) {
      this.$set(this.item, field, value);

      this.setState(field, null);

      this.dirty = true;
    },

    initValues() {
      for (const [field, value] of Object.entries(this.init)) {
        this.initValue(field, value);
      }
    },

    initValue(field, value) {
      this.$set(this.item, field, value);

      this.setState(field, null);
    },

    reset() {
      this.loading = false;
      this.item = {};
      this.errors = {};
      this.states = {};
      this.dirty = false;
    },

    async load() {
      this.loading = true;

      const [err, item] = await this.service.getItem(this.id);

      if (err && err.status === 404) {
        this.$emit('notfound');
      }

      if (err && err.status === 403) {
        this.$emit('forbidden');
      }

      if (item) {
        if (this.copy) {
          delete item.id;
        }

        this.item = item;
        this.$emit('loaded', this.item);
      }

      this.loading = false;
      this.dirty = false;
    },

    async save() {
      let err;
      let item;

      this.loading = true;

      this.resetErrors();
      this.resetStates();

      if (this.item.id) {
        [err, item] = await this.service.updateItem(this.item.id, this.item);
      } else {
        [err, item] = await this.service.createItem(this.item);
      }

      if (err && err.status === 422 && err?.data?.errors) {
        const errors = err?.data?.errors;
        for (const field in errors) {
          this.setError(field, Array.isArray(errors[field]) ? errors[field].join(', ') : errors[field]);
          this.setState(field, false);
        }
      }

      if (err && err.status === 403) {
        this.$emit('forbidden');
      }

      if (!err) {
        this.dirty = false;
        const id = item?.id || this.item.id;

        this.$emit('saved', id);
      } else {
        this.$emit('error', this.errors);
      }

      this.loading = false;

      return !err;
    },

    async remove() {
      this.loading = true;

      const [err, result] = await this.service.removeItem(this.id);

      if (err && err.status === 403) {
        this.$emit('forbidden');
      }

      if (!err) {
        this.reset();
        this.initValues();

        this.$emit('removed');
      }

      this.loading = false;
      this.dirty = false;

      return !err && result;
    },
  },
};
