<template>
  <navbar-item-dropdown
    :text="`Здравствуйте, ${username}`"
    :items="items"
    right
  >
  </navbar-item-dropdown>
</template>

<script>
import NavbarItemDropdown from './NavbarItemDropdown.vue';

export default {
  components: {
    NavbarItemDropdown,
  },
  data() {
    return {
      items: [
        {
          name: 'Выйти',
          url: '/logout',
          icon: 'fa-lock',
        },
      ],
    };
  },
  computed: {
    username() {
      return this.$user.name || '';
    },
  },
};
</script>
