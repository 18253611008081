var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container mail-accounts"},[_c('collection-provider',{ref:"collection",attrs:{"collection":"mailAccounts"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('dictionaries-provider',{attrs:{"names":['legalEntities']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var legalEntities = ref.legalEntities;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"panel-actions":_vm.datatable.panelActions,"item-actions":_vm.datatable.itemActions,"checked":_vm.datatable.checked,"permissions":_vm.$user.permissions},on:{"change-checked":function($event){_vm.datatable.checked = $event},"panel-action":function($event){return _vm.performPanelAction($event)},"item-action":function($event){return _vm.performItemAction($event)}},scopedSlots:_vm._u([{key:"cell(legalEntityId)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.legalEntity.name)+" ")]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}],null,true)})]}}])}),(_vm.modals.mailAccount.show)?_c('modal-model-form',{attrs:{"create-title":"Создание мерчант аккаунта","edit-title":"Редактирование мерчант аккаунта","collection":"mailAccounts","id":_vm.modals.mailAccount.id},on:{"close":function($event){_vm.modals.mailAccount.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('mail-account-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,false,3243405080)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }