<template>
  <div>
    <b-button
      size="sm"
      variant="info"
      class="mr-1"
      :disabled="disabled"
      @click="$emit('save')"
    >
      <icon icon="fa-floppy-o"></icon> Сохранить
    </b-button>

    <b-button
      size="sm"
      variant="outline-info"
      class="mr-2"
      :disabled="disabled"
      @click="$emit('cancel')"
    >
      Отмена
    </b-button>

    <b-button
      size="sm"
      variant="outline-info"
      class="mr-1"
      :disabled="disabled"
      @click="$emit('add-rows')"
    >
      <icon icon="fa-plus"></icon> Добавить ряды
    </b-button>

    <b-dropdown
      class="mr-1"
      variant="outline-info"
      size="sm"
      :disabled="disabled"
    >
      <template #button-content>
        <icon icon="fa-plus"></icon> Добавить проходы
      </template>
      <b-dropdown-item @click="$emit('add-road')">Горизонтальный</b-dropdown-item>
      <b-dropdown-item :disabled="!hasSelectedSeats && !hasSelectedGaps" @click="$emit('add-gap-left')">Слева от выделенных</b-dropdown-item>
      <b-dropdown-item :disabled="!hasSelectedSeats && !hasSelectedGaps" @click="$emit('add-gap-right')">Справа от выделенных</b-dropdown-item>
    </b-dropdown>

    <span
      class="button-container mr-1"
      v-b-tooltip.hover="'Смена порядка нумерации кресел'"
    >
      <b-button
        size="sm"
        variant="outline-info"
        :disabled="disabled || !hasSeats"
        @click="$emit('reverse-seats')"
      >
        <icon icon="fa-arrows-h"></icon>
      </b-button>
    </span>

    <span
      class="button-container mr-1"
      v-b-tooltip.hover="'Смена порядка нумерации рядов'"
    >
      <b-button
        size="sm"
        variant="outline-info"
        :disabled="disabled || !hasSeats"
        @click="$emit('reverse-rows')"
      >
        <icon icon="fa-arrows-v"></icon>
      </b-button>
    </span>

    <b-dropdown
      text="Изменить тип"
      class="mr-1"
      variant="outline-info"
      size="sm"
      :disabled="disabled || !hasSelectedSeats"
    >
      <b-dropdown-item
        v-for="type in seatTypes"
        @click="$emit('change-seats-type', type.code)"
        :key="type.value"
      >
        <div class="d-flex justify-content-between">
          <span>{{type.name}}</span>
          <span v-html="getSeatScheme(type.code)" class="ml-4 seat-scheme"></span>
        </div>
      </b-dropdown-item>
    </b-dropdown>

    <b-dropdown
      text="Ценовой пояс"
      class="mr-1"
      variant="outline-info"
      size="sm"
      :disabled="disabled || !hasSelectedSeats"
    >
      <b-dropdown-item
        v-for="color in colorSchemes"
        @click="$emit('change-seats-color-scheme', color.id)"
        :key="color.id"
      >
        <span
          class="color mr-1"
          :style="`background-color: ${color.color}`"
        ></span>
        {{color.name}}
      </b-dropdown-item>
    </b-dropdown>

    <span
      class="button-container mr-1"
      v-b-tooltip.hover="'Удалить'"
    >
      <b-button
        size="sm"
        variant="outline-info"
        :disabled="disabled || (!hasSelectedSeats && !hasSelectedGaps)"
        @click="$emit('remove-seats')"
      >
        <icon icon="fa-trash"></icon>
      </b-button>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    seatTypes: {
      default: () => ([]),
    },
    colorSchemes: {
      default: () => ([]),
    },
    hasSeats: {
      default: false,
    },
    hasSelectedSeats: {
      default: false,
    },
    hasSelectedGaps: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    getSeatScheme(type) {
      return this.seatTypes.find((seatType) => seatType.code === type)?.scheme;
    },
  },
};
</script>

<style scoped>
.button-container {
  display: inline-block;
}
.color {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 3px;
  vertical-align: middle;
}
.seat-scheme {
  display: inline-block;
  height: 1em;
  color: #bbb;
}
.seat-scheme >>> svg {
  height: 100%;
  width: auto;
  fill: #ccc !important;
}
.seat__scheme >>> svg > stop[stop-color^=""] {
  stop-color: #ccc !important;
}
</style>
