<template>
  <b-input-group>
    <b-form-input
      readonly
      type="text"
      size="sm"
      :value="value"
      :state="state"
      :disabled="disabled"
      @change="$emit('change', $event)"
    ></b-form-input>
    <b-input-group-append>
      <b-button
        size="sm"
        variant="outline-secondary"
        class="d-flex justify-content-center align-items-center"
        @click="showPopover = !showPopover"
      >
        <span
          id="color-picker"
          :style="`background-color: ${this.value}`"
        ></span>
        <b-popover
          target="color-picker"
          placement="bottom"
          :show.sync="showPopover"
        >
          <color-picker
            v-model="color"
            v-click-outside="hidePopover"
          ></color-picker>
        </b-popover>
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import VueColor from 'vue-color';

export default {
  components: {
    ColorPicker: VueColor.Sketch,
  },
  props: {
    value: {
      default: '',
    },
    state: {
      default: null,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      showPopover: false,
    };
  },
  computed: {
    color: {
      get() {
        return {
          hex: this.value || '#FFFFFF',
        };
      },
      set(value) {
        this.$emit('change', value.hex);
      },
    },
  },
  methods: {
    hidePopover() {
      this.showPopover = false;
    },
  },
};
</script>

<style scoped>
#color-picker {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #ccc;
}
.vc-compact, .vc-chrome, .vc-sketch {
  box-shadow: none;
}
</style>
