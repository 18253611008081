<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
import ChartJs from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJs.register(annotationPlugin);

export default {
  props: {
    actualData: {
      default: () => [],
    },
    targetData: {
      default: () => [],
    },
    periods: {
      default: () => [],
    },
    backgroundColor: {
      default: 'rgba(26, 141, 255, 0.2)',
    },
    labelColor: {
      default: '#1A8CFF',
    },
    targetColor: {
      default: '#1A8CFF',
    },
    minColor: {
      default: '#DC3545',
    },
    maxColor: {
      default: '#28A745',
    },
    lineHeight: {
      default: 12,
    },
    dash: {
      default: () => [10, 5],
    },
    tooltipTarget: {
      default: 'План',
    },
    tooltipActual: {
      default: 'Факт',
    },
    tooltipTitle: {
      default: '',
    },
    unit: {
      default: '',
    },
  },
  data() {
    return {
      // mChart: null, // всё правильно! иначе даёт ошибку...
    };
  },
  computed: {
    watchedValues() {
      return [...this.targetData, ...this.actualData];
    },

    maxValue() {
      return Math.max(...[...this.actualData, ...this.targetData]);
    },
  },
  watch: {
    watchedValues() {
      this.renderChart();
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    if (this.mChart) {
      this.mChart.destroy();
    }
  },
  methods: {
    getTopLabel(index) {
      const data = this.actualData?.[index];

      return `${(data ? data.toLocaleString() : 0)}${this.unit}`;
    },

    getBottomLabel(index) {
      return this.periods?.[index] || '';
    },

    getPercent(index) {
      if (!this.targetData?.[index] || !this.actualData?.[index]) {
        return 0;
      }

      const percent = this.targetData[index] ? Math.round((this.actualData[index] / this.targetData[index]) * 100) : 0;

      return percent.toLocaleString();
    },

    getMiddleLabel(index) {
      return `${this.getPercent(index)}%`;
    },

    renderChart() {
      if (this.mChart) {
        this.mChart.destroy();
      }

      this.mChart = new ChartJs(this.$refs.canvas, {
        type: 'bar',
        data: {
          labels: new Array(this.periods.length).fill(''),
          datasets: [{
            backgroundColor: this.backgroundColor,
            barPercentage: 1.1,
            data: this.actualData,
            xAxisID: 'xAxisActual',
            order: 2,
          },
          {
            backgroundColor: this.targetColor,
            barPercentage: 1.1,
            data: this.targetData.map((v) => [v - 0.005 * this.maxValue, v + 0.005 * this.maxValue]),
            xAxisID: 'xAxisTarget',
            order: 1,
          }],
        },
        options: {
          animation: {
            duration: 0,
          },
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: 20,
            },
          },
          scales: {
            y: {
              display: false,
              min: 0,
              max: 1.01 * Math.max(...[...this.actualData, ...this.targetData]),
            },
            xAxisActual: {
              display: false,
            },
            xAxisTarget: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              titleAlign: 'center',
              callbacks: {
                label: (context) => {
                  if (context.dataset.xAxisID === 'xAxisTarget') {
                    return `${this.tooltipTarget}: ${this.targetData[context.dataIndex].toLocaleString()}`;
                  }

                  if (context.dataset.xAxisID === 'xAxisActual') {
                    return `${this.tooltipActual}: ${this.actualData[context.dataIndex].toLocaleString()} (${this.getPercent(context.dataIndex)}%)`;
                  }

                  return '';
                },

                title: (context) => `${this.tooltipTitle} ${this.periods[context[0].dataIndex]}`,
              },
            },
            annotation: {
              annotations: {
                line2: {
                  drawTime: 'afterDraw',
                  scaleID: 'y',
                  type: 'line',
                  value: Math.max(...this.actualData),
                  borderColor: this.maxColor,
                  borderDash: this.dash,
                },
              },
            },
          },
        },
        plugins: [
          {
            afterDatasetsDraw: (chart) => {
              const { ctx } = chart;
              ctx.save();

              const xAxis = chart.scales.xAxisActual;
              const yAxis = chart.scales.y;
              const barWidth = xAxis.maxWidth / xAxis.ticks.length;

              const skipOdd = barWidth < 70;

              xAxis.ticks.forEach((tick, index) => {
                if (index % 2 === 1 && skipOdd) {
                  return;
                }

                const x = xAxis.getPixelForTick(index);

                ctx.textAlign = 'center';
                ctx.fillStyle = this.labelColor;

                const topLabel = this.getTopLabel(index);
                const middleLabel = this.getMiddleLabel(index);
                const bottomLabel = this.getBottomLabel(index);

                // const widthPerSymbolTop = barWidth / topLabel.length;
                // const widthPerSymbolMiddle = barWidth / middleLabel.length;
                const widthPerSymbolBottom = barWidth / bottomLabel.length;

                ctx.fillText(topLabel, x, yAxis.top - 0.5 * this.lineHeight);

                if (bottomLabel.includes('-') && widthPerSymbolBottom < 7) {
                  const [firstLine, secondLine] = bottomLabel.split('-');

                  ctx.fillText(middleLabel, x, yAxis.bottom - 3 * this.lineHeight);
                  ctx.fillText(`${firstLine} - `, x, yAxis.bottom - 1.7 * this.lineHeight);
                  ctx.fillText(secondLine, x, yAxis.bottom - 0.5 * this.lineHeight);
                } else {
                  ctx.fillText(middleLabel, x, yAxis.bottom - 1.7 * this.lineHeight);
                  ctx.fillText(bottomLabel, x, yAxis.bottom - 0.5 * this.lineHeight);
                }
              });

              ctx.restore();
            },
          },
        ],
      });
    },
  },
};
</script>
