import { isFile } from './_index.fields';
import {
  MEDIA_PANEL_LIBRARY_STATUS_OK as statusOk,
  MEDIA_PANEL_LIBRARY_STATUS_UPLOADING as statusLoading,
} from '../../constants';

export default ({ status, type }) => [
  {
    type: 'button',
    variant: 'outline-info',
    icon: 'mdi-pencil',
    class: 'mr-1',
    action: 'editFileOrDirectory',
    ...(isFile(type) && { disabled: status !== statusOk }),
    ...(isFile(type) && { hidden: status === statusLoading }),
  },
  {
    type: 'button',
    variant: 'outline-danger',
    icon: 'mdi-delete',
    class: 'mr-1',
    action: 'deleteFileOrDirectory',
  },
];
