<template>
  <div class="schedule-day d-flex flex-column">
    <div
      v-for="(line, lineIndex) in linesCount"
      class="schedule-day__line d-flex"
      :class="getLineClasses(lineIndex)"
      :style="getLineStyles(lineIndex)"
      :key="lineIndex"
    >
      <div class="schedule-day__gap schedule-day__gap-left"></div>
      <div
        class="schedule-day__content h-100 d-flex flex-grow-1"
        :class="{'schedule-day__line_odd': lineIndex % 2 === 1}"
      >
        <slot :name="`item(${lineIndex})`"></slot>
      </div>
      <div class="schedule-day__gap schedule-day__gap-right"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    holiday: {
      default: false,
    },
    isFirstDay: {
      default: false,
    },
    isLastDay: {
      default: false,
    },
    isSameAsLeft: {
      default: () => false,
    },
    isSameAsRight: {
      default: () => false,
    },
    linesCount: {
      default: 12,
    },
    lineHeight: {
      default: 30,
    },
  },
  methods: {
    getLineClasses(lineIndex) {
      return {
        'schedule-day__line_free': this.isLineFree(lineIndex),
        'schedule-day__line_holiday': this.holiday,
        'schedule-day__line_first-day': this.isFirstDay || (!this.isLineFree(lineIndex) && !this.isSameAsLeft(lineIndex)),
        'schedule-day__line_last-day': this.isLastDay || (!this.isLineFree(lineIndex) && !this.isSameAsRight(lineIndex)),
      };
    },
    getLineStyles() {
      return {
        height: `${this.lineHeight}px`,
      };
    },
    isLineFree(lineIndex) {
      return typeof this.$slots[`item(${lineIndex})`] === 'undefined';
    },
  },
};
</script>

<style scoped>
.schedule-day__line_odd {
  background-color: rgba(86,61,124,.1);
}
.schedule-day__line_holiday .schedule-day__line_odd {
  background-color: rgba(86,0,0,.1);
}
.schedule-day__content {
  border-bottom: 2px solid purple;
  width: 0;
}
.schedule-day__line_free .schedule-day__content {
  border-bottom: 1px dashed #ccc;
}
.schedule-day__gap {
  width: 3px;
  border-bottom: 2px solid purple;
  display: none;
}
.schedule-day__line_free .schedule-day__gap {
  border-bottom: none;
  display: block;
}
.schedule-day__line_first-day .schedule-day__gap-left {
  border-bottom: none;
  display: block;
}
.schedule-day__line_last-day .schedule-day__gap-right {
  border-bottom: none;
  display: block;
}
</style>
