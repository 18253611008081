<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Название:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Цвет:"
          :invalid-feedback="errors.color"
          :state="states.color"
        >
          <input-color
            :value="item.color"
            :state="states.color"
            :disabled="loading"
            @change="updateValue('color', $event)"
          ></input-color>
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group
                label="Дата начала и окончания показа:"
                :invalid-feedback="errors.dateEnd || errors.dateStart"
                :state="states.dateEnd || states.dateStart"
            >
              <date-interval
                  :value="{from: item.dateStart, to: item.dateEnd}"
                  :disabled="loading"
                  @selected="updateValue('dateStart', $event.from), updateValue('dateEnd', $event.to)">
              </date-interval>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
          <div class="align-self-end">Место действия метки</div>
        </div>

        <b-form-group
          label="Кинотеатры:"
          :invalid-feedback="errors.cinemas"
          :state="states.cinemas"
        >
          <dictionary-provider name="cinemas">
            <template #default="{ items, loading: dictionaryLoading }">
              <toggle-list
                placeholder="Все"
                :options="convertCinemas($array.union(items, item.cinemas))"
                :value="item.cinemas"
                :disabled="loading || dictionaryLoading"
                @change="updateValue('cinemas', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
          <div class="align-self-end">Фильмы, для которых действует метка</div>
        </div>

        <b-form-group
          label="Релизы:"
          :invalid-feedback="errors.releases"
          :state="states.releases"
        >
          <dictionary-provider name="showsReleases">
            <template #default="{ items, loading: dictionaryLoading }">
              <toggle-list
                placeholder="Все"
                :options="convertReleases(items)"
                :value="item.releases"
                :searchable="true"
                :disabled="loading || dictionaryLoading"
                @change="updateValue('releases', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group label="Участвует в сортировке?">
          <b-form-checkbox
            :checked="item.sortable"
            :state="states.sortable"
            :disabled="loading"
            @change="updateValue('sortable', $event)"
          >
            Да
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';
import InputColor from '../color-schemes/InputColor.vue';
import DateInterval from '../../components/fields/DateInterval.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
    DateInterval,
    InputColor,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    convertReleases(releases) {
      if (!releases) {
        return [];
      }

      return releases.map((release) => ({
        id: release.id,
        name: release?.format?.name ? `${release?.movie?.name}, ${release?.format?.name}` : release?.movie?.name,
      }));
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },
  },
};
</script>
