<template>
  <div
    class="d-flex justify-content-between"
  >
    <b-button
      v-if="cinemaId && $user.can('cinemas.edit')"
      size="sm"
      variant="outline-info"
      class="mr-1"
      :disabled="disabled"
      @click="$emit('edit-cinema')"
    >
      <icon icon="fa-pencil"></icon> Редактировать информацию
    </b-button>
    <b-button v-if="$user.can('cinemas.create')"
      size="sm"
      variant="info"
      :disabled="disabled"
      @click="$emit('create-cinema')"
    >
      <icon icon="fa-plus"></icon> Добавить кинотеатр
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      default: false,
    },
    cinemaId: {
      default: null,
    },
  },
};
</script>
