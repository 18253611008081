<template>
  <b-table-simple class="mb-0">
    <b-thead>
      <b-tr>
        <b-th>Кинотеатры</b-th>
        <b-th
          class="text-right"
          v-for="(release, index) in releases"
          :key="release.id"
        >
          <movies-favorite-checkbox-all
            :items="items"
            :index="index"
            :cinemas="cinemas"
            :release="release"
            @add="handleAddRelease"
            @remove="handleRemoveRelease"
          />
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr
        v-for="item in items"
        :key="item.cinema.cinema_id"
      >
        <b-td>
          {{ item.cinema.name }}, {{ item.cinema.city_name }}
        </b-td>
        <b-td
          class="text-right"
          v-for="release in item.releases"
          :key="release.release_id"
        >
          <movies-favorite-checkbox
            :cinemaId="release.cinema_id"
            :release="release"
            @add="handleAddRelease"
            @remove="handleRemoveRelease"
          />
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import MoviesFavoriteCheckboxAll from './MoviesFavoriteCheckboxAll.vue';
import MoviesFavoriteCheckbox from './MoviesFavoriteCheckbox.vue';

export default {
  components: {
    MoviesFavoriteCheckboxAll,
    MoviesFavoriteCheckbox,
  },
  props: {
    cinemas: {
      default: null,
    },
    releases: {
      default: null,
    },
  },
  computed: {
    items() {
      const items = [];

      for (const cinema of this.cinemas) {
        const availableReleases = this.getAvailableReleases(cinema.halls);

        const releases = [];

        for (const release of this.releases) {
          const disabledRelease = availableReleases.find(({ id }) => id === release.id);

          releases.push({
            cinema_id: cinema.id,
            release_id: release.id,
            format_name: release.format.name,
            disabled: !disabledRelease,
          });
        }

        items.push({
          cinema: {
            name: cinema.name,
            city_name: cinema.city.name,
          },
          releases,
        });
      }

      return items;
    },
  },
  methods: {
    getAvailableReleases(halls) {
      return this.releases
        .filter(({ formatId }) => halls
          .find(({ formats = [] }) => formats
            .find(({ id }) => id === formatId)));
    },

    handleAddRelease({ cinemaId, releaseId }) {
      this.$store.commit('data/favoriteReleases/addRelease', { cinemaId, releaseId });
    },

    handleRemoveRelease({ cinemaId, releaseId }) {
      this.$store.commit('data/favoriteReleases/removeRelease', { cinemaId, releaseId });
    },
  },
};
</script>
