<template>
  <div class="table-container apps">
    <collection-provider
      collection="apps"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <dictionaries-provider :names="['appGroups']">
          <template #default="{ appGroups }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :panel-actions="datatable.panelActions"
              :item-actions="datatable.itemActions"
              :checked="datatable.checked"
              :permissions="$user.permissions"
              @change-checked="datatable.checked = $event"
              @panel-action="performPanelAction($event)"
              @item-action="performItemAction($event)"
            >
              <template #filter(appGroupId)>
                <b-form-select
                  size="sm"
                  :value="data.filter.appGroupId"
                  :options="$array.convertToOptions(appGroups.filter(group => group.id > KIOSK_APP_GROUP_ID))"
                  :disabled="data.loading"
                  @change="actions.changeFilter('appGroupId', $event)"
                ></b-form-select>
              </template>

              <template #cell(name)="data">
                <div>{{data.item.name}}</div>
                <div
                  v-if="data.item.description"
                  class="text-muted mt-2"
                >
                  {{data.item.description}}
                </div>
              </template>
              <template #cell(appGroupId)="data">
                {{data.item.appGroup.name}}
              </template>
              <template #cell(ticketOperator)="data">
                {{data.item.ticketOperator ? 'Да' : 'Нет'}}
              </template>
              <template #cell(serviceFeePercent)="data">
                {{data.item.serviceFeePercent}} %
              </template>
              <template #cell(enabled)="data">
                {{getAppsActiveName(data.item.enabled)}}
              </template>
            </datatable>
          </template>
        </dictionaries-provider>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.app.show"
      create-title="Создание приложения"
      edit-title="Редактирование приложения"
      collection="apps"
      size="xl"
      :id="modals.app.id"
      @close="modals.app.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <app-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></app-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';
import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import AppForm from './AppForm.vue';
import DictionariesProvider from '../../providers/DictionariesProvider';

import { KIOSK_APP_GROUP_ID } from '../../constants';

export default {
  components: {
    DictionariesProvider,
    CollectionProvider,
    ModalModelForm,
    AppForm,
    Datatable,
  },
  data() {
    return {
      KIOSK_APP_GROUP_ID,
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        app: {
          show: false,
          id: null,
        },
      },
      appsActiveStatuses: [
        {
          value: null,
          text: '',
        },
        {
          value: false,
          text: 'Нет',
        },
        {
          value: true,
          text: 'Да',
        },
      ],
      yesNoOptions: [
        {
          value: 1,
          text: 'Да',
        },
        {
          value: 0,
          text: 'Нет',
        },
      ],
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateAppForm() {
      this.modals.app.id = null;
      this.modals.app.show = true;
    },

    openEditAppForm(id) {
      this.modals.app.id = id;
      this.modals.app.show = true;
    },

    async removeCheckedApps() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    async removeApp(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    pauseCheckedApps() {
      this.$refs.collection.changeItems(this.datatable.checked, { enabled: 0 });
      this.datatable.checked = [];
    },

    resumeCheckedApps() {
      this.$refs.collection.changeItems(this.datatable.checked, { enabled: 1 });
      this.datatable.checked = [];
    },

    pauseApp(id) {
      this.$refs.collection.changeItem(id, { enabled: 0 });
    },

    resumeApp(id) {
      this.$refs.collection.changeItem(id, { enabled: 1 });
    },

    getAppsActiveName(value) {
      return this.appsActiveStatuses.find((status) => status.value === value)?.text;
    },
  },
};
</script>
