<template>
  <model-provider
    ref="model"
    collection="repertoryItems"
    :id="id"
  >
    <template #default="{ data: { item, loading } }">
      <b-modal
        centered
        ref="modal"
        size="lg"
        :visible="true"
        :busy="loading"
        @hidden="hide($event)"
      >
        <template v-if="item" #modal-header>
          <div v-if="item.release" class="d-flex flex-column">
            <h5 class="modal-title">
              <span class="mr-1">{{item.release.movie.name}}</span>
              <span>{{item.release.format.name}}</span>
            </h5>
            <div class="release-date">старт {{ $datetime.formatDate(item.release.movie.dateStart, 'D MMMM YYYY') }}</div>
          </div>
          <button type="button" aria-label="Close" class="close" @click="$refs.modal.hide()">×</button>
        </template>

        <template v-if="item" #default>
          <div class="mb-3">
            Дата начала проката {{ $datetime.formatDate(item.dateStart, 'DD.MM.YYYY') }} - Дата конца проката {{ $datetime.formatDate(item.dateEnd, 'DD.MM.YYYY') }}
          </div>
          <repertory-item-dates
            :item="item"
            :loading="loading"
            @change="saveDates($event)"
          ></repertory-item-dates>
        </template>

        <template v-if="item" #modal-footer>
          <div class="d-flex justify-content-between flex-fill">
            <b-button
              variant="outline-danger"
              size="sm"
              :disabled="loading"
              @click="remove()"
            >Удалить</b-button>
          </div>
          <repertory-item-statuses-dropdown
            :value="item.repertoryItemStatusId"
            :disabled="loading"
            @change="setStatus($event)"
          ></repertory-item-statuses-dropdown>
        </template>
      </b-modal>
    </template>
  </model-provider>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import ModelProvider from '../../providers/ModelProvider';

import RepertoryItemStatusesDropdown from './RepertoryItemStatusesDropdown.vue';
import RepertoryItemDates from './RepertoryItemDates.vue';

export default {
  components: {
    ModelProvider,
    RepertoryItemStatusesDropdown,
    RepertoryItemDates,
  },
  props: {
    id: {},
  },
  methods: {
    hide(evt) {
      evt.preventDefault();
      this.$emit('hide');
      this.$emit('saved');
    },

    async remove() {
      if (await confirmDialog('Удалить?')) {
        await this.$refs.model.remove();
        this.$emit('removed');
        this.$refs.modal.hide();
      }
    },

    setStatus(value) {
      this.$refs.model.setValue('repertoryItemStatusId', value);
      this.$refs.model.save();
    },

    saveDates({
      dateStart, dateEnd, weeks, count,
    }) {
      this.$refs.model.setValue('dateStart', dateStart);
      this.$refs.model.setValue('dateEnd', dateEnd);
      this.$refs.model.setValue('weeks', weeks);
      this.$refs.model.setValue('count', count);
      this.$refs.model.save();
    },
  },
};
</script>

<style scoped>
.release-date {
  font-size: 12px;
}
</style>
