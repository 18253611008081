<template>
  <cinema-page
    route-name="schedule"
    route-param-name="cinemaId"
    @loaded="changeCinema($event)"
  >
    <template #fixed="{ cinemaId, cinema, cinemas, halls }">
      <multi-view :scroll-side="false">
        <template #side>
          <releases
            :cinema-id="cinemaId"
            :date="date"
            :dateStart="dateStart"
            :dateEnd="dateEnd"
            :shows="shows"
            :halls="halls"
            @mouseover="highlightOn($event)"
            @mouseleave="highlightOff($event)"
          ></releases>
        </template>

        <template #panel>
          <buttons
            :schedule-type="scheduleType"
            :intersections="intersections"
            :halls="halls"
            @change-schedule-type="setScheduleType($event)"
            @export-shows="openExportShowsModal()"
            @open-sales="openSales()"
            @clear-shows="clearShows()"
            @copy-shows="openCopyShowsModal()"
          ></buttons>
        </template>

        <template #subpanel>
          <calendar>
            <calendar-header>
              <template #left>
                <b-button-group
                  v-if="!isScheduleTypeDefault"
                  class="ml-2 mr-1"
                >
                  <b-button
                    size="sm"
                    variant="outline-info"
                    @click="decreaseMinuteWidth()"
                  >-</b-button>
                  <b-button
                    size="sm"
                    variant="outline-info"
                    @click="increaseMinuteWidth()"
                  >+</b-button>
                </b-button-group>
                <b-button-group
                  v-if="!isScheduleTypeDefault"
                  class="mr-1"
                >
                  <b-button
                    size="sm"
                    variant="outline-info"
                    @click="decreaseHallHeight()"
                  >↑</b-button>
                  <b-button
                    size="sm"
                    variant="outline-info"
                    @click="increaseHallHeight()"
                  >↓</b-button>
                </b-button-group>
              </template>
              <template #center>
                <calendar-picker
                  :date="isScheduleTypeDefault ? dateStart : date"
                  :calendar-type="isScheduleTypeDefault ? 'week' : 'day'"
                  @select-date="changeDate($event)"
                ></calendar-picker>
              </template>
              <template #right>
                <calendar-edit-mode-selector
                  v-if="isScheduleTypeTimeline"
                  :edit-mode="editMode"
                  @change-edit-mode="changeEditMode($event)"
                ></calendar-edit-mode-selector>
              </template>
            </calendar-header>
            <calendar-dates
              v-if="isScheduleTypeDefault"
              :dates="dates"
              :is-holiday="isHolidayInRegion"
              @clear-date="openClearDateModal($event)"
              @select-date="openTimeline($event)"
            >
            </calendar-dates>
          </calendar>
        </template>

        <template #content>
          <timeline
            v-if="isScheduleTypeTimeline"
            ref="timeline"
            :halls="halls"
            :shows="selectedDateShows"
            :gaps="selectedDateGaps"
            :disabled="loading"
            :minute-width="minuteWidth"
            :date="date"
            :get-advertisements-duration="getAdvertisementsDuration"
            :hall-height="hallHeight"
            @create-shows="createShows($event)"
            @change-shows="changeShows($event)"
          >
            <template #advertisement>
              <timeline-advertisement></timeline-advertisement>
            </template>
            <template #show="{ show, hall, gap, disabled, sorting }">
              <timeline-show
                :show="show"
                :highlight="highlight.movieId === show.release.movieId || highlight.releaseId === show.releaseId"
                :disabled="disabled"
                :selected="selectedItemId === show.id"
                @remove="removeShow(show)"
                @mouseover.native="highlightOn({ movieId: show.release.movieId, releaseId: 0 })"
                @mouseleave.native="highlightOff()"
                @toggle-locked="toggleLocked(show.id, $event)"
                @click.native="selectItem(show, hall.placesCount), detalizeShow(show.id)"
              >
              </timeline-show>
            </template>
            <template #gap="{ interval, warning }">
              <timeline-gap
                :interval="interval"
                :warning="warning"
              ></timeline-gap>
            </template>
          </timeline>
          <schedule
            v-if="isScheduleTypeDefault"
            :halls="halls"
            :shows="shows"
            :disabled="loading"
            :dates="dates"
            :is-holiday="isHolidayInRegion"
            :worktime-holiday="cinema.worktimeHoliday"
            :worktime-weekday="cinema.worktimeWeekday"
            :get-advertisements-duration="getAdvertisementsDuration"
            @copy-shows="copyShows($event)"
            @create-shows="createShows($event)"
            @clear-hall="clearHall($event)"
          >
            <template #item="{ show, isSame, hall }">
              <schedule-show
                :show="show"
                :is-same="isSame"
                :highlight="highlight.movieId === show.release.movieId || highlight.releaseId === show.releaseId"
                :selected="selectedItemId === show.id"
                @mouseover.native="highlightOn({ movieId: show.release.movieId, releaseId: 0 })"
                @mouseleave.native="highlightOff()"
                @click.native="selectItem(show, hall.placesCount), detalizeShow(show.id)"
                @contextmenu.native.prevent="openContextMenu($event, show)"
              >
              </schedule-show>
            </template>
          </schedule>

          <vue-context ref="menu">
            <li class="cursor-pointer">
              <a @click.prevent="contextMenuItem && removeShow(contextMenuItem), contextMenuItem = null">Удалить сеанс</a>
            </li>
          </vue-context>
        </template>

        <template #footer>
          <selected-show
            v-if="isScheduleTypeTimeline && selectedShow"
            :show="selectedShow"
            :placesCount="placesCount"
            :gap="selectedGap"
            @open-advertisements="openAdvertisementsModal()"
            @close="selectReset()"
          ></selected-show>
          <selected-show
            v-if="isScheduleTypeDefault && selectedSchedule"
            :show="selectedSchedule"
            :placesCount="placesCount"
            :gap="selectedGap"
            @open-advertisements="openAdvertisementsModal()"
            @close="selectReset()"
          ></selected-show>
        </template>

        <template #portal>
          <modal-export-shows-form
            v-if="modals.export.show"
            :cinema="cinema"
            :init-date-start="isScheduleTypeTimeline ? date : dateStart"
            :init-date-end="isScheduleTypeTimeline ? date : dateEnd"
            @hide="closeExportShowsModal()"
          ></modal-export-shows-form>

          <modal-clear-date
            v-if="modals.date.show"
            :date="modals.date.selected"
            :halls="halls"
            @hide="closeClearDateModal()"
          ></modal-clear-date>

          <modal-copy-shows-form
            v-if="modals.copy.show"
            :init-date-from="isScheduleTypeTimeline ? date : dateStart"
            :init-date-to="isScheduleTypeTimeline ? date : dateEnd"
            :cinema="cinema"
            :halls="halls"
            @hide="closeCopyShowsModal()"
          ></modal-copy-shows-form>

          <modal-advertisements
            v-if="modals.advertisements.show"
            :advertisements="isScheduleTypeTimeline ? selectedShow.advertisements : selectedSchedule.advertisements"
            @hide="closeAdvertisementsModal()"
          ></modal-advertisements>
        </template>
      </multi-view>
    </template>
  </cinema-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import VueContext from 'vue-context';

import { confirmDialog } from '../../utils/dialogs';

import CalendarPicker from '../../components/CalendarPicker.vue';
import CinemaPage from '../../layout/CinemaPage.vue';
import MultiView from '../../layout/MultiView.vue';
import Buttons from './Buttons.vue';
import Calendar from './Calendar.vue';
import CalendarHeader from './CalendarHeader.vue';
import CalendarDates from './CalendarDates.vue';
import CalendarEditModeSelector from './CalendarEditModeSelector.vue';
import Releases from './Releases.vue';
import Schedule from './Schedule.vue';
import ScheduleShow from './ScheduleShow.vue';
import Timeline from './Timeline.vue';
import TimelineShow from './TimelineShow.vue';
import TimelineGap from './TimelineGap.vue';
import TimelineAdvertisement from './TimelineAdvertisement.vue';
import SelectedShow from './SelectedShow.vue';
import ModalExportShowsForm from './ModalExportShowsForm.vue';
import ModalClearDate from './ModalClearDate.vue';
import ModalCopyShowsForm from './ModalCopyShowsForm.vue';
import ModalAdvertisements from './ModalAdvertisements.vue';

import { SCHEDULE_TYPE_DEFAULT, SCHEDULE_TYPE_TIMELINE } from '../../constants';

const LOCAL_STORAGE_KEY_FOR_MINUTE_WIDTH = 'minuteWidthSchedule';

const DEFAULT_MINUTE_WIDTH = 0.8;
const DEFAULT_MINUTE_WIDTH_STEP = 0.02;
const TIMELINE_HALLS_WIDTH = 110;

const DEFAULT_HALL_HEIGHT = 75;
const DEFAULT_HALL_HEIGHT_STEP = 3;

export default {
  components: {
    CinemaPage,
    MultiView,
    Buttons,
    Calendar,
    CalendarHeader,
    CalendarPicker,
    CalendarDates,
    CalendarEditModeSelector,
    Releases,
    Schedule,
    ScheduleShow,
    Timeline,
    TimelineShow,
    TimelineGap,
    TimelineAdvertisement,
    SelectedShow,
    ModalExportShowsForm,
    ModalClearDate,
    ModalCopyShowsForm,
    ModalAdvertisements,
    VueContext,
  },
  data() {
    return {
      highlight: {
        movieId: 0,
        releaseId: 0,
      },
      selectedItemId: null,
      placesCount: null,
      minuteWidth: DEFAULT_MINUTE_WIDTH,
      hallHeight: DEFAULT_HALL_HEIGHT,
      regionId: null,
      selectedSchedule: null,
      contextMenuItem: null,
      modals: {
        export: {
          show: false,
          cinema: null,
        },
        date: {
          show: false,
          selected: null,
        },
        copy: {
          show: false,
        },
        advertisements: {
          show: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters('data/shows', ['selectedDateShows', 'selectedDateGaps', 'dates', 'shows', 'intersections']),
    ...mapGetters('dictionaries/holidays', ['isHoliday', 'getDay']),

    ...mapState('data/shows', {
      loading: (state) => state.loading,
      cinemaId: (state) => state.cinemaId,
      date: (state) => state.date,
      dateStart: (state) => state.dateStart,
      dateEnd: (state) => state.dateEnd,
      editMode: (state) => state.editMode,
      scheduleType: (state) => state.scheduleType,
    }),

    isScheduleTypeDefault() {
      return this.scheduleType === SCHEDULE_TYPE_DEFAULT;
    },

    isScheduleTypeTimeline() {
      return this.scheduleType === SCHEDULE_TYPE_TIMELINE;
    },

    paramsComposition() {
      return [this.cinemaId, this.dateStart, this.dateEnd].join('');
    },

    selectedShow() {
      return this.selectedDateShows.find((_show) => _show.id === this.selectedItemId);
    },

    selectedGap() {
      return this.selectedDateGaps.find((_gap) => _gap.id === this.selectedItemId);
    },
  },
  watch: {
    scheduleType() {
      if (this.isScheduleTypeTimeline) {
        this.$nextTick(this.loadMinuteWidth);
      }
    },

    paramsComposition() {
      this.fetchAdvertisements();
    },
  },
  async created() {
    await this.initScheduleType();
    this.initDates();
    this.fetchHolidays();
    this.fetchAdvertisements();
  },
  destroyed() {
    this.resetShows();
    this.resetDates();
  },
  methods: {
    getAdvertisementsDuration(hallId, date, time, release) {
      const { id: releaseId, formatId, movie: { ageLimit } } = release;
      const day = this.getDay(this.regionId, date);

      return this.$store.getters['data/advertisements/getAdvertisementsDuration'](this.cinemaId, hallId, date, day, time, releaseId, formatId, ageLimit);
    },

    increaseMinuteWidth() {
      this.minuteWidth += DEFAULT_MINUTE_WIDTH_STEP;
      this.saveMinuteWidth();
    },

    decreaseMinuteWidth() {
      if (this.minuteWidth > DEFAULT_MINUTE_WIDTH_STEP) {
        this.minuteWidth -= DEFAULT_MINUTE_WIDTH_STEP;
        this.saveMinuteWidth();
      }
    },

    increaseHallHeight() {
      this.hallHeight += DEFAULT_HALL_HEIGHT_STEP;
    },

    decreaseHallHeight() {
      if (this.hallHeight > DEFAULT_HALL_HEIGHT_STEP) {
        this.hallHeight -= DEFAULT_HALL_HEIGHT_STEP;
      }
    },

    loadMinuteWidth() {
      const minuteWidth = localStorage.getItem(LOCAL_STORAGE_KEY_FOR_MINUTE_WIDTH);

      if (minuteWidth) {
        this.minuteWidth = parseFloat(minuteWidth);
      } else {
        this.minuteWidth = this.calculateMinuteWidth();
      }
    },

    saveMinuteWidth() {
      localStorage.setItem(LOCAL_STORAGE_KEY_FOR_MINUTE_WIDTH, this.minuteWidth);
    },

    calculateMinuteWidth() {
      if (!this.$refs.timeline) {
        return DEFAULT_MINUTE_WIDTH;
      }

      const rect = this.$refs.timeline.$el.getBoundingClientRect();

      return (rect.width - TIMELINE_HALLS_WIDTH) / 1440;
    },

    fetchHolidays() {
      this.$store.dispatch('dictionaries/holidays/fetchItems');
    },

    isHolidayInRegion(date) {
      return this.isHoliday(this.regionId, date);
    },

    async changeCinema(cinema) {
      this.regionId = cinema && cinema.city && cinema.city.regionId;

      await this.$store.dispatch('data/shows/fetchCinemaShows', cinema.id);

      this.$nextTick(this.loadMinuteWidth);
    },

    selectItem(show, placesCount) {
      if (this.selectedItemId === show.id) {
        this.selectReset();
      } else {
        this.selectedItemId = show.id;
        this.placesCount = placesCount;
        this.selectedSchedule = show;
        this.$store.dispatch('data/shows/changeDate', new Date(show.date));
      }
    },

    selectReset() {
      this.selectedItemId = null;
      this.placesCount = null;
      this.selectedSchedule = null;
    },

    async initDates() {
      await this.$store.dispatch('data/shows/initDates');
    },

    async initScheduleType() {
      await this.$store.dispatch('data/shows/initScheduleType');
    },

    async resetDates() {
      this.$store.dispatch('data/shows/resetDates');
    },

    async resetShows() {
      this.$store.dispatch('data/shows/resetItems');
    },

    setScheduleType(value) {
      this.$store.commit('data/shows/setScheduleType', value);
    },

    changeEditMode(value) {
      this.$store.commit('data/shows/setEditMode', value);
    },

    async changeDate(date) {
      if (this.isScheduleTypeTimeline) {
        await this.$store.dispatch('data/shows/changeDate', date);
      }

      if (this.isScheduleTypeDefault) {
        await this.$store.dispatch('data/shows/changeDateStart', date);
      }
    },

    openTimeline(date) {
      this.$store.commit('data/shows/setScheduleType', SCHEDULE_TYPE_TIMELINE);
      this.$store.dispatch('data/shows/changeDate', date);
    },

    openClearDateModal(date) {
      this.modals.date.show = true;
      this.modals.date.selected = date;
    },

    closeClearDateModal() {
      this.modals.date.show = false;
    },

    openCopyShowsModal() {
      this.modals.copy.show = true;
    },

    closeCopyShowsModal() {
      this.modals.copy.show = false;
    },

    openAdvertisementsModal() {
      this.modals.advertisements.show = true;
    },

    closeAdvertisementsModal() {
      this.modals.advertisements.show = false;
    },

    async clearShows() {
      if (await confirmDialog('Очистить расписание?')) {
        this.$store.dispatch('data/shows/clearAll');
      }
    },

    async clearHall(hallId) {
      if (await confirmDialog('Очистить зал?')) {
        this.$store.dispatch('data/shows/clearHall', hallId);
      }
    },

    async createShows(shows) {
      const [err] = await this.$store.dispatch('data/shows/createItems', shows);

      if (err) {
        if (err.status && err.status === 422) {
          this.$notify({
            type: 'error',
            text: err.data.error,
          });
        }
      }
    },

    changeShows(shows) {
      this.$store.dispatch('data/shows/updateItems', shows);
    },

    copyShows({
      hallIdFrom, hallIdTo, dateFrom, dateTo, hallIdsFrom, cinemaIdsTo,
    }) {
      this.$store.dispatch('data/shows/copyShows', {
        hallIdFrom, hallIdTo, dateFrom, dateTo, hallIdsFrom, cinemaIdsTo,
      });
    },

    async removeShow(show) {
      this.$store.dispatch('data/shows/removeItems', [show]);
    },

    highlightOn: debounce(function ({ movieId, releaseId }) {
      this.highlight.movieId = movieId;
      this.highlight.releaseId = releaseId;
    }, 100),

    highlightOff: debounce(function () {
      this.highlight.movieId = 0;
      this.highlight.releaseId = 0;
    }, 100),

    toggleLocked(showId, locked) {
      this.$store.dispatch('data/shows/toggleLocked', { showId, locked });
    },

    openExportShowsModal() {
      this.modals.export.show = true;
    },

    closeExportShowsModal() {
      this.modals.export.show = false;
    },

    openSales() {
      this.$store.commit('data/sales/setDate', this.date);
      this.$router.push({ name: 'sales', params: { cinemaId: this.cinemaId } });
    },

    fetchAdvertisements() {
      if (!this.cinemaId || !this.dateStart || !this.dateEnd) {
        return;
      }

      this.$store.dispatch('data/advertisements/setFilter', {
        field: 'cinemaId',
        value: this.cinemaId,
      });

      this.$store.dispatch('data/advertisements/setFilter', {
        field: 'dateStart',
        value: this.$datetime.convertDateToDbFormat(this.dateStart),
      });

      this.$store.dispatch('data/advertisements/setFilter', {
        field: 'dateEnd',
        value: this.$datetime.convertDateToDbFormat(this.dateEnd),
      });

      this.$store.dispatch('data/advertisements/setFilter', {
        field: 'mode',
        value: 'weekly',
      });

      this.$store.dispatch('data/advertisements/fetchItems');
    },

    detalizeShow(showId) {
      this.$store.dispatch('data/shows/detalizeItem', showId);
    },

    openContextMenu($event, show) {
      if (this.disabled || show.locked || show.ticketsCount || (show.appGroups && show.appGroups.length)) {
        return;
      }
      this.contextMenuItem = show;
      this.$refs.menu.open($event);
    },
  },
};
</script>
