<template>
  <div>
    <b-dropdown
      class="mb-4 type"
      size="lg"
      variant="link"
      toggle-class="text-decoration-none"
    >
      <template #button-content>
        <h2 class="d-inline m-0 p-0">{{ type === 'count' ? 'Доля рынка по билетам' : 'Доля рынка по валу' }}</h2>
      </template>
      <b-dropdown-item @click="type = 'count'">Доля рынка по билетам</b-dropdown-item>
      <b-dropdown-item @click="type = 'price'">Доля рынка по валу</b-dropdown-item>
    </b-dropdown>

    <b-row class="mb-4">
      <b-col cols="9">
        <chart-line
          :aspect-ratio="5"
          :y-min="0"
          y-label="%"
          :y-max="100"
          :y-step="10"
          :line-width="1.5"
          :bold-line-index="0"
          :x-ticks-auto="true"
          :data="percents"
          :periods="formattedPeriods"
          :colors="checkedCinemasColors"
        ></chart-line>
      </b-col>
      <b-col>
        <div v-for="cinemaId in cinemaIds" :key="cinemaId">
          <b-checkbox
            :checked="checkedCinemaIds.includes(cinemaId)"
            :disabled="cinemaId === currentCinemaId"
            @change="toggleCheckedCinemaId(cinemaId)"
          >
            <div class="d-flex align-items-center">
              <div
                class="mark mr-1"
                :style="{'background-color': cinemaColors[cinemaId]}"
              ></div>
              <div :class="{ 'font-weight-bold': cinemaId === currentCinemaId }">{{ getCinemaName(cinemaId) }}</div>
            </div>
          </b-checkbox>
        </div>
      </b-col>
    </b-row>

    <b-table
      class="datatable border"
      :bordered="true"
      :fields="fields"
      :items="items"
      :striped="true"
      :hover="false"
      :show-empty="true"
      :empty-text="loading ? 'Загрузка...' : 'Нет данных'"
    >
      <template #cell(name)="data">
        <div :class="{ 'font-weight-bold': data.item.cinemaId === currentCinemaId }">{{ data.value }}</div>
      </template>

      <template #cell(average)="data">
        <div
          class="text-center"
          :class="{ 'font-weight-bold': data.item.cinemaId === currentCinemaId }"
        >{{data.value}}%</div>
      </template>

      <template #cell()="data">
        <div
          class="text-center h-100 d-flex align-items-center justify-content-center"
          :class="{ 'font-weight-bold': data.item.cinemaId === currentCinemaId }"
          :style="{'background-color': levels[getLevel(data.index, data.unformatted)] }"
        >{{data.value}}%</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import colors from '../../../utils/colors';
import ChartLine from '../../../components/charts/ChartLine.vue';

export default {
  components: {
    ChartLine,
  },
  props: {
    loading: {
      default: false,
    },
    currentCinema: {
      default: () => ({}),
    },
    marketData: {
      default: () => ({}),
    },
    cinemasData: {
      default: () => ({}),
    },
    rentrakCinemas: {
      default: () => [],
    },
    periods: {
      default: () => [],
    },
    formattedPeriods: {
      default: () => [],
    },
    referencePeriod: {
      default: null,
    },
  },
  data() {
    return {
      type: 'count',
      checkedCinemaIds: [],
      levels: ['#ea9999', '#f4cccc', '#ffffff', '#d9ead3', '#b6d7a8'],
    };
  },
  computed: {
    cinemaIds() {
      if (!this.currentCinema || !this.currentCinema.id) {
        return [];
      }

      return [this.currentCinema.id, ...(this.currentCinema.relatedCinemas || [])];
    },

    currentCinemaId() {
      if (!this.currentCinema || !this.currentCinema.id) {
        return null;
      }

      return this.currentCinema.id;
    },

    currentMarketData() {
      return this.marketData[this.currentCinemaId] || [];
    },

    fields() {
      return [
        {
          key: 'name',
          label: '',
          filterable: false,
          sortable: false,
        },
        ...this.formattedPeriods.map((period) => ({
          key: period,
          label: period,
          filterable: false,
          sortable: true,
          class: 'p-0 h-100',
        })),
        {
          key: 'average',
          label: 'Среднее за 90 дней',
          filterable: false,
          sortable: true,
        },
      ];
    },

    cinemaColors() {
      const convertedColors = colors.map((color) => `#${color.toString(16).padStart(6, '0')}`);

      const cinemaColors = {};

      for (let i = 0; i < this.cinemaIds.length; i += 1) {
        const cinemaId = this.cinemaIds[i];

        cinemaColors[cinemaId] = convertedColors[i];
      }

      return cinemaColors;
    },

    checkedCinemasColors() {
      return this.checkedCinemaIds.map((cinemaId) => this.cinemaColors[cinemaId]);
    },

    items() {
      return this.checkedCinemaIds
        .map((cinemaId) => {
          const row = {
            cinemaId,
            name: this.getCinemaName(cinemaId),
          };

          for (let i = 0; i < this.periods.length; i += 1) {
            row[this.formattedPeriods[i]] = this.getValue(cinemaId, this.periods[i]);
          }

          row.average = this.getValue(cinemaId, this.referencePeriod);

          return row;
        });
    },

    percents() {
      return this.checkedCinemaIds
        .map((cinemaId) => this.periods.map((period) => this.getValue(cinemaId, period)));
    },

    thresholds() {
      const levelsCount = this.levels.length;

      return this.percents.map((percents) => {
        const thresholds = [];
        const min = Math.min(...percents);
        const step = (Math.max(...percents) - min) / levelsCount;

        for (let i = 0; i < levelsCount; i += 1) {
          thresholds.push(min + step * (i + 1));
        }

        return thresholds;
      });
    },
  },
  watch: {
    cinemaIds: {
      handler() {
        this.checkedCinemaIds = [...this.cinemaIds];
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getCinemaName(cinemaId) {
      const rentrakCinema = this.rentrakCinemas.find((_rentrakCinema) => _rentrakCinema.id === cinemaId);

      return rentrakCinema ? rentrakCinema.name : '';
    },

    toggleCheckedCinemaId(cinemaId) {
      const index = this.checkedCinemaIds.indexOf(cinemaId);

      if (index > -1) {
        this.checkedCinemaIds.splice(index, 1);
      } else {
        this.checkedCinemaIds.push(cinemaId);
      }
    },

    getValue(cinemaId, period) {
      const marketData = this.currentMarketData.find((_data) => _data.dateStart === period.dateStart && _data.dateEnd === period.dateEnd);
      const cinemaData = this.cinemasData[cinemaId].find((_data) => _data.dateStart === period.dateStart && _data.dateEnd === period.dateEnd);

      const cinemaValue = cinemaData ? +cinemaData[this.type] : 0;
      const marketValue = marketData ? +marketData[this.type] : 0;

      return marketValue ? ((cinemaValue / marketValue) * 100).toFixed(1) : 0;
    },

    getLevel(index, value) {
      return this.thresholds[index].findIndex((threshold) => value <= threshold);
    },
  },
};
</script>

<style scoped>
.datatable {
  height: 1px;
}
.datatable >>> tr, .datatable >>> td {
  height: 100%;
}
.datatable >>> th {
  text-align: center !important;
}
.type >>> .dropdown-toggle {
  margin: 0 !important;
  padding: 0 !important;
}
.mark {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
}
.table-bordered >>> th, .table-bordered >>> td {
  border: 1px solid #dee2e6 !important;
}
.font-weight-bold {
  font-weight: 500 !important;
}
</style>
