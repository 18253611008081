<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Наименование:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        type="text"
        autofocus
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Тип:"
      :invalid-feedback="errors.type"
      :state="states.type"
    >
      <b-form-select
        size="sm"
        :value="item.type"
        :options="types"
        :disabled="loading"
        @change="changeType($event)"
      ></b-form-select>
    </b-form-group>

    <template
      v-if="item.type === BANNER_TYPE_ADV_PROMOTION"
    >
      <b-form-group
        label="Дата начала и окончания показа:"
        :invalid-feedback="errors.dateEnd || errors.dateStart"
        :state="states.dateEnd || states.dateStart"
      >
        <date-interval
          :value="{from: item.dateStart, to: item.dateEnd}"
          :disabled="loading"
          @selected="updateDate('dateStart', $event.from), updateDate('dateEnd', $event.to)">
        </date-interval>
      </b-form-group>
      <b-form-group
        label="Акция:"
        :invalid-feedback="errors.advPromotionId"
        :state="states.advPromotionId"
      >
        <dictionary-provider
          name="advPromotions"
          :disable-fetching="!item.dateStart || !item.dateEnd"
          :filter="{
          cinemaIds: this.params.cinemaIds ? this.params.cinemaIds : '',
          active: true,
          dateStartLte: this.item.dateStart,
          dateEndGte: this.item.dateEnd,
        }"
        >
          <template #default="{ items: advPromotions, loading: advPromotionsLoading }">
            <toggle-list
              :options="advPromotions"
              :value="item.advPromotionId ? [convertToValue(item.advPromotionId, advPromotions)] : []"
              :searchable="true"
              :disabled="loading || advPromotionsLoading || !item.dateStart || !item.dateEnd"
              :multiple="false"
              :hide-selected="false"
              :close-on-select="true"
              @change="updateAdvPromotionId($event)"
            ></toggle-list>
          </template>
        </dictionary-provider>
      </b-form-group>
      <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
        <div class="align-self-end">Место показа</div>
      </div>
      <b-form-group
        label="Кинотеатры:"
        :invalid-feedback="errors.cinemas"
        :state="states.cinemas"
      >
        <dictionary-provider
          name="cinemas"
          :filter="{
            cinemaIds: filterCinemasIds ? filterCinemasIds : [],
          }"
        >
          <template #default="{ items: cinemas, loading: cinemasLoading }">
            <toggle-list
              placeholder="Все"
              :options="convertCinemas(cinemas)"
              :value="item.cinemas"
              :disabled="loading || cinemasLoading || !item.type"
              @change="updateValue('cinemas', $event)"
            ></toggle-list>
          </template>
        </dictionary-provider>
      </b-form-group>
    </template>
    <template v-else>
      <template
        v-if="item.type === BANNER_TYPE_OTHER"
      >
        <b-form-group
          label="Название ссылки:"
          :invalid-feedback="errors.urlName"
          :state="states.urlName"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.urlName"
            :state="states.urlName"
            :disabled="loading"
            @change="updateValue('urlName', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Ссылка:"
          description="Если оставить поле пустым, баннер будет некликабельным"
          :invalid-feedback="errors.url"
          :state="states.url"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.url"
            :state="states.url"
            :disabled="loading"
            placeholder="https://example.com/"
            @change="updateValue('url', $event)"
          ></b-form-input>
        </b-form-group>
      </template>

      <b-form-group
        v-if="item.type === BANNER_TYPE_MOVIE"
        label="Фильм:"
        :invalid-feedback="errors.movieId"
        :state="states.movieId"
      >
        <toggle-list
          :options="movies"
          :value="item.movieId ? [convertToValue(item.movieId, movies)] : []"
          :searchable="true"
          :disabled="loading"
          :multiple="false"
          :hide-selected="false"
          :close-on-select="true"
          @change="updateMovieId($event)"
        ></toggle-list>
      </b-form-group>

      <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
        <div class="align-self-end">Место показа</div>
      </div>
      <b-form-group
        label="Кинотеатры:"
        :invalid-feedback="errors.cinemas"
        :state="states.cinemas"
      >
        <dictionary-provider
          name="cinemas"
        >
          <template #default="{ items: cinemas, loading: cinemasLoading }">
            <toggle-list
              placeholder="Все"
              :options="convertCinemas(cinemas)"
              :value="item.cinemas ? item.cinemas : []"
              :disabled="loading || cinemasLoading || item.type === null || item.type === BANNER_TYPE_ADV_PROMOTION"
              @change="updateValue('cinemas', $event)"
            ></toggle-list>
          </template>
        </dictionary-provider>
      </b-form-group>

      <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
        <div class="align-self-end">Время действия</div>
      </div>
      <b-form-group
        label="Дата начала и окончания показа:"
        :invalid-feedback="errors.dateEnd || errors.dateStart"
        :state="states.dateEnd || states.dateStart"
      >
        <date-interval
          :value="{from: item.dateStart, to: item.dateEnd}"
          :disabled="loading"
          @selected="updateValue('dateStart', $event.from), updateValue('dateEnd', $event.to)">
        </date-interval>
      </b-form-group>
    </template>

    <b-form-group
      label="Время показа:"
      :invalid-feedback="errors.timeStart || errors.timeEnd"
      :state="states.timeStart || states.timeEnd"
    >
      <time-interval
        :value="{from: item.timeStart, to: item.timeEnd}"
        :disabled="loading"
        @change="updateValue('timeStart', $event.from), updateValue('timeEnd', $event.to)"
      ></time-interval>
    </b-form-group>

    <b-form-group
      label="Дни недели:"
      :invalid-feedback="errors.days"
      :state="states.days"
    >
      <toggle-list
        placeholder="Все"
        :options="days"
        :value="item.days"
        :disabled="loading"
        @change="updateValue('days', $event)"
      ></toggle-list>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group
          label="Баннер:"
          :invalid-feedback="errors.file"
          :state="states.image"
        >
          <file-uploader
            :disabled="loading"
            @change="handleFile($event, 'file')"
          ></file-uploader>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="item.file">
      <b-col>
        <image-viewer
          :image="item.file.filepath"
          @remove="updateValue('file', '')"
        ></image-viewer>
      </b-col>
    </b-row>

    <b-form-group
      label="Период ротации баннера (сек):"
      :invalid-feedback="errors.rotation"
      :state="states.rotation"
    >
      <b-form-input
        type="number"
        size="sm"
        :value="item.rotation"
        :state="states.rotation"
        :disabled="loading"
        @change="updateValue('rotation', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Рекламодатель:"
      :invalid-feedback="errors.advertiser"
      :state="states.advertiser"
    >
      <dictionary-provider name="legalEntities">
        <template #default="{ items }">
          <b-form-select
            size="sm"
            :value="item.legalEntityId"
            :options="$array.convertToOptions(items)"
            :state="states.legalEntityId"
            :disabled="loading || item.useCinemaLegalEntity"
            @change="updateValue('legalEntityId', $event)"
          ></b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group>
      <b-form-checkbox
        :checked="!!item.useCinemaLegalEntity"
        :state="states.useCinemaLegalEntity"
        :disabled="loading"
        @change="updateValue('useCinemaLegalEntity', $event)"
      >Рекламодателем является юридическое лицо кинотеатра</b-form-checkbox>
    </b-form-group>

    <b-form-group
      label="ERID:"
      :invalid-feedback="errors.erid"
      :state="states.erid"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.erid"
        :state="states.erid"
        :disabled="loading"
        @change="updateValue('erid', $event)"
      ></b-form-input>
    </b-form-group>

  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DictionaryProvider from '../../providers/DictionaryProvider';
import DateInterval from '../../components/fields/DateInterval.vue';
import TimeInterval from '../../components/fields/TimeInterval.vue';
import ToggleList from '../../components/fields/ToggleList.vue';

import FileUploader from '../../components/fields/FileUploader.vue';
import ImageViewer from '../../components/ImageViewer.vue';

import {
  FILE_UPLOAD_TYPE_CINEMA_PHOTO,
  BANNER_TYPE_MOVIE,
  BANNER_TYPE_ADV_PROMOTION,
  BANNER_TYPE_OTHER,
} from '../../constants';

export default {
  components: {
    DictionaryProvider,
    DateInterval,
    TimeInterval,
    ToggleList,
    FileUploader,
    ImageViewer,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  watch: {
    item: {
      handler(n, old) {
        if (n?.id !== old?.id) {
          if (this.item.type === BANNER_TYPE_ADV_PROMOTION) {
            this.updateFilterCinemasForAdvPromotion(this.item?.advPromotion);
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      days: [
        { id: 1, name: 'ПН' },
        { id: 2, name: 'ВТ' },
        { id: 3, name: 'СР' },
        { id: 4, name: 'ЧТ' },
        { id: 5, name: 'ПТ' },
        { id: 6, name: 'СБ' },
        { id: 7, name: 'ВС' },
      ],
      types: [
        {
          value: BANNER_TYPE_MOVIE,
          text: 'Фильм',
        },
        {
          value: BANNER_TYPE_ADV_PROMOTION,
          text: 'Акция',
        },
        {
          value: BANNER_TYPE_OTHER,
          text: 'Другое',
        },
      ],
      cinemasOptions: [],
      dateStart: null,
      dateEnd: null,
      filterCinemasIds: [],
      BANNER_TYPE_ADV_PROMOTION,
      BANNER_TYPE_MOVIE,
      BANNER_TYPE_OTHER,
    };
  },
  computed: {
    ...mapState('data/banners', {
      params: (state) => state.params,
    }),
    ...mapState('dictionaries/movies2', {
      movies: (state) => state.items,
      moviesLoading: (state) => state.loading,
    }),
  },

  created() {
    this.fetchMovies();
  },

  methods: {
    ...mapActions({
      setParam: 'data/banners/setParam',
    }),

    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    convertToValue(id, options) {
      if (!id) {
        return [];
      }

      return options.find((option) => (id === option.id));
    },

    updateMovieId($event) {
      if (!$event || !$event.length) {
        return;
      }

      const movie = $event[$event.length - 1];
      this.updateValue('movieId', movie.id);
    },

    changeType(bannerType) {
      this.updateValue('type', bannerType);

      if (bannerType === BANNER_TYPE_ADV_PROMOTION) {
        this.updateValue('cinemas', []);
      }

      this.updateValue('movieId', null);
      this.updateValue('advPromotionId', null);
      this.updateValue('url', null);
      this.updateValue('urlName', null);
    },

    updateDate(field, value) {
      if (value) {
        this.updateValue(field, value);
        this.updateValue('advPromotionId', null);
        this.updateValue('cinemas', []);

        this.updateFilterCinemasForAdvPromotion(null);
      }
    },

    updateAdvPromotionId($event) {
      if (!$event || !$event.length) {
        return;
      }

      const advPromotion = $event[$event.length - 1];
      this.updateFilterCinemasForAdvPromotion(advPromotion);
      this.updateValue('advPromotionId', advPromotion.id);
    },

    updateFilterCinemasForAdvPromotion(advPromotion) {
      this.filterCinemasIds = [];

      if (!advPromotion || !advPromotion.cinemas) {
        return;
      }

      this.filterCinemasIds = advPromotion.cinemas.map((cinema) => cinema.id);
    },

    fetchMovies() {
      this.$store.dispatch('dictionaries/movies2/fetchItems');
    },

    async handleFile(files, field) {
      const type = FILE_UPLOAD_TYPE_CINEMA_PHOTO;
      const fileId = await this.$fileManager.registerAndUpload(files[0], { type });

      this.updateValue(field, {
        fileId,
      });
    },
  },
};
</script>
