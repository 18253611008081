/* eslint-disable no-restricted-syntax */
import FileSaver from 'file-saver';
import Vue from 'vue';
import datetime from '../../utils/datetime';

import showsService from '../../services/Shows';

import {
  SCHEDULE_EDIT_MODE_DAY, SCHEDULE_EDIT_MODE_WEEK, DANGER_TIME_INTERVAL_BETWEEN_SHOWS, SCHEDULE_TYPE_DEFAULT, SCHEDULE_TYPE_TIMELINE,
} from '../../constants';

const draftIdStart = new Date().getTime();
let draftId = draftIdStart;
const inProcess = {};
const SCHEDULE_DATE_KEY = 'SCHEDULE_DATE';
const SCHEDULE_TYPE_KEY = 'SCHEDULE_TYPE';
const EXPIRATION_PERIOD = 1000 * 60 * 5; // 5 минут для хранения ключей в localStorage

export default {
  namespaced: true,

  state: {
    loading: false,
    items: [],
    cinemaId: null,
    date: null,
    dateStart: null,
    dateEnd: null,
    editMode: SCHEDULE_EDIT_MODE_DAY,
    scheduleType: SCHEDULE_TYPE_TIMELINE,
    time: 0,
  },

  actions: {
    async initDates({ commit, state }) {
      if (state.date && state.dateStart && state.dateEnd) {
        return;
      }
      let date;
      const storageDate = localStorage.getItem(SCHEDULE_DATE_KEY);
      if (storageDate) {
        const dateData = JSON.parse(storageDate);
        const currentTime = new Date().getTime();

        if (currentTime < dateData.expireAt) {
          date = datetime.convertToDate(dateData.date);
        } else {
          localStorage.removeItem(SCHEDULE_DATE_KEY);
        }
      }

      if (!date) {
        date = state.date || datetime.getCurrentDate();
      }

      const { dateStart, dateEnd } = datetime.getWeekByDate(date);

      commit('setDateStart', dateStart);
      commit('setDateEnd', dateEnd);
      commit('setDate', date);
    },

    async initScheduleType({ commit, state }) {
      const storageType = localStorage.getItem(SCHEDULE_TYPE_KEY);
      if (storageType) {
        const storageData = JSON.parse(storageType);
        const currentTime = new Date().getTime();
        if (currentTime < storageData.expireAt) {
          if (state.scheduleType !== storageData.scheduleType) {
            commit('setScheduleType', storageData.scheduleType);
          }
        } else {
          localStorage.removeItem(SCHEDULE_TYPE_KEY);
        }
      }
    },

    async resetDates({ commit }) {
      commit('setDateStart', null);
      commit('setDateEnd', null);
      commit('setDate', null);
    },

    async changeDate({ commit, state, dispatch }, date) {
      if (datetime.isDateBetween(date, state.dateStart, state.dateEnd)) {
        commit('setDate', date);
        return;
      }

      const { dateStart, dateEnd } = datetime.getWeekByDate(date);

      commit('setDateStart', dateStart);
      commit('setDateEnd', dateEnd);
      commit('setDate', date);

      commit('setItems', { items: [] });

      await dispatch('fetchItems');
    },

    async changeDateStart({ commit, state, dispatch }, date) {
      const { dateStart, dateEnd } = datetime.getWeekByDate(date);

      commit('setDateStart', dateStart);
      commit('setDateEnd', dateEnd);

      if (!datetime.isDateBetween(state.date, dateStart, dateEnd)) {
        commit('setDate', dateStart);
      }

      commit('setItems', { items: [] });

      await dispatch('fetchItems');
    },

    async fetchCinemaShows({ commit, dispatch }, cinemaId) {
      commit('setItems', { items: [] });
      commit('setCinemaId', cinemaId);

      await dispatch('fetchItems');
    },

    async resetItems({ commit }) {
      commit('setItems', { items: [] });
    },

    async fetchItems({
      commit, state: {
        cinemaId, dateStart, dateEnd,
      },
    }) {
      if (!cinemaId || !dateStart || !dateEnd) {
        return;
      }

      commit('setLoading', true);

      const [, data] = await showsService.fetchShows({
        filter: {
          cinemaId,
          dateStart: datetime.convertDateToDbFormat(dateStart),
          dateEnd: datetime.convertDateToDbFormat(dateEnd),
        },
        params: {
          withAdvertisements: 1,
          withReservedPlacesCount: 1,
          withPrices: 1,
        },
      });

      if (data) {
        const { items, time } = data;

        commit('setItems', { items, time });
      }

      commit('setLoading', false);
    },

    async createItems({
      commit, dispatch, state, getters,
    }, items) {
      if (!items.length) {
        return [null, null];
      }

      commit('setLoading', true);

      const shows = items.map((item) => ({
        // eslint-disable-next-line no-plusplus
        id: draftId++,
        ...item,
      }));

      commit('addItems', shows);

      let dates = [];

      if (state.scheduleType === SCHEDULE_TYPE_TIMELINE && state.editMode === SCHEDULE_EDIT_MODE_WEEK) {
        dates = getters
          .getOtherDates(datetime.convertDateToDbFormat(state.date))
          .map((_date) => datetime.convertDateToDbFormat(_date));
      }

      const [err, data] = await showsService.createShows(shows, dates);

      await dispatch('fetchItems');

      commit('setLoading', false);

      return [err, true];
    },

    async removeItems({
      commit, dispatch, state, getters,
    }, shows) {
      commit('setLoading', true);

      commit('removeItems', shows);

      let dates = [];

      if (state.scheduleType === SCHEDULE_TYPE_TIMELINE && state.editMode === SCHEDULE_EDIT_MODE_WEEK) {
        dates = getters
          .getOtherDates(datetime.convertDateToDbFormat(state.date))
          .map((_date) => datetime.convertDateToDbFormat(_date));
      }

      await showsService.deleteShows(shows, dates);

      await dispatch('fetchItems');

      commit('setLoading', false);

      return [null, null];
    },

    async updateItems({
      commit, dispatch, state, getters,
    }, shows) {
      commit('setLoading', true);

      commit('patchItems', shows);

      let dates = [];

      if (state.scheduleType === SCHEDULE_TYPE_TIMELINE && state.editMode === SCHEDULE_EDIT_MODE_WEEK) {
        dates = getters
          .getOtherDates(datetime.convertDateToDbFormat(state.date))
          .map((_date) => datetime.convertDateToDbFormat(_date));
      }

      await showsService.updateShows(shows, dates);

      await dispatch('fetchItems');

      commit('setLoading', false);

      return [null, null];
    },

    async clearDate({ commit, state, dispatch }, { date, hallId }) {
      commit('setLoading', true);

      const [err, result] = await showsService.clearShows({
        cinemaId: state.cinemaId,
        hallId,
        date: datetime.convertDateToDbFormat(date),
      });

      await dispatch('fetchItems');

      commit('setLoading', false);

      return [err, result];
    },

    async clearAll({ commit, state, dispatch }) {
      commit('setLoading', true);

      const [err, result] = await showsService.clearShows({
        cinemaId: state.cinemaId,
        dateStart: datetime.convertDateToDbFormat(state.dateStart),
        dateEnd: datetime.convertDateToDbFormat(state.dateEnd),
      });

      await dispatch('fetchItems');

      commit('setLoading', false);

      return [err, result];
    },

    async clearHall({ commit, state, dispatch }, hallId) {
      commit('setLoading', true);

      const [err, result] = await showsService.clearShows({
        cinemaId: state.cinemaId,
        hallId,
        dateStart: datetime.convertDateToDbFormat(state.dateStart),
        dateEnd: datetime.convertDateToDbFormat(state.dateEnd),
      });

      await dispatch('fetchItems');

      commit('setLoading', false);

      return [err, result];
    },

    async copyShows({ commit, dispatch }, {
      hallIdFrom, hallIdTo, dateFrom, dateTo, hallIdsFrom, cinemaIdsTo,
    }) {
      commit('setLoading', true);

      const [err, result] = await showsService.copyShows({
        hallIdFrom, hallIdTo, dateFrom: datetime.convertDateToDbFormat(dateFrom), dateTo: datetime.convertDateToDbFormat(dateTo), hallIdsFrom, cinemaIdsTo,
      });

      await dispatch('fetchItems');

      commit('setLoading', false);

      return [err, result];
    },

    async toggleLocked({ commit, dispatch }, { showId, locked }) {
      commit('setLoading', true);

      const [err, result] = await showsService.toggleLocked(showId, locked);

      await dispatch('loadItem', showId);

      commit('setLoading', false);

      return [err, result];
    },

    async loadItem({ commit }, id) {
      commit('setLoading', true);

      const [err, item] = await showsService.getShow(id, {
        withAdvertisements: 1,
        withPrices: 1,
        withReservedPlacesCount: 1,
      });

      if (!err && item) {
        commit('patchItem', item);
      }

      commit('setLoading', false);

      return [err, item];
    },

    async detalizeItem({ commit, state: { items } }, id) {
      if (id >= draftIdStart) {
        return [false, false];
      }

      const item = items.find((_item) => _item.id === id);

      if (item.advertisements && item.prices) {
        return [false, item];
      }

      if (inProcess[id]) {
        return [false, null];
      }

      inProcess[id] = true;

      const [err, _item] = await showsService.getShow(id, {
        withAdvertisements: 1,
        withPrices: 1,
        withReservedPlacesCount: 1,
      });

      delete inProcess[id];

      if (!err && _item) {
        commit('detalizeItem', _item);
      }

      return [err, item];
    },

    async exportShows({ state }, {
      dateStart, dateEnd, format, filename,
    }) {
      const [err, data] = await showsService.exportShows({
        dateStart: datetime.convertDateToDbFormat(dateStart),
        dateEnd: datetime.convertDateToDbFormat(dateEnd),
        format,
        cinemaId: state.cinemaId,
      });

      if (err) {
        return [err, null];
      }

      if (!err && data) {
        if (format === 'pdf' || format === 'xlsx') {
          FileSaver.saveAs(data, filename);
        }
      }

      return [null, true];
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setItems(state, { items, time }) {
      if (state.time > time) {
        return;
      }

      state.time = time;
      state.items = items;
    },

    addItems(state, items) {
      state.items = [
        ...state.items,
        ...items,
      ];
    },

    patchItem(state, show) {
      const index = state.items.findIndex((_item) => _item.id === show.id);

      if (index !== -1) {
        state.items.splice(index, 1, show);
      }
    },

    detalizeItem(state, show) {
      const item = state.items.find((_item) => _item.id === show.id);

      Vue.set(item, 'prices', show.prices);
      Vue.set(item, 'advertisements', show.advertisements);
      Vue.set(item, 'ticketsCount', show.ticketsCount);
      Vue.set(item, 'reservedPlacesCount', show.reservedPlacesCount);
    },

    patchItems(state, shows) {
      for (const show of shows) {
        const index = state.items.findIndex((_item) => _item.id === show.id);

        if (index !== -1) {
          state.items.splice(index, 1, show);
        }
      }
    },

    removeItems(state, shows) {
      state.items = state.items.filter((show) => !shows.find((_show) => _show.id === show.id));
    },

    setCinemaId(state, cinemaId) {
      state.cinemaId = cinemaId;
    },

    setEditMode(state, value) {
      state.editMode = value;
    },

    setScheduleType(state, value) {
      state.scheduleType = value;
      const storage = {
        scheduleType: value,
        expireAt: new Date().getTime() + EXPIRATION_PERIOD,
      };
      localStorage.setItem(SCHEDULE_TYPE_KEY, JSON.stringify(storage));
    },

    setDate(state, date) {
      state.date = date;
      if (date) {
        const storage = {
          date: datetime.convertDateToDbFormat(date),
          expireAt: new Date().getTime() + EXPIRATION_PERIOD,
        };
        localStorage.setItem(SCHEDULE_DATE_KEY, JSON.stringify(storage));
      } else {
        localStorage.removeItem(SCHEDULE_DATE_KEY);
      }
    },

    setDateStart(state, date) {
      state.dateStart = date;
    },

    setDateEnd(state, date) {
      state.dateEnd = date;
    },
  },

  getters: {
    dates: (state) => datetime.getDates(state.dateStart, state.dateEnd),

    shows: (state, getters, rootState, rootGetters) => {
      const cinema = rootState.dictionaries.cinemas.items.find((_cinema) => _cinema.id === state.cinemaId);

      if (!cinema) {
        return [];
      }

      const { regionId } = cinema.city || {};

      return [...state.items]
        .sort((show1, show2) => datetime.compareTimes(show1.time, show2.time))
        .map((show) => {
          let advertisementsDuration = 0;

          if (show.advertisements && show.advertisements.length) {
            advertisementsDuration = show.advertisements.reduce((total, adv) => total + adv.duration, 0) / 60;
          } else {
            const {
              hallId, date, time, release,
            } = show;
            const { id: releaseId, formatId, movie: { ageLimit } } = release;
            const day = rootGetters['dictionaries/holidays/getDay'](regionId, datetime.convertToDate(date));

            advertisementsDuration = rootGetters['data/advertisements/getAdvertisementsDuration'](state.cinemaId, hallId, date, day, time, releaseId, formatId, ageLimit);
          }

          return {
            ...show,
            advertisementsDuration,
          };
        });
    },

    selectedDateShows: (state, getters) => getters.shows
      .filter((_show) => datetime.isSameDay(_show.date, state.date)),

    getOtherDates: (state, getters) => (date) => getters.dates.filter((_date) => datetime.isDateSameOrAfter(_date, date)),

    getHallIds: () => (shows) => shows.reduce((_hallIds, show) => {
      if (!_hallIds.includes(show.hallId)) {
        _hallIds.push(show.hallId);
      }

      return _hallIds;
    }, []),

    getSortedHallShows: () => (shows, hallId) => {
      const hallShows = shows.filter((item) => item.hallId === hallId);

      hallShows.sort((show1, show2) => datetime.compareTimes(show1.time, show2.time));

      return hallShows;
    },

    isExistShowWithSameTime: () => (shows, show) => {
      const showTimeStart = datetime.convertTimeToMoment(show.time);

      return !!shows.find((currentShow) => {
        const currentShowTimeStart = datetime.convertTimeToMoment(currentShow.time);

        return show.id !== currentShow.id && Math.abs(datetime.getDiffInMinutes(showTimeStart, currentShowTimeStart)) < DANGER_TIME_INTERVAL_BETWEEN_SHOWS;
      });
    },

    selectedDateGaps: (state, getters) => {
      const gaps = [];
      const shows = getters.selectedDateShows;

      const hallIds = getters.getHallIds(shows);

      for (const hallId of hallIds) {
        const hallShows = getters.getSortedHallShows(shows, hallId);

        for (let i = 1; i < hallShows.length; i += 1) {
          const currentShow = hallShows[i];
          const prevShow = hallShows[i - 1];

          const prevShowTimeEnd = datetime.getIntervalEndTime(prevShow.time, prevShow.release.duration + prevShow.advertisementsDuration);
          const currentShowTimeStart = currentShow.time;

          const interval = Math.ceil(datetime.getDiffInSeconds(currentShowTimeStart, prevShowTimeEnd) / 60);

          if (interval > 0) {
            gaps.push({
              id: prevShow.id,
              nextId: currentShow.id,
              time: datetime.formatTime(prevShowTimeEnd),
              interval,
              hallId,
              warning: getters.isExistShowWithSameTime(shows, currentShow),
            });
          }
        }
      }

      return gaps;
    },

    intersections: (state, getters) => {
      const intersections = [];
      const { dates, shows } = getters;

      const hallIds = getters.getHallIds(shows);

      for (const date of dates) {
        const dateShows = shows.filter((_show) => datetime.isSameDay(_show.date, date));

        for (const hallId of hallIds) {
          const hallShows = getters.getSortedHallShows(dateShows, hallId);

          for (let i = 1; i < hallShows.length; i += 1) {
            const currentShow = hallShows[i];
            const prevShow = hallShows[i - 1];

            const prevShowTimeEnd = datetime.getIntervalEndTime(prevShow.time, prevShow.release.duration + prevShow.advertisementsDuration);
            const currentShowTimeStart = currentShow.time;

            const interval = datetime.getDiffInMinutes(currentShowTimeStart, prevShowTimeEnd);

            if (interval <= 0) {
              intersections.push(currentShow);
            }
          }
        }
      }

      return intersections;
    },
  },
};
