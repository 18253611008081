<template>
  <div>
    <b-button
      variant="outline-info"
      size="sm"
      :id="id"
      :disabled="disabled"
      :pressed="pressed"
      @click="showDatepicker = !showDatepicker"
    >{{ formattedDate }}</b-button>

    <b-popover
      class="datepicker"
      placement="bottom"
      :target="id"
      :disabled="disabled"
      :show.sync="showDatepicker"
    >
      <datepicker
        ref="datepicker"
        :value="date"
        :inline="true"
        :bootstrap-styling="true"
        :monday-first="true"
        @selected="selectDate"
        v-click-outside="hideDatepicker"
      ></datepicker>
    </b-popover>
  </div>
</template>

<script>
import Datepicker from '../datepicker/Datepicker.vue';

export default {
  components: {
    Datepicker,
  },
  props: {
    date: {
      default: () => new Date(),
    },
    disabled: {
      default: false,
    },
    pressed: {
      default: false,
    },
  },
  data() {
    return {
      showDatepicker: false,
    };
  },
  computed: {
    formattedDate() {
      return this.$datetime.formatDate(this.date);
    },
    id() {
      return `select_date_${Math.random() * 1000000}`;
    },
  },
  methods: {
    hideDatepicker() {
      this.showDatepicker = false;
    },

    selectDate(date) {
      this.$emit('select', date);
      this.showDatepicker = false;
    },
  },
};
</script>

<style scoped>
.popover {
  max-width: 400px;
}
.popover  >>> .vdp-datepicker__calendar {
  border-width: 0 !important;
}
</style>
