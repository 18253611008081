<template>
  <div class="calendar-period d-flex p-1 justify-content-center">
    <span
      class="mr-1 cursor-pointer"
      @click="$emit('prev-month')"
      v-b-tooltip="'Предыдущий месяц'"
    >
      <icon icon="fa-angle-double-left"></icon>
    </span>
    <span
      class="mr-2 cursor-pointer"
      @click="$emit('prev-week')"
      v-b-tooltip="'Предыдущая неделя'"
    >
      <icon icon="fa-angle-left"></icon>
    </span>
    <div class="calendar-period__name text-center">{{periodName}}</div>
    <span
      class="ml-2 cursor-pointer"
      @click="$emit('next-week')"
      v-b-tooltip="'Следующая неделя'"
    >
      <icon icon="fa-angle-right"></icon>
    </span>
    <span
      class="ml-1 cursor-pointer"
      @click="$emit('next-month')"
      v-b-tooltip="'Следующий месяц'"
    >
      <icon icon="fa-angle-double-right"></icon>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    weeks: {
      default: () => [],
    },
    calendarType: {
      default: 'month',
    },
  },
  computed: {
    dateStart() {
      return this.weeks?.[0]?.[0].date;
    },

    dateEnd() {
      const lastWeek = this.weeks?.[this.weeks.length - 1];
      return lastWeek && lastWeek?.[lastWeek.length - 1].date;
    },

    monthStartAsText() {
      const format = { month: 'long' };

      if (this.dateStart.getYear() !== this.dateEnd.getYear()) {
        format.year = 'numeric';
      }

      const text = this.dateStart.toLocaleString('ru', format);

      return text[0].toUpperCase() + text.slice(1);
    },

    dateStartAsText() {
      const format = { day: 'numeric', month: 'long' };

      if (this.dateStart.getYear() !== this.dateEnd.getYear()) {
        format.year = 'numeric';
      }

      const text = this.dateStart.toLocaleString('ru', format);

      return text[0].toUpperCase() + text.slice(1);
    },

    dateEndAsText() {
      const text = this.dateEnd.toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' });

      return text[0].toUpperCase() + text.slice(1);
    },

    monthEndAsText() {
      const text = this.dateEnd.toLocaleString('ru', { month: 'long', year: 'numeric' });

      return text[0].toUpperCase() + text.slice(1);
    },

    periodName() {
      if (this.calendarType === 'quarter') {
        return `${this.monthStartAsText} - ${this.monthEndAsText}`;
      }

      if (this.calendarType === 'month') {
        return this.monthEndAsText;
      }

      return `${this.dateStartAsText} - ${this.dateEndAsText}`;
    },
  },
};
</script>

<style scoped>
.calendar-period {
  min-height: 25px;
}
.calendar-period__name {
  min-width: 300px;
}
</style>
