var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cinema-page',{attrs:{"route-name":"productGroups","route-param-name":"cinemaId"},scopedSlots:_vm._u([{key:"scroll",fn:function(ref){
var cinemaId = ref.cinemaId;
return [_c('div',[_c('collection-provider',{ref:"collection",attrs:{"collection":"productGroups","filter":{ cinemaId: cinemaId },"disable-fetching":!cinemaId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"item-actions":_vm.datatable.itemActions,"panel-actions":_vm.datatable.panelActions},on:{"item-action":function($event){return _vm.performItemAction($event)},"panel-action":function($event){return _vm.performPanelAction($event)}}},'datatable',Object.assign({}, data, actions),false))]}}],null,true)}),(_vm.modals.productGroup.show)?_c('modal-model-form',{attrs:{"create-title":"Создание группы для отображения","edit-title":"Редактирование группы для отображения","collection":"productGroups","id":_vm.modals.productGroup.id,"init":{ cinemaId: cinemaId }},on:{"close":function($event){_vm.modals.productGroup.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('product-group-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,true)}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }