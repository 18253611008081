<template>
  <div class="tag-info">
    <div
      class="items-index__releases"
      :id="`releases_${item.id}`"
    >{{convertReleasesToText(item.releases)}}</div>
    <b-tooltip
      v-if="item.releases && item.releases.length"
      :target="`releases_${item.id}`"
    >
      <div
        class="mb-1"
        v-for="release in item.releases"
        :key="release.id"
      >
        <div>{{ release.movie.name }}, {{ release.format.name }}</div>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: () => ({}),
    },
  },
  methods: {
    convertReleasesToText(releases) {
      if (!releases.length) {
        return 'Все фильмы';
      }

      return releases.map((release) => `${release?.movie?.name} ${release?.format?.name}`).join(', ');
    },
  },
};
</script>

<style scoped>
.tag-info {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}
</style>
