var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dictionary-provider',{ref:"collection",attrs:{"name":"cinemas"},on:{"loaded":function($event){return _vm.onCollectionLoaded()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cinemas = ref.items;
var loading = ref.loading;
return [(_vm.cinemaId)?_c('model-provider',{ref:"cinema",attrs:{"collection":"cinemas","id":_vm.cinemaId},on:{"notfound":function($event){return _vm.openFirstCinema()},"loaded":function($event){return _vm.onCinemaLoaded($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_data = ref.data;
var loading = ref_data.loading;
var cinema = ref_data.item;
return [_vm._t("default",null,{"cinemaId":cinema && cinema.id,"cinema":cinema,"cinemas":cinemas,"loading":loading,"halls":cinema && cinema.halls,"regionId":cinema && cinema.city && cinema.city.regionId,"openCinema":_vm.openCinema})]}}],null,true)}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }