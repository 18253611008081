<template>
  <form
    class="filter d-flex"
    @submit.prevent="handleSubmit"
  >
    <filter-datepicker
      class="filter__date mr-2"
      :disabled="loading"
      :value="filter.dateStartGte"
      :formatOutput="'YYYY-MM-DD'"
      :clearButton="false"
      @selected="changeFilterDates($event, 2, 'next')"
    ></filter-datepicker>

    <filter-datepicker
      class="filter__date mr-2"
      :disabled="loading"
      :value="filter.dateStartLt"
      :formatOutput="'YYYY-MM-DD'"
      :clearButton="false"
      @selected="changeFilterDates($event, 2, 'prev')"
    ></filter-datepicker>

    <b-input
      class="filter__date mr-2"
      placeholder="Поиск..."
      size="sm"
      :disabled="loading"
      :value="filter.name"
      @change="setFilter({ field: 'name', value: $event })"
    ></b-input>

    <dictionary-provider name="distributors2">
      <template #default="{ items }">
        <toggle-list
          class="filter__item short-tag mr-2"
          placeholder="Все дистрибьюторы"
          :options="items"
          :searchable="true"
          :hideSelected="false"
          :limit="1"
          :disabled="loading"
          :value="convertToValue(filter.distributorIds, items)"
          @change="setFilter({ field: 'distributorIds', value: $event.map(item => item.id) })"
        ></toggle-list>
      </template>
    </dictionary-provider>

    <dictionary-provider name="formats2">
      <template #default="{ items }">
        <toggle-list
          class="filter__item mr-2"
          placeholder="Все форматы"
          :options="items"
          :searchable="true"
          :hideSelected="false"
          :limit="1"
          :disabled="loading"
          :value="convertToValue(filter.formatIds, items)"
          @change="setFilter({ field: 'formatIds', value: $event.map(item => item.id) })"
        ></toggle-list>
      </template>
    </dictionary-provider>

    <filter-dropdown
      class="mr-2"
      all-text="Все фильмы"
      :items="countries"
      :disabled="loading"
      :value="filter.russian"
      @change="setFilter({ field: 'russian', value: $event })"
    ></filter-dropdown>

    <b-button
      class="mr-2"
      variant="info"
      size="sm"
      type="submit"
      :disabled="loading"
    >Применить</b-button>

    <b-button
      class="text-nowrap"
      size="sm"
      variant="outline-danger"
      :disabled="loading"
      @click="handleReset"
    >Сбросить</b-button>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import DictionaryProvider from '../../providers/DictionaryProvider';
import FilterDatepicker from '../../components/fields/Date.vue';
import ToggleList from '../../components/fields/ToggleList.vue';
import FilterDropdown from '../../components/filters/Dropdown.vue';

import datetime from '../../utils/datetime';

export default {
  components: {
    DictionaryProvider,
    FilterDatepicker,
    ToggleList,
    FilterDropdown,
  },
  data() {
    return {
      countries: [
        {
          id: '1',
          name: 'Российские фильмы',
        },
        {
          id: '0',
          name: 'Зарубежные фильмы',
        },
      ],
    };
  },
  computed: {
    ...mapState('data/movies2', {
      loading: (state) => state.loading,
      filter: (state) => state.filter,
    }),
  },
  methods: {
    ...mapActions({
      setFilter: 'data/movies2/setFilter',
      fetchItems: 'data/movies2/fetchItems',
      resetFilter: 'data/movies2/resetFilter',
      resetItems: 'data/movies2/resetItems',
    }),

    async changeFilterDates(date, count, type) {
      const { dateStart: dateStartGte, dateEnd: dateStartLt } = datetime.getMonthByDate(date, count, type);

      this.setFilter({ field: 'dateStartGte', value: dateStartGte });
      this.setFilter({ field: 'dateStartLt', value: dateStartLt });

      await this.fetchItems();
    },

    setFilterDates() {
      const { dateStart: dateStartGte, dateEnd: dateStartLt } = datetime.getMonthByDate(new Date(), 2);

      this.setFilter({ field: 'dateStartGte', value: dateStartGte });
      this.setFilter({ field: 'dateStartLt', value: dateStartLt });
    },

    convertToValue(ids, options) {
      if (!ids) {
        return [];
      }

      // eslint-disable-next-line no-bitwise
      return options.filter((option) => !!~ids.indexOf(option.id));
    },

    async handleSubmit() {
      if (this.filter.name && this.filter.name.length !== undefined) {
        this.setFilter({ field: 'dateStartGte', value: '' });
        this.setFilter({ field: 'dateStartLt', value: '' });
      }

      await this.fetchItems();
    },

    handleReset() {
      this.resetFilter();
      this.resetItems();
      this.setFilterDates();
      this.handleSubmit();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  &__date {
    max-width: 160px;
  }
  &__item {
    position: relative;
    width: auto;
  }
}
</style>
