export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Акция',
    filterable: true,
    sortable: true,
  },
  {
    key: 'date',
    type: 'date',
    label: 'Период действия',
    filterable: true,
    sortable: true,
  },
  {
    key: 'settings',
    type: 'text',
    label: 'Настройки',
    sortable: false,
    filterable: false,
  },
  {
    key: 'enabled',
    type: 'select',
    label: 'Статус',
    sortable: true,
    filterable: true,
  },
  {
    key: 'createdAt',
    type: 'date',
    label: 'Дата создания',
    filterable: false,
    sortable: true,
  },
  {
    key: 'updatedAt',
    type: 'date',
    label: 'Дата изменения',
    filterable: false,
    sortable: true,
  },
];
