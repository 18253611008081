<template>
  <div class="h-100 d-flex flex-column">
    <div class="d-flex border-bottom shadow-sm p-2">
      <index-tabs
        :banner-type-id="bannerTypeId"
        @select="setBannerTypeId($event)"
      />
    </div>
    <scroll>
      <banners
        v-if="!bannerTypeId"
        ref="collection"
      />
      <banner-positions
        v-if="bannerTypeId"
        ref="collection"
      />
    </scroll>
  </div>
</template>

<script>
import IndexTabs from './IndexTabs.vue';
import Banners from './Banners.vue';
import BannerPositions from './BannerPositions.vue';

export default {
  components: {
    IndexTabs,
    Banners,
    BannerPositions,
  },
  data() {
    return {
      bannerTypeId: 0,
    };
  },
  methods: {
    setBannerTypeId(value) {
      this.bannerTypeId = value;
      this.$refs.collection.$children[0].reset();
    },
  },
};
</script>
