<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="От:"
          :invalid-feedback="errors.dateStart"
          :state="states.dateStart"
        >
          <date
            size="sm"
            format-output="YYYY-MM-DD"
            :value="item.dateStart"
            :state="states.dateStart"
            :disabled="loading"
            @selected="updateValue('dateStart', $event)"
          ></date>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="До:"
          :invalid-feedback="errors.dateEnd"
          :state="states.dateEnd"
        >
          <date
            size="sm"
            format-output="YYYY-MM-DD"
            :value="item.dateEnd"
            :state="states.dateEnd"
            :disabled="loading"
            @selected="updateValue('dateEnd', $event)"
          ></date>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Вал по билетам:"
          :invalid-feedback="errors.ticketsPrice"
          :state="states.ticketsPrice"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.ticketsPrice"
            :state="states.ticketsPrice"
            :disabled="loading"
            @change="updateValue('ticketsPrice', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Вал по бару:"
          :invalid-feedback="errors.productsPrice"
          :state="states.productsPrice"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.productsPrice"
            :state="states.productsPrice"
            :disabled="loading"
            @change="updateValue('productsPrice', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Число билетов:"
          :invalid-feedback="errors.ticketsCount"
          :state="states.ticketsCount"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.ticketsCount"
            :state="states.ticketsCount"
            :disabled="loading"
            @change="updateValue('ticketsCount', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Число продаж по бару:"
          :invalid-feedback="errors.productsCount"
          :state="states.productsCount"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.productsCount"
            :state="states.productsCount"
            :disabled="loading"
            @change="updateValue('productsCount', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Доля рынка по валу кино:"
          :invalid-feedback="errors.ticketsPricePercent"
          :state="states.ticketsPricePercent"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.ticketsPricePercent"
            :state="states.ticketsPricePercent"
            :disabled="loading"
            @change="updateValue('ticketsPricePercent', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Доля рынка по зрителям:"
          :invalid-feedback="errors.ticketsCountPercent"
          :state="states.ticketsCountPercent"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.ticketsCountPercent"
            :state="states.ticketsCountPercent"
            :disabled="loading"
            @change="updateValue('ticketsCountPercent', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="text-uppercase mb-3 mt-2">Дневные коэффициенты</div>

    <b-row>
      <b-col>
        <b-form-group
          label="Понедельник:"
          :invalid-feedback="errors.ratioMonday"
          :state="states.ratioMonday"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.ratioMonday"
            :state="states.ratioMonday"
            :disabled="loading"
            @change="updateValue('ratioMonday', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Пятница:"
          :invalid-feedback="errors.ratioFriday"
          :state="states.ratioFriday"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.ratioFriday"
            :state="states.ratioFriday"
            :disabled="loading"
            @change="updateValue('ratioFriday', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Вторник:"
          :invalid-feedback="errors.ratioTuesday"
          :state="states.ratioTuesday"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.ratioTuesday"
            :state="states.ratioTuesday"
            :disabled="loading"
            @change="updateValue('ratioTuesday', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Суббота:"
          :invalid-feedback="errors.ratioSaturday"
          :state="states.ratioSaturday"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.ratioSaturday"
            :state="states.ratioSaturday"
            :disabled="loading"
            @change="updateValue('ratioSaturday', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Среда:"
          :invalid-feedback="errors.ratioWednesday"
          :state="states.ratioWednesday"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.ratioWednesday"
            :state="states.ratioWednesday"
            :disabled="loading"
            @change="updateValue('ratioWednesday', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Воскресенье:"
          :invalid-feedback="errors.ratioSunday"
          :state="states.ratioSunday"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.ratioSunday"
            :state="states.ratioSunday"
            :disabled="loading"
            @change="updateValue('ratioSunday', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Четверг:"
          :invalid-feedback="errors.ratioThursday"
          :state="states.ratioThursday"
        >
          <b-form-input
            type="number"
            size="sm"
            :value="item.ratioThursday"
            :state="states.ratioThursday"
            :disabled="loading"
            @change="updateValue('ratioThursday', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Date from '../../components/fields/Date.vue';

export default {
  components: {
    Date,
  },
  props: {
    cinemaId: {
      default: null,
    },
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
