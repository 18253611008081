<template>
  <div class="schedule-hall">
    <div
      class="schedule-hall__header d-flex pl-2 pr-2 pt-1 pb-1 cursor-pointer"
      @click="$emit('toggle-hall')"
    >
      <div class="schedule-hall__name mr-2">Зал {{hall.name}}</div>
      <div class="schedule-hall__formats mr-auto">{{$array.convertToText(hall.formats)}}</div>
      <a
        class="schedule-hall__clear"
        @click.stop="$emit('clear-hall', hall.id)"
      >Очистить зал</a>
      <div class="ml-1">{{hall.placesCount}} мест</div>
    </div>
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  props: {
    hall: {
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.schedule-hall__header {
  background-color: #eee;
  border-bottom: 1px solid #ccc;
}
.schedule-hall__name {
  font-weight: 400;
}
.schedule-hall__formats {
  color: #aaa;
}
.schedule-hall__clear {
  color: blue;
  cursor: pointer;
  opacity: 0.5;
}
.schedule-hall__clear:hover {
  opacity: 1;
}
</style>
