<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Дистрибьютор:"
          :invalid-feedback="errors.distributorId"
          :state="states.distributorId"
        >
          <dictionary-provider name="distributors2">
            <template #default="{ items: distributors, loading: distributorsLoading }">
              <toggle-list
                :options="distributors"
                :value="item.distributor ? [item.distributor] : []"
                :searchable="true"
                :disabled="loading || distributorsLoading"
                :multiple="false"
                :hide-selected="false"
                :close-on-select="true"
                @change="updateDistributor($event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>
        <b-form-group
          label="Адреса электронной почты:"
          :invalid-feedback="errors.emails"
          :state="states.emails"
        >
          <b-form-textarea
            type="text"
            size="sm"
            :value="item.emails"
            :state="states.emails"
            :disabled="loading"
            @change="updateValue('emails', $event)"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DictionaryProvider from '../../providers/DictionaryProvider';
import ToggleList from '../../components/fields/ToggleList.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    updateDistributor($event) {
      if (!$event || !$event.length) {
        return;
      }

      const distributor = $event[$event.length - 1];

      this.updateValue('distributor', distributor);
      this.updateValue('distributorId', distributor.id);
    },
    getDistributorFullName(item) {
      return item.distributor ? item.distributor.name : '';
    },
  },
};
</script>
