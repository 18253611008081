<template>
  <dictionary-provider
    name="cinemas"
    ref="collection"
    @loaded="onCollectionLoaded()"
  >
    <template #default="{ items: cinemas, loading }">
      <model-provider
        v-if="cinemaId"
        ref="cinema"
        collection="cinemas"
        :id="cinemaId"
        @notfound="openFirstCinema()"
        @loaded="onCinemaLoaded($event)"
      >
        <template #default="{ data: { loading, item: cinema } }">
          <slot
            :cinema-id="cinema && cinema.id"
            :cinema="cinema"
            :cinemas="cinemas"
            :loading="loading"
            :halls="cinema && cinema.halls"
            :region-id="cinema && cinema.city && cinema.city.regionId"
            :open-cinema="openCinema"
          ></slot>
        </template>
      </model-provider>
    </template>
  </dictionary-provider>
</template>

<script>
import { mapState } from 'vuex';
import { toIntOrNull } from '../utils/cast';

import DictionaryProvider from './DictionaryProvider';
import ModelProvider from './ModelProvider';

export default {
  components: {
    DictionaryProvider,
    ModelProvider,
  },
  props: {
    routeName: {
      default: null,
    },
    routeParamName: {
      default: null,
    },
  },
  computed: {
    ...mapState(['cinemaId']),

    cinemaIdInRoute() {
      return toIntOrNull(this.$route.params[this.routeParamName]);
    },
  },
  watch: {
    cinemaId() {
      if (!this.routeName || !this.routeParamName) {
        return;
      }

      this.replaceCinemaIdInRoute();
    },

    $route(to) {
      if (!this.routeName || !this.routeParamName) {
        return;
      }

      if (!this.cinemaIdInRoute && this.$refs.collection.items.length) {
        this.openFirstCinema();
        return;
      }

      if (to.name === this.routeName && this.cinemaIdInRoute !== this.cinemaId) {
        this.$store.commit('setCinemaId', this.cinemaIdInRoute);
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (!this.routeName || !this.routeParamName) {
        return;
      }

      if (this.cinemaIdInRoute) {
        this.$store.commit('setCinemaId', this.cinemaIdInRoute);
      } else {
        this.replaceCinemaIdInRoute();
      }
    },

    replaceCinemaIdInRoute() {
      if (this.$route.name === this.routeName && this.cinemaIdInRoute !== this.cinemaId) {
        this.$router.replace({
          params: {
            ...this.$route.params,
            [this.routeParamName]: this.cinemaId,
          },
          query: this.$route.query,
        });
      }
    },

    setPageParams(cinema) {
      if (cinema) {
        this.$store.commit('page/setParams', { cinemaId: cinema.id, cinema: cinema.name, city: cinema.city?.name });
      } else {
        this.$store.commit('page/setParams', { cinema: '', city: '' });
      }
    },

    onCollectionLoaded() {
      if (this.cinemaId && this.$refs.collection.items.find((cinema) => cinema.id === this.cinemaId)) {
        return;
      }

      if (!this.$refs.collection.items.length) {
        this.$store.commit('setCinemaId', null);
        return;
      }

      this.openFirstCinema();
    },

    onCinemaLoaded(cinema) {
      this.setPageParams(cinema);
      this.$emit('loaded', cinema);
    },

    openFirstCinema() {
      const firstItem = this.$refs.collection.items[0];

      if (firstItem) {
        this.$store.commit('setCinemaId', firstItem.id);
      }
    },

    openCinema(id) {
      this.$store.commit('setCinemaId', id);
    },
  },
};
</script>
