/* eslint-disable no-restricted-syntax */
import datetime from '../../utils/datetime';

import salesService from '../../services/Sales';

import {
  DANGER_TIME_INTERVAL_BETWEEN_SHOWS,
} from '../../constants';

export default {
  namespaced: true,

  state: {
    loading: false,
    items: [],
    cinemaId: null,
    date: null,
  },

  actions: {
    async initDate({ commit, state }) {
      if (state.date) {
        return;
      }

      const date = datetime.getCurrentDate();

      commit('setDate', date);
    },

    async resetDate({ commit }) {
      commit('setDate', null);
    },

    async changeDate({ commit, dispatch }, date) {
      commit('setDate', date);

      commit('setItems', { items: [] });

      await dispatch('fetchItems');
    },

    async fetchCinemaShows({ commit, dispatch }, cinemaId) {
      commit('setItems', { items: [] });
      commit('setCinemaId', cinemaId);

      await dispatch('fetchItems');
    },

    async resetItems({ commit }) {
      commit('setItems', { items: [] });
    },

    async fetchItems({ commit, state: { cinemaId, date } }) {
      if (!cinemaId || !date) {
        return;
      }

      commit('setLoading', true);

      const [, data] = await salesService.fetchShows({
        filter: {
          cinemaId,
          date: datetime.convertDateToDbFormat(date),
        },
      });

      if (data) {
        const { items } = data;

        commit('setItems', { items });
      }

      commit('setLoading', false);
    },

    async loadItem({ commit }, id) {
      commit('setLoading', true);

      const [err, item] = await salesService.getShow(id, {
        withAdvertisements: 1,
      });

      if (!err && item) {
        commit('patchItem', item);
      }

      commit('setLoading', false);

      return [err, item];
    },

    async toggleSales({ commit, dispatch, state: { cinemaId } }, {
      hallId, dates, showId, appGroups,
    }) {
      commit('setLoading', true);

      const [err, result] = await salesService.toggleSales({
        cinemaId,
        hallId,
        dates: dates ? dates.map((date) => datetime.convertDateToDbFormat(date)) : null,
        showId,
      }, { appGroups });

      await dispatch('fetchItems');

      commit('setLoading', false);

      return [err, result];
    },

    async setMinPrice({ commit, dispatch, state: { cinemaId } }, {
      releaseId, dateFrom, dateTo, timeFrom, timeTo, minPrice,
    }) {
      commit('setLoading', true);

      const [err, result] = await salesService.setMinPrice({
        cinemaId,
        releaseId,
        dateFrom: dateFrom ? datetime.convertDateToDbFormat(dateFrom) : null,
        dateTo: dateTo ? datetime.convertDateToDbFormat(dateTo) : null,
        timeFrom,
        timeTo,
      }, {
        minPrice,
      });

      await dispatch('fetchItems');

      commit('setLoading', false);

      return [err, result];
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setItems(state, { items }) {
      state.items = items;
    },

    setCinemaId(state, cinemaId) {
      state.cinemaId = cinemaId;
    },

    setDate(state, date) {
      state.date = date;
    },
  },

  getters: {
    shows: (state) => [...state.items]
      .sort((show1, show2) => datetime.compareTimes(show1.time, show2.time))
      .map((show) => ({
        ...show,
        advertisementsDuration:
          show.advertisements && show.advertisements.length
            ? show.advertisements.reduce((total, adv) => total + adv.duration, 0) / 60
            : (show.advertisementsDuration || 0),
      })),

    getHallIds: () => (shows) => shows.reduce((_hallIds, show) => {
      if (!_hallIds.includes(show.hallId)) {
        _hallIds.push(show.hallId);
      }

      return _hallIds;
    }, []),

    getSortedHallShows: () => (shows, hallId) => {
      const hallShows = shows.filter((item) => item.hallId === hallId);

      hallShows.sort((show1, show2) => datetime.compareTimes(show1.time, show2.time));

      return hallShows;
    },

    isExistShowWithSameTime: () => (shows, show) => {
      const showTimeStart = datetime.convertTimeToMoment(show.time);

      return !!shows.find((currentShow) => {
        const currentShowTimeStart = datetime.convertTimeToMoment(currentShow.time);

        return show.id !== currentShow.id && Math.abs(datetime.getDiffInMinutes(showTimeStart, currentShowTimeStart)) < DANGER_TIME_INTERVAL_BETWEEN_SHOWS;
      });
    },

    gaps: (state, getters) => {
      const gaps = [];
      const { shows } = getters;

      const hallIds = getters.getHallIds(shows);

      for (const hallId of hallIds) {
        const hallShows = getters.getSortedHallShows(shows, hallId);

        for (let i = 1; i < hallShows.length; i += 1) {
          const currentShow = hallShows[i];
          const prevShow = hallShows[i - 1];

          const prevShowTimeEnd = datetime.getIntervalEndTime(prevShow.time, prevShow.release.duration + prevShow.advertisementsDuration);
          const currentShowTimeStart = currentShow.time;

          const interval = Math.ceil(datetime.getDiffInSeconds(currentShowTimeStart, prevShowTimeEnd) / 60);

          if (interval > 0) {
            gaps.push({
              id: prevShow.id,
              nextId: currentShow.id,
              time: datetime.formatTime(prevShowTimeEnd),
              interval,
              hallId,
              warning: getters.isExistShowWithSameTime(shows, currentShow),
            });
          }
        }
      }

      return gaps;
    },

    intersections: (state, getters) => {
      const intersections = [];
      const { shows } = getters;

      const hallIds = getters.getHallIds(shows);

      for (const hallId of hallIds) {
        const hallShows = getters.getSortedHallShows(shows, hallId);

        for (let i = 1; i < hallShows.length; i += 1) {
          const currentShow = hallShows[i];
          const prevShow = hallShows[i - 1];

          const prevShowTimeEnd = datetime.getIntervalEndTime(prevShow.time, prevShow.release.duration + prevShow.advertisementsDuration);
          const currentShowTimeStart = currentShow.time;

          const interval = datetime.getDiffInMinutes(currentShowTimeStart, prevShowTimeEnd);

          if (interval <= 0) {
            intersections.push(currentShow);
          }
        }
      }

      return intersections;
    },
  },
};
